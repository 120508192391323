import { SharedAppState } from 'containers/shared'
import { PaginatedList } from 'helpers/status'
import { Attachment, Media } from 'generated/mos/media'
import { DataStatus as GenericDataStatus, EntityStatus as GenericEntityStatus, Status } from 'helpers/status'

type MediaErrorCode = string;

export type DataStatus<T> = GenericDataStatus<T, MediaErrorCode>;
export type EntityStatus<T extends object> = GenericEntityStatus<T, MediaErrorCode, string>;

export type MediaList = PaginatedList<Media.Entity>;

export type MediaAppState = {
  readonly mediaCreate: DataStatus<Media.Entity>;
  readonly mediaUpdate: DataStatus<Media.Entity>;
  readonly mediaList: DataStatus<MediaList>;
  readonly entities: { [key: string]: DataStatus<Media.Entity> };
  readonly attachments: { [key: string]: DataStatus<Attachment.Ref> };
};

export const initialMediaAppState: MediaAppState = {
  mediaCreate: { status: Status.Idle },
  mediaUpdate: { status: Status.Idle },
  mediaList: { status: Status.Idle },
  entities: {},
  attachments: {},
}

export type MediaStore = {
  readonly media: MediaAppState;
  readonly shared: SharedAppState;
}
