import React, { useEffect, useState, FC, useRef } from 'react'
import { createFormBag, FormBag, FormData, FormErrors, validateFormBag } from 'react-formage'

import { Fieldset } from 'components/ui/fieldset'
import { Input } from 'components/ui/input'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { FooterEditEntity } from 'components/ui/footer'
import { MultiSelect } from 'components/ui/select'
import { IconLanguages, IconTrash } from 'components/ui/icons'
import { Button, ButtonGroup } from 'components/ui/button'
import { Confirm } from 'components/ui/modal'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { spacesBindableActionCreators } from 'domains/spaces/actions'
import { SpacesStore } from 'domains/spaces/store'
import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { Space } from 'generated/mos/structure'

import { statusSelector, Status } from 'helpers/status'
import { areObjectsEqual, pick } from 'helpers/core'
import { space } from 'helpers/style'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { getTourSubnav } from '../tours/subnav'

import { UpdateGuidedTourRequest, UpdateTourDirectionsRequest } from '../../types'

import styled from 'styled'


type FormValues = {
  abbreviation: string | undefined
  highlightColour: string | undefined
  supportingColour: string | undefined
  directionsTitle: string | undefined
  directionsAudioFilename: string
  directionsImageFilename: string
  directionsImageAltText: string | undefined
  directionsTranscript: string
  directionsSpaces: ReadonlyArray<Space.Ref>
  directionsAudioDuration: number
  outroAudioFilename: string
  outroAudioDuration: number
  outroTranscript: string
  outroImageFilename: string
  outroTitle: string | undefined
}

type ActionProps = Pick<
  WamActionCreators,
  'wamGetGuidedTourRequest' |
  'wamUpdateGuidedTourRequest' |
  'wamDeleteTourDirectionsRequest' |
  'wamDeleteTourOutroRequest' |
  'wamUpdateTourDirectionsRequest'
> &
  Pick<typeof spacesBindableActionCreators, "spacesRequest"> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<
  WamAppState['tours'],
  'tourUpdate' |
  'tourDirectionsDelete' |
  'tourOutroDelete'
> &
  Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const PrefixWrap = styled.div`
  position: relative;
  input {
    padding-left: ${space(7)};
  }
`
const Prefix = styled.div`
  position: absolute;
  z-index: 1;
  align-items: center;
  display: flex;
  bottom: 0;
  left: 0;
  height: 53px;
  padding-left: ${space(4)};
`

const Form = styled.form`
  width: 60%;
`

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: -${space(6)};
`

const DeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin: ${space(1)};

  &:focus {
    position: absolute;
  }
`

const EmptyContent = styled.span`
  padding-left: ${space(5)};
`

const HexInput: FC = ({ children }) => (
  <PrefixWrap>
    <Prefix>#</Prefix>
    {children}
  </PrefixWrap>
)

const isValidHex = (color: string) => /^[0-9A-F]{6}$/i.test(color)

const PropertiesWayfindingPageView = (props: Props) => {
  const {
    projectId,
    tourId,
    wamGetGuidedTourRequest,
    tourUpdate,
    historyPush,
    spacesList,
    spacesRequest,
    wamUpdateGuidedTourRequest,
    wamUpdateTourDirectionsRequest,
    wamDeleteTourDirectionsRequest,
    wamDeleteTourOutroRequest,
    tourDirectionsDelete,
    tourOutroDelete,
    toastNotification,
  } = props
  const data = statusSelector.data(tourUpdate)
  const spacesListData = statusSelector.data(spacesList)

  const [formBag, setFormBag] = useState<FormBag<FormValues> | undefined>(undefined)

  const [ deleteDirectionsModal, setDeleteDirectionsModal ] = useState(false)
  const [ deleteOutroModal, setDeleteOutroModal ] = useState(false)

  const hasSubmitted = useRef<boolean>(false)

  useEffect(() => {
    spacesRequest()
    wamGetGuidedTourRequest({ ecmsId: tourId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // delete Directions toast notifications
  useEffect(() => {
    if (tourDirectionsDelete.status === Status.Complete) {
      wamGetGuidedTourRequest({ ecmsId: tourId })
      toastNotification({
        type: 'success',
        text: 'Tour Directions deleted successfully.',
      })
    }
    if (tourDirectionsDelete.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Tour Directions could not be deleted',
      })
    }

    // delete Outro toast notifications
    if (tourOutroDelete.status === Status.Complete) {
      wamGetGuidedTourRequest({ ecmsId: tourId })
      toastNotification({
        type: 'success',
        text: 'Tour Outro deleted successfully.',
      })
    }
    if (tourOutroDelete.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Tour Outro could not be deleted',
      })
    }
  }, [tourDirectionsDelete, tourOutroDelete]) // eslint-disable-line react-hooks/exhaustive-deps


  // tour update toast notifications
  useEffect(() => {
    if (tourUpdate.status === Status.Ready && hasSubmitted.current) {
      toastNotification({
        type: 'success',
        text: 'Tour updated successfully.',
      })
      hasSubmitted.current = false
    }

    if (tourUpdate.status === Status.Failed && hasSubmitted.current) {
      toastNotification({
        type: 'error',
        text: 'Tour could not be updated.',
      })
      hasSubmitted.current = false
    }
  }, [tourUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const formValues = (data: any) => {
    const { directions, outro, abbreviation, highlightColour, supportingColour } = data
    const directionValues = directions && {
      directionsTitle: directions.title && directions.title.en,
      directionsAudioFilename: directions.audioFilename,
      directionsImageFilename: directions.imageFilename,
      directionsImageAltText: directions.imageAltText && directions.imageAltText.en,
      directionsTranscript: directions.transcript && directions.transcript.en,
      directionsAudioDuration: directions.audioDurationSeconds,
      directionsSpaces: directions.destinationSpaces,
    }
    const outroValues = outro && {
      outroAudioFilename: outro.audioFilename,
      outroAudioDuration: outro.audioDurationSeconds,
      outroTranscript: outro.transcript && outro.transcript.en,
      outroImageFilename: outro.imageFilename,
      outroTitle: outro.title
    }
    return {
      abbreviation,
      highlightColour,
      supportingColour,
      ...directionValues,
      ...outroValues,
    }
  }
  const initialFormValues: FormValues | undefined =
    data && data.ecmsId === tourId ? formValues(data) : undefined

  useEffect(() => {
    if (tourUpdate.status === Status.Ready && initialFormValues) {
      setFormBag(createFormBag(initialFormValues))
    }
  }, [tourUpdate.status])

  const modified =
    initialFormValues && data && formBag && !areObjectsEqual(initialFormValues, formBag.values)

  const validateForm = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {}
    if (
      !values.highlightColour ||
      (!!values.highlightColour.trim().length && !isValidHex(values.highlightColour.trim()))
    ) {
      errors.highlightColour = 'Must provided a valid Hexidecimal value'
    }

    if (
      !values.supportingColour ||
      (!!values.supportingColour.trim().length && !isValidHex(values.supportingColour.trim()))
    ) {
      errors.supportingColour = 'Must provided a valid Hexidecimal value'
    }

    return errors
  }

  const save = () => {
    if (formBag && data) {
      const bag = validateFormBag(formBag, validateForm)
      setFormBag(bag)

      if (!bag.valid) return false

      const {
        abbreviation,
        highlightColour,
        supportingColour,
        directionsTitle,
        directionsImageAltText,
        directionsSpaces
      } = formBag.values

      const payload: UpdateGuidedTourRequest = {
        ecmsId: tourId,
        abbreviation,
        highlightColour,
        supportingColour,
      }
      wamUpdateGuidedTourRequest(payload)

      // update tour directions
      if (data.directions) {
        const updateDirectionsPayload: UpdateTourDirectionsRequest = {
          ecmsId: tourId,
          title: {
            en: directionsTitle
          },
          imageAltText: {
            en: directionsImageAltText
          },
          destinationSpaces: directionsSpaces
        }

        wamUpdateTourDirectionsRequest(updateDirectionsPayload)
      }

      hasSubmitted.current = true
    }
  }

  const onDeleteDirections = () => {
    wamDeleteTourDirectionsRequest({ ecmsId: tourId })
    setDeleteDirectionsModal(false)
  }

  const onDeleteOutro = () => {
    wamDeleteTourOutroRequest({ ecmsId: tourId })
    setDeleteOutroModal(false)
  }

  return (
    <>
      {deleteDirectionsModal &&
        <Confirm
          title="Delete Directions?"
          onClose={() => setDeleteDirectionsModal(false)}
          message="Are you sure you want to permanently delete Directions?"
          confirmLabel="Delete"
          onConfirm={onDeleteDirections}
          danger
        />
      }
      {deleteOutroModal &&
        <Confirm
          title="Delete Outro?"
          onClose={() => setDeleteOutroModal(false)}
          message="Are you sure you want to permanently delete Outro?"
          confirmLabel="Delete"
          onConfirm={onDeleteOutro}
          danger
        />
      }
      <LayoutListOrFormPage
        nav={() => (
          <PrimaryNavigationEditEntity
            darkTheme
            title="Edit tour"
            navBackRoute={`/project/${projectId}/tours`}
          />
        )}
        title={data ? data.title : ''}
        subnav={() => <SecondaryNavigation navItems={getTourSubnav(projectId, tourId)} />}
        content={() => (
          <>
            {data && (data.directions || data.outro) &&
              <StyledButtonGroup>
                <Button
                  variant="subtle"
                  onClick={() => historyPush(`/project/${projectId}/tour/${tourId}/properties/translations`)}
                >
                  <IconLanguages />
                  Manage Translations
                </Button>
              </StyledButtonGroup>
            }
            <StatusGuard status={tourUpdate}>
              {formBag && data && spacesListData && (
                <Form
                  noValidate
                  onSubmit={(event) => {
                    event.preventDefault()
                  }}
                >
                  <FormData
                    bag={formBag}
                    onUpdate={(event) => {
                      setFormBag(event.bag)
                    }}
                    validate={() => ({})}
                  >
                    <Fieldset legend="Tour Properties">
                      <Input label="Abbreviation" field="abbreviation" />
                      <HexInput>
                        <Input
                          label="Highlight Colour"
                          field="highlightColour"
                          error={
                            formBag.touched.highlightColour ? formBag.errors.highlightColour : undefined
                          }
                        />
                      </HexInput>
                      <HexInput>
                        <Input
                          label="Supporting Colour"
                          field="supportingColour"
                          error={
                            formBag.touched.supportingColour
                              ? formBag.errors.supportingColour
                              : undefined
                          }
                        />
                      </HexInput>
                    </Fieldset>
                    <Fieldset legend="Directions">
                      {data.directions ?
                        <>
                          <DeleteButton
                            variant="subtle"
                            isIcon={true}
                            onClick={() => setDeleteDirectionsModal(true)}
                          >
                            <IconTrash />
                          </DeleteButton>
                          <Input label="Title" field="directionsTitle" />
                          <Input isDisabled label="Audio Asset URL" field="directionsAudioFilename" />
                          <Input isDisabled label="Duration" field="directionsAudioDuration" description="Time in seconds" />
                          <Input isDisabled label="Transcript" field="directionsTranscript" />
                          <MultiSelect<FormValues, 'directionsSpaces'>
                            label="Active space(s)"
                            field="directionsSpaces"
                            options={spacesListData.map(space => ({
                              label: space.displayName || space.name,
                              value: Space.mustRef(space.ref)
                            }))}
                          />
                          <Input isDisabled label="Image" field="directionsImageFilename" />
                          <Input label="Image Alt-Text" field="directionsImageAltText" />
                        </> :
                        <EmptyContent>No directions associated with this tour.</EmptyContent>
                      }
                    </Fieldset>
                    <Fieldset legend="Outro">
                    {data.outro ?
                      <>
                        <DeleteButton
                          variant="subtle"
                          isIcon={true}
                          onClick={() => setDeleteOutroModal(true)}
                        >
                          <IconTrash />
                        </DeleteButton>
                        <Input isDisabled label="Title" field="outroTitle" />
                        <Input isDisabled label="Audio Asset URL" field="audioFilename" />
                        <Input isDisabled label="Duration" field="outroAudioDuration" description="Time in seconds" />
                        <Input isDisabled label="Transcript" field="outroTranscript" />
                        <Input isDisabled label="Image" field="outroImageFilename" />
                      </> :
                      <EmptyContent>No outros associated with this tour.</EmptyContent>
                    }
                    </Fieldset>

                  </FormData>
                </Form>
              )}
            </StatusGuard>
          </>
        )}
        footer={() => (
          <FooterEditEntity
            entity={tourUpdate}
            modified={modified}
            onCancel={() => historyPush(`/project/${projectId}/tours`)}
            onSave={save}
          />
        )}
      />
    </>
  )
}

export const PropertiesWayfindingPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.tours, 'tourUpdate', 'tourDirectionsDelete', 'tourOutroDelete'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(
      actionCreators,
      'wamGetGuidedTourRequest',
      'wamUpdateGuidedTourRequest',
      'wamDeleteTourDirectionsRequest',
      'wamDeleteTourOutroRequest',
      'wamUpdateTourDirectionsRequest'
    ),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(PropertiesWayfindingPageView)
