import React, { useEffect } from 'react'

import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SectionHeader } from 'components/ui/section-header'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { statusSelector } from 'helpers/status'
import { pick } from 'helpers/core'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { getTourSubnav } from '../subnav'
import { StopItem } from './stop-item'

type ActionProps = Pick<WamActionCreators, 'wamGetGuidedTourRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['tours'], 'tourUpdate'>

type DirectProps = {
  projectId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const TourUpdatePageView = (props: Props) => {
  const { projectId, tourId, wamGetGuidedTourRequest, tourUpdate, historyPush } = props
  const data = statusSelector.data(tourUpdate)

  useEffect(() => {
    wamGetGuidedTourRequest({ ecmsId: tourId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LayoutListOrFormPage
      nav={() => (
        <PrimaryNavigationEditEntity
          darkTheme
          title="Edit tour"
          navBackRoute={`/project/${projectId}/tours`}
        />
      )}
      title={data ? data.title : ''}
      subnav={() => <SecondaryNavigation navItems={getTourSubnav(projectId, tourId)} />}
      content={() => (
        <StatusGuard status={tourUpdate}>
          <SectionHeader heading={`stops ${data ? data.stops.length : 0}`} />
          {data &&
            data.stops.map(({ ecmsId, title, thumbnailUrl }) => {
              return (
                <StopItem
                  key={ecmsId}
                  title={title}
                  tourId={tourId}
                  stopId={ecmsId}
                  thumbnailUrl={thumbnailUrl}
                  historyPush={historyPush}
                  projectId={projectId}
                />
              )
            })}
        </StatusGuard>
      )}
    />
  )
}

export const TourUpdatePage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.tours, 'tourUpdate'),
  }),
  {
    ...pick(actionCreators, 'wamGetGuidedTourRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(TourUpdatePageView)
