// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { AdminMap, Building, BuildingLevel, OutdoorLevel, Site, Space, SpaceType } from "generated/mos/structure";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, stripTypePropertyDeep } from "generated/webrpc";

export namespace GetAdminMapRequest {
  export const refName = "mos.structure_access.GetAdminMapRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMapRef: { readonly typename: 'mos.structure.AdminMap', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMapRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetAdminMapRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMapRef"] = v["adminMapRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetAdminMapResponse {
  export const refName = "mos.structure_access.GetAdminMapResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMap: AdminMap.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMap: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["adminMap"] = AdminMap.codec.encode(v.adminMap);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetAdminMapResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMap"] = ctx.decode(AdminMap.codec, v["adminMap"], "adminMap");
      return out as any as Entity;
    }
  }();
}

export namespace GetBuildingLevelRequest {
  export const refName = "mos.structure_access.GetBuildingLevelRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevelRef: { readonly typename: 'mos.structure.BuildingLevel', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevelRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetBuildingLevelRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevelRef"] = v["buildingLevelRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetBuildingLevelResponse {
  export const refName = "mos.structure_access.GetBuildingLevelResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevel: BuildingLevel.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevel: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingLevel"] = BuildingLevel.codec.encode(v.buildingLevel);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetBuildingLevelResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevel"] = ctx.decode(BuildingLevel.codec, v["buildingLevel"], "buildingLevel");
      return out as any as Entity;
    }
  }();
}

export namespace GetBuildingRequest {
  export const refName = "mos.structure_access.GetBuildingRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetBuildingRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingRef"] = v["buildingRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetBuildingResponse {
  export const refName = "mos.structure_access.GetBuildingResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly building: Building.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    building: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["building"] = Building.codec.encode(v.building);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetBuildingResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["building"] = ctx.decode(Building.codec, v["building"], "building");
      return out as any as Entity;
    }
  }();
}

export namespace GetOutdoorLevelRequest {
  export const refName = "mos.structure_access.GetOutdoorLevelRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly outdoorLevelRef: { readonly typename: 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    outdoorLevelRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetOutdoorLevelRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["outdoorLevelRef"] = v["outdoorLevelRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetOutdoorLevelResponse {
  export const refName = "mos.structure_access.GetOutdoorLevelResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly outdoorLevel: OutdoorLevel.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    outdoorLevel: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["outdoorLevel"] = OutdoorLevel.codec.encode(v.outdoorLevel);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetOutdoorLevelResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["outdoorLevel"] = ctx.decode(OutdoorLevel.codec, v["outdoorLevel"], "outdoorLevel");
      return out as any as Entity;
    }
  }();
}

export namespace GetSiteRequest {
  export const refName = "mos.structure_access.GetSiteRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSiteRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetSiteResponse {
  export const refName = "mos.structure_access.GetSiteResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly site: Site.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    site: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["site"] = Site.codec.encode(v.site);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSiteResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["site"] = ctx.decode(Site.codec, v["site"], "site");
      return out as any as Entity;
    }
  }();
}

export namespace GetSpaceRequest {
  export const refName = "mos.structure_access.GetSpaceRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceRef: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSpaceRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceRef"] = v["spaceRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetSpaceResponse {
  export const refName = "mos.structure_access.GetSpaceResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly space: Space.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    space: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["space"] = Space.codec.encode(v.space);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSpaceResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["space"] = ctx.decode(Space.codec, v["space"], "space");
      return out as any as Entity;
    }
  }();
}

export namespace GetSpaceTypeRequest {
  export const refName = "mos.structure_access.GetSpaceTypeRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceTypeRef: { readonly typename: 'mos.structure.SpaceType', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceTypeRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSpaceTypeRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceTypeRef"] = v["spaceTypeRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetSpaceTypeResponse {
  export const refName = "mos.structure_access.GetSpaceTypeResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceType: SpaceType.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceType: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.GetSpaceTypeResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceType"] = ctx.decode(SpaceType.codec, v["spaceType"], "spaceType");
      return out as any as Entity;
    }
  }();
}

export namespace ListAdminMapsRequest {
  export const refName = "mos.structure_access.ListAdminMapsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListAdminMapsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListAdminMapsResponse {
  export const refName = "mos.structure_access.ListAdminMapsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMaps: ReadonlyArray<AdminMap.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    adminMaps: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["adminMaps"] = [];
      for (const item of v.adminMaps) {
        obj["adminMaps"].push(AdminMap.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListAdminMapsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMaps"] = decodeMessageRepeated(ctx, AdminMap.codec, v["adminMaps"], "adminMaps");
      return out as any as Entity;
    }
  }();
}

export namespace ListBuildingLevelsRequest {
  export const refName = "mos.structure_access.ListBuildingLevelsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListBuildingLevelsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListBuildingLevelsResponse {
  export const refName = "mos.structure_access.ListBuildingLevelsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevels: ReadonlyArray<BuildingLevel.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevels: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingLevels"] = [];
      for (const item of v.buildingLevels) {
        obj["buildingLevels"].push(BuildingLevel.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListBuildingLevelsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevels"] = decodeMessageRepeated(ctx, BuildingLevel.codec, v["buildingLevels"], "buildingLevels");
      return out as any as Entity;
    }
  }();
}

export namespace ListBuildingsRequest {
  export const refName = "mos.structure_access.ListBuildingsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListBuildingsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListBuildingsResponse {
  export const refName = "mos.structure_access.ListBuildingsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildings: ReadonlyArray<Building.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    buildings: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildings"] = [];
      for (const item of v.buildings) {
        obj["buildings"].push(Building.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListBuildingsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildings"] = decodeMessageRepeated(ctx, Building.codec, v["buildings"], "buildings");
      return out as any as Entity;
    }
  }();
}

export namespace ListOutdoorLevelsRequest {
  export const refName = "mos.structure_access.ListOutdoorLevelsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListOutdoorLevelsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListOutdoorLevelsResponse {
  export const refName = "mos.structure_access.ListOutdoorLevelsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly outdoorLevels: ReadonlyArray<OutdoorLevel.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    outdoorLevels: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["outdoorLevels"] = [];
      for (const item of v.outdoorLevels) {
        obj["outdoorLevels"].push(OutdoorLevel.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListOutdoorLevelsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["outdoorLevels"] = decodeMessageRepeated(ctx, OutdoorLevel.codec, v["outdoorLevels"], "outdoorLevels");
      return out as any as Entity;
    }
  }();
}

export namespace ListSitesRequest {
  export const refName = "mos.structure_access.ListSitesRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSitesRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListSitesResponse {
  export const refName = "mos.structure_access.ListSitesResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly sites: ReadonlyArray<Site.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    sites: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["sites"] = [];
      for (const item of v.sites) {
        obj["sites"].push(Site.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSitesResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["sites"] = decodeMessageRepeated(ctx, Site.codec, v["sites"], "sites");
      return out as any as Entity;
    }
  }();
}

export namespace ListSpaceTypesRequest {
  export const refName = "mos.structure_access.ListSpaceTypesRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSpaceTypesRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListSpaceTypesResponse {
  export const refName = "mos.structure_access.ListSpaceTypesResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceTypes: ReadonlyArray<SpaceType.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    spaceTypes: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSpaceTypesResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceTypes"] = decodeMessageRepeated(ctx, SpaceType.codec, v["spaceTypes"], "spaceTypes");
      return out as any as Entity;
    }
  }();
}

export namespace ListSpacesRequest {
  export const refName = "mos.structure_access.ListSpacesRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSpacesRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListSpacesResponse {
  export const refName = "mos.structure_access.ListSpacesResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaces: ReadonlyArray<Space.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    spaces: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["spaces"] = [];
      for (const item of v.spaces) {
        obj["spaces"].push(Space.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_access.ListSpacesResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaces"] = decodeMessageRepeated(ctx, Space.codec, v["spaces"], "spaces");
      return out as any as Entity;
    }
  }();
}

