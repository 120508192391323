import React from 'react'

import { space } from 'helpers/style'
import styled from 'styled'

const Heading = styled.h3`
  padding: ${space(4)};
  margin: 0;
  margin-bottom: ${space(3)};	
  background-color: ${({ theme }) => theme.color.grayL80};
  color: ${({ theme }) => theme.color.darkText};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: uppercase;
`

type Props = {
  readonly heading: string;
  // FIXME: any https://github.com/emotion-js/emotion/issues/1137
  /**
   * Customises the HTML tag used.
   */
  readonly tag?: any;
}


export const SectionHeader = (props: Props) => (
  <Heading as={props.tag ? props.tag : null}>
    {props.heading}
  </Heading>
)

SectionHeader.defaultProps = {
  tag: 'h3',
}
