/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconWarning = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconWarningString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"  fillRule="nonzero" />
  </svg>`

IconWarning.displayName = 'IconWarning'
