import React, { useState } from 'react'

import { AudioPlayer } from 'components/ui/audio-player'
import { Button } from 'components/ui/button'
import {
  IconEdit,
  IconMediaAudio,
  IconMediaEmpty,
  IconMediaPdf,
  IconMediaSubtitles,
  IconMediaVideo
} from 'components/ui/icons'
import { ImageWithFallback } from 'components/ui/image'
import { StatusGuard } from 'components/ui/status-guard'

import { connect } from 'containers/store'

import {
  LinkableOverride,
  LinkableOverrideOptions,
} from 'domains/linkable/components/linkable-override'

import { Language } from 'generated/mos/i18n'
import { Media, MediaKind } from 'generated/mos/media'

import { bffEndpoint } from 'helpers/bff'
import { assertNever, pick } from 'helpers/core'
import { defaultLanguage } from 'helpers/i18n'
import { parseMetadataTimestamp } from 'helpers/metadata'
import { Status, statusSelector } from 'helpers/status'

import { actionCreators } from '../../actions'
import { DataStatus } from '../../store'
import { MediaEdit } from '../media-edit'
import { MediaPicker } from '../media-picker'

import {
  Description,
  EmptyDetails,
  FieldWrapper,
  Label,
  LabelSupport,
  LinkedFieldIcon,
  LinkedFieldEditButton,
  MainOptions,
  PlayerContainer,
  Preview,
  Table,
  TableCell,
  TableHeader,
  Thumbnail,
  Title,
  Warning,
} from './styled'

import styled from 'styled'

const Image = styled(ImageWithFallback)`
  height: 56px;
`;

type DirectProps = {
  /**
   * MediaKind
   */
  readonly kind: MediaKind;
  /**
   * Media.Ref
   */
  readonly mediaRef: Media.Ref | undefined;
  /**
   * A callback used to update the parent entity when the media entity is changed.
   */
  readonly onUpdate: (entity: Media.Entity | undefined) => void;
  readonly label: string;
  /**
   * Optional copy which sits to the right of the label in a subtle colour.
   */
  readonly labelSupportingText?: string;
  /**
   * Displays below the form element describing it's purpose.
   */
  readonly description?: string;
  readonly language?: Language.Entity | undefined;
  /**
   * Used for styling purposes only.
   */
  readonly isAssociated?: boolean;
  readonly isDisabled?: boolean;
  /**
   * Configuration object for linked field behaviour.
   */
  readonly linkableOptions?: LinkableOverrideOptions;
  /**
   * A warning that there is a publishing issue.
   */
  readonly warning?: string;
}

type ActionProps = Pick<typeof actionCreators, 'mediaGetMediaRequest'>;

type ConnectedProps = {
  /**
   * Read Media entities from the Redux store by ref ID.
   */
  readonly entities: { [key: string]: DataStatus<Media.Entity> };
};

type Props = ActionProps & ConnectedProps & DirectProps;

/**
 * This component is different to other form components because it is not driven by formage but instead integrates with the DMM. This means that validation and error handling scenarios are different and we need to specify which `MEDIA_KIND` an instance of the field supports, and how to update the parent entity by using the `onUpdate` prop.
 *
 * An optional `labelSupportText` prop can be used to provide supporting text for the fields label, and should be visually more subtle than the label copy itself.
 *
 * The `isAssociated` prop is used to change the visual style of the element when it relates to another field above it, for example alternate text, credits etc.
 *
 * ## Validation feedback
 *
 * The `error` prop is used to display field validation issues. The `warning` prop is used to display issues related to publishing snapshots. In cases where both an error and a warning are presented to the field, only the error will be displayed.
 *
 * ## Linked fields
 *
 * If a field is linked we need to pass a configuration object through the `linkableOptions` prop to allow the linking functionality to operate. This object consists of the following options:
 *
 * - `alternateFieldValues`: Attribute Ref based values to select from based on linked field values mappings.
 * - `allowCustomOverride`: When a field is linkable we generally allow the user to specify a custom override value rather than selecting another linked field value. This prop can be used to disable that option when set to false.
 * - `onUpdate`: **Required** This passes the value from the override form back to the parent components state.
 *
 * At least one of `alternateFieldValues` or `allowCustomOverride` must be truthy.
 *
 * When using linked field values the intention is that the original value from the entity in Redux can be used to display the current value in this field. Potential overrides should be handled separately within local state and can be kept updated with the `onUpdate` function.
 *
 * When the user wants to save the updates we should check to see if an override has been set (a non-`undefined` value is present) and use that value in place of the original. This override value may either be an Attribute Ref or a standard MOS value (string, Media Ref etc.). This also allows us to have manual control of setting `updateMask`s in the parent form component.
 */
// only exported for storybook.
export const MediaFieldComponent = (props: Props) => {
  const {
    description,
    entities,
    isAssociated,
    isDisabled,
    kind,
    label,
    labelSupportingText,
    language,
    linkableOptions,
    mediaGetMediaRequest,
    mediaRef,
    onUpdate,
    warning,
  } = props

  const allowCustomOverride = linkableOptions && linkableOptions.allowCustomOverride !== undefined ? linkableOptions.allowCustomOverride : true
  const mediaStatus = mediaRef ? entities[mediaRef.id] : undefined

  const [ isOverriding, setOverrideMode ] = useState(false)
  const [ editOpen, toggleEdit ] = useState(false)
  const [ pickerOpen, togglePicker ] = useState(false)

  // fetch the entity if a ref has been passed but the data isn't yet available.
  if (!mediaStatus || mediaStatus && mediaStatus.status === Status.Idle) {
    if (mediaRef) {
      mediaGetMediaRequest(mediaRef, {
        type: 'mos.media_access.GetMediaRequest',
        mediaRef,
      })
    }
  }

  const media = mediaStatus && statusSelector.data(mediaStatus)

  const hasWarning = warning && !!warning.trim().length
  const parsedTimestamp = media && Media.isRef(media.ref) && media.metadata ? parseMetadataTimestamp(media.metadata) : undefined

  if (media && Media.isRef(media.ref) && media.kind !== kind) {
    throw new Error('Media entity does not match specified media kind for media-field.')
  }

  const getTitle = (media: Media.Entity) => {
    if (media.title.length) {
      return media.title
    }

    return media.uploadedFilename
  }

  const renderThumbnailIcon = (media: Media.Entity | undefined) => {
    if (!media) {
      return <IconMediaEmpty />
    }

    switch (media.kind) {
      case 'MEDIA_KIND_AUDIO': {
        return <IconMediaAudio />
      }
      case 'MEDIA_KIND_BINARY': {
        // FIXME: we will need to add an icon for this.
        return <IconMediaSubtitles />
      }
      case 'MEDIA_KIND_PDF': {
        return <IconMediaPdf />
      }
      case 'MEDIA_KIND_RASTER_IMAGE': {
        const hostName = bffEndpoint(window.location.hostname)
        const mediaId = media.ref && media.ref.id

        const imagePath = mediaId ? `${hostName}/images/${mediaId}.jpg?s=crop&h=112&w=112` : ''
        return <Image src={imagePath} />
      }
      case 'MEDIA_KIND_VECTOR_IMAGE': {
        const hostName = bffEndpoint(window.location.hostname)
        const mediaId = media.ref && media.ref.id

        // use .svg for vector image
        const imagePath = mediaId ? `${hostName}/images/${mediaId}.svg` : ''
        return <Image src={imagePath} />
      }
      case 'MEDIA_KIND_VIDEO': {
        return <IconMediaVideo />
      }
      case 'MEDIA_KIND_UNSPECIFIED': {
        return <IconMediaEmpty />
      }
      default: {
        assertNever(media.kind)
      }
    }
  }

  const renderPreview = () => (
    <Preview isDisabled={isDisabled || !!linkableOptions} isOverriding={isOverriding}>
      <Thumbnail>
        {renderThumbnailIcon(media)}
      </Thumbnail>
      <div style={{ width: '100%' }}>
        <Title>{media && Media.isRef(media.ref) ? getTitle(media) : 'No file selected'}</Title>

        {media && Media.isRef(media.ref) ? (
          <Table>
            <tbody>
              <tr>
                <TableHeader>Filename</TableHeader>
                <TableCell>{media.uploadedFilename}</TableCell>
              </tr>
              <tr>
                <TableHeader>Modified</TableHeader>
                <TableCell>{parsedTimestamp!.value}</TableCell>
              </tr>
              <tr>
                <TableHeader>Notes</TableHeader>
                <TableCell>{media.internalNotes}</TableCell>
              </tr>
            </tbody>
          </Table>
        ) : (
          <EmptyDetails>-</EmptyDetails>
        )}
      </div>

      {linkableOptions && !isOverriding && (
        <LinkedFieldEditButton>
          <Button
            variant="subtle"
            isIcon={true}
            isDisabled={isDisabled}
            onClick={() => setOverrideMode(true)}
          >
            <IconEdit />
          </Button>
        </LinkedFieldEditButton>
      )}
    </Preview>
  )

  return (
    <FieldWrapper isAssociated={isAssociated}>
      <Label>
        <>
          {linkableOptions && (<LinkedFieldIcon size="base" />)}
          {label}
          {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
        </>

        {mediaStatus ? (
          <StatusGuard status={mediaStatus} mode="form">
            {renderPreview()}
          </StatusGuard>
        ) : renderPreview()}

        {media && Media.isRef(media.ref) && media.kind === 'MEDIA_KIND_AUDIO' && (
          <PlayerContainer>
            <AudioPlayer name="" src={media!.url} />
          </PlayerContainer>
        )}
      </Label>

      {!linkableOptions && !isDisabled && (
        <MainOptions align="left">
          {media && Media.isRef(media.ref) ? (
            <>
              <Button
                type="button"
                variant="link"
                appearance="secondary"
                isDisabled={isDisabled}
                onClick={(event) => {
                  event.preventDefault()
                  toggleEdit(true)
                }}
              >
                Edit
              </Button>
              <Button
                type="button"
                variant="link"
                appearance="secondary"
                isDisabled={isDisabled}
                onClick={(event) => {
                  event.preventDefault()
                  onUpdate(undefined)
                }}
              >
                Remove
              </Button>
              <Button
                type="button"
                variant="link"
                appearance="secondary"
                isDisabled={isDisabled}
                onClick={(event) => {
                  event.preventDefault()
                  togglePicker(true)
                }}
              >
                Replace
              </Button>
            </>
          ) : (
            <Button
              type="button"
              variant="link"
              appearance="secondary"
              isDisabled={isDisabled}
              onClick={(event) => {
                event.preventDefault()
                togglePicker(true)
              }}
            >
              Add media
            </Button>
          )}
        </MainOptions>
      )}

      {isOverriding && linkableOptions && (
        <LinkableOverride
          label={label}
          alternateFieldValues={linkableOptions.alternateFieldValues}
          customOverrideType={allowCustomOverride ? kind : undefined}
          onCancel={() => setOverrideMode(false)}
          onUpdate={(value) => linkableOptions.onUpdate(value)}
        />
      )}

      {media && Media.isRef(media.ref) && editOpen && (
        <MediaEdit
          media={media}
          onClose={() => toggleEdit(false)}
          onSave={(entity) => onUpdate(entity) }
        />
      )}

      {pickerOpen && (
        <MediaPicker
          kind={kind}
          language={language || defaultLanguage()}
          onClose={() => togglePicker(false)}
          onSave={(entity) => onUpdate(entity) }
        />
      )}

      {hasWarning && (<Warning>{warning}</Warning>)}
      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}

MediaFieldComponent.defaultProps = {
  isAssociated: false,
  isDisabled: false,
}

export const MediaField = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.media, 'entities'),
  pick(actionCreators, 'mediaGetMediaRequest'),
)(MediaFieldComponent)
