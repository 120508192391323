import React, { useState } from 'react'

import { ctaFocus, Button } from 'components/ui/button'
import { Confirm } from 'components/ui/modal'
import { space } from 'helpers/style'
import styled from 'styled'
import { NavLink } from 'react-router-dom'

const Nav = styled.nav`
  /* subnav is designed to sit above a container with a 1px upper border. */
  margin-bottom: -1px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  list-style-type: none;
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: ${space(8)};

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
    padding: ${space(2)} 0;
    border-bottom: 1px solid transparent;
    color: ${({ theme }) => theme.color.gray};

    &:hover {
      color: ${({ theme }) => theme.color.grayD40};
    }

    &:focus,
    &.is-active {
      color: ${({ theme }) => theme.color.primary};
      border-bottom: 1px solid ${({ theme }) => theme.color.primary};
    }

    ${ctaFocus}
  }
`

const NavLinkButton = styled(Button)<{ isActive: boolean }>`
  color: ${({ isActive, theme }) => isActive ? theme.color.primary : theme.color.gray};
  ${({ isActive, theme }) => isActive && `border-bottom: 1px solid ${theme.color.primary}`};
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.base};
  padding: ${space(2)} 0px;

  &:hover {
    color: ${({ isActive, theme }) => isActive ? theme.color.primary : theme.color.grayD20};
  }
`

type NavItem = {
  readonly title: string;
  readonly to: string;
}

export type NavItems = ReadonlyArray<NavItem>;

type Props = {
  readonly navItems: NavItems;
  readonly modified?: boolean;
  readonly historyPush?: (id: string) => void;
}

export const SecondaryNavigation = (props: Props) => {
  const {
    navItems,
    modified,
    historyPush
  } = props

  const [ confirmPath, setConfirmPath ] = useState<string | undefined>(undefined)

  const isTabSelected = (linkPath: string) => linkPath === window.location.pathname

  return (
    <>
      {navItems &&
        <Nav>
          <List>
            {navItems.map(navItem => (
              <>
                {confirmPath && historyPush &&
                  <Confirm
                    onClose={() => setConfirmPath(undefined) }
                    title="Unsaved Changes"
                    message="Do you want to discard your changes?"
                    confirmLabel="Yes, discard"
                    closeLabel="Cancel"
                    onConfirm={() => historyPush(confirmPath)}
                  />
                }
                <ListItem key={navItem.title}>
                  {historyPush ?
                    <NavLinkButton variant="link" isActive={isTabSelected(navItem.to)} onClick={() => {modified ? setConfirmPath(navItem.to) : historyPush && historyPush(navItem.to)}}>
                      {navItem.title}
                    </NavLinkButton> :
                    <NavLink exact to={navItem.to} activeClassName="is-active">
                      {navItem.title}
                    </NavLink>
                  }
                </ListItem>
              </>
            ))}
          </List>
        </Nav>
      }
    </>
  )
}
