import React from 'react'

import { Link } from 'components/ui/link'
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { GetConfigResponse } from 'generated/mos/admin/config';
import { pick } from 'helpers/core';
import { space } from 'helpers/style'
import { LayoutUnauthenticatedPage } from 'layouts/unauthenticated-page';
import styled from 'styled';

import { ForgotPasswordForm } from 'domains/account/components/forgot-password-form';
import { Org } from 'generated/mos/user';
import { ORG_SLUG } from '../../../../constants'


const Para = styled.p`
  margin-bottom: ${space(10)};
  text-align: center;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(10)};
`

type DirectProps = {
  readonly onComplete: () => void;
};

// type ConnectedProps = {
//   readonly config: GetConfigResponse.Entity | undefined;
// };

type ActionProps = Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = ActionProps & DirectProps;

const ForgotPasswordView = (props: Props) => {
  const { toastNotification } = props;
  // if (!config) {
  //   return null;
  // }

  // if (!config.publicOrgInfo || !config.publicOrgInfo.orgRef) {
  //   return null;
  // }

  const orgRef = {
    typename: "mos.user.Org",
    id: 'eb419529-63a6-4de3-9a88-6af4a98a7be3',
  } as Org.Ref

  return (
    <LayoutUnauthenticatedPage
      title={() => 'Forgot your password?'}
      content={() => (<>
        <Para>Please enter your email address and we will send you a link to reset your password.</Para>
        <ForgotPasswordForm
          orgRef={orgRef}
          onSuccess={() => {
            toastNotification({ type: 'success', text: 'Email sent' });
            props.onComplete();
          }}
        />
        <CtaContainer>
          <Link to="/sign-in">
            Back to sign in
          </Link>
        </CtaContainer>
      </>)}
    />
  );
};

export const ForgotPasswordPage = connect<{}, ActionProps, DirectProps>(
  () => ({}),
  pick(sharedActionCreators, 'toastNotification'),
)(ForgotPasswordView);
