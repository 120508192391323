// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Checksum } from "generated/mos/entity";
import { Language } from "generated/mos/i18n";
import { Attachment, AttachmentIntent, AttachmentIntentValues, Media, MediaKind, MediaKindValues, Tag, TagValue } from "generated/mos/media";
import { Codec, DecodeContext, FieldMask, RemoteObject, WebRPCError, decodeMessageRepeated, ensureBigInt, ensureScalar, stripTypePropertyDeep } from "generated/webrpc";
import jsbi from "jsbi";

export namespace AddAttachmentRequest {
  export const refName = "mos.media_management.AddAttachmentRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly mediaRef: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    readonly uploadedFilename: string,
    readonly mediaType: string,
    readonly url: string,
    readonly checksum: Checksum.Entity | undefined,
    readonly intent: AttachmentIntent,
    readonly language: Language.Entity | undefined,
    readonly fileSize: jsbi,
  }
  export const defaults: Entity = {
    type: refName,
    mediaRef: undefined,
    uploadedFilename: "",
    mediaType: "",
    url: "",
    checksum: undefined,
    intent: "MEDIA_INTENT_UNSPECIFIED",
    language: undefined,
    fileSize: jsbi.BigInt(0),
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["mediaRef"] = v.mediaRef;
      obj["uploadedFilename"] = v.uploadedFilename;
      obj["mediaType"] = v.mediaType;
      obj["url"] = v.url;
      obj["checksum"] = Checksum.codec.encode(v.checksum);
      obj["intent"] = v.intent;
      obj["language"] = Language.codec.encode(v.language);
      obj["fileSize"] = v.fileSize.toString(10);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.AddAttachmentRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["mediaRef"] = v["mediaRef"];
      out["uploadedFilename"] = ensureScalar(ctx, v["uploadedFilename"], "string");
      out["mediaType"] = ensureScalar(ctx, v["mediaType"], "string");
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["checksum"] = ctx.decode(Checksum.codec, v["checksum"], "checksum");
      {
        const ev = ensureScalar(ctx, v["intent"], "string") || defaults["intent"];
        if (!AttachmentIntentValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.AttachmentIntent`);
        }
        out["intent"] = ev as any;
      }
      out["language"] = ctx.decode(Language.codec, v["language"], "language");
      out["fileSize"] = ensureBigInt(ctx, v["fileSize"]);
      return out as any as Entity;
    }
  }();
}

export namespace AddAttachmentResponse {
  export const refName = "mos.media_management.AddAttachmentResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly attachment: Attachment.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    attachment: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["attachment"] = Attachment.codec.encode(v.attachment);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.AddAttachmentResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["attachment"] = ctx.decode(Attachment.codec, v["attachment"], "attachment");
      return out as any as Entity;
    }
  }();
}

export namespace CreateMediaRequest {
  export const refName = "mos.media_management.CreateMediaRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly title: string,
    readonly kind: MediaKind,
    readonly language: Language.Entity | undefined,
    readonly internalNotes: string,
    readonly uploadedFilename: string,
    readonly mediaType: string,
    // 'url' is required if 'remote_url' is empty
    // See mos.media.Media for more information about 'url'.
    readonly url: string,
    // 'remote_url' is required if 'url' is empty
    // See mos.media.Media for more information about 'remote_url' and how it relates to 'url'.
    readonly remoteUrl: string,
    // checksum is required if 'url' is set, and must be empty if only 'remote_url' is set.
    readonly checksum: Checksum.Entity | undefined,
    readonly attachments: ReadonlyArray<Attachment.Entity>,
    readonly tags: ReadonlyArray<TagValue.Entity>,
    // If true, ImportMedia happens when the Media is created. See the ImportMedia
    // RPC for more information.
    readonly importMedia: boolean,
    // This must be '0' if only remote_url is set.
    readonly fileSize: jsbi,
  }
  export const defaults: Entity = {
    type: refName,
    title: "",
    kind: "MEDIA_KIND_UNSPECIFIED",
    language: undefined,
    internalNotes: "",
    uploadedFilename: "",
    mediaType: "",
    url: "",
    remoteUrl: "",
    checksum: undefined,
    attachments: [],
    tags: [],
    importMedia: false,
    fileSize: jsbi.BigInt(0),
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["title"] = v.title;
      obj["kind"] = v.kind;
      obj["language"] = Language.codec.encode(v.language);
      obj["internalNotes"] = v.internalNotes;
      obj["uploadedFilename"] = v.uploadedFilename;
      obj["mediaType"] = v.mediaType;
      obj["url"] = v.url;
      obj["remoteUrl"] = v.remoteUrl;
      obj["checksum"] = Checksum.codec.encode(v.checksum);
      obj["attachments"] = [];
      for (const item of v.attachments) {
        obj["attachments"].push(Attachment.codec.encode(item));
      }
      obj["tags"] = [];
      for (const item of v.tags) {
        obj["tags"].push(TagValue.codec.encode(item));
      }
      obj["importMedia"] = v.importMedia;
      obj["fileSize"] = v.fileSize.toString(10);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.CreateMediaRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["title"] = ensureScalar(ctx, v["title"], "string");
      {
        const ev = ensureScalar(ctx, v["kind"], "string") || defaults["kind"];
        if (!MediaKindValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.MediaKind`);
        }
        out["kind"] = ev as any;
      }
      out["language"] = ctx.decode(Language.codec, v["language"], "language");
      out["internalNotes"] = ensureScalar(ctx, v["internalNotes"], "string");
      out["uploadedFilename"] = ensureScalar(ctx, v["uploadedFilename"], "string");
      out["mediaType"] = ensureScalar(ctx, v["mediaType"], "string");
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["remoteUrl"] = ensureScalar(ctx, v["remoteUrl"], "string");
      out["checksum"] = ctx.decode(Checksum.codec, v["checksum"], "checksum");
      out["attachments"] = decodeMessageRepeated(ctx, Attachment.codec, v["attachments"], "attachments");
      out["tags"] = decodeMessageRepeated(ctx, TagValue.codec, v["tags"], "tags");
      out["importMedia"] = ensureScalar(ctx, v["importMedia"], "boolean");
      out["fileSize"] = ensureBigInt(ctx, v["fileSize"]);
      return out as any as Entity;
    }
  }();
}

export namespace CreateMediaResponse {
  export const refName = "mos.media_management.CreateMediaResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly media: Media.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    media: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["media"] = Media.codec.encode(v.media);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.CreateMediaResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["media"] = ctx.decode(Media.codec, v["media"], "media");
      return out as any as Entity;
    }
  }();
}

export namespace CreateTagRequest {
  export const refName = "mos.media_management.CreateTagRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.CreateTagRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace CreateTagResponse {
  export const refName = "mos.media_management.CreateTagResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly tag: Tag.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    tag: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.CreateTagResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["tag"] = ctx.decode(Tag.codec, v["tag"], "tag");
      return out as any as Entity;
    }
  }();
}

export namespace DeleteAttachmentRequest {
  export const refName = "mos.media_management.DeleteAttachmentRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Attachment', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.DeleteAttachmentRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteAttachmentResponse {
  export const refName = "mos.media_management.DeleteAttachmentResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Attachment', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.DeleteAttachmentResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteTagRequest {
  export const refName = "mos.media_management.DeleteTagRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Tag', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.DeleteTagRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteTagResponse {
  export const refName = "mos.media_management.DeleteTagResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Tag', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.DeleteTagResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace ImportMediaRequest {
  export const refName = "mos.media_management.ImportMediaRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly mediaRef: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    mediaRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.ImportMediaRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["mediaRef"] = v["mediaRef"];
      return out as any as Entity;
    }
  }();
}

export namespace ImportMediaResponse {
  export const refName = "mos.media_management.ImportMediaResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.ImportMediaResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace UpdateMediaRequest {
  export const refName = "mos.media_management.UpdateMediaRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"ref" | "title" | "language" | "internalNotes" | "tags">,
    readonly title: string,
    readonly language: Language.Entity | undefined,
    readonly internalNotes: string,
    readonly tags: ReadonlyArray<TagValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    title: "",
    language: undefined,
    internalNotes: "",
    tags: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["title"] = v.title;
      obj["language"] = Language.codec.encode(v.language);
      obj["internalNotes"] = v.internalNotes;
      obj["tags"] = [];
      for (const item of v.tags) {
        obj["tags"].push(TagValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.UpdateMediaRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "ref" && fmv !== "title" && fmv !== "language" && fmv !== "internalNotes" && fmv !== "tags") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["title"] = ensureScalar(ctx, v["title"], "string");
      out["language"] = ctx.decode(Language.codec, v["language"], "language");
      out["internalNotes"] = ensureScalar(ctx, v["internalNotes"], "string");
      out["tags"] = decodeMessageRepeated(ctx, TagValue.codec, v["tags"], "tags");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateMediaResponse {
  export const refName = "mos.media_management.UpdateMediaResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly media: Media.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    media: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["media"] = Media.codec.encode(v.media);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.UpdateMediaResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["media"] = ctx.decode(Media.codec, v["media"], "media");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateTagRequest {
  export const refName = "mos.media_management.UpdateTagRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Tag', readonly id: string } | undefined,
    readonly name: string,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    name: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.UpdateTagRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateTagResponse {
  export const refName = "mos.media_management.UpdateTagResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly tag: Tag.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    tag: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_management.UpdateTagResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["tag"] = ctx.decode(Tag.codec, v["tag"], "tag");
      return out as any as Entity;
    }
  }();
}

