import React from 'react'

import styled from 'styled'
import { space } from 'helpers/style'

const RadioHidden = styled.input`
  /**
   * hide radio button visually but remain accessible to screen readers.
   * source: https://polished.js.org/docs/#hidevisually
  */
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const RadioStyled = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  margin: ${space(2)};
  margin-left: 0;
  border: 2px solid ${({ checked, theme }) => checked ? theme.color.primary : theme.color.gray};
  border-radius: 50px;
  ${({ checked, theme }) => checked && `
    &:after {
      content: " ";
      width: 10px;
      height: 10px;
      background-color: ${theme.color.primary};
      border-radius: 50px;
    }
  `}
`;

type Props = React.InputHTMLAttributes<HTMLInputElement>;
/**
 * Special case radio button, requires a `<label />` wrapping it to function.
 * Intended to only be useful as a flexible building block in a situation where you are managing your own state.
 */
export const RadioInputElement = (props: Props) => (
  <>
    <RadioHidden type="radio" {...props} />
    <RadioStyled checked={props.checked} />
  </>
)

// TODO when/if required: create <RadioButton /> for use in forms similar to <Checkbox />
