import React from 'react'

import styled from 'styled';
import { Button, buttonReset } from 'components/ui/button';
import { IconNavBack } from 'components/ui/icons';
import { space } from 'helpers/style'


type InitialValues = {
  // pageIndex is 0 indexed but is displayed to user as 1 indexed
  pageIndex?: number;
  pageSize?: number;
  totalItems?: number;
};

const defaultValues = {
  pageIndex: 0,
  pageSize: 20,
  totalItems: 0,
}

// usePagination is not used by <Paginator /> but they are quite coupled so in
// same file for simplicity
export const usePagination = (optionalInitialValues?: InitialValues) => {
  const initialValues = {
    ...defaultValues,
    ...optionalInitialValues,
  };
  const [pageSize, setPageSize] = React.useState(initialValues.pageSize);
  const [pageIndex, setPageIndex] = React.useState(initialValues.pageIndex);
  const [totalItems, setTotalItems] = React.useState(initialValues.totalItems);

  const totalPages = Math.ceil(totalItems / pageSize);

  const onPageChange = (newPageIndex: number) => {
    if (newPageIndex >= 0 && newPageIndex < totalPages) {
      setPageIndex(newPageIndex);
    }
  }
  const skipItems =  pageIndex * pageSize;
  const visibleRange = [
    skipItems + 1,
    Math.min(skipItems + pageSize, totalItems),
  ];

  return {
    visibleRange,
    skipItems,
    pageIndex,
    totalPages,
    pageSize,
    setPageSize,
    totalItems,
    onPageChange,
    setTotalItems,
  };
};


const PageButton = styled.button<{ isDisabled?: boolean; isSelected?: boolean }>`
  ${buttonReset};
  margin: 0 ${space(2)};
  height: ${space(10)};
  width: ${space(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  ${({ isSelected, theme }) => isSelected && `
    color: ${theme.color.primary};
    border-bottom: 1px solid ${theme.color.primary};
  `}
`;

const DisplayString = styled.span`
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 ${space(5)};
`;

const Ellipses = styled.span`
  margin: 0 ${space(2)};
  height: ${space(10)};
  width: ${space(5)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
Ellipses.defaultProps = {
  children: '...',
}

const ArrowIconButton = styled(Button)<{ isDisabled?: boolean }>`
  & svg {
    fill: ${({ theme, isDisabled }) => isDisabled ? theme.color.grayL60 : theme.color.primary};
  }
`;
ArrowIconButton.defaultProps = {
  variant: 'subtle',
  isIcon: true,
};

const PaginatorContainer = styled.div`
  align-items: center;
  box-align: center;
  display: flex;
  justify-content: flex-end;
`

type PaginationInfo = ReturnType<typeof usePagination>;
type Props = Pick<PaginationInfo, 'onPageChange' | 'totalPages' | 'pageIndex' | 'visibleRange' | 'totalItems'>;

export const Paginator = ({ onPageChange, totalPages, pageIndex, visibleRange, totalItems }: Props) => {
  const lastIndex = totalPages - 1;
  const hasPrev = pageIndex > 0;
  const hasNext = pageIndex < lastIndex;
  const displayingString = `${visibleRange[0]}-${visibleRange[1]} of ${totalItems}`;

  const pageIndexes = [
    ...new Set([0, pageIndex - 2, pageIndex - 1, pageIndex, pageIndex + 1, pageIndex + 2, lastIndex]),
  ].filter(num => num >= 0 && num <= lastIndex);

  const pageButtons: JSX.Element[] = [];
  pageIndexes.forEach((page, i) => {
    pageButtons.push(
      <PageButton
        key={page}
        onClick={() => onPageChange(page)}
        isSelected={page === pageIndex}
      >
        {page + 1}
      </PageButton>,
    );
    if (page !== lastIndex && page + 1 !== pageIndexes[i + 1]) {
      pageButtons.push(<Ellipses key={page + 1} />);
    }
  });


  return (
    <PaginatorContainer>
      <DisplayString>{displayingString}</DisplayString>
      <ArrowIconButton onClick={() => onPageChange(pageIndex - 1)} isDisabled={!hasPrev}>
        <IconNavBack size="small" />
      </ArrowIconButton>
      {pageButtons}
      <ArrowIconButton onClick={() => onPageChange(pageIndex + 1)} isDisabled={!hasNext}>
        <IconNavBack size="small" rotate={180} />
      </ArrowIconButton>
    </PaginatorContainer>
  )
}
