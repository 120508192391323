/* eslint @typescript-eslint/no-unused-vars: "error" */
import React, { Component } from 'react'

import { IconBeaconString } from 'components/ui/icons';
import { Mapbox, MapView } from 'components/mapbox';
import { MapInitialBounds } from 'components/mapbox/core';
import { NavigationControlSet } from 'components/mapsets/navigation-control';
import { PointClusterSet } from 'components/mapsets/point-cluster';
import { OnPointMoved, PointMarker, PointMarkerSet } from 'components/mapsets/point-marker';
import { Beacon } from 'generated/mos/beacon'
import { Site } from 'generated/mos/structure';
import { POI } from 'generated/mos/poi'
import { isNotNullOrUndefined } from 'helpers/core';
import * as geo from 'helpers/geo';
import { firstPlacedPointFromEntity } from 'helpers/locate'

import { isLevelRef, StructureRef, buildAdminMapCorners, Structures } from 'domains/structures/entities';
import { AdminMapSet, BoundarySet } from 'domains/structures/mapsets';
import { structuresSelector } from 'domains/structures/selectors';

export type PointEntity = Beacon.Entity | POI.Entity;
type PointList = ReadonlyArray<PointEntity>;

export type PointRef = Beacon.Ref | POI.Ref;

type Props = {
  readonly mapView: MapView;
  readonly onMapViewChanged?: (e: MapView) => void;

  readonly pointList?: PointList;
  readonly activePointRef?: PointRef;
  readonly canPositionActive?: boolean;
  readonly structures?: Structures;
  readonly structureRef?: StructureRef;
  readonly onPointMoved?: OnPointMoved;
  readonly initialBounds?: MapInitialBounds | undefined;
};

export class ClusterMap extends Component<Props> {
  public render() {
    const pointsList = this.props.pointList || [];
    const { structureRef, structures } = this.props;

    const points = geo.filter((pointsList as PointList).map(entity => {
        const placedPoint = firstPlacedPointFromEntity(entity)
        return geo.mustPoint2D(placedPoint!.point)
      }
    ));

    const structureBoundaries = structures ? selectBoundaries(structures, this.props.structureRef) : [];

    const pointIds = pointsList.map((v: PointEntity) => v.ref!.id);

    const adminMapSet = (() => {
      if (!structures || !structureRef) {
        return;
      }
      const adminMap = structuresSelector.selectAdminMapByRef(structures, structureRef);
      const corners = adminMap ? buildAdminMapCorners(adminMap) : undefined;
      if (adminMap && corners) {
        return new AdminMapSet('adminmap', adminMap.imageUrl, geo.mustCorners(corners))
      }
    })();

    const mapSets = [
      adminMapSet,
      // FIXME: unstable data identity
      new BoundarySet({ key: 'structures', boundaries: structureBoundaries }),
      !isLevelRef(structureRef)
        ? new PointClusterSet('points', pointIds, points)
        : new PointMarkerSet('point-markers', pointMarkers(pointsList), {
          active: this.props.activePointRef,
          canPosition: this.props.canPositionActive,
          onMoved: (e) => { if (this.props.onPointMoved) { this.props.onPointMoved(e); } },
        }),
      new NavigationControlSet({ showCompass: false }),
    ];

    return (
      <Mapbox
        view={this.props.mapView}
        onViewChanged={this.props.onMapViewChanged}
        mapSets={mapSets}
        initialBounds={this.props.initialBounds}
      />
    );
  }
}

function pointMarkers(pointsList: PointList): ReadonlyArray<PointMarker> {
  return pointsList
    .map((entity: PointEntity): PointMarker => {
      const placedPoint = firstPlacedPointFromEntity(entity)
      return {
        title: entity.name,
        // FIXME: assemble description from levels data once structures sagas are developed.
        description: '', // v.description
        icon: IconBeaconString, // FIXME: switch based on entity type, once poi icon is available.
        ref: entity.ref,
        position: placedPoint && geo.mustPoint2D(placedPoint.point),
      }
    });
}

function selectBoundaries(structures: Structures, ref?: StructureRef): ReadonlyArray<geo.Polygon2D> {
  if (!ref) {
    return [...structures.sites, ...structures.buildings]
      .filter(isNotNullOrUndefined)
      .map((v) => v.boundary)
      .filter(isNotNullOrUndefined);
  } else if (Site.isRef(ref)) {
    const site = structuresSelector.findSiteByRef(structures, ref);
    if (site === undefined) return [];

    return geo.filter(
      structures
        .buildings
        .filter(b => b.siteRef && b.siteRef.id === ref.id)
        .map(v => geo.optionalPolygon2D(v.boundary)),
    );
  }
  
  const boundary = structuresSelector.selectBoundaryByRef(structures, ref);
  return boundary ? [boundary] : [];
}
