/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaVideo = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 13l-4-3.2V16H6V8h8v3.2L18 8v8z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaVideoString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 13l-4-3.2V16H6V8h8v3.2L18 8v8z"  fillRule="nonzero" />
  </svg>`

IconMediaVideo.displayName = 'IconMediaVideo'
