import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button, ButtonGroup } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { connect } from 'containers/store';
import { Site } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import { Status } from 'helpers/status';

import { structuresActionCreators } from '../../actions';
import { StructuresAppState } from 'domains/structures/store';


type DirectProps = {
  readonly onSuccess: (ref: Site.Ref) => void;
};

type ActionProps = Pick<typeof structuresActionCreators, 'siteCreateReset' | 'siteCreateSaveRequest'>;

type ConnectedProps = Pick<StructuresAppState, 'siteCreate'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type FormValues = {
  readonly name: string;
};

type State = {
  readonly bag: FormBag<FormValues>;
};

class SiteCreateFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ name: '' }),
    };
  }

  public componentDidMount() {
    this.props.siteCreateReset();
  }

  public componentDidUpdate() {
    if (this.props.siteCreate.status === Status.Ready) {
      this.props.onSuccess(this.props.siteCreate.data);
    }
  }

  private onSubmit = () => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    this.props.siteCreateSaveRequest({
      name: bag.values.name,
    });
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    return errors;
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public render() {
    const { errors, valid, touched } = this.state.bag;
    const loading =
      this.props.siteCreate.status === Status.Updating ||
      // Ready is when the form is complete, and the modal will be closed
      this.props.siteCreate.status === Status.Ready;

    return (
      <form noValidate onSubmit={(e) => { e.preventDefault(); this.onSubmit(); }}>
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
          <Input
            label="Name"
            field="name"
            error={touched.name ? errors.name : undefined}
          />
          <ButtonGroup>
            <Button type="submit" isDisabled={!valid || loading}>
              {loading ? 'Creating...' : 'Create site'}
            </Button>
          </ButtonGroup>
        </FormData>
      </form>
    );
  }
}

export const SiteCreateForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store: {structures: StructuresAppState}) => ({ siteCreate: store.structures.siteCreate }),
  pick(structuresActionCreators, 'siteCreateReset', 'siteCreateSaveRequest'),
)(SiteCreateFormView);
