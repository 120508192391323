// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import * as geo from 'helpers/geo';
import JSBI from 'jsbi';

export type FieldMask<TField extends string> = {
  readonly type: "google.protobuf.FieldMask",
  readonly fields: ReadonlyArray<TField>,
};

export type Timestamp = {
  readonly type: "google.protobuf.Timestamp",

  // RFC3339-encoded timestamp value, in UTC (Z):
  // https://github.com/protocolbuffers/protobuf/blob/master/src/google/protobuf/timestamp.proto#L99
  //
  // Use Date.toISOString to convert to a Date.
  readonly value: string,
};

type PathSegment = string | number;

export class WebRPCError extends Error {}

export class DecodeContext {
  private _path: PathSegment[];
  public constructor(name: string) {
    this._path = [name];
  }
  public push(...parts: PathSegment[]): () => void {
    this._path.push(...parts);
    return () => this.pop(parts.length);
  }
  public pop(n: number = 1): DecodeContext {
    for (let i = 0; i < n; i++) {
      this._path.pop();
    }
    return this;
  }
  public decode<TEntity>(codec: Codec<TEntity>, v: RemoteObject, ...path: PathSegment[]): TEntity;
  public decode<TEntity>(codec: Codec<TEntity>, v: RemoteObject | null, ...path: PathSegment[]): TEntity | undefined {
    for (const part in path) this.push(part);
    const out = codec.decode(v);
    for (const part in path) this.pop();
    return out;
  }
  public expected(typeName: string, value: any, ...extraPath: PathSegment[]): WebRPCError {
    return this.error(`expected ${typeName}, found ${typeof(value)}`, ...extraPath);
  }
  public error(message: string, ...extraPath: PathSegment[]): WebRPCError {
    if (!Array.isArray(extraPath)) {
      extraPath = [extraPath+""];
    }
    const path = this._path.join(".") + (extraPath ? "."+extraPath.join(".") : "");
    return new WebRPCError(`decode failed at path ${path}: ${message}`);
  }
}

export type RemoteObject = { [key: string]: any };

export type Encoder<TEntity> = {
  (v: TEntity): RemoteObject;
  (v: TEntity | undefined): RemoteObject | null;
};

export type Decoder<TEntity> = {
  (v: RemoteObject, ctx?: DecodeContext): TEntity;
  (v: RemoteObject | null, ctx?: DecodeContext): TEntity | undefined;
};

export type Codec<TEntity> = {
  encode(v: TEntity): RemoteObject;
  encode(v: TEntity | undefined): RemoteObject | null;
  decode(v: RemoteObject, ctx?: DecodeContext): TEntity;
  decode(v: RemoteObject | null, ctx?: DecodeContext): TEntity | undefined;
};

export type AnyMessage = {
  "@type": string,
  [key: string]: any,
};

// {{{ Geo Jank!

// XXX: These do not choke when they receive unknown keys; this is how protobuf works but
// is it what we actually want here?

export function decodeMOSPoint(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.Point2D | undefined {
  if (!p) {
    return undefined;
  }
  if (typeof(p["longitude"]) !== "number" || isNaN(p["longitude"]) || !isFinite(p["longitude"])) {
    throw ctx.error(`expected number, found ${typeof(p["longitude"])}`, "longitude");
  }
  if (typeof(p["latitude"]) !== "number" || isNaN(p["latitude"]) || !isFinite(p["latitude"])) {
    throw ctx.error(`expected number, found ${typeof(p["latitude"])}`, "latitude");
  }
  return { type: "Point", coordinates: [p["longitude"], p["latitude"]] };
}

function decodeMOSCoordinateList(ctx: DecodeContext, p: RemoteObject | null | undefined): ReadonlyArray<geo.Position2D> {
  if (!p || p["values"] === null || p["values"] === undefined) {
    return [];
  }
  const vals = p["values"];
  if (!Array.isArray(vals)) {
    throw ctx.error(`expected array, found ${typeof(p["values"])}`, "values");
  }
  if (vals.length % 2 != 0) {
    throw ctx.error(`length must be even`, "values");
  }
  const coordinates: geo.Position2D[] = [];
  for (let i = 0; i < vals.length; i += 2) {
    const longitude = vals[i];
    const latitude = vals[i+1];
    if (typeof(longitude) !== "number" || isNaN(longitude) || !isFinite(longitude)) {
      throw ctx.expected("number", p["longitude"], "longitude");
    }
    if (typeof(latitude) !== "number" || isNaN(latitude) || !isFinite(latitude)) {
      throw ctx.expected("number", p["latitude"], "latitude");
    }
    coordinates.push([longitude, latitude]);
  }
  return coordinates;
}

function decodeMOSCoordinateList2D(ctx: DecodeContext, p: RemoteObject | null | undefined): ReadonlyArray<ReadonlyArray<geo.Position2D>> {
  if (!p || p["values"] === null || p["values"] === undefined) {
    return [];
  }
  const vals = p["values"];
  if (!Array.isArray(vals)) {
    throw ctx.expected("array", p["values"], "values");
  }
  const coordinates: Array<ReadonlyArray<geo.Position2D>> = [];
  for (let i = 0; i < p["values"].length; i++) {
    if (typeof(p["values"][i]) !== "object") {
      throw ctx.expected("object", p["values"][i], "values", i);
    }
    coordinates.push(decodeMOSCoordinateList(ctx, p["values"][i]));
  }
  return coordinates;
}

function decodeMOSCoordinateList3D(ctx: DecodeContext, p: RemoteObject | null | undefined): ReadonlyArray<ReadonlyArray<ReadonlyArray<geo.Position2D>>> {
  if (!p || p["values"] === null || p["values"] === undefined) {
    return [];
  }
  const vals = p["values"];
  if (!Array.isArray(vals)) {
    throw ctx.expected("array", p["values"], "values");
  }
  const coordinates: Array<ReadonlyArray<ReadonlyArray<geo.Position2D>>> = [];
  for (let i = 0; i < p["values"].length; i++) {
    if (typeof(p["values"][i]) !== "object") {
      throw ctx.expected("object", p["values"][i], "values", i);
    }
    coordinates.push(decodeMOSCoordinateList2D(ctx, p["values"][i]));
  }
  return coordinates;
}

export function decodeMOSMultiPoint(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.MultiPoint2D | undefined {
  if (!p || p["coordinates"] === null || p["coordinates"] === undefined) {
    return undefined;
  }
  if (typeof(p["coordinates"]) !== "object") {
    throw ctx.expected("object", p["coordinates"], "coordinates");
  }
  return { type: "MultiPoint", coordinates: decodeMOSCoordinateList(ctx, p["coordinates"]) };
}

export function decodeMOSLineString(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.LineString2D | undefined {
  if (!p || p["coordinates"] === null || p["coordinates"] === undefined) {
    return undefined;
  }
  if (typeof(p["coordinates"]) !== "object") {
    throw ctx.expected("object", p["coordinates"], "coordinates");
  }
  return { type: "LineString", coordinates: decodeMOSCoordinateList(ctx, p["coordinates"]) };
}

export function decodeMOSPolygon(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.Polygon2D | undefined {
  if (!p || p["coordinates"] === null || p["coordinates"] === undefined) {
    return undefined;
  }
  if (typeof(p["coordinates"]) !== "object") {
    throw ctx.expected("object", p["coordinates"], "coordinates");
  }
  return { type: "Polygon", coordinates: decodeMOSCoordinateList2D(ctx, p["coordinates"]) };
}

export function decodeMOSMultiLineString(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.MultiLineString2D | undefined {
  if (!p || p["coordinates"] === null || p["coordinates"] === undefined) {
    return undefined;
  }
  if (typeof(p["coordinates"]) !== "object") {
    throw ctx.expected("object", p["coordinates"], "coordinates");
  }
  return { type: "MultiLineString", coordinates: decodeMOSCoordinateList2D(ctx, p["coordinates"]) };
}

export function decodeMOSMultiPolygon(ctx: DecodeContext, p: RemoteObject | null | undefined): geo.MultiPolygon2D | undefined {
  if (!p || p["coordinates"] === null || p["coordinates"] === undefined) {
    return undefined;
  }
  if (typeof(p["coordinates"]) !== "object") {
    throw ctx.expected("object", p["coordinates"], "coordinates");
  }
  return { type: "MultiPolygon", coordinates: decodeMOSCoordinateList3D(ctx, p["coordinates"]) };
}

export function encodeMOSPoint(v: geo.Point2D | undefined): RemoteObject | null {
  if (!v) {
    return null;
  }
  return { longitude: v.coordinates[0], latitude: v.coordinates[1] };
}

export function encodeMOSMultiPoint(v: geo.MultiPoint2D | geo.LineString2D | undefined): RemoteObject | null {
  if (!v) {
    return null;
  }
  const values: number[] = [];
  for (const p of v.coordinates) {
    values.push(p[0], p[1]);
  }
  return {"coordinates": {"values": values}};
}

export function encodeMOSPolygon(v: geo.Polygon2D | geo.MultiLineString2D | undefined): RemoteObject | null {
  if (!v) {
    return null;
  }
  const linesOut: any[] = [];
  for (const line of v.coordinates) {
    const lineOut: number[] = [];
    for (const point of line) {
      lineOut.push(point[0], point[1]);
    }
    linesOut.push({"values": lineOut});
  }
  return {"coordinates": {"values": linesOut}};
}

export function encodeMOSMultiPolygon(v: geo.MultiPolygon2D | undefined): RemoteObject | null {
  if (!v) {
    return null;
  }
  const polysOut: any[] = [];
  for (const poly of v.coordinates) {
    const linesOut: any[] = [];
    for (const line of poly) {
      const lineOut: number[] = [];
      for (const point of line) {
        lineOut.push(point[0], point[1]);
      }
      linesOut.push({"values": lineOut});
    }
    polysOut.push({"values": linesOut});
  }
  return {"coordinates": {"values": polysOut}};
}

// }}} Geo jank

// {{{ Encode/decode helpers

type ScalarFromString<T> = 
    T extends 'string'  ? string : 
    T extends 'number'  ? number : 
    T extends 'boolean' ? boolean :
    never;

const scalarDefaults = {
  'string': '',
  'boolean': false,
  'number': 0,
};

export function ensureScalar<T extends 'string' | 'boolean' | 'number'>(ctx: DecodeContext, v: any, kind: T): ScalarFromString<T> {
  if (v === undefined) { // Backend will not return a value if there is none
    return scalarDefaults[kind] as any;
  }
  if (typeof v !== kind) {
    throw ctx.expected(kind, v);
  }
  return v;
};

export function ensureScalarOptional<T extends 'string' | 'boolean' | 'number'>(ctx: DecodeContext, v: any, kind: T): ScalarFromString<T> | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  if (typeof v !== kind) {
    throw ctx.expected(kind, v);
  }
  return v;
};

export function ensureScalarRepeated<T extends 'string' | 'boolean' | 'number'>(ctx: DecodeContext, v: any, kind: T): ReadonlyArray<ScalarFromString<T>> {
  if (v === undefined) {
    return [];
  }
  if (!Array.isArray(v)) {
    throw ctx.expected(kind+"[]", v);
  }
  for (const i of v) {
    if (typeof i !== kind) {
      throw ctx.expected(kind+"[]", v, i);
    }
  }
  return v;
};

export function ensureBigInt(ctx: DecodeContext, v: any): JSBI {
  if (v === undefined) { // The backend may not return key in an object if the value is the zero-value
    return JSBI.BigInt(0);
  }
  if (typeof v !== "string") { // Protobuf canonicalises 64-bit values to strings:
    throw ctx.expected("string(bigint)", v);
  }
  return JSBI.BigInt(v);
};

export function ensureBigIntOptional(ctx: DecodeContext, v: any): JSBI | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  if (typeof v !== "string") { // Protobuf canonicalises 64-bit values to strings:
    throw ctx.expected("string(bigint)", v);
  }
  return JSBI.BigInt(v);
};

export function ensureBigIntRepeated(ctx: DecodeContext, v: any): ReadonlyArray<JSBI> {
  if (v === undefined) {
    return [];
  }
  if (!Array.isArray(v)) {
    throw ctx.expected("string[]", v);
  }
  const out = [];
  for (const i of v) {
    if (typeof i !== "string") {
      throw ctx.expected("string[]", v, i);
    }
    out.push(JSBI.BigInt(i));
  }
  return out;
};

export function decodeMessageRepeated<T>(ctx: DecodeContext, codec: Codec<T>, vs: any, ...path: PathSegment[]): ReadonlyArray<T> {
  if (vs === undefined) { // Backend will not return a value if there is none
    return [];
  }
  if (!Array.isArray(vs)) {
    throw ctx.expected("array", vs, ...path);
  }

  const pop = ctx.push(...path);
  try {
    const out = [];
    for (let i = 0; i < vs.length; i++) {
      const item = vs[i];
      if (typeof item !== "object") {
        throw ctx.expected("object", item, i);
      }
      out.push(ctx.decode(codec, item, i));
    }
    return out;

  } finally {
    pop();
  }
}

export function stripTypePropertyDeep(input: object): object {
  return stripTypeDeep(input);
}

function stripTypeDeep(input: any): any {
  if (input === null || input === undefined)  {
    return input;

  } else if (Array.isArray(input)) {
    const out: any = [];
    for (const item of input) {
      out.push(stripTypeDeep(item));
    }
    return out;

  } else if (typeof(input) === "object") {
    const out: any = {};
    for (const key in input) {
      if (key === "type") {
        continue;
      }
      out[key] = stripTypeDeep(input[key]);
    }
    return out;

  } else {
    return input;
  }
}

// }}} Encode/decode helpers

