import React from 'react'

import styled, { keyframes } from 'styled';

const pulse = keyframes`
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
    background: white;
  }
  100% {
    transform: scale(0.5);
  }
`;

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
`;

const LoadingDot = styled.div`
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  margin: 0;
  height: 10px;
  width: 10px;
  border: 5px solid ${({ theme }) => theme.color.primary};
  border-radius: 100%;
  transform: transformZ(0);
  animation: ${pulse} 2s infinite;
`;

const LeftLoadingDot = styled(LoadingDot)`
  left: -20px;
  animation-delay: 0s;
`;

const MiddleLoadingDot = styled(LoadingDot)`
  left: 0px;
  animation-delay: 0.2s;
`;

const RightLoadingDot = styled(LoadingDot)`
  left: 20px;
  animation-delay: 0.4s;
`;

type Props = {}

export const Loader = (props: Props) => {
  return (
    <Wrapper {...props}>
      <LeftLoadingDot />
      <MiddleLoadingDot />
      <RightLoadingDot />
    </Wrapper>
  );
};
