import { SourceActions, SourceDef } from './sources';
import { identityEqual, objectKeys } from './util';


export type LayerDef = Pick<mapboxgl.Layer, Exclude<keyof mapboxgl.Layer, 'source'>> & {
  // 'dataIdentity' is used to detect when the layer has changed. If dataIdentity is not
  // passed, a shallow comparison of all RawLayer properties except 'source' is performed.
  //
  // Please, please, please read the function documentation for identityEqual in ./util.ts
  // for a more thorough treatment of the dataIdentity concept.
  readonly dataIdentity?: any[];

  // SourceDef objects may be shared between RawLayers; they will be
  // de-duplicated when resolved.
  readonly source: SourceDef;
};


// Clean up our layer defs to work with mapbox by fixing properties we have changed
// for our own purposes.
function prepareLayer(def: LayerDef): mapboxgl.Layer {
  // XXX: 'any' is used here so we can be indiscriminate for convenience sake;
  // this is not a good pattern to emulate.
  return {...def, source: def.source.id };
}

type LayerRemove = { type: 'remove'; layerId: string };
type LayerAdd = { type: 'add'; layerId: string; before: string | undefined };
type LayerMove = { type: 'move'; layerId: string; before: string | undefined };
type LayerUpdate = { type: 'update'; id: string; update(map: LayerMutator): void };

export interface LayerActions {
  readonly changed: boolean;
  readonly removes: ReadonlyArray<LayerRemove>;
  readonly moves: ReadonlyArray<LayerMove>;
  readonly adds: ReadonlyArray<LayerAdd>;
  readonly updates: ReadonlyArray<LayerUpdate>;
}


export interface LayerDataIndex {
  readonly layers: LayerDef[];
  readonly layerIds: string[];
  readonly layersById: { [key: string]: LayerDef };
  readonly layerZByLayerId: { [key: string]: number };
}


function calculatePropertyBagChanges(oldBag: object | undefined, newBag: object | undefined): Array<[string, any]> {
  const updates: Array<[string, any]> = [];

  if (oldBag) {
    for (const key of objectKeys(oldBag)) {
      const oldValue = oldBag ? oldBag[key] : undefined;
      const newValue = newBag ? newBag[key] : undefined;

      const oldType = typeof oldValue;
      const newType = typeof newValue;

      if (oldType !== newType) {
        updates.push([key, newValue]);

      } else {
        // XXX: the JSON.stringify here is needed because a lot of the mapbox paint/layout
        // properties are really complex, deeply nested objects (like the Expression syntax,
        // for example). It's impractical to do a recursive diff on those whole structures,
        // and it's highly unlikely that this will become a performance bottleneck; chrome's
        // profiler shows 0.15ms for this function in a primitive test, which is dwarfed by
        // basically anything React does... React destroys 10ms if it so much as sneezes.
        const oldCompare = typeof oldValue === 'object' && oldValue !== null
          ? JSON.stringify(oldValue)
          : oldValue;
        const newCompare = typeof newValue === 'object' && newValue !== null
          ? JSON.stringify(newValue)
          : newValue;

        if (oldCompare !== newCompare) {
          updates.push([key, newValue]);
        }
      }
    }
  }

  if (newBag) {
    for (const key of objectKeys(newBag)) {
      if (oldBag && key in oldBag) {
        continue;
      }
      updates.push([key, newBag[key]]);
    }
  }

  return updates;
}


function compareLayerDefs(oldLayer: LayerDef, newLayer: LayerDef): ((map: mapboxgl.Map) => void) | undefined {
  const paintUpdates = calculatePropertyBagChanges(oldLayer.paint, newLayer.paint);
  const layoutUpdates = calculatePropertyBagChanges(oldLayer.layout, newLayer.layout);

  if (paintUpdates || layoutUpdates) {
    return (map: mapboxgl.Map) => {
      for (const paintUpdate of paintUpdates) {
        // FIXME: Should we pass the 'validate' option or not? See docs in here for more
        // details: https://docs.mapbox.com/mapbox-gl-js/api/#map#setpaintproperty
        map.setPaintProperty(oldLayer.id, paintUpdate[0], paintUpdate[1]);
      }

      for (const layoutUpdate of layoutUpdates) {
        map.setLayoutProperty(oldLayer.id, layoutUpdate[0], layoutUpdate[1]);
      }
    };
  }

  return;
}


// Compare the list of layers in the old state to the list of layers in the new
// state and compute the minimal series of changes to apply. See LayerResolver
// for the code to apply these changes.
export function computeLayerChanges(sourceActions: SourceActions, currentIndex: LayerDataIndex, newIndex: LayerDataIndex): LayerActions {
  const layerAdds: LayerAdd[] = [];
  const layerMoves: LayerMove[] = [];
  const layerRemoves: LayerRemove[] = [];
  const layerUpdates: LayerUpdate[] = [];

  // FIXME: extract into MapDataIndex
  const targetNext: { [key: string]: string } = {};
  let last: string | undefined;
  for (const targetId of newIndex.layerIds) {
    if (last !== undefined) {
      targetNext[last] = targetId;
    }
    last = targetId;
  }

  // Keep a running view of the state of the layers after each action so each
  // subsequent action can be based on the previous state, not the initial state.
  const layerCalc = new LayerCalc(currentIndex.layerIds, newIndex.layerIds, targetNext);

  const currentLayers = [...currentIndex.layers].reverse();
  for (const currentLayer of currentLayers) {
    const newLayer = newIndex.layersById[currentLayer.id];
    if (!newLayer) {
      layerRemoves.push(layerCalc.remove(currentLayer.id));
      continue;
    }

    const newIdx = newIndex.layerZByLayerId[newLayer.id]!;
    const before = newIndex.layerIds[newIdx + 1];

    const sourceAction = sourceActions.actions[newLayer.source.id];
    const sourceWasReplaced = sourceAction && sourceAction.resolution === 'replace';

    if (sourceWasReplaced || !identityEqual(currentLayer.dataIdentity, newLayer.dataIdentity)) {
      layerRemoves.push(layerCalc.remove(currentLayer.id));
      layerAdds.push(layerCalc.add(currentLayer.id, before));

    } else {
      // Layer is in both old and new, calculate any updates. This is a
      // separate process to calculating the moves, it is used for things like
      // setPaintProperty().
      const update = compareLayerDefs(currentLayer, newLayer);
      if (update) {
        layerUpdates.push({ type: 'update', id: currentLayer.id, update });
      }
    }
  }

  for (const newLayer of newIndex.layers) {
    if (newLayer.id in currentIndex.layersById) {
      continue;
    }
    const newIdx = newIndex.layerZByLayerId[newLayer.id]!;
    const before = newIndex.layerIds[newIdx + 1];
    layerAdds.push(layerCalc.add(newLayer.id, before));
  }

  // All layers in newIndex should be added to layerCalc by now;
  // we can compute the moves:
  if (newIndex.layerIds) {
    for (let newIdx = newIndex.layerIds.length - 1; newIdx >= 0; newIdx--) {
      const moveLayerId = newIndex.layerIds[newIdx];
      const currentIdx = layerCalc.indexOf(moveLayerId);
      if (currentIdx < 0) {
        throw new Error();
      }
      if (currentIdx !== newIdx) {
        const before = targetNext[moveLayerId];
        const move = layerCalc.move(moveLayerId, before);
        if (move) {
          layerMoves.push(move);
        }
      }
    }
  }

  return {
    changed: layerAdds.length + layerMoves.length + layerRemoves.length > 0,
    adds: layerAdds,
    moves: layerMoves,
    removes: layerRemoves,
    updates: layerUpdates,
  };
}


// LayerMutator represents the subset of layer actions present on mapboxgl.Map required by LayerCalc:
// export type LayerMutator = Pick<mapboxgl.Map, 'addLayer' | 'moveLayer' |  'removeLayer'>;
// We don't use the above 'Pick' in order to mask return types.
//
// This is to facilitate testing.
export interface LayerMutator {
  addLayer(layer: mapboxgl.Layer, before?: string): void;
  moveLayer(id: string, beforeId?: string): void;
  removeLayer(id: string): void;
  setPaintProperty(layer: string, name: string, value: any): void;
}


export class LayerResolver {
  private map: LayerMutator;
  private expectedLayers: { [key: string]: LayerDef };

  public constructor(map: LayerMutator, expectedLayers: { [key: string]: LayerDef }) {
    this.map = map;
    this.expectedLayers = expectedLayers;
  }

  public buildUp(actions: LayerActions) {
    for (const change of actions.adds) {
      const layer = this.expectedLayers[change.layerId];
      if (!layer) {
        throw new Error(`layer ${change.layerId} not found in expected layers: [${Object.keys(this.expectedLayers).join(', ')}]`);
      }
      this.map.addLayer(prepareLayer(layer), change.before);
    }

    for (const move of actions.moves) {
      this.map.moveLayer(move.layerId, move.before);
    }

    for (const update of actions.updates) {
      update.update(this.map);
    }
  }

  public knockDown(actions: LayerActions) {
    for (const remove of actions.removes) {
      this.map.removeLayer(remove.layerId);
    }
  }
}


// Mapbox doesn't provide methods for querying the current list of layers or their
// current ordering. It only provides the ability to add a layer before another layer, or
// to move a layer before another layer:
//
//  - addLayer(layer, before?)
//  - moveLayer(layer, before?)
//  - removeLayer(layer)
//
// This simulates the add/move/remove calls of mapbox and maintains that view so we can
// compute the correct series of calls to the above methods to reconcile the layer list.
//
class LayerCalc {
  private idOrder: string[];
  private idSet: Set<string>;
  private targetNext: { [key: string]: string } = {};

  public constructor(currentIds: string[], targetIds: string[], targetNext: { [key: string]: string }) {
    this.idOrder = [...currentIds];
    this.idSet = new Set(this.idOrder);
    this.targetNext = targetNext;
  }

  public get length(): number {
    return this.idOrder.length;
  }

  public get ids(): ReadonlyArray<string> {
    return this.idOrder;
  }

  public has(id: string): boolean {
    return this.idSet.has(id);
  }

  public indexOf(id: string): number {
    return this.ids.indexOf(id);
  }

  public move(id: string, before: string | undefined): LayerMove | undefined {
    if (this.idOrder.length > 1) {
      this.remove(id);
      const add = this.add(id, before);
      return { type: 'move', layerId: id, before: add.before };
    }
    return;
  }

  public remove(id: string): LayerRemove {
    const cur = this.idOrder.indexOf(id);
    if (cur < 0) {
      throw new Error();
    }
    this.idOrder.splice(cur, 1);
    this.idSet.delete(id);
    return { type: 'remove', layerId: id };
  }

  public add(id: string, before: string | undefined): LayerAdd {
    this.idSet.add(id);

    if (!before || this.idOrder.length === 0) {
      this.idOrder.push(id);
      before = undefined; // mapbox will complain if 'before' doesn't exist

    } else {
      // The layer we are adding may need to be added before a layer that
      // doesn't exist yet. Walk forward through the list of layers until we
      // find one that does exist:
      while (before && !this.idSet.has(before)) {
        before = this.targetNext[before];
      }

      if (before) {
        const index = this.idOrder.indexOf(before);
        if (index < 0) {
          throw new Error();
        }
        this.idOrder.splice(index, 0, id);
      } else {
        this.idOrder.push(id);
      }
    }

    return { type: 'add', layerId: id, before };
  }
}

