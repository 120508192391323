import { LayerDef, MapSet, SourceDef } from 'components/mapbox';
import { ImageSourceDef } from 'components/mapbox/sources';
import * as geo from 'helpers/geo';
import * as mapStyles from 'helpers/map-styles';


export type BoundarySetOptions = {
  readonly key: string;
  readonly boundaries: ReadonlyArray<geo.Polygon2D>;
  readonly color?: string;
};

export class BoundarySet implements MapSet {
  private _layers: LayerDef[];

  public readonly key: string;

  public constructor(opts: BoundarySetOptions) {
    this.key = opts.key;

    const source: SourceDef = {
      id: opts.key,
      dataIdentity: undefined,
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: geo.mut(geo.asFeatures2D(opts.boundaries)),
      },
    };

    this._layers = [
      {
        id: `${opts.key}/boundaries`,
        source: source,
        type: 'fill',
        paint: mapStyles.boundaryPaint({ boundaryColor: opts.color }),
      },
    ];
  }

  public layers(): LayerDef[] { return this._layers; }
}


export class AdminMapSet implements MapSet {
  private _layers: LayerDef[];

  public readonly key: string;

  public constructor(key: string, imageUrl: string, corners: geo.Corners) {
    this.key = key;

    const source: ImageSourceDef = {
      type: 'image',
      id: key,
      url: imageUrl,
      coordinates: corners, // FIXME: geo-jank: mutable corners
    };

    this._layers = [
      {
        id: key,
        dataIdentity: [imageUrl, ...corners],
        source: source,
        type: 'raster',
        paint: {
          'raster-opacity': 0.6,
          'raster-opacity-transition': {
            duration: 0,
          },
        },
      },
    ];
  }

  public layers(): LayerDef[] { return this._layers; }
}
