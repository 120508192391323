import { Effect, put, takeEvery, takeLeading, takeLatest } from 'redux-saga/effects'

import { Media } from 'generated/mos/media'
import {
  GetMediaRequest,
  GetMediaResponse,
  ListMediaRequest,
  ListMediaResponse,
} from 'generated/mos/mediaaccess'
import {
  CreateMediaRequest,
  CreateMediaResponse,
  UpdateMediaRequest,
  UpdateMediaResponse,
  AddAttachmentRequest,
  AddAttachmentResponse,
  DeleteAttachmentRequest,
  DeleteAttachmentResponse,
} from 'generated/mos/mediamanagement'
import { unaryGRPC } from 'services/unary-grpc'
import { failureStatusMessage } from 'helpers/status'

import { MediaActions, actionCreators } from './actions'

const mediaTakeEvery = <TActionKey extends keyof MediaActions>(
  pattern: TActionKey,
  worker: (action: MediaActions[TActionKey]) => any,
) => {
  return takeEvery(pattern, worker);
}

const mediaTakeLeading = <TActionKey extends keyof MediaActions>(
  pattern: TActionKey,
  worker: (action: MediaActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
}

// used in searching to ensure the latest search term is used
const mediaTakeLatest = <TActionKey extends keyof MediaActions>(
  pattern: TActionKey,
  worker: (action: MediaActions[TActionKey]) => any,
) => {
  return takeLatest(pattern, worker);
}

const getMediaSaga = mediaTakeEvery(
  'mediaGetMediaRequest',
  function* (action: MediaActions['mediaGetMediaRequest']) {
    const { payload, ref } = action
    try {
      const response: GetMediaResponse.Entity = yield* unaryGRPC(
        'mos.media_access.MediaAccess/GetMedia',
        payload,
        GetMediaRequest.codec,
        GetMediaResponse.codec,
      )

      yield put(actionCreators.mediaGetMediaSuccess(ref, response))

    } catch (error) {
      yield put(actionCreators.mediaGetMediaFailure(
        ref,
        failureStatusMessage(error.message)
      ))
    }
  }
)

// using takeLatest as this saga is only used when searching media in media picker
const listMediaSaga = mediaTakeLatest(
  'mediaListMediaRequest',
  function* (action: MediaActions['mediaListMediaRequest']) {

    try {
      const { payload } = action
      const response: ListMediaResponse.Entity = yield* unaryGRPC(
        'mos.media_access.MediaAccess/ListMedia',
        payload,
        ListMediaRequest.codec,
        ListMediaResponse.codec,
      )

      yield put(actionCreators.mediaListMediaSuccess(response))

    } catch (error) {
      yield put(actionCreators.mediaListMediaFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

const createMediaSaga = mediaTakeLeading(
  'mediaCreateMediaRequest',
  function* (action: MediaActions['mediaCreateMediaRequest']) {

    try {
      const { payload } = action
      const response: CreateMediaResponse.Entity = yield* unaryGRPC(
        'mos.media_management.MediaManagement/CreateMedia',
        payload,
        CreateMediaRequest.codec,
        CreateMediaResponse.codec,
      )

      if (response && response.media && Media.mustRef(response.media.ref)) {
        yield put(actionCreators.mediaCreateMediaSuccess(response.media))
      }

    } catch (error) {
      yield put(actionCreators.mediaCreateMediaFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

const updateMediaSaga = mediaTakeLeading(
  'mediaUpdateMediaRequest',
  function* (action: MediaActions['mediaUpdateMediaRequest']) {

    try {
      const { payload } = action
      const response: UpdateMediaResponse.Entity = yield* unaryGRPC(
        'mos.media_management.MediaManagement/UpdateMedia',
        {
          ...payload,
          type: UpdateMediaRequest.refName,
          updateMask: {
            type: 'google.protobuf.FieldMask',
            fields: ['title', 'internalNotes', 'language', 'tags'],
          }
        },
        UpdateMediaRequest.codec,
        UpdateMediaResponse.codec,
      )

      if (response && response.media && Media.mustRef(response.media.ref)) {
        yield put(actionCreators.mediaUpdateMediaSuccess(response.media))
      }

    } catch (error) {
      yield put(actionCreators.mediaUpdateMediaFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

const addAttachmentSaga = mediaTakeLeading(
  'mediaAddAttachmentRequest',
  function* (action: MediaActions['mediaAddAttachmentRequest']) {
    const { payload, ref } = action
    try {
      const response: AddAttachmentResponse.Entity = yield* unaryGRPC(
        'mos.media_management.MediaManagement/AddAttachment',
        payload,
        AddAttachmentRequest.codec,
        AddAttachmentResponse.codec,
      )

      // update the parent media entity to display updated attachments.
      yield put(actionCreators.mediaGetMediaRequest(ref, {
        ...GetMediaRequest.defaults,
        mediaRef: ref,
      }))

      // update the attachment status, enabling attachment field buttons.
      yield put(actionCreators.mediaAddAttachmentSuccess(ref, response))

    } catch (error) {
      yield put(actionCreators.mediaAddAttachmentFailure(
        ref,
        failureStatusMessage(error.message)
      ))
    }
  }
)

const deleteAttachmentSaga = mediaTakeLeading(
  'mediaDeleteAttachmentRequest',
  function* (action: MediaActions['mediaDeleteAttachmentRequest']) {
    const { payload, ref } = action
    try {
      const response: DeleteAttachmentResponse.Entity = yield* unaryGRPC(
        'mos.media_management.MediaManagement/DeleteAttachment',
        payload,
        DeleteAttachmentRequest.codec,
        DeleteAttachmentResponse.codec,
      )

      // update the parent media entity to display updated attachments.
      yield put(actionCreators.mediaGetMediaRequest(ref, {
        ...GetMediaRequest.defaults,
        mediaRef: ref,
      }))

      // update the attachment status, enabling attachment field buttons.
      yield put(actionCreators.mediaDeleteAttachmentSuccess(ref, response))

    } catch (error) {
      yield put(actionCreators.mediaDeleteAttachmentFailure(
        ref,
        failureStatusMessage(error.message)
      ))
    }
  }
)

export const mediaRootSaga: ReadonlyArray<Effect> = [
  getMediaSaga,
  listMediaSaga,
  createMediaSaga,
  updateMediaSaga,
  addAttachmentSaga,
  deleteAttachmentSaga,
]
