/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconDownload = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconDownloadString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z"  fillRule="nonzero" />
  </svg>`

IconDownload.displayName = 'IconDownload'
