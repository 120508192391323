import React from 'react'
import { FormBag } from 'react-formage';

import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { Select } from 'components/ui/select'

import * as profiles from 'domains/beacons/profiles';

import {
  GenericNameIdentificationProfile,
  IBeaconIdentificationProfile,
  IdentificationProfile,
  KontaktIdentificationProfile,
} from 'generated/mos/beacon';

export type FormValues = {
  readonly name: string;
  readonly addAnother?: boolean;

  readonly profileType: profiles.ProfileType;

  // profileType == profiles.GENERIC_PROFILE
  readonly genericLocalName: string;

  // profileType == profiles.KONTAKT_PROFILE
  readonly kontaktUniqueId: string;

  // profileType == profiles.IBEACON_PROFILE
  readonly iBeaconMajor: string;
  readonly iBeaconMinor: string;
  readonly iBeaconUuid: string;

  readonly snapToPosition: boolean;
};

export const assembleIdentificationProfile = (values: FormValues): IdentificationProfile.Entity | undefined => {
  let profile = undefined

  switch (values.profileType) {
    case profiles.IBEACON_PROFILE: {
      profile = {
        ...IBeaconIdentificationProfile.defaults,
        major: Number(values.iBeaconMajor),
        minor: Number(values.iBeaconMinor),
        uuid: values.iBeaconUuid,
      }
      break
    }

    case profiles.KONTAKT_PROFILE: {
      profile = {
        ...KontaktIdentificationProfile.defaults,
        kontaktUniqueId: values.kontaktUniqueId,
      }
      break
    }

    case profiles.GENERIC_PROFILE: {
      profile = {
        ...GenericNameIdentificationProfile.defaults,
        localName: values.genericLocalName,
      }
      break
    }

    default: {
      break
    }
  }

  return profile ? {
    ...IdentificationProfile.defaults,
    profile,
  } : undefined
}

type Props = {
  readonly bag: FormBag<FormValues>;
};

export const BeaconFormLayout = ({ bag }: Props) => {
  const { errors, touched } = bag;
  const { profileType } = bag.values;
  return (
    <>
      <Input
        label="Name"
        field="name"
        error={touched.name ? errors.name : undefined}
      />

      <Select<FormValues, 'profileType'>
        label="Identification method"
        field="profileType"
        options={profiles.profileOptions}
        error={touched.profileType ? errors.profileType : undefined}
      />

      {profileType !== profiles.IBEACON_PROFILE ? null : <>
        <Input
          label="UUID"
          field="iBeaconUuid"
          error={touched.iBeaconUuid ? errors.iBeaconUuid : undefined}
        />
        <Input
          label="Major"
          field="iBeaconMajor"
          error={touched.iBeaconMajor ? errors.iBeaconMajor : undefined}
        />
        <Input
          label="Minor"
          field="iBeaconMinor"
          error={touched.iBeaconMinor ? errors.iBeaconMinor : undefined}
        />
      </>}

      {profileType !== profiles.KONTAKT_PROFILE ? null :
      <Input
        label="Kontakt ID"
        field="kontaktUniqueId"
        error={touched.kontaktUniqueId ? errors.kontaktUniqueId : undefined}
        />
      }

      {profileType !== profiles.GENERIC_PROFILE ? null :
      <Input
        label="Local Name"
        field="genericLocalName"
        error={touched.genericLocalName ? errors.genericLocalName : undefined}
        />
      }

      <Checkbox<FormValues, "snapToPosition">
        label="Snap to position"
        field="snapToPosition"
        description="Use only this beacons position when it is nearest, instead of using the default triangulation method."
      />
    </>
  );
};
