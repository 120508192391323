// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { HyperLink, Metadata } from "generated/mos/entity";
import { CoordinateQuad } from "generated/mos/geo";
import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, ensureScalar, ensureScalarOptional, ensureScalarRepeated } from "generated/webrpc";

// An authentication key for use by machine or non-interactive users
export namespace APIKey {
  export const refName = "mos.user.APIKey" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.APIKey");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

// The `access` token contains the full details of the user it represents,
// including the roles they hold. i.e. it can be used to validate that the user
// has the permissions to perform a certain action without talking to the
// identity management server directly. It cannot be revoked so it necessarily
// has a short lifespan.
// 
export namespace AccessToken {
  export const refName = "mos.user.AccessToken" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.AccessToken");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

export const DeviceKindValues = new Set([
  'DEVICE_KIND_UNKNOWN' as const,
  'DEVICE_KIND_IOS_APP' as const,
  'DEVICE_KIND_ANDROID_APP' as const,
  'DEVICE_KIND_WEB' as const,
  'DEVICE_KIND_SERVICE' as const,
  'DEVICE_KIND_SCRIPT' as const,
]);
export type DeviceKind = typeof DeviceKindValues extends Set<infer U> ? U : never;

export namespace DeviceProfile {
  export const refName = "mos.user.DeviceProfile" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly deviceId: string,
    readonly displayName: string,
    readonly deviceName: string,
    // Mobile client device model. This is a non-human-readable string, and for
    // iOS devices it's the "Model Identifier" value.
    readonly deviceModel: string,
    readonly appName: string,
    // When device_kind is DEVICE_KIND_WEB, this MUST be set to the User-Agent.
    readonly userAgent: string,
    readonly deviceKind: DeviceKind,
  }
  export const defaults: Entity = {
    type: refName,
    deviceId: "",
    displayName: "",
    deviceName: "",
    deviceModel: "",
    appName: "",
    userAgent: "",
    deviceKind: "DEVICE_KIND_UNKNOWN",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.DeviceProfile");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["deviceId"] = ensureScalar(ctx, v["deviceId"], "string");
      out["displayName"] = ensureScalar(ctx, v["displayName"], "string");
      out["deviceName"] = ensureScalar(ctx, v["deviceName"], "string");
      out["deviceModel"] = ensureScalar(ctx, v["deviceModel"], "string");
      out["appName"] = ensureScalar(ctx, v["appName"], "string");
      out["userAgent"] = ensureScalar(ctx, v["userAgent"], "string");
      {
        const ev = ensureScalar(ctx, v["deviceKind"], "string") || defaults["deviceKind"];
        if (!DeviceKindValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.user.DeviceKind`);
        }
        out["deviceKind"] = ev as any;
      }
      return out as any as Entity;
    }
  }();
}

export namespace Org {
  export type Ref = { readonly typename: 'mos.user.Org', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.user.Org');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Org`);
    return v;
  }
  export const refName = "mos.user.Org" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.user.Org', readonly id: string } | undefined,
    readonly name: string,
    // Lower-case, URL safe representation of the Org's name. See the "slug"
    // entry in the "Patterns" section of README.md for details.
    // Slug is required.
    readonly slug: string,
    readonly isActive: boolean,
    readonly metadata: Metadata.Entity | undefined,
    readonly logoUrl: string,
    // The auth support link is presented to users when authentication
    // issues arise to allow the user to seek help with their account.
    readonly authSupportLink: HyperLink.Entity | undefined,
    // If no other bounds are available, this is used to centre the map
    // used by this Org in the web admin interface.
    readonly defaultMapView: CoordinateQuad.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    name: "",
    slug: "",
    isActive: false,
    metadata: undefined,
    logoUrl: "",
    authSupportLink: undefined,
    defaultMapView: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["name"] = v.name;
      obj["slug"] = v.slug;
      obj["isActive"] = v.isActive;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["logoUrl"] = v.logoUrl;
      obj["authSupportLink"] = HyperLink.codec.encode(v.authSupportLink);
      obj["defaultMapView"] = CoordinateQuad.codec.encode(v.defaultMapView);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.Org");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["slug"] = ensureScalar(ctx, v["slug"], "string");
      out["isActive"] = ensureScalar(ctx, v["isActive"], "boolean");
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["logoUrl"] = ensureScalar(ctx, v["logoUrl"], "string");
      out["authSupportLink"] = ctx.decode(HyperLink.codec, v["authSupportLink"], "authSupportLink");
      out["defaultMapView"] = ctx.decode(CoordinateQuad.codec, v["defaultMapView"], "defaultMapView");
      return out as any as Entity;
    }
  }();
}

// PublicOrgInfo is a projection of Org that limits the information to the
// subset of fields we are content to expose to an unauthenticated endpoint.
// 
export namespace PublicOrgInfo {
  export const refName = "mos.user.PublicOrgInfo" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly orgRef: { readonly typename: 'mos.user.Org', readonly id: string } | undefined,
    readonly name: string,
    readonly slug: string,
    readonly logoUrl: string,
    // The auth support link is presented to users when authentication
    // issues arise to allow the user to seek help with their account.
    readonly authSupportLink: HyperLink.Entity | undefined,
    // If no other bounds are available, this is used to centre the map
    // used by this Org in the web admin interface:
    readonly defaultMapView: CoordinateQuad.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    orgRef: undefined,
    name: "",
    slug: "",
    logoUrl: "",
    authSupportLink: undefined,
    defaultMapView: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["orgRef"] = v.orgRef;
      obj["name"] = v.name;
      obj["slug"] = v.slug;
      obj["logoUrl"] = v.logoUrl;
      obj["authSupportLink"] = HyperLink.codec.encode(v.authSupportLink);
      obj["defaultMapView"] = CoordinateQuad.codec.encode(v.defaultMapView);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.PublicOrgInfo");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["orgRef"] = v["orgRef"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["slug"] = ensureScalar(ctx, v["slug"], "string");
      out["logoUrl"] = ensureScalar(ctx, v["logoUrl"], "string");
      out["authSupportLink"] = ctx.decode(HyperLink.codec, v["authSupportLink"], "authSupportLink");
      out["defaultMapView"] = ctx.decode(CoordinateQuad.codec, v["defaultMapView"], "defaultMapView");
      return out as any as Entity;
    }
  }();
}

// A long lived opaque token that can be used to re-authenticate a user and obtain a new access
// token
// 
// Refresh tokens can be revoked by the identity management system
// 
export namespace RefreshToken {
  export const refName = "mos.user.RefreshToken" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.RefreshToken");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

// Represents the information about a single refresh token
// 
export namespace RefreshTokenInfo {
  export type Ref = { readonly typename: 'mos.user.RefreshTokenInfo', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.user.RefreshTokenInfo');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a RefreshTokenInfo`);
    return v;
  }
  export const refName = "mos.user.RefreshTokenInfo" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.user.RefreshTokenInfo', readonly id: string } | undefined,
    readonly userRef: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
    readonly tokenSha256: string,
    readonly deviceProfile: DeviceProfile.Entity | undefined,
    readonly createdAt: Timestamp | undefined,
    readonly expiresAt: Timestamp | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    userRef: undefined,
    tokenSha256: "",
    deviceProfile: undefined,
    createdAt: undefined,
    expiresAt: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["userRef"] = v.userRef;
      obj["tokenSha256"] = v.tokenSha256;
      obj["deviceProfile"] = DeviceProfile.codec.encode(v.deviceProfile);
      obj["createdAt"] = v.createdAt ? v.createdAt.value : "";
      obj["expiresAt"] = v.expiresAt ? v.expiresAt.value : "";
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.RefreshTokenInfo");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["userRef"] = v["userRef"];
      out["tokenSha256"] = ensureScalar(ctx, v["tokenSha256"], "string");
      out["deviceProfile"] = ctx.decode(DeviceProfile.codec, v["deviceProfile"], "deviceProfile");
      {
        const tsv = ensureScalarOptional(ctx, v["createdAt"], "string");
        out["createdAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      {
        const tsv = ensureScalarOptional(ctx, v["expiresAt"], "string");
        out["expiresAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      return out as any as Entity;
    }
  }();
}

export namespace User {
  export type Ref = { readonly typename: 'mos.user.User', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.user.User');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a User`);
    return v;
  }
  export const refName = "mos.user.User" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
    readonly orgRef: { readonly typename: 'mos.user.Org', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    readonly email: string,
    readonly name: string,
    readonly isActive: boolean,
    readonly roles: ReadonlyArray<string>,
    readonly userKind: UserKind,
    // Is this was claimed by another authenticated user, this will container the owners reference.
    readonly canonicalUserRef: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    orgRef: undefined,
    metadata: undefined,
    email: "",
    name: "",
    isActive: false,
    roles: [],
    userKind: "USER_KIND_UNSPECIFIED",
    canonicalUserRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["orgRef"] = v.orgRef;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["email"] = v.email;
      obj["name"] = v.name;
      obj["isActive"] = v.isActive;
      obj["roles"] = v.roles;
      obj["userKind"] = v.userKind;
      obj["canonicalUserRef"] = v.canonicalUserRef;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.User");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["orgRef"] = v["orgRef"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["email"] = ensureScalar(ctx, v["email"], "string");
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["isActive"] = ensureScalar(ctx, v["isActive"], "boolean");
      out["roles"] = ensureScalarRepeated(ctx, v["roles"], "string");
      {
        const ev = ensureScalar(ctx, v["userKind"], "string") || defaults["userKind"];
        if (!UserKindValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.user.UserKind`);
        }
        out["userKind"] = ev as any;
      }
      out["canonicalUserRef"] = v["canonicalUserRef"];
      return out as any as Entity;
    }
  }();
}

// Represents a set of associations between users
// 
// The system allows for the creation of and then claiming of anonymous visitor users. The
// `mos.user.UserClaimSet` message represents these associations
export namespace UserClaimSet {
  export const refName = "mos.user.UserClaimSet" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly canonicalRef: { readonly typename: 'mos.user.UserClaimSet', readonly id: string } | undefined,
    readonly claimedUserRefs: ReadonlyArray<{ readonly typename: 'mos.user.User', readonly id: string }>,
  }
  export const defaults: Entity = {
    type: refName,
    canonicalRef: undefined,
    claimedUserRefs: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user.UserClaimSet");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["canonicalRef"] = v["canonicalRef"];
      out["claimedUserRefs"] = v["claimedUserRefs"];
      return out as any as Entity;
    }
  }();
}

export const UserKindValues = new Set([
  'USER_KIND_UNSPECIFIED' as const,
  'USER_KIND_VISITOR' as const,
  'USER_KIND_STAFF' as const,
  'USER_KIND_MACHINE' as const,
  'USER_KIND_ANONYMOUS_VISITOR' as const,
]);
export type UserKind = typeof UserKindValues extends Set<infer U> ? U : never;

