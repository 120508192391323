import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Error404Page } from 'pages/error/404';

import { SpaceEditPage } from 'domains/spaces/components/space-edit-page';
import { SpacesListPage } from 'domains/spaces/components/spaces-list-page';

export const SpacesRoutes = () => (
  <Switch>
    <Route
      exact path="/locate/spaces/edit/:spaceId"
      render={(routeProps: RouteComponentProps<{ spaceId: string }>) => (
        <SpaceEditPage
          spaceId={routeProps.match.params.spaceId}
          onComplete={() => routeProps.history.push('/locate/spaces/')}
        />
      )}
    />

    <Route
      exact path="/locate/spaces/"
      render={(routeProps) => (
        <SpacesListPage
          onCreate={(e) => {
            routeProps.history.push(`/locate/spaces/edit/${e.ref.id}`);
          }}
          navBackRoute="/locate"
        />
      )}
    />

    <Route render={() => <Error404Page /> } />
  </Switch>
);
