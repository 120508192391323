/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconGrip = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M10 16a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6-6a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6-6a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1V5a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V5a1 1 0 011-1h2z"  fillRule="evenodd" />
  </Icon>
)

// for use with mapbox addables.
export const IconGripString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M10 16a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6-6a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zm-6-6a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1V5a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V5a1 1 0 011-1h2z"  fillRule="evenodd" />
  </svg>`

IconGrip.displayName = 'IconGrip'
