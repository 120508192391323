import mapboxgl from 'mapbox-gl';

import { Resolution } from './core';
import { coordinatesEqual, DeepReadonly, identityEqual, objectKeys } from './util';

import { Corners } from 'helpers/geo'; // XXX: geo is the only mos-admin-web dependency allowed in here


// The mapbox typing defines source definitions a little weirdly and it chops
// and changes a lot.
//
// We need pure declarative representations of all sources, presented with the
// least amount of jank, so we have declared our own mappings to the declarative
// types, which should be used instead of the mapbox ones.
//
// Decoding the sources has proven to be a pretty difficult thing for new
// users. It may be worth simply copying in all the defs into here so there's
// a project-local source of truth for this, which will spare users the
// challenge of spelunking through the mapbox type definition files and
// source code when confronted with another one of TypeScript's horrendous
// type errors.
//
// XXX: any properties you add to the SourceDef objects have to be stripped out
// before being passed to mapbox. See prepareSource() for details;
//
// See also: mapboxgl.AnySourceData
export type SourceDef =
  CanvasSourceDef |
  GeoJSONSourceDef |
  ImageSourceDef |
  RasterSourceDef |
  RasterDemSourceDef |
  VectorSourceDef |
  VideoSourceDef;

export type SourceDefs = { [key: string]: SourceDef };

type SourceDefBase = {
  readonly id: string;
};

export type CanvasSourceDef = SourceDefBase & Readonly<mapboxgl.CanvasSourceRaw> & {
  readonly playing: boolean;
  readonly coordinates?: Corners;
};

export type GeoJSONSourceDef = SourceDefBase & Readonly<mapboxgl.GeoJSONSourceRaw> & {
  // If this is defined, it is used to check whether the data has changed. Each item in
  // the list is compared with each item in the previous list for strict equality (===).
  // This allows you to, for example, map a list of string IDs from a list of data that
  // you are filtering in your component's render() function and avoid spurious redraws.
  //
  // Please, please, please read the function documentation for identityEqual in ./util.ts
  // for a more thorough treatment of the dataIdentity concept.
  readonly dataIdentity: ReadonlyArray<any> | undefined;
};

export interface ImageSourceDef extends SourceDefBase, DeepReadonly<mapboxgl.ImageSourceRaw> {
  // Four geographical coordinates, represented as arrays of longitude and
  // latitude numbers, which define the corners of the image. The coordinates
  // start at the top left corner of the image and proceed in clockwise order.
  // They do not have to represent a rectangle.
  readonly coordinates?: Corners;
}

// RasterSource is a map content source that supplies raster image tiles to be
// shown on the map.
export type RasterSourceDef = SourceDefBase & Readonly<mapboxgl.RasterSource>;

// RasterDem is used for DEM (digital elevation model) files:
// https://en.wikipedia.org/wiki/Digital_elevation_model
export type RasterDemSourceDef = SourceDefBase & Readonly<mapboxgl.RasterDemSource>;

export type VectorSourceDef = SourceDefBase & Readonly<mapboxgl.VectorSource>;
export type VideoSourceDef = SourceDefBase & Readonly<mapboxgl.VideoSourceRaw>;

export type SourceDefType = SourceDef extends { type: infer U } ? U : never;

// Type assertions: these are possibly needed in order to make sure mapbox's typing
// doesn't move the goalposts on us. This is here only out of an abundance of caution.
//
// We can only test against DeepReadonly versions of the mapbox types because ours
// are immutable - if you remove the DeepReadonly check, this should fail (FIXME:
// use typings tester to verify this)
{ const _: DeepReadonly<mapboxgl.ImageSourceRaw> = null as any as ImageSourceDef; }
{ const _: DeepReadonly<mapboxgl.CanvasSourceRaw> = null as any as CanvasSourceDef; }

/* eslint-disable @typescript-eslint/no-object-literal-type-assertion */
// special case to work around mapbox
(() => ({addSource: () => {}} as any as mapboxgl.Map).addSource('id', {} as GeoJSONSourceDef))();
(() => ({addSource: () => {}} as any as mapboxgl.Map).addSource('id', {} as RasterSourceDef))();
(() => ({addSource: () => {}} as any as mapboxgl.Map).addSource('id', {} as RasterDemSourceDef))();
(() => ({addSource: () => {}} as any as mapboxgl.Map).addSource('id', {} as VectorSourceDef))();
(() => ({addSource: () => {}} as any as mapboxgl.Map).addSource('id', {} as VideoSourceDef))();
/* eslint-enable @typescript-eslint/no-object-literal-type-assertion */


type comparator<TDef, TSource extends mapboxgl.AnySourceImpl> = (prev: TDef, next: TDef) => SourceResolver<TSource>;

export type SourceResolver<TSource extends mapboxgl.AnySourceImpl = mapboxgl.AnySourceImpl> =
  { readonly resolution: 'add' } |
  { readonly resolution: 'replace' } |
  { readonly resolution: 'remove' } |
  { readonly resolution: 'keep' } |
  { readonly resolution: 'update'; update(source: TSource): void };

const resolutionAdd: SourceResolver = { resolution: 'add' };
const resolutionKeep: SourceResolver = { resolution: 'keep' };
const resolutionRemove: SourceResolver = { resolution: 'remove' };
const resolutionReplace: SourceResolver = { resolution: 'replace' };

// Resolves changes to a mapbox source.
//
// Properties in the previous source definition are compared to the incoming
// source definition.
//
// If there is no previous (i.e. the first time), it resolves as Resolution.New and the
// source is added using mapboxgl.Map.addSource().
//
// If a property can be updated without recreating the source (i.e. calling
// setData() on a GeoJSONSource), it resolves as Resolution.Update.
//
// If a property must be updated but there is no way to update the existing source
// (i.e. mapbox does not provide a setter on the source's implementation), it resolves
// to Resolution.New.
//
// Otherwise it resolves to Resolution.Same and nothing is changed.
//
export function compareSourceDef(
  prev: SourceDef | undefined,
  next: SourceDef | undefined,
): SourceResolver<mapboxgl.AnySourceImpl> {

  if (!prev && !next) {
    throw new Error();
  }
  if (!prev) {
    return resolutionAdd;
  } else if (!next) {
    return resolutionRemove;
  }

  if (prev.type !== next.type) {
    throw new Error();
  }

  switch (prev.type) {
  case 'canvas':
    return compareCanvas(prev, next as CanvasSourceDef);
  case 'geojson':
    return compareGeoJSON(prev, next as GeoJSONSourceDef);
  case 'image':
    return compareImage(prev, next as ImageSourceDef);
  case 'raster':
    return compareRaster(prev, next as RasterSourceDef);
  case 'raster-dem':
    return compareRasterDem(prev, next as RasterDemSourceDef);
  case 'vector':
    return compareVector(prev, next as VectorSourceDef);
  case 'video':
    return compareVideo(prev, next as VideoSourceDef);

  default:
    assertNever(prev);
    return resolutionAdd; // This doesn't do anything other than keep TypeScript happy.
  }
}

// Clean up our source defs to work with mapbox by removing properties we have added
export function prepareSource(def: SourceDef): mapboxgl.AnySourceData {
  // XXX: 'any' is used here so we can be indiscriminate - we are cheating a
  // bit for convenience by just deleting any old thing from this object
  // regardless of where it came from. Maybe we should use a generic wrapper
  // instead but this'll do for now.
  const out = {...(def as any)};
  delete out.dataIdentity;
  delete out.id;
  delete out.playing;
  return out;
}

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

function objectStrictEqualShallow<T extends object>(a: T, b: T, skip?: Set<keyof T>): boolean {
  for (const key of objectKeys(a)) {
    if (skip && skip.has(key)) {
      continue;
    }
    if (a[key] !== b[key]) {
      return false;
    }
  }
  for (const key of objectKeys(b)) {
    if (skip && skip.has(key)) {
      continue;
    }
    if (! (key in a)) {
      return false;
    }
  }
  return true;
}

function compareCanvas(prev: CanvasSourceDef, next: CanvasSourceDef): SourceResolver<mapboxgl.CanvasSource> {
  if (prev.canvas !== next.canvas) {
    return resolutionReplace;
  }

  let res: Resolution = 'keep';
  const updates: Array<(next: CanvasSourceDef, source: mapboxgl.CanvasSource) => void> = [];

  if (!coordinatesEqual(prev.coordinates, next.coordinates)) {
    updates.push((next, loaded) => loaded.setCoordinates(next.coordinates || []));
    res = 'update';
  }

  if (prev.playing !== next.playing) {
    updates.push((next, loaded) => prev.playing ? loaded.pause() : loaded.play());
    res = 'update';
  }

  if (res === 'keep') {
    return resolutionKeep;
  }

  return {
    resolution: res,
    update: (source): void => {
      for (const upd of updates) { upd(next, source); }
    },
  };
}

const compareGeoJSONManualKeys = new Set<keyof GeoJSONSourceDef>(['dataIdentity', 'data']);

function compareGeoJSON(prev: GeoJSONSourceDef, next: GeoJSONSourceDef): SourceResolver<mapboxgl.GeoJSONSource> {
  // If any of the keys change and the source implementation can't update them, we
  // must re-add a new source:
  if (!objectStrictEqualShallow(prev, next, compareGeoJSONManualKeys)) {
    return resolutionReplace;
  }

  if (!!prev.dataIdentity !== !!next.dataIdentity) {
    throw new Error('Can not switch between using dataIdentity and data for the same source');
  }

  const dataEqual = (prev.dataIdentity && next.dataIdentity)
    ? identityEqual(prev.dataIdentity, next.dataIdentity)
    : prev.data === next.data;

  if (!dataEqual) {
    // FIXME: mapbox typings have some stupid NodeJS string type hack in them
    // that means that the 'data' property on mapboxgl.GeoJSONSourceOptions is
    // incompatible with their own mapboxgl.GeoJSONSource.setData() property.
    return {
      resolution: 'update',
      update: (source) => source.setData(next.data as any),
    };
  }

  return resolutionKeep;
}

function compareImage(prev: ImageSourceDef, next: ImageSourceDef): SourceResolver<mapboxgl.ImageSource> {
  // There is no setter for updating the URL so we have to replace the whole source:
  if (prev.url !== next.url) {
    return resolutionReplace;
  }

  if (!coordinatesEqual(prev.coordinates, next.coordinates)) {
    return {
      resolution: 'update',

      // XXX: 'any' is because setCoordinates expects a mutable object but we force
      // our defs to be immutable:
      update: (source) => source.setCoordinates(next.coordinates as any || []),
    };
  }

  return resolutionKeep;
}

const compareVideoManualKeys = new Set<keyof VideoSourceDef>(['coordinates']);

function compareVideo(prev: VideoSourceDef, next: VideoSourceDef): SourceResolver<mapboxgl.VideoSource> {
  if (!objectStrictEqualShallow(prev, next, compareVideoManualKeys)) {
    return resolutionReplace;
  }

  if (!coordinatesEqual(prev.coordinates, next.coordinates)) {
    return {
      resolution: 'update',
      update: (source) => source.setCoordinates(next.coordinates || []),
    };
  }

  return resolutionKeep;
}

const compareRaster: comparator<RasterSourceDef, mapboxgl.RasterSource> = compareDef;
const compareRasterDem: comparator<RasterDemSourceDef, mapboxgl.RasterDemSource> = compareDef;
const compareVector: comparator<VectorSourceDef, mapboxgl.VectorSource> = compareDef;

// Resolve changes to a source definition where the underlying type is the
// same, for example mapboxgl.VectorSource is used as both the source
// definition and the type for the source implementation (i.e. the object
// returned by mapboxgl.Map.getSource())
function compareDef<TDef extends object, TSource extends mapboxgl.AnySourceImpl>(prev: TDef, next: TDef): SourceResolver<TSource> {
  if (!objectStrictEqualShallow(prev, next)) {
    return resolutionReplace;
  }
  return resolutionKeep;
}

export type SourceActions = {
  readonly changed: boolean;
  readonly actions: Readonly<{ [key: string]: SourceResolver }>;
};

export function computeSourceChanges(
  olds: { [key: string]: SourceDef } | undefined,
  news: { [key: string]: SourceDef },
): SourceActions {

  if (news === olds) {
    return { changed: false, actions: {} };
  }
  if (!olds) {
    olds = {};
  }

  const actions: { [key: string]: SourceResolver } = {};
  let changed = false;

  for (const key of Object.keys(olds)) {
    const resolver = compareSourceDef(olds[key], news[key]);
    if (resolver.resolution !== 'keep') {
      actions[key] = resolver;
      changed = true;
    }
  }

  for (const key of Object.keys(news)) {
    if (key in olds) {
      continue;
    }
    actions[key] = compareSourceDef(undefined, news[key]);
    changed = true;
  }

  return { changed: changed, actions: actions };
}

