import { DrawPolygon } from '@mapbox/mapbox-gl-draw';

import * as geo from 'helpers/geo';

import { createVertex } from './helpers';


const isFeature = (e: any) => (e && e.featureTarget && e.featureTarget.properties ?
  e.featureTarget.properties.meta === 'feature' :
  false);


interface State {
  originalRing?: geo.MutablePosition2D[];
  firstMouseDownLngLat?: geo.Position2D;
  firstBearing: number;
  rectangle: geo.MutablePolygon2D & DrawPolygon;
  originalCenter: geo.Position2D;
  featureId: string;
  lastMouseDownLngLat?: geo.Position2D;
}


interface Options {
  readonly key: string;
}

interface SetupOptions {
  readonly featureId: string;
}


type EventKind = 'transformEnd' | 'transformStart' | 'transform';

// FIXME: This whole thing may belong in the mapbox-gl-draw typings we had to create (see
// typings/mapbox.d.ts)
type DrawFeatureProperties = {
  readonly id: string;
  readonly active?: 'true'; // FIXME: why is this like this?
};


export function drawRotateEventKey(key: string, name: EventKind): string {
  return `draw.adminmaprot.${key}.${name}`;
}


export function drawRotateMode(opts: Options): any {
  const eventKey = (name: EventKind): string => {
    return drawRotateEventKey(opts.key, name);
  };

  return {
    onSetup(setupOpts: SetupOptions): State {
      const { featureId } = setupOpts;
      const rectangle = this.getFeature(featureId);
      if (!rectangle || (rectangle as any).type !== 'Polygon') {
        throw new Error(`feature '${featureId}' invalid`);
      }

      const state: State = {
        featureId,
        originalCenter: geo.centroid2D(rectangle),
        firstBearing: 0,
        rectangle,
      };
      return state;
    },

    resetState(state: State) {
      // FIXME: MUTATING MAP! DANGEROUS!
      this.map.dragPan.enable();

      this.map.fire(eventKey('transformEnd'));
      state.firstMouseDownLngLat = undefined;
      state.firstBearing = 0;
      state.originalRing = undefined;
    },

    onMouseDown(state: State, e: any) { // FIXME: event typing
      if (isFeature(e)) {
        // FIXME: MUTATING MAP! DANGEROUS!
        this.map.dragPan.disable();

        this.map.fire(eventKey('transformStart'));
        state.originalRing = [ ...state.rectangle.coordinates[0]];
        state.firstMouseDownLngLat = [e.lngLat.lng, e.lngLat.lat];
        state.firstBearing = geo.bearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);
      }
    },

    onMouseUp(state: State, e: any) { // FIXME: event typing
      this.resetState(state);
    },

    toDisplayFeatures(state: State, geojson: geo.Feature<geo.Geometry, DrawFeatureProperties>, display: (o: geo.Feature<geo.Geometry, DrawFeatureProperties>) => void) {
      const properties = geojson.properties || {};
      geojson = { ...geojson, properties: { ...properties, active: 'true' } };

      // 'active' is required to remove the blue-styled border from the feature:
      display(geojson);

      const centreCoords = geo.centroid2D(state.rectangle);

      const handles = createRotateHandles(geojson, centreCoords, state.lastMouseDownLngLat, state.firstMouseDownLngLat);
      for (const point of handles) {
        display(point);
      }
    },

    onClick(state: State, e: any) { // FIXME: event typing
      this.resetState(state);
    },

    onDrag(state: State, e: any) { // FIXME: event typing
      if (!state.originalRing) {
        return;
      }

      state.lastMouseDownLngLat = [e.lngLat.lng, e.lngLat.lat];
      const draggedBearing = state.firstBearing - geo.bearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);

      const rotatedRing: geo.MutablePosition2D[] = [];
      state.originalRing.forEach((coords) => {
        const distanceFromCenter = geo.kilometers(state.originalCenter, coords);
        const bearingFromCenter = geo.bearing(state.originalCenter, coords);
        const newPoint = geo.destKilometers(state.originalCenter, distanceFromCenter, bearingFromCenter - draggedBearing);
        rotatedRing.push(geo.mut(newPoint.coordinates));
      });

      state.rectangle.updateCoordinate(`0.0`, rotatedRing[0][0], rotatedRing[0][1]);
      state.rectangle.updateCoordinate(`0.1`, rotatedRing[1][0], rotatedRing[1][1]);
      state.rectangle.updateCoordinate(`0.2`, rotatedRing[2][0], rotatedRing[2][1]);
      state.rectangle.updateCoordinate(`0.3`, rotatedRing[3][0], rotatedRing[3][1]);

      const ring = state && state.rectangle && state.rectangle.coordinates ?
        state.rectangle.coordinates[0] :
        [];

      this.map.fire(eventKey('transform'), {
        coords: ring.slice(0, 4),
        rectangle: { ...state.rectangle },
        area: geo.squareMeters(state.rectangle),
      });
    },
  };
}


function createLine(parentId: any, coordinates: [geo.Position2D, geo.Position2D], selected: boolean): geo.Feature2D<geo.LineString2D, any> {
  return {
    type: 'Feature',
    geometry: { type: 'LineString', coordinates },
    properties: { // FIXME: typing for properties
      meta: 'guide_line',
      parent: parentId,
      active: selected ? 'true' : false,
    },
  };
}

function createText(parentId: any, centreCoords: geo.Position2D, cursorCoords: geo.Position2D, selected: boolean): geo.Feature2D<geo.Point2D, any> {
  const degFromNorth = getAngle(0, geo.bearing(centreCoords, cursorCoords));

  return {
    type: 'Feature',
    geometry: { type: 'Point', coordinates: cursorCoords },
    properties: { // FIXME: typing for properties
      meta: 'currentPosition',
      parent: parentId,
      angle: degFromNorth,
      active: selected ? 'true' : false,
    },
  };
}

function getAngle(a1: number, a2: number): number {
  return a2 - a1 < 0 ? a2 - a1 + 360 : a2 - a1;
}

function createRotateHandles(
  geojson: geo.Feature<geo.Geometry, any>,
  centreCoords: geo.Position2D | undefined,
  cursorCoords: geo.Position2D | undefined,
  firstCursorCoords: geo.Position2D | undefined,
) {
  const featureId = geojson.properties && geojson.properties.id;

  const supplementaryPoints: Array<geo.Feature2D<geo.Geometry2D, any>> = [];

  if (centreCoords && firstCursorCoords && cursorCoords) {
    supplementaryPoints.push(createVertex(featureId, centreCoords, `0.0`));

    supplementaryPoints.push(createLine(featureId, [centreCoords, cursorCoords], true));

    const radius = geo.kilometers(centreCoords, cursorCoords);
    const bearing1 = geo.bearing(centreCoords, firstCursorCoords);
    const endPoint = geo.destKilometers(centreCoords, radius, bearing1).coordinates;

    supplementaryPoints.push(createLine(featureId, [centreCoords, endPoint], true));

    supplementaryPoints.push(createArc(featureId, centreCoords, firstCursorCoords, cursorCoords, true));
    supplementaryPoints.push(createText(featureId, centreCoords, cursorCoords, true));
  }
  return supplementaryPoints;
}

function createArc(parentId: any, center: geo.Position2D, firstCursorCoords: geo.Position2D, cursorCoords: geo.Position2D, selected: boolean): geo.Feature2D<geo.LineString2D, any> {
  const radius = geo.kilometers(center, cursorCoords);
  const bearing1 = geo.bearing(center, firstCursorCoords);
  const bearing2 = geo.bearing(center, cursorCoords);

  const arc = getAngle(bearing1, bearing2) < 180
    ? geo.lineArcKilometers2D({ center, radius, bearing1, bearing2 })
    : geo.lineArcKilometers2D({ center, radius, bearing1: bearing2, bearing2: bearing1 });

  return {
    type: 'Feature',
    geometry: arc,
    properties: { // FIXME: properties typing
      meta: 'guide_line',
      parent: parentId,
      active: selected ? 'true' : false,
    },
  };
}

