import React, { useState } from 'react'

import styled from 'styled'

import { Button, ButtonGroup } from 'components/ui/button'
import { Confirm } from 'components/ui/modal'
import { DataStatus, Status } from 'helpers/status'
import { space } from 'helpers/style'

import { Metadata } from 'generated/mos/entity'

export const FOOTER_HEIGHT = 72

const Footer = styled.div`
  display: flex;
  align-items: center;
  height: ${FOOTER_HEIGHT}px;
  padding-left: ${space(8)};
  padding-right: ${space(8)};
  background-color: ${({ theme }) => theme.color.grayL90};
  border-top: 1px solid ${({ theme }) => theme.color.grayL60};
`

const FooterDetails = styled.div`
  margin-right: ${space(4)};
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const FooterActions = styled(ButtonGroup)`
  margin-left: auto;
`

export type Entity = {
  readonly metadata: Metadata.Entity | undefined;
}

type Props = {
  readonly entity?: DataStatus<any, any>;
  readonly modified?: boolean;
  readonly onCancel: () => void;
  readonly onSave: () => void;
};

export const FooterEditEntity = (props: Props) => {
  const { entity, modified, onCancel, onSave } = props
  const [touched, setTouched] = useState(false)
  const [confirmReturnModal, setConfirmReturnModal] = useState<boolean>(false)
  const isDisabled = !entity || ![
    Status.Idle,
    Status.Ready,
    Status.Complete,
    Status.Failed,
  ].includes(entity.status)

  // FIXME: touched is being set to true in some views when they load, 
  // causing the "All changes saved" message to appear before any changes have been made
  if (!touched && modified) {
    setTouched(true)
  }

  let entityStatusText = ''

  if (entity && touched) {
    if (entity.status === Status.Updating) {
      entityStatusText = 'Saving'
    } else if (modified && entity.status === Status.Ready) {
      entityStatusText = 'Unsaved changes'
    } else if (entity.status === Status.Ready) {
      entityStatusText = 'All changes saved'
    }
  }

  return (
    <>
      {confirmReturnModal && (
        <Confirm
          onClose={() => setConfirmReturnModal(false)}
          title="Unsaved Changes"
          message="Do you want to discard your changes?"
          confirmLabel="Yes, discard"
          closeLabel="Cancel"
          onConfirm={() => {
            onCancel()
            setConfirmReturnModal(false)
          }}
        />
      )}
      <Footer>

        <FooterActions>
          {entity && (
            <FooterDetails>
              <span>{entityStatusText}</span>
            </FooterDetails>
          )}
          <Button
            appearance="secondary"
            onClick={() => modified ? setConfirmReturnModal(true) : onCancel()}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSave()}
            isDisabled={isDisabled || !modified}
          >
            Save
          </Button>
        </FooterActions>
      </Footer>
    </>
  )
}
