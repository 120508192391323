import jsbi from 'jsbi'

import { AttributeRef } from 'generated/mos/entity'
import {
  LinkableBoolValue,
  LinkableStringValue,
  LinkableInt64Value,
  LinkableDoubleValue,
  LinkableMediaValue,
  LinkablePlacementValue,
} from 'generated/mos/linkable'
import { GetLinkedFieldMappingsResponse } from 'generated/mos/linkedfieldmappings'
import {
  ResolveLinkableFieldValuesRequest,
  ResolveLinkableFieldValuesResponse,
  ResolvedValueResult,
} from 'generated/mos/linkedfieldresolver'
import { Media } from 'generated/mos/media'
import { Placement } from 'generated/mos/placement'

import { assertNever, camelToSnakeCase, snakeToCamelCase } from 'helpers/core'
import { unaryGRPC } from 'services/unary-grpc'
import { Schema } from 'helpers/schema'

export type LinkableValueEntity =
  LinkableBoolValue.Entity |
  LinkableStringValue.Entity |
  LinkableInt64Value.Entity |
  LinkableDoubleValue.Entity |
  LinkableMediaValue.Entity |
  LinkablePlacementValue.Entity;

export type LinkableAbsoluteValue = Media.Ref | string | jsbi | number | Placement.Entity | boolean | undefined;

export type LinkableValue = AttributeRef.Entity | LinkableAbsoluteValue;

// convert a linkable value to a renderable value.
export const getLinkableValue = (input?: LinkableValueEntity): LinkableValue => {
  if (input === undefined || input.value === undefined) {
    return undefined
  }

  switch (input.type) {
    case 'mos.linkable.LinkableBoolValue':
    case 'mos.linkable.LinkableStringValue':
    case 'mos.linkable.LinkableInt64Value':
    case 'mos.linkable.LinkableDoubleValue':
    case 'mos.linkable.LinkableMediaValue':
    case 'mos.linkable.LinkablePlacementValue': {
      return input.value
    }
    default: {
      assertNever(input)
    }
  }
}

export const createLinkableMediaValue = (
  value: AttributeRef.Entity | Media.Ref | undefined,
): LinkableMediaValue.Entity => ({
  type: LinkableMediaValue.refName,
  value,
})

export const createLinkablePlacementValue = (
  value: AttributeRef.Entity | Placement.Entity | undefined,
): LinkablePlacementValue.Entity => ({
  type: LinkablePlacementValue.refName,
  value,
})

export const createLinkableStringValue = (
  value: AttributeRef.Entity | string | undefined,
): LinkableStringValue.Entity => ({
  type: LinkableStringValue.refName,
  value,
})

// strips away general entity and linkable attributes.
export const filterLinkableAttributes = (key: string) =>
  key !== 'type' &&
  key !== 'ref' &&
  key !== 'metadata' &&
  key !== 'basedOnRef'

// take an entities default structure and return an array of keys we can ask the backend service
// if linked value mappings exist using `linkableGetLinkedFieldMappingsRequest()`
export const extractSourceAttributeNames = (entity: Record<string, any>) => {
  // take the keys from the default source entity.
  return Object.keys(entity)
    // strip out common keys which cannot be attribute refs.
    .filter(filterLinkableAttributes)
    // convert to snake_case to match backend requirements.
    .map(key => camelToSnakeCase(key))
}

// convert field mapping response into useable values to spread into an entity.
export const convertLinkedFieldMappingsToValues = <TEntity>(
  fieldMappings: ReadonlyArray<GetLinkedFieldMappingsResponse.Mapping.Entity>,
  schema: Schema<TEntity>,
  basedOnRef: Pick<AttributeRef.Entity, 'id' | 'typename'>
): Partial<TEntity>  => {
  return fieldMappings
    // find non-error results which have at least one mapping.
    .filter(mapping => mapping.targetAttributeNames && mapping.targetAttributeNames.length)
    // convert to attribute ref.
    .reduce((accumulator, currentValue) => {
      const attributeName = snakeToCamelCase(currentValue.sourceAttributeName)
      const attributeRef = {
        type: AttributeRef.refName,
        ...basedOnRef,
        attributeName: currentValue.targetAttributeNames[0],
      }

      return {
        ...accumulator,
        // use schema to correctly format the value, or fallback to directly using the
        // attribute ref.
        [attributeName]: schema[attributeName] ?
          schema[attributeName].write(attributeRef) :
          attributeRef
      }
    }, {})
}

export const extractResolvedValue = (value: ResolvedValueResult.Value.Entity['targetValue']) => {
  if (value === undefined) {
    return undefined
  }
  switch (value.type) {
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.BoolValue': {
      return value.bool
    }
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.StringValue': {
      return value.string
    }
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.Int64Value': {
      return value.int64
    }
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.DoubleValue': {
      return value.double
    }
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.MediaValue': {
      return value.media
    }
    case 'mos.linked_field_resolver.ResolvedValueResult.Value.PlacementValue': {
      return value.placement
    }
    default: {
      assertNever(value)
    }
  }
}

const hasTargetValue = (v: ResolvedValueResult.Value.Entity): v is ResolvedValueResult.Value.Entity =>
  !!(
    v &&
    isResolvedValueResult(v) &&
    v.targetValue &&
    (
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.BoolValue' ||
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.StringValue' ||
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.Int64Value' ||
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.DoubleValue' ||
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.MediaValue' ||
      v.targetValue.type === 'mos.linked_field_resolver.ResolvedValueResult.Value.PlacementValue'
    )
  )

const isResolvedValueResult = (
  value: ResolvedValueResult.Value.Entity | ResolvedValueResult.Error.Entity | undefined
): value is ResolvedValueResult.Value.Entity =>
  !!value && value.type && value.type === 'mos.linked_field_resolver.ResolvedValueResult.Value'

const isAttributeRef = (value: any): value is AttributeRef.Entity =>
  !!value &&
  value.id &&
  value.type &&
  value.type === 'mos.entity.AttributeRef'

// check if a value is an attribute ref, and find it's resolved target value.
// otherwise return the original value.
export const getResolvedValue = (
  value: LinkableValue,
  resolvedValues?: ReadonlyArray<ResolvedValueResult.Entity> | undefined
) => {
  // we have an attribute ref to attempt to resolve.
  if (resolvedValues && value && isAttributeRef(value)) {
    const resolvedValue = resolvedValues.find(resolved => {
      const resolvedResult = resolved.result
      return (resolvedResult &&
        isResolvedValueResult(resolvedResult) &&
        hasTargetValue(resolvedResult) &&
        resolvedResult.targetFieldRef &&
        resolvedResult.targetFieldRef.typename === value.typename &&
        resolvedResult.targetFieldRef.id === value.id &&
        resolvedResult.targetFieldRef.attributeName === value.attributeName)
    })

    // if we find a matching resolved value for the attribute ref, return the target value.
    return resolvedValue &&
      resolvedValue.result &&
      hasTargetValue(resolvedValue.result as ResolvedValueResult.Value.Entity) &&
      isResolvedValueResult(resolvedValue.result) ?
        extractResolvedValue(resolvedValue.result.targetValue) :
        undefined
  }

  // if there are no resolved values, pass back the value.
  return value
}

// search for attribute ref values an entity contains, and return as an array.
export const getAttributeRefsFromEntity = <TEntity>(
  entity: TEntity & { basedOnRef?: Pick<AttributeRef.Entity, 'typename' | 'id'> | undefined },
  schema: Schema<TEntity>
): ReadonlyArray<AttributeRef.Entity> => {
  let targetFieldRefs: ReadonlyArray<AttributeRef.Entity> = []

  const getAttributeRefValue = <TEntity>(key: string, rawValue: LinkableValue, schema: Schema<TEntity>): AttributeRef.Entity | undefined => {
    const value: AttributeRef.Entity | any = schema[key] ? schema[key].read(rawValue) : rawValue
    if (value && value.type && value.id && value.type === AttributeRef.refName) {
      return value
    }

    return undefined
  }

  if (entity.basedOnRef) {
    Object.entries(entity).map(currentValue => {
      const key = currentValue[0]
      const rawValue: any = currentValue[1]

      const attributeRef = getAttributeRefValue(key, rawValue, schema)
      if (attributeRef) {
        targetFieldRefs = [
          ...targetFieldRefs,
          attributeRef,
        ]
      }
    })
  }

  return targetFieldRefs
}

export function* resolveAttributeRefsOfEntityList<TEntity>(
  entities: ReadonlyArray<TEntity>,
  schema: Schema<TEntity>
) {
  // map over the list, then iterate through values looking for attribute refs if the entity
  // has a `based_on` attribute.
  let targetFieldRefs: ReadonlyArray<AttributeRef.Entity> = []
  entities.map((entity) => {
    targetFieldRefs = [
      ...targetFieldRefs,
      ...getAttributeRefsFromEntity(entity, schema),
    ]
  })

  // resolve attribute refs for target values, if there are any.
  if (targetFieldRefs.length) {
    try {
      const resolvedValues: ResolveLinkableFieldValuesResponse.Entity = yield* unaryGRPC(
        'mos.linked_field_resolver.LinkedFieldResolver/ResolveLinkableFieldValues',
        {
          type: ResolveLinkableFieldValuesRequest.refName,
          targetFieldRefs,
        },
        ResolveLinkableFieldValuesRequest.codec,
        ResolveLinkableFieldValuesResponse.codec,
      )

      // replace values with static values.
      const resolvedValuesEntities = entities.reduce((parsedEntities: ReadonlyArray<any>, entity) => {
        const resolvedValuesEntity = Object.entries(entity).reduce((parsedEntity, [key, rawValue]) => {
          const value: LinkableValue = schema[key] ? schema[key].read(rawValue) : rawValue

          // we have an attribute ref to attempt to resolve.
          if (value && isAttributeRef(value)) {
            const targetValue = getResolvedValue(value, resolvedValues.resolvedValueResults)

            // if we find a matching resolved value for the attribute ref, replace with a literal
            // value. otherwise it is replaced with undefined. i think we should gracefully degrade
            // in this situation, but potentially log this once logging is available.
            if (targetValue) {
              return {
                ...parsedEntity,
                ...{ [key]: schema[key].write(targetValue)
                },
              }
            }
          }

          // otherwise this value wasn't an attribute ref. leave the existing value at this key.
          return {
            ...parsedEntity,
            ...{ [key]: rawValue },
          }
        }, {})

        // add the parsed entity.
        return [
          ...parsedEntities,
          resolvedValuesEntity,
        ]
      }, [])
      return resolvedValuesEntities

    } catch (error) {
      return error
    }
  }

  return entities
}

// returns a list of options to pass into a SelectRef as attribute ref values for a particular
// attribute within an entity (based on field mapping and value resolver responses).
export const getAlternateFieldValues = <TEntity>(
  attributeName: keyof TEntity,
  fieldMappings: ReadonlyArray<GetLinkedFieldMappingsResponse.Mapping.Entity>,
  resolvedValues: ReadonlyArray<ResolvedValueResult.Entity>,
  customLabel?: (resolvedValue: ResolvedValueResult.Value.Entity) => string
) => {
  const mappingForField = fieldMappings.find(mapping =>
    mapping.sourceAttributeName === camelToSnakeCase(attributeName as string))

  const targetNames = mappingForField && mappingForField.targetAttributeNames

  // ignores errors, including if a value isn't available, or the attribute resolver isn't
  // implemented.
  const targetValues = targetNames && targetNames
    .map(attributeName => {
      const resolvedValue = resolvedValues
        .filter(value =>
          value &&
          value.result &&
          value.result.type === 'mos.linked_field_resolver.ResolvedValueResult.Value')
        .find(value =>
          value.type === 'mos.linked_field_resolver.ResolvedValueResult' &&
          value.result &&
          value.result.targetFieldRef &&
          value.result.targetFieldRef.attributeName === attributeName)

      if (resolvedValue &&
        resolvedValue.result &&
        resolvedValue.result.type === 'mos.linked_field_resolver.ResolvedValueResult.Value' &&
        resolvedValue.result.targetFieldRef
      ) {
        return {
          value: resolvedValue.result.targetFieldRef,
          label: `${resolvedValue.result.targetFieldDisplayName}${
            customLabel
              ? ` - ${customLabel(resolvedValue.result)}`
              : typeof extractResolvedValue(resolvedValue.result.targetValue) ===
                "string"
                  ? ` - ${extractResolvedValue(resolvedValue.result.targetValue)}`
                  : ""
              }`,
        }
      }
    })

  return targetValues && targetValues.filter(value => value !== undefined) as any
}
