import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button, ButtonGroup } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { SelectRef } from 'components/ui/select'
import { connect } from 'containers/store';
import { Building, Site } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import { Status, statusSelector } from 'helpers/status';

import { structuresActionCreators } from 'domains/structures/actions';
import { DataStatus } from 'domains/structures/store';
import { Structures } from 'domains/structures/entities';

type DirectProps = {
  readonly onSuccess: (ref: Building.Ref) => void;
};

type ActionProps = Pick<typeof structuresActionCreators, 'buildingCreateReset' | 'buildingCreateSaveRequest' | 'structuresLoadRequest'>;

type ConnectedProps = {
  readonly buildingCreate: DataStatus<Building.Ref>;
  readonly structures: DataStatus<Structures>;
};

type Props = ActionProps & ConnectedProps & DirectProps;

type FormValues = {
  readonly name: string;
  readonly siteRef: Site.Ref | undefined;
};

type State = {
  readonly bag: FormBag<FormValues>;
};

class BuildingCreateFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ name: '', siteRef: undefined }),
    };
  }

  public componentDidMount() {
    this.props.buildingCreateReset();
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  public componentDidUpdate() {
    if (this.props.buildingCreate.status === Status.Ready) {
      this.props.onSuccess(this.props.buildingCreate.data);
    }
  }

  private onSubmit = () => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    const { buildingCreateSaveRequest, onSuccess } = this.props;
    const { values } = bag;

    buildingCreateSaveRequest({
      siteRef: Site.mustRef(values.siteRef),
      name: values.name,
    });
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    if (!values.siteRef) {
      errors.siteRef = 'Required';
    }
    return errors;
  }

  public render() {
    const { errors, valid, touched } = this.state.bag;

    const loading = this.props.buildingCreate.status === Status.Updating;
    const structures = statusSelector.data(this.props.structures);
    const sites = structures ? structures.sites : [];

    const siteOpts = sites.map((site) => ({
      label: site.name,
      value: Site.mustRef(site.ref),
    }));

    return (
      <form noValidate onSubmit={(e) => { e.preventDefault(); this.onSubmit(); }}>
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
          <SelectRef<FormValues, 'siteRef'>
            label="Site"
            field="siteRef"
            options={siteOpts}
            error={touched.siteRef ? errors.siteRef : undefined}
          />
          <Input
            label="Name"
            field="name"
            error={touched.name ? errors.name : undefined}
          />

          <ButtonGroup>
            <Button type="submit" isDisabled={!valid}>
              {loading ? 'Creating...' : 'Create building'}
            </Button>
          </ButtonGroup>

        </FormData>
      </form>
    );
  }
}

export const BuildingCreateForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.structures, 'buildingCreate', 'structures'),
  pick(structuresActionCreators, 'buildingCreateReset', 'buildingCreateSaveRequest', 'structuresLoadRequest'),
)(BuildingCreateFormView);
