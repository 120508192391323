/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconLevels = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M17 16.999v4H0v-4h17zM20.5 10v4h-17v-4h17zm3.5-7v4H7v-4h17z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconLevelsString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M17 16.999v4H0v-4h17zM20.5 10v4h-17v-4h17zm3.5-7v4H7v-4h17z"  fillRule="nonzero" />
  </svg>`

IconLevels.displayName = 'IconLevels'
