import { ActionType, ActionsType } from 'containers/core';
import { Building, Site } from 'generated/mos/structure';
import { Polygon2D } from "helpers/geo";
import { StructuresMessage } from '../store';

export const actionCreators = {
  buildingCreateReset: () => 
    ({ domain: 'structures' as const, type: 'buildingCreateReset' as const }),
  buildingEditReset: () =>
    ({ domain: 'structures' as const, type: 'buildingEditReset' as const }),

  buildingEditLoadRequest: (ref: Building.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingEditLoadRequest' as const, ref }),
  buildingEditLoadFailure: (ref: Building.Ref, message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingEditLoadFailure' as const, ref, message }),
  buildingEditLoadSuccess: (ref: Building.Ref, entity: Building.Entity) =>
    ({ domain: 'structures' as const, type: 'buildingEditLoadSuccess' as const, ref, entity }),

  buildingEditSaveRequest: (ref: Building.Ref, payload: {
    name: string;
    boundary?: Polygon2D;
  }) => ({ domain: 'structures' as const, type: 'buildingEditSaveRequest' as const, ref, payload }),
  buildingEditSaveFailure: (ref: Building.Ref, message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingEditSaveFailure' as const, ref, message }),
  buildingEditSaveSuccess: (ref: Building.Ref, entity: Building.Entity) =>
    ({ domain: 'structures' as const, type: 'buildingEditSaveSuccess' as const, ref, entity}),

  buildingCreateSaveRequest: (args: { siteRef: Site.Ref; name: string }) =>
    ({ domain: 'structures' as const, type: 'buildingCreateSaveRequest' as const, ...args }),
  buildingCreateSaveFailure: (message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingCreateSaveFailure' as const, message }),
  buildingCreateSaveSuccess: (ref: Building.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingCreateSaveSuccess' as const, ref }),

  buildingEditDeleteRequest: (ref: Building.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingEditDeleteRequest' as const, ref }),
  buildingEditDeleteFailure: (ref: Building.Ref, message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingEditDeleteFailure' as const, ref, message}),
  buildingEditDeleteSuccess: (ref: Building.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingEditDeleteSuccess' as const, ref }),

  buildingEditFailure: (ref: Building.Ref, message: string) =>
    ({ domain: 'structures' as const, type: 'buildingEditFailure' as const, ref, message }),
};

export type ActionCreators = typeof actionCreators;
export type BuildingAction = ActionType<ActionCreators>;
export type BuildingActions = ActionsType<ActionCreators>;
