import { Ref } from "entity";

// This is used to allow type-safe filtering of null or undefined values from
// a list. Use it like so:
//
//  const maybeStuff: Array<string | undefined | null> = [1, null, 2];
//
//  // The type of 'stuff' will be 'Array<string | undefined | null>' if
//  // using the traditional filter:
//  const stuff = maybeStuff.filter(Boolean);
//
//  // The type of 'stuff' will be 'Array<string>' if using this type guard:
//  const stuff = maybeStuff.filter(isNotNullOrUndefined);
//
// More info: https://github.com/Microsoft/TypeScript/issues/16069
export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return !!input;
}

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + JSON.stringify(x));
}

// Simplified version of lodash's pick; avoids the temptation to use lodash's
// type-clobbering path expression syntax.
//
// XXX(bw): this doesn't work properly with TS 3.3 if you attempt to use a
// wrapper function. We tried to create an 'actionsPick' function that curried
// in the 'actions' arg as 'v', but the resulting type did not correctly infer
// the picked keys.
export function pick<T, K extends keyof T>(v: T, ...keys: K[]): Pick<T, K> {
  const out: any = {};
  for (const key of keys) {
    out[key] = v[key];
  }
  return out;
}

// This shouldn't need to exist, but TypeScript can't even maintain the consistency
// of its own compromises so it's a bit of a stretch to think they'd bother to find
// a better way to support something like this:
// https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-263132208
export function objectKeys<T extends object>(v: T): Array<keyof T> {
  return Object.keys(v) as Array<keyof T>;
}

// simplified typescript version of https://github.com/dashed/shallowequal
export function shallowEqual(objA: Record<any, any>, objB: Record<any, any>) {
  if (objA === objB) {
    return true;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}


// Exception class that is thrown to indicate a condition that _must never happen_.
// If an Invariant is raised, something should've been caught a long time before.
//
// In terms of a hierarchy, Invariants are worse than Exceptions:
//
// - Error: expected behaviour that deviates from the happy path, should be handled
//   as a branching condition
// - Exception: unexpected behaviour that deviates from happy or error paths, may
//   be handled as a branching condition or propagated to a crash.
// - Invariant: The program is incorrect. An Invariant must never be raised under
//   any circumstances, and must never be caught. Program must crash.
//
// This is mostly used to allow us to encourage TypeScript to believe that we really
// have taken care of various things it might not otherwise be able to check for us.
//
// For another example of the difference between Exceptions and Invariants: you'd
// always disable your code coverage tool's reporting for a line that reports an
// invariant, but you wouldn't do so for an exception.
//
export class Invariant extends Error {
  public name: string

  public constructor(message?: string) {
    super(message)
    this.name = new.target.prototype.constructor.name
    Object.setPrototypeOf(this, new.target.prototype)
  }
}


// NOTE: this won't work until 3.7, when we can use the 'asserts' keyword:
// https://github.com/microsoft/TypeScript/pull/32695
export const assert = (expression: boolean, message: string) => {
  if (!expression) {
    throw new Invariant(message || "assertion failed");
  }
}


// Taken from https://github.com/chodorowicz/ts-debounce which is based on the
// underscore.js debounce function
type Procedure = (...args: any[]) => void;
export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  isImmediate: boolean = false,
): F {
  let timeoutId: NodeJS.Timeout | undefined;

  return function(this: any, ...args: any[]) {
    const context = this;

    const doLater = function() {
      timeoutId = undefined;
      if (!isImmediate) {
        func.apply(context, args);
      }
    }

    const shouldCallNow = isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as any
}

// taken from https://dev.to/joshuapbritz/why-it-s-so-hard-to-check-object-equality-in-javascript-8a0
export const areObjectsEqual = (first: { [key: string]: any }, second: { [key: string]: any }) => {
  const al = Object.getOwnPropertyNames(first)
  const bl = Object.getOwnPropertyNames(second)

  // Check if the two list of keys are the same
  // length. If they are not, we know the objects
  // are not equal.
  if (al.length !== bl.length) return false

  // Check that all keys from both objects match
  // are present on both objects.
  const hasAllKeys = al.every(value => !!bl.find(v => v === value))

  // If not all the keys match, we know the
  // objects are not equal.
  if (!hasAllKeys) return false

  // We can now check that the value of each
  // key matches its corresponding key in the
  // other object.
  for (const key of al) if (first[key] !== second[key]) return false

  // If the object hasn't return yet, at this
  // point we know that the objects are the
  // same
  return true
}

// FIXME: the following utility functions are used to convert the case of object keys and are
// primarily used in places where an orchestration layer should be converting these between the
// backend services and the client application.
export const camelToSnakeCase = (string: string) =>
  string.replace(/[\w]([A-Z])/g, match => match[0] + '_' + match[1]).toLowerCase()

export const snakeToCamelCase = (string: string) =>
  string.replace(/(_\w)/g, match => match[1].toUpperCase())

// Function to return back the desired string for the heading (pluralise or not)
export const pluralise = (count: number, single: string, plural: string): string =>
  `${count !== 1 ? plural : single}`

// util to check equality for ref values
export const areRefValuesEqual = (first: Ref | undefined, second: Ref | undefined) => {
  if (!first || !second) {
    return first === second
  }
  return areObjectsEqual(first, second)
}

// compares two lists of refs for absolute equality.
export const areArraysOfRefsEqual = (first: Array<Ref>, second: Array<Ref>) => {
  // if they don't contain the same number of children, they aren't equal.
  if (first.length !== second.length) {
    return false
  }

  // sort both arrays by ref id.
  const comparison = (a: Ref, b: Ref) => a.id < b.id ? -1 : 1
  first.sort(comparison)
  second.sort(comparison)

  return first.every((ref: Ref, i: number) => areRefValuesEqual(ref, second[i]))
}

