
import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import {
  ListReleasableDevicesResponse,
  PublishDevicesRequest,
  PublishDevicesResponse,
  ListReleasesRequest,
  ListReleasesResponse,
  SyncLabelRequest,
  SyncLabelResponse,
  SyncLabelFailure,
  SyncLabelReset,
  ReleaseSummaryRequest,
  ReleaseSummaryResponse,
  ReleaseSummaryFailure,
} from '../types'
import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const publishingActionCreators = {
  wamListReleasableDevicesRequest: (): FluxStandardAction<undefined, 'wamListReleasableDevicesRequest'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasableDevicesRequest' as const,
    payload: undefined
  }),
  wamListReleasableDevicesSuccess: (
    payload: ListReleasableDevicesResponse,
  ): FluxStandardAction<ListReleasableDevicesResponse, 'wamListReleasableDevicesSuccess'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasableDevicesSuccess' as const,
    payload,
  }),
  wamListReleasableDevicesFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamListReleasableDevicesFailure'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasableDevicesFailure' as const,
    payload,
    error: true,
  }),
  wamPublishDevicesRequest: (payload: PublishDevicesRequest): FluxStandardAction<PublishDevicesRequest, 'wamPublishDevicesRequest'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamPublishDevicesRequest' as const,
    payload
  }),
  wamPublishDevicesSuccess: (
    payload: PublishDevicesResponse,
  ): FluxStandardAction<PublishDevicesResponse, 'wamPublishDevicesSuccess'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamPublishDevicesSuccess' as const,
    payload,
  }),
  wamPublishDevicesFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamPublishDevicesFailure'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamPublishDevicesFailure' as const,
    payload,
    error: true,
  }),
  wamPublishDevicesReset: (): FluxStandardAction<undefined, 'wamPublishDevicesReset'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamPublishDevicesReset' as const,
    payload: undefined
  }),
  wamListReleasesRequest: (payload: ListReleasesRequest): FluxStandardAction<ListReleasesRequest, 'wamListReleasesRequest'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasesRequest' as const,
    payload,
  }),
  wamListReleasesSuccess: (
    payload: ListReleasesResponse,
  ): FluxStandardAction<ListReleasesResponse, 'wamListReleasesSuccess'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasesSuccess' as const,
    payload,
  }),
  wamListReleasesFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamListReleasesFailure'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasesFailure' as const,
    payload,
    error: true,
  }),
  wamListReleasesReset: (): FluxStandardAction<undefined, 'wamListReleasesReset'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamListReleasesReset' as const,
    payload: undefined
  }),
  wamReleaseSummaryRequest: (payload: ReleaseSummaryRequest): FluxStandardAction<ReleaseSummaryRequest, 'wamReleaseSummaryRequest'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamReleaseSummaryRequest' as const,
    payload,
  }),
  wamReleaseSummarySuccess: (
    payload: ReleaseSummaryResponse,
  ): FluxStandardAction<ReleaseSummaryResponse, 'wamReleaseSummarySuccess'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamReleaseSummarySuccess' as const,
    payload,
  }),
  wamReleaseSummaryFailure: (
    payload: ReleaseSummaryFailure,
  ): FluxStandardAction<ReleaseSummaryFailure, 'wamReleaseSummaryFailure'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamReleaseSummaryFailure' as const,
    payload,
  }),
  wamReleaseSummaryReset: () => ({
    domain: 'wam.publishing' as const,
    type: 'wamReleaseSummaryReset' as const,
  }),
  wamSyncLabelRequest: (payload: SyncLabelRequest): FluxStandardAction<SyncLabelRequest, 'wamSyncLabelRequest'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamSyncLabelRequest' as const,
    payload,
  }),
  wamSyncLabelSuccess: (
    payload: SyncLabelResponse,
  ): FluxStandardAction<SyncLabelResponse, 'wamSyncLabelSuccess'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamSyncLabelSuccess' as const,
    payload,
  }),
  wamSyncLabelFailure: (
    payload: SyncLabelFailure,
  ): FluxStandardAction<SyncLabelFailure, 'wamSyncLabelFailure'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamSyncLabelFailure' as const,
    payload,
  }),
  wamSyncLabelReset: (
    payload: SyncLabelReset,
  ): FluxStandardAction<SyncLabelReset, 'wamSyncLabelReset'> => ({
    domain: 'wam.publishing' as const,
    type: 'wamSyncLabelReset' as const,
    payload,
  }),
}

type WamAction = ReturnType<typeof publishingActionCreators[keyof typeof publishingActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.publishing'
}

export const publishingReducer = (
  state: WamAppState['publishing'] = initialWamAppState.publishing,
  action: WamAction,
): WamAppState['publishing'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamListReleasableDevicesRequest': {
      if (state.listDevices.status === Status.Ready) {
        return {
          ...state,
          listDevices: {
            status: Status.Updating,
            data: state.listDevices.data
          }
        }
      }
      return {
        ...state,
        listDevices: {
          status: Status.Loading,
        },
      }
    }
    case 'wamListReleasableDevicesSuccess': {
      const { payload } = action
      return {
        ...state,
        listDevices: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamListReleasableDevicesFailure': {
      const { payload } = action
      return {
        ...state,
        listDevices: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamPublishDevicesRequest': {
      return {
        ...state,
        release: {
          status: Status.Loading,
        },
      }
    }
    case 'wamPublishDevicesSuccess': {
      return {
        ...state,
        release: {
          status: Status.Complete,
          message: 'Release has completed'
        },
      }
    }
    case 'wamPublishDevicesFailure': {
      const { payload } = action
      return {
        ...state,
        release: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamPublishDevicesReset': {
      return {
        ...state,
        release: {
          status: Status.Idle,
        },
      }
    }
    case 'wamListReleasesRequest': {

      if (state.listReleases.status === Status.Ready) {
        return {
          ...state,
          listReleases: {
            ...state.listReleases,
            status: Status.Updating,

          },
        }
      }

      return {
        ...state,
        listReleases: {
          status: Status.Loading,
        },
      }
    }
    case 'wamListReleasesSuccess': {
      const { payload } = action
      return {
        ...state,
        listReleases: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamListReleasesFailure': {
      const { payload } = action
      return {
        ...state,
        listReleases: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamListReleasesReset': {
      return {
        ...state,
        listReleases: {
          status: Status.Idle,
        },
      }
    }
    case 'wamReleaseSummaryRequest': {
      const { payload: { id } } = action
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [id]: {
            status: Status.Loading,
          }
        }
      }
    }
    case 'wamReleaseSummarySuccess': {
      const { payload } = action
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [payload.id]: {
            status: Status.Ready,
            data: payload
          }
        }
      }
    }
    case 'wamReleaseSummaryFailure': {
      const { payload: { id, error } } = action
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [id]: {
            status: Status.Failed,
            messages: [failureStatusMessage(error.toString())],
          }
        }
      }
    }
    case 'wamReleaseSummaryReset': {
      return {
        ...state,
        summaries: {}
      }
    }
    case 'wamSyncLabelRequest': {
      const { payload: { id } } = action
      return {
        ...state,
        sync: {
          ...state.sync,
          [id]: {
            status: Status.Loading,
          }
        }
      }
    }
    case 'wamSyncLabelSuccess': {
      const { payload: { id } } = action
      return {
        ...state,
        sync: {
          ...state.sync,
          [id]: {
            status: Status.Complete,
            message: ''
          }
        }
      }
    }
    case 'wamSyncLabelFailure': {
      const { payload: { id, error } } = action
      return {
        ...state,
        sync: {
          ...state.sync,
          [id]: {
            status: Status.Failed,
            messages: [failureStatusMessage(error.toString())],
          }
        }
      }
    }
    case 'wamSyncLabelReset': {
      const { payload: { id } } = action
      return {
        ...state,
        sync: {
          ...state.sync,
          [id]: {
            status: Status.Idle,
          }
        }
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
