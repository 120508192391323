import React from 'react'

import styled from 'styled'
import { space } from 'helpers/style'

import { IconDragHandle } from '../icons'
import { listItemBaseStyles } from '../list-item-label'

export const Container = styled.div<{ isDragging?: boolean}>`
  border-radius: 0 0 ${space(1)} ${space(1)};
  padding-bottom: ${space(3)};

  ${({ theme, isDragging }) => isDragging
    && `
      background-color: ${theme.color.primaryL90};
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
      padding-bottom: 0;
    `
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
`

export const Handle = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: ${space(-2)};
`;

export const DragHandle = () => (
  <Handle>
    <IconDragHandle size="medium" />
  </Handle>
)

export const ItemIndex = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  background-color: ${({ theme }) => theme.color.grayL80};
  height: ${space(6)};
  width: ${space(6)};
  border-radius: 50%;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: ${space(4)};
`;

export const Wrap = styled.div<{ isDragging?: boolean; isDragDisabled?: boolean; listItemLink?: boolean}>`
  ${listItemBaseStyles}

  border-radius: ${space(1)};
  padding: ${space(3)};
  margin-bottom: 0;

  ${({ theme, isDragDisabled, isDragging, listItemLink }) => `
    background-color: ${isDragging ? theme.color.primaryL90
                        : isDragDisabled ? theme.color.grayL90 : theme.color.white};
    border: ${isDragging ? `2px solid ${theme.color.primary}`: isDragDisabled ? `1px solid ${theme.color.grayL80}` : `1px solid ${theme.color.grayL90}` };
    cursor: ${listItemLink ? `pointer` : `grab`}
  `}

  &:hover {
    border: ${({ theme, isDragDisabled }) => isDragDisabled ? `1px solid ${theme.color.grayL80}` : `1px solid ${theme.color.grayL90}` };
  }

  ${({ isDragDisabled }) => isDragDisabled
    && `
      &:hover{
        cursor: not-allowed;
      }
    `
  }
`
