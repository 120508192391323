import React from 'react'

import styled from 'styled';
import { space } from 'helpers/style'

const SecurityNoteWrapper = styled.div`
  border-radius: 2px;
  background-color: #f6f6f6;
  padding: ${space(4)};
  text-align: center;
  margin-top: ${space(8)};
`;

const SecurityNoteHeading = styled.p`
  color: #454545;
  font-size: 13px;
  line-height: 17px;
  margin-top: ${space(1)};
`;

const SecurityNoteText = styled.p`
  color: #7a7a7a;
  font-size: 10px;
  line-height: 14px;
`;


export const SecurityNote = () => (
  <SecurityNoteWrapper>
    <SecurityNoteHeading>Security is important to us</SecurityNoteHeading>
    <SecurityNoteText>
      Please ensure your password has <strong>at least</strong> one capital, one numeric and one
    non-alphanumeric character/s to be a valid password. Please avoid the usage of any common words that may be easy to guess!
    </SecurityNoteText>
  </SecurityNoteWrapper>
);
