import * as geo from 'helpers/geo';
import { escapeHtml } from 'helpers/html-escape';

import { PointRef } from 'components/cluster-map'
import { Addable, AddableManager, MapSet, Marker, MarkerEvent, Popup } from 'components/mapbox';
import { AddableComparison } from 'components/mapbox/addable';


// XXX(bw): This file is starting to groan with "just get it working" hacks. It
// really needs an overhaul. Some of the stuff in here points to some minor API
// issues with Addable, but maybe there's some cleanup in here we can do before
// that's necessary.


interface PointMarkerSetOptions {
  readonly active?: PointRef;
  readonly canPosition?: boolean;
  readonly onMoved?: OnPointMoved;
}


export class PointMarkerSet implements MapSet {
  private markers: ReadonlyArray<PointMarker> = [];
  private options: PointMarkerSetOptions;

  public readonly key: string;

  public constructor(key: string, markers: ReadonlyArray<PointMarker>, options: PointMarkerSetOptions = {}) {
    this.markers = markers;
    this.options = options;
    this.key = key;
  }

  public addables(): ReadonlyArray<Addable> {
    const addables: Addable[] = [];

    for (const point of this.markers) {
      const isActive = this.options.active && point.ref && point.ref.id === this.options.active.id;

      if (point.ref && point.ref.id) {
        addables.push(new PointMarkerAddable(point.ref.id, point, {
          isActive,
          canPosition: this.options.canPosition,
          onMoved: this.options.onMoved,
        }));
      }
    }

    return addables;
  }
}


export interface PointMarker {
  readonly title: string;
  readonly description: string;
  readonly ref: PointRef | undefined;
  readonly icon: string;
  readonly position: geo.Point2D | undefined;
}

export type OnPointMoved = (e: {ref: PointRef; pos: geo.Position2D}) => void;

interface PointMarkerOptions {
  readonly isActive?: boolean;
  readonly canPosition?: boolean;
  readonly onMoved?: OnPointMoved;
}


// XXX(bw): There's a little bit too much low-level boilerplate in here; I
// think most of this can be distilled into some helpers and templates for
// "marker with popup" or something.
class PointMarkerAddable implements Addable {
  private _key: string;
  public point: PointMarker;

  private markerElm?: HTMLDivElement;
  private marker?: mapboxgl.Marker;
  private popup?: mapboxgl.Popup;

  private options: PointMarkerOptions;

  public constructor(key: string, point: PointMarker, options: PointMarkerOptions = {}) {
    this._key = key;
    this.point = point;
    this.options = options;
  }

  public get key() {
    return this._key;
  }

  public add(map: AddableManager) {
    this.markerElm = document.createElement('div');
    this.markerElm.id = this.key;
    this.markerElm.innerHTML = this.point.icon;

    // FIXME: these styles come from ./styles.css, should be co-located better
    this.markerElm.classList.add('marker', (this.options.isActive ? 'is-active' : 'is-inactive'));
    if (this.options.isActive && this.options.canPosition) { this.markerElm!.classList.add('is-editing'); }

    // FIXME: re-enable this?
    // if (this.store.uiActivePOI && beacon.ref.id === this.store.uiActivePOI.id) {
    //   markerElm.classList.add('hover');
    // }
    // if (!canPositionBeacon) {
    //   markerElm.classList.add('is-fixed');
    // }

    const popupText = escapeHtml(this.point.title) + '<br>' + escapeHtml(this.point.description);
    this.popup = new Popup({ offset: 15 }).setHTML(popupText);

    this.markerElm.addEventListener('mouseenter', (e) => {
      if (this.popup) {
        this.popup.addTo(map.dangerousMap);
        // uiActions.setUIActiveListItem(this.store, beacon.ref);
      }
    });

    this.markerElm.addEventListener('mouseleave', (e) => {
      if (this.popup) {
        this.popup.remove();
      }
    });

    if (this.point.position) {
      this.marker = new Marker({ element: this.markerElm })
        .setLngLat(geo.asLngLat(this.point.position))
        .setDraggable(!!this.options.isActive && !!this.options.canPosition);

      if (!this.options.isActive || !this.options.canPosition) {
        this.marker.setPopup(this.popup);
      }

      this.marker.on('dragstart', () => this.markerElm!.classList.add('is-dragging'));

      // FIXME: 'any'. No idea what the type is supposed to be, but target is an
      // instance of mapboxgl.Marker. The mapboxgl typings get a bit poor here
      // and don't bear any resemblance to what we actually see:
      this.marker.on('dragend', (e: MarkerEvent) => {
        this.markerElm!.classList.remove('is-dragging');
        if (this.options.onMoved && this.point.ref) {
          const lngLat = e.target.getLngLat();
          this.options.onMoved({ ref: this.point.ref, pos: [lngLat.lng, lngLat.lat]});
        }
      });

      this.marker.addTo(map.dangerousMap);
    }
  }

  public destroy() {
    if (this.popup)  { this.popup.remove(); }
    if (this.marker) { this.marker.remove(); }
  }

  public compare(next: Addable): AddableComparison {
    const nextMarker: this = next as this;

    const updates: Array<() => void> = [];

    if (this.point.position && nextMarker.point.position && !geo.positionEqual(this.point.position.coordinates, nextMarker.point.position.coordinates)) {
      updates.push(() => {
        this.point = nextMarker.point;
        if (nextMarker && nextMarker.point && nextMarker.point.position) {
          this.marker!.setLngLat([
            nextMarker.point.position.coordinates[0],
            nextMarker.point.position.coordinates[1],
          ]); // FIXME: geo-jank
        }
      });
    }

    if ((this.options.isActive !== nextMarker.options.isActive) || (this.options.canPosition !== nextMarker.options.canPosition)) {
      updates.push(() => {
        this.options = nextMarker.options;
        this.marker!.setDraggable(!!nextMarker.options.isActive && !!nextMarker.options.canPosition);
        this.marker!.setPopup(!this.options.isActive || !this.options.canPosition ? this.popup : undefined);
        this.markerElm!.classList.remove('is-active', 'is-inactive');
        this.markerElm!.classList.add(this.options.isActive ? 'is-active' : 'is-inactive');
        this.markerElm!.classList.remove('is-editing');
        if (this.options.isActive && this.options.canPosition) { this.markerElm!.classList.add('is-editing'); }
      });
    }

    if (updates.length) {
      return {
        result: 'update',
        update: () => {
          for (const v of updates) { v(); }
        },
      };
    } else {
      return { result: 'keep' };
    }
  }
}
