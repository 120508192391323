import React from 'react'

import { IconMediaImage } from 'components/ui/icons';
import styled from 'styled';
import { captureException } from 'helpers/logging';

const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grayL80};
  position: relative;
`;

const PlaceHolder = styled.div<{ error: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ error }) => error ? 1 : 0};
  transition: opacity 200ms ease;
`;

const Img = styled.img<{ loaded: boolean }>`
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  opacity: ${({ loaded }) => loaded ? 1 : 0};
  transition: opacity 200ms ease;
  transform: translate(-50%, 0);
  position: absolute;
  left: 50%;
`;

type Props = {
  src: string;
};

export const ImageWithFallback = ({ src, ...passthroughProps }: Props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handledLoad = () => setLoaded(true);
  const handleError = () => {
    // TODO: enable error logging once assets are served via orchestrated projectsion from the DMM.
    // captureException({ error:`[ Image ] Error loading asset from: ${imagePath}` });
    setError(true);
  };

  return (
    <ImageWrapper {...passthroughProps}>
      <PlaceHolder error={error}>
        <IconMediaImage />
      </PlaceHolder>
      <Img
        src={src}
        loaded={loaded}
        onLoad={handledLoad}
        onError={handleError}
      />
    </ImageWrapper>
  );
};
