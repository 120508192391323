import {
  GetLinkedEntityMappingsRequest,
  GetLinkedEntityMappingsResponse,
  GetLinkedFieldMappingsRequest,
  GetLinkedFieldMappingsResponse,
} from 'generated/mos/linkedfieldmappings'

import { assertNever, Invariant } from 'helpers/core'
import { Status, ErrorMessage } from 'helpers/status'

import { LinkableAppState, initialLinkableAppState } from './store'

export const actionCreators = {
  linkableGetLinkedEntityMappingsRequest: (
    sourceTypename: string,
    relationshipName: string = 'based_on',
  ) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedEntityMappingsRequest' as const,
    sourceTypename,
    relationshipName,
  }),
  linkableGetLinkedEntityMappingsSuccess: (
    sourceTypename: string,
    payload: GetLinkedEntityMappingsResponse.Entity
  ) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedEntityMappingsSuccess' as const,
    sourceTypename,
    payload,
  }),
  linkableGetLinkedEntityMappingsFailure: (sourceTypename: string, message: ErrorMessage) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedEntityMappingsFailure' as const,
    sourceTypename,
    message,
  }),
  linkableGetLinkedEntityMappingsReset: () => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedEntityMappingsReset' as const,
  }),
  linkableGetLinkedFieldMappingsRequest: (payload: GetLinkedFieldMappingsRequest.Entity) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedFieldMappingsRequest' as const,
    payload,
  }),
  linkableGetLinkedFieldMappingsSuccess: (payload: GetLinkedFieldMappingsResponse.Entity) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedFieldMappingsSuccess' as const,
    payload,
  }),
  linkableGetLinkedFieldMappingsFailure: (message: ErrorMessage) => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedFieldMappingsFailure' as const,
    message,
  }),
  linkableGetLinkedFieldMappingsReset: () => ({
    domain: 'linkable' as const,
    type: 'linkableGetLinkedFieldMappingsReset' as const,
  }),
}

export type LinkableActionCreators = typeof actionCreators;
export type LinkableAction = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;
export type LinkableActions = {
  [T in keyof LinkableActionCreators]: ReturnType<LinkableActionCreators[T]>;
};

const assertLinkable = (domain: 'linkable') => {
  return domain === 'linkable'
}

export const linkableReducer = (state: LinkableAppState = initialLinkableAppState, action: LinkableAction): LinkableAppState => {
  if (!assertLinkable(action.domain)) {
    return state
  }

  switch (action.type) {
    // {{{ linkable field mappings.
    case 'linkableGetLinkedEntityMappingsRequest': {
      return {
        ...state,
        entityMappings: {
          status:  Status.Loading,
        }
      }
    }
    case 'linkableGetLinkedEntityMappingsSuccess': {
      return {
        ...state,
        entityMappings: {
          data: action.payload,
          errors: [],
          status: Status.Ready,
        }
      }
    }
    case 'linkableGetLinkedEntityMappingsFailure': {
      return {
        ...state,
        entityMappings: {
          messages: [ action.message ],
          status:  Status.Failed,
        }
      }
    }
    case 'linkableGetLinkedEntityMappingsReset': {
      return {
        ...state,
        entityMappings: {
          status:  Status.Idle,
        }
      }
    }

    case 'linkableGetLinkedFieldMappingsRequest': {
      return {
        ...state,
        fieldMappings: {
          status:  Status.Loading,
        }
      }
    }
    case 'linkableGetLinkedFieldMappingsSuccess': {
      return {
        ...state,
        fieldMappings: {
          data: action.payload,
          errors: [],
          status: Status.Ready,
        }
      }
    }
    case 'linkableGetLinkedFieldMappingsFailure': {
      return {
        ...state,
        fieldMappings: {
          messages: [ action.message ],
          status:  Status.Failed,
        }
      }
    }
    case 'linkableGetLinkedFieldMappingsReset': {
      return {
        ...state,
        fieldMappings: {
          status:  Status.Idle,
        }
      }
    }
    // }}} linkable field mappings.

    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
