/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconPlus = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
  </Icon>
)

// for use with mapbox addables.
export const IconPlusString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
  </svg>`

IconPlus.displayName = 'IconPlus'
