import { AnyAction, Dispatch, Middleware } from 'redux'

import { store } from 'containers/store'
import { pick } from 'helpers/core'
import { AuthSession, createAuthSession } from 'services'
import { AccountAppState, AccountStore, initialAccountAppState } from './store'

export const authSessionMiddleware = (((args: { dispatch: Dispatch<any>; getState: () => AccountStore }) => {
  let lastValue: any
  let savingWorks = true

  return (next: Dispatch<any>) => (action: AnyAction) => {
    const out = next(action)

    const state = args.getState().account
    if (savingWorks && lastValue !== state.session) {
      const result = authSessionSave(state.session)
      if (!result.success) {
        savingWorks = false
      } else {
        lastValue = state.session
      }
    }

    return out
  }
}) as any as Middleware<{}, AccountStore, Dispatch>)

export const accountLoadInitialState = (): AccountAppState => {
  const out = initialAccountAppState
  const session = authSessionLoad()
  if (session) {
    return { ...out, session }
  }
  return out
}

export const authStorageKey = 'mos.auth.v1'

export const authSessionLoad = (): AuthSession | undefined => {
  try {
    const item = localStorage.getItem(authStorageKey)
    if (!item) {
      return undefined
    }
    const parsed = JSON.parse(item)
    if (!parsed) {
      return undefined
    }
    return createAuthSession(parsed)

  } catch (e) {
    console.error(`authSessionLoad: failed with error '${e}'`)
    return undefined
  }
}

export const authSessionSave = (session: AuthSession | undefined): { success: boolean } => {
  const saved = session ? { access: session.access, refresh: session.refresh } : null
  try {
    localStorage.setItem(authStorageKey, JSON.stringify(saved))
    return { success: true }
  } catch (e) {
    // XXX: not much we can do yet if it doesn't save, but because this is only auth, the
    // only impact is that you'll have to log in again on refresh.
    return { success: false }
  }
}

export const getTokens = (): Pick<AuthSession, 'access' | 'refresh'> | undefined => {
  const session = store.getState().account.session
  return session ? pick(session, 'access', 'refresh') : undefined
}
