import React from 'react'
import { components } from 'react-select';
import styled from 'styled';

import { IconCaret, IconClose } from 'components/ui/icons';
import { refFromUrn } from 'entity';
import { space } from 'helpers/style'

import { isLevelRef, SpatialOption } from 'domains/structures/entities';

export const classNamePrefix = 'react-select';

export interface OptionType {
  readonly value: string;
  readonly data: SpatialOption;
  readonly key: string;
  readonly label: string;
}

// Below are components that override default components react-select uses internally.
// See: https://react-select.com/components
const MultiValueRemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 28px;
  width: 28px;
  cursor: pointer;
`;
export const MultiValueRemove = (props: any) => {
  return (
    <MultiValueRemoveWrapper onClick={props.innerProps.onClick}>
      <IconClose size="small" color="white" />
    </MultiValueRemoveWrapper>
  );
};

const MultiValueContainerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* hide all the remove icons, except the last one */
  &:not(:nth-last-child(2)) ${MultiValueRemoveWrapper} {
    display: none;
  }
`;
export const MultiValueContainer = (props: { readonly data: OptionType }) => {
  return (
    <MultiValueContainerWrapper>
      <components.MultiValueContainer {...props} />
      {/* All entities get an arrow after, except for levels because nothing can come after them */}
      {!isLevelRef(refFromUrn(props.data.value)) && (
        <span key={props.data.value} style={{ margin: 2 }}>
          <IconCaret rotate={90} size="base" />
        </span>
      )}
    </MultiValueContainerWrapper>
  );
};

// This is not the right way to use styled components - normally hard-coding class names is a
// terrible idea. But in this case it is the cleanest way to style the react-select component.
// See: https://react-select.com/styles#using-classnames
export const SelectWrapper = styled.div<{ isDisabled?: boolean }>`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  width: 600px;

  .${classNamePrefix}__value-container {
    padding: ${space(2)};
    min-height: 48px;
  }

  .${classNamePrefix}__control {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.color.grayL80};
    ${({ theme, isDisabled }) => isDisabled && `
      background-color: ${theme.color.grayL90};
      opacity: 0.8;
    `};
  }

  .${classNamePrefix}__control--is-focused {
    border: 1px solid ${({ theme }) => theme.color.grayL80} ;
    box-shadow: inherit;
  }

  .${classNamePrefix}__option {
    color: ${({ theme }) => theme.color.black};
    ${({ theme, isDisabled }) => isDisabled && `
      cursor: not-allowed;
      color: ${theme.color.grayL60}
    `};
    &:focus {
      background-color: ${({ theme }) => theme.color.grayL90};
    }
  }

  .${classNamePrefix}__multi-value {
    border-radius: 50px;
    ${({ theme, isDisabled }) => !isDisabled && `background-color: ${theme.color.grayL90}`};
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0;
    border: 1px solid ${({ theme }) => theme.color.grayL80};
    background-color: ${({ theme }) => theme.color.white};
  }

  .${classNamePrefix}__multi-value__label {
    color: ${({ theme, isDisabled }) => isDisabled ? theme.color.grayD20 : theme.color.gray};
    text-transform: uppercase;
    font-size: 11px;
    padding: 0;
    margin: 0 ${space(2)};
  }

  /* select last label and make it purple */
  ${MultiValueContainerWrapper}:nth-last-child(2) {
    .${classNamePrefix}__multi-value {
      border: none;
      background: ${({ theme }) => theme.color.primary};
      transition: background 0.2s ease;
    }
    .${classNamePrefix}__multi-value__label {
      color: ${({ theme }) => theme.color.white};
    }
  }

  .${classNamePrefix}__input input {
    font-size: 13px;
    color: ${({ theme }) => theme.color.gray};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  .${classNamePrefix}__menu {
    margin: 0;
    border-radius: 0 0 2px 2px;
    border: 1px solid ${({ theme }) => theme.color.grayL80};
    border-top: none;
    box-shadow: none;
  }

  .${classNamePrefix}__option {
    font-size: 14px;
    color: ${({ theme }) => theme.color.darkText};
  }

  .${classNamePrefix}__option--is-focused {
    background-color: ${({ theme }) => theme.color.grayL90};
  }

  .${classNamePrefix}__option--is-selected {
    background-color: ${({ theme }) => theme.color.white} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
  }

  .${classNamePrefix}__group-heading {
    font-size: 11px;
    color: ${({ theme }) => theme.color.gray};
    margin: ${space(2)} 0 ${space(4)} 0;
  }
`;
