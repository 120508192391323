// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { BundleType, BundleTypeValues, EntityChange, ReleaseControl } from "generated/mos/releasecontrols";
import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarOptional, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";

export namespace CreateEntityChangeRequest {
  export const refName = "mos.release_controls_management.CreateEntityChangeRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
    readonly notes: string,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    notes: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.CreateEntityChangeRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["notes"] = ensureScalar(ctx, v["notes"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace CreateEntityChangeResponse {
  export const refName = "mos.release_controls_management.CreateEntityChangeResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly entityChange: EntityChange.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    entityChange: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["entityChange"] = EntityChange.codec.encode(v.entityChange);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.CreateEntityChangeResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["entityChange"] = ctx.decode(EntityChange.codec, v["entityChange"], "entityChange");
      return out as any as Entity;
    }
  }();
}

export namespace EntityDeletedRequest {
  export const refName = "mos.release_controls_management.EntityDeletedRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.EntityDeletedRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace EntityDeletedResponse {
  export const refName = "mos.release_controls_management.EntityDeletedResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.EntityDeletedResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace SetLastPublishedRequest {
  export const refName = "mos.release_controls_management.SetLastPublishedRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly refs: ReadonlyArray<{ readonly typename: string, readonly id: string }>,
    readonly bundles: ReadonlyArray<BundleType>,
    readonly publishedAt: Timestamp | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    refs: [],
    bundles: [],
    publishedAt: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["refs"] = v.refs;
      obj["bundles"] = v.bundles;
      obj["publishedAt"] = v.publishedAt ? v.publishedAt.value : "";
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.SetLastPublishedRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["refs"] = v["refs"];
      {
        const evs = ensureScalarRepeated(ctx, v["bundles"], "string").map(i => i || defaults["bundles"]);
        for (const ev of evs) {
          if (!BundleTypeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.release_controls.BundleType`);
          }
        }
        out["bundles"] = evs as any;
      }
      {
        const tsv = ensureScalarOptional(ctx, v["publishedAt"], "string");
        out["publishedAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      return out as any as Entity;
    }
  }();
}

export namespace SetLastPublishedResponse {
  export const refName = "mos.release_controls_management.SetLastPublishedResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly releaseControls: ReadonlyArray<ReleaseControl.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    releaseControls: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["releaseControls"] = [];
      for (const item of v.releaseControls) {
        obj["releaseControls"].push(ReleaseControl.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.SetLastPublishedResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["releaseControls"] = decodeMessageRepeated(ctx, ReleaseControl.codec, v["releaseControls"], "releaseControls");
      return out as any as Entity;
    }
  }();
}

export namespace SetReleaseStatusRequest {
  export const refName = "mos.release_controls_management.SetReleaseStatusRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
    readonly bundles: ReadonlyArray<SetReleaseStatusRequest.BundleStatus.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    bundles: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.SetReleaseStatusRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["bundles"] = decodeMessageRepeated(ctx, SetReleaseStatusRequest.BundleStatus.codec, v["bundles"], "bundles");
      return out as any as Entity;
    }
  }();
  export namespace BundleStatus {
    export const refName = "mos.release_controls_management.SetReleaseStatusRequest.BundleStatus" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly bundleType: BundleType,
      readonly include: boolean,
    }
    export const defaults: Entity = {
      type: refName,
      bundleType: "BUNDLE_TYPE_UNSPECIFIED",
      include: false,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.SetReleaseStatusRequest.BundleStatus");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        {
          const ev = ensureScalar(ctx, v["bundleType"], "string") || defaults["bundleType"];
          if (!BundleTypeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.release_controls.BundleType`);
          }
          out["bundleType"] = ev as any;
        }
        out["include"] = ensureScalar(ctx, v["include"], "boolean");
        return out as any as Entity;
      }
    }();
  }

}

export namespace SetReleaseStatusResponse {
  export const refName = "mos.release_controls_management.SetReleaseStatusResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly releaseControl: ReleaseControl.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    releaseControl: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["releaseControl"] = ReleaseControl.codec.encode(v.releaseControl);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_management.SetReleaseStatusResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["releaseControl"] = ctx.decode(ReleaseControl.codec, v["releaseControl"], "releaseControl");
      return out as any as Entity;
    }
  }();
}

