import { LayerDef, MapSet, SourceDef, SourceDefs } from 'components/mapbox';
import * as geo from 'helpers/geo';
import * as mapStyles from 'helpers/map-styles';


const defaultSize = 22;
const sizes = [
  { moreThan: 20,  size: 24 },
  { moreThan: 50,  size: 26 },
  { moreThan: 100, size: 28 },
];

const styleClusters: mapboxgl.CirclePaint = {
  'circle-color': mapStyles.poiClusterColor,
  'circle-radius': [
    'step',

    // FIXME(bw): this will raise the error "Expected value to be of type number,
    // but found null instead." in the console. I tried wrapping it in 'to-number',
    // which makes the single-beacon clusters appear at the largest possible size
    // instead of the smallest for some reason. I tried 'case', but you can't
    // recursively resolve expressions in a case statement.
    //
    // We'll have to come to grips with the outrageously awful expression API at
    // some point, but today is not that day.
    ['get', 'point_count'],

    defaultSize,

    // This flattens the sizes into a single array of numbers:
    ...sizes.reduce((acc, v) => { acc.push(v.moreThan, v.size); return acc; }, [] as number[]),
  ],
  'circle-stroke-width': 2,
  'circle-stroke-color': '#ffffff',
};

const styleClusterCount = {
  'text-color': '#ffffff',
};

export class PointClusterSet implements MapSet {
  private _poiSource: SourceDef;
  private _sources: SourceDefs = {};
  private _layers: LayerDef[];

  public readonly key: string;

  public constructor(key: string, dataIdentity: ReadonlyArray<any>, pois: ReadonlyArray<geo.Point2D>) {
    this.key = key;

    this._poiSource = {
      id: key,
      dataIdentity: dataIdentity,
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: geo.mut(geo.asFeatures(pois)),
      },
      cluster: true,
    };

    const sourceName = `${this.key}/pois`;

    this._layers = [
      {
        id: `${this.key}/clusters`,
        source: this._poiSource,
        type: 'circle',
        paint: styleClusters,
      },
      {
        id: `${this.key}/counts`,
        source: this._poiSource,
        type: 'symbol',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], // FIXME: these fonts won't exist on most platforms?
          'text-size': 12,
        },
        paint: styleClusterCount,
      },
    ];

    this._sources[sourceName] = this._poiSource;
  }

  public layers(): LayerDef[] { return this._layers; }
}
