import React from 'react'

import { Button } from 'components/ui/button'
import { IconPlus } from 'components/ui/icons'
import { space } from 'helpers/style'
import styled from 'styled';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${space(-3)};
`;

type Props = {
  readonly title: string;
  readonly onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AddAttributeButton = (props: Props) => {
  const { onClick, title } = props;
  return (
    <Wrapper>
      <p>{title}</p>
      <Button appearance="secondary" isIcon={true} isSmall={true} onClick={onClick}>
        <IconPlus size="base" />
      </Button>
    </Wrapper>
  );
};
