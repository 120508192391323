/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconRotate = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M11.016 2.094l4.546 4.547-4.546 4.453v-3.89c-1.407.25-2.586.93-3.54 2.039-.953 1.11-1.43 2.398-1.43 3.867 0 1.469.477 2.758 1.43 3.867.954 1.11 2.133 1.79 3.54 2.04v2.015c-1.97-.25-3.625-1.125-4.97-2.625-1.343-1.5-2.015-3.266-2.015-5.297 0-2.031.672-3.797 2.016-5.297 1.344-1.5 3-2.375 4.969-2.625V2.094zm4.453 15.89l1.453 1.454a8.072 8.072 0 01-3.89 1.594v-2.016a5.999 5.999 0 002.437-1.031zm4.5-3.89a7.847 7.847 0 01-1.64 3.89l-1.407-1.405a6.013 6.013 0 001.031-2.485h2.016zm-1.64-5.86a7.847 7.847 0 011.64 3.891h-2.016a6.013 6.013 0 00-1.031-2.484l1.406-1.406z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconRotateString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M11.016 2.094l4.546 4.547-4.546 4.453v-3.89c-1.407.25-2.586.93-3.54 2.039-.953 1.11-1.43 2.398-1.43 3.867 0 1.469.477 2.758 1.43 3.867.954 1.11 2.133 1.79 3.54 2.04v2.015c-1.97-.25-3.625-1.125-4.97-2.625-1.343-1.5-2.015-3.266-2.015-5.297 0-2.031.672-3.797 2.016-5.297 1.344-1.5 3-2.375 4.969-2.625V2.094zm4.453 15.89l1.453 1.454a8.072 8.072 0 01-3.89 1.594v-2.016a5.999 5.999 0 002.437-1.031zm4.5-3.89a7.847 7.847 0 01-1.64 3.89l-1.407-1.405a6.013 6.013 0 001.031-2.485h2.016zm-1.64-5.86a7.847 7.847 0 011.64 3.891h-2.016a6.013 6.013 0 00-1.031-2.484l1.406-1.406z"  fillRule="nonzero" />
  </svg>`

IconRotate.displayName = 'IconRotate'
