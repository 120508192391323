import React from 'react'

import { Button } from 'components/ui/button'
import { IconClose, IconSearch } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled from 'styled';

const Wrapper = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme, open }) => open ? theme.color.gray : theme.color.grayL90};
  border-radius: 50px;
  transition: all 200ms ease-in-out;
  height: 32px;
  min-width: 34px;
`;

const Input = styled.input<{ open: boolean }>`
  color: ${({ theme }) => theme.color.gray};
  font-size: 11px;
  border: none;
  background: none;
  transition: all 200ms ease-in-out;
  width: ${({ open }) => open ? 200 : 0}px;
  padding: ${({ open }) => open ? `0 ${space(14)} 0 ${space(4)}` : 0};
  &:focus {
    outline: none;
  }
`;

const SearchButton = styled(Button)<{ open?: boolean }>`
  position: absolute;
  right: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  ${({ open }) => !open ? null : `
    border: 0;
    background: none;
  `}
`;

const CloseButton = styled(Button)<{ open?: boolean }>`
  opacity: ${({ open }) => open ? 1 : 0 };
  position: absolute;
  right: 32px;
  padding: ${space(1)} ${space(2)};
  background: transparent;
  border: 0;
  border-radius: 0;
`;

type Props = {
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onClear?: () => void;
  readonly value?: string;
};

type State = {
  readonly open: boolean;
};

export class HiddenSearchInput extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  public constructor(props: Props) {
    super(props);
    this.state = { open: false };
    this.inputRef = React.createRef();
  }

  // getDerivedStateFromProps used instead of componentDidMount to prevent unwanted animation
  public static getDerivedStateFromProps = (props: Props, state: State): State | null => {
    return props.value && !state.open ? { open: true } : null;
  }

  private toggleOpen = () => this.setState((state) => {
    if (!state.open) {
      // focus input on open
      if (this.inputRef.current) { this.inputRef.current.focus(); }
      return { open: true };
    } else if (this.props.value === '') {
      // only allowing closing if no input text
      return { open: false };
    }
    return null;
  })

  private onClear = () => {
    if (this.props.onClear) { this.props.onClear(); }
    this.setState({ open: false });
  }

  public render = () => (
    <Wrapper open={this.state.open}>
      <Input
        onChange={this.props.onChange}
        open={this.state.open}
        ref={this.inputRef}
        type="text"
        value={this.props.value}
      />

      {this.props.onClear && (
        <CloseButton
          appearance="secondary"
          isIcon={true}
          isSmall={true}
          onClick={this.onClear}
          open={this.state.open}
        >
          <IconClose size="small" />
        </CloseButton>
      )}

      <SearchButton
        appearance="secondary"
        isIcon={true}
        isSmall={true}
        onClick={this.toggleOpen}
        open={this.state.open}
      >
        <IconSearch size="small" />
      </SearchButton>
    </Wrapper>
  )
}
