import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'
import { uploadActionCreators } from '../actions/upload'
import { UploadResponse } from '../types'

const bulkUploadSaga = wamTakeLeading('wamUploadRequest', function* (action) {
  try {
    const formdata = new FormData()
    action.payload.forEach(file => formdata.append('files', file, file.name))
    const response: any = yield* httpRest<FormData, UploadResponse>('bulk-upload', formdata, 'POST')
    if (!response) {
      throw new Invariant('[ bulkUploadSaga ] Missing response payload')
    }
    yield put(uploadActionCreators.wamUploadSuccess(response))
  } catch (error) {
    yield put(uploadActionCreators.wamUploadFailure(error.message))
  }
})

export const uploadSagas: ReadonlyArray<Effect> = [
  bulkUploadSaga,
]
