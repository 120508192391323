import React from 'react'

import { Button } from 'components/ui/button'
import { IconPlus } from 'components/ui/icons';
import { HiddenSearchInput } from 'components/ui/hidden-search-input';
import { space } from 'helpers/style'
import styled from 'styled';

const panelHeight = '90px';

const Wrapper = styled.div`
  padding: 0 ${space(4)} 0 ${space(6)};
  height: ${panelHeight};
  color: ${({ theme })  => theme.color.grayL40};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin: 0;
`;

const AddButton = styled(Button)`
  margin-left: ${space(2)};
`

type Props = {
  readonly title: string;
  readonly onAdd: () => void;
  readonly onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly searchText?: string;
  readonly onSearchClear?: () => void;
};

export const PanelHeader = ({ title, onAdd, onSearchChange, onSearchClear, searchText }: Props) => (
  <Wrapper>
    <Title>{title}</Title>
    <div style={{ display: 'flex' }}>
      {!!onSearchChange && <HiddenSearchInput onChange={onSearchChange} onClear={onSearchClear} value={searchText} />}
      <AddButton isIcon={true} isSmall={true} onClick={onAdd}>
        <IconPlus size="base" />
      </AddButton>
    </div>
  </Wrapper>
);

export const FullHeightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PanelBody = styled.div`
  max-height:calc(100% - ${panelHeight});
  height: 100%;
`;
