import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { IconBeacon } from 'components/ui/icons'
import { FlatListItem } from 'components/ui/list-item';
import { PopOverMenu, PopOverMenuLink } from 'components/ui/pop-over-menu';
import { Beacon } from 'generated/mos/beacon'
import { debounce } from 'helpers/core';

const ITEM_HEIGHT_PX = 64;

type Props = {
  readonly active?: boolean;
  readonly items: ReadonlyArray<Beacon.Entity>;
  readonly onClick: (ref: Beacon.Ref) => void;
  readonly onMouseEnter: (ref: Beacon.Ref) => void;
  readonly onMouseLeave: (ref: Beacon.Ref) => void;

  // FIXME implement scroll to index based on selected ref
  readonly scrollToIndex?: number;
  readonly highlightedRef: Beacon.Ref | undefined;
};

export class ItemList extends React.Component<Props, {}> {
  private rowRenderer = ({ index, style }: ListChildComponentProps) => {
    const { items, onClick, onMouseEnter, onMouseLeave } = this.props;

    const item = items[index];
    const ref = Beacon.mustRef(item.ref);

    return (
      <div style={style}>
        <FlatListItem
          height={ITEM_HEIGHT_PX}
          icon={IconBeacon}
          title={item.name}
          subtitle=""
          description=""
          // TODO: pass the description through once structures sagas are developed.
          // description={item.description}
          onClick={() => onClick(ref) }
          onMouseEnter={debounce(() => onMouseEnter(ref), 50)}
          onMouseLeave={debounce(() => onMouseLeave(ref), 50)}
          key={`list-item-${item.ref ? item.ref.id : ''}`}
          active={!!this.props.active}
          metadata={item.metadata!}
          primaryAction={{
            label: 'Edit',
            to: item.ref ? `/locate/beacons/edit/${item.ref.id}` : undefined
          }}
          renderMenu={item.ref ? (
            <PopOverMenu>
              <PopOverMenuLink to={`/locate/beacons/edit/${item.ref.id}`}>Edit Beacon</PopOverMenuLink>
            </PopOverMenu>
          ) : undefined}
        />
      </div>
    );
  }

  public render() {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={this.props.items.length}
            itemSize={ITEM_HEIGHT_PX}
          >
            {this.rowRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  }
}
