import { darkenHex, lightenHex } from 'helpers/style';

// only exported for use in storybook, use the theme in components.
export const baseColors = {
  primary: '#9346ff',
  error: '#d12303',
  warning: '#f06e36',
  success: '#008000',
  info: '#209ddc',
  gray: '#76747a',
  black: '#000000',
  darkText: '#232224',
  white: '#ffffff'
};

export const theme = {
  color: {
    primaryD90: darkenHex(baseColors.primary, 90),
    primaryD80: darkenHex(baseColors.primary, 80),
    primaryD60: darkenHex(baseColors.primary, 60),
    primaryD40: darkenHex(baseColors.primary, 40),
    primaryD20: darkenHex(baseColors.primary, 20),
    primary: baseColors.primary,
    primaryL20: lightenHex(baseColors.primary, 20),
    primaryL40: lightenHex(baseColors.primary, 40),
    primaryL60: lightenHex(baseColors.primary, 60),
    primaryL80: lightenHex(baseColors.primary, 80),
    primaryL90: lightenHex(baseColors.primary, 90),
    errorD60: darkenHex(baseColors.error, 60),
    errorD20: darkenHex(baseColors.error, 20),
    error: baseColors.error,
    errorL20: lightenHex(baseColors.error, 20),
    errorL60: lightenHex(baseColors.error, 60),
    warning: baseColors.warning,
    successD60: darkenHex(baseColors.success, 60),
    successD20: darkenHex(baseColors.success, 20),
    success: baseColors.success,
    successL20: lightenHex(baseColors.success, 20),
    successL60: lightenHex(baseColors.success, 60),
    info: baseColors.info,
    grayD90: darkenHex(baseColors.gray, 90),
    grayD80: darkenHex(baseColors.gray, 80),
    grayD70: darkenHex(baseColors.gray, 70),
    grayD60: darkenHex(baseColors.gray, 60),
    grayD40: darkenHex(baseColors.gray, 40),
    grayD20: darkenHex(baseColors.gray, 20),
    gray: baseColors.gray,
    grayL20: lightenHex(baseColors.gray, 20),
    grayL40: lightenHex(baseColors.gray, 40),
    grayL60: lightenHex(baseColors.gray, 60),
    grayL70: lightenHex(baseColors.gray, 70),
    grayL80: lightenHex(baseColors.gray, 80),
    grayL90: lightenHex(baseColors.gray, 90),
    black: baseColors.black,
    white: baseColors.white,
    text: baseColors.gray,
    darkText: baseColors.darkText
  },
  font: {
    weight: {
      light: 300,
      base: 400,
      bold: 500,
    },
    family: {
      base: 'supria-sans, sans-serif',
    },
    size: {
      xLarge: '30px',
      large: '22px',
      medium: '18px',
      base: '16px',
      small: '14px',
      xSmall: '12px',
    },
  },
  transition: {
    base: 'all 200ms ease-in-out',
  },
};

export type ThemeInterface = typeof theme;

export type ThemeColor = keyof ThemeInterface['color'];
