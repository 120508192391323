import { space } from 'helpers/style'
import { createGlobalStyle } from '.';

export const GlobalStyles = createGlobalStyle`
  /* document layout and defaults */
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html, body, #root {
    height: 100%;
  }
  html {
    box-sizing: border-box;
    body {
      color: ${({ theme }) => theme.color.text};
      font-family: ${({ theme }) => theme.font.family.base};
      font-weight: ${({ theme }) => theme.font.weight.light};
    }
    svg {
      flex-basis: 100%;
    }

    /* typographic defaults */
    h1, h2, h3, h4, h5, h6 {
      font-family: ${({ theme }) => theme.font.family.base};
      font-weight: ${({ theme }) => theme.font.weight.light};
      color: ${({ theme }) => theme.color.darkText};
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h1 { font-size: ${({ theme }) => theme.font.size.xLarge}; };
    h2 { font-size: ${({ theme }) => theme.font.size.large}; };
    h3 { font-size: ${({ theme }) => theme.font.size.medium}; };
    h4 { font-size: ${({ theme }) => theme.font.size.base}; };
    h5 { font-size: ${({ theme }) => theme.font.size.base}; };
    h6 { font-size: ${({ theme }) => theme.font.size.base}; };
    p { font-size: ${({ theme }) => theme.font.size.base}; };
    label {
      color: ${({ theme }) => theme.color.darkText};
      font-size: ${({ theme }) => theme.font.size.small};
    };
    small { font-size: ${({ theme }) => theme.font.size.xSmall}; };
    strong {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.color.black};
      font-weight: ${({ theme }) => theme.font.weight.base};
    }
    hr {
      border: none;
      border-bottom: solid 1px #f5f5f5;
    }
  }

  /* Helpscout beacon */
  #HSBeaconFabButton {
    bottom: 23px !important;
    right: 50px !important;
  }

  /* {{{ temp solution until we have a standard way to control and style map controls */
  .mapboxgl-control-container {
    .mapboxgl-ctrl-group {
      box-shadow: none !important;
      border-radius: initial;
      overflow: initial;
      background: none;
    }

    .mapboxgl-ctrl-group > button {
      transition: ${({ theme }) => theme.transition.base};
      background-color: white;
      width: 34px;
      height: 34px;
      margin: ${space(2)};
      border: none !important;
      border-radius: 50px;
      padding: ${space(2)} !important;
      svg {
        fill: ${({ theme }) => theme.color.gray};
      }
      box-shadow: 1px 1px 0 0 rgba(161, 172, 180, 0.5);
      &:active {
        box-shadow: none;
      }
      &:hover {
        background: ${({ theme }) => theme.color.primary} !important;
        svg {
          fill: white;
        }
      }
    }
    .mapboxgl-ctrl-selected {
      background: ${({ theme }) => theme.color.primary} !important;
      svg {
        fill: white !important;
      }
    }
    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:${({ theme }) => theme.color.gray.replace('#', '%23')} ;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E");
      &:hover {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:${({ theme }) => theme.color.white.replace('#', '%23')} ;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E") !important;
      }
    }
    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:${({ theme }) => theme.color.gray.replace('#', '%23')};' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E");
      &:hover {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:${({ theme }) => theme.color.white.replace('#', '%23')};' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E") !important;
      }
    }
  }
  /* }}} */
`;
