import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import { ISagaModule } from 'redux-dynamic-modules-saga'

import { wamRootSaga } from '../sagas'
import { WamAppState } from '../store'

// reducers.
import { toursActionCreators, toursReducer } from './tours'
import { uploadActionCreators, uploadReducer } from './upload'
import { atmosphericZonesActionCreators, atmosphericZonesReducer } from './atmospheric-zones'
import { soundEffectZonesActionCreators, soundEffectZonesReducer } from './sound-effect-zones'
import { stopsActionCreators, stopsReducer } from './stops'
import { publishingActionCreators, publishingReducer } from './publishing'
import { holdingAudioActionCreators, holdingAudioReducer } from './holding-audio'

export type FluxStandardAction<TPayload, TType> = {
  readonly type: TType
  readonly payload: TPayload
  readonly error?: false
  readonly domain: 'wam.tours' | 'wam.upload' | 'wam.atmospheric-zones' | 'wam.sound-effect-zones' | 'wam.stops' | 'wam.publishing' | 'wam.holding-audio'
}

export type FluxStandardErrorAction<TType> = {
  readonly type: TType
  readonly payload: Error
  readonly error: true
  readonly domain: 'wam.tours' | 'wam.upload' | 'wam.atmospheric-zones' | 'wam.sound-effect-zones' | 'wam.stops' | 'wam.publishing' | 'wam.holding-audio'
}

export const actionCreators = {
  ...toursActionCreators,
  ...uploadActionCreators,
  ...atmosphericZonesActionCreators,
  ...soundEffectZonesActionCreators,
  ...stopsActionCreators,
  ...publishingActionCreators,
  ...holdingAudioActionCreators,
}

export type WamActionCreators = typeof actionCreators
export type WamAction = ReturnType<typeof actionCreators[keyof typeof actionCreators]>
export type WamActions = {
  [T in keyof WamActionCreators]: ReturnType<WamActionCreators[T]>
}

export const wamReducer = combineReducers({
  tours: toursReducer,
  upload: uploadReducer,
  atmosphericZones: atmosphericZonesReducer,
  soundEffectZones: soundEffectZonesReducer,
  stops: stopsReducer,
  publishing: publishingReducer,
  holdingAudio: holdingAudioReducer,
})

function* wamSagas() {
  return yield all([...wamRootSaga])
}

type WamModuleState = {
  readonly wam: WamAppState
}

export const getWamModule = (): ISagaModule<WamModuleState> => ({
  id: 'wam',
  reducerMap: {
    wam: wamReducer,
  } as any,
  retained: true,
  sagas: [wamSagas],
})
