/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconCaret = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M7 10l5 5 5-5z" />
  </Icon>
)

// for use with mapbox addables.
export const IconCaretString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M7 10l5 5 5-5z" />
  </svg>`

IconCaret.displayName = 'IconCaret'
