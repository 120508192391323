import { ReleaseControl } from 'generated/mos/releasecontrols'
import { ListReleaseControlsResponse } from 'generated/mos/releasecontrolsaccess'
import { SetReleaseStatusRequest, SetReleaseStatusResponse } from 'generated/mos/releasecontrolsmanagement'

import { assertNever, Invariant, pick } from 'helpers/core'
import { Status, ErrorMessage } from 'helpers/status'

import { ReleaseControlsAppState, initialReleaseControlsAppState } from './store'

// exported for sagas, not to be bound to connected components.
export const actionCreators = {
  // {{{ release-controls access.
  releaseControlsListReleaseControlsRequest: (payload: ReadonlyArray<{
    readonly typename: string;
    readonly id: string;
  }>) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsListReleaseControlsRequest' as const,
    payload,
  }),
  releaseControlsListReleaseControlsSuccess: (payload: ListReleaseControlsResponse.Entity) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsListReleaseControlsSuccess' as const,
    payload,
  }),
  releaseControlsListReleaseControlsFailure: (message: ErrorMessage) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsListReleaseControlsFailure' as const,
    message,
  }),
  // }}} release-controls access.

  // {{{ release-controls management.
  releaseControlsSetReleaseStatusRequest: (payload: SetReleaseStatusRequest.Entity) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsSetReleaseStatusRequest' as const,
    payload,
  }),
  releaseControlsSetReleaseStatusSuccess: (payload: SetReleaseStatusResponse.Entity) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsSetReleaseStatusSuccess' as const,
    payload,
  }),
  releaseControlsSetReleaseStatusFailure: (message: ErrorMessage) => ({
    domain: 'release-controls' as const,
    type: 'releaseControlsSetReleaseStatusFailure' as const,
    message,
  }),
  // }}} release-controls management.
}

export type ReleaseControlsActionCreators = typeof actionCreators
export type ReleaseControlsAction = ReturnType<typeof actionCreators[keyof typeof actionCreators]>
export type ReleaseControlsActions = {
  [T in keyof ReleaseControlsActionCreators]: ReturnType<ReleaseControlsActionCreators[T]>;
};

export const releaseControlsBindableActionCreators = pick(
  actionCreators,
  'releaseControlsListReleaseControlsRequest',
  'releaseControlsListReleaseControlsSuccess',
  'releaseControlsListReleaseControlsFailure',
  'releaseControlsSetReleaseStatusRequest',
  'releaseControlsSetReleaseStatusSuccess',
  'releaseControlsSetReleaseStatusFailure',
)

const assertReleaseControls = (domain: 'release-controls') => {
  return domain === 'release-controls'
}

export const releaseControlsReducer = (state: ReleaseControlsAppState = initialReleaseControlsAppState, action: ReleaseControlsAction): ReleaseControlsAppState => {
  if (!assertReleaseControls(action.domain)) {
    return state
  }

  switch (action.type) {
    // {{{ release-controls access.
    case 'releaseControlsListReleaseControlsRequest': {
      if(state.releaseControlsList.status === Status.Ready) {
        return {
          ...state,
          releaseControlsList: {
            status: Status.Updating,
            data: state.releaseControlsList.data
          },
        }
      }
      return {
        ...state,
        releaseControlsList: {
          status: Status.Loading,
        },
      }
    }
    case 'releaseControlsListReleaseControlsSuccess': {
      const data = action.payload.releaseControls.reduce((acc, curr: ReleaseControl.Entity) => {
        return curr.ref ? {
          ...acc,
          [curr.ref.id]: curr
        } : acc
      }, {})

      return {
        ...state,
        releaseControlsList: {
          data: {
            ...(state.releaseControlsList.status === Status.Updating) ? state.releaseControlsList.data : null,
            ...data
          },
          status: Status.Ready,
        },
      }
    }
    case 'releaseControlsListReleaseControlsFailure': {
      return {
        ...state,
        releaseControlsList: {
          messages: [action.message],
          status: Status.Failed,
        },
      }
    }
    // }}} release-controls access.

    // {{{ release-controls management.
    case 'releaseControlsSetReleaseStatusRequest': {
      if (state.releaseControlsList.status !== Status.Ready) {
        throw new Error('Release control details can not be updated if not already loaded');
      }
      return {
        ...state,
        releaseControlsList: {
          status: Status.Updating,
          data: state.releaseControlsList.data,
        },
      }
    }
    case 'releaseControlsSetReleaseStatusSuccess': {
      if (state.releaseControlsList.status !== Status.Updating) {
        throw new Error('Release control details can not be updated if not already loaded');
      }
      if (!action.payload.releaseControl) {
        throw new Error('Release control details are not in response');
      }
      const releaseControl = action.payload.releaseControl.ref ? { [action.payload.releaseControl.ref.id]: action.payload.releaseControl } : null
      return {
        ...state,
        releaseControlsList: {
          status: Status.Ready,
          data: {
            ...state.releaseControlsList.data,
            ...releaseControl,
          }
          
        },
      }
    }
    case 'releaseControlsSetReleaseStatusFailure': {
      return {
        ...state,
      }
    }
    // }}} release-controls management.

    default: {
      assertNever(action);
      throw new Invariant();
    }
  }
}
