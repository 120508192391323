import { PaginatedList } from 'helpers/status'

import { Attachment, Media } from 'generated/mos/media'
import {
  GetMediaRequest,
  GetMediaResponse,
  ListMediaRequest,
  ListMediaResponse,
} from 'generated/mos/mediaaccess'
import {
  CreateMediaRequest,
  AddAttachmentRequest,
  AddAttachmentResponse,
  DeleteAttachmentRequest,
  DeleteAttachmentResponse,
} from 'generated/mos/mediamanagement'

import { assertNever, Invariant } from 'helpers/core'
import { Status, ErrorMessage } from 'helpers/status'

import { DataStatus, MediaAppState, initialMediaAppState } from './store'

export const actionCreators = {
  mediaGetMediaRequest: (ref: Media.Ref, payload: GetMediaRequest.Entity) => ({
    domain: 'media' as const,
    type: 'mediaGetMediaRequest' as const,
    ref,
    payload,
  }),
  mediaGetMediaSuccess: (ref: Media.Ref, payload: GetMediaResponse.Entity) => ({
    domain: 'media' as const,
    type: 'mediaGetMediaSuccess' as const,
    ref,
    payload,
  }),
  mediaGetMediaFailure: (ref: Media.Ref, message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaGetMediaFailure' as const,
    ref,
    message,
  }),
  mediaListMediaRequest: (payload: ListMediaRequest.Entity) => ({
    domain: 'media' as const,
    type: 'mediaListMediaRequest' as const,
    payload,
  }),
  mediaListMediaSuccess: (payload: ListMediaResponse.Entity) => ({
    domain: 'media' as const,
    type: 'mediaListMediaSuccess' as const,
    payload,
  }),
  mediaListMediaFailure: (message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaListMediaFailure' as const,
    message,
  }),

  mediaCreateMediaRequest: (payload: CreateMediaRequest.Entity) => ({
    domain: 'media' as const,
    type: 'mediaCreateMediaRequest' as const,
    payload,
  }),
  mediaCreateMediaSuccess: (entity: Media.Entity) => ({
    domain: 'media' as const,
    type: 'mediaCreateMediaSuccess' as const,
    entity,
  }),
  mediaCreateMediaFailure: (message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaCreateMediaFailure' as const,
    message,
  }),
  mediaCreateMediaReset: () => ({
    domain: 'media' as const,
    type: 'mediaCreateMediaReset' as const,
  }),

  mediaUpdateMediaRequest: (payload: Media.Entity) => ({
    domain: 'media' as const,
    type: 'mediaUpdateMediaRequest' as const,
    payload,
  }),
  mediaUpdateMediaSuccess: (entity: Media.Entity) => ({
    domain: 'media' as const,
    type: 'mediaUpdateMediaSuccess' as const,
    entity,
  }),
  mediaUpdateMediaFailure: (message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaUpdateMediaFailure' as const,
    message,
  }),
  mediaUpdateMediaReset: () => ({
    domain: 'media' as const,
    type: 'mediaUpdateMediaReset' as const,
  }),

  // managing attachments only handles updating a single media entity at a time. if we need to
  //   support multiple async changes we will need to create an id from at least the media ref,
  //   attachment intent and language.
  mediaAddAttachmentRequest: (ref: Media.Ref, payload: AddAttachmentRequest.Entity) => ({
    domain: 'media' as const,
    type: 'mediaAddAttachmentRequest' as const,
    ref,
    payload,
  }),
  mediaAddAttachmentSuccess: (ref: Media.Ref, payload: AddAttachmentResponse.Entity) => ({
    domain: 'media' as const,
    type: 'mediaAddAttachmentSuccess' as const,
    ref,
    payload,
  }),
  mediaAddAttachmentFailure: (ref: Media.Ref, message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaAddAttachmentFailure' as const,
    ref,
    message,
  }),

  mediaDeleteAttachmentRequest: (ref: Media.Ref, payload: DeleteAttachmentRequest.Entity) => ({
    domain: 'media' as const,
    type: 'mediaDeleteAttachmentRequest' as const,
    ref,
    payload,
  }),
  mediaDeleteAttachmentSuccess: (ref: Media.Ref, payload: DeleteAttachmentResponse.Entity) => ({
    domain: 'media' as const,
    type: 'mediaDeleteAttachmentSuccess' as const,
    ref,
    payload,
  }),
  mediaDeleteAttachmentFailure: (ref: Media.Ref, message: ErrorMessage) => ({
    domain: 'media' as const,
    type: 'mediaDeleteAttachmentFailure' as const,
    ref,
    message,
  }),
  mediaUpdateAttachmentReset: (ref: Media.Ref) => ({
    domain: 'media' as const,
    type: 'mediaUpdateAttachmentReset' as const,
    ref,
  }),
}

export type MediaActionCreators = typeof actionCreators;
export type MediaAction = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;
export type MediaActions = {
  [T in keyof MediaActionCreators]: ReturnType<MediaActionCreators[T]>;
};

const assertMedia = (domain: 'media') => {
  return domain === 'media'
}

export const mediaReducer = (state: MediaAppState = initialMediaAppState, action: MediaAction): MediaAppState => {
  if (!assertMedia(action.domain)) {
    return state
  }

  switch (action.type) {
    // {{{ media access.
    case 'mediaGetMediaRequest': {
      const entityState = state.entities[action.ref.id]

      // allows subsequent calls to refresh data rather than purging the previous state immediately.
      const data: DataStatus<Media.Entity> = entityState && entityState.status === Status.Ready ? {
        status: Status.Updating,
        data: entityState!.data,
      } : {
        status:  Status.Loading,
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.ref.id]: data,
        },
      }
    }
    case 'mediaGetMediaSuccess': {
      const { media } = action.payload

      if (!media || !Media.isRef(media.ref)) {
        throw new Invariant('No media returned from mos.media_access.MediaAccess/GetMedia.')
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.ref.id]: {
            data: media,
            errors: [],
            status: Status.Ready,
          },
        },
      }
    }
    case 'mediaGetMediaFailure': {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.ref.id]: {
            messages: [action.message],
            status:  Status.Failed,
          },
        },
      }
    }
    case 'mediaListMediaRequest': {
      const { page } = action.payload

      // if we are paginating, set to updating so we can update the resultset.
      if (state.mediaList.status === Status.Ready) {
        if (!page || page && page.pageToken !== '') {
          return {
            ...state,
            mediaList: {
              data: state.mediaList.data,
              status:  Status.Updating,
            },
          }
        }
      }

      // otherwise this is a new resultset.
      return {
        ...state,
        mediaList: {
          status:  Status.Loading,
        },
      }
    }
    case 'mediaListMediaSuccess': {
      const { media, nextPage, totalItems } = action.payload

      const data: PaginatedList<Media.Entity> = {
        entityList: state.mediaList.status === Status.Updating
          ? [ ...state.mediaList.data.entityList, ...media ]
          : media,
        nextPage: nextPage || undefined,
        totalItems,
      }

      return {
        ...state,
        mediaList: {
          data,
          errors: [],
          status: Status.Ready,
        },
      }
    }
    case 'mediaListMediaFailure': {
      return {
        ...state,
        mediaList: {
          messages: [action.message],
          status: Status.Failed,
        },
      }
    }
    // }}} media access.

    // {{{ media management.
    case 'mediaCreateMediaRequest': {
      return {
        ...state,
        mediaCreate: {
          status: Status.Loading,
        },
      }
    }
    case 'mediaCreateMediaSuccess': {
      return {
        ...state,
        mediaCreate: {
          data: action.entity,
          errors: [],
          status: Status.Ready,
        },
      }
    }
    case 'mediaCreateMediaFailure': {
      return {
        ...state,
        mediaCreate: {
          messages: [action.message],
          status: Status.Failed,
        },
      }
    }
    case 'mediaCreateMediaReset': {
      return {
        ...state,
        mediaCreate: {
          status: Status.Idle,
        },
      }
    }

    case 'mediaUpdateMediaRequest': {
      return {
        ...state,
        mediaUpdate: {
          data: action.payload,
          status: Status.Updating,
        },
      }
    }
    case 'mediaUpdateMediaSuccess': {
      const { entity } = action
      const ref = Media.mustRef(entity.ref)
      return {
        ...state,
        mediaUpdate: {
          data: action.entity,
          errors: [],
          status: Status.Ready,
        },
        entities: {
          ...state.entities,
          [ref.id]: {
            data: action.entity,
            errors: [],
            status: Status.Ready,
          },
        },
      }
    }
    case 'mediaUpdateMediaFailure': {
      return {
        ...state,
        mediaUpdate: {
          messages: [action.message],
          status: Status.Failed,
        },
      }
    }
    case 'mediaUpdateMediaReset': {
      return {
        ...state,
        mediaUpdate: {
          status: Status.Idle,
        },
      }
    }

    case 'mediaAddAttachmentRequest':
    case 'mediaDeleteAttachmentRequest': {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.ref.id]: {
            status:  Status.Loading,
          },
        },
      }
    }
    case 'mediaAddAttachmentSuccess': {
      const { attachment } = action.payload

      if (!attachment || !Attachment.isRef(attachment.ref)) {
        throw new Invariant('No attachment returned from mos.media_management.MediaManagement/AddAttachment.')
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.ref.id]: {
            data: attachment.ref,
            errors: [],
            status: Status.Ready,
          },
        },
      }
    }
    case 'mediaDeleteAttachmentSuccess': {
      const { ref } = action.payload

      if (!Attachment.isRef(ref)) {
        throw new Invariant('No attachment ref returned from mos.media_management.MediaManagement/DeleteAttachment.')
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.ref.id]: {
            data: ref,
            errors: [],
            status: Status.Ready,
          },
        },
      }
    }
    case 'mediaAddAttachmentFailure':
    case 'mediaDeleteAttachmentFailure': {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.ref.id]: {
            messages: [action.message],
            status:  Status.Failed,
          },
        },
      }
    }
    case 'mediaUpdateAttachmentReset': {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.ref.id]: {
            status:  Status.Idle,
          },
        },
      }
    }
    // }}} media management.

    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
