import { Space, SpaceType } from 'generated/mos/structure';

import { SpacesFilterState, SpaceTypes } from 'domains/spaces/store';
import { structuresSelector } from 'domains/structures';
import { Structures, StructureRef } from 'domains/structures/entities';

// FIXME: there's a lot of overlap between this and the equivalent script in
// the beacons domain. There's probably something generalisable in here, but
// we need to retain the flexibility to change the rules for each domain
// independently of the other.

// Scopes shown in list view
export const visibleScopes: ReadonlyArray<VisibleScope> = ['currentScope', 'unpositioned', 'otherScopes'];
export type VisibleScope = Extract<keyof SpacesData["sets"], 'currentScope' | 'unpositioned' | 'otherScopes'>;

export type SpacesData = {
  readonly sets: {
    // Both the search and the activeRef are applied. Contains all positioned spaces if there
    // is no search or activeRef.
    readonly currentScope: ReadonlyArray<Space.Entity>;

    // Spaces that match the search term and have no position
    readonly unpositioned: ReadonlyArray<Space.Entity>;

    // Spaces that match the search term, have a position, and are not in currentScope
    readonly otherScopes: ReadonlyArray<Space.Entity>;

    // All spaces passed in, most recently updated first
    readonly allOrdered: ReadonlyArray<Space.Entity>;
  };

  readonly spaceTypes: SpaceTypes;
};

export function filterSpaces(
  spaces: ReadonlyArray<Space.Entity>,
  structures: Structures,
  structureRef: StructureRef | undefined,
  filters: SpacesFilterState,
  spaceTypes: SpaceTypes,
): SpacesData {

  const allOrdered = spaces.filter((a) => a && true).sort((a, b) => {
    return b.name.localeCompare(a.name);
  });

  const searched = allOrdered.filter(filterSpacesBySearchTerm(filters.search));

  const currentScope = structureRef ?
    searched.filter(filterSpacesBySpatialScope(structures, structureRef)) :
    searched.filter((v) => !!structuresSelector.firstBoundary(v.boundaries));

  const unpositioned = searched.filter((v) => !structuresSelector.firstBoundary(v.boundaries));

  const currentSet = new Set(currentScope);
  const otherScopes = allOrdered
    .filter((v) => !currentSet.has(v) && !!structuresSelector.firstBoundary(v.boundaries))
    .filter(filterSpacesBySearchTerm(filters.search));

  const data: SpacesData = {
    sets: {
      currentScope,
      unpositioned,
      otherScopes,
      allOrdered,
    },
    spaceTypes,
  };

  return data;
}

const filterSpacesBySearchTerm = (searchTerm?: string) => (space: Space.Entity): boolean => {
  return !searchTerm
    ? true
    : space.name.toLowerCase().includes(searchTerm.trim().toLowerCase());
};

const filterSpacesBySpatialScope = (structures: Structures, activeRef: StructureRef | undefined) => (space: Space.Entity): boolean => {
  if (!activeRef) {
    return !!space;
  } else if (!space || !space.boundaries) {
    return false;
  }

  const boundary = structuresSelector.firstBoundary(space.boundaries);
  if (!boundary || !boundary.levelRef) {
    return false;
  }
  return structuresSelector.containsOrEquals(structures, activeRef, boundary.levelRef);
};

