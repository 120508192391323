/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconCategory = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M20.469 11.622l-8.1-8.1A1.789 1.789 0 0011.1 3H4.8C3.81 3 3 3.81 3 4.8v6.3c0 .495.198.945.531 1.278l8.1 8.1c.324.324.774.522 1.269.522a1.76 1.76 0 001.269-.531l6.3-6.3A1.76 1.76 0 0021 12.9c0-.495-.207-.954-.531-1.278zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5 8 5.67 8 6.5 7.33 8 6.5 8z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconCategoryString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M20.469 11.622l-8.1-8.1A1.789 1.789 0 0011.1 3H4.8C3.81 3 3 3.81 3 4.8v6.3c0 .495.198.945.531 1.278l8.1 8.1c.324.324.774.522 1.269.522a1.76 1.76 0 001.269-.531l6.3-6.3A1.76 1.76 0 0021 12.9c0-.495-.207-.954-.531-1.278zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5 8 5.67 8 6.5 7.33 8 6.5 8z"  fillRule="nonzero" />
  </svg>`

IconCategory.displayName = 'IconCategory'
