import React from 'react'

import { Modal } from 'components/ui/modal';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { pick } from 'helpers/core';

import { StructureRef } from 'domains/structures/entities';

import { BuildingCreateForm } from '../building-create-form';
import { BuildingLevelCreateForm } from '../building-level-create-form';
import { SiteCreateForm } from '../site-create-form';

import { Title } from './common';
import { SelectStructureType, SelectStructureTypeHeader } from './step-select-structure';


export interface Steps {
  readonly selectStructureType: Step;
  readonly selectSite: Step;
  readonly selectBuilding: Step;
  readonly selectBuildingLevel: Step;
}

type DirectProps = {
  readonly onClose: () => void;
  readonly onCreate: (ref: StructureRef) => void;
};

type ConnectedProps = {};

type ActionProps = Pick<typeof sharedActionCreators, 'toastNotification'>;

type State = {
  readonly step: keyof Steps;
};

type Step = {
  readonly header?: React.ReactElement;
  readonly body: React.ReactElement;
};

type Props = DirectProps & ActionProps;

// XXX(bw): This feels like it was initially built to be a branching multi-step
// wizard, but we have no need for that now. I've combined most of the steps
// that were only 5 lines of meat (plus 30 lines of import, connect and prop
// boilerplate) into this, but it could possibly be simplified further.
class CreateNewStructureModalView extends React.Component<Props, State> {
  private steps: { [ key: string ]: Step } = {
    selectStructureType: {
      header: <SelectStructureTypeHeader />,
      body: <SelectStructureType setStep={(step) => this.setStep(step)} />,
    },

    selectSite: {
      header: <Title>Site details</Title>,
      body: <SiteCreateForm
        onSuccess={(result) => {
          this.props.toastNotification({ type: 'success', text: 'Site created' });
          this.props.onCreate(result);
        }}
      />,
    },

    selectBuilding: {
      header: <Title>Building details</Title>,
      body: <BuildingCreateForm
        onSuccess={(buildingRef) => {
          this.props.toastNotification({ type: 'success', text: 'Building created' });
          this.props.onCreate(buildingRef);
        }}
      />,
    },

    selectBuildingLevel: {
      header: <Title>Building level details</Title>,
      body: <BuildingLevelCreateForm
        onSuccess={(levelRef) => {
          this.props.toastNotification({ type: 'success', text: 'Building level created' });
          this.props.onCreate(levelRef);
        }}
      />,
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      step: 'selectStructureType',
    };
  }

  private setStep(step: keyof Steps) {
    this.setState({ step });
  }

  public render() {
    const { step } = this.state;

    return (
      <Modal
        onClose={() => {
          this.setState({ step: 'selectStructureType' });
          this.props.onClose();
        }}
        header={this.steps[step].header}>
        {this.steps[step].body}
      </Modal>
    );
  }
}

export const CreateNewStructureModal = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({}),
  pick(sharedActionCreators, 'toastNotification'),
)(CreateNewStructureModalView);
