// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureScalar } from "generated/webrpc";

// Represents a natural language ising the ISO language codes and their variants
// 
// Presentation of these languages to a user requires localisation itself, as such this message does
// not include a display name
export namespace Language {
  export const refName = "mos.i18n.Language" as const;
  export type Entity = {
    readonly type: typeof refName,
    // 2 letter ISO language code -  e.g. en or zh
    readonly languageCode: string,
    // variant - e.g. gb or hans
    readonly variant: string,
  }
  export const defaults: Entity = {
    type: refName,
    languageCode: "",
    variant: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.i18n.Language");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["languageCode"] = ensureScalar(ctx, v["languageCode"], "string");
      out["variant"] = ensureScalar(ctx, v["variant"], "string");
      return out as any as Entity;
    }
  }();
}

