import React from 'react'

import { Link } from 'components/ui/link';
import { StatusGuard } from 'components/ui/status-guard';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { ResetToken, ValidateResetTokenRequest } from 'generated/mos/userauthentication'
import { pick } from 'helpers/core';
import { statusSelector } from 'helpers/status';
import { space } from 'helpers/style'
import { LayoutUnauthenticatedPage } from 'layouts/unauthenticated-page';
import styled from 'styled';

import { AssignNewPasswordForm } from '../assign-new-password-form';

import { accountBindableActionCreators } from 'domains/account/actions';
import { DataStatus, TokenValidationResult } from 'domains/account/store';

const InvalidBodyText = styled.div`
  text-align: center;
`;

const InvalidBodyTextHeading = styled.h2`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.light};
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(10)};
`

type DirectProps = {
  readonly userId: string;
  readonly token: string;
  readonly onComplete: () => void;
};

type ConnectedProps = {
  readonly tokenValidation: DataStatus<TokenValidationResult>;
};

type ActionProps =
  Pick<typeof accountBindableActionCreators, 'accountValidateResetTokenRequest'> &
  Pick<typeof sharedActionCreators, 'toastNotification'>
;

type Props = ActionProps & ConnectedProps & DirectProps;

class AssignNewPasswordView extends React.Component<Props> {
  public componentDidMount() {
    this.props.accountValidateResetTokenRequest({
      ...ValidateResetTokenRequest.defaults,
      token: {
        ...ResetToken.defaults,
        value: this.props.token,
      },
      userRef: { typename: 'mos.user.User', id: this.props.userId },
    });
  }

  public render() {
    const tokenValidation = statusSelector.data(this.props.tokenValidation);
    const { onComplete, token, toastNotification } = this.props;

    return (
      <StatusGuard status={this.props.tokenValidation}>
        <LayoutUnauthenticatedPage
          title={() => 'Create new password?'}
          content={() => !tokenValidation || !tokenValidation.valid ? (
            <InvalidBodyText>
              <InvalidBodyTextHeading>Sorry this is not a valid link.</InvalidBodyTextHeading>
              <p>Please try again or contact your Organisation Admin.</p>

              <CtaContainer>
                <Link to="/forgot-password">Back to forgot password</Link>
              </CtaContainer>
            </InvalidBodyText>
            ) : (
              <>
                <AssignNewPasswordForm
                  userRef={{ typename: 'mos.user.User', id: this.props.userId }}
                  token={token}
                  onSuccess={() => {
                    toastNotification({ type: 'success', text: 'Password reset succeeded' });
                    onComplete();
                  }}
                />
                <CtaContainer>
                  <Link to="/sign-in">Back to sign in</Link>
                </CtaContainer>
              </>
            )}
        />
      </StatusGuard>
    );
  }
}

export const AssignNewPasswordPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'tokenValidation'),
  {
    ...pick(accountBindableActionCreators, 'accountValidateResetTokenRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(AssignNewPasswordView);
