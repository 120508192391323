// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Duration } from "generated/google/protobuf";
import { Color } from "generated/mos/color";
import { Checksum, Metadata } from "generated/mos/entity";
import { Language } from "generated/mos/i18n";
import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, decodeMessageRepeated, ensureBigInt, ensureScalar, ensureScalarOptional, stripTypePropertyDeep } from "generated/webrpc";
import jsbi from "jsbi";

// Attachments are secondary associated representation of the primary media representation
// For example the French subtitles to a Japanese video.
export namespace Attachment {
  export type Ref = { readonly typename: 'mos.media.Attachment', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.media.Attachment');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Attachment`);
    return v;
  }
  export const refName = "mos.media.Attachment" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Attachment', readonly id: string } | undefined,
    readonly uploadedFilename: string,
    readonly mediaType: string,
    readonly url: string,
    readonly checksum: Checksum.Entity | undefined,
    readonly intent: AttachmentIntent,
    readonly language: Language.Entity | undefined,
    readonly fileSize: jsbi,
    readonly metadata: Metadata.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    uploadedFilename: "",
    mediaType: "",
    url: "",
    checksum: undefined,
    intent: "MEDIA_INTENT_UNSPECIFIED",
    language: undefined,
    fileSize: jsbi.BigInt(0),
    metadata: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["uploadedFilename"] = v.uploadedFilename;
      obj["mediaType"] = v.mediaType;
      obj["url"] = v.url;
      obj["checksum"] = Checksum.codec.encode(v.checksum);
      obj["intent"] = v.intent;
      obj["language"] = Language.codec.encode(v.language);
      obj["fileSize"] = v.fileSize.toString(10);
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.Attachment");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["uploadedFilename"] = ensureScalar(ctx, v["uploadedFilename"], "string");
      out["mediaType"] = ensureScalar(ctx, v["mediaType"], "string");
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["checksum"] = ctx.decode(Checksum.codec, v["checksum"], "checksum");
      {
        const ev = ensureScalar(ctx, v["intent"], "string") || defaults["intent"];
        if (!AttachmentIntentValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.AttachmentIntent`);
        }
        out["intent"] = ev as any;
      }
      out["language"] = ctx.decode(Language.codec, v["language"], "language");
      out["fileSize"] = ensureBigInt(ctx, v["fileSize"]);
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      return out as any as Entity;
    }
  }();
}

export const AttachmentIntentValues = new Set([
  'MEDIA_INTENT_UNSPECIFIED' as const,
  'MEDIA_INTENT_TRANSCRIPT' as const,
  'MEDIA_INTENT_SUBTITLES' as const,
  'MEDIA_INTENT_CLOSED_CAPTIONS' as const,
  'MEDIA_INTENT_THUMBNAIL' as const,
]);
export type AttachmentIntent = typeof AttachmentIntentValues extends Set<infer U> ? U : never;

// Audio track specific metadata attributes
export namespace AudioAttributes {
  export const refName = "mos.media.AudioAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly duration: Duration.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    duration: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["duration"] = Duration.codec.encode(v.duration);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.AudioAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["duration"] = ctx.decode(Duration.codec, v["duration"], "duration");
      return out as any as Entity;
    }
  }();
}

// Represent a custom, perhaps organisation specific transformation
// The identifier should be treated as opaque and has meaning for the implementing service only
export namespace CustomTransform {
  export const refName = "mos.media.CustomTransform" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly identifier: string,
  }
  export const defaults: Entity = {
    type: refName,
    identifier: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.CustomTransform");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["identifier"] = ensureScalar(ctx, v["identifier"], "string");
      return out as any as Entity;
    }
  }();
}

// As the name suggest File represent a single digital file within the blob store
// 
// Note that it is really just a pointer and identity for that file which can be retrieved directly
// from the URL. The addition of the uploaded filename and the content type is to allow simple, and
// fairly direct serving of these file via a CDN without having to interrogate the file itself.
export namespace File {
  export type Ref = { readonly typename: 'mos.media.File', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.media.File');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a File`);
    return v;
  }
  export const refName = "mos.media.File" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.File', readonly id: string } | undefined,
    readonly uploadedFilename: string,
    readonly mediaType: string,
    readonly url: string,
    readonly fileSize: jsbi,
    readonly checksum: Checksum.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    uploadedFilename: "",
    mediaType: "",
    url: "",
    fileSize: jsbi.BigInt(0),
    checksum: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["uploadedFilename"] = v.uploadedFilename;
      obj["mediaType"] = v.mediaType;
      obj["url"] = v.url;
      obj["fileSize"] = v.fileSize.toString(10);
      obj["checksum"] = Checksum.codec.encode(v.checksum);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.File");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["uploadedFilename"] = ensureScalar(ctx, v["uploadedFilename"], "string");
      out["mediaType"] = ensureScalar(ctx, v["mediaType"], "string");
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["fileSize"] = ensureBigInt(ctx, v["fileSize"]);
      out["checksum"] = ctx.decode(Checksum.codec, v["checksum"], "checksum");
      return out as any as Entity;
    }
  }();
}

// Media is what the DMM is all about - it is the metadata that makes finding, managing and user
// media possible. A key thing to note is that files is plural (and thus repeated), a single Media
// asset can consist of several files. In most cases it is likely to be a single file but the
// repeated part if there to ensure multi-file assets are supported
export namespace Media {
  export type Ref = { readonly typename: 'mos.media.Media', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.media.Media');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Media`);
    return v;
  }
  export const refName = "mos.media.Media" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    readonly title: string,
    readonly kind: MediaKind,
    readonly language: Language.Entity | undefined,
    readonly internalNotes: string,
    readonly uploadedFilename: string,
    readonly mediaType: string,
    // Contains a URL for the internally stored representation of a piece of media. This
    // means that we have a copy somewhere within the boundary of MOS of the relevant data.
    // If this is empty, remote_url MUST be set.
    readonly url: string,
    // Contains a URL for an externally stored representation of a piece of media. As soon
    // as a Media item needs to be used (i.e. by projections), it will be fetched and stored
    // within MOS, then made available via 'url'. This is optional if 'url' is set, and
    // required if 'url' is empty.
    readonly remoteUrl: string,
    // If 'url' is set, contains the hash of the internally stored representation. If 'url'
    // is not set, this must be empty.
    readonly checksum: Checksum.Entity | undefined,
    readonly attachments: ReadonlyArray<Attachment.Entity>,
    readonly tags: ReadonlyArray<TagValue.Entity>,
    readonly fileSize: jsbi,
    readonly attributes: MediaAttributes.Entity | undefined,
    // If the remote_url has been fetched, stored internally and 'url' is set,
    // this is the time it was fetched:
    readonly remoteFetchedAt: Timestamp | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    title: "",
    kind: "MEDIA_KIND_UNSPECIFIED",
    language: undefined,
    internalNotes: "",
    uploadedFilename: "",
    mediaType: "",
    url: "",
    remoteUrl: "",
    checksum: undefined,
    attachments: [],
    tags: [],
    fileSize: jsbi.BigInt(0),
    attributes: undefined,
    remoteFetchedAt: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["title"] = v.title;
      obj["kind"] = v.kind;
      obj["language"] = Language.codec.encode(v.language);
      obj["internalNotes"] = v.internalNotes;
      obj["uploadedFilename"] = v.uploadedFilename;
      obj["mediaType"] = v.mediaType;
      obj["url"] = v.url;
      obj["remoteUrl"] = v.remoteUrl;
      obj["checksum"] = Checksum.codec.encode(v.checksum);
      obj["attachments"] = [];
      for (const item of v.attachments) {
        obj["attachments"].push(Attachment.codec.encode(item));
      }
      obj["tags"] = [];
      for (const item of v.tags) {
        obj["tags"].push(TagValue.codec.encode(item));
      }
      obj["fileSize"] = v.fileSize.toString(10);
      obj["attributes"] = MediaAttributes.codec.encode(v.attributes);
      obj["remoteFetchedAt"] = v.remoteFetchedAt ? v.remoteFetchedAt.value : "";
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.Media");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["title"] = ensureScalar(ctx, v["title"], "string");
      {
        const ev = ensureScalar(ctx, v["kind"], "string") || defaults["kind"];
        if (!MediaKindValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.MediaKind`);
        }
        out["kind"] = ev as any;
      }
      out["language"] = ctx.decode(Language.codec, v["language"], "language");
      out["internalNotes"] = ensureScalar(ctx, v["internalNotes"], "string");
      out["uploadedFilename"] = ensureScalar(ctx, v["uploadedFilename"], "string");
      out["mediaType"] = ensureScalar(ctx, v["mediaType"], "string");
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["remoteUrl"] = ensureScalar(ctx, v["remoteUrl"], "string");
      out["checksum"] = ctx.decode(Checksum.codec, v["checksum"], "checksum");
      out["attachments"] = decodeMessageRepeated(ctx, Attachment.codec, v["attachments"], "attachments");
      out["tags"] = decodeMessageRepeated(ctx, TagValue.codec, v["tags"], "tags");
      out["fileSize"] = ensureBigInt(ctx, v["fileSize"]);
      out["attributes"] = ctx.decode(MediaAttributes.codec, v["attributes"], "attributes");
      {
        const tsv = ensureScalarOptional(ctx, v["remoteFetchedAt"], "string");
        out["remoteFetchedAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      return out as any as Entity;
    }
  }();
}

// Container for media metadata attributes
export namespace MediaAttributes {
  export const refName = "mos.media.MediaAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: RasterImageAttributes.Entity | VectorImageAttributes.Entity | AudioAttributes.Entity | VideoAttributes.Entity | PDFAttributes.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (uv.type == "mos.media.AudioAttributes") {
            obj["audioAttributes"] = AudioAttributes.codec.encode(uv);
          }
          else if (uv.type == "mos.media.PDFAttributes") {
            obj["pdfAttributes"] = PDFAttributes.codec.encode(uv);
          }
          else if (uv.type == "mos.media.RasterImageAttributes") {
            obj["rasterImageAttributes"] = RasterImageAttributes.codec.encode(uv);
          }
          else if (uv.type == "mos.media.VectorImageAttributes") {
            obj["vectorImageAttributes"] = VectorImageAttributes.codec.encode(uv);
          }
          else if (uv.type == "mos.media.VideoAttributes") {
            obj["videoAttributes"] = VideoAttributes.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.media.MediaAttributes.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.MediaAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["audioAttributes"] !== undefined) {
          out["value"] = ctx.decode(AudioAttributes.codec, v["audioAttributes"], "audioAttributes");
        }
        if (v["pdfAttributes"] !== undefined) {
          out["value"] = ctx.decode(PDFAttributes.codec, v["pdfAttributes"], "pdfAttributes");
        }
        if (v["rasterImageAttributes"] !== undefined) {
          out["value"] = ctx.decode(RasterImageAttributes.codec, v["rasterImageAttributes"], "rasterImageAttributes");
        }
        if (v["vectorImageAttributes"] !== undefined) {
          out["value"] = ctx.decode(VectorImageAttributes.codec, v["vectorImageAttributes"], "vectorImageAttributes");
        }
        if (v["videoAttributes"] !== undefined) {
          out["value"] = ctx.decode(VideoAttributes.codec, v["videoAttributes"], "videoAttributes");
        }
      }
      return out as any as Entity;
    }
  }();
}

export const MediaKindValues = new Set([
  'MEDIA_KIND_UNSPECIFIED' as const,
  'MEDIA_KIND_BINARY' as const,
  'MEDIA_KIND_RASTER_IMAGE' as const,
  'MEDIA_KIND_VECTOR_IMAGE' as const,
  'MEDIA_KIND_AUDIO' as const,
  'MEDIA_KIND_VIDEO' as const,
  'MEDIA_KIND_PDF' as const,
]);
export type MediaKind = typeof MediaKindValues extends Set<infer U> ? U : never;

// A transform to use when you just want the original file un-modified
// 
// This allows for a single access mechanism for fetching a digital asset rather than
// a client needing to retrieve differentially based on whether any transforms are
// needed or not
export namespace NoOpTransform {
  export const refName = "mos.media.NoOpTransform" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.NoOpTransform");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

// Portable Document Format specific metadata attributes
export namespace PDFAttributes {
  export const refName = "mos.media.PDFAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly pageCount: number,
  }
  export const defaults: Entity = {
    type: refName,
    pageCount: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.PDFAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["pageCount"] = ensureScalar(ctx, v["pageCount"], "number");
      return out as any as Entity;
    }
  }();
}

// Projections are a dynamic representation of Media. They are created as the union of a Media ref
// and an ordered set of tranformations. The processing of producing the digital representation of a
// project may involve a non-trivial amount of time - as such Projects have a status to effectively
// let consumers know if they are _ready for use_.
export namespace Projection {
  export type Ref = { readonly typename: 'mos.media.Projection', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.media.Projection');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Projection`);
    return v;
  }
  export const refName = "mos.media.Projection" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Projection', readonly id: string } | undefined,
    readonly derivedFrom: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    readonly status: ProjectionStatus,
    readonly files: ReadonlyArray<File.Entity>,
    readonly transform: Transform.Entity | undefined,
    readonly attributes: MediaAttributes.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    derivedFrom: undefined,
    status: "PROJECTION_STATUS_UNSPECIFIED",
    files: [],
    transform: undefined,
    attributes: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["derivedFrom"] = v.derivedFrom;
      obj["status"] = v.status;
      obj["files"] = [];
      for (const item of v.files) {
        obj["files"].push(File.codec.encode(item));
      }
      obj["transform"] = Transform.codec.encode(v.transform);
      obj["attributes"] = MediaAttributes.codec.encode(v.attributes);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.Projection");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["derivedFrom"] = v["derivedFrom"];
      {
        const ev = ensureScalar(ctx, v["status"], "string") || defaults["status"];
        if (!ProjectionStatusValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.ProjectionStatus`);
        }
        out["status"] = ev as any;
      }
      out["files"] = decodeMessageRepeated(ctx, File.codec, v["files"], "files");
      out["transform"] = ctx.decode(Transform.codec, v["transform"], "transform");
      out["attributes"] = ctx.decode(MediaAttributes.codec, v["attributes"], "attributes");
      return out as any as Entity;
    }
  }();
}

export const ProjectionStatusValues = new Set([
  'PROJECTION_STATUS_UNSPECIFIED' as const,
  'PROJECTION_STATUS_IN_PROGRESS' as const,
  'PROJECTION_STATUS_READY' as const,
]);
export type ProjectionStatus = typeof ProjectionStatusValues extends Set<infer U> ? U : never;

// Raster image specific metadata attributes
export namespace RasterImageAttributes {
  export const refName = "mos.media.RasterImageAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly widthPx: number,
    readonly heightPx: number,
  }
  export const defaults: Entity = {
    type: refName,
    widthPx: 0,
    heightPx: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.RasterImageAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["widthPx"] = ensureScalar(ctx, v["widthPx"], "number");
      out["heightPx"] = ensureScalar(ctx, v["heightPx"], "number");
      return out as any as Entity;
    }
  }();
}

export const RasterImageFormatValues = new Set([
  'RASTER_IMAGE_FORMAT_UNSPECIFIED' as const,
  'RASTER_IMAGE_FORMAT_JPEG' as const,
  'RASTER_IMAGE_FORMAT_PNG' as const,
]);
export type RasterImageFormat = typeof RasterImageFormatValues extends Set<infer U> ? U : never;

export const RasterImageResizeStrategyValues = new Set([
  'RASTER_IMAGE_RESIZE_STRATEGY_UNSPECIFIED' as const,
  'RASTER_IMAGE_RESIZE_STRATEGY_PRESERVE_ASPECT_RATIO' as const,
  'RASTER_IMAGE_RESIZE_STRATEGY_CROP_TO_FIT' as const,
]);
export type RasterImageResizeStrategy = typeof RasterImageResizeStrategyValues extends Set<infer U> ? U : never;

export namespace RasterImageTransform {
  export const refName = "mos.media.RasterImageTransform" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Crop values may be negative, which will extend the canvas. New pixels will be filled
    // with background_color.
    readonly cropLeftPx: number,
    readonly cropTopPx: number,
    readonly cropRightPx: number,
    readonly cropBottomPx: number,
    readonly backgroundColor: Color.Entity | undefined,
    readonly format: RasterImageFormat,
    // The following can be used to resize a raster image.
    // If only 1 of the 2 values is supplied (non-zero) then the second dimension is
    // automatically calculated relative to the one supplied
    readonly widthPx: number,
    readonly heightPx: number,
    // Specifies what strategy will be used to resize the image
    readonly resizeStrategy: RasterImageResizeStrategy,
  }
  export const defaults: Entity = {
    type: refName,
    cropLeftPx: 0,
    cropTopPx: 0,
    cropRightPx: 0,
    cropBottomPx: 0,
    backgroundColor: undefined,
    format: "RASTER_IMAGE_FORMAT_UNSPECIFIED",
    widthPx: 0,
    heightPx: 0,
    resizeStrategy: "RASTER_IMAGE_RESIZE_STRATEGY_UNSPECIFIED",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.RasterImageTransform");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["cropLeftPx"] = ensureScalar(ctx, v["cropLeftPx"], "number");
      out["cropTopPx"] = ensureScalar(ctx, v["cropTopPx"], "number");
      out["cropRightPx"] = ensureScalar(ctx, v["cropRightPx"], "number");
      out["cropBottomPx"] = ensureScalar(ctx, v["cropBottomPx"], "number");
      out["backgroundColor"] = ctx.decode(Color.codec, v["backgroundColor"], "backgroundColor");
      {
        const ev = ensureScalar(ctx, v["format"], "string") || defaults["format"];
        if (!RasterImageFormatValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.RasterImageFormat`);
        }
        out["format"] = ev as any;
      }
      out["widthPx"] = ensureScalar(ctx, v["widthPx"], "number");
      out["heightPx"] = ensureScalar(ctx, v["heightPx"], "number");
      {
        const ev = ensureScalar(ctx, v["resizeStrategy"], "string") || defaults["resizeStrategy"];
        if (!RasterImageResizeStrategyValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media.RasterImageResizeStrategy`);
        }
        out["resizeStrategy"] = ev as any;
      }
      return out as any as Entity;
    }
  }();
}

// Media can have additional information _tags_ associated with them which are used exclusively for
// searching and human recognition, given they are dynamic they should not be relied upon to exist.
// However this adds some degree of control over the list of tags that can be use. This
// helps avoid issues like `accession_number`, `AccessionNumber`, `AccNum`, `AcccessionNumber` etc.
export namespace Tag {
  export type Ref = { readonly typename: 'mos.media.Tag', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.media.Tag');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Tag`);
    return v;
  }
  export const refName = "mos.media.Tag" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.media.Tag', readonly id: string } | undefined,
    readonly name: string,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    name: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.Tag");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace TagValue {
  export const refName = "mos.media.TagValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly tagRef: { readonly typename: 'mos.media.Tag', readonly id: string } | undefined,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    tagRef: undefined,
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.TagValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["tagRef"] = v["tagRef"];
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

// Wraps up the different types of transformation
// In part this is to make canonical representation and seralization/deserialization
// cleaner in the backend
export namespace Transform {
  export const refName = "mos.media.Transform" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly kind: CustomTransform.Entity | RasterImageTransform.Entity | NoOpTransform.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    kind: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.kind;
        if (uv !== undefined) {
          if (uv.type == "mos.media.CustomTransform") {
            obj["customTransform"] = CustomTransform.codec.encode(uv);
          }
          else if (uv.type == "mos.media.NoOpTransform") {
            obj["noOpTransform"] = NoOpTransform.codec.encode(uv);
          }
          else if (uv.type == "mos.media.RasterImageTransform") {
            obj["rasterImageTransform"] = RasterImageTransform.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.media.Transform.kind");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.Transform");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["customTransform"] !== undefined) {
          out["kind"] = ctx.decode(CustomTransform.codec, v["customTransform"], "customTransform");
        }
        if (v["noOpTransform"] !== undefined) {
          out["kind"] = ctx.decode(NoOpTransform.codec, v["noOpTransform"], "noOpTransform");
        }
        if (v["rasterImageTransform"] !== undefined) {
          out["kind"] = ctx.decode(RasterImageTransform.codec, v["rasterImageTransform"], "rasterImageTransform");
        }
      }
      return out as any as Entity;
    }
  }();
}

// Vector image specific metadata attributes
export namespace VectorImageAttributes {
  export const refName = "mos.media.VectorImageAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.VectorImageAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

// Video specific metadata attributes
export namespace VideoAttributes {
  export const refName = "mos.media.VideoAttributes" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly duration: Duration.Entity | undefined,
    readonly widthPx: number,
    readonly heightPx: number,
  }
  export const defaults: Entity = {
    type: refName,
    duration: undefined,
    widthPx: 0,
    heightPx: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["duration"] = Duration.codec.encode(v.duration);
      obj["widthPx"] = v.widthPx;
      obj["heightPx"] = v.heightPx;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media.VideoAttributes");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["duration"] = ctx.decode(Duration.codec, v["duration"], "duration");
      out["widthPx"] = ensureScalar(ctx, v["widthPx"], "number");
      out["heightPx"] = ensureScalar(ctx, v["heightPx"], "number");
      return out as any as Entity;
    }
  }();
}

