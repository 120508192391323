import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import { Button } from 'components/ui/button'
import { IconClose } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled, { keyframes, css } from 'styled';

const Overlay = styled.div<{ loaded: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ loaded }) => loaded && 'rgba(0, 0, 0, 0.5)'};
  transition: background 0.2s ease;
  backdrop-filter: blur(2px);
`;

const zoomIn = keyframes`
  0% {
      transform: scale(0.8);
  }
  50% {
      transform: scale(1.02);
  }
  100% {
      transform: scale(1);
  }
`;

const Wrapper = styled.div<{ size: ModalSizes; loaded: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 736px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.color.grayL70};
  background: white;
  ${({ size }) => size === 'small' && `
    text-align: center;
    width: 480px;
  `}
  ${({ size, theme }) => size === 'large' && `
    height: calc(100vh - ${space(30)});
    margin: ${space(15)};
    max-width: 1080px;
    width: 100%;
    background: ${theme.color.grayL90}};
  `}

  animation: ${({ loaded }) => loaded && css`${zoomIn} 0.2s ease`};
  animation-fill-mode: forwards;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 5px;

  &:focus {
    position: absolute;
  }
`;

const Header = styled.section`
  padding: ${space(5)} ${space(5)} ${space(2)};
  text-align: center;
  background-color: ${({ theme }) => theme.color.grayL90};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL70};
`;

const Body = styled.section<{ size: ModalSizes }>`
  padding: ${({ size }) => size === 'small' ? space(14) : space(8)};
  min-height: 100px;
  max-height: ${({ size }) => size !== 'large' ? '490px' : undefined};
  overflow-y: auto;
`;

const Footer = styled.section`
  margin-top: auto;
  padding: ${space(4)};
  background-color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.grayL70};
  text-align: center;
`;

type ModalSizes = 'small' | 'base' | 'large';

export type ModalProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  size?: ModalSizes;

  // onClose needs to support button events and keypress events, so it doesn't
  // take an event parameter:
  onClose: () => void;
}
type State = {
  readonly loaded: boolean;
}

export class Modal extends Component<ModalProps, State> {
  private root: Element
  private instance: Element | null

  public constructor(props: ModalProps) {
    super(props)
    this.state = { loaded: false }

    const MODAL_ROOT_ID = 'modal-root'

    const createModalRoot = (id: string) => {
      const root: Element = document.createElement('div')
      root.setAttribute('id', id)
      document.body.appendChild(root)
      return root
    }

    this.root = document.getElementById(MODAL_ROOT_ID) || createModalRoot(MODAL_ROOT_ID)
    this.instance = document.createElement('div')
  }

  private handleKeyPress(event: KeyboardEvent) {
    const KEYCODE_ESCAPE = 27
    const { onClose } = this.props;
    if (event.keyCode === KEYCODE_ESCAPE) {
      onClose()
    }
  }

  public componentDidMount() {
    document.addEventListener('keydown', (e: KeyboardEvent) => this.handleKeyPress(e), false)
    this.instance && this.root.appendChild(this.instance)

    // timeout used to trigger animation
    setTimeout(() => {
      this.setState({ loaded: true })
    }, 0)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', (e: KeyboardEvent) => this.handleKeyPress(e), false)
    this.instance && this.root.removeChild(this.instance)
  }

  public render() {
    const { header, footer, children, onClose, size = 'base' } = this.props;

    const modalContent = (
      <>
        <Overlay loaded={this.state.loaded}>
          <Wrapper size={size} loaded={this.state.loaded}>
            <CloseButton
              variant="subtle"
              isIcon={true}
              onClick={onClose}
            >
              <IconClose size="base" />
            </CloseButton>
            {header && <Header>{header}</Header>}
            <Body size={size}>{children}</Body>
            {footer && <Footer>{footer}</Footer>}
          </Wrapper>
        </Overlay>
      </>
    );

    return this.instance ? ReactDOM.createPortal(modalContent, this.instance) : null
  }
}
