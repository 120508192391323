import React, { useState, useEffect, useRef } from 'react'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { Button, ButtonGroup } from 'components/ui/button'
import { CheckboxInputElement } from 'components/ui/checkbox'
import { FieldSection } from 'components/ui/fieldset'
import { IconBundlePublic, IconBundlePreview, IconMonitor, IconPhone, } from 'components/ui/icons'
import { Loader } from 'components/ui/loader'
import { Description, Title, Subtitle, listItemBaseStyles } from 'components/ui/list-item-label'
import { Confirm } from 'components/ui/modal'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { RadioInputElement } from 'components/ui/radio'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SelectFilter, SelectFilterItem } from 'components/ui/select-filter'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { pick } from 'helpers/core'
import { statusSelector, Status } from 'helpers/status'
import { space } from 'helpers/style'
import { timestampToFormattedString, stringToDateTime } from 'helpers/timestamps'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { PublishDetails } from './sidebar'

import { Release, Device } from '../../types'

import { ThemeColor } from 'styled/theme'
import styled from 'styled'


const SectionTitle = styled.h2`
  margin-top: 0;
  color: ${({ theme }) => theme.color.grayD80};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.base};
  line-height: 1.6;
  &:not(:first-child) {
    margin-top: ${space(18)};
  }
`

const IconStyles = `
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  margin-right: ${space(4)}
`

const IconContainer = styled.span`
  ${IconStyles}
`

const PreviewBundleIcon = styled.span`
  ${IconStyles}
  background: #334563;
`

const PublicBundleIcon = styled.span`
  ${IconStyles}
  background: #1D94D0;
`

const ListItemWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  align-items: center;

  & > *:first-child {
    width: calc(100% - 148px);
  }
`

const ListItemLabel = styled.label<{ disabled?: boolean }>`
  ${listItemBaseStyles}
  flex: 1;
  background: ${({ theme }) => theme.color.white};
  margin-right: ${space(4)};
  &:first-of-type {
    margin-top: ${space(4)};
  }
`

const Devices = styled.div`
  position: relative;
  min-height: 100px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: ${space(2)};
  }
`

const ProcessingStatus = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  width: 172px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.6;
  margin-left: auto;
  margin-right: ${space(2)};
`

const Square = styled.span<{ color: ThemeColor }>`
  width: 14px;
  height: 14px;
  background-color: ${({ theme, color }) => theme.color[color]};
  border-radius: 4px;
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.color.grayL90};
  margin-right: ${space(2)}
`;

const ConfirmDetails = styled.div`
  text-align: left;
  li {
    margin-bottom: ${space(1)};
  }
`

const Loading = () => {
  return <LoadingWrapper>
    <Loader />
  </LoadingWrapper>
}

const POLL_FREQUENCY = 10000

type ActionProps =
  Pick<WamActionCreators, 'wamListReleasableDevicesRequest' | 'wamPublishDevicesRequest' | 'wamSyncLabelRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['publishing'], 'listDevices' | 'release' | 'sync'>


type DirectProps = {
  projectId: string;
  historyPush: (id: string) => void;
}

type Props = ActionProps & ConnectedProps & DirectProps;

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>(() => { });
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay]);
}

type BundleState = {
  public: {
    mobile: boolean;
    digitalLabels: { id: string; title: string }[]
  },
  preview: {
    mobile: boolean;
    digitalLabels: { id: string; title: string }[]
  }
}

enum PUBLISH_TYPE {
  PREVIEW = 'preview',
  PUBLIC = 'public',
}

type FilterProps = {
  publishType: 'PUBLIC' | 'PREVIEW',
  device: Device
}
const filterOptions = [{
  label: 'All devices',
  check: (props: FilterProps) => true,
},
{
  label: 'Only devices with unpublished changes',
  check: ({ publishType, device }: FilterProps) => {
    const latestRelease = publishType === 'PUBLIC' ? device.latestPublicRelease : device.latestPreviewRelease
    return !!latestRelease && stringToDateTime(device.lastModifiedDate) > stringToDateTime(latestRelease.createdDate) && latestRelease.processingStatus !== 'RUNNING'
  },
}]

const PublishingPageView = (props: Props) => {
  const {
    projectId,
    listDevices,
    release,
    sync,
    wamListReleasableDevicesRequest,
    wamPublishDevicesRequest,
    toastNotification,
    wamSyncLabelRequest,
  } = props

  const data = statusSelector.data(listDevices)

  const [publishType, setPublishType] = useState<'PREVIEW' | 'PUBLIC'>('PREVIEW')
  const [confirmPublishModal, setConfirmPublishModal] = useState<boolean>(false)
  const [deviceFilter, setDeviceFilter] = useState<{ label: string; check: (props: FilterProps) => boolean }>(filterOptions[0])
  const [bundle, setBundle] = useState<BundleState>({
    public: {
      mobile: false,
      digitalLabels: [],
    },
    preview: {
      mobile: false,
      digitalLabels: [],
    }
  })

  useEffect(() => {
    wamListReleasableDevicesRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (release.status === Status.Complete) toastNotification({ text: release.message, type: 'success' })
    if (release.status === Status.Failed) toastNotification({ text: "Failed to start the release", type: 'error' })
  }, [release.status])

  // poll releasable devices.
  useInterval(() => {
    wamListReleasableDevicesRequest()
  }, POLL_FREQUENCY);

  useEffect(() => {
    Object.keys(sync).forEach(id => {
      const digitalLabel = sync[id]
      if (digitalLabel.status === Status.Complete) toastNotification({ text: `Sync for Digital label ${id} is complete`, type: 'success' })
      if (digitalLabel.status === Status.Failed) toastNotification({ text: `Failed to sync Digital label ${id}`, type: 'error' })
    })
  }, [sync])

  const submit = () => {
    const { mobile: publishMobile, digitalLabels } = bundle[PUBLISH_TYPE[publishType]]
    const publishDigitalLabels = digitalLabels.map(label => label.id)
    toastNotification({
      text: 'Your current release has started', type: 'info'
    })
    wamPublishDevicesRequest({
      audiences: [PUBLISH_TYPE[publishType]],
      publishMobile,
      publishDigitalLabels
    })
  }

  const togleDigitalLabel = ({ id, title }: { id: string; title: string }) => {
    const labels = bundle[PUBLISH_TYPE[publishType]].digitalLabels
    if (labels.some(label => label.id === id)) return labels.filter((label) => label.id !== id)
    return [...labels, { id, title }]
  }

  const toggleBundleOption = (key: 'mobile' | 'digitalLabels', details?: { id: string, title: string }) => {
    const value = key === 'digitalLabels' && details ? togleDigitalLabel(details) : !bundle[PUBLISH_TYPE[publishType]][key]
    setBundle({
      ...bundle,
      [PUBLISH_TYPE[publishType]]: {
        ...bundle[PUBLISH_TYPE[publishType]],
        [key]: value,
      }
    })
  }

  const getStatus = (latestRelease: Release, lastModifiedDate: string) => {
    const color = latestRelease.processingStatus === 'RUNNING' ? 'warning' : 'info'
    const text = latestRelease.processingStatus === 'RUNNING' ? 'Publish in progress' : 'Unpublished changes'
    return latestRelease.processingStatus === 'RUNNING' || stringToDateTime(lastModifiedDate) > stringToDateTime(latestRelease.createdDate)
      ? <ProcessingStatus>
        <Square color={color} />
        <span>{text}</span>
      </ProcessingStatus>
      : null
  }

  const mobileOption = (device: Device) => {
    const { latestPublicRelease, latestPreviewRelease, title, id, lastModifiedDate } = device
    const latestRelease = publishType === 'PUBLIC' ? latestPublicRelease : latestPreviewRelease
    const disabled = latestRelease && latestRelease.processingStatus === 'RUNNING'
    if (device)
      return (
        <ListItemLabel disabled={disabled}>
          <CheckboxInputElement
            disabled={disabled}
            checked={bundle[PUBLISH_TYPE[publishType]].mobile}
            onChange={() => toggleBundleOption('mobile')}
          />
          <IconContainer><IconPhone /></IconContainer>
          <Description>
            <Title>{title ? title : 'Mobile'}</Title>
          </Description>
          {latestRelease && getStatus(latestRelease, lastModifiedDate)}
        </ListItemLabel>
      )
  }

  const syncLabel = (id: string) => wamSyncLabelRequest({ id })

  const syncStatus = (id: string) => {
    const status = sync[id] && sync[id].status
    switch (status) {
      case Status.Loading: return 'Syncing'
      case Status.Failed: return 'Retry'
      case Status.Complete: return 'Complete'
      case Status.Idle:
      default: return 'ECMS Sync'
    }
  }

  const syncDisabled = (id: string) => {
    const status = sync[id] && sync[id].status
    return status === Status.Loading
  }

  const digitalLabelOption = (device: Device) => {
    const { latestPublicRelease, latestPreviewRelease, title, id, lastModifiedDate } = device
    const latestRelease = publishType === 'PUBLIC' ? latestPublicRelease : latestPreviewRelease
    const disabled = latestRelease && latestRelease.processingStatus === 'RUNNING'
    return (
      <ListItemWrap>
        <ListItemLabel disabled={disabled} key={id}>
          <CheckboxInputElement
            checked={bundle[PUBLISH_TYPE[publishType]].digitalLabels.some(label => label.id === id)}
            disabled={disabled}
            onChange={() => toggleBundleOption('digitalLabels', { id, title })}
          />
          <IconContainer><IconMonitor /></IconContainer>
          <Description>
            <Title>{title}</Title>
            <Details>
              <Subtitle>ID: {id}</Subtitle>
              {/* TODO: Add in last sync time one added to devices in device list endpoint */}
              {/* <Subtitle>Last sync: {TIME_HERE}</Subtitle> */}
            </Details>
          </Description>
          {latestRelease && getStatus(latestRelease, lastModifiedDate)}
        </ListItemLabel>
        <Button appearance="secondary" isDisabled={syncDisabled(id)} onClick={() => syncLabel(id)}>{syncStatus(id)}</Button>
      </ListItemWrap>
    )
  }
  const mobile = data && [data.mobile].filter(device => deviceFilter.check({ publishType, device }))[0]
  return (
    <LayoutListOrFormPage
      nav={() => (
        <PrimaryNavigationEditEntity
          title="Publish Project"
          navBackRoute={`/project/${projectId}/tours`}
          darkTheme
        />
      )}
      title={'Publish "WAM"'}
      subnav={() => (
        <SecondaryNavigation
          navItems={[]}
        />
      )}
      content={() => (
        <>
          {confirmPublishModal && data &&
            <Confirm
              title="Are you sure you want to publish?"
              onClose={() => setConfirmPublishModal(false)}
              message={<ConfirmDetails>
                <strong>Publishing to these devices:</strong>
                <ul>
                  {bundle[PUBLISH_TYPE[publishType]].mobile && <li>{`${data.mobile.title ? data.mobile.title : 'Mobile'}`}</li>}
                  {bundle[PUBLISH_TYPE[publishType]].digitalLabels.map(label => <li>{`${label.title}`}</li>)}
                </ul>
                <strong>Audience set to:</strong>
                <ul><li>{publishType === 'PUBLIC' ? 'Public' : 'Preview'}</li></ul>
              </ConfirmDetails>}
              confirmLabel="Publish"
              onConfirm={() => {
                setConfirmPublishModal(false)
                submit()
              }}
            />
          }
          <SectionTitle>Select an audience to publish to</SectionTitle>
          <ListItemLabel>
            <RadioInputElement
              checked={publishType === 'PUBLIC'}
              name='publishType'
              onChange={() => setPublishType('PUBLIC')}
            />
            <PublicBundleIcon>
              <IconBundlePublic color="white" />
            </PublicBundleIcon>
            <Description>
              <Title>Public</Title>
              <Subtitle>Public content is made visible to the public</Subtitle>
            </Description>
          </ListItemLabel>
          <ListItemLabel>
            <RadioInputElement
              checked={publishType === 'PREVIEW'}
              name='publishType'
              onChange={() => setPublishType('PREVIEW')}
            />
            <PreviewBundleIcon>
              <IconBundlePreview color="white" />
            </PreviewBundleIcon>
            <Description>
              <Title>Preview</Title>
              <Subtitle>Preview content can be viewed by staff only</Subtitle>
            </Description>
          </ListItemLabel>
          <SectionTitle>Which device(s) would you like to publish to?</SectionTitle>
          <SelectFilter value={deviceFilter.label}>
            {filterOptions.map(filter => (
              <SelectFilterItem key={filter.label} selected={deviceFilter.label === filter.label} onClick={() => {
                setDeviceFilter(filter)
              }}>
                {filter.label}
              </SelectFilterItem>
            ))}
          </SelectFilter>
          <Devices>
            {data ? <>
              {mobile && mobileOption(mobile)}
              {data.digitalLabels.filter(device => deviceFilter.check({ publishType, device })).map(digitalLabelOption)}
            </> : <Loading />}
          </Devices>
          <FieldSection />
          <ButtonGroup>
            <Button appearance="positive" isDisabled={!bundle[PUBLISH_TYPE[publishType]].mobile && bundle[PUBLISH_TYPE[publishType]].digitalLabels.length === 0} onClick={() => setConfirmPublishModal(true)}>Publish</Button>
          </ButtonGroup>
        </>
      )}
      sidebar={() => (
        <PublishDetails publishDate={data && data.latestRelease && timestampToFormattedString(data.latestRelease.createdDate) || ''} projectId={projectId} />
      )}
    />
  )
}

export const PublishingPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators>(
    (store) => pick(store.wam.publishing, 'listDevices', 'release', 'sync'),
    {
      ...pick(actionCreators, 'wamListReleasableDevicesRequest', 'wamPublishDevicesRequest', 'wamSyncLabelRequest'),
      ...pick(sharedActionCreators, 'toastNotification'),
    }
  )(PublishingPageView)
