// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Metadata } from "generated/mos/entity";
import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarOptional } from "generated/webrpc";

export const BundleTypeValues = new Set([
  'BUNDLE_TYPE_UNSPECIFIED' as const,
  'BUNDLE_TYPE_PRIVATE' as const,
  'BUNDLE_TYPE_PUBLIC' as const,
]);
export type BundleType = typeof BundleTypeValues extends Set<infer U> ? U : never;

// Records when an entity changes
// 
// Like `ReleaseControl`, this message has an untyped ref field as it can refer to any entity.
// This message is used to keep track of what entities have changes over time in order that the system can
// answer questions like "what has changed since we last published a public bundle?"
// Only those entities that can be included in a bundle need create `EntityChange` messages
export namespace EntityChange {
  export const refName = "mos.release_controls.EntityChange" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly entityRef: { readonly typename: string, readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    readonly notes: string,
  }
  export const defaults: Entity = {
    type: refName,
    entityRef: undefined,
    metadata: undefined,
    notes: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["entityRef"] = v.entityRef;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["notes"] = v.notes;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls.EntityChange");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["entityRef"] = v["entityRef"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["notes"] = ensureScalar(ctx, v["notes"], "string");
      return out as any as Entity;
    }
  }();
}

// Represents the release status of an entity, marking whether it should or should not be included in a bundle
// 
// The message models the ability to have individual controls and metadata for any number of bundles. The
// actual range of permissable bundles is intentionaly constrained by the `BundleType` enum. This ensure that
// the client making use of `ReleaseControl` is not also lumped with managing integrity of bundle types.
// 
// Note that `ref` is not of a particular type or union of types, this is intentional as it allows release
// controls to be applied to any entity that can be identified by a Ref.
// It also helps keep the concept of release controls independant of any org specific concepts of what is
// publishable.
// 
// Whilst it is possible for _any_ entity to have a release control, the intent is that only those that can
// be included in a published bundle need to. The absence of a release control, or the absence of a specific
// bundle type in a release control should be considered identical to if it did exist, but was set as
// `include=False`, i.e. it should be omitted from bundles of that type.
// 
// Also note that `ref` is still the identity of the message - but one that is shares with the entity it
// represent, there is no ReleaseControl ref type in it's own right
export namespace ReleaseControl {
  export const refName = "mos.release_controls.ReleaseControl" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    readonly bundles: ReadonlyArray<ReleaseControl.Bundle.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    bundles: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["bundles"] = [];
      for (const item of v.bundles) {
        obj["bundles"].push(ReleaseControl.Bundle.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls.ReleaseControl");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["bundles"] = decodeMessageRepeated(ctx, ReleaseControl.Bundle.codec, v["bundles"], "bundles");
      return out as any as Entity;
    }
  }();
  export namespace Bundle {
    export const refName = "mos.release_controls.ReleaseControl.Bundle" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly bundleType: BundleType,
      readonly include: boolean,
      readonly lastPublished: Timestamp | undefined,
      readonly changedSincePublish: boolean,
    }
    export const defaults: Entity = {
      type: refName,
      bundleType: "BUNDLE_TYPE_UNSPECIFIED",
      include: false,
      lastPublished: undefined,
      changedSincePublish: false,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["bundleType"] = v.bundleType;
        obj["include"] = v.include;
        obj["lastPublished"] = v.lastPublished ? v.lastPublished.value : "";
        obj["changedSincePublish"] = v.changedSincePublish;
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.release_controls.ReleaseControl.Bundle");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        {
          const ev = ensureScalar(ctx, v["bundleType"], "string") || defaults["bundleType"];
          if (!BundleTypeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.release_controls.BundleType`);
          }
          out["bundleType"] = ev as any;
        }
        out["include"] = ensureScalar(ctx, v["include"], "boolean");
        {
          const tsv = ensureScalarOptional(ctx, v["lastPublished"], "string");
          out["lastPublished"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
        }
        out["changedSincePublish"] = ensureScalar(ctx, v["changedSincePublish"], "boolean");
        return out as any as Entity;
      }
    }();
  }

}

