/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconParagraph = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconParagraphString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z"  fillRule="nonzero" />
  </svg>`

IconParagraph.displayName = 'IconParagraph'
