import * as styledComponents from 'styled-components';

import { theme, ThemeInterface } from './theme';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

// Type returned by css()
export type FlattenSimpleInterpolation = styledComponents.FlattenSimpleInterpolation;

// There is a default export and unused exports to match the api of styled-components
// while providing correct types for SC and the theme object. This means we can use
// `import ... from 'styled';` the same as `import ... from 'styled-components';`
export { css, createGlobalStyle, keyframes, ThemeProvider, theme };
export default styled;
