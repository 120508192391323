import { call } from 'redux-saga/effects'

import { getTokens } from 'domains/account/auth-session'
import { ensureValidSession, ensureActiveJWT } from '.'

const API_DOMAIN = `${process.env.REACT_APP_API_DOMAIN || window.location.origin}/ems`

type HttpVerbs = 'PUT' | 'GET' | 'POST' | 'PATCH' | 'DELETE'

type RequestData<TRequestEntity> = {
  readonly payload: TRequestEntity;
  readonly verb: HttpVerbs;
}

type Payload = {
  method: HttpVerbs,
  headers: Headers,
  body?: string | FormData,
}

export const assemblePayload = <TRequestEntity>(
  { payload, verb = 'GET' }: RequestData<TRequestEntity>
): Payload => {

  const headers = new Headers()
  let body: Payload['body']

  const isMultipart = payload instanceof FormData
  const tokens = getTokens()

  // standard headers for http rest calls.
  if (tokens && tokens.access) headers.append('Authorization', `Bearer ${tokens.access}`)
  // allow fetch to automatically assemble the boundary for multipart formdata.
  if (!isMultipart) headers.append('Content-Type', 'application/json; charset=utf-8')

  // transformations to request payload.
  if (isMultipart) {
    body = payload as unknown as FormData
  } else if (verb !== 'GET') {
    body = JSON.stringify(payload)
  }

  return {
    method: isMultipart ? 'POST' : verb,
    headers,
    body,
  }
}

export function* httpRest<TRequestEntity, TResponseEntity>(
  endpoint: string,
  payloadBody: TRequestEntity,
  verb: HttpVerbs,
): Generator<any, TResponseEntity, any> {
  // attemp to check jwt validity.
  yield call(ensureActiveJWT)

  // encode and assemble the payload.
  let payload = assemblePayload<TRequestEntity>({ payload: payloadBody, verb })

  // attempt to make the network request, or terminate on error.
  let result: Response

  try {
    result = yield call(fetch, `${API_DOMAIN}/${endpoint}`, payload)
  } catch (error) {
    throw new Error(`There was a network error: ${error.message}.`)
  }

  // check for errors in the response.
  if (!result || !result.ok) {
    // check for invalid access token.
    if (result.status && result.status === 401) {
      // refresh access token or queue request until a new token is obtained.
      yield call(ensureValidSession)

      // once ready, retry the network request a second and final time.
      try {
        payload = assemblePayload<TRequestEntity>({ payload: payloadBody, verb })
        result = yield call(fetch, `${API_DOMAIN}/${endpoint}`, payload)
      } catch (error) {
        throw new Error(`There was a network error: ${error.message}.`)
      }

      if (!result || !result.ok) {
        const res = yield call([result, 'json'])
        throw new Error(res && res.detail || 'There was an error with the response.')
      }
    }
    else if (result.status) {
      const res = yield call([result, 'json'])
      throw new Error(res && res.detail || 'There was an error with the response.')
    }
    else {
      throw new Error('There was an error with receiving an expected response.')
    }
  }

  // return empty response body if status is 204 (No Content success)
  if (result.status === 204) {
    return yield {}
  }

  return yield call([result, 'json'])
}
