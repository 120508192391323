/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconRoom = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M12 2c3.87 0 7 3.13 7 7 0 5.25-7 13-7 13l-.412-.473C10.087 19.767 5 13.475 5 9c0-3.87 3.13-7 7-7zm0 4.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconRoomString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M12 2c3.87 0 7 3.13 7 7 0 5.25-7 13-7 13l-.412-.473C10.087 19.767 5 13.475 5 9c0-3.87 3.13-7 7-7zm0 4.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"  fillRule="nonzero" />
  </svg>`

IconRoom.displayName = 'IconRoom'
