/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconAudioStop = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v14H5V5h14zm-7 4.5A2.497 2.497 0 009.5 12c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconAudioStopString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v14H5V5h14zm-7 4.5A2.497 2.497 0 009.5 12c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z"  fillRule="nonzero" />
  </svg>`

IconAudioStop.displayName = 'IconAudioStop'
