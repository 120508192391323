// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";

export namespace Error {
  export const refName = "mosx.errors.Error" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The type that defines the value referenced in 'code'. Fully-qualified,
    // resolvable path through which a Protocol Buffer `EnumDescriptor` should be
    // obtainable, with nesting levels separated by periods.
    readonly codeScope: string,
    // Numeric value of the enum associated with this error. Must exist in the
    // enum defined by code_scope.
    readonly code: number,
    // debug_name should contain the enum's symbolic name, but may contain any
    // short English-language representation of the error code.
    // 
    // The value in 'debug_name' is unstable and is for debugging or logging
    // purposes only. It is not intended to be shown to any users, nor should it
    // be used as the key in any translation files (a representation of a code
    // ref should be used for that).
    readonly debugName: string,
    // Optional list of paths to the field in the input that caused this Error.
    // 
    // The `paths` field is optional; semantically, errors that have no paths are
    // associated to the input as a whole, not to a specific field within it.
    // Multiple input paths can relate to a single Error.
    // 
    // Each path is a period delimited path to the field in the input type that
    // relates to the error. Field keys and map keys can be descended into using
    // string segments, i.e. `foo.bar.baz`. Repeated fields can be
    // descended into with numeric segments, i.e. `foo.0.bar`.
    readonly paths: ReadonlyArray<string>,
    // The `parameters` field contains a simple list of key/value string
    // substitutions for use in a translated message. This is an unsophisticated,
    // stringly-typed mechanism for substitution and is strictly a presentational
    // feature, so clients MUST NOT use `parameters` for decision-making.
    // 
    // This proposal presumes a model like [i18next](https://github.com/i18next/i18next)
    // for message templating is used, where the error code (in our case the
    // `code_scope/code` pair is used to look up a template string, which then
    // has a substitution step with `parameters` performed.
    readonly parameters: Readonly<{ [key: string]: string }>,
  }
  export const defaults: Entity = {
    type: refName,
    codeScope: "",
    code: 0,
    debugName: "",
    paths: [],
    parameters: {},
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mosx.errors.Error");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["codeScope"] = ensureScalar(ctx, v["codeScope"], "string");
      out["code"] = ensureScalar(ctx, v["code"], "number");
      out["debugName"] = ensureScalar(ctx, v["debugName"], "string");
      out["paths"] = ensureScalarRepeated(ctx, v["paths"], "string");
      {
        const into: any = {};
        const from = v["parameters"];
        if (typeof(v["parameters"]) !== "object") {
          throw ctx.expected("object", v["parameters"]);
        }
        for (const key in from) {
          into[key] = ensureScalar(ctx, from[key], "string");
        }
        out["parameters"] = into;
      }
      return out as any as Entity;
    }
  }();
}

export namespace Errors {
  export const refName = "mosx.errors.Errors" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly errors: ReadonlyArray<Error.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    errors: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mosx.errors.Errors");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["errors"] = decodeMessageRepeated(ctx, Error.codec, v["errors"], "errors");
      return out as any as Entity;
    }
  }();
}

