import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import {
  GetAtmosphericZoneRequest,
  GetAtmosphericZoneResponse,
  UpdateAtmosphericZoneRequest,
  UpdateAtmosphericZoneResponse,
  DeleteAtmosphericZoneRequest,
  DeleteAtmosphericZoneResponse,
} from '../types'
import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const atmosphericZonesActionCreators = {
  wamGetAtmosphericZoneRequest: (
    payload: GetAtmosphericZoneRequest,
  ): FluxStandardAction<GetAtmosphericZoneRequest, 'wamGetAtmosphericZoneRequest'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamGetAtmosphericZoneRequest' as const,
    payload,
  }),
  wamGetAtmosphericZoneSuccess: (
    payload: GetAtmosphericZoneResponse,
  ): FluxStandardAction<GetAtmosphericZoneResponse, 'wamGetAtmosphericZoneSuccess'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamGetAtmosphericZoneSuccess' as const,
    payload,
  }),
  wamGetAtmosphericZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamGetAtmosphericZoneFailure'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamGetAtmosphericZoneFailure' as const,
    payload,
    error: true,
  }),
  wamUpdateAtmosphericZoneRequest: (
    payload: UpdateAtmosphericZoneRequest,
  ): FluxStandardAction<UpdateAtmosphericZoneRequest, 'wamUpdateAtmosphericZoneRequest'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamUpdateAtmosphericZoneRequest' as const,
    payload,
  }),
  wamUpdateAtmosphericZoneSuccess: (
    payload: UpdateAtmosphericZoneResponse,
  ): FluxStandardAction<UpdateAtmosphericZoneResponse, 'wamUpdateAtmosphericZoneSuccess'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamUpdateAtmosphericZoneSuccess' as const,
    payload,
  }),
  wamUpdateAtmosphericZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamUpdateAtmosphericZoneFailure'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamUpdateAtmosphericZoneFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteAtmosphericZoneRequest: (
    payload: DeleteAtmosphericZoneRequest,
  ): FluxStandardAction<DeleteAtmosphericZoneRequest, 'wamDeleteAtmosphericZoneRequest'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamDeleteAtmosphericZoneRequest' as const,
    payload,
  }),
  wamDeleteAtmosphericZoneSuccess: (
    payload: DeleteAtmosphericZoneResponse
  ): FluxStandardAction<DeleteAtmosphericZoneResponse, 'wamDeleteAtmosphericZoneSuccess'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamDeleteAtmosphericZoneSuccess' as const,
    payload
  }),
  wamDeleteAtmosphericZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamDeleteAtmosphericZoneFailure'> => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamDeleteAtmosphericZoneFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteAtmosphericZoneReset: () => ({
    domain: 'wam.atmospheric-zones' as const,
    type: 'wamDeleteAtmosphericZoneReset' as const
  }),
}

type WamAction = ReturnType<typeof atmosphericZonesActionCreators[keyof typeof atmosphericZonesActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.atmospheric-zones'
}

export const atmosphericZonesReducer = (
  state: WamAppState['atmosphericZones'] = initialWamAppState.atmosphericZones,
  action: WamAction,
): WamAppState['atmosphericZones'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamGetAtmosphericZoneRequest': {
      return {
        ...state,
        atmosphericZonesUpdate: {
          status: Status.Loading,
        },
      }
    }
    case 'wamGetAtmosphericZoneSuccess': {
      const { payload } = action
      return {
        ...state,
        atmosphericZonesUpdate: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamGetAtmosphericZoneFailure': {
      const { payload } = action
      return {
        ...state,
        atmosphericZonesUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamUpdateAtmosphericZoneRequest': {
      if (state.atmosphericZonesUpdate.status !== Status.Ready) {
        throw new Error(
          '[ wamUpdateAtmosphericZoneRequest ] Cannot update entity when `atmosphericZonesUpdate` is not in Ready state',
        )
      }
      return {
        ...state,
        atmosphericZonesUpdate: {
          ...state.atmosphericZonesUpdate,
          status: Status.Updating,
        },
      }
    }
    case 'wamUpdateAtmosphericZoneSuccess': {
      const { payload } = action
      return {
        ...state,
        atmosphericZonesUpdate: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamUpdateAtmosphericZoneFailure': {
      const { payload } = action
      return {
        ...state,
        atmosphericZonesUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteAtmosphericZoneRequest': {
      return {
        ...state,
        atmosphericZoneDelete: {
          status: Status.Loading
        }
      }
    }
    case 'wamDeleteAtmosphericZoneSuccess': {
      return {
        ...state,
        atmosphericZoneDelete: {
          status: Status.Complete,
          message: 'Atmospheric sound deleted',
        }
      }
    }
    case 'wamDeleteAtmosphericZoneFailure': {
      const { payload } = action
      return {
        ...state,
        atmosphericZoneDelete: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteAtmosphericZoneReset': {
      return {
        ...state,
        atmosphericZoneDelete: {
          status: Status.Idle
        }
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
