import React from 'react'

import { Mapbox, MapSet, MapView } from 'components/mapbox';
import { MapInitialBounds } from 'components/mapbox/core';
import { NavigationControlSet } from 'components/mapsets/navigation-control';
import * as entity from 'entity';
import * as geo from 'helpers/geo';
import * as mapStyles from 'helpers/map-styles';
import { Site } from 'generated/mos/structure';

import { structuresSelector } from 'domains/structures';
import { StructureRef, buildAdminMapCorners, Structures } from 'domains/structures/entities';
import { AdminMapSet, BoundarySet } from 'domains/structures/mapsets';

type Props = {
  readonly mapId?: string;
  readonly mapView: MapView;
  readonly onMapViewChanged?: (e: MapView) => void;

  readonly structures?: Structures;
  readonly highlightRef?: StructureRef;
  readonly selectedRef?: StructureRef;
  readonly initialBounds?: MapInitialBounds | undefined;
};

export class StructuresOutlineMap extends React.Component<Props> {
  public render() {
    const [ activeBoundarySet, inactiveBoundarySet ] = this.createStructureMapSets();

    const sets = [
      this.createAdminMapSet(),
      this.createHighlightSet(),
      activeBoundarySet,
      inactiveBoundarySet,
      new NavigationControlSet({ showCompass: false }),
    ];

    return (
      <Mapbox
        mapId={this.props.mapId}
        view={this.props.mapView}
        onViewChanged={this.props.onMapViewChanged}
        mapSets={sets}
        initialBounds={this.props.initialBounds}
      />
    );
  }

  private createStructureMapSets = (): [ MapSet | undefined, MapSet | undefined ] => {
    const { selectedRef, structures } = this.props;
    if (!structures) {
      return [ undefined, undefined ];
    }

    const activeSiteRef = selectedRef ? structuresSelector.siteRef(structures, selectedRef) : undefined;
    let activeSites = structures.sites.filter((site) => site.ref === activeSiteRef);
    let inactiveSites = structures.sites.filter((site) => site.ref !== activeSiteRef);

    // if no active sites, then all sites show as active
    if (activeSites.length === 0) {
      activeSites = inactiveSites;
      inactiveSites = [];
    }

    const activeBoundaries = Array.from(allBoundaries(structures, activeSites));
    const inactiveBoundaries = Array.from(allBoundaries(structures, inactiveSites));

    return [
      new BoundarySet({ key: 'structures-active', boundaries: activeBoundaries }),
      new BoundarySet({ key: 'structures', boundaries: inactiveBoundaries, color: mapStyles.backgroundBoundaryColor }),
    ];
  }


  private createAdminMapSet(): MapSet | undefined {
    const { selectedRef, structures } = this.props;
    if (!structures || !selectedRef) {
      return;
    }
    const selectedEntity = structures.index[entity.refToUrn(selectedRef)];
    if (!selectedEntity) {
      return;
    }

    const adminMap = structuresSelector.selectAdminMapByRef(structures, selectedRef);
    if (adminMap === undefined || !adminMap.imageUrl) return undefined;
    const adminMapCorners = buildAdminMapCorners(adminMap);
    if (adminMapCorners === undefined) return undefined;

    return new AdminMapSet('adminmap', adminMap.imageUrl, adminMapCorners);
  }

  private createHighlightSet(): MapSet | undefined {
    const { highlightRef, structures } = this.props;
    if (!structures || !highlightRef) {
      return;
    }

    const highlightBoundary = structuresSelector.selectBoundaryByRef(structures, highlightRef);

    return new BoundarySet({ key: 'structhover', boundaries: highlightBoundary ? [highlightBoundary] : [] });
  }
}

function *allBoundaries(structures: Structures, sites: ReadonlyArray<Site.Entity>): IterableIterator<geo.Polygon2D> {
  for (const site of sites) {
    if (site.boundary) {
      yield site.boundary;
    }
    for (const buildingRef of (site.buildingRefs || [])) {
      const boundary = structuresSelector.selectBoundaryByRef(structures, buildingRef);
      if (boundary) {
        yield boundary;
      }
    }
  }
}
