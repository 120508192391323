import { Language } from 'generated/mos/i18n'
import { Attachment, AttachmentIntent } from 'generated/mos/media'

import { defaultLanguage } from 'helpers/i18n'
import { getValuesForLanguage } from 'helpers/translatables'

export const getAttachment = (
  attachments: ReadonlyArray<Attachment.Entity>,
  intent: AttachmentIntent,
  requestedLanguage: Language.Entity,
): Attachment.Entity | undefined => {
  const language = requestedLanguage || defaultLanguage()
  const matchesIntent = attachments.filter(attachment => attachment.intent === intent)
  const matches = getValuesForLanguage(matchesIntent, language) as any as ReadonlyArray<Attachment.Entity>

  return matches && matches.length ? matches[0] : undefined
}
