import mapboxgl from 'mapbox-gl';
import React from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAPBOX_STYLES, REACT_APP_MAPBOX_DEVTOOLS } from './constants';
import { App } from 'components/app';
import { DisplayEnv } from 'components/env-helpers';
import { ToastNotifications } from 'components/ui/toast-notifications';
import { MapboxProvider } from 'components/mapbox';
import { Routes } from 'routes';
import { store } from 'containers/store';
import { theme, ThemeProvider } from 'styled';
import { GlobalStyles } from 'styled/global-styles';
import { ErrorPage } from './pages/error/500';
import * as serviceWorker from './serviceWorker';

import { getCenter } from 'helpers/locate'
import { captureException, configureErrorLogging } from 'helpers/logging'

// locate.
import 'components/mapbox/style.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// global.
import 'styled/normalize.css';

type State = {
  readonly hasError: boolean;
};

type Props = {};

class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true })
    captureException({ error, errorInfo })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

const useMapboxDevTools = REACT_APP_MAPBOX_DEVTOOLS;
const mapStyle = REACT_APP_MAPBOX_STYLES;

configureErrorLogging()

ReactDOM.render(
  <Provider store={store}>
    {/* FIXME: default centre point should be determined by the Org: */}
    <MapboxProvider value={{ center: getCenter(), devTools: useMapboxDevTools, mapStyle: mapStyle }}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <BrowserRouter>
            <ErrorBoundary>
              <App>
                {process.env.NODE_ENV !== 'production' && <DisplayEnv />}
                {/** FIXME: offset should be dynamic based on if footer is present. */}
                <ToastNotifications offset />
                <Routes />
              </App>
            </ErrorBoundary>
          </BrowserRouter>
        </>
      </ThemeProvider>
    </MapboxProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


// FIXME: this isn't supported by the mapbox integration yet
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN || '';
