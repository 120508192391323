import { connect as innerConnect, MapDispatchToProps, Options } from 'react-redux';
import { withRouter } from 'react-router'; // eslint-disable-line no-restricted-imports
import {
  Action,
  AnyAction,
  Reducer,
  ReducersMapObject,
  applyMiddleware,
  combineReducers,
} from 'redux';
import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension, ISagaModule } from 'redux-dynamic-modules-saga'
import { getThunkExtension } from 'redux-dynamic-modules-thunk'
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

// {{{ domains.

// account.
import { AccountAction, AccountBindableActionCreators, accountReducer } from 'domains/account/actions';
import { accountLoadInitialState, authSessionMiddleware } from 'domains/account/auth-session';
import { AccountAppState, initialAccountAppState } from 'domains/account/store';
import { accountRootSaga } from 'domains/account/sagas'

// beacons.
import { BeaconsAction, BeaconsActionCreators, beaconsReducer } from 'domains/beacons/actions';
import { BeaconsAppState, initialBeaconsAppState } from 'domains/beacons/store';
import { beaconsRootSaga } from 'domains/beacons/sagas'

// linkable.
import { LinkableAction, LinkableActionCreators, linkableReducer } from 'domains/linkable/actions'
import { initialLinkableAppState, LinkableAppState } from 'domains/linkable/store'
import { linkableRootSaga } from 'domains/linkable/sagas'

// media.
import { MediaAction, MediaActionCreators, mediaReducer } from 'domains/media/actions'
import { initialMediaAppState, MediaAppState } from 'domains/media/store'
import { mediaRootSaga } from 'domains/media/sagas'

// release-controls.
import { ReleaseControlsAction, ReleaseControlsActionCreators, releaseControlsReducer} from 'domains/release-controls/actions';
import { initialReleaseControlsAppState, ReleaseControlsAppState } from 'domains/release-controls/store';
import { releaseControlsRootSaga } from 'domains/release-controls/sagas'

// spaces.
import { SpacesAction, SpacesBindableActionCreators, spacesReducer } from 'domains/spaces/actions';
import { initialSpacesAppState, SpacesAppState } from 'domains/spaces/store';
import { spacesRootSaga } from 'domains/spaces/sagas'

// structures.
import { StructuresAction, ActionCreators as StructuresActionCreators, structuresReducer } from 'domains/structures/actions';
import { initialStructuresAppState, StructuresAppState } from 'domains/structures/store';
import { structuresRootSaga } from 'domains/structures/sagas'

// }}} domains.

import { initialSharedAppState, SharedAction, SharedActionCreators, SharedAppState, sharedReducer } from './shared';

export type DomainAction =
  AccountAction |
  BeaconsAction |
  LinkableAction |
  MediaAction |
  ReleaseControlsAction |
  SharedAction |
  SpacesAction |
  StructuresAction |

  ReturnType<typeof globalActionCreators[keyof typeof globalActionCreators]>
;

export type ActionCreators =
  AccountBindableActionCreators |
  BeaconsActionCreators |
  LinkableActionCreators |
  MediaActionCreators |
  ReleaseControlsActionCreators |
  SpacesBindableActionCreators |
  StructuresActionCreators |

  SharedActionCreators |

  typeof globalActionCreators
;

export type AppState = {
  account: AccountAppState;
  beacons: BeaconsAppState;
  linkable: LinkableAppState;
  media: MediaAppState;
  releaseControls: ReleaseControlsAppState;
  shared: SharedAppState;
  spaces: SpacesAppState;
  structures: StructuresAppState;
};

const initialAppState: AppState = {
  account: initialAccountAppState,
  beacons: initialBeaconsAppState,
  linkable: initialLinkableAppState,
  media: initialMediaAppState,
  releaseControls: initialReleaseControlsAppState,
  shared: initialSharedAppState,
  spaces: initialSpacesAppState,
  structures: initialStructuresAppState,
};

const rootReducers = {
  account: accountReducer,
  beacons: beaconsReducer,
  linkable: linkableReducer,
  media: mediaReducer,
  releaseControls: releaseControlsReducer,
  shared: sharedReducer,
  spaces: spacesReducer,
  structures: structuresReducer,
}

function* rootSaga() {
  return yield all([
    ...accountRootSaga,
    ...beaconsRootSaga,
    ...linkableRootSaga,
    ...mediaRootSaga,
    ...releaseControlsRootSaga,
    ...spacesRootSaga,
    ...structuresRootSaga,
  ])
}

// FIXME: only global functionality should be in the root module (account, locate, media)
//   other domains should later be dynamically added if the user/org has access to it.
// create root redux module of global functionality.
const getRootModule = (): ISagaModule<AppState> => ({
  id: 'root',
  reducerMap: {
    ...rootReducers,
  } as any,
  retained: true,
  sagas: [ rootSaga ],
})

type DomainActionTemplate = {
  readonly domain: keyof AppState;
  readonly type: string;
};

// Please do not add to this; only extremely special cases should go in here.
// Run-of-the-mill shared actions should go in sharedActionCreators.
export const globalActionCreators = {
  reset: () => ({ type: 'reset' as const }),
};

const advancedCombineReducers = (reducers: ReducersMapObject<AppState, AnyAction>): Reducer<AppState, AnyAction> => {
  const combinedReducer = combineReducers(reducers)

  const enhancedReducer = (state: AppState = initialAppState, action: AnyAction): AppState => {
    // this reducer handles actions which should be global across the application. these actions
    // originate from `globalActionCreators` and should be used sparingly.
    const globalReducer = (state: AppState, action: AnyAction): AppState => {
      switch (action.type) {
        case 'reset': {
          return {
            ...initialAppState,
            account: {
              ...initialAppState.account,
            }
          }
        }

        default: {
          return state
        }
      }
    }

    const intermediateState = combinedReducer(state, action)
    const finalState = globalReducer(intermediateState, action)
    return finalState
  }

  return enhancedReducer
}

export const configureStore = (initialState: AppState) => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = applyMiddleware(sagaMiddleware, thunkMiddleware, authSessionMiddleware)

  const store = createStore<AppState>({
      advancedCombineReducers,
      initialState,
      enhancers: [ middleware ],
      extensions: [
        getSagaExtension(),
        getThunkExtension(),
      ],
    },
    getRootModule(),
  )

  return store
}

const startupAppState = { ...initialAppState, account: accountLoadInitialState() };

export const store = configureStore(startupAppState);


type ExtraConnectOptions = {
  readonly respondToRouteChanges?: boolean;
};

// connect wraps containers/connect.connect() to use our AppState and action creator. This is
// a convenience for component authors and can save a stack of typing.
export function connect<
  TStoreProps,
  TDispatchProps extends Partial<(ActionCreators & TActionCreatorsAdditional)>,
  TOwnProps,
  TStoreAdditional = {},
  TActionCreatorsAdditional = {},
>(
  mapStateToProps: (state: AppState & TStoreAdditional, ownProps?: TOwnProps) => TStoreProps,
  mapDispatchToProps?: MapDispatchToProps<TDispatchProps, TOwnProps>,
  mergeProps?: null, // we don't use this option now, but want to keep the connect API standard
  options?: Options<AppState, TStoreProps, TOwnProps, {}> & ExtraConnectOptions,
): (
  child: ((props: TStoreProps & TDispatchProps & TOwnProps) => React.ReactNode) |
    React.ComponentClass<TStoreProps & TDispatchProps & TOwnProps>)
      => React.ComponentType<TOwnProps> {

  if (mergeProps !== null && mergeProps !== undefined) {
    throw new Error('unexpected value for unused mergeProps parameter');
  }

  // XXX: pure is true by default, but that breaks react-router (see
  // https://reacttraining.com/react-router/web/guides/redux-integration). Our first
  // solution was to force this default to be false, but the performance regression is
  // unacceptable. The current solution is to set 'pure: false' in specific components in
  // the tree (App and Routes at the time of writing).
  const defaultOptions = { pure: true };

  const inner = innerConnect(
    mapStateToProps,
    mapDispatchToProps as any,
    null, // Unused parameter
    { ...defaultOptions, ...options },
  ) as any;

  if (!options || !options.respondToRouteChanges) {
    return inner;
  } else {
    return (child: any): any => {
      return withRouter(inner(child as any) as any) as any;
    };
  }
}
