import { call } from 'redux-saga/effects'

import { getTokens } from 'domains/account/auth-session'

import { Codec, RemoteObject } from 'generated/webrpc'
import { UnaryRequest, UnaryResponse } from 'generated/mos/admin/webrpc'

import { bffEndpoint, StatusCode } from 'helpers/bff'
import { Invariant } from 'helpers/core'

import { ensureValidSession, ensureActiveJWT } from '.'

const API_ENDPOINT = `${bffEndpoint(window.location.hostname)}/call`

export const assemblePayload = (requestBodyObject: RemoteObject) => {
  // retrieve access token if available.
  const tokens = getTokens()

  // set any standard headers for grpc calls.
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (tokens && tokens.access) headers.append('Authorization', `bearer ${tokens.access}`)

  // encode the request body.
  const requestPayload = {
    ...UnaryRequest.defaults,
    body: requestBodyObject,
  }
  const encodedPayload = UnaryRequest.codec.encode(requestPayload)

  // assemble the payload.
  return {
    method: 'POST' as const,
    headers,
    body: JSON.stringify(encodedPayload)
  }
}

export class UnaryGRPCError extends Error {
  public name: string

  public constructor(message?: string) {
    super(message)
    this.name = new.target.prototype.constructor.name
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export function* unaryGRPC<TRequestEntity, TResponseEntity>(
  grpcPath: string,
  requestEntity: TRequestEntity,
  requestCodec: Codec<TRequestEntity>,
  responseCodec: Codec<TResponseEntity>,
) {
  // encode and assemble the payload.
  const encodedPayload = requestCodec.encode(requestEntity)
  let payload = assemblePayload(encodedPayload)

  // attempt to make the network request, or terminate on error.
  let result: Response
  try {
    result = yield call(fetch, `${API_ENDPOINT}/${grpcPath}`, payload)
  } catch (error) {
    throw new UnaryGRPCError(`[ UnaryGRPC ] There was a network error: ${error.message}.`)
  }

  // check for errors in the response.
  if (!result || !result.ok) {
    const data = yield call([result, 'json'])
    const response = UnaryResponse.codec.decode(data)
    if (!response) {
      throw new UnaryGRPCError('[ UnaryGRPC ] There was an error with receiving an expected response.')
    }
    // check for invalid access token.
    else if (response.code && response.code === StatusCode.PermissionDenied) {
      // refresh access token or queue request until a new token is obtained.
      yield call(ensureValidSession)

      // once ready, retry the network request a second and final time.
      try {
        payload = assemblePayload(encodedPayload)
        result = yield call(fetch, `${API_ENDPOINT}/${grpcPath}`, payload)
      } catch (error) {
        throw new UnaryGRPCError(`[ UnaryGRPC ] There was a network error: ${error.message}.`)
      }

      if (!result || !result.ok) {
        throw new UnaryGRPCError('[ UnaryGRPC ] There was an error with the response.')
      }

    }
    // TODO: check what the code is and provide a more meaningful error to the ui.
    else if ((response.code && response.code > 0) || response.errors) {
      throw new UnaryGRPCError('[ UnaryGRPC ] An error was received.')
    } else {
      throw new Invariant('[ UnaryGRPC ] Unhandled response.')
    }
  }

  // if we get this far the response was successful, now get data and check it matches generated
  //   types.
  const rawResponse: RemoteObject = yield call([result, 'json'])
  const data = {
    ...UnaryResponse.defaults,
    ...rawResponse,
  }
  const response = UnaryResponse.codec.decode(data)

  if (!response || !response.body) {
    throw new UnaryGRPCError('[ UnaryGRPC ] There was an error with receiving an expected response.')
  }
  // TODO: check what the code is and provide a more meaningful error to the ui.
  else if ((response.code && response.code > 0) || response.errors) {
    throw new UnaryGRPCError('[ UnaryGRPC ] An error was received.')
  }

  // check for validation failures, terminate on error or return the validated payload to the
  //   parent generator.
  const validated: TResponseEntity = responseCodec.decode(response.body)
  if (!validated) {
    throw new UnaryGRPCError('[ UnaryGRPC ] Response payload shape validation failed.')
  }

  return validated
}
