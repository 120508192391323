import React, { Component, ReactNode } from 'react'

import { Button, ButtonGroup } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { createFormBag, FormBag, FormData } from 'react-formage'
import { IconWarning } from 'components/ui/icons'
import { space } from 'helpers/style'
import styled from 'styled'

import { Modal } from './modal'

type AlertProps = {
  message: string;
  onClose: () => void;
};

const Alert = ({ message, onClose }: AlertProps) => (
  <Modal
    onClose={onClose}
    size="small"
    footer={(
      <ButtonGroup>
        <Button onClick={onClose}>Ok</Button>
      </ButtonGroup>
    )}
  >
    <p>{message}</p>
  </Modal>
);

type ConfirmProps = {
  title?: string;
  message?: JSX.Element | string;
  danger?: boolean;
  confirmLabel?: string;
  closeLabel?: string;
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

type ConfirmState = {
  // FIXME this component really shouldn't maintain it's own state
  isDisabled: boolean;
};

class Confirm extends Component<ConfirmProps, ConfirmState> {
  public constructor(props: ConfirmProps) {
    super(props);
    this.state = {
      isDisabled: false,
    };
  }

  public render() {
    const { title, message, danger, onClose, onConfirm, confirmLabel='Ok', closeLabel='Cancel' } = this.props;
    return (
      <Modal
        onClose={onClose}
        size="small"
        header={title ? <h3>{title}</h3> : null}
        footer={(
          <ButtonGroup>
            <Button appearance="secondary" onClick={(e) => onClose(e)}>
              {closeLabel}
            </Button>
            <Button
              isDisabled={this.state.isDisabled}
              onClick={(e) => {
                this.setState({ isDisabled: true });
                onConfirm(e);
              }}
              appearance= {(danger ? 'negative' : 'primary')}
            >
              {confirmLabel}
            </Button>
          </ButtonGroup>
        )}
      >
        <div>{message}</div>
      </Modal>
    );
  }
}

const WarningLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${space(-6)} 0 ${space(8)} 0;
  color: ${({ theme }) => theme.color.darkText};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

type ConfirmDeleteProps = {
  title: string;
  message: string;
  confirmLabel?: string;
  closeLabel?: string;
  checklist: string[];
  onConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

type ConfirmDeleteState = {
  readonly formBag: FormBag<FormValues>;
}

type FormValues = Record<string, boolean>;

class ConfirmDelete extends Component<ConfirmDeleteProps, ConfirmDeleteState> {
  public constructor(props: ConfirmDeleteProps) {
    super(props)
    const initialFormValues = this.props.checklist.reduce((state: FormValues, warning) => {
      state[warning] = false;
      return state;
    }, {})

    const formBag: FormBag<FormValues> = createFormBag(initialFormValues)
    this.state = {
      formBag,
    }
  }

  private setFormBag = (event: any) => {
    this.setState({
      formBag: event.bag,
    })
  }

  public render() {
    const {
      title,
      message,
      onClose,
      onConfirm,
      confirmLabel='Yes, delete this',
      closeLabel='No thanks, cancel',
    } = this.props;
    const { formBag } = this.state
    const { values } = formBag

    let hasCompletedChecklist = false
    Object.keys(values).forEach(function (item) {
      if (!values[item]) {
        hasCompletedChecklist = true
      }
    })

    const header = (
      <>
        <h3>{title}</h3>
        <p style={{ margin: `${space(4)} ${space(14)}` }}>{message}</p>
      </>
    )

    const footer = (
      <ButtonGroup>
        <Button appearance="secondary" onClick={onClose}>{closeLabel}</Button>
        <Button onClick={onConfirm} isDisabled={hasCompletedChecklist}>
          {confirmLabel}
        </Button>
      </ButtonGroup>
    )

    return (
      <Modal onClose={onClose} header={header} footer={footer} size="small">
        <WarningLabel>
          <IconWarning size="base" color="error" />
          <span style={{ paddingLeft: 8 }}>Warning: This cannot be undone.</span>
        </WarningLabel>

        <FormData
          bag={formBag}
          onUpdate={this.setFormBag}
        >
          {this.props.checklist.map((warning) => (
            <Checkbox<FormValues, typeof warning>
              key={warning}
              label={warning}
              field={warning}
              isDestructive={true}
            />
          ))}
        </FormData>
      </Modal>
    );
  }
}

export { Modal, Alert, Confirm, ConfirmDelete };
