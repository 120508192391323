import { Beacon } from 'generated/mos/beacon'
import { CreateBeaconRequest, UpdateBeaconRequest } from 'generated/mos/beaconmanagement'
import { assertNever, Invariant } from 'helpers/core';
import { Status, statusSelector, ErrorMessage } from 'helpers/status';

import * as profiles from './profiles';
import { BeaconFilterState, BeaconsList, BeaconsAppState, initialBeaconsAppState } from './store';

// exported for sagas, not to be bound to connected components.
export const actionCreators = {
  beaconsListBeaconsRequest: () => ({
    domain: 'beacons' as const,
    type: 'beaconsListBeaconsRequest' as const,
  }),
  beaconsListBeaconsSuccess: (beacons: BeaconsList) => ({
    domain: 'beacons' as const,
    type: 'beaconsListBeaconsSuccess' as const,
    beacons,
  }),
  beaconsListBeaconsFailure: (message: ErrorMessage) => ({
    domain: 'beacons' as const,
    type: 'beaconsListBeaconsFailure' as const,
    message,
  }),

  beaconsGetBeaconRequest: (beaconRef: Beacon.Ref) => ({
    domain: 'beacons' as const,
    type: 'beaconsGetBeaconRequest' as const,
    beaconRef,
  }),
  beaconsGetBeaconSuccess: (beacon: Beacon.Entity) => ({
    domain: 'beacons' as const,
    type: 'beaconsGetBeaconSuccess' as const,
    beacon,
  }),
  beaconsGetBeaconFailure: (beaconRef: Beacon.Ref, message: ErrorMessage) => ({
    domain: 'beacons' as const,
    type: 'beaconsGetBeaconFailure' as const,
    beaconRef,
    message,
  }),

  beaconsCreateBeaconRequest: (payload: CreateBeaconRequest.Entity) => ({
    domain: 'beacons' as const,
    type: 'beaconsCreateBeaconRequest' as const,
    payload,
  }),
  beaconsCreateBeaconSuccess: (args: { ref: Beacon.Ref; profileType: profiles.ProfileType }) => ({
    domain: 'beacons' as const,
    type: 'beaconsCreateBeaconSuccess' as const,
    ...args,
  }),
  beaconsCreateBeaconFailure: (message: ErrorMessage) => ({
    domain: 'beacons' as const,
    type: 'beaconsCreateBeaconFailure' as const,
    message,
  }),

  beaconsUpdateBeaconRequest: (payload: UpdateBeaconRequest.Entity) => ({
    domain: 'beacons' as const,
    type: 'beaconsUpdateBeaconRequest' as const,
    payload,
  }),
  beaconsUpdateBeaconSuccess: (beacon: Beacon.Entity) => ({
    domain: 'beacons' as const,
    type: 'beaconsUpdateBeaconSuccess' as const,
    beacon,
  }),
  beaconsUpdateBeaconFailure: (beaconRef: Beacon.Ref, message: ErrorMessage) => ({
    domain: 'beacons' as const,
    type: 'beaconsUpdateBeaconFailure' as const,
    beaconRef,
    message,
  }),

  beaconsDeleteBeaconRequest: (beaconRef: Beacon.Ref) => ({
    domain: 'beacons' as const,
    type: 'beaconsDeleteBeaconRequest' as const,
    beaconRef,
  }),
  beaconsDeleteBeaconSuccess: (beaconRef: Beacon.Ref) => ({
    domain: 'beacons' as const,
    type: 'beaconsDeleteBeaconSuccess' as const,
    beaconRef,
  }),
  beaconsDeleteBeaconFailure: (message: ErrorMessage) => ({
    domain: 'beacons' as const,
    type: 'beaconsDeleteBeaconFailure' as const,
    message,
  }),

  beaconFiltersUpdate: (updates: Partial<BeaconFilterState>) => ({
    domain: 'beacons' as const,
    type: 'beaconFiltersUpdate' as const,
    updates,
  }),
};

export type BeaconsActionCreators = typeof actionCreators;
export type BeaconsAction = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;
export type BeaconsActions = {
  [T in keyof BeaconsActionCreators]: ReturnType<BeaconsActionCreators[T]>;
};

const assertBeacons = (domain: 'beacons') => {
  return domain === 'beacons'
}

export const beaconsReducer = (state: BeaconsAppState = initialBeaconsAppState, action: BeaconsAction): BeaconsAppState => {
  if (!assertBeacons(action.domain)) {
    return state
  }

  switch (action.type) {
    // {{{ beacon access sagas.
    case 'beaconsListBeaconsRequest': {
      return {
        ...state,
        beacons: {
          status: Status.Loading,
        },
        beaconCreate: {
          status: Status.Idle,
        },
        beaconEdit: {
          status: Status.Idle,
        },
        beaconDelete: {
          status: Status.Idle,
        },
      }
    }
    case 'beaconsListBeaconsSuccess': {
      return {
        ...state,
        beacons: {
          data: action.beacons,
          errors: [],
          status: Status.Ready,
        },
      };
    }
    case 'beaconsListBeaconsFailure': {
      return {
        ...state,
        beacons: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      }
    }

    case 'beaconsGetBeaconRequest': {
      return {
        ...state,
        beaconEdit: {
          ref: action.beaconRef,
          status: Status.Loading,
        },
      };
    }
    case 'beaconsGetBeaconSuccess': {
      return {
        ...state,
        beaconEdit: {
          data: action.beacon,
          errors: [],
          ref: Beacon.mustRef(action.beacon.ref),
          status: Status.Ready,
        },
      };
    }
    case 'beaconsGetBeaconFailure': {
      return {
        ...state,
        beaconEdit: {
          messages: [ action.message ],
          ref: action.beaconRef,
          status: Status.Failed,
        }
      }
    }
    // }}} beacon access sagas.

    // {{{ beacon management sagas.
    case 'beaconsCreateBeaconRequest': {
      return {
        ...state,
        beaconCreate: {
          status: Status.Loading,
        }
      }
    }
    case 'beaconsCreateBeaconSuccess': {
      return {
        ...state,
        beacons: statusSelector.invalidate(state.beacons),
        beaconCreate: {
          status: Status.Ready,
          data: action.ref,
        },
        beaconLastProfileType: action.profileType,
      };
    }
    case 'beaconsCreateBeaconFailure': {
      return {
        ...state,
        beaconCreate: {
          messages: [ action.message ],
          status: Status.Failed,
        }
      }
    }

    case 'beaconsUpdateBeaconRequest': {
      if (state.beaconEdit.status !== Status.Ready) {
        throw new Invariant(`beaconEdit tried to edit ${action.payload.beaconRef!.id} while ${state.beaconEdit.status}`);
      }
      return {
        ...state,
        beaconEdit: {
          ref: Beacon.mustRef(action.payload.beaconRef),
          status: Status.Loading,
        },
      };
    }
    case 'beaconsUpdateBeaconSuccess': {
      return {
        ...state,
        beacons: statusSelector.invalidate(state.beacons),
        beaconEdit: {
          data: action.beacon,
          ref: Beacon.mustRef(action.beacon.ref),
          status: Status.Ready,
        },
      };
    }
    case 'beaconsUpdateBeaconFailure': {
      return {
        ...state,
        beaconEdit: {
          messages: [ action.message ],
          ref: action.beaconRef,
          status: Status.Failed,
        },
      }
    }

    case 'beaconsDeleteBeaconRequest': {
      return {
        ...state,
        beaconDelete: {
          status: Status.Loading,
        },
      };
    }
    case 'beaconsDeleteBeaconSuccess': {
      return {
        ...state,
        beaconDelete: {
          data: undefined,
          status: Status.Ready,
        },
      };
    }
    case 'beaconsDeleteBeaconFailure': {
      return {
        ...state,
        beaconDelete: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      };
    }
    // }}} beacon management sagas.

    case 'beaconFiltersUpdate': {
      return { ...state, beaconFilters: { ...state.beaconFilters, ...action.updates } };
    }

    default:
      assertNever(action);
      throw new Error();
  }
};
