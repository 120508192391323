import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu';
import { AttributeBlock } from 'components/ui/attribute-block';
import { PrimaryNavigationEditPosition } from 'components/ui/primary-navigation/edit-position'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { Mapbox, MapInitialBounds, MapView } from 'components/mapbox';
import { EditableBoundaryMapSet } from 'components/mapsets/editable-polygon';
import { RefCopy } from 'components/ui/ref-copy';
import { AddAttributeButton } from 'components/ui/add-attribute-button';
import { Alert, ConfirmDelete } from 'components/ui/modal';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { Building } from 'generated/mos/structure';
import { pick, shallowEqual } from 'helpers/core';
import * as geo from 'helpers/geo';
import { Status, statusSelector } from 'helpers/status';
import { space } from 'helpers/style'
import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresActionCreators } from 'domains/structures/actions';
import { BoundarySet } from 'domains/structures/mapsets';
import { StructuresAppState } from 'domains/structures/store';

import styled from 'styled';


const SectionHeader = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  margin-top: ${space(9)};
  margin-bottom: ${space(9)};
  font-size: 20px;
`;

const Section = styled.div`
  padding: 0 ${space(6)};
`;

const LastButtonRow = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${space(6)};
`;

const NoBoundaryLabel = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayL80};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL80};
  padding: ${space(3)} ${space(4)};
`;

type DirectProps = {
  readonly buildingId: string;
  readonly onComplete: () => void;
};

type ConnectedProps = Pick<StructuresAppState, 'buildingEdit' | 'structures' | 'globalMap'>;

type ActionProps =
  Pick<typeof sharedActionCreators, 'toastNotification'> &
  Pick<typeof structuresActionCreators,
    'structuresLoadRequest' | 'structuresFiltersUpdate' |
    'buildingEditSaveRequest' | 'buildingEditLoadRequest' | 'buildingEditReset' | 'buildingEditDeleteRequest'>;

type Props = ConnectedProps & DirectProps & ActionProps;

type EditMode = undefined | 'boundary';

type State = {
  readonly buildingEditReady?: boolean;
  readonly showDismissableErrorModal: boolean;
  readonly showConfirmDeleteModal?: boolean;
  readonly showBoundaryAlert?: boolean;
  readonly editMode: EditMode;
  readonly errorModalMessage: string;

  readonly boundary?: geo.Polygon2D;
  readonly boundaryPrevious?: geo.Polygon2D;

  // The globalMap's view is only used for a default if the beacon is
  // unpositioned.
  readonly mapView?: MapView;

  // Replace this with a new '{}' to force mapbox to update to the initial bounds:
  readonly mapBoundsIdentity?: object;
  readonly mapBounds?: geo.BBox2D;

  readonly bag: FormBag<FormValues>;
  readonly initialFormValues: FormValues;

};

type FormValues = {
  readonly name: string;
};

class BuildingEditPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const initialFormValues = { name: '' }
    this.state = {
      mapView: props.globalMap.view,
      bag: createFormBag(initialFormValues),
      initialFormValues,
      errorModalMessage: '',
      showDismissableErrorModal: false,
      editMode: undefined,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    if (state.buildingEditReady ||
      !statusSelector.hasData(props.buildingEdit) ||
      !statusSelector.hasData(props.structures)) {

      return null;
    }

    const building = props.buildingEdit.data;
    if (!building) {
      throw new Error();
    }

    let mapView: MapView = props.globalMap.view;
    if (building.boundary) {
      mapView = {
        ...mapView,
        center: geo.centroid2D({
          type: 'Polygon',
          coordinates: building.boundary.coordinates as any, // FIXME: geo-jank
        }),
      };
    }

    const initialFormValues = { name: building.name }

    const newState: Partial<State> = {
      buildingEditReady: true,
      boundary: geo.optionalPolygon2D(building.boundary),
      bag: createFormBag(initialFormValues),
      mapView,
      initialFormValues,
    };

    return newState
  }

  private get ref(): Building.Ref {
    return { typename: 'mos.structure.Building', id: this.props.buildingId };
  }

  private get name() { return this.state.bag.values.name; }

  private mapBounds(): MapInitialBounds | undefined {
    if (!this.state.mapBoundsIdentity || !this.state.mapBounds) {
      return;
    }
    return {
      padding: 120, // FIXME put in context
      identity: this.state.mapBoundsIdentity,
      bounds: this.state.mapBounds,
    };
  }

  public componentDidMount() {
    if (this.props.buildingEdit.status === Status.Idle) {
      this.props.buildingEditLoadRequest(this.ref);
    }
  }

  public componentWillUnmount() {
    this.props.buildingEditReset();
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public componentDidUpdate() {
    if (this.props.buildingEdit.status === Status.Complete) {
      this.props.toastNotification({ type: 'success', text: this.props.buildingEdit.message });
      this.props.onComplete();
      return;
    }
    this.refresh();
  }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    return errors;
  }

  private save = () => {
    if (this.props.buildingEdit.status !== Status.Ready) return;
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.structuresFiltersUpdate({ lastEdited: this.ref });
    this.props.buildingEditSaveRequest(this.props.buildingEdit.ref, {
      name: this.name,
      boundary: this.state.boundary,
    });
  }

  private onConfirmDelete() {
    if (this.props.buildingEdit.status !== Status.Ready) {
      return;
    }
    this.props.buildingEditDeleteRequest(this.props.buildingEdit.ref);
  }


  // {{{ Boundary handlers

  private onBoundaryShow = () => {
    if (this.state.boundary) {
      this.setState({ mapBoundsIdentity: {}, mapBounds: geo.bbox2D(this.state.boundary) });
    }
  }

  private onBoundaryAdd = () => {
    if (this.state.boundary) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: `'${this.name}' already has a boundary.` });
    } else {
      this.setState({ editMode: 'boundary' });
    }
  }

  private onBoundaryEdit = () => this.setState({ editMode: 'boundary', boundaryPrevious: this.state.boundary });
  private onBoundaryCancel = () => this.setState({ editMode: undefined, boundary: this.state.boundaryPrevious });
  private onBoundaryPosCommit = () => this.setState({ editMode: undefined });
  private onBoundaryRemove = () => this.setState({ boundary: undefined });

  private onBoundaryUpdate = (e: { polygon: geo.Polygon2D | undefined }) => {
    this.setState({ boundary: e.polygon });
  }

  // }}}


  private onCancelEdit = () => {
    this.props.onComplete();
  }

  private isModified = (): boolean => {
    if (!statusSelector.hasData(this.props.buildingEdit) ||
      !statusSelector.hasData(this.props.structures)) {
      return false;
    }

    // check if form has changed
    if (!shallowEqual(this.state.bag.values, this.state.initialFormValues)) {
      return true;
    }

    // check if boundary has changed
    const buildingEdit = this.props.buildingEdit.data;
    const initialBoundary = buildingEdit.boundary;

    if (!geo.equal(initialBoundary, this.state.boundary)) {
      return true;
    }

    return false;
  }

  public render() {
    if (this.props.buildingEdit.status === Status.Failed) {
      // FIXME: el-cheapo error display
      return this.props.buildingEdit.messages.map((v) => v.text).join(', ');
    }
    if (this.props.buildingEdit.status === Status.Complete) {
      return <div />;
    }

    const isDisabled = this.props.buildingEdit.status === Status.Updating;
    const { errors, touched } = this.state.bag;

    const modified = this.isModified()

    const sidebar = () => (
      <>
        <div>
          <Section>
            <SectionHeader>Details</SectionHeader>
            <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
              <Input
                label="Name"
                field="name"
                error={touched.name ? errors.name : undefined}
              />
            </FormData>
          </Section>

          <Section style={{ paddingBottom: 60 }}>
            <SectionHeader>Attributes</SectionHeader>
            <AddAttributeButton title="Boundary" onClick={this.onBoundaryAdd} />
            {!this.state.boundary
              ? <NoBoundaryLabel>No boundary set</NoBoundaryLabel>
              : <AttributeBlock title="Building boundary">
                <PopOverMenu>
                  <PopOverMenuButton onClick={this.onBoundaryEdit}>Edit boundary</PopOverMenuButton>
                  <PopOverMenuButton onClick={this.onBoundaryShow}>Centre on map</PopOverMenuButton>
                </PopOverMenu>
              </AttributeBlock>
            }
          </Section>
        </div>

        <LastButtonRow>
          <Button
            isDisabled={isDisabled}
            onClick={() => this.setState({ showConfirmDeleteModal: true })}
          >
            Delete building
          </Button>
          <RefCopy modelRef={this.ref} />
        </LastButtonRow>
      </>
    );

    const nav = () => this.state.editMode ? (
      <PrimaryNavigationEditPosition
        title="Edit building boundary"
        kind="boundary"
        onCommit={this.onBoundaryPosCommit}
        onCancel={this.onBoundaryCancel}
        onDelete={this.onBoundaryRemove}
      />
    ) : (
        <PrimaryNavigationEditEntity
          title="Edit Building"
          onSave={this.save}
          disabled={!modified}
          onCancel={this.onCancelEdit}
          onConfirm={modified ? this.onCancelEdit : undefined}
          navBackRoute={!modified ? '/locate/structures' : undefined}
        />
      );

    let boundaryEditSet: EditableBoundaryMapSet | undefined;
    let boundarySet: BoundarySet | undefined;

    if (this.state.editMode === 'boundary') {
      boundaryEditSet = new EditableBoundaryMapSet({
        key: 'activeStructure',
        boundary: this.state.boundary,
        onUpdated: this.onBoundaryUpdate,
      });

    } else if (this.state.boundary) {
      boundarySet = new BoundarySet({ key: 'structhover', boundaries: [this.state.boundary] });
    }

    const mapSets = [boundarySet, boundaryEditSet];

    return (
      <>
        {!this.state.showConfirmDeleteModal ? null :
          <ConfirmDelete
            title={`Delete '${this.name}'?`}
            message="Deleting a building will in turn delete all associated levels, spaces, beacons, and properties"
            checklist={['All levels and level properties will be deleted', 'All beacons and spaces will be unpositioned', 'This cannot be undone.']}
            confirmLabel="Yes, delete this building"
            onConfirm={() => this.onConfirmDelete()}
            onClose={() => this.setState({ showConfirmDeleteModal: false })}
          />
        }

        {!this.state.showBoundaryAlert ? null :
          <Alert
            onClose={() => this.setState({ showBoundaryAlert: false })}
            message="You can only add one boundary. Please remove or edit the existing boundary."
          />
        }

        {!this.state.showDismissableErrorModal ? null :
          <Alert
            onClose={() => this.setState({ showDismissableErrorModal: false })}
            message={this.state.errorModalMessage} />
        }

        <LayoutLocateEditPage
          nav={nav}
          sidebar={sidebar}
          content={() => (
            <React.Fragment>
              <Mapbox
                view={this.state.mapView!}
                onViewChanged={(e) => this.setState({ mapView: e })}
                initialBounds={this.mapBounds()}
                mapSets={mapSets}
              />
            </React.Fragment>
          )}
        />
      </>
    );
  }
}

export const BuildingEditPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.structures, 'buildingEdit', 'globalMap', 'structures'),
  {
    ...pick(
      structuresActionCreators,
      'structuresLoadRequest', 'buildingEditLoadRequest', 'buildingEditReset', 'buildingEditDeleteRequest',
      'buildingEditSaveRequest', 'structuresFiltersUpdate',
    ),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(BuildingEditPageView);
