import { assertNever } from 'helpers/core';
import { RefUrn } from 'entity';
import { Site, Building, OutdoorLevel, BuildingLevel, AdminMap } from 'generated/mos/structure';
import * as geo from 'helpers/geo';

export type LevelRef = BuildingLevel.Ref | OutdoorLevel.Ref;
export type StructureRef = LevelRef | Building.Ref | Site.Ref;

export function isLevelRef(ref?: { typename: string; id: string }): ref is LevelRef {
  return BuildingLevel.isRef(ref) || OutdoorLevel.isRef(ref);
}

export function isStructureRef(ref?: { typename: string; id: string }): ref is StructureRef {
  return isLevelRef(ref) || Site.isRef(ref) || Building.isRef(ref);
}

export function structurePreposition(ref: StructureRef): string {
  switch (ref.typename) {
  case 'mos.structure.Building': return 'in';
  case 'mos.structure.BuildingLevel': return 'on';
  case 'mos.structure.OutdoorLevel': return 'on';
  case 'mos.structure.Site': return 'at';
  default:
    assertNever(ref);
    return "";
  }
}

export type LevelEntity = BuildingLevel.Entity | OutdoorLevel.Entity;
export type StructureEntity = Site.Entity | Building.Entity | LevelEntity;

export function mustLevelRef(ref: { typename: string; id: string } | undefined): LevelRef {
  if (!isLevelRef(ref)) {
    throw new Error(`unknown level ref type ${ref ? ref.typename : 'undefined'}`);
  }
  return ref;
}

export function mustStructureRef(ref: { typename: string; id: string } | undefined): StructureRef {
  if (!isStructureRef(ref)) {
    throw new Error(`unknown structure ref type ${ref ? ref.typename : 'undefined'}`);
  }
  return ref;
}

export function buildAdminMapCorners(
  adminMap: AdminMap.Entity,
): geo.Corners | undefined {
  const adminMapCorners = [
    adminMap.topLeftGeoReference ? adminMap.topLeftGeoReference.coordinates : undefined,
    adminMap.topRightGeoReference ? adminMap.topRightGeoReference.coordinates : undefined,
    adminMap.bottomRightGeoReference ? adminMap.bottomRightGeoReference.coordinates : undefined,
    adminMap.bottomLeftGeoReference ? adminMap.bottomLeftGeoReference.coordinates : undefined,
  ];
  return geo.isCorners(adminMapCorners) ? adminMapCorners : undefined;
}

export type SpatialOptions = {
  // TypeScript can't do much for us here with the index type: they've decided to
  // do nothing to help this need. 'key' in the context of this object is either
  // an 'entity.RefUrn', or, as a standin for the also-unsupported 'undefined',
  // an empty string to indicate the root node.
  readonly children: Readonly<{[key: string]: ReadonlyArray<SpatialOption>}>;

  // As with 'children', 'key' is an entity.RefUrn. There is no need to support
  // undefined here, so the key MUST always be a valid entity.RefUrn:
  readonly index: Readonly<{[key: string]: SpatialOption}>;

  readonly leaves: ReadonlyArray<SpatialOption>;
};

export type SpatialOption = {
  readonly ref: StructureRef;
  readonly refUrn: RefUrn;
  readonly parents: SpatialOption[];
  readonly name: string;
  readonly fullName: string;
};

export type Structures = {
  readonly sites: ReadonlyArray<Site.Entity>;
  readonly buildings: ReadonlyArray<Building.Entity>;
  readonly buildingLevels: ReadonlyArray<BuildingLevel.Entity>;
  readonly outdoorLevels: ReadonlyArray<OutdoorLevel.Entity>;
  readonly adminMaps: ReadonlyArray<AdminMap.Entity>;

  readonly spatialOptions: SpatialOptions;
  readonly index: Readonly<{[refUrn: string]: StructureEntity}>;
  readonly parentRef: Readonly<{[refUrn: string]: StructureRef}>;
  readonly childRefs: Readonly<{[refUrn: string]: ReadonlyArray<StructureRef>}>;
};

export const spatialOptionSort = (a: SpatialOption, b: SpatialOption) =>
  a.fullName
    .toLowerCase()
    .localeCompare(b.fullName.toLowerCase(), undefined, {numeric: true});

export class SpatialOptionIndex {
  private children = new Map<RefUrn, SpatialOption[]>();
  private index = new Map<RefUrn, SpatialOption>();

  public add(option: SpatialOption, parent: SpatialOption | undefined) {
    const parentUrn = parent ? parent.refUrn : '';
    if (!this.children.has(parentUrn)) {
      this.children.set(parentUrn, []);
    }
    this.children.get(parentUrn)!.push(option);
    this.index.set(option.refUrn, option);
  }

  public seal() {
    // FIXME: key should be entity.RefUrn but typescript doesn't support
    // aliasing strings as object keys yet.
    const children: {[key: string]: ReadonlyArray<SpatialOption>} = {};
    for (const [key, value] of this.children) {
      value.sort(spatialOptionSort);
      children[key] = value;
    }

    const index: {[key: string]: SpatialOption} = {};
    for (const [key, value] of this.index) {
      index[key] = value;
    }

    return {index: index, children: children};
  }
}

