import { NavigationControl } from 'mapbox-gl';

import { Addable, AddableManager, MapSet } from 'components/mapbox';


type InternalOptions = (typeof NavigationControl extends new (v?: infer U) => any ? U : never);

type NavigationControlOptions = { readonly key?: string } & InternalOptions;


export class NavigationControlSet implements MapSet {
  public readonly key: string;

  private opts: InternalOptions;

  public constructor(opts?: NavigationControlOptions) {
    if (!opts) {
      opts = {};
    }
    const { key, ...rest } = opts;
    this.opts = rest;
    this.key = key || 'navigation';
  }

  public addables(): ReadonlyArray<Addable> {
    return [this];
  }

  public add(map: AddableManager) {
    map.addControl(new NavigationControl(this.opts));
  }
}
