// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Beacon, IdentificationProfile } from "generated/mos/beacon";
import { PlacedPoint } from "generated/mos/geo";
import { Codec, DecodeContext, FieldMask, RemoteObject, WebRPCError, ensureScalar } from "generated/webrpc";

export namespace CreateBeaconRequest {
  export const refName = "mos.beacon_management.CreateBeaconRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly identificationProfile: IdentificationProfile.Entity | undefined,
    // If the beacon is an "unpositioned" beacon, this should be a zero-length
    // value. See mos.beacon.Beacon for more details.
    readonly position: PlacedPoint.Entity | undefined,
    readonly snapToPosition: boolean,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    identificationProfile: undefined,
    position: undefined,
    snapToPosition: false,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["identificationProfile"] = IdentificationProfile.codec.encode(v.identificationProfile);
      obj["position"] = PlacedPoint.codec.encode(v.position);
      obj["snapToPosition"] = v.snapToPosition;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.CreateBeaconRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["identificationProfile"] = ctx.decode(IdentificationProfile.codec, v["identificationProfile"], "identificationProfile");
      out["position"] = ctx.decode(PlacedPoint.codec, v["position"], "position");
      out["snapToPosition"] = ensureScalar(ctx, v["snapToPosition"], "boolean");
      return out as any as Entity;
    }
  }();
}

export namespace CreateBeaconResponse {
  export const refName = "mos.beacon_management.CreateBeaconResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beacon: Beacon.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    beacon: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["beacon"] = Beacon.codec.encode(v.beacon);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.CreateBeaconResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beacon"] = ctx.decode(Beacon.codec, v["beacon"], "beacon");
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBeaconRequest {
  export const refName = "mos.beacon_management.DeleteBeaconRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beaconRef: { readonly typename: 'mos.beacon.Beacon', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    beaconRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.DeleteBeaconRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beaconRef"] = v["beaconRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBeaconResponse {
  export const refName = "mos.beacon_management.DeleteBeaconResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.beacon.Beacon', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.DeleteBeaconResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBeaconRequest {
  export const refName = "mos.beacon_management.UpdateBeaconRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beaconRef: { readonly typename: 'mos.beacon.Beacon', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"beaconRef" | "name" | "identificationProfile" | "position" | "snapToPosition">,
    readonly name: string,
    readonly identificationProfile: IdentificationProfile.Entity | undefined,
    readonly position: PlacedPoint.Entity | undefined,
    readonly snapToPosition: boolean,
  }
  export const defaults: Entity = {
    type: refName,
    beaconRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    name: "",
    identificationProfile: undefined,
    position: undefined,
    snapToPosition: false,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["beaconRef"] = v.beaconRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["name"] = v.name;
      obj["identificationProfile"] = IdentificationProfile.codec.encode(v.identificationProfile);
      obj["position"] = PlacedPoint.codec.encode(v.position);
      obj["snapToPosition"] = v.snapToPosition;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.UpdateBeaconRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beaconRef"] = v["beaconRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "beaconRef" && fmv !== "name" && fmv !== "identificationProfile" && fmv !== "position" && fmv !== "snapToPosition") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["identificationProfile"] = ctx.decode(IdentificationProfile.codec, v["identificationProfile"], "identificationProfile");
      out["position"] = ctx.decode(PlacedPoint.codec, v["position"], "position");
      out["snapToPosition"] = ensureScalar(ctx, v["snapToPosition"], "boolean");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBeaconResponse {
  export const refName = "mos.beacon_management.UpdateBeaconResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beacon: Beacon.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    beacon: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["beacon"] = Beacon.codec.encode(v.beacon);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_management.UpdateBeaconResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beacon"] = ctx.decode(Beacon.codec, v["beacon"], "beacon");
      return out as any as Entity;
    }
  }();
}

