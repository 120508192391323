import React, { useState, useEffect, useRef } from 'react'

import { Button } from 'components/ui/button'
import { IconWarning, IconSuccess, IconAddItem, IconPlus } from 'components/ui/icons'
import { Description, Title, listItemBaseStyles } from 'components/ui/list-item-label'
import { sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'
import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore } from 'domains/org/wam/store'
import { pick } from 'helpers/core'
import { statusSelector, Status } from 'helpers/status'
import { space } from 'helpers/style'

import { DataStatus } from '../../store'
import { BulkUpload } from '../ui/bulk-upload'
import { UploadResponse, UploadedFileStatus } from '../../types'

import styled from 'styled'

const List = styled.ul`
  padding: 0;
  margin-bottom: ${space(8)};
`

const ListItem = styled.li`
  ${listItemBaseStyles}
`

const Message = styled.span`
  color: ${({ theme }) => theme.color.warning};
  flex-direction: row;
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-top: 1px;
    margin-right: ${space(2)};
  }
`

const Heading = styled.strong`
  flex-direction: row;
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: ${space(2)};
  }
`

type ConnectedProps = {
  readonly upload: DataStatus<any>;
}

type ActionProps =
  Pick<typeof actionCreators, 'wamUploadRequest' | 'wamUploadReset'> &
  Pick<typeof sharedActionCreators, 'toastNotification'>;

type DirectProps = {
  onSuccessHandler: () => void
}

type Props = ConnectedProps & ActionProps & DirectProps;

type State = {
  readonly isOpen: boolean;
}

const hasErrors = (data: UploadResponse) => {
  return !!data.filter((item: UploadedFileStatus) => !item[1].matched).length
}

const uploadSummary = (data: UploadResponse) => {
  if (!data) return null
  const success = data.filter((item: UploadedFileStatus) => item[1].matched)
  const failed = data.filter((item: UploadedFileStatus) => !item[1].matched)
  return <>
    {!!failed.length && <>
      <Heading><IconWarning size='medium' color="warning" />The following items were NOT uploaded and linked</Heading>
      <List>{
        failed.map(file => {
          const [filename, { message }] = file
          return <ListItem>
            <Description>
              <Title>{filename}</Title>
              <Message>
                <IconWarning size='small' color="warning" />
                <span>{message}</span>
              </Message>
            </Description>
          </ListItem>
        })
      }</List>
    </>}
    {!!success.length && <>
      <Heading><IconSuccess size='medium' color="success" /> The following items were succesfully uploaded and linked</Heading>
      <List>{
        success.map(file => {
          const [filename] = file
          return <ListItem>
            <Description>
              <Title>{filename}</Title>
            </Description>
          </ListItem>
        })
      }</List>
    </>}
  </>
}

const UploadAssetsComponent = ({ upload, wamUploadRequest, wamUploadReset, toastNotification, onSuccessHandler }: Props) => {

  const [isOpen, setIsOpen] = useState<State['isOpen']>(false)
  const hasSubmitted = useRef<boolean>(false)

  const data = statusSelector.data(upload)

  useEffect(() => {
    if (!isOpen && upload.status === Status.Ready && !hasErrors(data)) {
      wamUploadReset()
    }

    // refresh the view data on upload success to show the updated data
    if (upload.status === Status.Ready && hasSubmitted.current){
      onSuccessHandler()
      hasSubmitted.current = false
    }
  }, [upload.status])

  const onDrop = (files: ReadonlyArray<File>) => {
    wamUploadRequest(files)
    hasSubmitted.current = true
  }

  const onClose = () => {
    if (upload.status === Status.Ready) wamUploadReset()
    setIsOpen(false)
  }
  const onOpen = () => {
    setIsOpen(true)
  }

  return (
    <>
      <Button
        variant = "subtle"
        appearance={data && hasErrors(data) ? "negative" : "secondary"}
        onClick={onOpen}
      >
        {upload.status === Status.Failed ? <IconWarning size='small' /> : <IconPlus size='small' />}
        {(upload.status === Status.Idle ||  upload.status === Status.Ready) && 'Upload Assets'}
        {upload.status === Status.Loading && 'Uploading...'}
        {upload.status === Status.Failed && 'Failed upload'}
      </Button>
      {isOpen && (<>
        <BulkUpload
          status={upload}
          onClose={onClose}
          onDrop={onDrop}
          renderComplete={uploadSummary(data)}
        />
      </>)
      }
    </>
  )
}

export const UploadAssets = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam, 'upload'),
  }),
  {
    ...pick(actionCreators, 'wamUploadRequest', 'wamUploadReset'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(UploadAssetsComponent)
