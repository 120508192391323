const capTo8Bit = (num: number) => {
  num = Math.round(num);
  if (num > 255) {
    return 255;
  } else if (num < 0) {
    return 0;
  }
  return num;
};

const hexBrightness = (color: string, percent: number, darken: boolean = false) => {
  let usePound = false;
  if (color[0] === '#') {
      color = color.slice(1);
      usePound = true;
  }

  if (color.length !== 6) { throw new Error('input must be of the form `#76747a` or `76747a`'); }
  if (percent < 0 || percent > 100) { throw new Error ('percent must be between 0 and 100'); }

  const darkenFn = (color: number) => capTo8Bit(color * (100 - percent) / 100);

  const lightenFn = (color: number) => {
    color = 255 - color;
    color = color * (100 - percent) / 100;
    color = 255 - color;
    return capTo8Bit(color);
  };

  const colorHex = parseInt(color, 16);
  const initialRgb = [ (colorHex >> 16), (colorHex & 0x0000FF), ((colorHex >> 8) & 0x00FF) ];
  const rgb = initialRgb.map(darken ? darkenFn : lightenFn);

  const finalHexString = (rgb[1] | (rgb[2] << 8) | (rgb[0] << 16)).toString(16);

  return (usePound ? '#' : '') + finalHexString.padStart(6, '0')
};

export const lightenHex = (color: string, percent: number) => hexBrightness(color, percent);

export const darkenHex = (color: string, percent: number) => hexBrightness(color, percent, true);

export const SPACING_MULTIPLIER = 4
/**
 * This utility is for use on margins and padding, but shouldn't necessarily use this to form
 * height and width values.
 */
export const space = (num: number = 1, suffix: boolean = true): string | number => {
  if (num % 1 !== 0) {
    throw new Error('An integer must be passed.')
  }
  return suffix ? `${num * SPACING_MULTIPLIER}px` : num * SPACING_MULTIPLIER
}
