import React from 'react'
import ReactS3Uploader from 'react-s3-uploader';

import { connect, store } from 'containers/store';
import { pick } from 'helpers/core';
import { space } from 'helpers/style'
import styled from 'styled'

import { accountBindableActionCreators } from 'domains/account/actions';
import { AuthSession } from 'services'

const UploadButtonWrapper = styled.div<{ htmlId: string }>`
  input#${({ htmlId }) => htmlId} {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label[for="${({ htmlId }) => htmlId}"] {
    display: inline-flex;
    align-items: center;
    height: 42px;
    justify-content: center;
    padding: 0 ${space(4)};

    appearance: none;
    background: ${({ theme }) => theme.color.primary};
    border-radius: 100px;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-decoration: none;
    transition: ${({ theme }) => theme.transition.base};

  }
`;

type ConnectedProps = {
  readonly session: AuthSession | undefined;
  readonly bffEndpoint: string;
};

type DirectProps = {
  readonly onComplete: (result: SignResult) => void;
  readonly disabled?: boolean;
  readonly htmlId: string;
  readonly label?: string;
};

type ActionProps = Pick<typeof accountBindableActionCreators, 'ensureSession'>;

type Props = ActionProps & ConnectedProps & DirectProps;

export type SignResult = {
  readonly name: string;
  readonly signedUrl: string;
  readonly uuid: string;
};

const S3UploaderView = (props: Props) => {
  return (
    <UploadButtonWrapper htmlId={props.htmlId}>
      <ReactS3Uploader<SignResult>
        signingUrl={`${props.bffEndpoint}/sign-s3-upload`}
        signingUrlMethod="GET"

        // XXX: We need to make sure the access token is valid, as otherwise we
        // will just blithely use the old, possibly expired, access token, at
        // the worst possible time - right after a user tries to upload the file!
        //
        // Unfortunately, we couldn't find a way to do this using the original version of
        // ReactS3Uploader and our async ensureSession() action, so we had to fork it. Their
        // issue tracker makes it clear that trying to upstream fixes would be a waste of
        // time, so we have vendored our fork and tamed the ancient dependencies a bit.
        //
        signingUrlHeaders={async () => {
          // FIXME FIXME FIXME AWOOGAH AWOOGAH NO IDEA IF THIS WORKS FIXME FIXME FIXME: redux-cleanup
          await props.ensureSession();
          const session = store.getState().account.session;
          const auth = !session ? {} : { ['Authorization']: `Bearer ${session.access}` };
          return auth;
        }}

        disabled={props.disabled}
        onError={(error) => console.log(error)}
        onFinish={props.onComplete}
        signingUrlWithCredentials={false} // in case when need to pass authentication credentials via CORS
        contentDisposition="auto"
        scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
        htmlId={props.htmlId}
        label={props.label || 'Browse...'}
      />
    </UploadButtonWrapper>
  );
};

export const S3Uploader = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    bffEndpoint: store.shared.bffEndpoint,
    session: store.account.session,
  }),
  pick(accountBindableActionCreators, 'ensureSession'),
)(S3UploaderView);
