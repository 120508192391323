import React from 'react'
import styled from 'styled'

import { PrimaryNavigation } from 'components/ui/primary-navigation'
import { SectionHeader } from 'components/ui/section-header'
import { space } from 'helpers/style'
import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { ProjectCard } from './project-card'

const ProjectCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${space(-3)};
  margin-right: ${space(-3)};
`

export const ProjectsPage = () => {
  const [modalVisible, toggleModalVisible] = React.useState(false)
  return (
    <LayoutListOrFormPage
      nav={() => <PrimaryNavigation />}
      title="Projects"
      content={() => (
        <>
          <SectionHeader heading="1 project" />
          <ProjectCardGrid>
            <ProjectCard projectTitle="WAM" />
          </ProjectCardGrid>
        </>
      )}
    />
  )
}
