import { ReleaseControl } from 'generated/mos/releasecontrols'
import { DataStatus as GenericDataStatus, EntityStatus as GenericEntityStatus, Status } from 'helpers/status'

type ReleaseControlErrorCode = string

export type DataStatus<T> = GenericDataStatus<T, ReleaseControlErrorCode>
export type EntityStatus<T extends object> = GenericEntityStatus<T, ReleaseControlErrorCode, string>

export type ReleaseControlsList = {
  [key: string]: ReleaseControl.Entity;
}

export type ReleaseControlsAppState = {
  readonly releaseControlsList: DataStatus<ReleaseControlsList>;
}

export const initialReleaseControlsAppState: ReleaseControlsAppState = {
  releaseControlsList: { status: Status.Idle },
}

export type ReleaseControlsStore = {
  readonly releaseControls: ReleaseControlsAppState;
}
