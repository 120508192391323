// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";

export namespace GetLinkedEntityMappingsRequest {
  export const refName = "mos.linked_field_mappings.GetLinkedEntityMappingsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly sourceTypename: string,
    readonly relationshipName: string,
  }
  export const defaults: Entity = {
    type: refName,
    sourceTypename: "",
    relationshipName: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedEntityMappingsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["sourceTypename"] = ensureScalar(ctx, v["sourceTypename"], "string");
      out["relationshipName"] = ensureScalar(ctx, v["relationshipName"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace GetLinkedEntityMappingsResponse {
  export const refName = "mos.linked_field_mappings.GetLinkedEntityMappingsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly mappings: ReadonlyArray<GetLinkedEntityMappingsResponse.Mapping.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    mappings: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedEntityMappingsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["mappings"] = decodeMessageRepeated(ctx, GetLinkedEntityMappingsResponse.Mapping.codec, v["mappings"], "mappings");
      return out as any as Entity;
    }
  }();
  export namespace Mapping {
    export const refName = "mos.linked_field_mappings.GetLinkedEntityMappingsResponse.Mapping" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly sourceTypename: string,
      readonly relationshipTypename: string,
      readonly targetTypename: string,
      readonly targetTypeDisplayName: string,
      readonly createNewMessageTitle: string,
      readonly createNewMessageSubtitle: string,
    }
    export const defaults: Entity = {
      type: refName,
      sourceTypename: "",
      relationshipTypename: "",
      targetTypename: "",
      targetTypeDisplayName: "",
      createNewMessageTitle: "",
      createNewMessageSubtitle: "",
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedEntityMappingsResponse.Mapping");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["sourceTypename"] = ensureScalar(ctx, v["sourceTypename"], "string");
        out["relationshipTypename"] = ensureScalar(ctx, v["relationshipTypename"], "string");
        out["targetTypename"] = ensureScalar(ctx, v["targetTypename"], "string");
        out["targetTypeDisplayName"] = ensureScalar(ctx, v["targetTypeDisplayName"], "string");
        out["createNewMessageTitle"] = ensureScalar(ctx, v["createNewMessageTitle"], "string");
        out["createNewMessageSubtitle"] = ensureScalar(ctx, v["createNewMessageSubtitle"], "string");
        return out as any as Entity;
      }
    }();
  }

}

export namespace GetLinkedFieldMappingsRequest {
  export const refName = "mos.linked_field_mappings.GetLinkedFieldMappingsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly sourceTypename: string,
    readonly targetTypename: string,
    readonly relationshipName: string,
    readonly sourceAttributeNames: ReadonlyArray<string>,
  }
  export const defaults: Entity = {
    type: refName,
    sourceTypename: "",
    targetTypename: "",
    relationshipName: "",
    sourceAttributeNames: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedFieldMappingsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["sourceTypename"] = ensureScalar(ctx, v["sourceTypename"], "string");
      out["targetTypename"] = ensureScalar(ctx, v["targetTypename"], "string");
      out["relationshipName"] = ensureScalar(ctx, v["relationshipName"], "string");
      out["sourceAttributeNames"] = ensureScalarRepeated(ctx, v["sourceAttributeNames"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace GetLinkedFieldMappingsResponse {
  export const refName = "mos.linked_field_mappings.GetLinkedFieldMappingsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    // A Mapping will be returned for each source attribute name in
    // <GetLinkedFieldMappingsRequest.source_attribute_names>, describing the
    // target_attribute_names that that field can link to.
    readonly mappings: ReadonlyArray<GetLinkedFieldMappingsResponse.Mapping.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    mappings: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedFieldMappingsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["mappings"] = decodeMessageRepeated(ctx, GetLinkedFieldMappingsResponse.Mapping.codec, v["mappings"], "mappings");
      return out as any as Entity;
    }
  }();
  export namespace Mapping {
    export const refName = "mos.linked_field_mappings.GetLinkedFieldMappingsResponse.Mapping" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly sourceTypename: string,
      readonly targetTypename: string,
      readonly relationshipName: string,
      readonly sourceAttributeName: string,
      readonly targetAttributeNames: ReadonlyArray<string>,
    }
    export const defaults: Entity = {
      type: refName,
      sourceTypename: "",
      targetTypename: "",
      relationshipName: "",
      sourceAttributeName: "",
      targetAttributeNames: [],
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.linked_field_mappings.GetLinkedFieldMappingsResponse.Mapping");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["sourceTypename"] = ensureScalar(ctx, v["sourceTypename"], "string");
        out["targetTypename"] = ensureScalar(ctx, v["targetTypename"], "string");
        out["relationshipName"] = ensureScalar(ctx, v["relationshipName"], "string");
        out["sourceAttributeName"] = ensureScalar(ctx, v["sourceAttributeName"], "string");
        out["targetAttributeNames"] = ensureScalarRepeated(ctx, v["targetAttributeNames"], "string");
        return out as any as Entity;
      }
    }();
  }

}

