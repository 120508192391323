import React, { useEffect } from 'react'
import { History } from 'history';

import { Link } from 'components/ui/link';
import { IconError, IconInfo, IconSuccess, IconWarning, IconType } from 'components/ui/icons';
import { FOOTER_HEIGHT } from 'components/ui/footer'

import { sharedActionCreators, SharedActionCreators, ToastNotification } from 'containers/shared';
import { connect } from 'containers/store';

import { assertNever, pick } from 'helpers/core';
import { space } from 'helpers/style';

import styled from 'styled';
import { ThemeInterface } from 'styled/theme';

const Wrapper = styled.div<{ theme: ThemeInterface; offset?: boolean }>`
  z-index: 9999999999999;
  position: fixed;
  bottom: ${({ offset }) => offset ? FOOTER_HEIGHT : 0 }px;
  right: 0;
  padding: ${space(4)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const Message = styled.div<{ theme: ThemeInterface }>`
  margin:${space(2)};
  padding: ${space(2)} ${space(4)};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.grayL70};
  display: flex;
  align-items: flex-start;
  padding: ${space(4)} ${space(4)};
  min-height: 56px;
  min-width: 320px;
  max-width: 570px;
`;

const Text = styled.p<{ theme: ThemeInterface }>`
  padding-left: ${space(2)};
  padding-right: ${space(2)};
  align-self: center;
  font-size: ${({ theme }) => theme.font.size.small};
  margin: 0;
  color: ${({ theme }) => theme.color.grayD70};
  pointer-events: none;
`

const Description = styled.span`
  padding-left: ${space(2)};
  color: ${({ theme }) => theme.color.gray};
`

const OptionalLink = styled(Link)`
  margin-left: auto;
`

type DirectProps = {
  offset? : boolean;
};

type ConnectedProps = {
  readonly toastNotifications: ReadonlyArray<ToastNotification>;
};

type ActionProps = Pick<SharedActionCreators, 'toastNotificationRemove'>;

type Props = ActionProps & DirectProps & ConnectedProps;

export type ToastNotificationType = 'success' | 'error' | 'warning' | 'info';

export type Notification = {
  /**
   * The type of toast notification to display.
  */
  readonly type: ToastNotificationType;
  /**
   * The text message to display with the toast notification.
  */
  readonly text: string;
  /**
   * Optional description text to add after text message.
  */
  readonly description?: string;
  /**
   * Optional link to display after text message.
   *
   * Note: The Description prop must be defined in order for the link to display as it is the textual content of the link.
  */
  readonly link?: History.LocationDescriptor<any>;
}

export type NotificationProps = {
  /**
   * Optional time to display toast notification for in milliseconds.
  */
  readonly durationMs?: number;
  /**
  * Optional function for use of notification removal.
  */
  readonly removeHandler?: () => void;
} & Notification

// Icon utils.
const getIcon = (type: ToastNotificationType): IconType | undefined => {
  switch (type) {
    case 'success': return IconSuccess;
    case 'error': return IconError;
    case 'warning': return IconWarning;
    case 'info': return IconInfo;
    default:
      assertNever(type);
  }
}

const getIconColor = (type: ToastNotificationType) => {
  switch (type) {
    case 'success': return 'success';
    case 'error': return 'error';
    case 'warning': return 'warning';
    case 'info': return 'info';
    default:
      assertNever(type);
  }
}

// This component is only exported for use with storybook.
export const Notification = (props: NotificationProps) => {
  const { type, text, description, link, removeHandler } = props
  const Icon = getIcon(type);
  useEffect(() => {
    if(removeHandler) removeHandler()
  }, [removeHandler])
  return (
    <Message>
      {Icon && <Icon size="base" color={getIconColor(type)} />}
      <Text>
        {text}
        {!link && description && <Description>{description}</Description>}
      </Text>
      {link && description && <OptionalLink to={link} >{description}</OptionalLink>}
    </Message>
  )
}

class ToastNotificationsView extends React.Component<Props> {
  public componentWillUnmount() { this.clearAll(); }

  private clearAll() {
    const { toastNotificationRemove, toastNotifications } = this.props;
    toastNotifications.forEach(() => {
      toastNotificationRemove();
    })
  }

  public render() {
    const { toastNotificationRemove, toastNotifications, offset } = this.props;

    if (!toastNotifications || !toastNotifications.length) {
      return null;
    }
    const now = Date.now();
    const removeHandler = (expiryTime: number) => () => {
      setTimeout(() => {
        toastNotificationRemove();
      }, expiryTime - Date.now())
    }
    return (
      <Wrapper offset={offset}>
        {toastNotifications
          .filter((m) => m.expiryTime > now)
          .map((message, i) => <Notification key={`tn-${i}`} {...message} removeHandler={removeHandler(message.expiryTime)} />)
        }
      </Wrapper>
    );
  }
}

export const ToastNotifications = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({ toastNotifications: store.shared.toastNotifications }),
  pick(sharedActionCreators, 'toastNotificationRemove'),
)(ToastNotificationsView);
