import React, { useEffect, Fragment, useState } from 'react'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { FieldSection } from 'components/ui/fieldset'
import { IconMonitor, IconPhone, IconWarning, IconAngle } from 'components/ui/icons'
import { Description, Title, Subtitle, listItemBaseStyles } from 'components/ui/list-item-label'
import { usePagination, Paginator } from 'components/pagination'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { StatusGuard } from 'components/ui/status-guard'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { pick } from 'helpers/core'
import { statusSelector, Status } from 'helpers/status'
import { space } from 'helpers/style'
import { timestampToFormattedString } from 'helpers/timestamps'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { Bundle } from '../../../types'

import { ThemeColor } from 'styled/theme'
import styled from 'styled'


const ReleaseTitle = styled.h2`
  margin-top: 0;
  color: ${({ theme }) => theme.color.grayD80};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.base};
  line-height: 1.6;
  display: flex;
  flex-direction: row;
`
const ReleaseId = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin-left: auto;
`

const IconStyles = `
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  margin-right: ${space(4)}
`

const IconContainer = styled.span`
  ${IconStyles}
  flex-direction: column;
  margin-left: ${space(2)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
  padding-top: ${space(2)};
  *:first-child {
    margin-bottom: ${space(1)};
  }
`
const Releases = styled.ol`
  padding: 0;
`
const Bundles = styled.ul`
  padding: 0;
`
const ListItemLabel = styled.li<{ disabled?: boolean }>`
  ${listItemBaseStyles}
  height: 80px;
  pointer-events: none;
`

const ProcessingStatus = styled.div`
  display: flex;
  width: 110px;
  justify-items: center;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.6;
  margin-left: auto;
  margin-right: ${space(2)};
`

const Square = styled.span<{ color: ThemeColor }>`
  width: 14px;
  height: 14px;
  background-color: ${({ theme, color }) => theme.color[color]};
  border-radius: 4px;
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.color.grayL90};
  margin-right: ${space(2)}
`;

const SummaryButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${space(2)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
  & > * {
    margin-right: ${space(2)};
  }
  &:focus {
    outline: none;
  }
`

const SummaryText = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin-bottom: ${space(4)};
`

const SummaryListItem = styled.li`
  margin-bottom: ${space(1)};
`

type ActionProps =
  Pick<WamActionCreators, 'wamListReleasesRequest' | 'wamListReleasesReset' | 'wamReleaseSummaryRequest' | 'wamReleaseSummaryReset'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['publishing'], 'listReleases' | 'summaries'>

type DirectProps = {
  projectId: string;
  historyPush: (id: string) => void;
}

type Props = ActionProps & ConnectedProps & DirectProps;

const ReleasesPageView = (props: Props) => {
  const {
    projectId,
    listReleases,
    summaries,
    wamListReleasesRequest,
    wamListReleasesReset,
    wamReleaseSummaryRequest,
    wamReleaseSummaryReset,
  } = props
  const pagination = usePagination({ pageSize: 10, pageIndex: 0 });
  const data = statusSelector.data(listReleases)

  useEffect(() => {
    wamReleaseSummaryReset()
    wamListReleasesReset()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (listReleases.status === Status.Ready) {
      data && pagination.setTotalItems(data.count)
    }
  }, [listReleases])

  useEffect(() => {
    wamListReleasesRequest({ page: pagination.pageIndex + 1 })
  }, [pagination.pageIndex])


  const getStatus = (processingStatus: string) => {
    const text = processingStatus === 'RUNNING' ? 'In progress' : processingStatus
    const color = processingStatus === 'RUNNING' ? 'warning' : processingStatus === 'FAILED' ? 'error' : 'success'
    return <ProcessingStatus>
      <Square color={color} />
      <span>{text}</span>
    </ProcessingStatus>
  }

  const listItem = ({ digitalLabel, audience, deviceType, processingStatus, modifiedDate }: Bundle, id: string) => (
    <ListItemLabel key={`${id}-${modifiedDate}`}>
      <IconContainer>
        {deviceType === 'DIGITAL_LABEL' ? <IconMonitor /> : <IconPhone />}
        {audience === 'PUBLIC' ? 'Public' : 'Preview'}
      </IconContainer>
      <Description>
        <Title>{deviceType === 'DIGITAL_LABEL' ? digitalLabel.title : 'Mobile'}</Title>
        {digitalLabel && digitalLabel.ecmsId && <Subtitle>ID: {digitalLabel.ecmsId}</Subtitle>}
      </Description>
      {getStatus(processingStatus)}
    </ListItemLabel>
  )

  const summaryFormat = (summary: string) => {
    return <ul>
      {summary.split("\n").map((item, i) => {
        return <SummaryListItem key={i}>{item}</SummaryListItem>
      })}
    </ul>
  }

  const Summary = ({ id }: { id: string }) => {
    const [openSummary, setOpenSummary] = useState<boolean>(!!(summaries[id] && summaries[id].status === Status.Ready))
    const getSummary = () => {
      if (!summaries[id] || summaries[id].status !== Status.Ready) wamReleaseSummaryRequest({ id })
      setOpenSummary(!openSummary)
    }
    const summaryData = summaries[id] && statusSelector.data(summaries[id])
    return <div>
      <SummaryButton onClick={getSummary}><IconAngle rotate={openSummary ? 180 : 0} /> <IconWarning color='warning' size="small" /> There were issues related to this release.</SummaryButton>
      {openSummary ? <SummaryText>{summaryData && summaryFormat(summaryData.summary)}</SummaryText> : null}
    </div>
  }

  return (
    <LayoutListOrFormPage
      nav={() => (
        <PrimaryNavigationEditEntity
          title="Publish Project"
          navBackRoute={`/project/${projectId}/publishing`}
          darkTheme
        />
      )}
      title={'WAM Publish History'}
      subnav={() => (
        <SecondaryNavigation
          navItems={[]}
        />
      )}
      content={() => (
        <StatusGuard status={listReleases}>
          <Releases>
            {data && data.results.map(({ id, createdDate, bundles, hasSummary }) => (
              <Fragment key={id}>
                <ReleaseTitle>{timestampToFormattedString(createdDate)} <ReleaseId>{`Release ID: ${id}`}</ReleaseId></ReleaseTitle>
                {hasSummary && <Summary id={id} />}
                <Bundles>{bundles.map((bundle) => listItem(bundle, id))}</Bundles>
                <FieldSection />
              </Fragment>
            ))}
          </Releases>
          <Paginator {...pagination} />
        </StatusGuard >
      )}
      sidebar={() => null}
    />
  )
}

export const ReleasesPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators>(
    (store) => pick(store.wam.publishing, 'listReleases', 'summaries'),
    {
      ...pick(actionCreators, 'wamListReleasesRequest', 'wamListReleasesReset', 'wamReleaseSummaryRequest', 'wamReleaseSummaryReset'),
      ...pick(sharedActionCreators, 'toastNotification'),
    }
  )(ReleasesPageView)
