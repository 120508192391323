import { assertNever, Invariant } from 'helpers/core';
import { Status, statusSelector } from 'helpers/status';

import {Site} from 'generated/mos/structure';
import { actionCreators as buildingActionCreators } from './building';
import { actionCreators as levelActionCreators } from './level';
import { actionCreators as siteActionCreators } from './site';
import {
  actionCreators as innerStructuresActionCreators,
  buildStructuresData,
} from './structures';

import { initialStructuresAppState, StructuresAppState } from '../store';

export const structuresActionCreators = {
  ...buildingActionCreators,
  ...levelActionCreators,
  ...siteActionCreators,
  ...innerStructuresActionCreators,
};

export type ActionCreators = typeof structuresActionCreators;
export type StructuresAction = ReturnType<ActionCreators[keyof ActionCreators]>;
export type StructuresActions = {
  [T in keyof ActionCreators]: ReturnType<ActionCreators[T]>;
};

const assertStructures = (domain: 'structures') => {
  return domain === 'structures'
}

export const structuresReducer = (state: StructuresAppState = initialStructuresAppState, action: StructuresAction): StructuresAppState => {
  if (!assertStructures(action.domain)) {
    return state
  }

  switch (action.type) {
  case 'buildingCreateReset':
    return { ...state, buildingCreate: { status: Status.Idle }};
  case 'buildingEditReset':
    return { ...state, buildingEdit: { status: Status.Idle }};

  case 'buildingCreateSaveRequest':
    return { ...state, buildingCreate: { status: Status.Loading }};
  case 'buildingCreateSaveFailure':
    return { ...state, buildingCreate: { status: Status.Failed, messages: [action.message] }};
  case 'buildingCreateSaveSuccess':
    return { ...state, buildingCreate: { status: Status.Ready, data: action.ref }};

  case 'buildingEditLoadRequest':
    return { ...state, buildingEdit: { status: Status.Loading, ref: action.ref }};
  case 'buildingEditLoadFailure':
    return { ...state, buildingEdit: { status: Status.Failed, messages: [action.message], ref: action.ref }};
  case 'buildingEditLoadSuccess':
    return { ...state, buildingEdit: {
      status: Status.Ready,
      ref: action.ref,
      data: action.entity,
      errors: [],
    }};

  case 'buildingEditSaveRequest':
    if (state.buildingEdit.status !== Status.Ready) {
      throw new Invariant(`expected status to be ${Status.Ready}, was ${state.buildingEdit.status}`);
    }
    return { ...state, buildingEdit: {
      status: Status.Updating,
      ref: action.ref,
      data: state.buildingEdit.data,
    }};
  case 'buildingEditSaveFailure':
    return { ...state, buildingEdit: { status: Status.Failed, messages: [action.message], ref: action.ref }};
  case 'buildingEditSaveSuccess':
    return { ...state, buildingEdit: {
      status: Status.Complete,
      ref: action.ref,
      message: 'Building saved',
    }};

  case 'buildingEditFailure':
    return { ...state, buildingEdit: { status: Status.Failed, ref: action.ref, messages: [{ code: "", text: action.message}] }}

  case 'buildingEditDeleteRequest':
    if (state.buildingEdit.status !== Status.Ready)  {
      throw new Invariant("reducer state invalid"); // FIXME: 3.7, use core.ts 'assert'
    }
    return {
      ...state,
      buildingEdit: { ...state.buildingEdit, ref: action.ref, status: Status.Updating },
    };
  case 'buildingEditDeleteFailure':
    return {
      ...state,
      buildingEdit: { status: Status.Failed, ref: action.ref, messages: [action.message] },
    };
  case 'buildingEditDeleteSuccess':
    return {
      ...state,
      buildingEdit: { status: Status.Complete, ref: action.ref, message: 'Building deleted.' },
    };

  case 'buildingLevelCreateReset':
    return { ...state, buildingLevelCreate: { status: Status.Idle }};
  case 'buildingLevelCreateSaveRequest':
    return { ...state, buildingLevelCreate: { status: Status.Loading }};
  case 'buildingLevelCreateSaveFailure':
    return { ...state, buildingLevelCreate: { status: Status.Failed, messages: [action.message] }};
  case 'buildingLevelCreateSaveSuccess':
    return { ...state, buildingLevelCreate: { status: Status.Ready, data: action.ref }};

  case 'buildingLevelEditLoadRequest':
    return { ...state, buildingLevelEdit: { status: Status.Loading, ref: action.ref }};
  case 'buildingLevelEditLoadFailure':
    return { ...state, buildingLevelEdit: { status: Status.Failed, ref: action.ref, messages: [action.message] }};
  case 'buildingLevelEditLoadSuccess':
    return { ...state, buildingLevelEdit: {
      status: Status.Ready,
      ref: action.ref,
      data: { entity: action.entity!, adminMapEntity: action.adminMapEntity },
      errors: [],
    }};

  case 'buildingLevelEditSaveRequest':
    if (state.buildingLevelEdit.status !== Status.Ready)  {
      throw new Invariant("reducer state invalid"); // FIXME: 3.7, use core.ts 'assert'
    }
    return { ...state, buildingLevelEdit: {
      status: Status.Updating,
      data: state.buildingLevelEdit.data,
      ref: action.ref,
    }};
  case 'buildingLevelEditSaveFailure':
    return { ...state, buildingLevelEdit: { status: Status.Failed, ref: action.ref, messages: [action.message] }};
  case 'buildingLevelEditSaveSuccess':
    return { ...state, buildingLevelEdit: { status: Status.Complete, ref: action.ref, message: 'Building level saved' }};

  case 'buildingLevelEditReset':
    return { ...state, buildingLevelEdit: { status: Status.Idle }};

  // TODO(dc): refactor away improper Update action
  case 'buildingLevelEditUpdate':
    return { ...state, buildingLevelEdit: action.value };
  case 'buildingLevelEditFailure':
    return { ...state, buildingLevelEdit: { status: Status.Failed, ref: action.ref, messages: [{ code: "", text: action.message}] }}

  case 'buildingLevelEditDeleteRequest':
    if (state.buildingLevelEdit.status !== Status.Ready)  {
      throw new Invariant("reducer state invalid"); // FIXME: 3.7, use core.ts 'assert'
    }
    return { ...state, buildingLevelEdit: { ...state.buildingLevelEdit, status: Status.Updating }};

  case 'siteCreateReset':
      return { ...state, siteCreate: { status: Status.Idle }};
  case 'siteCreateSaveRequest':
      return { ...state, siteCreate: { status: Status.Loading }};
  case 'siteCreateSaveFailure':
      return { ...state, siteCreate: { status: Status.Failed, messages: [action.message] }};
  case 'siteCreateSaveSuccess':
      return { ...state, siteCreate: { status: Status.Ready, data: action.ref }};

  case 'siteEditReset':
      return { ...state, siteEdit: { status: Status.Idle }};
  case 'siteEditLoadRequest':
      return { ...state, siteEdit: { status: Status.Loading, ref: action.ref }};
  case 'siteEditLoadFailure':
      return { ...state, siteEdit: { status: Status.Failed, ref: action.ref, messages: [action.message] }};
  case 'siteEditLoadSuccess':
      return { ...state, siteEdit: {
        status: Status.Ready,
        ref: Site.mustRef(action.entity.ref),
        data: { entity: action.entity, adminMapEntity: action.adminMapEntity },
        errors: [],
      }};

  case 'siteEditSaveRequest':
      if (state.siteEdit.status !== Status.Ready) {
        throw new Invariant(`expected status to be Ready, was ${state.siteEdit.status}`);
      }
      return { ...state, siteEdit: { status: Status.Updating, ref: action.ref, data: state.siteEdit.data }};
  case 'siteEditSaveFailure':
      return { ...state, siteEdit: { status: Status.Failed, ref: action.ref, messages: [action.message] }};
  case 'siteEditSaveSuccess':
      return { ...state, siteEdit: {
        status: Status.Complete,
        ref: Site.mustRef(action.entity.ref),
        message: 'Site saved.',
      }};

  case 'siteEditDeleteRequest':
    if (state.siteEdit.status !== Status.Ready)  {
      throw new Invariant("reducer state invalid"); // FIXME: 3.7, use core.ts 'assert'
    }
    return { ...state, siteEdit: { ...state.siteEdit, ref: action.ref, status: Status.Updating }};
  case 'siteEditDeleteFailure':
    return { ...state, siteEdit: { ref: action.ref, status: Status.Failed, messages: [action.message] }};
  case 'siteEditDeleteSuccess':
    return { ...state, siteEdit: { ref: action.ref, status: Status.Complete, message: 'Site deleted.' }};

  case 'structuresFiltersUpdate':
    return { ...state, structureFilters: { ...state.structureFilters, ...action.updates } };
  case 'structuresInvalidate':
    return { ...state, structures: statusSelector.invalidate(state.structures) };

  case 'globalMapUpdate':
    return { ...state, globalMap: { ...state.globalMap, ...action.mapState } };

  case 'structuresLoadRequest':
    return { ...state, structures: { status: Status.Loading }};
  case 'structuresLoadFailure':
    return { ...state, structures: { status: Status.Failed, messages: [ action.message] }};
  case 'structuresLoadSuccess':
    return { ...state, structures: {
      status: Status.Ready,
      data: buildStructuresData(action.payload),
    }};

  default:
    assertNever(action);
    throw new Error();
  }
};
