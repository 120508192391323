/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconHash = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M16.693 3.944l1.98.278-.461 3.278H21v2h-3.069l-.843 6H21v2h-4.193l-.5 3.556-1.98-.278.46-3.278h-5.98l-.5 3.556-1.98-.278.46-3.278H4v-2h3.068l.843-6H4v-2h4.192l.5-3.556 1.981.278-.461 3.278h5.98l.5-3.556zM15.91 9.5h-5.98l-.843 6h5.98l.843-6z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconHashString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M16.693 3.944l1.98.278-.461 3.278H21v2h-3.069l-.843 6H21v2h-4.193l-.5 3.556-1.98-.278.46-3.278h-5.98l-.5 3.556-1.98-.278.46-3.278H4v-2h3.068l.843-6H4v-2h4.192l.5-3.556 1.981.278-.461 3.278h5.98l.5-3.556zM15.91 9.5h-5.98l-.843 6h5.98l.843-6z"  fillRule="nonzero" />
  </svg>`

IconHash.displayName = 'IconHash'
