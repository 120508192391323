// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Errors } from "generated/mosx/errors";
import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureScalar, stripTypePropertyDeep } from "generated/webrpc";

export const HTTPStatusValues = new Set([
  'HTTP_UNSPECIFIED' as const,
  'HTTP_OK' as const,
  'HTTP_BAD_REQUEST' as const,
  'HTTP_UNAUTHORIZED' as const,
  'HTTP_FORBIDDEN' as const,
  'HTTP_NOT_FOUND' as const,
  'HTTP_CONFLICT' as const,
  'HTTP_TOO_MANY_REQUESTS' as const,
  'HTTP_CLIENT_CLOSED_REQUEST' as const,
  'HTTP_INTERNAL' as const,
  'HTTP_NOT_IMPLEMENTED' as const,
  'HTTP_SERVICE_UNAVAILABLE' as const,
  'HTTP_GATEWAY_TIMEOUT' as const,
]);
export type HTTPStatus = typeof HTTPStatusValues extends Set<infer U> ? U : never;

export namespace UnaryRequest {
  export const refName = "mos.admin.webrpc.UnaryRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly body: RemoteObject | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    body: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.admin.webrpc.UnaryRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["body"] = v["body"];
      return out as any as Entity;
    }
  }();
}

export namespace UnaryResponse {
  export const refName = "mos.admin.webrpc.UnaryResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    // GRPC status code: https://github.com/grpc/grpc/blob/master/doc/statuscodes.md
    // Will be 0 on success. In an HTTP context, the GRPC status code is mapped to a
    // HTTP status code using the mappings specified in HTTPStatus.
    readonly code: number,
    // GRPC status code name. Included for debugging purposes, do not use this directly
    // for anything other than printing. Not guaranteed to be present.
    readonly codeDebugName: string,
    readonly body: RemoteObject | undefined,
    readonly message: string,
    // XXX: Rich errors - supplements 'status'. See MOS RFC0003. The Errors
    // structure is even more experimental than this one!
    readonly errors: Errors.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    code: 0,
    codeDebugName: "",
    body: undefined,
    message: "",
    errors: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.admin.webrpc.UnaryResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["code"] = ensureScalar(ctx, v["code"], "number");
      out["codeDebugName"] = ensureScalar(ctx, v["codeDebugName"], "string");
      out["body"] = v["body"];
      out["message"] = ensureScalar(ctx, v["message"], "string");
      out["errors"] = ctx.decode(Errors.codec, v["errors"], "errors");
      return out as any as Entity;
    }
  }();
}

