// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Language } from "generated/mos/i18n";
import { LinkableBoolValue, LinkableDoubleValue, LinkableInt64Value, LinkableMediaValue, LinkableStringValue } from "generated/mos/linkable";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureBigInt, ensureScalar, stripTypePropertyDeep } from "generated/webrpc";
import jsbi from "jsbi";

export namespace TranslatableBoolValue {
  export const refName = "mos.translatable.TranslatableBoolValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableBoolValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableBoolValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableBoolValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableBoolValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly boolValue: boolean,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      boolValue: false,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        return stripTypePropertyDeep(v);
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableBoolValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["boolValue"] = ensureScalar(ctx, v["boolValue"], "boolean");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableDoubleValue {
  export const refName = "mos.translatable.TranslatableDoubleValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableDoubleValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableDoubleValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableDoubleValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableDoubleValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly doubleValue: number,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      doubleValue: 0,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        return stripTypePropertyDeep(v);
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableDoubleValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["doubleValue"] = ensureScalar(ctx, v["doubleValue"], "number");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableInt64Value {
  export const refName = "mos.translatable.TranslatableInt64Value" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableInt64Value.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableInt64Value.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableInt64Value");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableInt64Value.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableInt64Value.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly int64Value: jsbi,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      int64Value: jsbi.BigInt(0),
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["int64Value"] = v.int64Value.toString(10);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableInt64Value.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["int64Value"] = ensureBigInt(ctx, v["int64Value"]);
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableLinkableBoolValue {
  export const refName = "mos.translatable.TranslatableLinkableBoolValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableLinkableBoolValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableLinkableBoolValue.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableBoolValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableLinkableBoolValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableLinkableBoolValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly linkableBoolValue: LinkableBoolValue.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      linkableBoolValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["linkableBoolValue"] = LinkableBoolValue.codec.encode(v.linkableBoolValue);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableBoolValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["linkableBoolValue"] = ctx.decode(LinkableBoolValue.codec, v["linkableBoolValue"], "linkableBoolValue");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableLinkableDoubleValue {
  export const refName = "mos.translatable.TranslatableLinkableDoubleValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableLinkableDoubleValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableLinkableDoubleValue.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableDoubleValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableLinkableDoubleValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableLinkableDoubleValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly linkableDoubleValue: LinkableDoubleValue.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      linkableDoubleValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["linkableDoubleValue"] = LinkableDoubleValue.codec.encode(v.linkableDoubleValue);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableDoubleValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["linkableDoubleValue"] = ctx.decode(LinkableDoubleValue.codec, v["linkableDoubleValue"], "linkableDoubleValue");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableLinkableInt64Value {
  export const refName = "mos.translatable.TranslatableLinkableInt64Value" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableLinkableInt64Value.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableLinkableInt64Value.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableInt64Value");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableLinkableInt64Value.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableLinkableInt64Value.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly linkableInt64Value: LinkableInt64Value.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      linkableInt64Value: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["linkableInt64Value"] = LinkableInt64Value.codec.encode(v.linkableInt64Value);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableInt64Value.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["linkableInt64Value"] = ctx.decode(LinkableInt64Value.codec, v["linkableInt64Value"], "linkableInt64Value");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableLinkableMediaValue {
  export const refName = "mos.translatable.TranslatableLinkableMediaValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableLinkableMediaValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableLinkableMediaValue.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableMediaValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableLinkableMediaValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableLinkableMediaValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly linkableMediaValue: LinkableMediaValue.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      linkableMediaValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["linkableMediaValue"] = LinkableMediaValue.codec.encode(v.linkableMediaValue);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableMediaValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["linkableMediaValue"] = ctx.decode(LinkableMediaValue.codec, v["linkableMediaValue"], "linkableMediaValue");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableLinkableStringValue {
  export const refName = "mos.translatable.TranslatableLinkableStringValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableLinkableStringValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["translatedValues"] = [];
      for (const item of v.translatedValues) {
        obj["translatedValues"].push(TranslatableLinkableStringValue.TranslatedValue.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableStringValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableLinkableStringValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableLinkableStringValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly linkableStringValue: LinkableStringValue.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      linkableStringValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["language"] = Language.codec.encode(v.language);
        obj["mode"] = v.mode;
        obj["linkableStringValue"] = LinkableStringValue.codec.encode(v.linkableStringValue);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableLinkableStringValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["linkableStringValue"] = ctx.decode(LinkableStringValue.codec, v["linkableStringValue"], "linkableStringValue");
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableMediaValue {
  export const refName = "mos.translatable.TranslatableMediaValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableMediaValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableMediaValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableMediaValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableMediaValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly mediaRefValue: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      mediaRefValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        return stripTypePropertyDeep(v);
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableMediaValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["mediaRefValue"] = v["mediaRefValue"];
        return out as any as Entity;
      }
    }();
  }

}

export namespace TranslatableStringValue {
  export const refName = "mos.translatable.TranslatableStringValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly translatedValues: ReadonlyArray<TranslatableStringValue.TranslatedValue.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    translatedValues: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableStringValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["translatedValues"] = decodeMessageRepeated(ctx, TranslatableStringValue.TranslatedValue.codec, v["translatedValues"], "translatedValues");
      return out as any as Entity;
    }
  }();
  export namespace TranslatedValue {
    export const refName = "mos.translatable.TranslatableStringValue.TranslatedValue" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly language: Language.Entity | undefined,
      readonly mode: TranslatedValueMode,
      readonly stringValue: string,
    }
    export const defaults: Entity = {
      type: refName,
      language: undefined,
      mode: "TRANSLATED_VALUE_MODE_UNSPECIFIED",
      stringValue: "",
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        return stripTypePropertyDeep(v);
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.translatable.TranslatableStringValue.TranslatedValue");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["language"] = ctx.decode(Language.codec, v["language"], "language");
        {
          const ev = ensureScalar(ctx, v["mode"], "string") || defaults["mode"];
          if (!TranslatedValueModeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .mos.translatable.TranslatedValueMode`);
          }
          out["mode"] = ev as any;
        }
        out["stringValue"] = ensureScalar(ctx, v["stringValue"], "string");
        return out as any as Entity;
      }
    }();
  }

}

export const TranslatedValueModeValues = new Set([
  'TRANSLATED_VALUE_MODE_UNSPECIFIED' as const,
  'TRANSLATED_VALUE_MODE_NO_VALUE' as const,
  'TRANSLATED_VALUE_MODE_VALUE_SET' as const,
  'TRANSLATED_VALUE_MODE_FALLBACK' as const,
]);
export type TranslatedValueMode = typeof TranslatedValueModeValues extends Set<infer U> ? U : never;

