import { space } from 'helpers/style'
import styled from 'styled';

// these styles are exported only to support legacy forms in locate and should be avoided.

export const Label = styled.label`
  display: block;
  margin: 0 0 ${space(2)} 0;
  color: ${({ theme }) => theme.color.darkText};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: left;
`;

export const ErrorLabel = styled(Label)`
  margin: ${space(-3)} ${space(1)} ${space(4)};
  height: 18px;
  color: ${({ theme }) => theme.color.error};
`;
