import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'
import { toursActionCreators } from '../actions/tours'

import {
  ListGuidedToursResponse,
  GetGuidedTourResponse,
  UpdateGuidedTourRequest,
  UpdateGuidedTourResponse,
  DeleteTourDirectionsResponse,
  DeleteTourOutroResponse,
  UpdateTourDirectionsResponse,
  UpdateTourDirectionsRequest,
} from '../types'

const getGuidedToursSaga = wamTakeLeading('wamListGuidedToursRequest', function* (action) {
  try {
    const response: ListGuidedToursResponse =
      yield* httpRest<undefined, ListGuidedToursResponse>('guided-tours/', undefined, 'GET')

    if (!response) {
      throw new Invariant('[ getGuidedToursaga ] Missing response payload')
    }
    yield put(toursActionCreators.wamListGuidedToursSuccess(response))

  } catch (error) {
    yield put(toursActionCreators.wamListGuidedToursFailure(error.message))
  }
})

const getGuidedTourSaga = wamTakeLeading('wamGetGuidedTourRequest', function* (action) {
  try {
    const response: GetGuidedTourResponse =
      yield*
      httpRest<undefined, GetGuidedTourResponse>(
        `guided-tours/${action.payload.ecmsId}/`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getGuidedTourSaga ] Missing response payload')
    }
    yield put(toursActionCreators.wamGetGuidedTourSuccess(response))

  } catch (error) {
    yield put(toursActionCreators.wamGetGuidedTourFailure(error.message))
  }
})

const updateGuidedTourSaga = wamTakeLeading('wamUpdateGuidedTourRequest', function* (action) {
  try {
    const { ecmsId, ...data } = action.payload
    const response: UpdateGuidedTourResponse =
      yield*
      httpRest<Omit<UpdateGuidedTourRequest, 'ecmsId'>, UpdateGuidedTourResponse>(
        `guided-tours/${ecmsId}/`,
        data,
        'PUT',
      )

    if (!response) {
      throw new Invariant('[ updateGuidedTourSaga ] Missing response payload')
    }
    yield put(toursActionCreators.wamUpdateGuidedTourSuccess(response))

  } catch (error) {
    yield put(toursActionCreators.wamUpdateGuidedTourFailure(error.message))
  }
})

const updateTourDirectionsSaga = wamTakeLeading('wamUpdateTourDirectionsRequest', function* (action) {
  try {
    const { ecmsId, ...data } = action.payload
    const tourDirectionsResponse: UpdateTourDirectionsResponse =
      yield*
      httpRest<Omit<UpdateTourDirectionsRequest, 'ecmsId'>, UpdateTourDirectionsResponse>(
        `guided-tours/${ecmsId}/directions`,
        data,
        'PUT',
      )

    if (!tourDirectionsResponse) {
      throw new Invariant('[ updateTourDirectionsSaga ] Missing response payload')
    }

    yield put(toursActionCreators.wamUpdateTourDirectionsSuccess(tourDirectionsResponse))

    // get tour with the updated directions values
    yield put(toursActionCreators.wamGetGuidedTourRequest({ ecmsId: ecmsId }))

  } catch (error) {
    yield put(toursActionCreators.wamUpdateTourDirectionsFailure(error.message))
  }
})

const deleteTourDirectionsSaga = wamTakeLeading('wamDeleteTourDirectionsRequest', function* (action) {
  try {
    const response: DeleteTourDirectionsResponse =
      yield*
      httpRest<undefined, DeleteTourDirectionsResponse>(
        `guided-tours/${action.payload.ecmsId}/directions`,
        undefined,
        'DELETE',
      )

    if (!response) {
      throw new Invariant('[ deleteTourDirectionsSaga ] Missing response payload')
    }
    yield put(toursActionCreators.wamDeleteTourDirectionsSuccess(response))
    yield put(toursActionCreators.wamDeleteTourDirectionsReset())

  } catch (error) {
    yield put(toursActionCreators.wamDeleteTourDirectionsFailure(error.message))
  }
})

const deleteTourOutroSaga = wamTakeLeading('wamDeleteTourOutroRequest', function* (action) {
  try {
    const response: DeleteTourOutroResponse =
      yield*
      httpRest<undefined, DeleteTourOutroResponse>(
        `guided-tours/${action.payload.ecmsId}/outro`,
        undefined,
        'DELETE',
      )

    if (!response) {
      throw new Invariant('[ deleteTourOutroSaga ] Missing response payload')
    }
    yield put(toursActionCreators.wamDeleteTourOutroSuccess(response))
    yield put(toursActionCreators.wamDeleteTourOutroReset())

  } catch (error) {
    yield put(toursActionCreators.wamDeleteTourOutroFailure(error.message))
  }
})

export const toursSagas: ReadonlyArray<Effect> = [
  getGuidedToursSaga,
  getGuidedTourSaga,
  updateGuidedTourSaga,
  deleteTourDirectionsSaga,
  deleteTourOutroSaga,
  updateTourDirectionsSaga,
]
