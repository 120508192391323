import React from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'containers/store';
import { IconBeacon, IconSite, IconType, IconSpace } from 'components/ui/icons';
import { PrimaryNavigation } from 'components/ui/primary-navigation';
import { pick } from 'helpers/core';
import { space } from 'helpers/style'
import styled from 'styled';
import { ORG_SLUG } from '../constants'

import { AuthSession } from 'services';

const Wrapper = styled(Link)`
  height: 160px;
  width: 160px;
  margin-right: ${space(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.color.grayL80};
  &:hover {
    border-color: ${({ theme }) => theme.color.gray};
  }
`;

const IconWrapper = styled.div`
  border: solid 1px ${({ theme }) => theme.color.grayL80};
  border-radius: 50%;
  padding: ${space(3)};
`;

const LinkTitle = styled.p`
  margin: ${space(4)} 0 0 0;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.color.darkText};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin: ${space(4)} 0;
`;

const LinkRow = styled.div`
  display: flex;
  margin: ${space(8)} 0;
`;

type LargeLinkProps = {
  readonly icon: IconType;
  readonly title: string;
  readonly to: string;
};

const LargeLink = ({ icon, title, to }: LargeLinkProps) => {
  const IconComponent = icon;
  return (
    <Wrapper to={to}>
      <IconWrapper><IconComponent /></IconWrapper>
      <LinkTitle>{title}</LinkTitle>
    </Wrapper>
  )
};

type DirectProps = {};

type ConnectedProps = {
  readonly session: AuthSession | undefined;
};

type Props = DirectProps & ConnectedProps;

const DashboardView = (props: Props) => {
  return(
    <>
      <PrimaryNavigation />
      <div style={{ margin: space(8, false) }}>
        {ORG_SLUG === "wam" && window.location.pathname === "/sitemanagement"? 
          <Title>Under Construction</Title>
        :
          <>
            <Title>Welcome, {props.session && props.session.user.name}.</Title>
            <LinkRow>
              <LargeLink icon={IconSite} title="Structures" to="/locate/structures" />
              <LargeLink icon={IconBeacon} title="Beacons" to="/locate/beacons" />
              <LargeLink icon={IconSpace} title="Spaces" to="/locate/spaces" />
            </LinkRow>
          </>
        }
      </div>
    </>
  );

};

export const Dashboard = connect<ConnectedProps, {}, DirectProps>(
  (store) => pick(store.account, 'session'),
)(DashboardView);
