import { DateTime } from 'luxon'

export const stringToDateTime = (timestampString: string): DateTime =>
  DateTime.fromISO(timestampString).setLocale('au')

export const formatDateTime = (datetime: DateTime): string => {
  const today = DateTime.local().startOf('day')
  const yesterday = today.minus({ days: 1 })

  let formattedString = ''

  if (datetime > today) {
    formattedString = datetime.toFormat("'Today at 'hh:mm a")
  } else if (datetime > yesterday) {
    formattedString = datetime.toFormat("'Yesterday at 'hh:mm a")
  } else {
    formattedString = datetime.toFormat("d LLL yyyy' at 'hh:mm a")
  }

  return formattedString
}

export const timestampToFormattedString = (timestamp: string): string => {
  const datestamp = stringToDateTime(timestamp)
  return formatDateTime(datestamp)
}
