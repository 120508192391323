import React from 'react'

import { NavItems, SecondaryNavigation } from 'components/ui/secondary-navigation'

import { space } from 'helpers/style'
import styled from 'styled'
import { mediaQueries } from 'styled/core'

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.grayL90};
`

const PrimaryNav = styled.nav`
  flex-grow: 0;
  flex-shrink: 0;
`

const PageContent = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-left: ${space(9)};
  padding-right: ${space(9)};
  padding-bottom: ${space(18)};

  ${mediaQueries.lap} {
    padding-left: ${space(18)};
    padding-right: ${space(18)};
  }
`

const Header = styled.header<{ hasSubnav: boolean }>`
  display: flex;
  align-items: center;
  height: ${({ hasSubnav }) => hasSubnav ? '140px' : '120px'};
`

const Heading = styled.h1<{ hasSubnav: boolean }>`
  margin: 0;

  ${({ hasSubnav }) =>
    !hasSubnav &&
    `padding-top: 20px`
  }

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Toolbar = styled.div`
  margin-left: auto;
`

const Summary = styled.div`
  width: 100%;
  padding-bottom: ${space(8)};
`

const Main = styled.main<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${space(9)};

  ${({ hasBorder, theme }) =>
    hasBorder &&
    `border-top: 1px solid ${theme.color.grayL60};
     padding-top: ${space(9)};`
  }

  ${mediaQueries.hand} {
    flex-direction: row;
  }
`

const Section = styled.section<{ hasAside: boolean }>`
  ${mediaQueries.hand} {
    width: ${({ hasAside }) => hasAside ? '60%' : '100%'};
  }
`

const Aside = styled.aside`
  padding-top: ${space(6)};

  ${mediaQueries.hand} {
    padding-top: 0;
    padding-left: ${space(18)};
    width: 40%;
  }

  ${mediaQueries.lap} {
    padding-left: ${space(25)};
  }
`

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
`

type Props = {
  readonly nav: () => React.ReactNode;
  readonly title: string;
  readonly toolbar?: () => React.ReactNode;
  readonly summary?: () => React.ReactNode;
  readonly subnav?: () => React.ReactNode;
  readonly content: () => React.ReactNode;
  readonly sidebar?: () => React.ReactNode;
  readonly footer?: () => React.ReactNode;
};

export const LayoutListOrFormPage = (props: Props) => (
  <PageContainer>
    <PrimaryNav>{props.nav()}</PrimaryNav>
    <PageContent>
      <Header hasSubnav={!!props.subnav}>
        <Heading hasSubnav={!!props.subnav}>{props.title}</Heading>
        {props.toolbar && (<Toolbar>{props.toolbar()}</Toolbar>)}
      </Header>
      {props.summary && (<Summary>{props.summary()}</Summary>)}
      {props.subnav && props.subnav()}
      <Main hasBorder={!!props.subnav}>
        <Section hasAside={!!props.sidebar}>
          {props.content()}
        </Section>
        {props.sidebar && (<Aside>{props.sidebar()}</Aside>)}
      </Main>
    </PageContent>
    {props.footer && (<Footer>{props.footer()}</Footer>)}
  </PageContainer>
)
