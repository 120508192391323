import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'
import { stopsActionCreators } from '../actions/stops'

import {
  DeleteStopDirectionsResponse,
  GetStopResponse, UpdateStopDirectionsRequest, UpdateStopDirectionsResponse,
} from '../types'

const getStopSaga = wamTakeLeading('wamGetStopRequest', function* (action) {
  try {
    const response: GetStopResponse =
      yield*
      httpRest<undefined, GetStopResponse>(
        `guided-tours/${action.payload.ecmsTourId}/stops/${action.payload.ecmsStopId}`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getStopSaga ] Missing response payload')
    }
    yield put(stopsActionCreators.wamGetStopSuccess(response))

  } catch (error) {
    yield put(stopsActionCreators.wamGetStopFailure(error.message))
  }
})

const updateStopDirectionsSaga = wamTakeLeading('wamUpdateStopDirectionsRequest', function* (action) {
  try {
    const { ecmsTourId, ecmsStopId, ...data } = action.payload
    const stopDirectionsResponse: UpdateStopDirectionsResponse =
      yield*
      httpRest<Omit<UpdateStopDirectionsRequest, 'ecmsTourId' | 'ecmsStopId'>, UpdateStopDirectionsResponse>(
        `guided-tours/${ecmsTourId}/stops/${ecmsStopId}/directions`,
        data,
        'PUT',
      )

    if (!stopDirectionsResponse) {
      throw new Invariant('[ updateStopDirectionsSaga ] Missing response payload')
    }

    yield put(stopsActionCreators.wamUpdateStopDirectionsSuccess(stopDirectionsResponse))

    // get tour with the updated directions values
    yield put(stopsActionCreators.wamGetStopRequest({
      ecmsTourId: ecmsTourId,
      ecmsStopId: ecmsStopId,
     }))

  } catch (error) {
    yield put(stopsActionCreators.wamUpdateStopDirectionsFailure(error.message))
  }
})

const deleteStopDirectionsSaga = wamTakeLeading('wamDeleteStopDirectionsRequest', function* (action) {
  try {
    const { ecmsTourId, ecmsStopId } = action.payload
    const response: DeleteStopDirectionsResponse =
      yield*
      httpRest<undefined, DeleteStopDirectionsResponse>(
        `guided-tours/${ecmsTourId}/stops/${ecmsStopId}/directions`,
        undefined,
        'DELETE',
      )

    if (!response) {
      throw new Invariant('[ deleteStopDirectionsSaga ] Missing response payload')
    }
    yield put(stopsActionCreators.wamDeleteStopDirectionsSuccess(response))
    yield put(stopsActionCreators.wamDeleteStopDirectionsReset())

  } catch (error) {
    yield put(stopsActionCreators.wamDeleteStopDirectionsFailure(error.message))
  }
})

export const stopsSagas: ReadonlyArray<Effect> = [
  getStopSaga,
  updateStopDirectionsSaga,
  deleteStopDirectionsSaga,
]
