import React, { ReactNode } from 'react'

import { Loader } from 'components/ui/loader';
import { assertNever } from 'helpers/core';
import { DataStatus, Status } from 'helpers/status';
import styled from 'styled';

const ErrorTitle = styled.h4`
  color: ${({ theme }) => theme.color.error};
  font-weight: ${({ theme }) => theme.font.weight.light};
`;

const ErrorSubTitle = styled.p`
  color: ${({ theme }) => theme.color.error};
`;

const ErrorList = styled.ul`
  color: ${({ theme }) => theme.color.error};
  font-weight: ${({ theme }) => theme.font.weight.light};
  li {
    list-style: none;
  }
`;

const OuterCover = styled.div<{ translucent?: boolean; fullHeight?: boolean }>`
  position: relative;
  ${({ fullHeight }) => fullHeight && 'height: 100%;'}
  ${({ translucent }) => translucent && 'opacity: 0.5;'}
`;

const InnerCover = styled.div<{ background?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ theme, background }) => background && `background-color: ${theme.color.white};`}
`;

// only exported for storybook
type Mode = 'default' | 'form' | 'hideErrors';
export const modes: Mode[] = ['default', 'form', 'hideErrors'];

type Props = {
  readonly children?: ReactNode;
  readonly fullHeight?: boolean;
  readonly mode?: Mode;
  readonly status: DataStatus<any, any>;
};

export const StatusGuard = ({ children, fullHeight, mode, status }: Props) => {
  const messages = status.status  === Status.Failed ? status.messages : undefined;

  const auxComponents = {
    normal: null,
    blank: <InnerCover background />,
    updating: <InnerCover><Loader /></InnerCover>,
    loading: <InnerCover background><Loader /></InnerCover>,
    error:
  <InnerCover background>
    <ErrorTitle>An error occurred</ErrorTitle>
    {!!messages && (
    <> <ErrorSubTitle>The following messages were received</ErrorSubTitle>
      <ErrorList>
        {messages.map((v) => <li key={v.code}>{v.text}</li>)}
      </ErrorList> </>
        )}
  </InnerCover>,
  };

  let displayStyle: keyof typeof auxComponents | undefined;

  if (mode === 'form') {
    // idle is initial state for create forms, and errors should not hide the form
    switch (status.status) {
      case Status.Idle: displayStyle = 'normal'; break;
      case Status.Failed: displayStyle = 'normal'; break;
    }

  } else if (mode === 'hideErrors') {
    switch (status.status) {
      case Status.Failed: displayStyle = 'blank'; break;
    }
  }

  // default behavior
  if (displayStyle === undefined) {
    switch (status.status) {
      case Status.Idle: displayStyle = 'blank'; break;
      case Status.Loading: displayStyle = 'loading'; break;
      case Status.Stale: displayStyle = 'normal'; break;
      case Status.Ready: displayStyle = 'normal'; break;
      case Status.Updating: displayStyle = 'updating'; break;
      case Status.Complete: displayStyle = 'blank'; break;
      case Status.Failed: displayStyle = 'error'; break;
      default:
        assertNever(status);
        displayStyle = 'normal';
    }
  }

  return (
    <OuterCover translucent={displayStyle === 'updating'} fullHeight={fullHeight}>
      {children}
      {auxComponents[displayStyle]}
    </OuterCover>
  );
};
