import React from 'react'

import { Link } from 'components/ui/link'

import { space } from 'helpers/style'

import styled from 'styled'

const DetailsContainer = styled.div`
  padding: ${space(5)};
  border: 1px solid ${({ theme }) => theme.color.grayL70};
  border-bottom: 0;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme  }) => theme.font.weight.light};

  &:first-of-type {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayL70};
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

const DetailsHeading = styled.h4`
  margin-top: 0;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme  }) => theme.font.weight.base};
`

const DescriptionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: ${space(4)};
`

const DescriptionTitle = styled.dt`
  padding-bottom: ${space(1)};
  width: 130px;
`

const DescriptionValue = styled.dd`
  margin-left: 0;
  padding-bottom: ${space(1)};
  width: calc(100% - 130px)
`

type Props = {
  readonly publishDate: string;
  readonly projectId: string;
}

export const PublishDetails = ({ publishDate, projectId}: Props) => (
  <DetailsContainer>
    <DescriptionList>
      <DescriptionTitle>Last published</DescriptionTitle>
      <DescriptionValue>
        {publishDate}
      </DescriptionValue>
    </DescriptionList>
    <Link appearance="secondary" to={`/project/${projectId}/releases`}>See all releases</Link>
  </DetailsContainer>
)
