import { call, Effect, put, select, takeLeading } from 'redux-saga/effects'

import { refToUrn } from 'entity';
import { Space, SpaceType } from 'generated/mos/structure';
import { Invariant } from 'helpers/core';

import {
  GetSpaceRequest,
  GetSpaceResponse,
  ListSpacesRequest,
  ListSpacesResponse,
  ListSpaceTypesRequest,
  ListSpaceTypesResponse,
} from 'generated/mos/structureaccess';

import {
  CreateSpaceRequest,
  CreateSpaceResponse,
  DeleteSpaceRequest,
  DeleteSpaceResponse,
  UpdateSpaceRequest,
  UpdateSpaceResponse,
} from 'generated/mos/structuremanagement';

import { unaryGRPC } from 'services/unary-grpc';
import { Status } from 'helpers/status'

import { SpacesActions, actionCreators } from './actions'
import { SpacesAppState, SpaceTypes } from './store';


// spacesTakeLeading wraps takeLeading, requiring that the action derive from a key of
// SpacesActions. It brings an additional level of type safety to the ActionPattern.
//
// It is not necessary to pass the type parameter for TActionKey - it will be inferred
// from the value for the 'pattern' arg.
const spacesTakeLeading = <TActionKey extends keyof SpacesActions>(
  pattern: TActionKey,
  worker: (action: SpacesActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
};


export const spaceCreate = spacesTakeLeading(
  'spaceCreateRequest',
  function* (action: SpacesActions["spaceCreateRequest"]) {
    try {
      const entity = yield* unaryGRPC<
        CreateSpaceRequest.Entity,
        CreateSpaceResponse.Entity
      >(
        'mos.structure_management.StructureManagement/CreateSpace',
        action.args.input,
        CreateSpaceRequest.codec,
        CreateSpaceResponse.codec,
      );

      if (entity && entity.space && entity.space.ref && action.args.onSuccess) { // FIXME: legacy callback
        action.args.onSuccess(entity.space.ref);
      }
      yield put(actionCreators.spaceCreateSuccess(entity.space!)); // FIXME: should not be undefined in the generated wrapper
    } catch (err) {
      yield put(actionCreators.spaceCreateFailure(err.message));
    }
  }
);


function* initiateSpaceEdit(action: SpacesActions["spaceEditBeginRequest"]) {
  const request: GetSpaceRequest.Entity = {
    ...GetSpaceRequest.defaults,
    spaceRef: action.ref,
  }

  try {
    const resp = yield* unaryGRPC<
      GetSpaceRequest.Entity,
      GetSpaceResponse.Entity
    >(
      'mos.structure_access.StructureAccess/GetSpace',
      request,
      GetSpaceRequest.codec,
      GetSpaceResponse.codec,
    );
    yield put(actionCreators.spaceEditBeginSuccess(resp.space!)); // FIXME: this should not be undefined in the TS wrapper
    return resp;
  } catch (err) {
    yield put(actionCreators.spaceEditFailure(action.ref, err.message));
  }
}


export const spaceEditBeginRequest = spacesTakeLeading(
  "spaceEditBeginRequest",
  function* (action: SpacesActions["spaceEditBeginRequest"]) {
    const response: GetSpaceResponse.Entity = yield call(initiateSpaceEdit, action);
    yield put(actionCreators.spaceEditUpdate({ status: Status.Ready, ref: action.ref, data: response.space! })); // FIXME: this should not be undefined in the TS wrapper
  }
);


export const spaceEditDeleteRequest = spacesTakeLeading(
  "spaceEditDeleteRequest",
  function* (action: SpacesActions["spaceEditDeleteRequest"]) {
    const state: SpacesAppState = (yield select()).spaces;
    const { spaceEdit } = state;
    if (spaceEdit.status !== Status.Updating)  {
      throw new Invariant(`expected Updating, found ${spaceEdit.status}`); // FIXME: 3.7, use core.ts 'assert'
    }
    if (!spaceEdit.ref) {
      throw new Invariant("space missing ref"); // FIXME: 3.7, use core.ts 'assert'
    }
    const ref = spaceEdit.ref;

    try {
      const entity = yield* unaryGRPC<
        DeleteSpaceRequest.Entity,
        DeleteSpaceResponse.Entity
      >(
        'mos.structure_management.StructureManagement/DeleteSpace',
        { ...DeleteSpaceRequest.defaults, spaceRef: ref },
        DeleteSpaceRequest.codec,
        DeleteSpaceResponse.codec,
      );
      yield put(actionCreators.spaceEditUpdate({ status: Status.Idle }));
      yield put(actionCreators.spacesInvalidate());

      if (action.args.onSuccess) { // FIXME: legacy callback
        action.args.onSuccess();
      }
    } catch (err) {
      yield put(actionCreators.spaceEditFailure(ref, err.message));
    }
  }
);


function* initiateSpaceEditSave(request: UpdateSpaceRequest.Entity) {
  const { spaceRef } = request;
  if (!spaceRef) {
    throw new Invariant("space missing ref"); // FIXME: 3.7, use core.ts 'assert'
  }
  try {
    yield* unaryGRPC<
      UpdateSpaceRequest.Entity,
      UpdateSpaceResponse.Entity
    >(
      'mos.structure_management.StructureManagement/UpdateSpace',
      request,
      UpdateSpaceRequest.codec,
      UpdateSpaceResponse.codec,
    );
    yield put(actionCreators.spaceEditUpdate({ status: Status.Idle }));
  } catch (err) {
    yield put(actionCreators.spaceEditFailure(spaceRef, err.message));
  }
}

export const spaceEditSaveRequest = spacesTakeLeading(
  "spaceEditSaveRequest",
  function* (action: SpacesActions["spaceEditSaveRequest"]) {
    const state: SpacesAppState = (yield select()).spaces;
    const { spaceEdit } = state;

    if (spaceEdit.status !== Status.Ready) {
      throw new Error(); // FIXME: error-handling
    }
    const ref = action.args.spaceInput.spaceRef;
    if (!ref) {
      throw new Invariant("space missing ref"); // FIXME: 3.7, use core.ts 'assert'
    }

    yield put(actionCreators.spaceEditUpdate({ ...spaceEdit, status: Status.Updating }));
    yield call(initiateSpaceEditSave, action.args.spaceInput);

    yield put(actionCreators.spacesInvalidate());

    // FIXME: should this be a call?
    yield put(actionCreators.spaceEditBeginRequest(ref));

    if (action.args.onSuccess) { // FIXME: legacy callback
      action.args.onSuccess(ref);
    }
  }
);


export const spaceTypesRequest = spacesTakeLeading(
  "spaceTypesRequest",
  function* (action: SpacesActions["spaceTypesRequest"]) {
    try {
      const entity = yield* unaryGRPC<
        ListSpaceTypesRequest.Entity,
        ListSpaceTypesResponse.Entity
      >(
        'mos.structure_access.StructureAccess/ListSpaceTypes',
        ListSpaceTypesRequest.defaults,
        ListSpaceTypesRequest.codec,
        ListSpaceTypesResponse.codec,
      );
      const index: { [urn: string]: SpaceType.Entity } = {};
      entity.spaceTypes.reduce(
        (acc: typeof index, v: SpaceType.Entity) => {
          if (v.ref) acc[refToUrn(v.ref)] = v;
          return acc;
        },
        index,
      );
      const data: SpaceTypes = { spaceTypes: entity.spaceTypes, index };
      yield put(actionCreators.spaceTypesUpdate({ status: Status.Ready, data }));
    } catch (err) {
      yield put(actionCreators.spaceTypesFailure(err.message));
    }
  }
);


export const spacesRequest = spacesTakeLeading(
  "spacesRequest",
  function* (action: SpacesActions["spacesRequest"]) {
    try {
      const entity = yield* unaryGRPC<
        ListSpacesRequest.Entity,
        ListSpacesResponse.Entity
      >(
        'mos.structure_access.StructureAccess/ListSpaces',
        ListSpacesRequest.defaults,
        ListSpacesRequest.codec,
        ListSpacesResponse.codec,
      );
      yield put(actionCreators.spacesUpdate({ status: Status.Ready, data: entity.spaces || [] }));
    } catch (err) {
      yield put(actionCreators.spacesFailure(err.message));
    }
  }
);


export const spacesRootSaga: ReadonlyArray<Effect> = [
  spaceCreate,
  spaceEditBeginRequest,
  spaceEditDeleteRequest,
  spaceEditSaveRequest,
  spaceTypesRequest,
  spacesRequest,
];
