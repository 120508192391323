import React, { useEffect } from 'react'

import { Button } from 'components/ui/button'
import { IconHelpscout } from '../icons/IconHelpscout';
import { space } from 'helpers/style';

import styled from 'styled';

const HelpButton = styled(Button)<{darkTheme?: boolean}>`
  background: transparent;
  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.white : theme.color.gray};

  border: 0;
  border-radius: 0;
  border-right: 1px solid ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};

  margin-right: ${space(3)};

  &:focus::after {
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.color.grayD20};
  }

  &:active {
    background: transparent;
  }
`

const ButtonLabel = styled.span<{darkTheme?: boolean}>`
  padding-right: ${space(1)};
  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
`

const StyledIconHelpscout = styled(IconHelpscout)`
  margin-top: ${space(1)};
  margin-right: ${space(1)};
`

type Props = {
  readonly darkTheme?: boolean;
}

export const HelpScout = (props: Props) => {
  const {
    darkTheme,
  } = props

  useEffect(() => {
    window.Beacon('init', '9bea69c8-9f2f-44a7-8328-ce46c5a1f863')

    window.Beacon('config', {
      display: {
        style: "manual",
      }
    })
  }, [])

  return (
    <HelpButton
      appearance="secondary"
      darkTheme={darkTheme}
      isSmall={true}
      onClick={()=> window.Beacon('toggle') }
    >
      <ButtonLabel darkTheme={darkTheme}>Help</ButtonLabel>
      <StyledIconHelpscout color={darkTheme ? 'white' : 'gray'} />
    </HelpButton>
  )
}
