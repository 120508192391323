import tags from 'language-tags'

import { store } from 'containers/store'
import { Language } from 'generated/mos/i18n'
import { Invariant } from 'helpers/core'
import { ORG_SLUG } from '../constants'

export const defaultLanguage = (): Language.Entity => {
  const language = {
    type: Language.refName,
    languageCode: 'en',
    variant: 'AU',
  }

  // FIXME: we need a better way of determining the default language for an org.
  if (ORG_SLUG === 'wam') {
    language.variant = 'US'
  }

  return language
}

export const parseLanguage = (input: Language.Entity) => {
  const languageInput = input.languageCode
  const variantInput = input.variant

  const getDescription = (tag: { descriptions: () => ReadonlyArray<string> | null }) => {
    const descriptions: ReadonlyArray<string> | null = tag.descriptions()
    return descriptions && descriptions.length ? descriptions[0] : null
  }

  const languageTag = tags.language(languageInput)

  if (languageTag) {
    const languageName = getDescription(languageTag)
    let regionName = ''
    let scriptName = ''

    // FIXME: generated typescript doesn't allow undefined variant, so currently we will be passing
    // an empty string to represent no variant value.
    if (variantInput && variantInput.trim().length) {
      const regionTag = tags.type(variantInput, 'region')
      if (regionTag) {
        regionName = `${regionTag.data.record.Subtag} `
      }

      const scriptTag = tags.type(variantInput, 'script')
      if (scriptTag) {
        scriptName = ` ${getDescription(scriptTag)}`
      }
    }

    return `${regionName}${languageName}${scriptName}`
  }

  throw new Invariant(`No language resolved for input: ${languageInput}`)
}
