import * as geo from 'helpers/geo'; // XXX: geo is the only mos-admin-web dependency allowed in here

import { Addable } from './addable';
import { LayerDataIndex, LayerDef } from './layers';
import { SourceDef, SourceDefs } from './sources';


export type DefaultState = {};


// LayoutProperties uses dark TypeScript voodoo to create a type alias for the type
// of the 'layout' parameter which is embedded in the mapboxgl.Layer type.
export type LayoutProperties = mapboxgl.Layer extends { layout?: infer I } ? I : never;


// PaintProperties uses dark TypeScript voodoo to create a type alias for the type
// of the 'paint' parameter which is embedded in the mapboxgl.Layer type.
export type PaintProperties = mapboxgl.Layer extends { paint?: infer I } ? I : never;


// ImageDefData uses dark TypeScript voodoo to create a type alias for the type
// of the 'image' parameter which is embedded in the mapboxgl.Map.addImage() function.
type ImageDefData = mapboxgl.Map extends {
  addImage(name: string, image: infer I, options?: any): mapboxgl.Map;
} ? I : never;


// ImageDefOptions uses dark TypeScript voodoo to create a type alias for the type
// of the 'options' parameter which is embedded in the mapboxgl.Map.addImage() function.
type ImageDefOptions = mapboxgl.Map extends {
  addImage(name: string, image: ImageDefData, options?: infer I): mapboxgl.Map;
} ? I : never;


export interface ImageDef {
  readonly key: string;
  readonly data: ImageDefData;
  readonly options?: ImageDefOptions;
}


export interface MapSet<TState=never> {
  readonly key: string;

  layers?(state?: TState): ReadonlyArray<LayerDef>;

  // Add an image to the style. This image can be used in icon-image,
  // background-pattern, fill-pattern, and line-pattern.
  images?(state?: TState): ReadonlyArray<ImageDef>;

  // Addables are items like a mapboxgl.Marker or a mapboxgl.Popup, associated
  // with the MapSet and removed when the MapSet is removed. Addables are added
  // after layers are added, and removed before layers are removed.
  addables?(state?: TState): ReadonlyArray<Addable>;
}


export type LngLat = Readonly<{ lng: number; lat: number }>;


// MapView MUST be serialisable.
export type MapView = {
  readonly center: geo.Position2D;
  readonly zoom: number;
};

export interface MapEvents {
  readonly onViewChanged?: (e: MapView) => void;
}


// MapInitialBounds MUST be serialisable.
export interface MapInitialBounds {
  // This is used to invalidate `bounds`. Initial bounds will not be set as long
  // as `identity` compares with === to the previously used `identity` value,
  // regardless of whether or not `bounds` has changed.
  //
  // There are two recommended techniques for dealing with this:
  //
  // If the initial bounds will only ever be set once during the lifetime of
  // the component, i.e. in an initialisation step, use the `this` of the
  // component itself.
  //
  // If the initial bounds must be re-set in response to events during the
  // lifetime of the component, use an incrementing integer in the component's
  // state.
  //
  // XXX: We experimented with an alternative way of passing the bounds that
  // avoids the need for identity: replacing MapView with a union that could
  // accept either a center/zoom pair or a bounds/padding pair, but this doesn't
  // cover all the use cases. It works well from the point of view of the data
  // flow, but Twe have a lot of code that depends on being able to know the centre
  // point at any time (for example, using the map centre for a beacon's initial
  // position).
  readonly identity: any;

  readonly bounds: geo.BBox2D | geo.Extent2D;

  // FIXME: default padding in Context
  readonly padding: number;
}


interface MapConfig {
  // Layers are collected from MapSet. Z-ordering of layers is preserved both
  // across the MapSets array, and within the MapSet itself.
  readonly mapSets?: ReadonlyArray<MapSet<any> | null | undefined>;
  readonly view?: MapView;

  // MapView allows you to set the center and zoom, but React's model makes it
  // impossible to calculate the correct center and zoom for a given map bounds
  // in a declarative fashion: mapbox/geo-viewport allows you to calculate
  // center/zoom from bounds, but only once you have the width and height of the
  // DOM element, which you can't get until after the first time the component
  // has rendered, and which changes regularly.
  //
  // We also do not want to destroy the abstraction we have worked so hard to
  // create by exposing the map object for arbitrary imperative calls.
  //
  // This is a less-worse way of doing it: if you pass the initialBounds prop,
  // mapbox will apply the initialBounds once and only once, then revert to
  // using the center/zoom found in MapView. To make mapbox update the bounds
  // again, update the identity prop.
  readonly initialBounds?: MapInitialBounds;
}

export interface MapData extends MapConfig, MapEvents {}


export class MapDataIndex implements LayerDataIndex {
  public readonly mapData: MapData;

  public readonly addables: ReadonlyArray<Addable>;
  public readonly images: ReadonlyArray<ImageDef>;
  public readonly layers: LayerDef[] = [];
  public readonly sources: SourceDefs = {};
  public readonly mapSetsByAddable = new Map<Addable, MapSet>();

  public readonly addablesByKey: { [key: string]: Addable };
  public readonly imagesByKey: { [key: string]: ImageDef };
  public readonly layerIds: string[] = [];
  public readonly layersById: { [key: string]: LayerDef } = {};
  public readonly layersBySource = new Map<SourceDef, LayerDef[]>();
  public readonly layerZByLayerId: { [key: string]: number } = {};
  public readonly mapSetsByKey: { [key: string]: MapSet } = {};
  public readonly sourceIdsBySource = new Map<SourceDef, string>();
  public readonly sourcesById: { [key: string]: SourceDef } = {};

  public constructor(newData: MapData, mapSetStates: { [key: string]: any }) {
    this.mapData = newData;

    const allAddables: Addable[] = [];
    const allAddablesByKey: { [key: string]: Addable } = {};
    const allImages: ImageDef[] = [];
    const allImagesByKey: { [key: string]: ImageDef } = {};

    this.addables = allAddables;
    this.addablesByKey = allAddablesByKey;
    this.images = allImages;
    this.imagesByKey = allImagesByKey;

    let layerZ = 0;

    for (const mapSet of (newData.mapSets || []).values()) {
      if (!mapSet) {
        continue;
      }

      const state = mapSetStates[mapSet.key];

      this.mapSetsByKey[mapSet.key] = mapSet;

      const addables = mapSet.addables ? mapSet.addables(state) : [];
      allAddables.push(...addables);
      indexKeyed('addable', addables, allAddablesByKey);
      for (const addable of addables) {
        this.mapSetsByAddable.set(addable, mapSet);
      }

      const images = mapSet.images ? mapSet.images(state) : [];
      allImages.push(...images);
      indexKeyed('image', images, allImagesByKey);

      for (const layer of mapSet.layers ? mapSet.layers(state) : []) {
        this.layers.push(layer);
        this.sources[layer.source.id] = layer.source;

        const sourceId = layer.source.id;
        if (!layer.source.id) {
          throw new Error(`source for layer '${layer.id}' has no ID`);
        }
        if (sourceId in this.sourcesById && this.sourcesById[sourceId] !== layer.source) {
          throw new Error(`duplicate source ID ${layer.source.id}`);
        }
        this.sourceIdsBySource.set(layer.source, sourceId);
        this.sourcesById[sourceId] = layer.source;

        if (layer.id in this.layersById) {
          throw new Error(`duplicate layer ID ${layer.id}`);
        }

        const layerId = layer.id;
        this.layersById[layerId] = layer;

        if (!this.layersBySource.has(layer.source)) {
          this.layersBySource.set(layer.source, []);
        }
        this.layersBySource.get(layer.source)!.push(layer);
        this.layerZByLayerId[layerId] = layerZ;
        this.layerIds.push(layerId);

        layerZ++;
      }
    }
  }
}


// XXX(bw): We used to use a nice enum for resolution, but it was cumbersome to
// use in practice. It required a specific import in every implementation. If
// you wanted to use a subset of it (as we do in Addable), tsserver couldn't
// show you the subset in autocomplete. So we've gone back to traditional
// string-based discriminated unions. This has its own drawbacks, of course,
// but Enums are just NQR in practice.
export type Resolution = 'add' | 'keep' | 'remove' | 'replace' | 'update';


function indexKeyed<T extends { key: string }>(
  kind: string,
  items: ReadonlyArray<T>,
  into: { [key: string]: T },
) {
  const index: { [key: string]: T } = {};

  let i = 0;
  for (const item of items) {
    if (!item.key) {
      throw new Error(`${kind} at index ${i} has no key`);
    }
    if (item.key in index) {
      throw new Error(`duplicate ${kind} key ${item.key} at index ${i}`);
    }
    into[item.key] = item;

    i++;
  }
}
