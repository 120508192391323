import React from 'react'

import { NavItems, SecondaryNavigation } from 'components/ui/secondary-navigation'

import { space } from 'helpers/style'
import styled from 'styled'
import { mediaQueries } from 'styled/core'

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.grayL90};
`

const PrimaryNav = styled.nav`
  flex-grow: 0;
  flex-shrink: 0;
`

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-left: ${space(9)};
  padding-right: ${space(9)};

  ${mediaQueries.lap} {
    padding-left: ${space(18)};
    padding-right: ${space(18)};
  }
`


const Header = styled.header<{ hasSubnav: boolean }>`
  display: flex;
  align-items: center;
  height: ${({ hasSubnav }) => hasSubnav ? '140px' : '120px'};
`

const Heading = styled.h1<{ hasSubnav: boolean }>`
  margin: 0;
  width: 100%;

  ${({ hasSubnav }) => 
    !hasSubnav && 
    `padding-top: 20px`
  }
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Main = styled.main<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${space(9)};

  ${({ hasBorder, theme }) => 
    hasBorder && 
    `border-top: 1px solid ${theme.color.grayL60};
     padding-top: ${space(9)};`
  }

  ${mediaQueries.hand} {
    flex-direction: row;
  }
`

const Toolbar = styled.div`
  margin-left: auto;
`

const Section = styled.section`
  width: 100%;
`

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
`

type Props = {
  readonly nav: () => React.ReactNode;
  readonly title?: string;
  readonly toolbar?: () => React.ReactNode;
  readonly header?: () => React.ReactNode;
  // subnav items are rendered into the layout directly so this area doesn't get repurposed.
  readonly subnav?: NavItems;
  readonly content: () => React.ReactNode;
  readonly footer?: () => React.ReactNode;
};

export const LayoutFullWidthPage = (props: Props) => (
  <PageContainer>
    <PrimaryNav>{props.nav()}</PrimaryNav>
    <div>
      {(props.title || props.header) && <Wrap>
        {props.title && <Header hasSubnav={!!props.subnav}>
          {props.title && <Heading hasSubnav={!!props.subnav}>{props.title}</Heading>}
          {props.toolbar && (<Toolbar>{props.toolbar()}</Toolbar>)}
        </Header>}
        {props.header && props.header()}
      </Wrap>}
      {props.subnav && (
        <SecondaryNavigation navItems={props.subnav} />
      )}
      <Main hasBorder={!!props.subnav}>
        <Section>
          {props.content()}
        </Section>
      </Main>
    </div>
    {props.footer && (<Footer>{props.footer()}</Footer>)}
  </PageContainer>
)
