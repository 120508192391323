import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { assertNever } from 'helpers/core';
import { Error404Page } from 'pages/error/404';

import { BuildingEditPage } from 'domains/structures/components/building-edit-page';
import { BuildingLevelEditPage } from 'domains/structures/components/building-level-edit-page';
import { SiteEditPage } from 'domains/structures/components/site-edit-page';
import { StructuresListPage } from 'domains/structures/components/structures-list-page';
import { StructureRef } from 'domains/structures/entities';


function refTypeRoute(ref: StructureRef) {
  switch (ref.typename) {
  case 'mos.structure.Site': return 'site';
  case 'mos.structure.Building': return 'building';
  case 'mos.structure.BuildingLevel': return 'building-level';
  case 'mos.structure.OutdoorLevel':
    throw new Error("not supported");
  default:
    assertNever(ref);
  }
}

export const StructuresRoutes = () => (
  <Switch>
    <Route
      exact path="/locate/structures/site/edit/:id"
      render={
        (routeProps: RouteComponentProps<{ id: string }>) => (
          <SiteEditPage
            siteId={routeProps.match.params.id}
            onComplete={() => routeProps.history.push('/locate/structures')}
          />
        )
      }
    />

    <Route
      exact path="/locate/structures/building/edit/:id"
      render={
        (routeProps: RouteComponentProps<{ id: string }>) => (
          <BuildingEditPage
            buildingId={routeProps.match.params.id}
            onComplete={() => routeProps.history.push('/locate/structures')}
          />
        )
      }
    />

    <Route
      exact path="/locate/structures/building-level/edit/:id"
      render={
        (routeProps: RouteComponentProps<{ id: string }>) => (
          <BuildingLevelEditPage
            buildingLevelId={routeProps.match.params.id}
            onComplete={() => routeProps.history.push('/locate/structures')}
          />
        )
      }
    />

    <Route
      exact path="/locate/structures/:structureRef?"
      render={
        (routeProps: RouteComponentProps<{ structureRef: string }>) => (
          <StructuresListPage
            onCreate={(ref) => {
              routeProps.history.push(`/locate/structures/${refTypeRoute(ref)}/edit/${ref.id}`);
            }}
            navBackRoute="/locate"
          />
        )
      }
    />

    <Route render={() => <Error404Page />} />
  </Switch>
);
