// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureScalar } from "generated/webrpc";

export namespace Page {
  export const refName = "mos.pagination.Page" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Starts the pagination n items from the start of the list. It may not be
    // supported/supportable by all endpoints; if not,
    // ERR_PAGINATION_SKIP_ITEMS_NOT_IMPLEMENTED is raised.
    // 
    // The value must not change for subsequent pages. If it changes,
    // ERR_PAGINATION_SKIP_ITEMS_CHANGED is raised.
    readonly skipItems: number,
    // The value must not change for subsequent pages. If it changes,
    // ERR_PAGINATION_SIZE_CHANGED is raised.
    readonly pageSize: number,
    readonly pageToken: string,
  }
  export const defaults: Entity = {
    type: refName,
    skipItems: 0,
    pageSize: 0,
    pageToken: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.pagination.Page");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["skipItems"] = ensureScalar(ctx, v["skipItems"], "number");
      out["pageSize"] = ensureScalar(ctx, v["pageSize"], "number");
      out["pageToken"] = ensureScalar(ctx, v["pageToken"], "string");
      return out as any as Entity;
    }
  }();
}

export const PaginationErrorValues = new Set([
  'ERR_PAGINATION_UNSPECIFIED' as const,
  'ERR_PAGINATION_SIZE_REQUIRED' as const,
  'ERR_PAGINATION_FILTER_CHANGED' as const,
  'ERR_PAGINATION_SIZE_CHANGED' as const,
  'ERR_PAGINATION_SKIP_ITEMS_CHANGED' as const,
  'ERR_PAGINATION_SORT_ORDER_CHANGED' as const,
  'ERR_PAGINATION_SKIP_ITEMS_NOT_IMPLEMENTED' as const,
]);
export type PaginationError = typeof PaginationErrorValues extends Set<infer U> ? U : never;

