import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Error404Page } from 'pages/error/404';

import { BeaconEditPage } from 'domains/beacons/components/beacon-edit-page';
import { BeaconListPage } from 'domains/beacons/components/beacon-list-page';

export const BeaconsRoutes = () => (
  <Switch>
    <Route
      exact path="/locate/beacons/edit/:beaconId"
      render={(routeProps: RouteComponentProps<{ beaconId: string }>) => (
        <BeaconEditPage
          beaconId={routeProps.match.params.beaconId}
          onComplete={() => routeProps.history.push('/locate/beacons')}
        />
      )}
    />

    <Route
      exact path="/locate/beacons/:structureRef?"
      render={
        (routeProps: RouteComponentProps<{ structureRef: string }>) => (
          <BeaconListPage
            onCreate={(e) => {
              routeProps.history.push(`/locate/beacons/edit/${e.ref.id}`);
            }}
            navBackRoute="/locate"
          />
        )
      }
    />

    <Route render={() => <Error404Page />} />
  </Switch>
);
