/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconTrash = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M18.014 8.59a.682.682 0 01.681.792L16.868 20.29a.682.682 0 01-.668.559H7.8a.682.682 0 01-.668-.56L5.305 9.383a.682.682 0 01.668-.791h12.04zm-3.287-5.454c.377 0 .682.306.682.682v2.046h3.41a.682.682 0 010 1.363H5.181a.682.682 0 010-1.363H8.59V3.818c0-.376.305-.682.682-.682h5.454zM14.045 4.5h-4.09v1.364h4.09V4.5z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconTrashString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M18.014 8.59a.682.682 0 01.681.792L16.868 20.29a.682.682 0 01-.668.559H7.8a.682.682 0 01-.668-.56L5.305 9.383a.682.682 0 01.668-.791h12.04zm-3.287-5.454c.377 0 .682.306.682.682v2.046h3.41a.682.682 0 010 1.363H5.181a.682.682 0 010-1.363H8.59V3.818c0-.376.305-.682.682-.682h5.454zM14.045 4.5h-4.09v1.364h4.09V4.5z"  fillRule="nonzero" />
  </svg>`

IconTrash.displayName = 'IconTrash'
