import React, { useEffect, useState } from 'react'

import { Button, ButtonGroup } from 'components/ui/button'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { Confirm } from 'components/ui/modal'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SectionHeader } from 'components/ui/section-header'

import { UploadAssets } from '../upload-assets'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { Status, statusSelector } from 'helpers/status'
import { pick, pluralise } from 'helpers/core'
import { space } from 'helpers/style'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { getSubnav } from '../subnav'

import styled from 'styled'

import { ListItem } from './list-item'
import { HoldingAudio } from '../../types'

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: -${space(6)};
  margin-bottom: ${space(3)};
`;

type ActionProps = Pick<WamActionCreators, 'wamListHoldingAudioRequest' | 'wamClearHoldingAudioRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['holdingAudio'], 'list' | 'holdingAudioClear'>

type DirectProps = {
  projectId: string
  historyPush: (id: string) => void
  navBackRoute: string
  title: string
}
type Props = ActionProps & ConnectedProps & DirectProps

const HoldingAudioPageView = (props: Props) => {
  const {
    wamListHoldingAudioRequest,
    list,
    projectId,
    historyPush,
    navBackRoute,
    wamClearHoldingAudioRequest,
    holdingAudioClear,
    toastNotification,
  } = props

  const data = statusSelector.data(list)
  const [ clearHoldingAudio, setClearHoldingAudio ] = useState<HoldingAudio.Entity | undefined>(undefined)

  useEffect(() => {
    wamListHoldingAudioRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (holdingAudioClear.status === Status.Complete) {
      wamListHoldingAudioRequest()
      toastNotification({
        type: 'success',
        text: 'Holding audio deleted successfully.',
      })
    }

    if (holdingAudioClear.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Holding audio could not be deleted.',
      })
    }
  }, [holdingAudioClear])

  const deleteHandler = (holdingAudioFloorId: string) => {
    wamClearHoldingAudioRequest({
      ecmsFloorId: holdingAudioFloorId
    })
    setClearHoldingAudio(undefined)
  }

  return (
    <>
      {clearHoldingAudio &&
        <Confirm
          title="Clear Holding Audio?"
          onClose={() => setClearHoldingAudio(undefined)}
          message={`Are you sure you want to permanently clear holding audio from floor "${clearHoldingAudio.floorName}" ?`}
          confirmLabel="Delete"
          onConfirm={() => deleteHandler(clearHoldingAudio.floorId)}
          danger
        />
      }
      <LayoutListOrFormPage
        nav={() =>
          <PrimaryNavigationEditEntity
            title="Edit Project"
            navBackRoute={navBackRoute}
            darkTheme
          />
        }
        title={'WAM'}
        toolbar={() => (
          <ButtonGroup>
            <Button
              appearance="primary"
              onClick={() => props.historyPush(`/project/${projectId}/publishing`)}
            >
              Publish
            </Button>
          </ButtonGroup>
        )}
        subnav={() => <SecondaryNavigation navItems={getSubnav(projectId)} />}
        content={() => (
          <>
            <ButtonRow>
              <ButtonGroup>
                <UploadAssets onSuccessHandler={() => wamListHoldingAudioRequest()}/>
              </ButtonGroup>
            </ButtonRow>
            <StatusGuard status={list}>
              <SectionHeader heading={`${data ? data.length : 0} ${pluralise(data ? data.length : 0, 'floor', 'floors')} `} />
              {data &&
                data.map((holdingAudio) => {
                  const { floorId, floorName, audioFilename } = holdingAudio
                  return (
                    <ListItem
                      key={floorId}
                      audioFilename={audioFilename}
                      floorName={floorName}
                      deleteHandler={() => setClearHoldingAudio(holdingAudio)}
                    />
                  )
                })}
            </StatusGuard>
          </>
        )}
      />
    </>
  )
}

export const HoldingAudioPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.holdingAudio, 'list', 'holdingAudioClear'),
  }),
  {
    ...pick(actionCreators, 'wamListHoldingAudioRequest', 'wamClearHoldingAudioRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(HoldingAudioPageView)
