import React, { useEffect, useState } from 'react'
import { createFormBag, FormBag, FormData, FormErrors, validateFormBag } from 'react-formage'

import { Fieldset } from 'components/ui/fieldset'
import { Input } from 'components/ui/input'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { FooterEditEntity } from 'components/ui/footer'
import { MultiSelect } from 'components/ui/select'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { spacesBindableActionCreators } from 'domains/spaces/actions'
import { SpacesStore } from 'domains/spaces/store'
import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { Space } from 'generated/mos/structure'

import { statusSelector, Status } from 'helpers/status'
import { areObjectsEqual, pick } from 'helpers/core'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

type FormValues = {
  title: string | undefined
  activeSpaces: ReadonlyArray<Space.Ref>
  audioFilename: string | undefined
  audioDurationSeconds: number | undefined
}

type ActionProps = Pick<WamActionCreators, 'wamGetAtmosphericZoneRequest' | 'wamUpdateAtmosphericZoneRequest'> &
  Pick<typeof spacesBindableActionCreators, "spacesRequest"> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['atmosphericZones'], 'atmosphericZonesUpdate'> & Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  tourId: string
  atmosphericZoneId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const AtmosphericZoneUpdatePageView = (props: Props) => {
  const {
    projectId,
    tourId,
    atmosphericZoneId,
    wamGetAtmosphericZoneRequest,
    atmosphericZonesUpdate,
    historyPush,
    spacesRequest,
    spacesList,
    wamUpdateAtmosphericZoneRequest,
  } = props
  const data = statusSelector.data(atmosphericZonesUpdate)
  const spacesListData = statusSelector.data(spacesList)

  const [formBag, setFormBag] = useState<FormBag<FormValues> | undefined>(undefined)

  useEffect(() => {
    spacesRequest()
    wamGetAtmosphericZoneRequest({ ecmsId: tourId, ecmsAtmosSlug: atmosphericZoneId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formValues = (data: any) => {
    const { title, activeSpaces, audioFilename, audioDurationSeconds } = data
    return {
      title,
      activeSpaces,
      audioFilename,
      audioDurationSeconds,
    }
  }
  const initialFormValues: FormValues | undefined =
    data && data.slug === atmosphericZoneId ? formValues(data) : undefined

  useEffect(() => {
    if (atmosphericZonesUpdate.status === Status.Ready && initialFormValues) {
      setFormBag(createFormBag(initialFormValues))
    }
  }, [atmosphericZonesUpdate.status])

  const modified =
    initialFormValues && data && formBag && !areObjectsEqual(initialFormValues, formBag.values)

  const save = () => {
    if (formBag && data) {
      const {
        title,
        activeSpaces,
      } = formBag.values

      wamUpdateAtmosphericZoneRequest({
        ecmsId: tourId,
        ecmsAtmosSlug: atmosphericZoneId,
        title: (!title || !title.trim().length) ? undefined : title,
        activeSpaces,
      })
    }
  }

  return (
    <LayoutListOrFormPage
      nav={() => (
        <PrimaryNavigationEditEntity
          title="Edit Atmospheric Sound"
          onConfirm={modified ? () => historyPush(`/project/${projectId}/tour/${tourId}/atmospheric-zones`) : undefined}
          navBackRoute={`/project/${projectId}/tour/${tourId}/atmospheric-zones`}
        />
      )}
      sidebar={() => null}
      subnav={() => null}
      title={data ? data.title || data && `Untitled - ${data.slug}` : ''}
      content={() => (
        <StatusGuard status={atmosphericZonesUpdate}>
          {formBag && data && spacesListData && (
            <form
              noValidate
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              <FormData
                bag={formBag}
                onUpdate={(event) => {
                  setFormBag(event.bag)
                }}
                validate={() => ({})}
              >
                <Fieldset legend="Details">
                  <Input label="Atmospheric Sound Title" field="title" error={formBag.touched.title ? formBag.errors.title : undefined} />
                  <MultiSelect<FormValues, 'activeSpaces'>
                    label="Active space(s)"
                    field="activeSpaces"
                    options={spacesListData.map(space => ({
                      label: space.displayName || space.name,
                      value: Space.mustRef(space.ref)
                    }))}
                  />
                  <Input isDisabled label="Audio Asset URL" field="audioFilename" />
                  <Input isDisabled label="Duration" field="audioDurationSeconds" description="Time in seconds" />
                </Fieldset>
              </FormData>
            </form>
          )}
        </StatusGuard>
      )}
      footer={() => (
        <FooterEditEntity
          entity={atmosphericZonesUpdate}
          modified={modified}
          onCancel={() => historyPush(`/project/${projectId}/tours`)}
          onSave={save}
        />
      )}
    />
  )
}

export const AtmosphericZoneUpdatePage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.atmosphericZones, 'atmosphericZonesUpdate'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(actionCreators, 'wamGetAtmosphericZoneRequest', 'wamUpdateAtmosphericZoneRequest'),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(AtmosphericZoneUpdatePageView)
