import * as Sentry from '@sentry/browser'
import packageJson from '../../package.json'

import { bffEndpoint } from 'helpers/bff'

// FIXME: this is a bit nasty, but a workaround while we are multi-tenanted.
// try to determine which dsn to send log data to based on the org slug obtained from the bff
// endpoint url. all dsn values are baked into the application bundle also due to multi-tenancy.
const dsn = {
  awm: 'https://d3428f6e111f4fd384b23259356f4795@o22412.ingest.sentry.io/1273959',
  getty: 'https://31900bfdd3f34a6b84b8f7910b83e216@o22412.ingest.sentry.io/5241413',
}
const orgSlug = bffEndpoint(window.location.hostname).split('.')[1] as keyof typeof dsn

type Config = {
  readonly dsn?: string;
  readonly environment?: string;
  readonly debug?: boolean;
  readonly release?: string;
  readonly ignoreErrors: Array<string>;
  readonly blacklistUrls: Array<string | RegExp>;
};

export const configureErrorLogging = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT
  const config: Config = {
    ...(dsn[orgSlug] ? { dsn: dsn[orgSlug] } : null),
    ...(environment ? { environment } : null),
    release: packageJson.version,

    // https://docs.sentry.io/platforms/javascript/#filter-events--custom-logic
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    blacklistUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  }

  // initialise if we have resolved a sentry dsn for the org, and have an allowed environment defined.
  if (
    config.dsn &&
    config.environment &&
    // environment strings defined under each environment build config
    // https://github.com/ArtProcessors/mos-apps-deploy/tree/master/apps
    ['development', 'staging', 'production'].includes(config.environment)
  ) {
    Sentry.init(config)
  }
}

type Identifiers = {
  readonly id?: string;
  readonly ip_address?: string;

  // the following have been left to indicate they are available in sentry, but should not be
  // used due to pii security
  // readonly email?: string;
  // readonly username?: string;
}

export const setErrorLoggingIdentifiers = (identifiers: Identifiers) => {
  Sentry.configureScope((scope) => scope.setUser(identifiers))

}

export const resetErrorLoggingIdentifiers = () => Sentry.configureScope(scope => scope.clear())

type ExceptionArguments = {
  readonly error: Error | string;
  readonly errorInfo?: React.ErrorInfo;
}

export const captureException = ({ error, errorInfo }: ExceptionArguments) => {
  Sentry.withScope((scope) => {
    console.error({ error, errorInfo })
    errorInfo && scope.setExtras(errorInfo)
    typeof error === 'string' && scope.setLevel(Sentry.Severity.Error)
    Sentry.captureException(error)
  })
}
