import { BeaconFilterState, BeaconsList } from 'domains/beacons/store';
import { structuresSelector } from 'domains/structures';
import { mustStructureRef, StructureRef, Structures } from 'domains/structures/entities';

import { Beacon } from 'generated/mos/beacon';
import { Timestamp } from 'generated/webrpc'


// FIXME: there's a lot of overlap between this and the equivalent script in
// the spaces domain. There's probably something generalisable in here, but
// we need to retain the flexibility to change the rules for each domain
// independently of the other.

// Scopes shown in list view
export const visibleScopes = ['currentScope', 'unpositioned', 'otherScopes'];
export type VisibleScope = 'currentScope' | 'unpositioned' | 'otherScopes';

export type BeaconsData = {
  // Both the search and the activeRef are applied. Contains all positioned beacons if there
  // is no search or activeRef.
  readonly currentScope: BeaconsList;

  // Beacons that match the search term and have no position
  readonly unpositioned: BeaconsList;

  // Beacons that match the search term, have a position, and are not in currentScope
  readonly otherScopes: BeaconsList;

  // All beacons passed in, most recently updated first
  readonly allOrdered: BeaconsList;
};

export function filterBeacons(
  beacons: BeaconsList,
  structures: Structures,
  filters: Pick<BeaconFilterState, 'search'>,
  activeRef: StructureRef | undefined,
): BeaconsData {

  const allOrdered = beacons.filter((a) => a && true).sort((a, b) => {
    // order descending by last updated date; cheat by taking advantage of
    // the fact that ISO8601 dates sort lexically;
    if (b.metadata && b.metadata.modifiedAt && a.metadata && a.metadata.modifiedAt) {
      const modifiedAt: Timestamp = b.metadata.modifiedAt;
      return modifiedAt.value.localeCompare(a.metadata.modifiedAt.value);
    }
    return 0;
  });

  const searched = allOrdered.filter(filterBeaconsBySearchTerm(filters.search));

  const currentScope = activeRef ?
    searched.filter(filterBeaconsBySpatialScope(structures, activeRef)) :
    searched.filter((v) => !!v.position);

  const unpositioned = searched.filter((v) => !v.position);

  const currentSet = new Set(currentScope);
  const otherScopes = allOrdered.filter((v) => !currentSet.has(v) && !!v.position)
    .filter(filterBeaconsBySearchTerm(filters.search));

  return {
    currentScope,
    unpositioned,
    otherScopes,
    allOrdered,
  };
}

const filterBeaconsBySearchTerm = (searchTerm?: string) => (beacon: Beacon.Entity): boolean => {
  if (!searchTerm) {
    return true;
  }

  const s = searchTerm.trim().toLowerCase();
  return (
    // FIXME: assemble these from levels data once structures sagas are developed.
    // (beacon.subtitle || '').toLowerCase().includes(s) ||
    // (beacon.description || '').toLowerCase().includes(s) ||
    (beacon.name || '').toLowerCase().includes(s)
  );
};

const filterBeaconsBySpatialScope = (structures: Structures, activeRef: StructureRef | undefined) => (beacon: Beacon.Entity): boolean => {
  if (!activeRef) {
    return !!beacon;
  } else if (!beacon || !beacon.position || !beacon.position) {
    return false;
  }

  return structuresSelector.containsOrEquals(structures, activeRef,
    mustStructureRef(beacon.position.levelRef));
};
