import React, { PureComponent } from 'react'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'
import Dropzone from 'react-dropzone'
import { FormBag } from 'react-formage'

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { IconClose, IconSuccess, IconWarning } from 'components/ui/icons'

import { sharedActionCreators } from 'containers/shared'
import { connect, store } from 'containers/store'

import { ensureSession } from 'domains/account/actions'
import { AuthSession } from 'services'
import { getTokens } from 'domains/account/auth-session'

import { Checksum } from 'generated/mos/entity'
import { Media, MediaKind } from 'generated/mos/media'

import { defaultLanguage } from 'helpers/i18n'
import { bffEndpoint } from 'helpers/bff'
import { debounce, Invariant, pick } from 'helpers/core'
import { Status } from 'helpers/status'

import {
  DropArea,
  DropAreaText,
  DropAreaTextSmall,
  FileDetails,
  FileListItem,
  FileTitle,
  MediaDetails,
  ModalBody,
  ProgressArea,
  ProgressBar,
} from './styled'
import { FormValues } from '.'

/*
these constants are file format magic numbers:
  https://en.wikipedia.org/wiki/File_format#Magic_number
try to keep these groups aligned with the MEDIA_KINDs from the Media entity.
const FILE_TYPES = {
  AUDIO: [
    '66747970', // m4a
    '00020',    // m4a
    '00018',    // m4a
    '494433',   // mp3
  ],
  IMAGES: [
    'FFD8FFDB', // jpeg
    'FFD8FFE0', // jpeg
    '89504E47', // png
  ],
  VIDEO: [
    '66747970', // mp4
    '66747970', // m4v
    '00020', //m4v
  ],
}
*/

type DirectProps = {
  readonly bag: FormBag<FormValues>;
  readonly kind: MediaKind;
  readonly mediaItem: Media.Entity;
  readonly onUpdate: (details: Partial<Media.Entity>) => void;
  readonly updateCancelToken: (cancelToken: CancelTokenSource | undefined) => void;
}

type ActionProps = Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = DirectProps & ActionProps

type UploadStatus = Status.Idle | Status.Updating | Status.Ready | Status.Failed;

type State = {
  readonly uploadCancelSource?: CancelTokenSource;
  readonly uploadPercentage?: number;
  readonly uploadStatus?: UploadStatus;
}

type SignedUrlData = {
  readonly name: string;
  readonly signedUrl: string;
  readonly uuid: string;
}

const uploadPercentageOffset = 5 // offset progress bar by a percentage.

class UploadMediaComponent extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      uploadPercentage: uploadPercentageOffset,
      uploadStatus: Status.Idle,
    }
  }

  private onCancel = () => {
    const { uploadCancelSource: source } = this.state
    if (source && source.cancel) {
      source.cancel()
      this.setState({
        uploadPercentage: uploadPercentageOffset,
        uploadStatus: Status.Idle,
      })
    }
  }

  private onDrop = (files: ReadonlyArray<File>) => {
    const { onUpdate } = this.props
    const fileReader = new FileReader()

    // check file validity.
    files.map(file => {
      // extract first 4 bytes.
      const blob = file.slice(0, 4)
      fileReader.readAsArrayBuffer(blob)
      fileReader.onloadend = async (event: ProgressEvent<FileReader>) => {
        if (event && event.target && event.target.result) {
          // convert to hexadecimal.
          const uint = new Uint8Array(event.target.result as ArrayBuffer )

          const hashBuffer = await crypto.subtle.digest('SHA-256', uint)
          // convert buffer to byte array then hex string.
          const hashArray = Array.from(new Uint8Array(hashBuffer))
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

          const checksum = {
            type: Checksum.refName,
            method: 'CHECKSUM_METHOD_SHA256' as const,
            value: hashHex,
          }

          onUpdate({
            checksum,
          })

          let bytes: Array<string> = []
          uint.forEach((byte) => {
            bytes.push(byte.toString(16))
          })
          const hex = bytes.join('').toUpperCase()

          let supportedFileType = true

          /*
          Commented out until the backend can support validation.

          Checks for a matches against supported file types.

          switch (this.props.kind) {
            case 'MEDIA_KIND_AUDIO': {
              supportedFileType =
                FILE_TYPES.AUDIO.includes(hex) ||
                // check for mp3.
                FILE_TYPES.AUDIO.includes(hex.substr(0, 6))
              break
            }

            case 'MEDIA_KIND_RASTER_IMAGE': {
              supportedFileType = FILE_TYPES.IMAGES.includes(hex)
              break
            }

            case 'MEDIA_KIND_VIDEO': {
              supportedFileType = FILE_TYPES.VIDEO.includes(hex)
              break
            }

            case 'MEDIA_KIND_UNSPECIFIED':
            case 'MEDIA_KIND_BINARY':
            case 'MEDIA_KIND_VECTOR_IMAGE':
            default: {
              // file type is unsupported.
              this.props.toastNotification({ type: 'error', text: `File type of ${file.name} is not supported.` })
            }
          }
          */

          // upload file, if it is supported.
          if (supportedFileType) {
            this.uploadFileToS3(file)
          } else {
            this.props.toastNotification({ type: 'error', text: `File ${file.name} is not supported.` })
          }
        }
      }
    })
  }

  private uploadFileToS3 = async (file: File) => {
    const { onUpdate, toastNotification, updateCancelToken } = this.props
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    onUpdate({
      language: defaultLanguage(),
      mediaType: file.type,
      uploadedFilename: file.name,
    })

    updateCancelToken(source)

    this.setState({
      uploadCancelSource: source,
      uploadStatus: Status.Updating,
    })

    try {
      // get valid access token.
      await store.dispatch(ensureSession() as any) as AuthSession
      const tokens = getTokens()

      // attempt to fetch the signed url.
      const result: AxiosResponse<SignedUrlData> = await axios.get<SignedUrlData>(`${bffEndpoint(window.location.hostname)}/sign-media-upload`, {
        headers: {
          Authorization: tokens && tokens.access ? `bearer ${tokens.access}` : null
        },
        params: {
          contentType: file.type,
          objectName: file.name,
        }
      })

      // upload the file to s3 and return the file url.
      const { signedUrl } = result.data

      if (!signedUrl) {
        throw new Invariant('[ UploadMedia ] No signedUrl returned.')
      }

      try {
        await axios.put(signedUrl, file, {
          cancelToken: source.token,
          headers: {
            'Content-Type': file.type
          },
          onUploadProgress: debounce((progress: { loaded: number; total: number}) => {
            // update the progress for this file.
            this.setState({
              uploadPercentage: uploadPercentageOffset + Math.ceil(progress.loaded / progress.total * (100 - uploadPercentageOffset)),
            })
          }, 50)
        })

        // mark as complete and add the files final url.
        onUpdate({
          url: signedUrl.split('?')[0],
        })

        updateCancelToken(undefined)

        this.setState({
          uploadStatus: Status.Ready,
        })

      } catch (e) {
        // axios error, failed to upload file to s3.
        const error: AxiosError = e

        // non 2xx response received.
        if (error.response) {
          toastNotification({
            type: 'error',
            text: 'A failure occurred when attempting to uploading your file. Please try again.',
          })
        }
        // no response received.
        else if (error.request) {
          toastNotification({
            type: 'error',
            text: 'No response was received from the file storage service. Please try again.',
          })
        }
        // error sent from backend service.
        else if (error.message) {
          toastNotification({
            type: 'error',
            text: 'An error was received when attempting to uploading your file. Please try again.',
          })
        }
        // fetch api failure.
        else {
          toastNotification({
            type: 'error',
            text: 'There was a problem with your network connection. Please try again.',
          })
        }

        this.setState({
          uploadStatus: Status.Failed,
        })
      }

    } catch (e) {
      // axios error, failed to upload file to s3.
        const error: AxiosError = e

      // non 2xx response received.
      if (error.response) {
        toastNotification({
          type: 'error',
          text: 'A failure occurred when attempting to uploading your file. Please try again.',
        })
        this.setState({
          uploadStatus: Status.Failed,
        })
      }
      // no response received.
      else if (error.request) {
        toastNotification({
          type: 'error',
          text: 'No response was received from the file storage service. Please try again.',
        })
        this.setState({
          uploadStatus: Status.Failed,
        })
      }
      // error sent from backend service.
      else if (error.message) {
        // error.message is defined for network issues, but not if we cancel the axios request.
        //   we could create a message by passing a string to source.cancel() but then we can't
        //   switch toast notification types in a solid way.
        toastNotification({
          type: 'error',
          text: 'An error was received when attempting to uploading your file. Please try again.',
        })
        this.setState({
          uploadStatus: Status.Failed,
        })
      }
      // fetch api failure, or user intervention using `cancelToken`.
      else {
        toastNotification({ type: 'info', text: 'Your upload has been cancelled.' })
      }
    }
  }

  public render() {
    const { bag: { errors, touched }, mediaItem: { uploadedFilename } } = this.props
    const { uploadPercentage, uploadStatus } = this.state

    const isUploading = uploadStatus !== Status.Idle

    return !isUploading ? (
      <ModalBody>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <DropArea {...getRootProps()}>
              <DropAreaText>
                Drag a file here
                <DropAreaTextSmall>or</DropAreaTextSmall>
              </DropAreaText>
              <input {...getInputProps()} multiple={false} />
              <Button onClick={(event) => { event.preventDefault() }}>
                Choose from device
              </Button>
            </DropArea>
          )}
        </Dropzone>
      </ModalBody>
    ) : (
      <ModalBody>
        <FileListItem>
          <FileDetails>
            <FileTitle>{uploadedFilename}</FileTitle>
          </FileDetails>
          {uploadStatus === Status.Updating && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProgressArea>
                <ProgressBar percentage={uploadPercentage} />
              </ProgressArea>
              <Button
                appearance="secondary"
                isIcon={true}
                isSmall={true}
                onClick={this.onCancel}
              >
                <IconClose size="small" />
              </Button>
            </div>
          )}
          {uploadStatus === Status.Ready && (
            <IconSuccess color="success" />
          )}
          {uploadStatus === Status.Failed && (
            <IconWarning color="warning" />
          )}
        </FileListItem>

        <MediaDetails>
          <Input
            label="Title"
            labelSupportingText="(optional)"
            field="title"
            error={touched.title ? errors.title : undefined}
          />
          <Input
            label="Internal notes"
            labelSupportingText="(optional)"
            field="internalNotes"
            component="textarea"
            error={touched.internalNotes ? errors.internalNotes : undefined}
          />
        </MediaDetails>
      </ModalBody>
    )
  }
}

export const UploadMedia = connect<{}, ActionProps, DirectProps>(
  () => ({}),
  pick(sharedActionCreators, 'toastNotification')
)(UploadMediaComponent)
