import { DrawPolygon, ModeInterface, ModeInterfaceAccessors } from '@mapbox/mapbox-gl-draw';

import * as geo from 'helpers/geo';

import { adminMapMove, createSupplementaryPoints } from './helpers';

const isVertex = (e: any) => (e && e.featureTarget && e.featureTarget.properties ?
  e.featureTarget.properties.meta === 'vertex' :
  false);

const isFeature = (e: any) => (e && e.featureTarget && e.featureTarget.properties ?
  e.featureTarget.properties.meta === 'feature' :
  false);

const getTransformOriginCoordPath = (coordPath: string): [number, number] | undefined => {
  if (coordPath === '0.3') { return [0, 1]; }
  if (coordPath === '0.2') { return [0, 0]; }
  if (coordPath === '0.1') { return [0, 3]; }
  if (coordPath === '0.0') { return [0, 2]; }
  return;
};

const getTransformOrigin = (rectangle: any, selectedVertex: any): geo.Position2D | undefined => {
  const path = getTransformOriginCoordPath(selectedVertex.properties.coord_path);
  if (!path) {
    return;
  }
  return rectangle.coordinates[path[0]][path[1]];
};

// FIXME: weak typing
interface State {
  featureId: string;
  rectangle: geo.MutablePolygon2D & DrawPolygon;
  canDragMove: boolean;
  canResize: boolean;
  dragMoving?: boolean;
  dragMoveStructure?: any;
  transformOrigin?: geo.Position2D;
  originalPolygon?: geo.MutablePolygon2D & DrawPolygon;
  selectedVertex?: any;
  transformLastDistance?: any;

  resetState?: any; // not sure why this is here; see resetState
}

interface Options {
  readonly key: string;
}

interface SetupOptions {
  readonly featureId: string;
}

type EventKind = 'transformEnd' | 'transformStart' | 'transform';

export function drawEventKey(key: string, name: EventKind): string {
  return `draw.adminmap.${key}.${name}`;
}

export function drawMode(opts: Options): any {
  const eventKey = (name: EventKind): string => {
    return drawEventKey(opts.key, name);
  };

  return {
    onSetup(setupOpts: SetupOptions): State {
      const { featureId } = setupOpts;

      this.updateUIClasses({ mouse: 'move' });

      const rectangle = this.getFeature(featureId);
      const state = {
        featureId,
        rectangle,
        canDragMove: false,
        canResize: false,
      };

      return state;
    },

    resetState(state: State) {
      state.canDragMove = false;
      state.canResize = false;

      // FIXME: MUTATING MAP! DANGEROUS!
      this.map.dragPan.enable();

      this.map.fire(eventKey('transformEnd'));
    },

    onClick(state: State, e: any) {
      this.resetState(state);
    },

    onMouseDown(state: State, e: any) {
      if (isVertex(e)) {
        this.map.fire(eventKey('transformStart'));

        // There is a bug where mouse up is not triggered on a single click
        // This timout will clear if immediately dragged.
        return this.clickOnVertex(state, e);

      } else if (isFeature(e)) {
        this.map.fire(eventKey('transformStart'));
        return this.clickOnFeature(state, e);
      }
    },

    onMouseUp(state: State, e: any) {
      this.resetState(state);
    },

    onDrag(state: State, e: any) {
      if (state.canDragMove) {
        state.dragMoving = true;
        e.originalEvent.stopPropagation();

        const delta = {
          lng: e.lngLat.lng - state.dragMoveStructure.lng,
          lat: e.lngLat.lat - state.dragMoveStructure.lat,
        };

        adminMapMove(state.rectangle, delta);
        state.dragMoveStructure = e.lngLat;
      }

      if (state.canResize) {
        if (!state.transformOrigin) {
          throw new Error();
        }
        const newDistance = geo.kilometers(state.transformOrigin, [e.lngLat.lng, e.lngLat.lat]);

        const scaleFactor = newDistance / state.transformLastDistance;
        state.transformLastDistance = newDistance;

        const scaledPolygon = geo.transformScale2D(state.originalPolygon, scaleFactor, state.transformOrigin);
        if (scaledPolygon) {
          state.rectangle.updateCoordinate(`0.0`, scaledPolygon.coordinates[0][0][0], scaledPolygon.coordinates[0][0][1]);
          state.rectangle.updateCoordinate(`0.1`, scaledPolygon.coordinates[0][1][0], scaledPolygon.coordinates[0][1][1]);
          state.rectangle.updateCoordinate(`0.2`, scaledPolygon.coordinates[0][2][0], scaledPolygon.coordinates[0][2][1]);
          state.rectangle.updateCoordinate(`0.3`, scaledPolygon.coordinates[0][3][0], scaledPolygon.coordinates[0][3][1]);
        }
      }
      const coords = state.rectangle.coordinates[0];

      this.map.fire(eventKey('transform'), {
        coords: coords.slice(0, 4),
        rectangle: { ...state.rectangle },
        area: geo.squareMeters(state.rectangle),
      });
    },

    onMouseMove(state: State, e: any) {},

    onStop(state: State) {},

    clickOnVertex(state: State, e: any) {
      // FIXME: MUTATING MAP! DANGEROUS!
      this.map.dragPan.disable(); // Disable map.dragPan immediately so it can't start

      state.canResize = true;
      state.originalPolygon = { ...state.rectangle };
      state.selectedVertex = e.featureTarget;
      state.transformOrigin = getTransformOrigin(state.rectangle, e.featureTarget);
      if (!state.transformOrigin) {
        throw new Error();
      }

      const path = (state.selectedVertex.properties.coord_path as string).split('.').map(Number);

      state.transformLastDistance = geo.kilometers(
        state.rectangle.coordinates[path[0]][path[1]],
        state.transformOrigin,
      );
    },

    clickOnFeature(state: State, e: any) {
      // FIXME: MUTATING MAP! DANGEROUS!
      this.map.dragPan.disable(); // Disable map.dragPan immediately so it can't start

      // Re-render it and enable drag move
      this.doRender(state.featureId);
      state.canDragMove = true;
      state.dragMoveStructure = e.lngLat;
    },

    toDisplayFeatures(state: State, geojson: geo.Feature2D<geo.Geometry2D>, display: (obj: geo.Feature2D<geo.Geometry2D>) => void) {
      const properties = geojson.properties || {};

      // FIXME: geo assertion functions
      if (geojson.geometry.type !== 'Polygon') {
        console.log('received unsupported feature:', geojson);
        return;
      }

      // 'active' is required to remove the blue-styled border from the feature:
      geojson = { ...geojson, properties: { ...properties, active: 'true' } };
      display(geojson);

      for (const point of createSupplementaryPoints(geojson as geo.Feature2D<geo.Polygon2D>)) { // FIXME: geo-jank
        display(point);
      }
    },
  };
}
