// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Beacon } from "generated/mos/beacon";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated } from "generated/webrpc";

export namespace GetBeaconRequest {
  export const refName = "mos.beacon_access.GetBeaconRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beaconRef: { readonly typename: 'mos.beacon.Beacon', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    beaconRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_access.GetBeaconRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beaconRef"] = v["beaconRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetBeaconResponse {
  export const refName = "mos.beacon_access.GetBeaconResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beacon: Beacon.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    beacon: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["beacon"] = Beacon.codec.encode(v.beacon);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_access.GetBeaconResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beacon"] = ctx.decode(Beacon.codec, v["beacon"], "beacon");
      return out as any as Entity;
    }
  }();
}

export namespace ListBeaconsRequest {
  export const refName = "mos.beacon_access.ListBeaconsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_access.ListBeaconsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace ListBeaconsResponse {
  export const refName = "mos.beacon_access.ListBeaconsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly beacons: ReadonlyArray<Beacon.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    beacons: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["beacons"] = [];
      for (const item of v.beacons) {
        obj["beacons"].push(Beacon.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon_access.ListBeaconsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["beacons"] = decodeMessageRepeated(ctx, Beacon.codec, v["beacons"], "beacons");
      return out as any as Entity;
    }
  }();
}

