import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import { FluxStandardAction, FluxStandardErrorAction } from '.'
import { UploadRequest } from '../types'

export const uploadActionCreators = {
  wamUploadRequest: (
    payload: ReadonlyArray<File>,
  ): FluxStandardAction<UploadRequest, 'wamUploadRequest'> => ({
    domain: 'wam.upload' as const,
    type: 'wamUploadRequest' as const,
    payload,
  }),
  wamUploadSuccess: (payload: any): FluxStandardAction<any, 'wamUploadSuccess'> => ({
    domain: 'wam.upload' as const,
    type: 'wamUploadSuccess' as const,
    payload,
  }),
  wamUploadFailure: (payload: Error): FluxStandardErrorAction<'wamUploadFailure'> => ({
    domain: 'wam.upload' as const,
    type: 'wamUploadFailure' as const,
    payload,
    error: true,
  }),
  wamUploadReset: (): FluxStandardAction<undefined, 'wamUploadReset'> => ({
    domain: 'wam.upload' as const,
    type: 'wamUploadReset' as const,
    payload: undefined
  }),
}

type WamAction = ReturnType<typeof uploadActionCreators[keyof typeof uploadActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.upload'
}

export const uploadReducer = (
  state: WamAppState['upload'] = initialWamAppState.upload,
  action: WamAction,
): WamAppState['upload'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamUploadReset': {
      return {
        ...state,
        status: Status.Idle,
      }
    }
    case 'wamUploadRequest': {
      return {
        ...state,
        status: Status.Loading,
      }
    }
    case 'wamUploadSuccess': {
      const { payload } = action
      return {
        ...state,
        data: payload,
        status: Status.Ready,
      }
    }
    case 'wamUploadFailure': {
      const { payload } = action
      return {
        ...state,
        messages: [failureStatusMessage(payload.toString())],
        status: Status.Failed,
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
