import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button, ButtonGroup } from 'components/ui/button'
import { StatusGuard } from 'components/ui/status-guard';
import { Modal } from 'components/ui/modal';
import { connect } from 'containers/store';
import { Beacon } from 'generated/mos/beacon'
import { CreateBeaconRequest } from 'generated/mos/beaconmanagement'
import { pick } from 'helpers/core';
import { Status } from 'helpers/status';

import { actionCreators } from '../actions';
import * as profiles from '../profiles';
import { DataStatus } from '../store';

import { assembleIdentificationProfile, BeaconFormLayout, FormValues } from './beacon-form-layout';

type DirectProps = {
  readonly onClose: () => void;
  readonly onSuccess: (e: {ref: Beacon.Ref}) => void;
};

type ConnectedProps = {
  readonly beaconCreate: DataStatus<Beacon.Ref>;
  readonly beaconLastProfileType?: profiles.ProfileType;
};

type ActionProps = Pick<typeof actionCreators, 'beaconsCreateBeaconRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

const fieldReset: FormValues = {
  name: '',
  addAnother: false,
  profileType: profiles.KONTAKT_PROFILE,
  kontaktUniqueId: '',
  genericLocalName: '',
  iBeaconMajor: '',
  iBeaconMinor: '',
  iBeaconUuid: '',
  snapToPosition: false,
};

class BeaconCreateFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag(fieldReset),
    };
  }

  public componentDidUpdate() {
    if (this.props.beaconCreate.status === Status.Ready) {
      if (this.props.onSuccess) {
        const { beaconCreate: { data: ref } } = this.props
        this.props.onSuccess({ ref });
      }
    }
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    if (!values.profileType.trim()) {
      errors.profileType = 'Required';

    } else if (values.profileType === profiles.GENERIC_PROFILE) {
      // Nothing to validate

    } else if (values.profileType === profiles.KONTAKT_PROFILE) {
      // Nothing to validate

    } else if (values.profileType === profiles.IBEACON_PROFILE) {
      if (values.iBeaconMajor) {
        const result = Number(values.iBeaconMajor);
        if (isNaN(result) || result < 0 || result > 65535 || result % 1 > 0.000001) {
          errors.iBeaconMajor = 'Must be whole number from 0 to 65535';
        }
      }

      if (values.iBeaconMinor) {
        const result = Number(values.iBeaconMinor);
        if (isNaN(result) || result < 0 || result > 65535 || result % 1 > 0.000001) {
          errors.iBeaconMinor = 'Must be whole number from 0 to 65535';
        }
      }

    } else {
      throw new Error(`Unexpected profile type ${values.profileType}`);
    }

    return errors;
  }

  private submit = (addAnother=false) => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    const { values } = bag;
    const { beaconsCreateBeaconRequest } = this.props;

    const payload: CreateBeaconRequest.Entity = {
      ...CreateBeaconRequest.defaults,
      name: values.name,
      identificationProfile: assembleIdentificationProfile(values),
      snapToPosition: values.snapToPosition,
    };

    beaconsCreateBeaconRequest(payload);

    // if we're not adding another, close the form.
    !addAnother && this.props.onClose()
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public render() {
    const { valid } = this.state.bag;
    const loading = this.props.beaconCreate.status === Status.Updating;

    return (
      <Modal onClose={this.props.onClose} header={<h2>Create A New Beacon</h2>}>
        <form noValidate onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
          <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
            <StatusGuard status={this.props.beaconCreate} mode="form">
              <BeaconFormLayout bag={this.state.bag} />
            </StatusGuard>

            <ButtonGroup style={{ justifyContent: 'flex-end' }}>
              <Button type="submit" isDisabled={!valid || loading}>
                {loading ? 'Creating...' : 'Create beacon'}
              </Button>

              <Button
                type="button"
                key="another"
                appearance="secondary"
                isDisabled={!valid || loading}
                onClick={() => this.submit(true)}
              >
                {loading ? 'Creating...' : 'Create and add another'}
              </Button>
            </ButtonGroup>
          </FormData>
        </form>
      </Modal>
    );
  }
}

export const BeaconCreateForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.beacons, 'beaconCreate', 'beaconLastProfileType'),
  pick(actionCreators, 'beaconsCreateBeaconRequest'),
)(BeaconCreateFormView);
