import React from 'react'

// NOTE: most of this file can be code-generated from mos-proto; this is a
// medium-term concern though.

export type Entity<TRefType=string> = {
  ref: Ref<TRefType>;
};

export type NamedEntity<TRefType=string> = Entity<TRefType> & {
  name: string;
};

export type HyperLink<TRefType=string> = {
  url: string;
  displayText: string;
};

export type Ref<T=string> = {
  typename: T;
  id: string;
};

export type BeaconRef = { typename: 'mos.beacon.Beacon'; id: string };


export function isBeaconRef(ref: Ref | undefined): ref is BeaconRef {
  return !!ref && ref.typename === 'mos.beacon.Beacon';
}

export function mustBeaconRef(ref: Ref | undefined): BeaconRef {
  // NOTE: any here is used because ensureRefType guarantees everything we need to guarantee.
  ensureRefType(ref, 'mos.beacon.Beacon'); return ref as any;
}

export function isRefEqual(a: Ref | undefined | null, b: Ref | undefined | null): boolean {
  if (a && b) {
    return a.typename === b.typename && a.id === b.id;
  } else {
    return !!a === !!b;
  }
}

// This type is "documentation only"; there's actually no way for typescript
// to enforce this:
export type RefUrn = string;

export function refToUrn(ref: Ref): RefUrn {
  if (!isValidTypename(ref.typename)) {
    throw new Error(`refToUrn: invalid typename ${ref.typename}`);
  }
  if (!ref.id) {
    throw new Error(`refToUrn: missing id for typename ${ref.typename}`);
  }
  return `mos:${ref.typename}:${ref.id}`;
}

// refToDebugUrn will convert any ref (as well as undefined or null) to a
// string for debug purposes only.
export function reffishToDebugUrn(reffish?: Reffish | null): RefUrn {
  const ref = reffish ? asRef(reffish) : undefined;
  return ref ? `mos:${ref.typename}:${ref.id}` : 'mos:<empty>';
}

// refFromValidUrn is used when you can guarantee that the input ref is in the
// correct format. If you can't absolutely make this guarantee, use refFromUrn.
export function refFromValidUrn(urn: RefUrn): Ref {
  // FIXME: could look for '(?i)urn:' at the start of the input and strip if found

  const parts = urn.slice(4);
  const delim = urn.indexOf(':');
  return { typename: parts.slice(0, delim), id: parts.slice(delim + 1) };
}

// FIXME: As of 2019-01, this is not concretely specified. This check has been
// introduced to catch bugs and omissions while refactoring, but may create
// problems later; a concrete specification is needed.
const typenamePattern = /^mosx?(\.([a-zA-Z])([a-zA-Z0-9.]*)([a-zA-Z0-9])+)$/;

export function refFromUrn(urn: RefUrn): Ref {
  // FIXME: could look for '(?i)urn:' at the start of the input and strip if found

  if (!urn.startsWith('mos:')) {
    throw new Error(`invalid mos urn '${urn}': missing nsid`);
  }

  const parts = urn.slice(4); // 4 == 'mos:'.length

  const delim = parts.indexOf(':');
  if (delim < 0) {
    throw new Error(`invalid mos URN '${parts}': invalid format`);
  }

  const ref = { typename: parts.slice(0, delim), id: parts.slice(delim + 1) };
  if (!isValidTypename(ref.typename)
    || ref.typename.startsWith('mos.mos') // This is a canary; it's not in the spec.
    || !typenamePattern.test(ref.typename)
  ) {
    throw new Error(`invalid mos URN '${parts}': type '${ref.typename}' invalid`);
  }

  if (!ref.id) {
    throw new Error(`invalid mos URN '${parts}': id missing`);
  }

  return ref;
}

function isValidTypename(name: string): boolean {
  return name.startsWith('mos.') || name.startsWith('mosx.');
}

type LooseRef = {
  id: string | undefined | null;
  typename: string | undefined | null;
};

type Reffish = RefUrn | Ref | LooseRef;

// asRef is used to coerce Reffish things into a canonicalised Ref object.
//
// If the input is falsey, undefined is returned. For a strict variant,
// see mustRef, which will fail if the input is falsey.
//
// Use it for objects coming in from the GraphQL API, for example, which
// contain nullable 'id' and 'typename' fields (because they may actually
// be null). asRef will assert that they contain a real value.
export function asRef(reffish: Reffish | undefined | null): Ref | undefined {
  if (!reffish) {
    return undefined;
  }
  if (typeof reffish === 'string') {
    return refFromUrn(reffish);
  }
  if (!reffish.typename || !reffish.id) {
    throw new Error(`incomplete ref: ${JSON.stringify(reffish)}`);
  }

  // These are known to be strings by this point, TypeScript just hasn't realised:
  return reffish as Ref;
}

// mustRef is used to coerce Reffish things into a canonicalised Ref object.
//
// If the input is falsey, an error is raised. See asRef for a loose variant.
export function mustRef(reffish: Reffish | undefined | null): Ref {
  const ref = asRef(reffish);
  if (!ref) {
    throw new Error('empty ref');
  }
  return ref;
}

function ensureRefType(ref: Ref | undefined, refType: string | string[]): void {
  if (ref) {
    if (Array.isArray(refType)) {
      if (refType.indexOf(ref.typename) >= 0) {
        return;
      }
    } else if (ref.typename === refType) {
      return;
    }
  }
  throw new Error(`ref ${reffishToDebugUrn(ref)} is not a ${refType}`);
}

export function useSelectedRefs<T extends Ref>(initialRefs: ReadonlyArray<T>) {
  const [selected, setSelected] = React.useState(initialRefs);

  const includes = (refToFind?: T): boolean => {
    if (!refToFind) return false;
    return !!selected.filter(ref => ref.id === refToFind.id).length;
  };

  const toggle = (toggledRef?: T) => {
    if (!toggledRef) return;
    if (includes(toggledRef)) {
      setSelected(selected.filter(ref => ref.id !== toggledRef.id));
    } else {
      setSelected([ ...selected, toggledRef ]);
    }
  };

  return { selected, setSelected, toggle, includes };
}
