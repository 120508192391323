import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'

import { ClearHoldingAudioResponse, ListHoldingAudioResponse } from '../types'
import { holdingAudioActionCreators } from '../actions/holding-audio'

const listHoldingAudioSaga = wamTakeLeading('wamListHoldingAudioRequest', function* (action) {
  try {
    const response: ListHoldingAudioResponse =
      yield* httpRest<undefined, ListHoldingAudioResponse>('floors/', undefined, 'GET')

    if (!response) {
      throw new Invariant('[ listHoldingAudioSaga ] Missing response payload')
    }

    yield put(holdingAudioActionCreators.wamListHoldingAudioSuccess(response))

  } catch (error) {
    yield put(holdingAudioActionCreators.wamListHoldingAudioFailure(error.message))
  }
})

const clearHoldingAudioSaga = wamTakeLeading('wamClearHoldingAudioRequest', function* (action) {
  try {
    const response: ClearHoldingAudioResponse =
      yield*
      httpRest<undefined, ClearHoldingAudioResponse>(
        `floors/${action.payload.ecmsFloorId}/clear_holding_audio`,
        undefined,
        'PUT',
      )

    if (!response) {
      throw new Invariant('[ deleteHoldingAudioSaga ] Missing response payload')
    }

    yield put(holdingAudioActionCreators.wamClearHoldingAudioSuccess(response))
    yield put(holdingAudioActionCreators.wamClearHoldingAudioReset())

  } catch (error) {
    yield put(holdingAudioActionCreators.wamClearHoldingAudioFailure(error.message))
  }
})

export const holdingAudioSagas: ReadonlyArray<Effect> = [
  listHoldingAudioSaga,
  clearHoldingAudioSaga,
]
