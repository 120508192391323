import React from 'react'

import { buttonReset } from 'components/ui/button'
import { IconPause, IconPlay } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled from 'styled';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grayL80};
  font-weight: ${({ theme }) => theme.font.weight.light};
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: ${space(2)} ${space(4)} ${space(2)} ${space(2)};
`;

const PlayerButton = styled.button`
  ${buttonReset}
  height: auto;
  padding: ${space(2)};
`;

const ProgressContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.grayL80};
`;

const ProgressBar = styled.div<{ ratio: number }>`
  height: 8px;
  width: ${({ ratio }) => ratio * 100}%;
  background-color: ${({ theme }) => theme.color.primary};
`;

const Times = styled.div`
 display: flex;
 justify-content: space-between;
`

type TimeStates = {
  current: number;
  duration: number;
};

type Props = {
  name?: string;
  src: string;
};

export const AudioPlayer = ({ name, src }: Props) => {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [time, setTime] = React.useState<TimeStates>({ current: 0, duration: 0 });
  const playerRef = React.useRef<HTMLAudioElement>(null);

  const updateListener = (event: React.SyntheticEvent<HTMLAudioElement>) =>
    setTime({ current: event.currentTarget.currentTime, duration: event.currentTarget.duration });

  const toggleIsPlaying = () => {
    if (playerRef.current) {
      isPlaying ? playerRef.current.pause() : playerRef.current.play();
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time: number) =>
    !time ? '0:00' : Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);

  return (
    <Wrapper>
      <audio
        src={src}
        ref={playerRef}
        onTimeUpdate={updateListener}
        onDurationChange={updateListener}
        preload="metadata"
      />

      <PlayerButton onClick={toggleIsPlaying} type="button">
        {isPlaying ? (
          <IconPause size="large" color="primary" />
        ) : (
          <IconPlay size="large" color="gray" />
        )}

      </PlayerButton>

      <MainColumn>
        <span>{name}</span>
        <ProgressContainer>
          <ProgressBar ratio={time.current === 0 ? 0 : time.current / time.duration} />
        </ProgressContainer>
        <Times>
          <span>{formatTime(time.current)}</span>
          <span>{formatTime(time.duration)}</span>
        </Times>
      </MainColumn>
    </Wrapper>
  );
};
