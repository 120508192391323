/* eslint @typescript-eslint/no-unused-vars: "error" */

import { MapView } from 'components/mapbox';
import { SharedAppState } from 'containers/shared';
import { BuildingLevel, Building, Site, AdminMap } from 'generated/mos/structure';
import { getCenter } from 'helpers/locate'
import {
  DataStatus as GenericDataStatus,
  EntityStatus as GenericEntityStatus,
  Status,
  Message,
} from 'helpers/status';

import { StructureFilterState } from '.';
import * as structureEntities from './entities';

type StructureErrorCode = string;
type StructuresErrorCode = string;
export type StructuresMessage = Message<StructuresErrorCode>;
export type DataStatus<T> = GenericDataStatus<T, StructureErrorCode>;
export type EntityStatus<T extends object, TRef extends string> =
  GenericEntityStatus<T, StructureErrorCode, TRef>;

export type GlobalMapState = {
  readonly activeRef: structureEntities.StructureRef | undefined;
  readonly view: MapView;
};

export type StructuresAppState = {
  readonly siteCreate: DataStatus<Site.Ref>;
  readonly siteEdit: EntityStatus<
    {entity: Site.Entity; adminMapEntity: AdminMap.Entity | null},
    Site.Ref['typename']
  >;

  readonly buildingCreate: DataStatus<Building.Ref>;
  readonly buildingEdit: EntityStatus<Building.Entity, Building.Ref['typename']>;

  readonly buildingLevelCreate: DataStatus<BuildingLevel.Ref>;
  readonly buildingLevelEdit: EntityStatus<
    { entity: BuildingLevel.Entity; adminMapEntity: AdminMap.Entity | null },
    BuildingLevel.Ref['typename']
  >;

  readonly structures: DataStatus<structureEntities.Structures>;
  readonly globalMap: GlobalMapState;
  readonly structureFilters: StructureFilterState;
};

export const initialStructuresAppState: StructuresAppState = {
  buildingCreate: { status: Status.Idle },
  buildingEdit: { status: Status.Idle },
  buildingLevelCreate: { status: Status.Idle },
  buildingLevelEdit: { status: Status.Idle },
  siteCreate: { status: Status.Idle },
  siteEdit: { status: Status.Idle },
  structureFilters: { expanded: {} },
  structures: { status: Status.Idle },
  globalMap: {
    activeRef: undefined,
    view: {
      center: getCenter(), // FIXME: should this go here?
      zoom: 16,
    },
  },
};

export type StructuresStore = {
  readonly structures: StructuresAppState;
  readonly shared: SharedAppState;
};
