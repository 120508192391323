import React, { PureComponent } from 'react'
import { CancelTokenSource } from 'axios'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { Button, ButtonGroup } from 'components/ui/button'
import { Modal } from 'components/ui/modal'
import { StatusGuard } from 'components/ui/status-guard'

import { sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { Language } from 'generated/mos/i18n'
import { Media, MediaKind } from 'generated/mos/media'
import { CreateMediaRequest } from 'generated/mos/mediamanagement'

import { pick } from 'helpers/core'
import { Status } from 'helpers/status'

import { actionCreators } from '../../actions'
import { DataStatus } from '../../store'
import { MediaLibrary } from './media-library'
import { UploadMedia } from './upload-media'
import { TabWrapper } from './styled'

type ConnectedProps = {
  readonly mediaCreate: DataStatus<Media.Entity>;
}

type DirectProps = {
  readonly kind: MediaKind;
  readonly language: Language.Entity;
  readonly onClose: () => void;
  readonly onSave: (entity: Media.Entity) => void;
}

type ActionProps =
  Pick<typeof actionCreators, 'mediaCreateMediaRequest' | 'mediaCreateMediaReset'> &
  Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = ConnectedProps & DirectProps & ActionProps;

export type FormValues = {
  readonly internalNotes: string;
  readonly title: string;
};

type State  = {
  readonly bag: FormBag<FormValues>;
  readonly mediaItem: Media.Entity;
  readonly selectedTabIndex: number;
  readonly uploadCancelSource?: CancelTokenSource;
}

class MediaPickerComponent extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      bag: createFormBag({
        internalNotes: '',
        title: '',
      }),
      mediaItem: {
        ...Media.defaults,
        internalNotes: '',
        kind: this.props.kind,
        title: '',
      },
      selectedTabIndex: 0,
    }
  }

  public componentDidUpdate() {
    const { mediaCreate, mediaCreateMediaReset, onClose, onSave, toastNotification } = this.props

    // if creating a media entity in the dmm was successful...
    if (mediaCreate.status === Status.Ready) {
      const mediaEntity = mediaCreate.data

      // reset the mediaCreate status and close the modal.
      onSave(mediaEntity)
      mediaCreateMediaReset()
      onClose()
    }

    if (mediaCreate.status === Status.Failed) {
      toastNotification({ type: 'error', text: 'There was a problem saving your file.' })
      mediaCreateMediaReset()
    }
  }

  private onClose = () => {
    const { uploadCancelSource: source } = this.state

    if (source && source.cancel) {
      source.cancel()
    }

    this.props.onClose()
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag })

    if (e.bag.valid) {
      this.updateMediaItem(e.bag.values)
    }
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {}
    return errors
  }

  private save = () => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate)
    this.setState({ bag })

    if (!bag.valid) {
      return
    }

    const { language, mediaCreateMediaRequest, onClose, onSave } = this.props

    if (!Media.isRef(this.state.mediaItem.ref)) {
      const payload = {
        ...this.state.mediaItem,
        importMedia: false,
        language,
        type: CreateMediaRequest.refName,
        kind: this.props.kind,
      }

      mediaCreateMediaRequest(payload)

    } else {
      onSave(this.state.mediaItem)
      onClose()
    }
  }

  private onTabChange = (tabIndex: number) => {
    const { mediaCreateMediaReset } = this.props
    mediaCreateMediaReset()

    // reset our edit state of a media entity. if one was just created, it now becomes abandoned.
    this.setState({
      mediaItem: {
        ...Media.defaults,
        ref: undefined,
        internalNotes: '',
        title: '',
        url: '',
      },
      selectedTabIndex: tabIndex,
    })

    return true
  }

  private updateCancelToken = (cancelToken: CancelTokenSource | undefined) => {
    this.setState({
      uploadCancelSource: cancelToken,
    })
  }

  private updateMediaItem = (updates: Partial<Media.Entity>) => {
    this.setState({
      mediaItem: {
        ...this.state.mediaItem,
        ...updates,
      }
    })
  }

  public render() {
    const { kind } = this.props
    const { mediaItem, selectedTabIndex } = this.state
    const loading = this.props.mediaCreate.status === Status.Loading
    const uploadInProgress = mediaItem.uploadedFilename.length && selectedTabIndex === 0
    const { url, remoteUrl } = mediaItem

    const footer = (
      <ButtonGroup>
        <Button
          type="button"
          key="cancel"
          appearance="secondary"
          onClick={(event) => { event.preventDefault(); this.onClose(); }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isDisabled={loading || url === '' &&  remoteUrl === ''}
          onClick={() => this.save()}
        >
          {loading ? 'Adding...' : 'Add media'}
        </Button>
      </ButtonGroup>
    )

    return (
      <Modal onClose={this.onClose} header={<h3>Add media</h3>} footer={footer}>
        <form noValidate onSubmit={(event) => { event.preventDefault(); this.save(); }}>
          <TabWrapper>
            <Tabs onSelect={this.onTabChange}>
              <div className={uploadInProgress ? 'is-hidden' : ''}>
                <TabList>
                  <Tab>Upload file</Tab>
                  <Tab>Media manager</Tab>
                </TabList>
              </div>
              <TabPanel>
                <StatusGuard status={this.props.mediaCreate} mode="form">
                  <FormData
                    bag={this.state.bag}
                    onUpdate={this.onFormUpdate}
                    validate={this.onFormValidate}
                  >
                    <UploadMedia
                      bag={this.state.bag}
                      kind={kind}
                      mediaItem={mediaItem}
                      onUpdate={this.updateMediaItem}
                      updateCancelToken={this.updateCancelToken}
                    />
                  </FormData>
                </StatusGuard>
              </TabPanel>
              <TabPanel>
                <MediaLibrary
                  kind={kind}
                  mediaItem={mediaItem}
                  onUpdate={this.updateMediaItem}
                />
              </TabPanel>
            </Tabs>
          </TabWrapper>
        </form>
      </Modal>
    );
  }
}

export const MediaPicker = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.media, 'mediaCreate'),
  {
    ...pick(actionCreators, 'mediaCreateMediaRequest', 'mediaCreateMediaReset'),
    ...pick(sharedActionCreators, 'toastNotification'),
  }
)(MediaPickerComponent)
