// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Metadata } from "generated/mos/entity";
import { PlacedPoint } from "generated/mos/geo";
import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureScalar } from "generated/webrpc";

export namespace Beacon {
  export type Ref = { readonly typename: 'mos.beacon.Beacon', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.beacon.Beacon');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Beacon`);
    return v;
  }
  export const refName = "mos.beacon.Beacon" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.beacon.Beacon', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // Name is not required. If a name is not present, the "name" should be
    // inferred at the presentation site by the identification profile if
    // one is set.
    readonly name: string,
    // Identifies a beacon using manufacturer-specific information. This may not
    // be set, and may not be unique from a data-model point of view (though
    // conceptually the data should probably be unique). This is to support user
    // workflows that may require incomplete or inconsistent data to be entered
    // pending a "publication" step which would validate the constraints.
    readonly identificationProfile: IdentificationProfile.Entity | undefined,
    // A beacon is considered "unpositioned" if this field is zero-length.
    // Unpositioned beacons may be attached to objects whose position is not
    // fixed,
    readonly position: PlacedPoint.Entity | undefined,
    // When 'snap_to_position' is true, locate will snap the location to that of
    // this beacon when this beacon is the nearest beacon detected.
    // This is useful for environments when there are not enough beacons installed to
    // triangulate.
    readonly snapToPosition: boolean,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    name: "",
    identificationProfile: undefined,
    position: undefined,
    snapToPosition: false,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["name"] = v.name;
      obj["identificationProfile"] = IdentificationProfile.codec.encode(v.identificationProfile);
      obj["position"] = PlacedPoint.codec.encode(v.position);
      obj["snapToPosition"] = v.snapToPosition;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon.Beacon");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["identificationProfile"] = ctx.decode(IdentificationProfile.codec, v["identificationProfile"], "identificationProfile");
      out["position"] = ctx.decode(PlacedPoint.codec, v["position"], "position");
      out["snapToPosition"] = ensureScalar(ctx, v["snapToPosition"], "boolean");
      return out as any as Entity;
    }
  }();
}

export namespace GenericNameIdentificationProfile {
  export const refName = "mos.beacon.GenericNameIdentificationProfile" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly localName: string,
  }
  export const defaults: Entity = {
    type: refName,
    localName: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon.GenericNameIdentificationProfile");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["localName"] = ensureScalar(ctx, v["localName"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace IBeaconIdentificationProfile {
  export const refName = "mos.beacon.IBeaconIdentificationProfile" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly uuid: string,
    readonly major: number,
    readonly minor: number,
  }
  export const defaults: Entity = {
    type: refName,
    uuid: "",
    major: 0,
    minor: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon.IBeaconIdentificationProfile");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["uuid"] = ensureScalar(ctx, v["uuid"], "string");
      out["major"] = ensureScalar(ctx, v["major"], "number");
      out["minor"] = ensureScalar(ctx, v["minor"], "number");
      return out as any as Entity;
    }
  }();
}

export namespace IdentificationProfile {
  export const refName = "mos.beacon.IdentificationProfile" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly profile: IBeaconIdentificationProfile.Entity | KontaktIdentificationProfile.Entity | GenericNameIdentificationProfile.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    profile: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.profile;
        if (uv !== undefined) {
          if (uv.type == "mos.beacon.GenericNameIdentificationProfile") {
            obj["genericNameIdentificationProfile"] = GenericNameIdentificationProfile.codec.encode(uv);
          }
          else if (uv.type == "mos.beacon.IBeaconIdentificationProfile") {
            obj["ibeaconIdentificationProfile"] = IBeaconIdentificationProfile.codec.encode(uv);
          }
          else if (uv.type == "mos.beacon.KontaktIdentificationProfile") {
            obj["kontaktIdentificationProfile"] = KontaktIdentificationProfile.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.beacon.IdentificationProfile.profile");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon.IdentificationProfile");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["genericNameIdentificationProfile"] !== undefined) {
          out["profile"] = ctx.decode(GenericNameIdentificationProfile.codec, v["genericNameIdentificationProfile"], "genericNameIdentificationProfile");
        }
        if (v["ibeaconIdentificationProfile"] !== undefined) {
          out["profile"] = ctx.decode(IBeaconIdentificationProfile.codec, v["ibeaconIdentificationProfile"], "ibeaconIdentificationProfile");
        }
        if (v["kontaktIdentificationProfile"] !== undefined) {
          out["profile"] = ctx.decode(KontaktIdentificationProfile.codec, v["kontaktIdentificationProfile"], "kontaktIdentificationProfile");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace KontaktIdentificationProfile {
  export const refName = "mos.beacon.KontaktIdentificationProfile" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly kontaktUniqueId: string,
  }
  export const defaults: Entity = {
    type: refName,
    kontaktUniqueId: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.beacon.KontaktIdentificationProfile");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["kontaktUniqueId"] = ensureScalar(ctx, v["kontaktUniqueId"], "string");
      return out as any as Entity;
    }
  }();
}

