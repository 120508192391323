import React from 'react'

import { EntitySearch } from 'components/ui/entity-search'
import { IconList } from 'components/ui/icons'


import { Description, Image, Title, ListItemLabel, Subtitle } from 'components/ui/list-item-label'
import { RadioInputElement } from 'components/ui/radio'


import { connect } from 'containers/store'

import { Media, MediaKind } from 'generated/mos/media'
import { ListMediaRequest } from 'generated/mos/mediaaccess'

import { bffEndpoint } from 'helpers/bff'
import { pick } from 'helpers/core'

import { actionCreators } from '../../actions'
import { DataStatus, MediaList } from '../../store'

import { ModalBody } from './styled'

type ConnectedProps = {
  readonly mediaList: DataStatus<MediaList>;
}

type DirectProps = {
  readonly kind: MediaKind;
  readonly mediaItem: Media.Entity;
  readonly onUpdate: (details: Partial<Media.Entity>) => void;
}

type ActionProps = Pick<typeof actionCreators, 'mediaListMediaRequest'>;

type Props = ConnectedProps & DirectProps & ActionProps

const MediaLibraryComponent = (props: Props) => {
  const { kind, mediaItem, mediaList, mediaListMediaRequest, onUpdate } = props

  const onToggleEntity = (entity: Media.Entity) => {

    const selectedRef = mediaItem && Media.isRef(mediaItem.ref) ? mediaItem.ref.id : undefined

    // it's currently selected, so unselect it.
    if (selectedRef && selectedRef === Media.mustRef(entity.ref).id) {
      onUpdate(Media.defaults)
    }
    // update the parent with the entity which has been selected.
    else {
      onUpdate(entity)
    }
  }

  const hostName = bffEndpoint(window.location.hostname)

  const listItem = (entity: Media.Entity) => {
    const selected =
      mediaItem &&
      mediaItem.ref &&
      Media.mustRef(mediaItem.ref).id === Media.mustRef(entity.ref).id

    const mediaId = entity.ref && entity.ref.id
    const imagePath = mediaId ? `${hostName}/images/${mediaId}.jpg?s=crop&h=112&w=112` : ''

    return (
      <ListItemLabel key={Media.mustRef(entity.ref).id}>
        <RadioInputElement
          checked={selected}
          name={Media.mustRef(entity.ref).id}
          onChange={() => onToggleEntity(entity)}
        />
        <Image src={imagePath} />
        <Description>
          <Title>{entity.uploadedFilename}</Title>
          {entity.title && (<Subtitle>{entity.title}</Subtitle>)}
        </Description>
      </ListItemLabel>
    )
  }

  const renderEntityStyles = [
    {
      icon: IconList,
      name: 'list',
      render: listItem,
    },
  ]

  return (
    <ModalBody>
      <EntitySearch
        entityList={mediaList}
        filter={{
          type: ListMediaRequest.ByKindAndTerm.refName,
          kinds: [ kind ],
        }}
        onSearch={() => onUpdate(Media.defaults)}
        renderEntityStyles={renderEntityStyles}
        requestRefType={ListMediaRequest.refName}
        triggerRequest={mediaListMediaRequest}
      />
    </ModalBody>
  )
}

export const MediaLibrary = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.media, 'mediaList'),
  pick(actionCreators, 'mediaListMediaRequest')
)(MediaLibraryComponent)
