// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { PlacedPoint } from "generated/mos/geo";
import { Codec, DecodeContext, RemoteObject, WebRPCError } from "generated/webrpc";

export namespace BuildingPlacement {
  export const refName = "mos.placement.BuildingPlacement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.BuildingPlacement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingRef"] = v["buildingRef"];
      return out as any as Entity;
    }
  }();
}

export namespace LevelPlacement {
  export const refName = "mos.placement.LevelPlacement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly levelRef: { readonly typename: 'mos.structure.BuildingLevel' | 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    levelRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.LevelPlacement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["levelRef"] = v["levelRef"];
      return out as any as Entity;
    }
  }();
}

export namespace Placement {
  export const refName = "mos.placement.Placement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly placement: PositionPlacement.Entity | SpacePlacement.Entity | LevelPlacement.Entity | BuildingPlacement.Entity | SitePlacement.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    placement: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.placement;
        if (uv !== undefined) {
          if (uv.type == "mos.placement.BuildingPlacement") {
            obj["building"] = BuildingPlacement.codec.encode(uv);
          }
          else if (uv.type == "mos.placement.LevelPlacement") {
            obj["level"] = LevelPlacement.codec.encode(uv);
          }
          else if (uv.type == "mos.placement.PositionPlacement") {
            obj["position"] = PositionPlacement.codec.encode(uv);
          }
          else if (uv.type == "mos.placement.SitePlacement") {
            obj["site"] = SitePlacement.codec.encode(uv);
          }
          else if (uv.type == "mos.placement.SpacePlacement") {
            obj["space"] = SpacePlacement.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.placement.Placement.placement");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.Placement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["building"] !== undefined) {
          out["placement"] = ctx.decode(BuildingPlacement.codec, v["building"], "building");
        }
        if (v["level"] !== undefined) {
          out["placement"] = ctx.decode(LevelPlacement.codec, v["level"], "level");
        }
        if (v["position"] !== undefined) {
          out["placement"] = ctx.decode(PositionPlacement.codec, v["position"], "position");
        }
        if (v["site"] !== undefined) {
          out["placement"] = ctx.decode(SitePlacement.codec, v["site"], "site");
        }
        if (v["space"] !== undefined) {
          out["placement"] = ctx.decode(SpacePlacement.codec, v["space"], "space");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace PositionPlacement {
  export const refName = "mos.placement.PositionPlacement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly position: PlacedPoint.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    position: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["position"] = PlacedPoint.codec.encode(v.position);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.PositionPlacement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["position"] = ctx.decode(PlacedPoint.codec, v["position"], "position");
      return out as any as Entity;
    }
  }();
}

export namespace SitePlacement {
  export const refName = "mos.placement.SitePlacement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.SitePlacement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      return out as any as Entity;
    }
  }();
}

export namespace SpacePlacement {
  export const refName = "mos.placement.SpacePlacement" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceRef: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.placement.SpacePlacement");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceRef"] = v["spaceRef"];
      return out as any as Entity;
    }
  }();
}

