/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconBundlePreview = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M3.71 2.29L2.29 3.71l2.78 2.77a13.7 13.7 0 00-1.45 1.38A14.68 14.68 0 001 12s3 8 11 8a10.79 10.79 0 003.27-.49 9.84 9.84 0 002-.85l3 3.05 1.42-1.42zM12 4a10.79 10.79 0 00-3.27.49l1.66 1.66A8.23 8.23 0 0112 6c5.28 0 7.94 4.27 8.81 6A12.81 12.81 0 0119 14.72l1.42 1.42A14.68 14.68 0 0023 12s-3-8-11-8zM6.49 7.9L8.56 10A3.92 3.92 0 008 12a2.09 2.09 0 000 .25A4 4 0 0011.75 16H12a3.92 3.92 0 002-.56l1.73 1.73a8.08 8.08 0 01-2.15.68A8.3 8.3 0 0112 18c-5.28 0-7.94-4.27-8.81-6A12.43 12.43 0 015 9.28 11.31 11.31 0 016.49 7.9zm5.76.1L16 11.75A4 4 0 0012.25 8zm-2.18 3.47l2.45 2.45A1.77 1.77 0 0112 14a2 2 0 01-2-2 1.77 1.77 0 01.07-.52z" />
  </Icon>
)

// for use with mapbox addables.
export const IconBundlePreviewString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M3.71 2.29L2.29 3.71l2.78 2.77a13.7 13.7 0 00-1.45 1.38A14.68 14.68 0 001 12s3 8 11 8a10.79 10.79 0 003.27-.49 9.84 9.84 0 002-.85l3 3.05 1.42-1.42zM12 4a10.79 10.79 0 00-3.27.49l1.66 1.66A8.23 8.23 0 0112 6c5.28 0 7.94 4.27 8.81 6A12.81 12.81 0 0119 14.72l1.42 1.42A14.68 14.68 0 0023 12s-3-8-11-8zM6.49 7.9L8.56 10A3.92 3.92 0 008 12a2.09 2.09 0 000 .25A4 4 0 0011.75 16H12a3.92 3.92 0 002-.56l1.73 1.73a8.08 8.08 0 01-2.15.68A8.3 8.3 0 0112 18c-5.28 0-7.94-4.27-8.81-6A12.43 12.43 0 015 9.28 11.31 11.31 0 016.49 7.9zm5.76.1L16 11.75A4 4 0 0012.25 8zm-2.18 3.47l2.45 2.45A1.77 1.77 0 0112 14a2 2 0 01-2-2 1.77 1.77 0 01.07-.52z" />
  </svg>`

IconBundlePreview.displayName = 'IconBundlePreview'
