import React from 'react'

import { Modal, ModalProps } from 'components/ui/modal/modal';
import { space } from 'helpers/style'
import styled from 'styled';

const Wrapper = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 36px;
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 17px;
  margin-top: ${space(1)};
`;

// type ConnectedProps = {
//   readonly config: GetConfigResponse.Entity | undefined;
// };

type DirectProps = ModalProps;

// type Props = DirectProps & ConnectedProps;

const revisionDate = 'revisionDate'
const revisionHash = 'revisionHash'
export const VersionModal = (props: ModalProps) => {
  // const { config, ...props } = props;
  // if (!config) {
  //   return null;
  // }

  // const revisionDate = config.revisionDate ? new Date(config.revisionDate.value).toISOString() : "<unknown>"

  return (
    <Modal {...props}>
      <Wrapper>
        <Title>Version Details</Title>

        <div>
          <Description>{revisionHash} @ {revisionDate}</Description>
        </div>
      </Wrapper>
    </Modal>
  );
};

// export const VersionModal = connect<ConnectedProps, {}, DirectProps>(
//   (store) => ({ config: store.account.config }),
// )(VersionModalComponent);
