import React from 'react'

import { PrimaryNavigation } from 'components/ui/primary-navigation';
import { space } from 'helpers/style'

export const Error404Page = (props: React.PropsWithChildren<{}>) => {
  return (
    <>
      <PrimaryNavigation />
      <div style={{ padding: space(10, false) }}>
        <p>Page not found</p>
        {props.children}
      </div>
    </>
  );
};
