import React from 'react'

import styled from 'styled';
import { space } from 'helpers/style'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${space(2)} 0;
  border: solid 1px ${({ theme }) => theme.color.grayL80};
  color: ${({ theme }) => theme.color.darkText};
`;

const ContentBlock = styled.div`
  padding: ${space(3)} ${space(4)};
`;

const AppendBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
  &:empty {
    display: none;
  }
`;

const Description = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.color.gray};
`;

type Props = {
  readonly title: string;
  readonly description?: string;
};

export const AttributeBlock = (props: React.PropsWithChildren<Props>) => {
  const { title, description, children } = props;

  return (
    <Wrapper>
      <ContentBlock>
        <div>{title}</div>
        {description && <Description>{description}</Description>}
      </ContentBlock>
      {children
        ? <AppendBlock>{children}</AppendBlock>
        : null
      }
    </Wrapper>
  );
};
