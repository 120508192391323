import React from 'react'

import { space } from 'helpers/style'
import styled from 'styled';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.main`
  width: 450px;
  padding: ${space(15)};
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: ${space(5)};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 24px;
`;

type Props = {
  readonly title?: () => React.ReactNode;
  readonly content: () => React.ReactNode;
};

export const LayoutUnauthenticatedPage = (props: Props) => {
  return (
    <Wrapper>
      <InnerWrapper>
        {!props.title ? null : (<Title>{props.title()}</Title>)}
        {props.content()}
      </InnerWrapper>
    </Wrapper>
  );
};
