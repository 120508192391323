import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import {
  ClearHoldingAudioRequest,
  ClearHoldingAudioResponse,
  ListHoldingAudioResponse
} from '../types'

import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const holdingAudioActionCreators = {
  wamListHoldingAudioRequest: (): FluxStandardAction<
    undefined,
    'wamListHoldingAudioRequest'
  > => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamListHoldingAudioRequest' as const,
    payload: undefined,
  }),
  wamListHoldingAudioSuccess: (
    payload: ListHoldingAudioResponse,
  ): FluxStandardAction<ListHoldingAudioResponse, 'wamListHoldingAudioSuccess'> => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamListHoldingAudioSuccess' as const,
    payload,
  }),
  wamListHoldingAudioFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamListHoldingAudioFailure'> => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamListHoldingAudioFailure' as const,
    payload,
    error: true,
  }),

  wamClearHoldingAudioRequest: (
    payload: ClearHoldingAudioRequest,
  ): FluxStandardAction<ClearHoldingAudioRequest, 'wamClearHoldingAudioRequest'> => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamClearHoldingAudioRequest' as const,
    payload,
  }),
  wamClearHoldingAudioSuccess: (
    payload: ClearHoldingAudioResponse
  ): FluxStandardAction<ClearHoldingAudioResponse, 'wamClearHoldingAudioSuccess'> => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamClearHoldingAudioSuccess' as const,
    payload
  }),
  wamClearHoldingAudioFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamClearHoldingAudioFailure'> => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamClearHoldingAudioFailure' as const,
    payload,
    error: true,
  }),
  wamClearHoldingAudioReset: ()  => ({
    domain: 'wam.holding-audio' as const,
    type: 'wamClearHoldingAudioReset' as const
  }),
}

type WamAction = ReturnType<typeof holdingAudioActionCreators[keyof typeof holdingAudioActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.holding-audio'
}

export const holdingAudioReducer = (
  state: WamAppState['holdingAudio'] = initialWamAppState.holdingAudio,
  action: WamAction,
): WamAppState['holdingAudio'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamListHoldingAudioRequest': {
      return {
        ...state,
        list: {
          status: Status.Loading,
        },
      }
    }
    case 'wamListHoldingAudioSuccess': {
      const { payload } = action
      return {
        ...state,
        list: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamListHoldingAudioFailure': {
      const { payload } = action
      return {
        ...state,
        list: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }

    case 'wamClearHoldingAudioRequest': {
      return {
        ...state,
        holdingAudioClear: {
          status: Status.Loading,
        },
      }
    }
    case 'wamClearHoldingAudioSuccess': {
      const { payload } = action
      return {
        ...state,
        holdingAudioClear: {
          status: Status.Complete,
          message: 'Holding audio cleared',
        },
      }
    }
    case 'wamClearHoldingAudioFailure': {
      const { payload } = action
      return {
        ...state,
        holdingAudioClear: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamClearHoldingAudioReset': {
      return {
        ...state,
        holdingAudioClear: {
          status: Status.Idle
        }
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
