import {
  DataStatus as GenericDataStatus,
  EntityStatus as GenericEntityStatus,
  Status,
} from 'helpers/status'

type WamErrorCode = string

export type DataStatus<T> = GenericDataStatus<T, WamErrorCode>
export type EntityStatus<T extends object> = GenericEntityStatus<T, WamErrorCode, string>

import {
  ListGuidedToursResponse,
  UpdateGuidedTourResponse,
  UploadResponse,
  UpdateAtmosphericZoneResponse,
  UpdateSoundEffectZoneResponse,
  DeleteTourDirectionsResponse,
  UpdateTourDirectionsResponse,
  DeleteTourOutroResponse,
  DeleteAtmosphericZoneResponse,
  DeleteSoundEffectZoneResponse,
  GetStopResponse,
  UpdateStopDirectionsResponse,
  DeleteStopDirectionsResponse,
  ListReleasableDevicesResponse,
  ListReleasesResponse,
  ListHoldingAudioResponse,
  ClearHoldingAudioResponse,
  SyncLabelResponse,
  ReleaseSummaryResponse,
} from './types'

export type WamAppState = {
  readonly tours: {
    readonly list: DataStatus<ListGuidedToursResponse>
    readonly tourUpdate: DataStatus<UpdateGuidedTourResponse>
    readonly tourDirectionsUpdate: DataStatus<UpdateTourDirectionsResponse>
    readonly tourDirectionsDelete: DataStatus<DeleteTourDirectionsResponse>
    readonly tourOutroDelete: DataStatus<DeleteTourOutroResponse>
  }
  readonly upload: DataStatus<UploadResponse>
  readonly atmosphericZones: {
    readonly atmosphericZonesUpdate: DataStatus<UpdateAtmosphericZoneResponse>
    readonly atmosphericZoneDelete: DataStatus<DeleteAtmosphericZoneResponse>
  }
  readonly soundEffectZones: {
    readonly soundEffectZoneUpdate: DataStatus<UpdateSoundEffectZoneResponse>
    readonly soundEffectZoneDelete: DataStatus<DeleteSoundEffectZoneResponse>
  }
  readonly stops: {
    readonly stopUpdate: DataStatus<GetStopResponse>
    readonly stopDirectionsUpdate: DataStatus<UpdateStopDirectionsResponse>
    readonly stopDirectionsDelete: DataStatus<DeleteStopDirectionsResponse>
  }
  readonly publishing: {
    readonly listDevices: DataStatus<ListReleasableDevicesResponse>
    readonly listReleases: DataStatus<ListReleasesResponse>
    readonly release: DataStatus<{}>
    readonly sync: {
      readonly [key: string]: DataStatus<SyncLabelResponse>
    },
    readonly summaries: {
      readonly [key: string]: DataStatus<ReleaseSummaryResponse>
    }
  }
  readonly holdingAudio: {
    readonly list: DataStatus<ListHoldingAudioResponse>
    readonly holdingAudioClear: DataStatus<ClearHoldingAudioResponse>
  }
}

export const initialWamAppState: WamAppState = {
  tours: {
    list: { status: Status.Idle },
    tourUpdate: { status: Status.Idle },
    tourDirectionsUpdate: { status: Status.Idle },
    tourDirectionsDelete: { status: Status.Idle },
    tourOutroDelete: { status: Status.Idle },
  },
  upload: { status: Status.Idle },
  atmosphericZones: {
    atmosphericZonesUpdate: { status: Status.Idle },
    atmosphericZoneDelete: { status: Status.Idle },
  },
  soundEffectZones: {
    soundEffectZoneUpdate: { status: Status.Idle },
    soundEffectZoneDelete: { status: Status.Idle },
  },
  stops: {
    stopUpdate: { status: Status.Idle },
    stopDirectionsUpdate: { status: Status.Idle },
    stopDirectionsDelete: { status: Status.Idle },
  },
  publishing: {
    listDevices: { status: Status.Idle },
    listReleases: { status: Status.Idle },
    release: { status: Status.Idle },
    sync: {},
    summaries: {}
  },
  holdingAudio: {
    list: { status: Status.Idle },
    holdingAudioClear: { status: Status.Idle }
  }
}

export type WamStore = {
  readonly wam: WamAppState
}
