import { DateTime } from 'luxon'

import { Metadata } from 'generated/mos/entity'
import { Invariant } from 'helpers/core'
import { formatDateTime, stringToDateTime } from 'helpers/timestamps'

type ParsedTimestamp = {
  readonly recentlyModified: boolean;
  readonly value: string;
}

export const parseMetadataTimestamp = (metadata: Metadata.Entity): ParsedTimestamp => {
  if (!metadata || !metadata.modifiedAt) {
    throw new Invariant('No timestamp found in metadata')
  }

  const datestamp = stringToDateTime(metadata.modifiedAt.value)
  const modifiedOffset = DateTime.local().minus({ minutes: 1 })

  return {
    recentlyModified: datestamp > modifiedOffset,
    value: formatDateTime(datestamp),
  }
}
