import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { ClusterMap, PointRef } from 'components/cluster-map';
import { Button } from 'components/ui/button'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu';
import { StatusGuard } from 'components/ui/status-guard';
import { AttributeBlock } from 'components/ui/attribute-block';
import { PrimaryNavigationEditPosition } from 'components/ui/primary-navigation/edit-position'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { MapInitialBounds, MapView } from 'components/mapbox';
import { RefCopy } from 'components/ui/ref-copy';
import { AddAttributeButton } from 'components/ui/add-attribute-button';
import { Alert, Confirm } from 'components/ui/modal';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { refToUrn } from 'entity';
import { Beacon } from 'generated/mos/beacon';
import { UpdateBeaconRequest } from 'generated/mos/beaconmanagement'
import { PlacedPoint } from 'generated/mos/geo'
import { assertNever, pick, shallowEqual } from 'helpers/core';
import * as geo from 'helpers/geo';
import { Status, statusSelector } from 'helpers/status';
import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresSelector } from 'domains/structures';
import { structuresActionCreators } from 'domains/structures/actions';
import { SpatialScopeSelector } from 'domains/structures/components/spatial-scope-select';
import { isLevelRef, LevelRef, mustStructureRef, StructureRef, Structures } from 'domains/structures/entities';
import { DataStatus as StructuresDataStatus, GlobalMapState } from 'domains/structures/store';

import { actionCreators } from '../../actions';
import { BeaconEdit, BeaconsList, DataStatus, EntityStatus } from '../../store';

import { isProfileType, isKontakt, isIBeacon, isGeneric, KONTAKT_PROFILE } from '../../profiles';
import { assembleIdentificationProfile, BeaconFormLayout, FormValues } from '../beacon-form-layout';
import { filterBeacons } from '../beacon-list-page/beacons-data'; // FIXME: this is in a bad location

import { AttributeBlockAppend, LastButtonRow, NoBoundaryLabel, Section, SectionHeader } from './styled';


type DirectProps = {
  readonly beaconId: string;
  readonly onComplete: () => void;
};

type ConnectedProps = {
  readonly beaconEdit: EntityStatus<BeaconEdit>;
  readonly beaconDelete: DataStatus<undefined>;
  readonly beacons: DataStatus<BeaconsList>;
  readonly structures: StructuresDataStatus<Structures>;

  // The globalMap's activeRef is used as a fallback for the spatial
  // scope selector if the beacon being edited does not have a position:
  readonly globalMap: GlobalMapState;
};

type ActionProps =
  Pick<typeof actionCreators, 'beaconsGetBeaconRequest' | 'beaconsDeleteBeaconRequest' | 'beaconsUpdateBeaconRequest' | 'beaconsListBeaconsRequest' | 'beaconFiltersUpdate'> &
  Pick<typeof sharedActionCreators, 'toastNotification'> &
  Pick<typeof structuresActionCreators, 'structuresLoadRequest'>;

type Props = DirectProps & ConnectedProps & ActionProps;

type EditMode = undefined | 'position';

type State = {
  readonly showConfirmDeleteModal?: boolean;
  readonly showDismissableErrorModal?: boolean;
  readonly errorModalMessage: string;

  // The globalMap's activeRef is only used for a default if the beacon
  // is unpositioned. The spatial scope selector on the beacon edit
  // page is managed by this state value:
  readonly spatialRef: StructureRef | undefined;

  // The globalMap's view is only used for a default if the beacon is
  // unpositioned.
  readonly mapView?: MapView;

  // Replace this with a new '{}' to force mapbox to update to the initial bounds:
  readonly mapBoundsIdentity?: object;
  readonly mapBounds?: geo.BBox2D;

  readonly bag: FormBag<FormValues>;
  readonly beaconEditReady?: boolean;
  readonly dirtyBeacon?: Beacon.Entity;
  readonly editMode: EditMode;
  readonly initialFormValues: FormValues;
};

function createDirtyBeacon(ref: Beacon.Ref, beacons: ReadonlyArray<Beacon.Entity> | undefined): Beacon.Entity {
  if (!beacons) {
    throw new Error();
  }
  const dirtyBeacon = beacons ? beacons.find((v) => v.ref && v.ref.id === ref.id) : undefined;
  if (!dirtyBeacon) {
    throw new Error();
  }
  return dirtyBeacon;
}


class BeaconEditPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const initialFormValues = {
      name: '',
      profileType: KONTAKT_PROFILE,
      kontaktUniqueId: '',
      genericLocalName: '',
      iBeaconMajor: '',
      iBeaconMinor: '',
      iBeaconUuid: '',
      snapToPosition: false,
    }
    this.state = {
      errorModalMessage: '',
      editMode: undefined,

      // If the beacon is not positioned, we default to the global active
      // spatial, but we never modify the global activeRef in this component:
      spatialRef: props.globalMap.activeRef,
      mapView: props.globalMap.view,

      bag: createFormBag(initialFormValues),
      initialFormValues,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    // FIXME: if non-existent beacon is requested, should call onComplete(). This depends
    // on a proper error code we can pull out of DataStatus.messages; at the moment, it's
    // just "".

    // When the beacon to be edited has loaded, we set the spatial scope selector's
    // ref to the ref contained in the position and construct the dir
    if (state.beaconEditReady ||
      !statusSelector.hasData(props.beaconEdit) ||
      !statusSelector.hasData(props.beacons)) {

      return null;
    }

    const beacon = props.beaconEdit.data;
    if (!beacon) {
      throw new Error();
    }

    const beacons = statusSelector.data(props.beacons);
    const dirtyBeacon = createDirtyBeacon({ id: props.beaconId, typename: Beacon.refName }, beacons);

    const idProfile = beacon.identificationProfile;
    if (!idProfile || !idProfile.profile || !isProfileType(idProfile.profile)) {
      throw new Error(`beacon profile type is invalid`);
    }

    // extract specific profiles from generic IdentificationProfile.
    const { profile } = idProfile

    const values: FormValues = {
      name: beacon.name,
      profileType: profile.type,
      kontaktUniqueId: isKontakt(profile) && profile.kontaktUniqueId ? profile.kontaktUniqueId : '',
      genericLocalName: isGeneric(profile) && profile.localName ? profile.localName : '',
      ...(
        !isIBeacon(profile) ? {
          iBeaconMajor: '',
          iBeaconMinor: '',
          iBeaconUuid: '',
        } : {
            iBeaconMajor: profile.major + '',
            iBeaconMinor: profile.minor + '',
            iBeaconUuid: profile.uuid,
          }
      ),
      snapToPosition: beacon.snapToPosition,
    };
    const newState: Partial<State> = {
      beaconEditReady: true,
      spatialRef: beacon.position ? mustStructureRef(beacon.position.levelRef) : state.spatialRef,
      dirtyBeacon,
      bag: createFormBag(values),
      initialFormValues: values,
    };
    return newState;
  }

  public get ref(): Beacon.Ref {
    return { typename: Beacon.refName, id: this.props.beaconId };
  }

  private get name() { return this.state.bag.values.name; }

  private mapBounds(): MapInitialBounds | undefined {
    if (!this.state.mapBoundsIdentity || !this.state.mapBounds) {
      return;
    }
    return {
      padding: 120, // FIXME: put in context
      identity: this.state.mapBoundsIdentity,
      bounds: this.state.mapBounds,
    };
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    return errors;
  }

  public componentWillUnmount() {
    this.props.beaconsListBeaconsRequest();
  }

  public componentDidMount() {
    this.props.beaconsGetBeaconRequest(this.ref);
    this.refresh();
  }

  public componentDidUpdate() {
    this.refresh();

    if (this.props.beaconEdit.status === Status.Ready && !this.props.beaconEdit.errors) {
      this.props.toastNotification({ type: 'success', text: `Beacon saved` });
      this.props.onComplete();
    }

    if (this.props.beaconDelete.status === Status.Ready) {
      this.props.toastNotification({ type: 'success', text: `Beacon deleted` });
      this.props.onComplete();
    }
  }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.beacons.status)) {
      this.props.beaconsListBeaconsRequest();
    }
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private updateDirtyBeaconPosition(args: { pos?: geo.Position2D; levelRef?: LevelRef }) {
    const { pos, levelRef } = args;
    const dirtyBeacon = this.state.dirtyBeacon;
    if (!dirtyBeacon || !statusSelector.hasData(this.props.structures)) {
      throw new Error();
    }

    let position: PlacedPoint.Entity | undefined;
    const level = levelRef || (dirtyBeacon.position ? dirtyBeacon.position.levelRef : undefined);

    if (pos && level) {
      position = {
        ...PlacedPoint.defaults,
        point: geo.asPoint2D(pos),
        levelRef: level,
      };
    }

    this.setState({ dirtyBeacon: { ...dirtyBeacon, position: position || undefined } });
  }

  private onBeaconMoved = (e: { ref: PointRef; pos: geo.Position2D }) => {
    this.updateDirtyBeaconPosition(e);
  }

  private onBeaconUnposition = () => {
    this.updateDirtyBeaconPosition({ pos: undefined });
  }

  private onPositionEdit = () => {
    this.setState({ editMode: 'position' });
  }

  private onPositionShow = () => {
    if (!this.state.dirtyBeacon || !this.state.dirtyBeacon.position) {
      return;
    }

    const pos = this.state.dirtyBeacon.position;
    this.setState({
      spatialRef: mustStructureRef(pos.levelRef),
      mapView: {
        ...this.state.mapView!,
        zoom: 19,  // FIXME: hard-coded default zoom
        center: geo.asPosition2D(pos.point!),
      },
    });
  }

  private onPositionAdd = () => {
    if (this.props.beaconEdit.status !== Status.Ready) {
      throw new Error();
    }

    if (this.props.structures.status !== Status.Ready) {
      // TODO(dc): should this ever happen?
      throw new Error('tried to add a position before structures were ready');
    }

    const editData = this.props.beaconEdit.data;
    const structures = this.props.structures.data;
    const level = structures && this.state.spatialRef && structures.index[refToUrn(this.state.spatialRef)];

    if (this.state.dirtyBeacon && this.state.dirtyBeacon.position) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: `'${editData.name}' already has a position.` });
    } else if (!isLevelRef(this.state.spatialRef)) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: 'You must be on a level to place a beacon.' });
    } else {
      const boundary = structuresSelector.selectBoundaryByRef(structures, this.state.spatialRef);
      if (!boundary) {
        this.setState({
          showDismissableErrorModal: true,
          errorModalMessage: `${editData.name} has no boundary, you must add a boundary before adding beacons to it.`,
        });
      } else {
        this.setState({ mapBoundsIdentity: {}, mapBounds: geo.bbox2D(boundary) });
        this.updateDirtyBeaconPosition({ pos: geo.center2D(boundary), levelRef: this.state.spatialRef });
        this.setState({ editMode: 'position' });
      }
    }
  }

  private onConfirmDelete = () => {
    if (this.props.beaconEdit.status !== Status.Ready) {
      return;
    }
    this.props.beaconsDeleteBeaconRequest(Beacon.mustRef(this.props.beaconEdit.data.ref));
  }

  private save() {
    if (this.state.editMode) {
      throw new Error();
    }

    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.beaconFiltersUpdate({ lastEdited: this.ref });
    const values = bag.values;
    this.props.beaconsUpdateBeaconRequest({
      ...UpdateBeaconRequest.defaults,
      beaconRef: this.ref,
      name: values.name,
      identificationProfile: assembleIdentificationProfile(values),

      // XXX(bw): you can't just splat 'this.state.dirtyBeacon.position' as it may contain
      // properties the BFF won't accept and the errors are currently not functioning
      // properly:
      position: !this.state.dirtyBeacon || !this.state.dirtyBeacon.position ? undefined : {
        ...PlacedPoint.defaults,
        point: geo.asPoint2D(this.state.dirtyBeacon.position.point!),
        levelRef: this.state.dirtyBeacon.position.levelRef,
      },
      snapToPosition: values.snapToPosition,
    });
  }

  private onCancelEdit = () => {
    this.props.onComplete();
  }

  private isModified = (): boolean => {
    if (!statusSelector.hasData(this.props.beaconEdit) ||
      !statusSelector.hasData(this.props.beacons)) {
      return false;
    }

    // check if form has changed
    if (!shallowEqual(this.state.bag.values, this.state.initialFormValues)) {
      return true;
    }

    // check if position changed
    const beaconEdit = this.props.beaconEdit.data;
    const initialPosition = beaconEdit.position;

    if (this.state.dirtyBeacon &&
      initialPosition &&
      this.state.dirtyBeacon.position &&
      !geo.equal(initialPosition.point, this.state.dirtyBeacon.position.point)) {
      return true;
    }

    if (this.state.dirtyBeacon && !initialPosition && initialPosition !== this.state.dirtyBeacon.position) return true

    return false;
  }

  public render() {
    const structures = statusSelector.data(this.props.structures);

    let locationString: string = '';

    const pointList = [...statusSelector.data(this.props.beacons) || []];

    if (this.state.dirtyBeacon) {
      // The updated beacon needs to be merged into the list that comes from the store so
      // that our edits don't disappear from view if we do things like change the level
      // in the spatial scope selector:
      const idx = pointList.findIndex((v) => v.ref && v.ref.id === this.ref.id);
      pointList.splice(idx, 1);
      pointList.push(this.state.dirtyBeacon);

      // FIXME: gql-jank ref typing
      if (structures && this.state.dirtyBeacon.position && this.state.dirtyBeacon.position.levelRef) {
        locationString = structuresSelector.namePath(structures,
          mustStructureRef(this.state.dirtyBeacon.position.levelRef), ['No position']).join(' > ');
      }
    }

    const beaconsData = structures ?
      filterBeacons(pointList, structures, {}, this.state.spatialRef) :
      undefined;

    const modified = this.isModified()

    const sidebar = () => (
      <StatusGuard status={this.props.beaconEdit} mode="form" fullHeight>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
          <Section>
            <SectionHeader>Details</SectionHeader>
            <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
              <BeaconFormLayout bag={this.state.bag} />
            </FormData>
          </Section>

          <Section>
            <SectionHeader>Attributes</SectionHeader>
            <AddAttributeButton title="Position" onClick={this.onPositionAdd} />
            {!this.state.dirtyBeacon || !this.state.dirtyBeacon.position
              ? <NoBoundaryLabel>No position</NoBoundaryLabel>
              : <AttributeBlock title="Main position" description={locationString}>
                <AttributeBlockAppend>
                  <PopOverMenu>
                    <PopOverMenuButton onClick={this.onPositionEdit}>Edit position</PopOverMenuButton>
                    <PopOverMenuButton onClick={this.onPositionShow}>Centre on map</PopOverMenuButton>
                  </PopOverMenu>
                </AttributeBlockAppend>
              </AttributeBlock>
            }
          </Section>

          <LastButtonRow>
            <Button onClick={() => this.setState({ showConfirmDeleteModal: true })}>
              Delete beacon
            </Button>
            <RefCopy modelRef={this.ref} />
          </LastButtonRow>
        </div>
      </StatusGuard>
    );

    const content = () => (
      <>
        <SpatialScopeSelector
          structures={structures}
          structureRef={this.state.spatialRef}
          onChange={(e) => this.setState({ spatialRef: e })}
        />
        <ClusterMap
          mapView={this.state.mapView!}
          onMapViewChanged={(e) => this.setState({ mapView: e })}
          initialBounds={this.mapBounds()}
          pointList={beaconsData ? beaconsData.currentScope : []}
          activePointRef={this.ref}
          canPositionActive={this.state.editMode === 'position'}
          onPointMoved={(e) => this.onBeaconMoved(e)}
          structures={structures}
          structureRef={this.state.spatialRef}
        />
      </>
    );

    let nav: () => React.ReactNode;
    if (this.state.editMode === 'position') {
      nav = () => (
        <PrimaryNavigationEditPosition
          title="Edit beacon position"
          kind="position"
          onCommit={() => this.setState({ editMode: undefined })}
          onCancel={this.onCancelEdit}
          onDelete={this.onBeaconUnposition}
        />
      );

    } else if (!this.state.editMode) {
      nav = () => (
        <PrimaryNavigationEditEntity
          title="Edit Beacon"
          saveDisabled={!!this.state.editMode || this.props.beaconEdit.status !== Status.Ready}
          disabled={!modified}
          onSave={() => this.save()}
          onCancel={this.onCancelEdit}
          onConfirm={modified ? this.onCancelEdit : undefined}
          navBackRoute={!modified ? '/locate/beacons' : undefined}
        />
      );

    } else {
      assertNever(this.state.editMode); // Exhaustiveness check
      throw new Error(); // TypeScript requires this
    }

    return (
      <div style={{ height: '100vh' }}>
        {!this.state.showConfirmDeleteModal ? null :
          <Confirm
            onClose={() => this.setState({ showConfirmDeleteModal: false })}
            message={`Are you sure you want to permanently delete ${this.name}?`}
            onConfirm={this.onConfirmDelete}
          />
        }

        {!this.state.showDismissableErrorModal ? null :
          <Alert onClose={() => this.setState({ showDismissableErrorModal: false })}
            message={this.state.errorModalMessage} />
        }

        <LayoutLocateEditPage
          nav={nav}
          sidebar={sidebar}
          content={content}
        />
      </div>
    );
  }
}

export const BeaconEditPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    ...pick(store.beacons, 'beaconDelete', 'beaconEdit', 'beacons'),
    ...pick(store.structures, 'globalMap', 'structures'),
  }),
  {
    ...pick(actionCreators, 'beaconsGetBeaconRequest', 'beaconsDeleteBeaconRequest', 'beaconsListBeaconsRequest', 'beaconsUpdateBeaconRequest', 'beaconFiltersUpdate'),
    ...pick(sharedActionCreators, 'toastNotification'),
    ...pick(structuresActionCreators, 'globalMapUpdate', 'structuresLoadRequest'),
  },
)(BeaconEditPageView);
