import React from 'react'

import { Container, Content, Wrap } from 'components/ui/draggable'
import { IconRoom } from 'components/ui/icons'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu'
import { Description, Image, Title } from 'components/ui/list-item-label'

import { space } from 'helpers/style'

import styled from 'styled'

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-weight: ${({ theme }) => theme.font.weight.base};
  color: ${({ theme }) => theme.color.grayL40};
`;

const IconText = styled.span<{ wide?: boolean }>`
  width: ${({ wide }) => wide ? space(45) : space(10)};
  margin-left: ${space(2)};
  font-size: ${({ theme }) => theme.font.size.small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconsContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 0;

  // add margin to align location icon in disabled list items and active ones
  margin-right: ${({ disabled }) => disabled && "42px"};
`

type Props = {
  readonly floorName: string
  readonly audioFilename: string | undefined
  readonly deleteHandler: () => void
};

export const ListItem: React.FC<Props> = ({
  deleteHandler,
  floorName,
  audioFilename,
}) => {
  return (
    <Container>
      <Wrap isDragDisabled={!audioFilename}>
        <Content>
          <Image src='' />
          <Description>
            <Title>{audioFilename ? audioFilename : '--'}</Title>
          </Description>
        </Content>
        <IconsContainer disabled={!audioFilename}>
          <IconBlock>
            <IconRoom color="grayL40" size="small" />
            <IconText wide>{floorName}</IconText>
          </IconBlock>
        </IconsContainer>
        {audioFilename &&
          <PopOverMenu iconSize="base">
            <PopOverMenuButton
              onClick={(event) => {
                deleteHandler()
                event.stopPropagation()
              }}
            >
              Clear Audio
            </PopOverMenuButton>
          </PopOverMenu>
        }
      </Wrap>
    </Container>

  )
}
