import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { StatusGuard } from 'components/ui/status-guard'
import { connect } from 'containers/store';
import { User } from 'generated/mos/user';
import { AssignNewPasswordRequest, ResetToken } from 'generated/mos/userauthentication';
import { pick } from 'helpers/core';
import { passwordValidationError } from 'helpers/form';
import { Status } from 'helpers/status';
import styled from 'styled'

import { accountBindableActionCreators } from 'domains/account/actions';
import { SecurityNote } from 'domains/account/components/security-note';
import { DataStatus } from 'domains/account/store';

const WideButton = styled(Button)`
  width: 100%;
`

type DirectProps = {
  readonly userRef: User.Ref;
  readonly token: string;
  readonly onSuccess: () => void;
};

type ConnectedProps = {
  readonly assignNewPasswordStatus: DataStatus<undefined>;
};

type ActionProps = Pick<typeof accountBindableActionCreators, 'accountAssignNewPasswordRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly password: string;
  readonly confirmPassword: string;
};


class AssignNewPasswordFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ password: '', confirmPassword: '' }),
    };
  }

  public componentDidUpdate() {
    if (this.props.assignNewPasswordStatus.status === Status.Ready) {
      this.props.onSuccess()
    }
  }

  private validate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    const error = passwordValidationError(values.password);
    if (error) {
      errors.password = error;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const bag = validateFormBag(this.state.bag, this.validate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.accountAssignNewPasswordRequest({
      ...AssignNewPasswordRequest.defaults,
      password: bag.values.password,
      token: {
        ...ResetToken.defaults,
        value: this.props.token,
      },
      userRef: this.props.userRef,
    });
  }

  public render() {
    const { touched, errors, valid } = this.state.bag;

    const loading = this.props.assignNewPasswordStatus.status === Status.Updating;

    return (
      <form noValidate onSubmit={this.onSubmit} >
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.validate}>
          <StatusGuard status={this.props.assignNewPasswordStatus} mode="form">
            <Input
              label="Password"
              field="password"
              type="password"
              error={touched.password ? errors.password : undefined}
            />
            <Input
              label="Confirm password"
              field="confirmPassword"
              type="password"
              error={touched.confirmPassword ? errors.confirmPassword : undefined}
            />
            <SecurityNote />
          </StatusGuard>

          <WideButton type="submit" isDisabled={!valid}>
            {loading ? 'Assigning...' : 'Assign password'}
          </WideButton>
        </FormData>
      </form>
    );
  }
}

export const AssignNewPasswordForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'assignNewPasswordStatus'),
  pick(accountBindableActionCreators, 'accountAssignNewPasswordRequest'),
)(AssignNewPasswordFormView);
