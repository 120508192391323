import React from 'react'
import Select from 'react-select';
import { createFormBag, Field, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';
import { classNamePrefix, FieldWrapper } from 'components/ui/select'
import { Button, ButtonGroup } from 'components/ui/button'
import { ErrorLabel, Label } from 'components/ui/forms'
import { Input } from 'components/ui/input'
import { connect } from 'containers/store';
import { Site, Space, SpaceType } from 'generated/mos/structure';
import { CreateSpaceRequest } from 'generated/mos/structuremanagement';
import { pick } from 'helpers/core';

import { Structures } from 'domains/structures/entities';
import { spacesBindableActionCreators } from 'domains/spaces/actions';
import { SpaceTypeSelect } from 'domains/spaces/components/space-type-select';
import { DataStatus } from 'domains/spaces/store';

export type SpaceCreatedEvent = {
  readonly ref: Space.Ref;
  readonly siteRef: Site.Ref;
};

type DirectProps = {
  readonly onSuccess: (e: SpaceCreatedEvent) => void;
  readonly structures?: Structures;
};

type ConnectedProps = {
  readonly spaceCreate: DataStatus<Space.Ref>;
};

type ActionProps = Pick<typeof spacesBindableActionCreators, 'spaceCreateRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly name: string;
  readonly spaceType?: SpaceType.Entity;
  readonly siteRef?: Site.Ref;
};

type SiteOption = {
  readonly label: string;
  readonly value: Site.Ref;
};

class SpaceCreateFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ name: '' }),
    };
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    if (!Site.isRef(values.siteRef)) {
      errors.siteRef = 'Required';
    }
    if (!values.spaceType) {
      errors.spaceType = 'Required';
    }
    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  }

  private submit = () => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    const { values } = bag;
    const { spaceCreateRequest } = this.props;

    const input: CreateSpaceRequest.Entity = {
      ...CreateSpaceRequest.defaults,
      name: values.name,
      siteRef: values.siteRef!,
      spaceTypeRef: values.spaceType!.ref,
      boundaries: [],
    };

    spaceCreateRequest({
      input: input,
      onSuccess: (ref: Space.Ref) => {
        if (this.props.onSuccess) {
          this.props.onSuccess({ ref, siteRef: values.siteRef! });
        }
      },
    });
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public render() {
    const { errors, valid, touched } = this.state.bag;
    // const loading = this.props.spaceCreate.status === Status.Updating;
    const loading = false;

    const siteOptions = !this.props.structures ? [] : this.props.structures.sites.map((site) => ({
      label: site.name,
      value: Site.mustRef(site.ref),
    }));

    return (
      <form noValidate onSubmit={(e) => { e.preventDefault(); this.submit(); }}>
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
          <Input
            label="Name"
            field="name"
            error={touched.name ? errors.name : undefined}
          />
          <Label>Site</Label>
          <FieldWrapper>
            <Field<FormValues, 'siteRef'> name="siteRef" render={(props) => (
              <Select
                classNamePrefix={classNamePrefix}
                options={siteOptions}
                styles={{ control: (base) => ({ ...base, height: '51px' }), menuList: (base) => ({ ...base, maxHeight: '100%' }), }}
                onChange={(e: any) => {
                  props.change(e.value)
                }}
                error={touched.siteRef ? errors.siteRef : undefined}
              />
            )} />
          </FieldWrapper>
          <ErrorLabel>{touched.siteRef && errors.siteRef}</ErrorLabel>

          <Label>Space Type</Label>
          {/* FIXME: this select is connected to the store and triggers api calls :/ */}
          <Field<FormValues, 'spaceType'> name="spaceType" render={(props) => (
            <SpaceTypeSelect
              selected={props.value}
              onBlur={props.blur}
              onChange={(e) => props.change(e.spaceType)}
            />
          )} />
          <ErrorLabel>{touched.spaceType && errors.spaceType}</ErrorLabel>

          <ButtonGroup>
            <Button type="submit" isDisabled={!valid || loading}>
              {loading ? 'Creating...' : 'Create space'}
            </Button>
          </ButtonGroup>
        </FormData>
      </form>
    );
  }
}

export const SpaceCreateForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({ spaceCreate: store.spaces.spaceCreate }),
  pick(spacesBindableActionCreators, 'spaceCreateRequest'),
)(SpaceCreateFormView);
