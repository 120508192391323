import mapboxgl from 'mapbox-gl';

import { DefaultState } from './core';
import { assertNever } from './util';


// AddControlPosition uses dark TypeScript voodoo to create a type alias for the type
// of the 'position' parameter which is embedded in the mapboxgl.Map.addControl() function.
export type AddControlPosition = mapboxgl.Map extends {
  addControl(control: any, position?: infer I): mapboxgl.Map;
} ? I : never;


// Addable represents an arbitrary plugin or integration that can be added to or removed
// from a Mapbox component. Addables may manipulate the map using any method exposed via
// AddableManager.
//
// Addables are intended to be the least convenient and most flexible integration you
// can write; they should be reserved for advanced functionality that cannot be
// accomplished by simpler means.
//
// Warning: We have made a pragmatic compromise with Addables: there's no distinction
// between 'Definition' and 'Implementation' like there is with Sources. The first
// instance of your Addable detected by MapboxBridge is retained as the 'Implementation'
// and is allowed to accumulate state. Subsequent instances of your Addable are used as
// 'Definitions' only.
//
// It's up to you to handle this double-duty in the addable itself: don't do any work in
// the constructor(), you must wait until add() is called. If you need to retain any state
// (like a mapboxgl.Marker instance), you MUST ONLY do so in add(). You are responsible
// for updating your own internal Addable state in response to changes (see
// Addable.compare()).
//
export interface Addable<TMapSetState=DefaultState> {
  readonly key: string;

  readonly add: (map: AddableManager<TMapSetState>) => void;

  // destroy is called as the Addable is being removed from the map. You MUST
  // release any resources you have acquired, and reverse any changes you have
  // made to dangerousMap that you have not provided an undo() function for.
  readonly destroy?: () => void;

  // repr is used to provide a simple representation for debugging use. It MUST be
  // serialisable to JSON.
  readonly repr?: () => object;

  // compare is an optional method that lets you decide how an Addable that is
  // present in both the previous and current render() calls should be resolved.
  //
  // If you want to make changes, you MUST NOT make them directly in the call
  // to compare(), you must return a function that applies them later.
  //
  // The initial motivating use case is a marker with a position, which may be
  // changed directly in the controlling component's state. This should cause
  // the map marker to move, not to be removed and re-added.
  readonly compare?: (next: Addable) => AddableComparison;
}


// AddableManager provides a set of automatically reversible functions for interacting
// with mapboxgl.Map in an Addable's add() function.
//
// You can elbow your way around this using dangerousMap, but you MUST either pass
// a callback function to undo() which will reverse your changes, or reverse them
// yourself in Addable.destroy(), otherwise Terrible Things will happen.
//
// You may retain AddableManager inside your Addable's class properties if you wish,
// as long as you do not attempt to use it after Addable.destroy() has returned and
// as long as you don't allow the AddableManager instance to escape your Addable.
//
export interface AddableManager<TMapSetState=DefaultState> {
  readonly dangerousMap: mapboxgl.Map;
  readonly state: Readonly<TMapSetState> | undefined;

  addControl(control: mapboxgl.Control | mapboxgl.IControl, position?: AddControlPosition): void;

  // Set MapSet-local state from this addable.
  setState(stateUpdate: Partial<TMapSetState>): void;

  // Push a function onto the 'undo' stack which will be called when your Addable
  // is removed from the map.
  undo(fn: () => void): void;

  // {{{
  // These definitions are copied almost verbatim from the Mapbox typing, with the
  // exception of the return value, which becomes 'void'
  on<T extends keyof mapboxgl.MapLayerEventType>(type: T, layer: string, listener: (ev: mapboxgl.MapLayerEventType[T] & mapboxgl.EventData) => void): void;
  on<T extends keyof mapboxgl.MapEventType>(type: T, listener: (ev: mapboxgl.MapEventType[T] & mapboxgl.EventData) => void): void;
  on(type: string, listener: (ev: any) => void): void;
  // }}}
}


// Extension of AddableManager for internal purposes:
export interface AddableManagerInternal<TMapSetState> extends AddableManager<TMapSetState> {
  knockDown(): void;
}


export type AddableComparison =
  { result: 'keep' } |
  { result: 'replace' } |
  { result: 'update'; update: () => void };


export type AddableChange =
  { readonly resolution: 'add' } |
  { readonly resolution: 'update'; update(): void };


// {{{ These are just cached instances, used internally.
const compareKeep: AddableComparison = { result: 'keep' };
const compareReplace: AddableComparison = { result: 'replace' };
const changeAdd: AddableChange = { resolution: 'add' };
// }}}


// RetainedAddable holds the primary 'Implementation' instance of the addable and the
// AddableManager that contains its knockDown list. It holds the first instance of the
// Addable, the one which is allowed to retain state.
export type RetainedAddable = {
  readonly addable: Addable;
  readonly manager: AddableManagerInternal<DefaultState>;
};


export type AddableActions = {
  readonly changed: boolean;
  readonly removes: ReadonlyArray<RetainedAddable>;
  readonly changes: { [key: string]: Readonly<AddableChange> };
};


// It's very important that you pass the retained addables that are registered
// with mapbox to this function, rather than the previous index's addables. The
// previous index's addables may never have had add() called - they may not be
// the same instances.
export function computeAddableChanges(
  retaineds: { [key: string]: RetainedAddable } | undefined,
  news: { [key: string]: Addable },
): AddableActions {

  if (!retaineds) {
    retaineds = {};
  }

  const changes: { [key: string]: AddableChange } = {};
  const removes: RetainedAddable[] = [];
  let changed = false;

  for (const key of Object.keys(retaineds)) {
    const retainedAddable = retaineds[key];
    const newAddable = news[key];
    if (!newAddable) {
      changed = true;
      removes.push(retainedAddable);
      continue;
    }

    const comparison = retainedAddable.addable.compare
      ? retainedAddable.addable.compare(newAddable)
      : compareKeep;

    if (comparison.result === 'replace') {
      changed = true;
      removes.push(retainedAddable);
      changes[key] = changeAdd;

    } else if (comparison.result === 'update') {
      changed = true;
      changes[key] = { resolution: 'update', update: comparison.update };

    } else if (comparison.result === 'keep') {
      // do nothing

    } else {
      assertNever(comparison);
    }
  }

  for (const key of Object.keys(news)) {
    if (key in retaineds) {
      continue;
    }
    changes[key] = changeAdd;
    changed = true;
  }

  return { changed: removes.length > 0 || changed, changes, removes };
}
