import { Space } from 'generated/mos/structure';
import { CreateSpaceRequest, UpdateSpaceRequest } from 'generated/mos/structuremanagement';
import { assertNever, Invariant, pick } from 'helpers/core';
import { Status, statusSelector } from 'helpers/status';

import { initialSpacesAppState, SpacesAppState, SpacesFilterState } from './store';


export interface SpaceCreateRequestArgs {
  readonly input: CreateSpaceRequest.Entity;
  readonly onSuccess: (ref: Space.Ref) => void;
}

export interface SpaceEditBeginArgs {
  readonly ref: Space.Ref;
}

export interface SpaceEditDeleteArgs {
  readonly onSuccess?: () => void;
}

export interface SpaceEditSaveArgs {
  readonly onSuccess?: (ref: Space.Ref) => void;
  readonly spaceInput: UpdateSpaceRequest.Entity;
}

// {{{ actions

// Internal action creators:
export const actionCreators = {
  spaceEditBeginRequest: (ref: Space.Ref) =>
    ({ domain: 'spaces' as const, type: 'spaceEditBeginRequest' as const, ref }),

  spaceEditBeginSuccess: (space: Space.Entity) => {
    if (!space || !space.ref) {
      throw new Error("space was missing a ref");
    }
    return { domain: 'spaces' as const, type: 'spaceEditBeginSuccess' as const, space, ref: space.ref };
  },

  spaceEditSaveRequest: (args: SpaceEditSaveArgs) =>
    ({ domain: 'spaces' as const, type: 'spaceEditSaveRequest' as const, args }),

  spaceEditDeleteRequest: (args: SpaceEditDeleteArgs) =>
    ({ domain: 'spaces' as const, type: 'spaceEditDeleteRequest' as const, args}),

  spaceEditClear: () =>
    ({ domain: 'spaces' as const, type: 'spaceEditClear' as const }),

  spaceEditFailure: (ref: Space.Ref, message: string) =>
    ({ domain: 'spaces' as const, type: 'spaceEditFailure' as const, ref, message }),

  spaceEditUpdate: (value: SpacesAppState['spaceEdit']) =>
    ({ domain: 'spaces' as const, type: 'spaceEditUpdate' as const, value }),

  spaceCreateRequest: (args: SpaceCreateRequestArgs) =>
    ({ domain: 'spaces' as const, type: 'spaceCreateRequest' as const, args }),

  spaceCreateSuccess: (space: Space.Entity) => {
    if (!space.ref) {
      throw new Error("space was missing a ref");
    }
    return { domain: 'spaces' as const, type: 'spaceCreateSuccess' as const, ref: space.ref };
  },

  spaceCreateFailure: (message: string) =>
    ({ domain: 'spaces' as const, type: 'spaceCreateFailure' as const, message }),

  spaceCreateUpdate: (value: SpacesAppState['spaceCreate']) =>
    ({ domain: 'spaces' as const, type: 'spaceCreateUpdate' as const, value }),


  spacesFiltersExpand: (key: string, expanded: boolean) =>
    ({ domain: 'spaces' as const, type: 'spacesFiltersExpand' as const, key, expanded }),

  spacesFiltersUpdate: (updates: Partial<SpacesFilterState>) =>
    ({ domain: 'spaces' as const, type: 'spacesFiltersUpdate' as const, updates }),


  spacesInvalidate: () =>
    ({ domain: 'spaces' as const, type: 'spacesInvalidate' as const }),

  spacesRequest: () =>
    ({ domain: 'spaces' as const, type: 'spacesRequest' as const }),

  spacesUpdate: (value: SpacesAppState['spacesList']) =>
    ({ domain: 'spaces' as const, type: 'spacesUpdate' as const, value }),

  spacesFailure: (message: string) =>
    ({ domain: 'spaces' as const, type: 'spacesFailure' as const, message }),

  spaceTypesRequest: () =>
    ({ domain: 'spaces' as const, type: 'spaceTypesRequest' as const }),

  spaceTypesUpdate: (value: SpacesAppState['spaceTypes']) =>
    ({ domain: 'spaces' as const, type: 'spaceTypesUpdate' as const, value }),

  spaceTypesFailure: (message: string) =>
    ({ domain: 'spaces' as const, type: 'spaceTypesFailure' as const, message }),
};

type ActionCreators = typeof actionCreators;
export type SpacesAction = ReturnType<ActionCreators[keyof ActionCreators]>;
export type SpacesActions = {
  [T in keyof ActionCreators]: ReturnType<ActionCreators[T]>;
};

export const spacesBindableActionCreators = pick(
  actionCreators,
  'spaceCreateRequest',

  'spaceEditBeginRequest',
  'spaceEditClear',
  'spaceEditDeleteRequest',
  'spaceEditSaveRequest',

  'spacesFiltersExpand',
  'spacesFiltersUpdate',
  'spacesInvalidate',
  'spacesRequest',
  'spaceTypesRequest',
);

export type SpacesBindableActionCreators = typeof spacesBindableActionCreators;

// }}}

const assertSpaces = (domain: 'spaces') => {
  return domain === 'spaces'
}

export const spacesReducer = (state: SpacesAppState = initialSpacesAppState, action: SpacesAction): SpacesAppState => {
  if (!assertSpaces(action.domain)) {
    return state
  }

  switch (action.type) {
  case 'spaceCreateRequest':
    return { ...state, spaceCreate: { status: Status.Loading }};
  case 'spaceCreateSuccess':
    return {
      ...state,
      spaceCreate: { status: Status.Ready, data: action.ref },
      spacesList: statusSelector.invalidate(state.spacesList),
    };

  case 'spaceCreateUpdate':
    return { ...state, spaceCreate: action.value };

  case 'spaceCreateFailure':
    return { ...state, spaceCreate: { status: Status.Failed, messages: [{ code: "", text: action.message}] }}


  case 'spaceEditClear':
    return { ...state, spaceEdit: { status: Status.Idle }};

  case 'spaceEditFailure':
    return { ...state, spaceEdit: { status: Status.Failed, ref: action.ref, messages: [{ code: "", text: action.message}] }}

  case 'spaceEditBeginSuccess':
    return { ...state, spaceEdit: { status: Status.Ready, data: action.space, ref: action.ref }};

  case 'spaceEditBeginRequest':
    return { ...state, spaceEdit: { status: Status.Loading, ref: action.ref }};

  case 'spaceEditDeleteRequest':
    if (state.spaceEdit.status !== Status.Ready)  {
      throw new Invariant(`expected Ready, found ${state.spaceEdit.status}`); // FIXME: 3.7, use core.ts 'assert'
    }
    return { ...state, spaceEdit: { ...state.spaceEdit, status: Status.Updating }};

  case 'spaceEditSaveRequest':
    return state;

  case 'spaceEditUpdate':
    return { ...state, spaceEdit: action.value };


  case 'spacesFiltersExpand':
    return {
      ...state,
      spacesFilters: {
        ...state.spacesFilters,
        expanded: { ...state.spacesFilters.expanded, [action.key]: action.expanded },
      },
    };

  case 'spacesFiltersUpdate':
    return { ...state, spacesFilters: { ...state.spacesFilters, ...action.updates } };

  case 'spacesInvalidate':
    return { ...state, spacesList: statusSelector.invalidate(state.spacesList) };

  case 'spacesUpdate':
    return { ...state, spacesList: action.value };

  case 'spacesFailure':
    return { ...state, spacesList: { status: Status.Failed, messages: [{ code: "", text: action.message}] }}

  case 'spaceTypesUpdate':
    return { ...state, spaceTypes: action.value };

  case 'spaceTypesFailure':
    return { ...state, spaceTypes: { status: Status.Failed, messages: [{ code: "", text: action.message}] }}

  case 'spaceTypesRequest':
    return { ...state, spaceTypes: { status: Status.Loading }};

  case 'spacesRequest':
    return { ...state, spacesList: { status: Status.Loading }};

  default:
    assertNever(action); // FIXME not sure we can use this with sync actions (unless we use action creators)
    throw new Invariant(); // FIXME: 3.7, assert keyword should obviate the need for this
  }
};
