import React from 'react'

import { connect } from 'containers/store';
import { pick } from 'helpers/core';
import { Button, buttonReset, solidCtaFocus } from 'components/ui/button'
import { Link } from 'components/ui/link'
import { VersionModal } from 'components/version-modal';
import { space } from 'helpers/style'
import styled, { css } from 'styled';

import { AuthSession } from 'services';
import { IconArrow } from '../icons';

const MenuToggleButton = styled.button<{darkTheme?: boolean}>`
  ${buttonReset}
  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${solidCtaFocus}
  
  &:focus {
    &::after {
      top: auto;
      width: calc(100% + 10px);
    }
  }
`;

const MenuToggleIcon = styled(IconArrow)`
  transition: ${({ theme }) => theme.transition.base};
`;

const Menu = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  width: 226px;
  box-shadow: 0 2px 4px 0 rgba(194,194,194,0.5);
  background-color: ${({ theme }) => theme.color.white};
  z-index: 99999;
  border: solid 1px ${({ theme }) => theme.color.grayL80};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::before, &::after {
    position: absolute;
    right: 12px;
    top: -6px;
    content: '';
    display: block;
    border-width: 6px 6px 0;
    border-top-width: 0;
    border-bottom-width: 6px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.white} transparent transparent;
    border-top-color: transparent;
    border-bottom-color: ${({ theme }) => theme.color.white};
  }

  &::before {
    border-bottom-color: ${({ theme }) => theme.color.grayL80};
    top: -7px;
  }
`;

const UserInitial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary};
  width: 80px;
  height: 80px;
  border-radius: 100px;
  font-size: 36px;
`;

const UserInfo = styled.div`
  margin: ${space(9)} 0 ${space(8)} 0;
  padding: 0 ${space(4)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserText = styled.span<{ large?: boolean }>`
  color: ${({ large, theme }) => large && theme.color.darkText};
  font-size: ${({ large, theme }) => large ? theme.font.size.medium : theme.font.size.small};
  margin-top: ${({ large }) => large && space(2)};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserOption = styled.div`
  border-top: solid 1px #ddd;
  text-align: left;
  font-size: ${({ theme }) => theme.font.size.medium};
  > button, > a {
    font-weight: ${({ theme }) => theme.font.weight.light};
    margin: 0;
    padding: ${space(3)};
    display: block;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: ${({ theme }) => theme.color.grayL90};
      transition: ${({ theme }) => theme.transition.base};
    }
    &:active {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

const optionStyles = css`
  color: ${({ theme }) => theme.color.grayD80};
  font-size: ${({ theme }) => theme.font.size.base};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.grayD80};
  }
`

const MenuButton = styled(Button)`
  ${optionStyles}
`

const MenuLink = styled(Link)`
  ${optionStyles}
`

type State = {
  readonly show: boolean;
  readonly showVersionModal?: boolean;
};

type DirectProps = {
  readonly darkTheme?: boolean;
};

type ConnectedProps = {
  readonly session: AuthSession | undefined;
};

type Props = DirectProps & ConnectedProps;

class UserMenuView extends React.Component<Props, State> {
  private outerRef = React.createRef<HTMLDivElement>();

  public constructor(props: Props) {
    super(props);
    this.state = { show: false };
  }

  private onClickAway = (e: MouseEvent) => {
    const { current } = this.outerRef;

    if (e.target instanceof Node && (!current || current.contains(e.target))) {
      return;
    }
    this.setState({ show: false });
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.onClickAway, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickAway, false);
  }

  public render() {
    const { session } = this.props;

    if(session) {
      const user = session.user;

      return(
        <div ref={this.outerRef} style={{ position: 'relative' }}>
          {this.state.showVersionModal &&
            <VersionModal onClose={() => this.setState({ showVersionModal: false })} />
          }

          <MenuToggleButton darkTheme={this.props.darkTheme} onClick={() => this.setState((state) => ({ show: !state.show })) } >
            {user.name.trim().split(' ')[0]}
            <MenuToggleIcon size="base" rotate={this.state.show ? 180 : 0} color={this.props.darkTheme ? 'grayL60' : 'gray'} />
          </MenuToggleButton>

          {this.state.show && (
            <Menu>
              <UserInfo>
                <UserInitial>{user.name[0]}</UserInitial>
                <UserText large>{user.name}</UserText>
                <UserText>{user.email}</UserText>
              </UserInfo>

              <UserOption>
                <MenuButton appearance="secondary" variant="link" onClick={() => this.setState({ showVersionModal: true })}>
                  Version details
                </MenuButton>
              </UserOption>

              <UserOption>
                <MenuLink appearance="secondary" to="/sign-out">Logout</MenuLink>
              </UserOption>
            </Menu>
          )}
        </div>
      );
    }
  }
}

export const UserMenu = connect<ConnectedProps, {}, DirectProps>(
  (store) => pick(store.account, 'session'),
)(UserMenuView);
