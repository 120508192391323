import React from 'react'
import { Redirect } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import { RouteConfig } from 'routes/route-config'

// general.
import { Dashboard } from 'pages/dashboard'
import { Error404Page } from 'pages/error/404'

import { ToursPage } from './components/tours'
import { TourUpdatePage } from './components/tours/tour-update'
import { ProjectsPage } from './components/projects'
import { AtmosphericZonePage } from './components/atmospheric-zones'
import { AtmosphericZoneUpdatePage } from './components/atmospheric-zones/atmospheric-update'

import { SoundEffectZonePage } from './components/sound-effect-zones'
import { PropertiesWayfindingPage } from './components/properties-wayfinding'
import { PropertiesWayfindingTranslationsPage } from './components/properties-wayfinding/properties-wayfinding-translations'
import { SoundEffectPage } from './components/sound-effect-zones/sound-effect-zones-update'
import { StopUpdatePage } from './components/stops/stop-update'
import { StopTranslationsPage } from './components/stops/stop-translations'

import { PublishingPage } from './components/publishing'
import { ReleasesPage } from './components/publishing/releases'
import { HoldingAudioPage } from './components/holding-audio'

const WamRoutes = () => (
  <Switch>
    {/* tours. */}
    <Route
      exact
      path="/project/:projectId/tours"
      render={({ match, history }) => (
        <ToursPage
          title="Tours"
          projectId={match.params.projectId}
          navBackRoute="/"
          historyPush={history.push}
        />
      )}
    />
    {/* holding audio */}
    <Route
      exact
      path="/project/:projectId/holding-audio"
      render={({ match, history }) => (
        <HoldingAudioPage
          title="Holding Audio"
          projectId={match.params.projectId}
          navBackRoute="/"
          historyPush={history.push}
        />
      )}
    />
    {/* stops. */}
    <Route
      exact
      path="/project/:projectId/tour/:tourId"
      render={({ match }) => (
        <Redirect
          to={{ pathname: `/project/${match.params.projectId}/tour/${match.params.tourId}/stops` }}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/stops"
      render={({ match, history }) => (
        <TourUpdatePage
          tourId={match.params.tourId}
          projectId={match.params.projectId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/stop/:stopId"
      render={({ history, match }) => (
        <StopUpdatePage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          stopId={match.params.stopId}
          historyPush={history.push}
        />
      )}
    />
    {/* atmospheric-zones. */}
    <Route
      exact
      path="/project/:projectId/tour/:tourId/atmospheric-zones"
      render={({ match, history }) => (
        <AtmosphericZonePage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/atmospheric-zone/:atmosphericZoneId"
      render={({ history, match }) => (
        <AtmosphericZoneUpdatePage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          atmosphericZoneId={match.params.atmosphericZoneId}
          historyPush={history.push}
        />
      )}
    />
    {/* sound-effect-zones. */}
    <Route
      exact
      path="/project/:projectId/tour/:tourId/sound-effect-zones"
      render={({ match, history }) => (
        <SoundEffectZonePage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/sound-effect-zone/:soundEffectZoneId"
      render={({ history, match }) => (
        <SoundEffectPage
          projectId={match.params.projectId}
          soundEffectZoneId={match.params.soundEffectZoneId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    {/* properties. */}
    <Route
      exact
      path="/project/:projectId/tour/:tourId/properties"
      render={({ match, history }) => (
        <PropertiesWayfindingPage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/properties/translations"
      render={({ match, history }) => (
        <PropertiesWayfindingTranslationsPage
          projectId={match.params.projectId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/tour/:tourId/stop/:stopId/translations"
      render={({ match, history }) => (
        <StopTranslationsPage
          projectId={match.params.projectId}
          stopId={match.params.stopId}
          tourId={match.params.tourId}
          historyPush={history.push}
        />
      )}
    />
    {/* publishing. */}
    <Route
      exact
      path="/project/:projectId/publishing"
      render={({ history, match }) => (
        <PublishingPage
          projectId={match.params.projectId}
          historyPush={history.push}
        />
      )}
    />
    <Route
      exact
      path="/project/:projectId/releases"
      render={({ history, match }) => (
        <ReleasesPage
          projectId={match.params.projectId}
          historyPush={history.push}
        />
      )}
    />
    <Route render={() => <Error404Page />} />
  </Switch>
)

// const AnalyticsRoutes = () => (
//   <Switch>
//     <Route
//       exact path="/analytics"
//       render={({ match }) => (
//         <Redirect to={{ pathname: '/analytics/sessions' }} />
//       )}
//     />
//     <Route
//       exact path="/analytics/sessions"
//       render={() => (
//         <AnalyticsSessions />
//       )}
//     />
//     <Route
//       exact path="/analytics/stops"
//       render={() => (
//         <AnalyticsStops />
//       )}
//     />
//     <Route
//       exact path="/analytics/tours"
//       render={() => (
//         <AnalyticsTours />
//       )}
//     />
//     <Route render={() => <Error404Page />} />
//   </Switch>
// )

/* eslint-disable react/display-name */
export const wamRoutes: ReadonlyArray<RouteConfig> = [
  {
    path: '/',
    exact: true,
    render: () => <ProjectsPage />,
  },
  {
    path: '/project',
    render: () => <WamRoutes />,
  },
  {
    path: '/sitemanagement',
    exact: true,
    render: () => <Dashboard />,
  },
]
/* eslint-enable react/display-name */
