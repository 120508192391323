import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import {
  GetSoundEffectZoneRequest,
  GetSoundEffectZoneResponse,
  UpdateSoundEffectZoneRequest,
  UpdateSoundEffectZoneResponse,
  DeleteSoundEffectZoneRequest,
  DeleteSoundEffectZoneResponse,
} from '../types'
import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const soundEffectZonesActionCreators = {
  wamGetSoundEffectZoneRequest: (
    payload: GetSoundEffectZoneRequest,
  ): FluxStandardAction<GetSoundEffectZoneRequest, 'wamGetSoundEffectZoneRequest'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamGetSoundEffectZoneRequest' as const,
    payload,
  }),
  wamGetSoundEffectZoneSuccess: (
    payload: GetSoundEffectZoneResponse,
  ): FluxStandardAction<GetSoundEffectZoneResponse, 'wamGetSoundEffectZoneSuccess'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamGetSoundEffectZoneSuccess' as const,
    payload,
  }),
  wamGetSoundEffectZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamGetSoundEffectZoneFailure'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamGetSoundEffectZoneFailure' as const,
    payload,
    error: true,
  }),
  wamUpdateSoundEffectZoneRequest: (
    payload: UpdateSoundEffectZoneRequest,
  ): FluxStandardAction<UpdateSoundEffectZoneRequest, 'wamUpdateSoundEffectZoneRequest'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamUpdateSoundEffectZoneRequest' as const,
    payload,
  }),
  wamUpdateSoundEffectZoneSuccess: (
    payload: UpdateSoundEffectZoneResponse,
  ): FluxStandardAction<UpdateSoundEffectZoneResponse, 'wamUpdateSoundEffectZoneSuccess'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamUpdateSoundEffectZoneSuccess' as const,
    payload,
  }),
  wamUpdateSoundEffectZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamUpdateSoundEffectZoneFailure'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamUpdateSoundEffectZoneFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteSoundEffectZoneRequest: (
    payload: DeleteSoundEffectZoneRequest,
  ): FluxStandardAction<DeleteSoundEffectZoneRequest, 'wamDeleteSoundEffectZoneRequest'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamDeleteSoundEffectZoneRequest' as const,
    payload,
  }),
  wamDeleteSoundEffectZoneSuccess: (
    payload: DeleteSoundEffectZoneResponse
  ): FluxStandardAction<DeleteSoundEffectZoneResponse, 'wamDeleteSoundEffectZoneSuccess'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamDeleteSoundEffectZoneSuccess' as const,
    payload
  }),
  wamDeleteSoundEffectZoneFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamDeleteSoundEffectZoneFailure'> => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamDeleteSoundEffectZoneFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteSoundEffectZoneReset: () => ({
    domain: 'wam.sound-effect-zones' as const,
    type: 'wamDeleteSoundEffectZoneReset' as const
  }),
}

type WamAction = ReturnType<typeof soundEffectZonesActionCreators[keyof typeof soundEffectZonesActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.sound-effect-zones'
}

export const soundEffectZonesReducer = (
  state: WamAppState['soundEffectZones'] = initialWamAppState.soundEffectZones,
  action: WamAction,
): WamAppState['soundEffectZones'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamGetSoundEffectZoneRequest': {
      return {
        ...state,
        soundEffectZoneUpdate: {
          status: Status.Loading,
        },
      }
    }
    case 'wamGetSoundEffectZoneSuccess': {
      const { payload } = action
      return {
        ...state,
        soundEffectZoneUpdate: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamGetSoundEffectZoneFailure': {
      const { payload } = action
      return {
        ...state,
        soundEffectZoneUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamUpdateSoundEffectZoneRequest': {
      if (state.soundEffectZoneUpdate.status !== Status.Ready) {
        throw new Error(
          '[ wamUpdateSoundEffectZoneRequest ] Cannot update entity when `soundEffectZoneUpdate` is not in Ready state',
        )
      }
      return {
        ...state,
        soundEffectZoneUpdate: {
          ...state.soundEffectZoneUpdate,
          status: Status.Updating,
        },
      }
    }
    case 'wamUpdateSoundEffectZoneSuccess': {
      const { payload } = action
      return {
        ...state,
        soundEffectZoneUpdate: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamUpdateSoundEffectZoneFailure': {
      const { payload } = action
      return {
        ...state,
        soundEffectZoneUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteSoundEffectZoneRequest': {
      return {
        ...state,
        soundEffectZoneDelete: {
          status: Status.Loading
        }
      }
    }
    case 'wamDeleteSoundEffectZoneSuccess': {
      return {
        ...state,
        soundEffectZoneDelete: {
          status: Status.Complete,
          message: 'Sound effect deleted',
        }
      }
    }
    case 'wamDeleteSoundEffectZoneFailure': {
      const { payload } = action
      return {
        ...state,
        soundEffectZoneDelete: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteSoundEffectZoneReset': {
      return {
        ...state,
        soundEffectZoneDelete: {
          status: Status.Idle
        }
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
