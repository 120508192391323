/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaAudio = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-3 5h-3.77v4.64a2.525 2.525 0 00-.807-.14C10.083 12.5 9 13.505 9 14.75S10.082 17 11.423 17c1.244 0 2.262-.875 2.396-2h.027V9.5H16V8z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaAudioString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-3 5h-3.77v4.64a2.525 2.525 0 00-.807-.14C10.083 12.5 9 13.505 9 14.75S10.082 17 11.423 17c1.244 0 2.262-.875 2.396-2h.027V9.5H16V8z"  fillRule="nonzero" />
  </svg>`

IconMediaAudio.displayName = 'IconMediaAudio'
