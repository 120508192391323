/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconVContext = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M12.75 6.5a2.758 2.758 0 002.75-2.75A2.758 2.758 0 0012.75 1 2.758 2.758 0 0010 3.75a2.758 2.758 0 002.75 2.75zm0 2.75A2.758 2.758 0 0010 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 0015.5 12a2.758 2.758 0 00-2.75-2.75zm0 8.25A2.758 2.758 0 0010 20.25 2.758 2.758 0 0012.75 23a2.758 2.758 0 002.75-2.75 2.758 2.758 0 00-2.75-2.75z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconVContextString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M12.75 6.5a2.758 2.758 0 002.75-2.75A2.758 2.758 0 0012.75 1 2.758 2.758 0 0010 3.75a2.758 2.758 0 002.75 2.75zm0 2.75A2.758 2.758 0 0010 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 0015.5 12a2.758 2.758 0 00-2.75-2.75zm0 8.25A2.758 2.758 0 0010 20.25 2.758 2.758 0 0012.75 23a2.758 2.758 0 002.75-2.75 2.758 2.758 0 00-2.75-2.75z"  fillRule="nonzero" />
  </svg>`

IconVContext.displayName = 'IconVContext'
