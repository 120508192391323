import {Invariant} from 'helpers/core';
import {MapView} from 'components/mapbox';
import {refToUrn} from 'entity';
import {
  AdminMap,
  Site,
  BuildingLevel,
  OutdoorLevel,
  Building,
} from 'generated/mos/structure';

import {StructureFilterState} from '..';
import {
  GlobalMapState,
  StructuresMessage,
} from '../store';
import {
  StructureEntity,
  SpatialOption,
  SpatialOptions,
  SpatialOptionIndex,
  Structures,
  spatialOptionSort,
} from '../entities';

const spatialOptionInitNew = (args: {
  item: StructureEntity;
  parents?: SpatialOption[];
}): SpatialOption => {
  const {item, parents} = args;
  if (item.ref === undefined) {
    throw new Invariant('missing ref');
  }
  return {
    ref: item.ref,
    refUrn: refToUrn(item.ref),
    parents: parents || [],
    name: item.name,
    fullName: !parents
      ? item.name
      : parents
          .map(v => v.name)
          .concat([item.name])
          .join(' > '),
  };
};

const buildSpatialOptions = (payload: StructuresPayload): SpatialOptions => {
  const flatLeaves: SpatialOption[] = [];
  const index = new SpatialOptionIndex();

  for (const site of payload.sites) {
    const siteOption = spatialOptionInitNew({item: site});
    index.add(siteOption, undefined);

    if (site.outdoorLevelRef) {
      const item = payload.outdoorLevels.find(
        level =>
          OutdoorLevel.mustRef(site.outdoorLevelRef).id ===
          OutdoorLevel.mustRef(level.ref).id,
      );
      if (item === undefined) {
        throw new Invariant('missing item');
      }
      const outdoorLevelOption = spatialOptionInitNew({
        item,
        parents: [siteOption],
      });
      index.add(outdoorLevelOption, siteOption);
      flatLeaves.push(outdoorLevelOption);
    }

    for (const buildingRef of (site.buildingRefs || [])) {
      const building = payload.buildings.find(
        building => building.ref!.id === buildingRef.id,
      );
      if (building === undefined) {
        throw new Invariant('missing item');
      }
      const buildingOption = spatialOptionInitNew({
        item: building,
        parents: [siteOption],
      });
      index.add(buildingOption, siteOption);

      for (const buildingLevelRef of (building.buildingLevelRefs || [])) {
        const buildingLevel = payload.buildingLevels.find(
          level => level.ref!.id === buildingLevelRef.id,
        );
        if (buildingLevel === undefined) {
          throw new Invariant('missing item');
        }
        const buildingLevelOption = spatialOptionInitNew({
          item: buildingLevel,
          parents: [siteOption, buildingOption],
        });
        index.add(buildingLevelOption, buildingOption);
        flatLeaves.push(buildingLevelOption);
      }
    }
  }

  return {
    leaves: flatLeaves.sort(spatialOptionSort),
    ...index.seal(),
  };
};

export const buildStructuresData = (
  payload: StructuresPayload,
): Structures => {
  let index: Structures['index'] = {};
  let parentRef: Structures['parentRef'] = {};
  let childRefs: Structures['childRefs'] = {};

  for (const site of payload.sites) {
    const refUrn = refToUrn(Site.mustRef(site.ref));
    index = {...index, [refUrn]: site};
    const outdoorLevelRefs = site.outdoorLevelRef ? [site.outdoorLevelRef] : [];
    const buildingRefs = site.buildingRefs ? site.buildingRefs : [];
    childRefs = {
      ...childRefs,
      [refUrn]: [...buildingRefs, ...outdoorLevelRefs],
    };
  }

  for (const outdoorLevel of payload.outdoorLevels) {
    const refUrn = refToUrn(OutdoorLevel.mustRef(outdoorLevel.ref));
    index = {...index, [refUrn]: outdoorLevel};
    parentRef = {
      ...parentRef,
      [refUrn]: Site.mustRef(outdoorLevel.siteRef),
    };
  }

  for (const building of payload.buildings) {
    const refUrn = refToUrn(Building.mustRef(building.ref));
    index = {...index, [refUrn]: building};
    parentRef = {
      ...parentRef,
      [refUrn]: Site.mustRef(building.siteRef),
    };
    childRefs = {
      ...childRefs,
      [refUrn]: building.buildingLevelRefs,
    };
  }

  for (const buildingLevel of payload.buildingLevels) {
    const refUrn = refToUrn(BuildingLevel.mustRef(buildingLevel.ref));
    index = {...index, [refUrn]: buildingLevel};
    parentRef = {
      ...parentRef,
      [refUrn]: Building.mustRef(buildingLevel.buildingRef),
    };
  }

  return {
    ...payload,
    spatialOptions: buildSpatialOptions(payload),
    index,
    parentRef,
    childRefs,
  };
};

type GlobalMapUpdateArgs = MapView | Partial<GlobalMapState>;

function isMapViewEvent(v: GlobalMapUpdateArgs): v is MapView {
  return (v as any).center !== undefined && (v as any).zoom !== undefined;
}

type StructuresPayload = {
  sites: ReadonlyArray<Site.Entity>;
  buildings: ReadonlyArray<Building.Entity>;
  buildingLevels: ReadonlyArray<BuildingLevel.Entity>;
  outdoorLevels: ReadonlyArray<OutdoorLevel.Entity>;
  adminMaps: ReadonlyArray<AdminMap.Entity>;
};

export const actionCreators = {
  globalMapUpdate: (args: GlobalMapUpdateArgs) =>
    isMapViewEvent(args)
      ? {
          domain: 'structures' as const,
          type: 'globalMapUpdate' as const,
          mapState: {view: args},
        }
      : {
          domain: 'structures' as const,
          type: 'globalMapUpdate' as const,
          mapState: args,
        },

  structuresFiltersUpdate: (updates: Partial<StructureFilterState>) => ({
    domain: 'structures' as const,
    type: 'structuresFiltersUpdate' as const,
    updates,
  }),

  structuresInvalidate: () => ({
    domain: 'structures' as const,
    type: 'structuresInvalidate' as const,
  }),

  structuresLoadRequest: () => ({
    domain: 'structures' as const,
    type: 'structuresLoadRequest' as const,
  }),

  structuresLoadFailure: (message: StructuresMessage) => ({
    domain: 'structures' as const,
    type: 'structuresLoadFailure' as const,
    message,
  }),

  structuresLoadSuccess: (payload: StructuresPayload) => ({
    domain: 'structures' as const,
    type: 'structuresLoadSuccess' as const,
    payload,
  }),
};
