import React, { useState } from 'react'
import { Field } from 'react-formage'

import { Button } from 'components/ui/button'
import { IconEdit, IconLink } from 'components/ui/icons'

import {
  LinkableOverride,
  LinkableOverrideOptions,
} from 'domains/linkable/components/linkable-override'

import { space } from 'helpers/style'
import styled from 'styled'

type Props = {
  readonly component?: 'input' | 'textarea';
  /**
   * Only has an impact when `component=input` is used.
   */
  readonly type: 'email' | 'text' | 'password';
  /**
   * This maps to the property name in the `formage` form bag.
   */
  readonly field: string;
  readonly label: string;
  /**
   * Optional copy which sits to the right of the label in a subtle colour.
   */
  readonly labelSupportingText?: string;
  /**
   * Option to visually hide the label.
   */
  readonly labelHidden?: boolean;
  /**
   * An optional placeholder within the element. Check with the content or design team if not available in visual designs.
   */
  readonly placeholder?: string;
  /**
   * Displays below the form element describing it's purpose.
   */
  readonly description?: string;
  /**
   * Used for styling purposes only.
   */
  readonly isAssociated?: boolean;
  readonly isDisabled?: boolean;
  /**
   * Configuration object for linked field behaviour.
   */
  readonly linkableOptions?: LinkableOverrideOptions;
  /**
   * A validation error.
   */
  readonly error?: string;
  /**
   * A warning that there is a publishing issue.
   */
  readonly warning?: string;

  readonly onKeyUp?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldWrapper = styled.div<{ isAssociated?: boolean }>`
  margin-bottom: ${({ isAssociated }) => isAssociated ? space(4) : space(6)};
  text-align: left;
  ${({ isAssociated, theme }) => !isAssociated ? null :`
    padding-left: ${space(4)};
    border-left: 3px solid ${theme.color.grayL70};
  `}
`

const LinkedFieldIcon = styled(IconLink)`
  display: inline-block;
  margin-right: ${space(1)};
  vertical-align: middle;
`

const Label = styled.label`
  color: ${({ theme }) => theme.color.grayD60};
  font-weight: ${({ theme }) => theme.font.weight.base};
`

const LabelSupport = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const HiddenLabel = styled.span`
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden
`

const Description = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.gray};
  line-height: ${space(6)};
`

const Error = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.error};
  line-height: ${space(6)};
`

const Warning = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.warning};
  line-height: ${space(6)};
`

type TextElementProps = {
  readonly disabled?: boolean;
  readonly invalid?: boolean;
}

const TextElement = styled(Field)<TextElementProps>`
  display: block;
  padding: ${space(4)};
  margin-top: ${space(2)};
  margin-bottom: ${space(1)};
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.color.grayL80};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.gray};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'auto'};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 1.2;

  &[component=textarea] {
    height: 125px;
    resize: none;
  }

  &:hover {
    ${({ disabled, theme }) => !disabled && `
      border-color: ${theme.color.grayL40};
    `}
  }

  &:active,
  &:focus {
    ${({ disabled, theme }) => !disabled && `
      outline: solid 2px ${theme.color.primaryD40};
    `}
  }

  &.is-linked {
    border: solid 1px ${({ theme }) => theme.color.grayL70};
    cursor: default;
    padding-right: ${space(10)};
  }

  &.has-error {
    border-bottom-width: 2px;
    border-bottom-color: ${({ theme }) => theme.color.error};

    &:hover {
      border-bottom-color: ${({ theme }) => theme.color.error};
    }
  }

  &.is-overriding {
    ${({ theme }) => {
      const gray = theme.color.grayL80
      return `
        background-image: linear-gradient(135deg, ${gray} 25%, transparent 25%, transparent 50%, ${gray} 50%, ${gray} 75%, transparent 75%, transparent);
        background-size: 8px 8px;
      `
    }}
  }

  ${({ disabled }) => disabled && `
    background-color: transparent;
  `}
`;

const LinkedFieldEditButton = styled.div`
  position: absolute;
  right: ${space(1)};
  top: 50%;
  transform: translate(0, -50%);
`

/**
 * The only props which are mandatory are the `label` and the `field`. The field value is a string representing the name of the value the element should control in the formage formbag.
 *
 * An optional `labelSupportText` prop can be used to provide supporting text for the fields label, and should be visually more subtle than the label copy itself.
 *
 * The `isAssociated` prop is used to change the visual style of the element when it relates to another field above it, for example alternate text, credits etc.
 *
 * ## Textarea
 *
 * This component also provides a textarea field. This is triggered by passing `component="textarea"`.
 *
 * ## Accessibility
 *
 * The field is wrapped with a `label` element to avoid the need to pass ids into the component. Focus states should be tested when altering the styles of this component.
 *
 * There is a `labelHidden` prop which visually hides the label, but it is still available to assistive technologies.
 *
 * ## Validation feedback
 *
 * The `error` prop is used to display field validation issues. The `warning` prop is used to display issues related to publishing snapshots. In cases where both an error and a warning are presented to the field, only the error will be displayed.
 *
 * ## Linked fields
 *
 * If a field is linked we need to pass a configuration object through the `linkableOptions` prop to allow the linking functionality to operate. This object consists of the following options:
 *
 * - `alternateFieldValues`: Attribute Ref based values to select from based on linked field values mappings.
 * - `allowCustomOverride`: When a field is linkable we generally allow the user to specify a custom override value rather than selecting another linked field value. This prop can be used to disable that option when set to false.
 * - `onUpdate`: **Required** This passes the value from the override form back to the parent components state.
 *
 * At least one of `alternateFieldValues` or `allowCustomOverride` must be truthy.
 *
 * When using linked field values the intention is that the original value from the entity in Redux can be used to display the current value in this field. Potential overrides should be handled separately within local state and can be kept updated with the `onUpdate` function.
 *
 * When the user wants to save the updates we should check to see if an override has been set (a non-`undefined` value is present) and use that value in place of the original. This override value may either be an Attribute Ref or a standard MOS value (string, Media Ref etc.). This also allows us to have manual control of setting `updateMask`s in the parent form component.
 */
export const Input = (props: Props) => {
  const {
    component,
    description,
    error,
    field,
    isAssociated,
    isDisabled,
    label,
    labelSupportingText,
    linkableOptions,
    labelHidden,
    warning,
    ...rest
  } = props
  const hasError = error && !!error.trim().length
  const hasWarning = warning && !!warning.trim().length
  const allowCustomOverride = linkableOptions && linkableOptions.allowCustomOverride !== undefined ? linkableOptions.allowCustomOverride : true

  const [ isOverriding, setOverrideMode ] = useState(false)

  const renderLabel = () => (
    <>
      {linkableOptions ? (<LinkedFieldIcon size="base" />) : null}
      {label}
      {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
    </>
  )

  return (
    <FieldWrapper isAssociated={isAssociated}>
      <Label>
        {labelHidden ? (
          <HiddenLabel aria-hidden="true">{renderLabel()}</HiddenLabel>
        ) :
          renderLabel()
        }
        <div style={{ position: 'relative' }}>
          <TextElement
            {...rest}
            aria-invalid={hasError || hasWarning ? true : null}
            className={`
              ${hasError ? 'has-error' : ''}
              ${linkableOptions ? 'is-linked' : ''}
              ${isOverriding ? 'is-overriding' : ''}
            `}
            component={component}
            disabled={isDisabled || !!linkableOptions}
            name={field}
            type={component === 'input' ? rest.type : undefined}
          />
          {linkableOptions && !isOverriding && (
            <LinkedFieldEditButton>
              <Button
                variant="subtle"
                isIcon={true}
                onClick={() => setOverrideMode(true)}
              >
                <IconEdit />
              </Button>
            </LinkedFieldEditButton>
          )}
        </div>
      </Label>

      {isOverriding && linkableOptions && (
        <LinkableOverride
          label={label}
          alternateFieldValues={linkableOptions.alternateFieldValues}
          customOverrideType={allowCustomOverride ? component : undefined}
          onCancel={() => setOverrideMode(false)}
          onUpdate={(value) => linkableOptions.onUpdate(value)}
        />
      )}

      {hasError && (<Error role="alert">{error}</Error>)}
      {!hasError && hasWarning && (<Warning role="alert">{warning}</Warning>)}
      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}

Input.defaultProps = {
  component: 'input',
  isAssociated: false,
  isDisabled: false,
  labelHidden: false,
  type: 'text',
}
