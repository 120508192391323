import MapboxGLDraw, { DefaultModes } from '@mapbox/mapbox-gl-draw';

import { Addable, AddableManager, MapSet } from 'components/mapbox';
import { AddableComparison } from 'components/mapbox/addable';
import * as geo from 'helpers/geo';


export type OnPolygonUpdated = (e: { polygon: geo.Polygon2D | undefined }) => void;

export class EditableBoundaryMapSet implements MapSet {
  private polygon: geo.Polygon2D | undefined;
  private onUpdated?: OnPolygonUpdated;

  public key: string;

  public constructor({ key, boundary, onUpdated }: { key: string; boundary: geo.Polygon2D | undefined; onUpdated?: OnPolygonUpdated }) {
    this.key = key;
    this.polygon = boundary;
    this.onUpdated = onUpdated;
  }

  public addables(): ReadonlyArray<Addable> {
    return [
      new EditablePolygon('editBoundary', this.polygon, { onUpdated: this.onUpdated }),
    ];
  }
}


type EditablePolygonOptions = {
  readonly onUpdated?: OnPolygonUpdated;
};

class EditablePolygon implements Addable {
  public readonly key: string;

  private draw: MapboxGLDraw<DefaultModes> | undefined;

  private polygon: geo.Polygon2D | undefined;
  private options: EditablePolygonOptions;

  public constructor(key: string, polygon: geo.Polygon2D | undefined, options: EditablePolygonOptions = {}) {
    this.key = key;
    this.polygon = polygon;
    this.options = options;
  }

  private onUpdate = (e: {features: ReadonlyArray<geo.Feature2D<geo.Polygon2D>>}) => {
    if (e.features.length > 1) {
      throw new Error();
    }
    this.polygon = geo.unwrapFeature(e.features[0]);
    if (this.options.onUpdated) {
      this.options.onUpdated({ polygon: this.polygon });
    }
  }

  private onDelete = (e: {features: ReadonlyArray<geo.Feature<geo.Polygon>>}) => {
    // XXX(bw): I noticed some weirdness with this. If you delete a feature using the trash
    // icon, it still shows in the features list that this event receives. As we are only
    // dealing with one polygon, we just assume "if we got a delete, we deleted the only
    // polygon".
    this.polygon = undefined;
    if (this.options.onUpdated) {
      this.options.onUpdated({ polygon: this.polygon });
    }
    if (this.draw) {
      // If the polygon has been removed, we have to switch back into draw mode otherwise
      // the user won't be able to add another one:
      this.draw.changeMode('draw_polygon');
    }
  }

  public add(map: AddableManager) {
    this.draw = new MapboxGLDraw({
      displayControlsDefault: false,
      defaultMode: 'draw_polygon',
      modes: Object.assign({}, MapboxGLDraw.modes),
    });

    map.on('draw.create', this.onUpdate);
    map.on('draw.update', this.onUpdate);
    map.on('draw.delete', this.onDelete);

    map.addControl(this.draw);

    // XXX: you must only muck around with the draw instance _after_ it has been added to the map:
    if (this.polygon) {
      const featureId = this.draw.add(geo.mut(this.polygon))[0];
      this.draw.changeMode('direct_select', { featureId });
    }
  }

  public destroy() {
    if (this.draw) {
      this.draw = undefined;
    }
  }

  public compare(next: Addable): AddableComparison {
    const nextPolygon: this = next as this;

    // We retain a copy of the polygon in here on change, so if compare is
    // called in response to an onUpdate event that passes the polygon out of
    // this, the comparison should succeed:
    if (geo.geoJsonEqual(this.polygon, nextPolygon.polygon)) {
      return { result: 'keep' };
    }

    return { result: 'replace' };
  }
}

