import React from 'react'

import { Loader } from 'components/ui/loader';
import { connect, store } from 'containers/store';
import { pick } from 'helpers/core';
import { setErrorLoggingIdentifiers } from 'helpers/logging'
import { statusSelector } from 'helpers/status';

import { accountBindableActionCreators } from 'domains/account/actions';
import { AccountAppState } from 'domains/account/store';

// {{{ org specific.
import { ORG_SLUG, ORG_NAME, ORG_TITLE } from '../constants'
import { getWamModule } from 'domains/org/wam/actions'
// }}} org specific.

// XXX(bw): This experiment reduces some typing repetition, at the expense of forcing you
// to run back to the AccountAppState to understand the shape of this component:
type ConnectedProps = Pick<AccountAppState, 'session'>;

// type ActionProps = Pick<typeof accountBindableActionCreators, 'loadConfig'>;

type Props = ConnectedProps;

type State = {
  readonly appReady: boolean;
};

class AppView extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      appReady: false,
    };
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    if (prevState.appReady) {
      return null;
    }
  
    document.title = `${ORG_NAME} - ${ORG_TITLE}`;

    if (ORG_SLUG === 'wam') {
      store.addModule(getWamModule())
    }
  
    if (nextProps.session && nextProps.session.user.ref.id) {
      setErrorLoggingIdentifiers({ id: nextProps.session.user.ref.id })
    }

    return { appReady: true };
  }

  // public componentDidMount() {
  //   if (statusSelector.shouldLoad(this.props.configStatus.status)) {
  //     this.props.loadConfig();
  //   }
  // }

  public render() {
    // FIXME(bw): this is a bit weird; getDerivedStateFromProps will see that props.config
    // contains a defined value and update the state, but the subsequent render() will
    // not receive the prop? not sure what I'm doing wrong here.
    // if (!this.state.appReady || !this.props.config) {
    if (!this.state.appReady) {
      return <Loader />;
    }

    return this.props.children;
  }
}

export const App = connect<ConnectedProps, {}, {}>(
  (store) => pick(store.account, 'session'),
  undefined,
  undefined,
  { respondToRouteChanges: true },
)(AppView);
