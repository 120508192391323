import React, { ReactNode } from 'react'

import { SectionHeader } from 'components/ui/section-header'
import styled from 'styled'
import { space } from 'helpers/style'

type FieldsetProps = {
  readonly children: ReactNode;
  readonly legend: string;
}

const FieldsetElement = styled.fieldset`
  position: relative;
  margin: 0 0 ${space(25)};
  padding: 0;
  border: 0;

  legend {
    width: 100%;
    margin-bottom: ${space(6)};
  }
`

const Legend = styled(SectionHeader)``

/**
 * This creates an html `fieldset` container around a group of form fields. These can be nested if required and it is semantic and accessible to do so.
 *
 * The legend element is created using the `SectionHeader` component and so inherits those visual styles.
 *
 * ## FieldSection
 *
 * There is a separate component exposed to create a visual separation between fields, demonstrated in the next story. This is simply using a horizontal rule element and allows us to maintain consistency.
 */
export const Fieldset = (props: FieldsetProps) => (
  <FieldsetElement>
    <Legend heading={props.legend} tag="legend" />
    {props.children}
  </FieldsetElement>
)

export const FieldSection = styled.hr`
  margin: ${space(6)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL70};
`
