import MapboxGLDraw, { DefaultModes } from '@mapbox/mapbox-gl-draw';

import { IconMoveString, IconRotateString } from 'components/ui/icons';
import { Addable, AddableManager, LayerDef, MapSet } from 'components/mapbox';
import { ImageSourceDef } from 'components/mapbox/sources';
import { objectKeys } from 'helpers/core';
import * as geo from 'helpers/geo';
import * as mapStyles from 'helpers/map-styles';

import { drawEventKey, drawMode } from './draw-mode';
import { drawRotateEventKey, drawRotateMode } from './draw-rotate-mode';
import { Dimensions, getAdminMapCorners } from './helpers';


export type Dimensions = Dimensions; // XXX: MQS0002

export type OnAdminMapUpdated = (e: { corners: geo.Corners }) => void;

type State = {
  readonly opacity?: number;
  readonly corners?: geo.Corners;
};

type Options = {
  readonly key: string;
  readonly imageUrl: string;
  readonly dimensions: Dimensions;

  // Corners may be undefined even if we have an imageUrl - in this case, the
  // addable attempts to place the image itself based on its viewport.
  readonly corners: geo.Corners | undefined;

  readonly onUpdated: OnAdminMapUpdated;
};


export class AdminMapEditSet implements MapSet<State> {
  public key: string;

  private corners: geo.Corners | undefined;
  private imageUrl: string;
  private dimensions: Dimensions;
  private onUpdated: OnAdminMapUpdated;

  public constructor({ key, imageUrl, dimensions, corners, onUpdated }: Options) {
    this.corners = corners;
    this.key = key;
    this.imageUrl = imageUrl;
    this.dimensions = dimensions;
    this.onUpdated = onUpdated;
  }

  public addables(): ReadonlyArray<Addable> {
    return [
      new EditableImage(this.key, this.corners, this.dimensions, this.onUpdated),
    ];
  }

  public layers(state: State): ReadonlyArray<LayerDef> {
    const corners = state.corners || this.corners;

    if (!this.corners) {
      // If there are no corners, we can't render anything yet. We need to wait
      // for EditableImage to place it and update the controlling component.
      return [];
    }

    const source: ImageSourceDef = {
      type: 'image',
      id: this.key,
      url: this.imageUrl,
      coordinates: corners,
    };

    return [
      {
        id: this.key,
        source: source,
        type: 'raster',
        paint: {
          'raster-opacity': state.opacity === undefined ? 0.6 : state.opacity,
          'raster-opacity-transition': {
            duration: 0,
          },
        },
      },
    ];
  }
}


interface EditableImageModes extends DefaultModes {
  ['draw_admin_map']: any; // FIXME: mapboxgl-draw typing is a fiasco
  ['draw_admin_map_rotate']: any; // FIXME: mapboxgl-draw typing is a fiasco
}


class EditableImage implements Addable<State> {
  private _key: string;

  private draw: MapboxGLDraw<EditableImageModes> | undefined;
  private corners: geo.Corners | undefined;
  private featureId: string | undefined;
  private controls: AdminMapControls | undefined;
  private dimensions: Dimensions;
  private onUpdated: OnAdminMapUpdated;

  private map: AddableManager<State> | undefined;

  public constructor(
    key: string,
    corners: geo.Corners | undefined,
    dimensions: Dimensions,
    onUpdated: OnAdminMapUpdated,
  ) {
    this._key = key;
    this.corners = corners;
    this.dimensions = dimensions;
    this.onUpdated = onUpdated;
  }

  public get key() {
    return this._key;
  }

  private placeImage(): geo.Corners {
    if (!this.map) {
      throw new Error();
    }

    const bounds = this.map.dangerousMap.getBounds();
    const extent = geo.asExtent2D(bounds);

    this.corners = getAdminMapCorners(extent, this.dimensions);

    // We have to notify the controlling component of the change:
    this.onUpdated({ corners: this.corners });

    return this.corners;
  }

  public add(map: AddableManager<State>) {
    this.map = map;

    const modes = {
      ['draw_admin_map']: drawMode({ key: this._key }),
      ['draw_admin_map_rotate']: drawRotateMode({ key: this._key }),
    };

    const allModes = Object.assign(modes, MapboxGLDraw.modes);

    this.draw = new MapboxGLDraw<EditableImageModes>({
      displayControlsDefault: false,
      controls: { trash: true },
      defaultMode: 'simple_select',
      modes: allModes,
      styles: mapStyles.drawStyles,
    });

    const corners = this.corners || this.placeImage();

    const polyCoords = [[...corners, corners[0]]];

    map.on(drawEventKey(this._key, 'transform'), this.onTransform);
    map.on(drawEventKey(this._key, 'transformStart'), this.onTransformStart);
    map.on(drawEventKey(this._key, 'transformEnd'), this.onTransformEnd);

    map.on(drawRotateEventKey(this._key, 'transform'), this.onTransform);
    map.on(drawRotateEventKey(this._key, 'transformEnd'), this.onTransformEnd);

    map.addControl(this.draw);
    this.featureId = this.draw.add(geo.mut({ type: 'Polygon', coordinates: polyCoords }))[0];

    this.draw.changeMode('draw_admin_map', { featureId: this.featureId! });

    this.controls = new AdminMapControls({
      initialMode: 'draw_admin_map',
      onChangeMode: (mode: keyof EditableImageModes) => {
        this.draw!.changeMode(mode, { featureId: this.featureId! });
      },
    });
    map.addControl(this.controls);
  }

  public remove() {
    if (this.draw) {
      this.draw.deleteAll();
      this.draw = undefined;
    }
  }

  private onTransform = (e: { coords: geo.Corners }) => { // FIXME: typing
    this.map!.setState({ corners: e.coords });
  }

  private onTransformStart = (e: any) => { // FIXME: typing
    this.map!.setState({ opacity: 0.5 });
  }

  private onTransformEnd = (e: any) => { // FIXME: typing
    if (this.map!.state && this.map!.state.corners) {
      this.onUpdated({ corners: this.map!.state.corners });
    }
    this.map!.setState({ opacity: 0.8 });
  }
}


class AdminMapControls implements mapboxgl.IControl {
  private container?: HTMLDivElement;
  private initialMode: keyof EditableImageModes;
  private onChangeMode: (mode: keyof EditableImageModes) => void;
  private modeButtons: { [key in keyof EditableImageModes]?: HTMLButtonElement } = {};

  public constructor(opts: {
    initialMode: keyof EditableImageModes;
    onChangeMode: (mode: keyof EditableImageModes) => void;
  }) {
    this.onChangeMode = opts.onChangeMode;
    this.initialMode = opts.initialMode;
  }

  private addModeButton(mode: keyof EditableImageModes, button: HTMLButtonElement) {
    button.className = 'mapbox-gl-draw_ctrl-draw-btn';
    button.style.padding = '3px';
    button.onclick = (e: any) => {
      e.preventDefault();
      this.onChangeMode(mode);
      this.setActiveMode(mode);
    };
    this.modeButtons[mode] = button;
  }

  private setActiveMode(mode: keyof EditableImageModes) {
    for (const k of objectKeys(this.modeButtons)) {
      if (k === mode) {
        this.modeButtons[k]!.classList.add('mapboxgl-ctrl-selected');
      } else {
        this.modeButtons[k]!.classList.remove('mapboxgl-ctrl-selected');
      }
    }
  }

  public onAdd(map: mapboxgl.Map) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';

    {
      const modeGroup = document.createElement('div');
      modeGroup.className = 'mapboxgl-ctrl-group';
      this.container.appendChild(modeGroup);

      {
        const moveButton = document.createElement('button');
        moveButton.setAttribute('title', 'Move/Resize');
        moveButton.innerHTML = IconMoveString;
        modeGroup.appendChild(moveButton);
        this.addModeButton('draw_admin_map', moveButton);
      }

      {
        const rotateButton = document.createElement('button');
        rotateButton.setAttribute('title', 'Rotate');
        rotateButton.innerHTML = IconRotateString;
        modeGroup.appendChild(rotateButton);
        this.addModeButton('draw_admin_map_rotate', rotateButton);
      }

      this.setActiveMode(this.initialMode);
    }

    return this.container;
  }

  public onRemove() {
    this.container!.parentNode!.removeChild(this.container!);
  }
}
