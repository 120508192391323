import { Effect, call, put, select, takeLeading } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import { RefreshToken } from 'generated/mos/user'
import {
  AssignNewPasswordRequest,
  AssignNewPasswordResponse,
  AuthenticateEmailUserRequest,
  AuthenticateEmailUserResponse,
  DeviceProfileInput,
  RefreshAuthenticationTokenRequest,
  RefreshAuthenticationTokenResponse,
  RequestNewPasswordRequest,
  RequestNewPasswordResponse,
  ValidateResetTokenRequest,
  ValidateResetTokenResponse,
} from 'generated/mos/userauthentication'
import { Invariant } from 'helpers/core'
import { unaryGRPC } from 'services/unary-grpc'
import { ensureSessionChannel } from 'services'
import { Status, failureStatusMessage } from 'helpers/status'

import {
  AccountActions,
  actionCreators,
  helpscoutIdentify,
} from './actions'
import { AuthDevice, createAuthSession } from 'services'
import { authSessionLoad } from './auth-session'

const accountTakeLeading = <TActionKey extends keyof AccountActions>(
  pattern: TActionKey,
  worker: (action: AccountActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
};

const createAuthDevice = (args: Omit<AuthDevice, 'id'>): AuthDevice => ({
  id: uuidv4(),
  ...args,
});

function* initiateAuthenticateEmailUser(action: AccountActions['accountLoginRequest']) {
  const { account } = yield select()

  // set device info, if required.
  let device: AuthDevice
  if (!account.device) {
    device = yield call(createAuthDevice, { displayName: navigator.userAgent })
    yield put(actionCreators.accountDeviceUpdate(device))
  } else {
    device = account.device
  }

  const request: AuthenticateEmailUserRequest.Entity = {
    ...action.payload,
    deviceProfile: {
      ...DeviceProfileInput.defaults,
      deviceId: device.id,
      deviceName: device.displayName,
    },
  }

  return yield* unaryGRPC<
    AuthenticateEmailUserRequest.Entity,
    AuthenticateEmailUserResponse.Entity
  >(
    'mos.user_authentication.UserAuthentication/AuthenticateEmailUser',
    request,
    AuthenticateEmailUserRequest.codec,
    AuthenticateEmailUserResponse.codec,
  );
}

const loginSaga = accountTakeLeading(
  'accountLoginRequest',
  function* (action: AccountActions['accountLoginRequest']) {
    try {
      const authTokens = yield* initiateAuthenticateEmailUser(action);
      yield put(actionCreators.accountLoginSuccess(authTokens));

      if (!authTokens.accessToken || !authTokens.refreshToken) {
        // a valid api response, meeting expected types, somehow still now a valid auth
        //   response above?
        throw new Invariant(`Unhandled authentication response: ${JSON.stringify(authTokens)}`);
      }

      // store tokens in local storage.
      yield put(actionCreators.accountRefreshAuthenticationTokenSuccess(authTokens))

      // assemble session data to update sub-store.
      const tokens = {
        access: authTokens.accessToken.value,
        refresh: authTokens.refreshToken.value,
      }
      const session = yield call(createAuthSession, tokens)
      yield put(actionCreators.accountSessionUpdate({ session }))

      yield call(helpscoutIdentify, session.user.name, session.user.email)
    } catch (err) {
      console.error(err);
      yield put(actionCreators.accountLoginFailure(failureStatusMessage(err.message)));
      return
    }
  }
)

const refreshAuthenticationTokenSaga = accountTakeLeading(
  'accountRefreshAuthenticationTokenRequest',
  function* (action: AccountActions['accountRefreshAuthenticationTokenRequest']) {
    try {
      const session = authSessionLoad()
      const refreshTokenValue: string = session && session.refresh ? session.refresh : ''
      const request: RefreshAuthenticationTokenRequest.Entity = {
        ...RefreshAuthenticationTokenRequest.defaults,
        refreshToken: {
          ...RefreshToken.defaults,
          value: refreshTokenValue,
        }
      }
      const entity = yield* unaryGRPC<
        RefreshAuthenticationTokenRequest.Entity,
        RefreshAuthenticationTokenResponse.Entity
      >(
        'mos.user_authentication.UserAuthentication/RefreshAuthenticationToken',
        request,
        RefreshAuthenticationTokenRequest.codec,
        RefreshAuthenticationTokenResponse.codec,
      )
      yield put(actionCreators.accountRefreshAuthenticationTokenSuccess(entity));
    } catch (err) {
      yield put(actionCreators.accountRefreshAuthenticationTokenFailure(failureStatusMessage(err.message)));
    }
  }
)

const refreshAuthenticationTokenSuccessSaga = accountTakeLeading(
  'accountRefreshAuthenticationTokenSuccess',
  function* (action: AccountActions['accountRefreshAuthenticationTokenSuccess']) {
    yield put(ensureSessionChannel, {})
  }
)

const requestNewPasswordSaga = accountTakeLeading(
  'accountRequestNewPasswordRequest',
  function* (action: AccountActions['accountRequestNewPasswordRequest']) {
    try {
      yield* unaryGRPC<
        RequestNewPasswordRequest.Entity,
        RequestNewPasswordResponse.Entity
      >(
        'mos.user_authentication.UserAuthentication/RequestNewPassword',
        action.payload,
        RequestNewPasswordRequest.codec,
        RequestNewPasswordResponse.codec,
      );
      yield put(actionCreators.accountRequestNewPasswordSuccess());
      yield put(actionCreators.accountRequestNewPasswordReset());
    } catch (err) {
      yield put(actionCreators.accountRequestNewPasswordFailure(failureStatusMessage(err.message)));
    }
  }
)

const validateResetTokenSaga = accountTakeLeading(
  'accountValidateResetTokenRequest',
  function* (action: AccountActions['accountValidateResetTokenRequest']) {
    try {
      const resp = yield* unaryGRPC<
        ValidateResetTokenRequest.Entity,
        ValidateResetTokenResponse.Entity
      >(
        'mos.user_authentication.UserAuthentication/ValidateResetToken',
        action.payload,
        ValidateResetTokenRequest.codec,
        ValidateResetTokenResponse.codec,
      );
      yield put(actionCreators.accountValidateResetTokenSuccess(resp.valid));
    } catch (err) {
      yield put(actionCreators.accountValidateResetTokenFailure(failureStatusMessage(err.message)));
    }
  }
)

const assignNewPasswordSaga = accountTakeLeading(
  'accountAssignNewPasswordRequest',
  function* (action: AccountActions['accountAssignNewPasswordRequest']) {
    try {
      yield* unaryGRPC<
        AssignNewPasswordRequest.Entity,
        AssignNewPasswordResponse.Entity
      >(
        'mos.user_authentication.UserAuthentication/AssignNewPassword',
        action.payload,
        AssignNewPasswordRequest.codec,
        AssignNewPasswordResponse.codec,
      );
      yield put(actionCreators.accountAssignNewPasswordSuccess());
    } catch (err) {
      yield put(actionCreators.accountAssignNewPasswordFailure(failureStatusMessage(err.message)));
    }
  }
)

export const accountRootSaga: ReadonlyArray<Effect> = [
  loginSaga,
  refreshAuthenticationTokenSaga,
  refreshAuthenticationTokenSuccessSaga,
  requestNewPasswordSaga,
  validateResetTokenSaga,
  assignNewPasswordSaga,
]
