import React, { useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Dropzone from 'react-dropzone'

import { Button } from 'components/ui/button'
import {
  IconMediaCaptions,
  IconMediaEmpty,
  IconMediaSubtitles,
  IconMediaTranscript,
} from 'components/ui/icons'
import { ImageWithFallback } from 'components/ui/image'
import { Modal } from 'components/ui/modal'

import { sharedActionCreators } from 'containers/shared'
import { connect, store } from 'containers/store'

import { ensureSession } from 'domains/account/actions'
import { AuthSession } from 'services'
import { getTokens } from 'domains/account/auth-session'

import { Checksum } from 'generated/mos/entity'
import { Language } from 'generated/mos/i18n'
import { Attachment, AttachmentIntent, Media } from 'generated/mos/media'
import { AddAttachmentRequest, DeleteAttachmentRequest } from 'generated/mos/mediamanagement'

import { bffEndpoint } from 'helpers/bff'
import { assertNever, Invariant, pick } from 'helpers/core'
import { timestampToFormattedString } from 'helpers/timestamps'

import { actionCreators } from '../../actions'

import {
  FieldWrapper,
} from './styled'

import {
  Description,
  EmptyDetails,
  Label,
  LabelSupport,
  MainOptions,
  Preview,
  Table,
  TableCell,
  TableHeader,
  Thumbnail,
} from '../media-field/styled'

import {
  DropArea,
  DropAreaText,
  DropAreaTextSmall,
} from '../media-picker/styled'

import styled from 'styled'

const Image = styled(ImageWithFallback)`
  height: 56px;
`;

type SignedUrlData = {
  readonly name: string;
  readonly signedUrl: string;
  readonly uuid: string;
}

type DirectProps = {
  /**
   * AttachmentIntent
   */
  readonly intent: AttachmentIntent;
  /**
   * Attachment.Entity
   */
  readonly attachment: Attachment.Entity | undefined;
  /**
   * Media.Ref used to refresh the parent entity after a successful update.
   */
  readonly mediaRef: Media.Ref;
  readonly label: string;
  /**
   * Optional copy which sits to the right of the label in a subtle colour.
   */
  readonly labelSupportingText?: string;
  /**
   * Currently this will be the same language as the parent Media entity.
   */
  readonly language: Language.Entity;
  /**
   * Displays below the form element describing it's purpose.
   */
  readonly description?: string;
  /**
   * Prevents updates while an attachment update is occurring for the parent media entity.
   */
  readonly isUpdating?: boolean;
};

type ActionProps =
  Pick<typeof sharedActionCreators, 'toastNotification'> &
  Pick<typeof actionCreators, 'mediaAddAttachmentRequest' | 'mediaDeleteAttachmentRequest' | 'mediaUpdateAttachmentReset'>;


type Props = DirectProps & ActionProps;

/**
 * This component is effectively a stripped back combination of the `MediaField` and `MediaPicker` components, with customisations to allow it to handle `Attachment` entities. It is built to be consumed by the `MediaEdit` component, as this is the only situation where attachments of a media entity can be modified.
 *
 * This means that validation and error handling scenarios are different and we need to specify which `ATTACHMENT_INTENT` an instance of the field supports, and how to update the parent entity by using the audio management APIs.
 *
 * An optional `labelSupportText` prop can be used to provide supporting text for the fields label, and should be visually more subtle than the label copy itself.
 */
// only exported for storybook.
export const AttachmentFieldComponent = (props: Props) => {
  const {
    attachment,
    description,
    intent,
    isUpdating,
    label,
    labelSupportingText,
    language,
    mediaAddAttachmentRequest,
    mediaDeleteAttachmentRequest,
    mediaRef,
    mediaUpdateAttachmentReset,
    toastNotification,
  } = props

  const [ pickerOpen, setPickerOpen ] = useState<boolean>(false)
  const [ isPreparingAttachment, setPreparingAttachment ] = useState<boolean>(false)
  const attachmentRef = attachment && Attachment.isRef(attachment.ref) ? attachment.ref : undefined

  const onDrop = (files: ReadonlyArray<File>) => {
    const fileReader = new FileReader()

    setPreparingAttachment(true)

    // FIXME: this could be lifted from here and upload-media into a utility
    //   https://github.com/ArtProcessors/mos-apps/pull/242#discussion_r374474767
    // check file validity.
    files.map(file => {
      // extract first 4 bytes.
      const blob = file.slice(0, 4)
      fileReader.readAsArrayBuffer(blob)
      fileReader.onloadend = async (event: ProgressEvent<FileReader>) => {
        if (event && event.target && event.target.result) {
          // convert to hexadecimal.
          const uint = new Uint8Array(event.target.result as ArrayBuffer )

          const hashBuffer = await crypto.subtle.digest('SHA-256', uint)
          // convert buffer to byte array then hex string.
          const hashArray = Array.from(new Uint8Array(hashBuffer))
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

          const checksum: Checksum.Entity = {
            type: Checksum.refName,
            method: 'CHECKSUM_METHOD_SHA256' as const,
            value: hashHex,
          }

          setPickerOpen(false)
          uploadFileToS3(file, checksum)
        }
      }
    })
  }

  // this is largely copied from the media-picker/upload-media component and not using the
  //   s3-uploader to reduce dependency on the vendor package, which can be removed once admin maps
  //   become based on media entity values.
  const uploadFileToS3 = async (file: File, checksum: Checksum.Entity) => {
    try {
      // get valid access token.
      await store.dispatch(ensureSession() as any) as AuthSession
      const tokens = getTokens()

      // attempt to fetch the signed url.
      const result: AxiosResponse<SignedUrlData> = await axios.get<SignedUrlData>(`${bffEndpoint(window.location.hostname)}/sign-media-upload`, {
        headers: {
          Authorization: tokens && tokens.access ? `bearer ${tokens.access}` : null
        },
        params: {
          contentType: file.type,
          objectName: file.name,
        }
      })

      // upload the file to s3 and return the file url.
      const { signedUrl } = result.data

      if (!signedUrl) {
        throw new Invariant('[ AttachmentField ] No signedUrl returned.')
      }

      try {
        await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type
          },
        })

        try {
          const payload: AddAttachmentRequest.Entity = {
            ...AddAttachmentRequest.defaults,
            mediaRef,
            language,
            mediaType: file.type,
            uploadedFilename: file.name,
            url: signedUrl.split('?')[0],
            intent,
            checksum,
          }

          // if this is a replace operation, remove the existing attachment before adding the new
          // one.
          if (attachmentRef) {
            mediaUpdateAttachmentReset(mediaRef)
            mediaDeleteAttachmentRequest(mediaRef, {
              type: DeleteAttachmentRequest.refName,
              ref: attachmentRef,
            })
          }
          mediaUpdateAttachmentReset(mediaRef)
          mediaAddAttachmentRequest(mediaRef, payload)

          // this is set after the action creators are called because the parent component is
          // watching for the `Loading` status to transition to `Ready` or `Failed` and will update // the isUpdating prop.
          setPreparingAttachment(false)

        } catch (error) {
          // failed to successfully call media management endpoints.
          toastNotification({
            type: 'error',
            text: 'An error occurred when attempting to save changes to your attachment. Please try again.',
          })
        }

      } catch (e) {
        // axios error, failed to upload file to s3.
        const error: AxiosError = e

        // non 2xx response received.
        if (error.response) {
          toastNotification({
            type: 'error',
            text: 'A failure occurred when attempting to uploading your file. Please try again.',
          })
        }
        // no response received.
        else if (error.request) {
          toastNotification({
            type: 'error',
            text: 'No response was received from the file storage service. Please try again.',
          })
        }
        // error sent from backend service.
        else if (error.message) {
          toastNotification({
            type: 'error',
            text: 'An error was received when attempting to uploading your file. Please try again.',
          })
        }
        // fetch api failure.
        else {
          toastNotification({
            type: 'error',
            text: 'There was a problem with your network connection. Please try again.',
          })
        }
      }

    } catch (e) {
      // axios error, failed to retreive signed upload endpoint via bff.
      const error: AxiosError = e

      // non 2xx response received.
      if (error.response) {
        toastNotification({
          type: 'error',
          text: 'A failure occurred when requesting a file storage location. Please try again.',
        })
      }
      // no response received.
      else if (error.request) {
        toastNotification({
          type: 'error',
          text: 'No file storage location was received. Please try again.',
        })
      }
      // error sent from backend service.
      else if (error.message) {
        toastNotification({
          type: 'error',
          text: 'An error was received when requesting a file storage location. Please try again.',
        })
      }
      // fetch api failure.
      else {
        toastNotification({
          type: 'error',
          text: 'There was a problem with your network connection. Please try again.',
        })
      }
    }
  }

  const isDisabled = isPreparingAttachment || isUpdating

  const renderThumbnailIcon = (attachment: Attachment.Entity | undefined) => {
    if (!attachment) {
      return <IconMediaEmpty />
    }

    switch (attachment.intent) {
      case 'MEDIA_INTENT_CLOSED_CAPTIONS': {
        return <IconMediaCaptions />
      }
      case 'MEDIA_INTENT_SUBTITLES': {
        return <IconMediaSubtitles />
      }
      case 'MEDIA_INTENT_THUMBNAIL': {
        return <Image src={attachment.url} />
      }
      case 'MEDIA_INTENT_TRANSCRIPT': {
        return <IconMediaTranscript />
      }
      case 'MEDIA_INTENT_UNSPECIFIED': {
        return <IconMediaEmpty />
      }
      default: {
        assertNever(attachment.intent)
      }
    }
  }

  const renderPreview = () => (
    <Preview isDisabled={isDisabled}>
      <Thumbnail>
        {renderThumbnailIcon(attachment)}
      </Thumbnail>
      {attachment ? (
        <Table>
          <tbody>
            <tr>
              <TableHeader>Filename</TableHeader>
              <TableCell>{attachment.uploadedFilename}</TableCell>
            </tr>
            {attachment && attachment.metadata && attachment.metadata.modifiedAt && (
              <tr>
                <TableHeader>Modified</TableHeader>
                <TableCell>
                  {timestampToFormattedString(attachment!.metadata!.modifiedAt.value)}
                </TableCell>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <EmptyDetails>-</EmptyDetails>
      )}
    </Preview>
  )

  return (
    <FieldWrapper>
      <Label>
        <>
          {label}
          {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
        </>

        {renderPreview()}
      </Label>

      <MainOptions align="left">
        {attachment && Attachment.isRef(attachment.ref) ? (
          <>
            <Button
              variant="link"
              appearance="secondary"
              isDisabled={isDisabled}
              onClick={(event) => {
                event.preventDefault()
                mediaUpdateAttachmentReset(mediaRef)
                mediaDeleteAttachmentRequest(mediaRef, {
                  type: DeleteAttachmentRequest.refName,
                  ref: attachment.ref,
                })
              }}
            >
              Remove
            </Button>
            <Button
              variant="link"
              appearance="secondary"
              isDisabled={isDisabled}
              onClick={(event) => {
                event.preventDefault()
                setPickerOpen(true)
              }}
            >
              Replace
            </Button>
          </>
        ) : (
          <Button
            variant="link"
            appearance="secondary"
            isDisabled={isDisabled}
            onClick={(event) => {
              event.preventDefault()
              setPickerOpen(true)
            }}
          >
            Add attachment
          </Button>
        )}
      </MainOptions>

      {pickerOpen && (
        <Modal
          header={<h3>Upload attachment</h3>}
          footer={null}
          onClose={() => setPickerOpen(false)}
        >
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <DropArea {...getRootProps()}>
                <DropAreaText>
                  Drag a file here
                  <DropAreaTextSmall>or</DropAreaTextSmall>
                </DropAreaText>
                <input {...getInputProps()} multiple={false} />
                <Button onClick={(event) => { event.preventDefault() }}>
                  Choose from device
                </Button>
              </DropArea>
            )}
          </Dropzone>
        </Modal>
      )}

      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}

AttachmentFieldComponent.defaultProps = {
  isUpdating: false,
}

Button.defaultProps = {
  type: "button"
}

export const AttachmentField = connect<{}, ActionProps, DirectProps>(
  () => ({}),
  {
    ...pick(sharedActionCreators, 'toastNotification'),
    ...pick(actionCreators, 'mediaAddAttachmentRequest', 'mediaDeleteAttachmentRequest', 'mediaUpdateAttachmentReset'),
  }
)(AttachmentFieldComponent)
