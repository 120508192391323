import {
  IBeaconIdentificationProfile,
  KontaktIdentificationProfile,
  GenericNameIdentificationProfile,
} from 'generated/mos/beacon'

export const GENERIC_PROFILE = GenericNameIdentificationProfile.refName
export const IBEACON_PROFILE = IBeaconIdentificationProfile.refName
export const KONTAKT_PROFILE = KontaktIdentificationProfile.refName

export const profileTypes = [
  GENERIC_PROFILE,
  IBEACON_PROFILE,
  KONTAKT_PROFILE,
];

export type ProfileType = typeof profileTypes[number]

export type ProfileEntity =
  IBeaconIdentificationProfile.Entity |
  KontaktIdentificationProfile.Entity |
  GenericNameIdentificationProfile.Entity


export function isProfileType(v?: ProfileEntity | undefined): v is ProfileEntity {
  return !v ? false : profileTypes.indexOf(v.type) >= 0;
}

// FIXME: this discriminated union stuff can almost certainly be generated by a
// graphql-code-generator plugin.
export function isGeneric(v: ProfileEntity): v is GenericNameIdentificationProfile.Entity {
  return v.type === GENERIC_PROFILE;
}
export function isIBeacon(v: ProfileEntity): v is IBeaconIdentificationProfile.Entity {
  return v.type === IBEACON_PROFILE;
}
export function isKontakt(v: ProfileEntity): v is KontaktIdentificationProfile.Entity {
  return v.type === KONTAKT_PROFILE;
}

export type ProfileOption = {
  readonly value: ProfileType;
  readonly label: string;
}

export const profileOptions: ProfileOption[] = [
  {
    value: KONTAKT_PROFILE,
    label: 'Kontakt',
  },
  {
    value: IBEACON_PROFILE,
    label: 'iBeacon',
  },
  {
    value: GENERIC_PROFILE,
    label: 'Generic',
  },
];
