// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureBigInt, ensureScalar, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";
import jsbi from "jsbi";

// Describes a message type.
export namespace DescriptorProto {
  export const refName = "google.protobuf.DescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly field: ReadonlyArray<FieldDescriptorProto.Entity>,
    readonly extension: ReadonlyArray<FieldDescriptorProto.Entity>,
    readonly nestedType: ReadonlyArray<DescriptorProto.Entity>,
    readonly enumType: ReadonlyArray<EnumDescriptorProto.Entity>,
    readonly extensionRange: ReadonlyArray<DescriptorProto.ExtensionRange.Entity>,
    readonly oneofDecl: ReadonlyArray<OneofDescriptorProto.Entity>,
    readonly options: MessageOptions.Entity | undefined,
    readonly reservedRange: ReadonlyArray<DescriptorProto.ReservedRange.Entity>,
    // Reserved field names, which may not be used by fields in the same message.
    // A given name may only be reserved once.
    readonly reservedName: ReadonlyArray<string>,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    field: [],
    extension: [],
    nestedType: [],
    enumType: [],
    extensionRange: [],
    oneofDecl: [],
    options: undefined,
    reservedRange: [],
    reservedName: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["field"] = [];
      for (const item of v.field) {
        obj["field"].push(FieldDescriptorProto.codec.encode(item));
      }
      obj["extension"] = [];
      for (const item of v.extension) {
        obj["extension"].push(FieldDescriptorProto.codec.encode(item));
      }
      obj["nestedType"] = [];
      for (const item of v.nestedType) {
        obj["nestedType"].push(DescriptorProto.codec.encode(item));
      }
      obj["enumType"] = [];
      for (const item of v.enumType) {
        obj["enumType"].push(EnumDescriptorProto.codec.encode(item));
      }
      obj["extensionRange"] = [];
      for (const item of v.extensionRange) {
        obj["extensionRange"].push(DescriptorProto.ExtensionRange.codec.encode(item));
      }
      obj["oneofDecl"] = [];
      for (const item of v.oneofDecl) {
        obj["oneofDecl"].push(OneofDescriptorProto.codec.encode(item));
      }
      obj["options"] = MessageOptions.codec.encode(v.options);
      obj["reservedRange"] = [];
      for (const item of v.reservedRange) {
        obj["reservedRange"].push(DescriptorProto.ReservedRange.codec.encode(item));
      }
      obj["reservedName"] = v.reservedName;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.DescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["field"] = decodeMessageRepeated(ctx, FieldDescriptorProto.codec, v["field"], "field");
      out["extension"] = decodeMessageRepeated(ctx, FieldDescriptorProto.codec, v["extension"], "extension");
      out["nestedType"] = decodeMessageRepeated(ctx, DescriptorProto.codec, v["nestedType"], "nestedType");
      out["enumType"] = decodeMessageRepeated(ctx, EnumDescriptorProto.codec, v["enumType"], "enumType");
      out["extensionRange"] = decodeMessageRepeated(ctx, DescriptorProto.ExtensionRange.codec, v["extensionRange"], "extensionRange");
      out["oneofDecl"] = decodeMessageRepeated(ctx, OneofDescriptorProto.codec, v["oneofDecl"], "oneofDecl");
      out["options"] = ctx.decode(MessageOptions.codec, v["options"], "options");
      out["reservedRange"] = decodeMessageRepeated(ctx, DescriptorProto.ReservedRange.codec, v["reservedRange"], "reservedRange");
      out["reservedName"] = ensureScalarRepeated(ctx, v["reservedName"], "string");
      return out as any as Entity;
    }
  }();
  export namespace ExtensionRange {
    export const refName = "google.protobuf.DescriptorProto.ExtensionRange" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly start: number,
      readonly end: number,
      readonly options: ExtensionRangeOptions.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      start: 0,
      end: 0,
      options: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["start"] = v.start;
        obj["end"] = v.end;
        obj["options"] = ExtensionRangeOptions.codec.encode(v.options);
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.DescriptorProto.ExtensionRange");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["start"] = ensureScalar(ctx, v["start"], "number");
        out["end"] = ensureScalar(ctx, v["end"], "number");
        out["options"] = ctx.decode(ExtensionRangeOptions.codec, v["options"], "options");
        return out as any as Entity;
      }
    }();
  }

  export namespace ReservedRange {
    export const refName = "google.protobuf.DescriptorProto.ReservedRange" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly start: number,
      readonly end: number,
    }
    export const defaults: Entity = {
      type: refName,
      start: 0,
      end: 0,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.DescriptorProto.ReservedRange");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["start"] = ensureScalar(ctx, v["start"], "number");
        out["end"] = ensureScalar(ctx, v["end"], "number");
        return out as any as Entity;
      }
    }();
  }

}

// A Duration represents a signed, fixed-length span of time represented
// as a count of seconds and fractions of seconds at nanosecond
// resolution. It is independent of any calendar and concepts like "day"
// or "month". It is related to Timestamp in that the difference between
// two Timestamp values is a Duration and it can be added or subtracted
// from a Timestamp. Range is approximately +-10,000 years.
// 
// # Examples
// 
// Example 1: Compute Duration from two Timestamps in pseudo code.
// 
// Timestamp start = ...;
// Timestamp end = ...;
// Duration duration = ...;
// 
// duration.seconds = end.seconds - start.seconds;
// duration.nanos = end.nanos - start.nanos;
// 
// if (duration.seconds < 0 && duration.nanos > 0) {
// duration.seconds += 1;
// duration.nanos -= 1000000000;
// } else if (duration.seconds > 0 && duration.nanos < 0) {
// duration.seconds -= 1;
// duration.nanos += 1000000000;
// }
// 
// Example 2: Compute Timestamp from Timestamp + Duration in pseudo code.
// 
// Timestamp start = ...;
// Duration duration = ...;
// Timestamp end = ...;
// 
// end.seconds = start.seconds + duration.seconds;
// end.nanos = start.nanos + duration.nanos;
// 
// if (end.nanos < 0) {
// end.seconds -= 1;
// end.nanos += 1000000000;
// } else if (end.nanos >= 1000000000) {
// end.seconds += 1;
// end.nanos -= 1000000000;
// }
// 
// Example 3: Compute Duration from datetime.timedelta in Python.
// 
// td = datetime.timedelta(days=3, minutes=10)
// duration = Duration()
// duration.FromTimedelta(td)
// 
// # JSON Mapping
// 
// In JSON format, the Duration type is encoded as a string rather than an
// object, where the string ends in the suffix "s" (indicating seconds) and
// is preceded by the number of seconds, with nanoseconds expressed as
// fractional seconds. For example, 3 seconds with 0 nanoseconds should be
// encoded in JSON format as "3s", while 3 seconds and 1 nanosecond should
// be expressed in JSON format as "3.000000001s", and 3 seconds and 1
// microsecond should be expressed in JSON format as "3.000001s".
// 
// 
export namespace Duration {
  export const refName = "google.protobuf.Duration" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Signed seconds of the span of time. Must be from -315,576,000,000
    // to +315,576,000,000 inclusive. Note: these bounds are computed from:
    // 60 sec/min * 60 min/hr * 24 hr/day * 365.25 days/year * 10000 years
    readonly seconds: jsbi,
    // Signed fractions of a second at nanosecond resolution of the span
    // of time. Durations less than one second are represented with a 0
    // `seconds` field and a positive or negative `nanos` field. For durations
    // of one second or more, a non-zero value for the `nanos` field must be
    // of the same sign as the `seconds` field. Must be from -999,999,999
    // to +999,999,999 inclusive.
    readonly nanos: number,
  }
  export const defaults: Entity = {
    type: refName,
    seconds: jsbi.BigInt(0),
    nanos: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["seconds"] = v.seconds.toString(10);
      obj["nanos"] = v.nanos;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.Duration");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["seconds"] = ensureBigInt(ctx, v["seconds"]);
      out["nanos"] = ensureScalar(ctx, v["nanos"], "number");
      return out as any as Entity;
    }
  }();
}

// Describes an enum type.
export namespace EnumDescriptorProto {
  export const refName = "google.protobuf.EnumDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly value: ReadonlyArray<EnumValueDescriptorProto.Entity>,
    readonly options: EnumOptions.Entity | undefined,
    // Range of reserved numeric values. Reserved numeric values may not be used
    // by enum values in the same enum declaration. Reserved ranges may not
    // overlap.
    readonly reservedRange: ReadonlyArray<EnumDescriptorProto.EnumReservedRange.Entity>,
    // Reserved enum value names, which may not be reused. A given name may only
    // be reserved once.
    readonly reservedName: ReadonlyArray<string>,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    value: [],
    options: undefined,
    reservedRange: [],
    reservedName: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["value"] = [];
      for (const item of v.value) {
        obj["value"].push(EnumValueDescriptorProto.codec.encode(item));
      }
      obj["options"] = EnumOptions.codec.encode(v.options);
      obj["reservedRange"] = [];
      for (const item of v.reservedRange) {
        obj["reservedRange"].push(EnumDescriptorProto.EnumReservedRange.codec.encode(item));
      }
      obj["reservedName"] = v.reservedName;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.EnumDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["value"] = decodeMessageRepeated(ctx, EnumValueDescriptorProto.codec, v["value"], "value");
      out["options"] = ctx.decode(EnumOptions.codec, v["options"], "options");
      out["reservedRange"] = decodeMessageRepeated(ctx, EnumDescriptorProto.EnumReservedRange.codec, v["reservedRange"], "reservedRange");
      out["reservedName"] = ensureScalarRepeated(ctx, v["reservedName"], "string");
      return out as any as Entity;
    }
  }();
  export namespace EnumReservedRange {
    export const refName = "google.protobuf.EnumDescriptorProto.EnumReservedRange" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly start: number,
      readonly end: number,
    }
    export const defaults: Entity = {
      type: refName,
      start: 0,
      end: 0,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.EnumDescriptorProto.EnumReservedRange");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["start"] = ensureScalar(ctx, v["start"], "number");
        out["end"] = ensureScalar(ctx, v["end"], "number");
        return out as any as Entity;
      }
    }();
  }

}

export namespace EnumOptions {
  export const refName = "google.protobuf.EnumOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Set this option to true to allow mapping different tag names to the same
    // value.
    readonly allowAlias: boolean,
    // Is this enum deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for the enum, or it will be completely ignored; in the very least, this
    // is a formalization for deprecating enums.
    readonly deprecated: boolean,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    allowAlias: false,
    deprecated: false,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["allowAlias"] = v.allowAlias;
      obj["deprecated"] = v.deprecated;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.EnumOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["allowAlias"] = ensureScalar(ctx, v["allowAlias"], "boolean");
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

// Describes a value within an enum.
export namespace EnumValueDescriptorProto {
  export const refName = "google.protobuf.EnumValueDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly number: number,
    readonly options: EnumValueOptions.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    number: 0,
    options: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["number"] = v.number;
      obj["options"] = EnumValueOptions.codec.encode(v.options);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.EnumValueDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["number"] = ensureScalar(ctx, v["number"], "number");
      out["options"] = ctx.decode(EnumValueOptions.codec, v["options"], "options");
      return out as any as Entity;
    }
  }();
}

export namespace EnumValueOptions {
  export const refName = "google.protobuf.EnumValueOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Is this enum value deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for the enum value, or it will be completely ignored; in the very least,
    // this is a formalization for deprecating enum values.
    readonly deprecated: boolean,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    deprecated: false,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["deprecated"] = v.deprecated;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.EnumValueOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

export namespace ExtensionRangeOptions {
  export const refName = "google.protobuf.ExtensionRangeOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.ExtensionRangeOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

// Describes a field within a message.
export namespace FieldDescriptorProto {
  export const refName = "google.protobuf.FieldDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly number: number,
    readonly label: FieldDescriptorProto.Label,
    // If type_name is set, this need not be set.  If both this and type_name
    // are set, this must be one of TYPE_ENUM, TYPE_MESSAGE or TYPE_GROUP.
    readonly _type: FieldDescriptorProto.Type,
    // For message and enum types, this is the name of the type.  If the name
    // starts with a '.', it is fully-qualified.  Otherwise, C++-like scoping
    // rules are used to find the type (i.e. first the nested types within this
    // message are searched, then within the parent, on up to the root
    // namespace).
    readonly typeName: string,
    // For extensions, this is the name of the type being extended.  It is
    // resolved in the same manner as type_name.
    readonly extendee: string,
    // For numeric types, contains the original text representation of the value.
    // For booleans, "true" or "false".
    // For strings, contains the default text contents (not escaped in any way).
    // For bytes, contains the C escaped value.  All bytes >= 128 are escaped.
    // TODO(kenton):  Base-64 encode?
    readonly defaultValue: string,
    // If set, gives the index of a oneof in the containing type's oneof_decl
    // list.  This field is a member of that oneof.
    readonly oneofIndex: number,
    // JSON name of this field. The value is set by protocol compiler. If the
    // user has set a "json_name" option on this field, that option's value
    // will be used. Otherwise, it's deduced from the field's name by converting
    // it to camelCase.
    readonly jsonName: string,
    readonly options: FieldOptions.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    number: 0,
    label: "",
    _type: "",
    typeName: "",
    extendee: "",
    defaultValue: "",
    oneofIndex: 0,
    jsonName: "",
    options: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["number"] = v.number;
      obj["label"] = v.label;
      obj["type"] = v._type;
      obj["typeName"] = v.typeName;
      obj["extendee"] = v.extendee;
      obj["defaultValue"] = v.defaultValue;
      obj["oneofIndex"] = v.oneofIndex;
      obj["jsonName"] = v.jsonName;
      obj["options"] = FieldOptions.codec.encode(v.options);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.FieldDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["number"] = ensureScalar(ctx, v["number"], "number");
      {
        const ev = ensureScalar(ctx, v["label"], "string") || defaults["label"];
        if (!FieldDescriptorProto.LabelValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.FieldDescriptorProto.Label`);
        }
        out["label"] = ev as any;
      }
      {
        const ev = ensureScalar(ctx, v["type"], "string") || defaults["_type"];
        if (!FieldDescriptorProto.TypeValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.FieldDescriptorProto.Type`);
        }
        out["_type"] = ev as any;
      }
      out["typeName"] = ensureScalar(ctx, v["typeName"], "string");
      out["extendee"] = ensureScalar(ctx, v["extendee"], "string");
      out["defaultValue"] = ensureScalar(ctx, v["defaultValue"], "string");
      out["oneofIndex"] = ensureScalar(ctx, v["oneofIndex"], "number");
      out["jsonName"] = ensureScalar(ctx, v["jsonName"], "string");
      out["options"] = ctx.decode(FieldOptions.codec, v["options"], "options");
      return out as any as Entity;
    }
  }();
  export const TypeValues = new Set([
    "" as const,
    'TYPE_DOUBLE' as const,
    'TYPE_FLOAT' as const,
    'TYPE_INT64' as const,
    'TYPE_UINT64' as const,
    'TYPE_INT32' as const,
    'TYPE_FIXED64' as const,
    'TYPE_FIXED32' as const,
    'TYPE_BOOL' as const,
    'TYPE_STRING' as const,
    'TYPE_GROUP' as const,
    'TYPE_MESSAGE' as const,
    'TYPE_BYTES' as const,
    'TYPE_UINT32' as const,
    'TYPE_ENUM' as const,
    'TYPE_SFIXED32' as const,
    'TYPE_SFIXED64' as const,
    'TYPE_SINT32' as const,
    'TYPE_SINT64' as const,
  ]);
  export type Type = typeof TypeValues extends Set<infer U> ? U : never;

  export const LabelValues = new Set([
    "" as const,
    'LABEL_OPTIONAL' as const,
    'LABEL_REQUIRED' as const,
    'LABEL_REPEATED' as const,
  ]);
  export type Label = typeof LabelValues extends Set<infer U> ? U : never;

}

export namespace FieldOptions {
  export const refName = "google.protobuf.FieldOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The ctype option instructs the C++ code generator to use a different
    // representation of the field than it normally would.  See the specific
    // options below.  This option is not yet implemented in the open source
    // release -- sorry, we'll try to include it in a future version!
    readonly ctype: FieldOptions.CType,
    // The packed option can be enabled for repeated primitive fields to enable
    // a more efficient representation on the wire. Rather than repeatedly
    // writing the tag and type for each element, the entire array is encoded as
    // a single length-delimited blob. In proto3, only explicit setting it to
    // false will avoid using packed encoding.
    readonly packed: boolean,
    // The jstype option determines the JavaScript type used for values of the
    // field.  The option is permitted only for 64 bit integral and fixed types
    // (int64, uint64, sint64, fixed64, sfixed64).  A field with jstype JS_STRING
    // is represented as JavaScript string, which avoids loss of precision that
    // can happen when a large value is converted to a floating point JavaScript.
    // Specifying JS_NUMBER for the jstype causes the generated JavaScript code to
    // use the JavaScript "number" type.  The behavior of the default option
    // JS_NORMAL is implementation dependent.
    // 
    // This option is an enum to permit additional types to be added, e.g.
    // goog.math.Integer.
    readonly jstype: FieldOptions.JSType,
    // Should this field be parsed lazily?  Lazy applies only to message-type
    // fields.  It means that when the outer message is initially parsed, the
    // inner message's contents will not be parsed but instead stored in encoded
    // form.  The inner message will actually be parsed when it is first accessed.
    // 
    // This is only a hint.  Implementations are free to choose whether to use
    // eager or lazy parsing regardless of the value of this option.  However,
    // setting this option true suggests that the protocol author believes that
    // using lazy parsing on this field is worth the additional bookkeeping
    // overhead typically needed to implement it.
    // 
    // This option does not affect the public interface of any generated code;
    // all method signatures remain the same.  Furthermore, thread-safety of the
    // interface is not affected by this option; const methods remain safe to
    // call from multiple threads concurrently, while non-const methods continue
    // to require exclusive access.
    // 
    // 
    // Note that implementations may choose not to check required fields within
    // a lazy sub-message.  That is, calling IsInitialized() on the outer message
    // may return true even if the inner message has missing required fields.
    // This is necessary because otherwise the inner message would have to be
    // parsed in order to perform the check, defeating the purpose of lazy
    // parsing.  An implementation which chooses not to check required fields
    // must be consistent about it.  That is, for any particular sub-message, the
    // implementation must either *always* check its required fields, or *never*
    // check its required fields, regardless of whether or not the message has
    // been parsed.
    readonly lazy: boolean,
    // Is this field deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for accessors, or it will be completely ignored; in the very least, this
    // is a formalization for deprecating fields.
    readonly deprecated: boolean,
    // For Google-internal migration only. Do not use.
    readonly weak: boolean,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    ctype: "STRING",
    packed: false,
    jstype: "JS_NORMAL",
    lazy: false,
    deprecated: false,
    weak: false,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ctype"] = v.ctype;
      obj["packed"] = v.packed;
      obj["jstype"] = v.jstype;
      obj["lazy"] = v.lazy;
      obj["deprecated"] = v.deprecated;
      obj["weak"] = v.weak;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.FieldOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        const ev = ensureScalar(ctx, v["ctype"], "string") || defaults["ctype"];
        if (!FieldOptions.CTypeValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.FieldOptions.CType`);
        }
        out["ctype"] = ev as any;
      }
      out["packed"] = ensureScalar(ctx, v["packed"], "boolean");
      {
        const ev = ensureScalar(ctx, v["jstype"], "string") || defaults["jstype"];
        if (!FieldOptions.JSTypeValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.FieldOptions.JSType`);
        }
        out["jstype"] = ev as any;
      }
      out["lazy"] = ensureScalar(ctx, v["lazy"], "boolean");
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["weak"] = ensureScalar(ctx, v["weak"], "boolean");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
  export const CTypeValues = new Set([
    'STRING' as const,
    'CORD' as const,
    'STRING_PIECE' as const,
  ]);
  export type CType = typeof CTypeValues extends Set<infer U> ? U : never;

  export const JSTypeValues = new Set([
    'JS_NORMAL' as const,
    'JS_STRING' as const,
    'JS_NUMBER' as const,
  ]);
  export type JSType = typeof JSTypeValues extends Set<infer U> ? U : never;

}

// Describes a complete .proto file.
export namespace FileDescriptorProto {
  export const refName = "google.protobuf.FileDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly package: string,
    // Names of files imported by this file.
    readonly dependency: ReadonlyArray<string>,
    // Indexes of the public imported files in the dependency list above.
    readonly publicDependency: ReadonlyArray<number>,
    // Indexes of the weak imported files in the dependency list.
    // For Google-internal migration only. Do not use.
    readonly weakDependency: ReadonlyArray<number>,
    // All top-level definitions in this file.
    readonly messageType: ReadonlyArray<DescriptorProto.Entity>,
    readonly enumType: ReadonlyArray<EnumDescriptorProto.Entity>,
    readonly service: ReadonlyArray<ServiceDescriptorProto.Entity>,
    readonly extension: ReadonlyArray<FieldDescriptorProto.Entity>,
    readonly options: FileOptions.Entity | undefined,
    // This field contains optional information about the original source code.
    // You may safely remove this entire field without harming runtime
    // functionality of the descriptors -- the information is needed only by
    // development tools.
    readonly sourceCodeInfo: SourceCodeInfo.Entity | undefined,
    // The syntax of the proto file.
    // The supported values are "proto2" and "proto3".
    readonly syntax: string,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    package: "",
    dependency: [],
    publicDependency: [],
    weakDependency: [],
    messageType: [],
    enumType: [],
    service: [],
    extension: [],
    options: undefined,
    sourceCodeInfo: undefined,
    syntax: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["package"] = v.package;
      obj["dependency"] = v.dependency;
      obj["publicDependency"] = v.publicDependency;
      obj["weakDependency"] = v.weakDependency;
      obj["messageType"] = [];
      for (const item of v.messageType) {
        obj["messageType"].push(DescriptorProto.codec.encode(item));
      }
      obj["enumType"] = [];
      for (const item of v.enumType) {
        obj["enumType"].push(EnumDescriptorProto.codec.encode(item));
      }
      obj["service"] = [];
      for (const item of v.service) {
        obj["service"].push(ServiceDescriptorProto.codec.encode(item));
      }
      obj["extension"] = [];
      for (const item of v.extension) {
        obj["extension"].push(FieldDescriptorProto.codec.encode(item));
      }
      obj["options"] = FileOptions.codec.encode(v.options);
      obj["sourceCodeInfo"] = SourceCodeInfo.codec.encode(v.sourceCodeInfo);
      obj["syntax"] = v.syntax;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.FileDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["package"] = ensureScalar(ctx, v["package"], "string");
      out["dependency"] = ensureScalarRepeated(ctx, v["dependency"], "string");
      out["publicDependency"] = ensureScalarRepeated(ctx, v["publicDependency"], "number");
      out["weakDependency"] = ensureScalarRepeated(ctx, v["weakDependency"], "number");
      out["messageType"] = decodeMessageRepeated(ctx, DescriptorProto.codec, v["messageType"], "messageType");
      out["enumType"] = decodeMessageRepeated(ctx, EnumDescriptorProto.codec, v["enumType"], "enumType");
      out["service"] = decodeMessageRepeated(ctx, ServiceDescriptorProto.codec, v["service"], "service");
      out["extension"] = decodeMessageRepeated(ctx, FieldDescriptorProto.codec, v["extension"], "extension");
      out["options"] = ctx.decode(FileOptions.codec, v["options"], "options");
      out["sourceCodeInfo"] = ctx.decode(SourceCodeInfo.codec, v["sourceCodeInfo"], "sourceCodeInfo");
      out["syntax"] = ensureScalar(ctx, v["syntax"], "string");
      return out as any as Entity;
    }
  }();
}

// The protocol compiler can output a FileDescriptorSet containing the .proto
// files it parses.
export namespace FileDescriptorSet {
  export const refName = "google.protobuf.FileDescriptorSet" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly file: ReadonlyArray<FileDescriptorProto.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    file: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["file"] = [];
      for (const item of v.file) {
        obj["file"].push(FileDescriptorProto.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.FileDescriptorSet");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["file"] = decodeMessageRepeated(ctx, FileDescriptorProto.codec, v["file"], "file");
      return out as any as Entity;
    }
  }();
}

export namespace FileOptions {
  export const refName = "google.protobuf.FileOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Sets the Java package where classes generated from this .proto will be
    // placed.  By default, the proto package is used, but this is often
    // inappropriate because proto packages do not normally start with backwards
    // domain names.
    readonly javaPackage: string,
    // If set, all the classes from the .proto file are wrapped in a single
    // outer class with the given name.  This applies to both Proto1
    // (equivalent to the old "--one_java_file" option) and Proto2 (where
    // a .proto always translates to a single class, but you may want to
    // explicitly choose the class name).
    readonly javaOuterClassname: string,
    // If set true, then the Java code generator will generate a separate .java
    // file for each top-level message, enum, and service defined in the .proto
    // file.  Thus, these types will *not* be nested inside the outer class
    // named by java_outer_classname.  However, the outer class will still be
    // generated to contain the file's getDescriptor() method as well as any
    // top-level extensions defined in the file.
    readonly javaMultipleFiles: boolean,
    // This option does nothing.
    readonly javaGenerateEqualsAndHash: boolean,
    // If set true, then the Java2 code generator will generate code that
    // throws an exception whenever an attempt is made to assign a non-UTF-8
    // byte sequence to a string field.
    // Message reflection will do the same.
    // However, an extension field still accepts non-UTF-8 byte sequences.
    // This option has no effect on when used with the lite runtime.
    readonly javaStringCheckUtf8: boolean,
    readonly optimizeFor: FileOptions.OptimizeMode,
    // Sets the Go package where structs generated from this .proto will be
    // placed. If omitted, the Go package will be derived from the following:
    // - The basename of the package import path, if provided.
    // - Otherwise, the package statement in the .proto file, if present.
    // - Otherwise, the basename of the .proto file, without extension.
    readonly goPackage: string,
    // Should generic services be generated in each language?  "Generic" services
    // are not specific to any particular RPC system.  They are generated by the
    // main code generators in each language (without additional plugins).
    // Generic services were the only kind of service generation supported by
    // early versions of google.protobuf.
    // 
    // Generic services are now considered deprecated in favor of using plugins
    // that generate code specific to your particular RPC system.  Therefore,
    // these default to false.  Old code which depends on generic services should
    // explicitly set them to true.
    readonly ccGenericServices: boolean,
    readonly javaGenericServices: boolean,
    readonly pyGenericServices: boolean,
    readonly phpGenericServices: boolean,
    // Is this file deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for everything in the file, or it will be completely ignored; in the very
    // least, this is a formalization for deprecating files.
    readonly deprecated: boolean,
    // Enables the use of arenas for the proto messages in this file. This applies
    // only to generated classes for C++.
    readonly ccEnableArenas: boolean,
    // Sets the objective c class prefix which is prepended to all objective c
    // generated classes from this .proto. There is no default.
    readonly objcClassPrefix: string,
    // Namespace for generated classes; defaults to the package.
    readonly csharpNamespace: string,
    // By default Swift generators will take the proto package and CamelCase it
    // replacing '.' with underscore and use that to prefix the types/symbols
    // defined. When this options is provided, they will use this value instead
    // to prefix the types/symbols defined.
    readonly swiftPrefix: string,
    // Sets the php class prefix which is prepended to all php generated classes
    // from this .proto. Default is empty.
    readonly phpClassPrefix: string,
    // Use this option to change the namespace of php generated classes. Default
    // is empty. When this option is empty, the package name will be used for
    // determining the namespace.
    readonly phpNamespace: string,
    // Use this option to change the namespace of php generated metadata classes.
    // Default is empty. When this option is empty, the proto file name will be
    // used for determining the namespace.
    readonly phpMetadataNamespace: string,
    // Use this option to change the package of ruby generated classes. Default
    // is empty. When this option is not set, the package name will be used for
    // determining the ruby package.
    readonly rubyPackage: string,
    // The parser stores options it doesn't recognize here.
    // See the documentation for the "Options" section above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    javaPackage: "",
    javaOuterClassname: "",
    javaMultipleFiles: false,
    javaGenerateEqualsAndHash: false,
    javaStringCheckUtf8: false,
    optimizeFor: "",
    goPackage: "",
    ccGenericServices: false,
    javaGenericServices: false,
    pyGenericServices: false,
    phpGenericServices: false,
    deprecated: false,
    ccEnableArenas: false,
    objcClassPrefix: "",
    csharpNamespace: "",
    swiftPrefix: "",
    phpClassPrefix: "",
    phpNamespace: "",
    phpMetadataNamespace: "",
    rubyPackage: "",
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["javaPackage"] = v.javaPackage;
      obj["javaOuterClassname"] = v.javaOuterClassname;
      obj["javaMultipleFiles"] = v.javaMultipleFiles;
      obj["javaGenerateEqualsAndHash"] = v.javaGenerateEqualsAndHash;
      obj["javaStringCheckUtf8"] = v.javaStringCheckUtf8;
      obj["optimizeFor"] = v.optimizeFor;
      obj["goPackage"] = v.goPackage;
      obj["ccGenericServices"] = v.ccGenericServices;
      obj["javaGenericServices"] = v.javaGenericServices;
      obj["pyGenericServices"] = v.pyGenericServices;
      obj["phpGenericServices"] = v.phpGenericServices;
      obj["deprecated"] = v.deprecated;
      obj["ccEnableArenas"] = v.ccEnableArenas;
      obj["objcClassPrefix"] = v.objcClassPrefix;
      obj["csharpNamespace"] = v.csharpNamespace;
      obj["swiftPrefix"] = v.swiftPrefix;
      obj["phpClassPrefix"] = v.phpClassPrefix;
      obj["phpNamespace"] = v.phpNamespace;
      obj["phpMetadataNamespace"] = v.phpMetadataNamespace;
      obj["rubyPackage"] = v.rubyPackage;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.FileOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["javaPackage"] = ensureScalar(ctx, v["javaPackage"], "string");
      out["javaOuterClassname"] = ensureScalar(ctx, v["javaOuterClassname"], "string");
      out["javaMultipleFiles"] = ensureScalar(ctx, v["javaMultipleFiles"], "boolean");
      out["javaGenerateEqualsAndHash"] = ensureScalar(ctx, v["javaGenerateEqualsAndHash"], "boolean");
      out["javaStringCheckUtf8"] = ensureScalar(ctx, v["javaStringCheckUtf8"], "boolean");
      {
        const ev = ensureScalar(ctx, v["optimizeFor"], "string") || defaults["optimizeFor"];
        if (!FileOptions.OptimizeModeValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.FileOptions.OptimizeMode`);
        }
        out["optimizeFor"] = ev as any;
      }
      out["goPackage"] = ensureScalar(ctx, v["goPackage"], "string");
      out["ccGenericServices"] = ensureScalar(ctx, v["ccGenericServices"], "boolean");
      out["javaGenericServices"] = ensureScalar(ctx, v["javaGenericServices"], "boolean");
      out["pyGenericServices"] = ensureScalar(ctx, v["pyGenericServices"], "boolean");
      out["phpGenericServices"] = ensureScalar(ctx, v["phpGenericServices"], "boolean");
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["ccEnableArenas"] = ensureScalar(ctx, v["ccEnableArenas"], "boolean");
      out["objcClassPrefix"] = ensureScalar(ctx, v["objcClassPrefix"], "string");
      out["csharpNamespace"] = ensureScalar(ctx, v["csharpNamespace"], "string");
      out["swiftPrefix"] = ensureScalar(ctx, v["swiftPrefix"], "string");
      out["phpClassPrefix"] = ensureScalar(ctx, v["phpClassPrefix"], "string");
      out["phpNamespace"] = ensureScalar(ctx, v["phpNamespace"], "string");
      out["phpMetadataNamespace"] = ensureScalar(ctx, v["phpMetadataNamespace"], "string");
      out["rubyPackage"] = ensureScalar(ctx, v["rubyPackage"], "string");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
  export const OptimizeModeValues = new Set([
    "" as const,
    'SPEED' as const,
    'CODE_SIZE' as const,
    'LITE_RUNTIME' as const,
  ]);
  export type OptimizeMode = typeof OptimizeModeValues extends Set<infer U> ? U : never;

}

// Describes the relationship between generated code and its original source
// file. A GeneratedCodeInfo message is associated with only one generated
// source file, but may contain references to different source .proto files.
export namespace GeneratedCodeInfo {
  export const refName = "google.protobuf.GeneratedCodeInfo" as const;
  export type Entity = {
    readonly type: typeof refName,
    // An Annotation connects some span of text in generated code to an element
    // of its generating .proto file.
    readonly annotation: ReadonlyArray<GeneratedCodeInfo.Annotation.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    annotation: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.GeneratedCodeInfo");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["annotation"] = decodeMessageRepeated(ctx, GeneratedCodeInfo.Annotation.codec, v["annotation"], "annotation");
      return out as any as Entity;
    }
  }();
  export namespace Annotation {
    export const refName = "google.protobuf.GeneratedCodeInfo.Annotation" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly path: ReadonlyArray<number>,
      readonly sourceFile: string,
      readonly begin: number,
      readonly end: number,
    }
    export const defaults: Entity = {
      type: refName,
      path: [],
      sourceFile: "",
      begin: 0,
      end: 0,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.GeneratedCodeInfo.Annotation");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["path"] = ensureScalarRepeated(ctx, v["path"], "number");
        out["sourceFile"] = ensureScalar(ctx, v["sourceFile"], "string");
        out["begin"] = ensureScalar(ctx, v["begin"], "number");
        out["end"] = ensureScalar(ctx, v["end"], "number");
        return out as any as Entity;
      }
    }();
  }

}

export namespace MessageOptions {
  export const refName = "google.protobuf.MessageOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Set true to use the old proto1 MessageSet wire format for extensions.
    // This is provided for backwards-compatibility with the MessageSet wire
    // format.  You should not use this for any other reason:  It's less
    // efficient, has fewer features, and is more complicated.
    // 
    // The message must be defined exactly as follows:
    // message Foo {
    // option message_set_wire_format = true;
    // extensions 4 to max;
    // }
    // Note that the message cannot have any defined fields; MessageSets only
    // have extensions.
    // 
    // All extensions of your type must be singular messages; e.g. they cannot
    // be int32s, enums, or repeated messages.
    // 
    // Because this is an option, the above two restrictions are not enforced by
    // the protocol compiler.
    readonly messageSetWireFormat: boolean,
    // Disables the generation of the standard "descriptor()" accessor, which can
    // conflict with a field of the same name.  This is meant to make migration
    // from proto1 easier; new code should avoid fields named "descriptor".
    readonly noStandardDescriptorAccessor: boolean,
    // Is this message deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for the message, or it will be completely ignored; in the very least,
    // this is a formalization for deprecating messages.
    readonly deprecated: boolean,
    // Whether the message is an automatically generated map entry type for the
    // maps field.
    // 
    // For maps fields:
    // map<KeyType, ValueType> map_field = 1;
    // The parsed descriptor looks like:
    // message MapFieldEntry {
    // option map_entry = true;
    // optional KeyType key = 1;
    // optional ValueType value = 2;
    // }
    // repeated MapFieldEntry map_field = 1;
    // 
    // Implementations may choose not to generate the map_entry=true message, but
    // use a native map in the target language to hold the keys and values.
    // The reflection APIs in such implementations still need to work as
    // if the field is a repeated message field.
    // 
    // NOTE: Do not set the option in .proto files. Always use the maps syntax
    // instead. The option should only be implicitly set by the proto compiler
    // parser.
    readonly mapEntry: boolean,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    messageSetWireFormat: false,
    noStandardDescriptorAccessor: false,
    deprecated: false,
    mapEntry: false,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["messageSetWireFormat"] = v.messageSetWireFormat;
      obj["noStandardDescriptorAccessor"] = v.noStandardDescriptorAccessor;
      obj["deprecated"] = v.deprecated;
      obj["mapEntry"] = v.mapEntry;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.MessageOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["messageSetWireFormat"] = ensureScalar(ctx, v["messageSetWireFormat"], "boolean");
      out["noStandardDescriptorAccessor"] = ensureScalar(ctx, v["noStandardDescriptorAccessor"], "boolean");
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["mapEntry"] = ensureScalar(ctx, v["mapEntry"], "boolean");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

// Describes a method of a service.
export namespace MethodDescriptorProto {
  export const refName = "google.protobuf.MethodDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    // Input and output type names.  These are resolved in the same way as
    // FieldDescriptorProto.type_name, but must refer to a message type.
    readonly inputType: string,
    readonly outputType: string,
    readonly options: MethodOptions.Entity | undefined,
    // Identifies if client streams multiple client messages
    readonly clientStreaming: boolean,
    // Identifies if server streams multiple server messages
    readonly serverStreaming: boolean,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    inputType: "",
    outputType: "",
    options: undefined,
    clientStreaming: false,
    serverStreaming: false,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["inputType"] = v.inputType;
      obj["outputType"] = v.outputType;
      obj["options"] = MethodOptions.codec.encode(v.options);
      obj["clientStreaming"] = v.clientStreaming;
      obj["serverStreaming"] = v.serverStreaming;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.MethodDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["inputType"] = ensureScalar(ctx, v["inputType"], "string");
      out["outputType"] = ensureScalar(ctx, v["outputType"], "string");
      out["options"] = ctx.decode(MethodOptions.codec, v["options"], "options");
      out["clientStreaming"] = ensureScalar(ctx, v["clientStreaming"], "boolean");
      out["serverStreaming"] = ensureScalar(ctx, v["serverStreaming"], "boolean");
      return out as any as Entity;
    }
  }();
}

export namespace MethodOptions {
  export const refName = "google.protobuf.MethodOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Is this method deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for the method, or it will be completely ignored; in the very least,
    // this is a formalization for deprecating methods.
    readonly deprecated: boolean,
    readonly idempotencyLevel: MethodOptions.IdempotencyLevel,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    deprecated: false,
    idempotencyLevel: "IDEMPOTENCY_UNKNOWN",
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["deprecated"] = v.deprecated;
      obj["idempotencyLevel"] = v.idempotencyLevel;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.MethodOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      {
        const ev = ensureScalar(ctx, v["idempotencyLevel"], "string") || defaults["idempotencyLevel"];
        if (!MethodOptions.IdempotencyLevelValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .google.protobuf.MethodOptions.IdempotencyLevel`);
        }
        out["idempotencyLevel"] = ev as any;
      }
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
  export const IdempotencyLevelValues = new Set([
    'IDEMPOTENCY_UNKNOWN' as const,
    'NO_SIDE_EFFECTS' as const,
    'IDEMPOTENT' as const,
  ]);
  export type IdempotencyLevel = typeof IdempotencyLevelValues extends Set<infer U> ? U : never;

}

export const NullValueValues = new Set([
  'NULL_VALUE' as const,
]);
export type NullValue = typeof NullValueValues extends Set<infer U> ? U : never;

// Describes a oneof.
export namespace OneofDescriptorProto {
  export const refName = "google.protobuf.OneofDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly options: OneofOptions.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    options: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["options"] = OneofOptions.codec.encode(v.options);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.OneofDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["options"] = ctx.decode(OneofOptions.codec, v["options"], "options");
      return out as any as Entity;
    }
  }();
}

export namespace OneofOptions {
  export const refName = "google.protobuf.OneofOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.OneofOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

// Describes a service.
export namespace ServiceDescriptorProto {
  export const refName = "google.protobuf.ServiceDescriptorProto" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly method: ReadonlyArray<MethodDescriptorProto.Entity>,
    readonly options: ServiceOptions.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    method: [],
    options: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["method"] = [];
      for (const item of v.method) {
        obj["method"].push(MethodDescriptorProto.codec.encode(item));
      }
      obj["options"] = ServiceOptions.codec.encode(v.options);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.ServiceDescriptorProto");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["method"] = decodeMessageRepeated(ctx, MethodDescriptorProto.codec, v["method"], "method");
      out["options"] = ctx.decode(ServiceOptions.codec, v["options"], "options");
      return out as any as Entity;
    }
  }();
}

export namespace ServiceOptions {
  export const refName = "google.protobuf.ServiceOptions" as const;
  export type Entity = {
    readonly type: typeof refName,
    // Is this service deprecated?
    // Depending on the target platform, this can emit Deprecated annotations
    // for the service, or it will be completely ignored; in the very least,
    // this is a formalization for deprecating services.
    readonly deprecated: boolean,
    // The parser stores options it doesn't recognize here. See above.
    readonly uninterpretedOption: ReadonlyArray<UninterpretedOption.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    deprecated: false,
    uninterpretedOption: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["deprecated"] = v.deprecated;
      obj["uninterpretedOption"] = [];
      for (const item of v.uninterpretedOption) {
        obj["uninterpretedOption"].push(UninterpretedOption.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.ServiceOptions");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["deprecated"] = ensureScalar(ctx, v["deprecated"], "boolean");
      out["uninterpretedOption"] = decodeMessageRepeated(ctx, UninterpretedOption.codec, v["uninterpretedOption"], "uninterpretedOption");
      return out as any as Entity;
    }
  }();
}

// Encapsulates information about the original source file from which a
// FileDescriptorProto was generated.
export namespace SourceCodeInfo {
  export const refName = "google.protobuf.SourceCodeInfo" as const;
  export type Entity = {
    readonly type: typeof refName,
    // A Location identifies a piece of source code in a .proto file which
    // corresponds to a particular definition.  This information is intended
    // to be useful to IDEs, code indexers, documentation generators, and similar
    // tools.
    // 
    // For example, say we have a file like:
    // message Foo {
    // optional string foo = 1;
    // }
    // Let's look at just the field definition:
    // optional string foo = 1;
    // ^       ^^     ^^  ^  ^^^
    // a       bc     de  f  ghi
    // We have the following locations:
    // span   path               represents
    // [a,i)  [ 4, 0, 2, 0 ]     The whole field definition.
    // [a,b)  [ 4, 0, 2, 0, 4 ]  The label (optional).
    // [c,d)  [ 4, 0, 2, 0, 5 ]  The type (string).
    // [e,f)  [ 4, 0, 2, 0, 1 ]  The name (foo).
    // [g,h)  [ 4, 0, 2, 0, 3 ]  The number (1).
    // 
    // Notes:
    // - A location may refer to a repeated field itself (i.e. not to any
    // particular index within it).  This is used whenever a set of elements are
    // logically enclosed in a single code segment.  For example, an entire
    // extend block (possibly containing multiple extension definitions) will
    // have an outer location whose path refers to the "extensions" repeated
    // field without an index.
    // - Multiple locations may have the same path.  This happens when a single
    // logical declaration is spread out across multiple places.  The most
    // obvious example is the "extend" block again -- there may be multiple
    // extend blocks in the same scope, each of which will have the same path.
    // - A location's span is not always a subset of its parent's span.  For
    // example, the "extendee" of an extension declaration appears at the
    // beginning of the "extend" block and is shared by all extensions within
    // the block.
    // - Just because a location's span is a subset of some other location's span
    // does not mean that it is a descendant.  For example, a "group" defines
    // both a type and a field in a single declaration.  Thus, the locations
    // corresponding to the type and field and their components will overlap.
    // - Code which tries to interpret locations should probably be designed to
    // ignore those that it doesn't understand, as more types of locations could
    // be recorded in the future.
    readonly location: ReadonlyArray<SourceCodeInfo.Location.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    location: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.SourceCodeInfo");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["location"] = decodeMessageRepeated(ctx, SourceCodeInfo.Location.codec, v["location"], "location");
      return out as any as Entity;
    }
  }();
  export namespace Location {
    export const refName = "google.protobuf.SourceCodeInfo.Location" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly path: ReadonlyArray<number>,
      readonly span: ReadonlyArray<number>,
      readonly leadingComments: string,
      readonly trailingComments: string,
      readonly leadingDetachedComments: ReadonlyArray<string>,
    }
    export const defaults: Entity = {
      type: refName,
      path: [],
      span: [],
      leadingComments: "",
      trailingComments: "",
      leadingDetachedComments: [],
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.SourceCodeInfo.Location");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["path"] = ensureScalarRepeated(ctx, v["path"], "number");
        out["span"] = ensureScalarRepeated(ctx, v["span"], "number");
        out["leadingComments"] = ensureScalar(ctx, v["leadingComments"], "string");
        out["trailingComments"] = ensureScalar(ctx, v["trailingComments"], "string");
        out["leadingDetachedComments"] = ensureScalarRepeated(ctx, v["leadingDetachedComments"], "string");
        return out as any as Entity;
      }
    }();
  }

}

// A message representing a option the parser does not recognize. This only
// appears in options protos created by the compiler::Parser class.
// DescriptorPool resolves these when building Descriptor objects. Therefore,
// options protos in descriptor objects (e.g. returned by Descriptor::options(),
// or produced by Descriptor::CopyTo()) will never have UninterpretedOptions
// in them.
export namespace UninterpretedOption {
  export const refName = "google.protobuf.UninterpretedOption" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: ReadonlyArray<UninterpretedOption.NamePart.Entity>,
    // The value of the uninterpreted option, in whatever type the tokenizer
    // identified it as during parsing. Exactly one of these should be set.
    readonly identifierValue: string,
    readonly positiveIntValue: jsbi,
    readonly negativeIntValue: jsbi,
    readonly doubleValue: number,
    readonly stringValue: string,
    readonly aggregateValue: string,
  }
  export const defaults: Entity = {
    type: refName,
    name: [],
    identifierValue: "",
    positiveIntValue: jsbi.BigInt(0),
    negativeIntValue: jsbi.BigInt(0),
    doubleValue: 0,
    stringValue: "",
    aggregateValue: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = [];
      for (const item of v.name) {
        obj["name"].push(UninterpretedOption.NamePart.codec.encode(item));
      }
      obj["identifierValue"] = v.identifierValue;
      obj["positiveIntValue"] = v.positiveIntValue.toString(10);
      obj["negativeIntValue"] = v.negativeIntValue.toString(10);
      obj["doubleValue"] = v.doubleValue;
      obj["stringValue"] = v.stringValue;
      obj["aggregateValue"] = v.aggregateValue;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".google.protobuf.UninterpretedOption");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = decodeMessageRepeated(ctx, UninterpretedOption.NamePart.codec, v["name"], "name");
      out["identifierValue"] = ensureScalar(ctx, v["identifierValue"], "string");
      out["positiveIntValue"] = ensureBigInt(ctx, v["positiveIntValue"]);
      out["negativeIntValue"] = ensureBigInt(ctx, v["negativeIntValue"]);
      out["doubleValue"] = ensureScalar(ctx, v["doubleValue"], "number");
      out["stringValue"] = ensureScalar(ctx, v["stringValue"], "string");
      out["aggregateValue"] = ensureScalar(ctx, v["aggregateValue"], "string");
      return out as any as Entity;
    }
  }();
  export namespace NamePart {
    export const refName = "google.protobuf.UninterpretedOption.NamePart" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly namePart: string,
      readonly isExtension: boolean,
    }
    export const defaults: Entity = {
      type: refName,
      namePart: "",
      isExtension: false,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".google.protobuf.UninterpretedOption.NamePart");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["namePart"] = ensureScalar(ctx, v["namePart"], "string");
        out["isExtension"] = ensureScalar(ctx, v["isExtension"], "boolean");
        return out as any as Entity;
      }
    }();
  }

}

