import React from 'react'

import { Button, buttonReset } from 'components/ui/button'
import { Link } from 'components/ui/link'
import { IconAngle, IconType } from 'components/ui/icons';
import { Metadata } from 'generated/mos/entity'
import { space } from 'helpers/style'
import { parseMetadataTimestamp } from 'helpers/metadata'
import styled, { keyframes } from 'styled';
import { ThemeInterface } from 'styled/theme';

const TRUNCATION_PX = 220;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 ${space(2)};
  };
  /* hidden unless the wrapper is hovered */
  opacity: 0;
  transition: ${({ theme }) => theme.transition.base};
`;

const TimeLabel = styled.div<{ fresh?: boolean }>`
  position: absolute;
  bottom: 12px;
  right: 16px;
  font-size: 11px;
  color: ${({ theme }) => theme.color.gray};
  /* visible unless the wrapper is hovered */
  opacity: 1;
  transition: ${({ theme }) => theme.transition.base};
  /* purple dot if fresh */
  ${({ fresh, theme }) => fresh && `
    &::before {
      content: "";
      height: 8px;
      width: 8px;
      background: ${theme.color.primary};
      border-radius: 50px;
      position: absolute;
      left: -12px;
      top: 2px;
    }
  `};
`;

const pulseSec = 2.5;
const pulseBgFrames = (theme: ThemeInterface) => keyframes`
  0% { background: ${theme.color.primaryL90}; }
  5% { background: ${theme.color.white} }
  10% { background: ${theme.color.primaryL90}; }
  15% { background: ${theme.color.white} }
  20% { background: ${theme.color.primaryL90}; }
`;
const pulseIconBgFrames = (theme: ThemeInterface) => keyframes`
  0%, 20% {
    border: 2px solid ${theme.color.white};
    background-color: ${theme.color.primary};
  }
`;
const pulseIconFrames = (theme: ThemeInterface) => keyframes`
  0%, 20% {
    fill: ${theme.color.white};
  }
`;

const Wrapper = styled.div<{ height?: number; fresh?: boolean }>`
  ${({ height }) => height && `height: ${height}px;`}
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.color.grayL80};
  background-color: ${({ theme }) => theme.color.grayL90};
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.color.white};
    ${Actions} { opacity: 1; };
    ${TimeLabel} { opacity: 0; };
  }

  animation-name: ${({ theme }) => pulseBgFrames(theme)};
  ${({ fresh }) => fresh && `animation-duration: ${pulseSec}s;`}
`;

const Main = styled.button`
  ${buttonReset}
  font-size: 14px;
  color: ${({ theme }) => theme.color.darkText};
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  &:focus {
    outline: none;

    &::after {
      display: none;
    }
  }
  &::-moz-focus-inner { border: 0; }
`;

const ArrowWrapper = styled.div<{ level: number }>`
  width: 16px;
  display: flex;
  justify-content: center;
  margin-left: ${({ level }) => level * (space(6, false) as number)}px;
`;

const LargeIconWrapper = styled.div<{ fresh?: boolean }>`
  height: 40px;
  width: 40px;
  border: 1px solid ${({ theme }) => theme.color.grayL90};
  border-radius: 50px;
  margin: 0 ${space(2)} 0 ${space(4)};
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: ${({ theme }) => pulseIconBgFrames(theme)};
  ${({ fresh }) => fresh && `animation-duration: ${pulseSec}s;`}
  svg {
    animation-name: ${({ theme }) => pulseIconFrames(theme)};
    ${({ fresh }) => fresh && `animation-duration: ${pulseSec}s;`}
  }
`;

const TruncatedText = styled.div<{ maxWidth?: number; bold?: boolean }>`
  text-align: left;
  padding-right: ${space(2)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ bold, theme }) => bold && `font-weight: ${theme.font.weight.bold};`}
`;

type CommonProps = {
  readonly active?: boolean;
  readonly height?: number;
  readonly icon: IconType;
  readonly onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  readonly onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  readonly onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  readonly primaryAction?: {
    readonly label: string;
    readonly to?: string;
    readonly onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  };
  readonly renderMenu?: JSX.Element;
  readonly title: string;
};

type TreeListItemProps = CommonProps & {
  readonly isExpanded?: boolean;
  readonly isLastLevel?: boolean;
  readonly level: number;
  readonly childCount: number;
};

export const TreeListItem = (props: TreeListItemProps) => {
  const { icon, primaryAction } = props;
  const IconComponent = icon

  return (
    <Wrapper onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} height={props.height || 48}>
      <Main onClick={props.onClick}>
        <ArrowWrapper level={props.level}>
          {!props.isLastLevel && props.childCount ? <IconAngle size="small" rotate={props.isExpanded ? 0 : 270} /> : null}
        </ArrowWrapper>
        <div style={{ padding: `0 ${space(2)}` }}>
          <IconComponent size="small" />
        </div>
        {props.title}
      </Main>

      <Actions>
        {!!primaryAction && (
          primaryAction.to ? (
            <Link to={primaryAction.to} onClick={primaryAction.onClick}>{primaryAction.label}</Link>
          ) : (
              <Button variant="link" onClick={primaryAction.onClick}>{primaryAction.label}</Button>
            )
        )}

        {props.renderMenu}
      </Actions>
    </Wrapper>
  );
};

type FlatListItemProps = CommonProps & {
  readonly description: string;
  readonly metadata: Metadata.Entity;
  readonly subtitle: string;
};

export const FlatListItem = (props: FlatListItemProps) => {
  const { icon, metadata, primaryAction } = props;
  const IconComponent = icon

  const { recentlyModified: fresh, value: modifiedString } = parseMetadataTimestamp(metadata)

  return (
    <Wrapper onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} height={props.height || 64} fresh={fresh}>
      <Main onClick={props.onClick}>
        <LargeIconWrapper fresh={fresh}>
          <IconComponent />
        </LargeIconWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <TruncatedText bold maxWidth={TRUNCATION_PX}>{props.title}</TruncatedText>
            <TruncatedText maxWidth={TRUNCATION_PX}>{props.subtitle}</TruncatedText>
          </div>
          <div>
            <TruncatedText maxWidth={TRUNCATION_PX * 2}>{props.description}</TruncatedText>
          </div>
        </div>
      </Main>

      <Actions>
        {!!primaryAction && (
          primaryAction.to ? (
            <Link to={primaryAction.to} onClick={primaryAction.onClick}>{primaryAction.label}</Link>
          ) : (
              <Button variant="link" onClick={primaryAction.onClick}>{primaryAction.label}</Button>
            )
        )}

        {props.renderMenu}
      </Actions>
      {modifiedString && <TimeLabel fresh={fresh}>{modifiedString}</TimeLabel>}
    </Wrapper>
  );
};
