export const isEmail = (str?: string): boolean => {
  // Unfortunately, stricter validation than this for emails is invalid:
  return str ? str.includes('@') : false;
};

export const isNumeric = (str?: string): boolean => {
  return str ? !isNaN(Number(str)) : false;
};

export const passwordValidationError = (v: string): string | undefined => {
  // FIXME: this is perhaps out of sync with the backend
  if (!v.trim()) {
    return 'Password is required';
  }
  if (v.length < 8) {
    return 'Password must be 8 characters or more';
  }
  if (!/[A-Za-z]/.test(v)) {
    return 'Password must contain at least one letter';
  }
  if (!/[0-9]/.test(v)) {
    return 'Password must contain at least one number';
  }
  return undefined;
};
