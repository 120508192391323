import { space } from 'helpers/style'
import styled from 'styled'

export const SearchContainer = styled.div`
  margin-bottom: ${space(4)};
  position: relative;
`

export const SearchTools = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 36px;
  margin: 0 0 ${space(4)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 500;
  text-transform: uppercase;
`

export const DisplayOptions = styled.div`
  display: flex;
  margin-left: auto;
`
