// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Code, CodeValues } from "generated/google/rpc";
import { AttributeRef } from "generated/mos/entity";
import { Placement } from "generated/mos/placement";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureBigInt, ensureScalar, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";
import jsbi from "jsbi";

export namespace ResolveLinkableFieldValuesRequest {
  export const refName = "mos.linked_field_resolver.ResolveLinkableFieldValuesRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly targetFieldRefs: ReadonlyArray<AttributeRef.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    targetFieldRefs: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolveLinkableFieldValuesRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["targetFieldRefs"] = decodeMessageRepeated(ctx, AttributeRef.codec, v["targetFieldRefs"], "targetFieldRefs");
      return out as any as Entity;
    }
  }();
}

export namespace ResolveLinkableFieldValuesResponse {
  export const refName = "mos.linked_field_resolver.ResolveLinkableFieldValuesResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly resolvedValueResults: ReadonlyArray<ResolvedValueResult.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    resolvedValueResults: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["resolvedValueResults"] = [];
      for (const item of v.resolvedValueResults) {
        obj["resolvedValueResults"].push(ResolvedValueResult.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolveLinkableFieldValuesResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["resolvedValueResults"] = decodeMessageRepeated(ctx, ResolvedValueResult.codec, v["resolvedValueResults"], "resolvedValueResults");
      return out as any as Entity;
    }
  }();
}

export namespace ResolvedValueResult {
  export const refName = "mos.linked_field_resolver.ResolvedValueResult" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly result: ResolvedValueResult.Value.Entity | ResolvedValueResult.Error.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    result: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.result;
        if (uv !== undefined) {
          if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Error") {
            obj["error"] = ResolvedValueResult.Error.codec.encode(uv);
          }
          else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value") {
            obj["value"] = ResolvedValueResult.Value.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linked_field_resolver.ResolvedValueResult.result");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["error"] !== undefined) {
          out["result"] = ctx.decode(ResolvedValueResult.Error.codec, v["error"], "error");
        }
        if (v["value"] !== undefined) {
          out["result"] = ctx.decode(ResolvedValueResult.Value.codec, v["value"], "value");
        }
      }
      return out as any as Entity;
    }
  }();
  export namespace Value {
    export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly targetFieldRef: AttributeRef.Entity | undefined,
      readonly targetFieldDisplayName: string,
      readonly recursiveIntermediateTargetFieldRefs: ReadonlyArray<AttributeRef.Entity>,
      readonly recursiveIntermediateTargetFieldDisplayNames: ReadonlyArray<string>,
      readonly targetValue: ResolvedValueResult.Value.StringValue.Entity | ResolvedValueResult.Value.MediaValue.Entity | ResolvedValueResult.Value.PlacementValue.Entity | ResolvedValueResult.Value.Int64Value.Entity | ResolvedValueResult.Value.DoubleValue.Entity | ResolvedValueResult.Value.BoolValue.Entity | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      targetFieldRef: undefined,
      targetFieldDisplayName: "",
      recursiveIntermediateTargetFieldRefs: [],
      recursiveIntermediateTargetFieldDisplayNames: [],
      targetValue: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["targetFieldRef"] = AttributeRef.codec.encode(v.targetFieldRef);
        obj["targetFieldDisplayName"] = v.targetFieldDisplayName;
        obj["recursiveIntermediateTargetFieldRefs"] = [];
        for (const item of v.recursiveIntermediateTargetFieldRefs) {
          obj["recursiveIntermediateTargetFieldRefs"].push(AttributeRef.codec.encode(item));
        }
        obj["recursiveIntermediateTargetFieldDisplayNames"] = v.recursiveIntermediateTargetFieldDisplayNames;
        {
          const uv: any = v.targetValue;
          if (uv !== undefined) {
            if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.BoolValue") {
              obj["bool"] = ResolvedValueResult.Value.BoolValue.codec.encode(uv);
            }
            else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.DoubleValue") {
              obj["double"] = ResolvedValueResult.Value.DoubleValue.codec.encode(uv);
            }
            else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.Int64Value") {
              obj["int64"] = ResolvedValueResult.Value.Int64Value.codec.encode(uv);
            }
            else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.MediaValue") {
              obj["media"] = ResolvedValueResult.Value.MediaValue.codec.encode(uv);
            }
            else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.PlacementValue") {
              obj["placement"] = ResolvedValueResult.Value.PlacementValue.codec.encode(uv);
            }
            else if (uv.type == "mos.linked_field_resolver.ResolvedValueResult.Value.StringValue") {
              obj["string"] = ResolvedValueResult.Value.StringValue.codec.encode(uv);
            }
            else {
              throw new WebRPCError("union discrimination failed for .mos.linked_field_resolver.ResolvedValueResult.Value.target_value");
            }
          }
        }
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["targetFieldRef"] = ctx.decode(AttributeRef.codec, v["targetFieldRef"], "targetFieldRef");
        out["targetFieldDisplayName"] = ensureScalar(ctx, v["targetFieldDisplayName"], "string");
        out["recursiveIntermediateTargetFieldRefs"] = decodeMessageRepeated(ctx, AttributeRef.codec, v["recursiveIntermediateTargetFieldRefs"], "recursiveIntermediateTargetFieldRefs");
        out["recursiveIntermediateTargetFieldDisplayNames"] = ensureScalarRepeated(ctx, v["recursiveIntermediateTargetFieldDisplayNames"], "string");
        {
          if (v["bool"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.BoolValue.codec, v["bool"], "bool");
          }
          if (v["double"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.DoubleValue.codec, v["double"], "double");
          }
          if (v["int64"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.Int64Value.codec, v["int64"], "int64");
          }
          if (v["media"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.MediaValue.codec, v["media"], "media");
          }
          if (v["placement"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.PlacementValue.codec, v["placement"], "placement");
          }
          if (v["string"] !== undefined) {
            out["targetValue"] = ctx.decode(ResolvedValueResult.Value.StringValue.codec, v["string"], "string");
          }
        }
        return out as any as Entity;
      }
    }();
    export namespace StringValue {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.StringValue" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly string: string,
      }
      export const defaults: Entity = {
        type: refName,
        string: "",
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const { type, ...out } = v;
          return out;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.StringValue");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["string"] = ensureScalar(ctx, v["string"], "string");
          return out as any as Entity;
        }
      }();
    }

    export namespace MediaValue {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.MediaValue" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly media: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
      }
      export const defaults: Entity = {
        type: refName,
        media: undefined,
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const { type, ...out } = v;
          return out;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.MediaValue");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["media"] = v["media"];
          return out as any as Entity;
        }
      }();
    }

    export namespace PlacementValue {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.PlacementValue" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly placement: Placement.Entity | undefined,
      }
      export const defaults: Entity = {
        type: refName,
        placement: undefined,
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const obj: RemoteObject = {};
          obj["placement"] = Placement.codec.encode(v.placement);
          return obj;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.PlacementValue");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["placement"] = ctx.decode(Placement.codec, v["placement"], "placement");
          return out as any as Entity;
        }
      }();
    }

    export namespace Int64Value {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.Int64Value" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly int64: jsbi,
      }
      export const defaults: Entity = {
        type: refName,
        int64: jsbi.BigInt(0),
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const obj: RemoteObject = {};
          obj["int64"] = v.int64.toString(10);
          return obj;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.Int64Value");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["int64"] = ensureBigInt(ctx, v["int64"]);
          return out as any as Entity;
        }
      }();
    }

    export namespace DoubleValue {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.DoubleValue" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly double: number,
      }
      export const defaults: Entity = {
        type: refName,
        double: 0,
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const { type, ...out } = v;
          return out;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.DoubleValue");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["double"] = ensureScalar(ctx, v["double"], "number");
          return out as any as Entity;
        }
      }();
    }

    export namespace BoolValue {
      export const refName = "mos.linked_field_resolver.ResolvedValueResult.Value.BoolValue" as const;
      export type Entity = {
        readonly type: typeof refName,
        readonly bool: boolean,
      }
      export const defaults: Entity = {
        type: refName,
        bool: false,
      }
      export const codec: Codec<Entity> = new class {
        public encode(v: Entity): RemoteObject;
        public encode(v: Entity | undefined): RemoteObject | null {
          if (!v) return null;
          const { type, ...out } = v;
          return out;
        }
        public decode(v: RemoteObject): Entity;
        public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
          if (v === undefined || v === null) return undefined;
          if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Value.BoolValue");
          if (typeof(v) !== "object") throw ctx.expected("object", v);
          const out: RemoteObject = { ...defaults };
          out["bool"] = ensureScalar(ctx, v["bool"], "boolean");
          return out as any as Entity;
        }
      }();
    }

  }

  export namespace Error {
    export const refName = "mos.linked_field_resolver.ResolvedValueResult.Error" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly targetFieldRef: AttributeRef.Entity | undefined,
      readonly statusCode: Code,
    }
    export const defaults: Entity = {
      type: refName,
      targetFieldRef: undefined,
      statusCode: "OK",
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        return stripTypePropertyDeep(v);
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.linked_field_resolver.ResolvedValueResult.Error");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["targetFieldRef"] = ctx.decode(AttributeRef.codec, v["targetFieldRef"], "targetFieldRef");
        {
          const ev = ensureScalar(ctx, v["statusCode"], "string") || defaults["statusCode"];
          if (!CodeValues.has(ev as any)) {
            throw ctx.error(`unknown value "${ev}" for enum .google.rpc.Code`);
          }
          out["statusCode"] = ev as any;
        }
        return out as any as Entity;
      }
    }();
  }

}

