import React from 'react'

import { ColumnAccordion, ColumnAccordionItem } from 'components/ui/accordion';
import { isRefEqual, Ref, refToUrn } from 'entity';
import { Space, SpaceType } from 'generated/mos/structure';
import { objectKeys } from 'helpers/core';

import { Structures, StructureRef, structurePreposition } from 'domains/structures/entities';
import { GlobalMapState } from 'domains/structures/store';

import { spacesBindableActionCreators } from 'domains/spaces/actions';
import { SpacesFilterState, SpaceTypes } from 'domains/spaces/store';

import { ItemList } from './item-list';
import { SpacesData, VisibleScope, visibleScopes } from './spaces-data';


type Props = {
  // These are deliberately undefined, not optional, to enforce that they not forgotten:
  readonly structures: Structures | undefined;
  readonly spacesData: SpacesData | undefined;

  readonly highlightRef?: Space.Ref;
  readonly setHighlightedRef: (v: Space.Ref | undefined) => void;

  readonly focusSpaceOnMap: (v: Space.Entity) => void;
  readonly globalMap: GlobalMapState;
  readonly spacesFilters: SpacesFilterState;
  readonly spacesFiltersUpdate: typeof spacesBindableActionCreators.spacesFiltersUpdate;
};

export class SpacesListGroups extends React.Component<Props> {
  // only allow pre-expand accordion once per lifecycle
  private hasPreExpanded = false;

  public componentDidMount = () => this.preExpand();
  public componentDidUpdate = () => this.preExpand();

  // remove lastEdited ref on unmount as this data is now 'stale'
  public componentWillUnmount = () => this.props.spacesFiltersUpdate({ lastEdited: undefined });

  private preExpand = () => {
    // TODO once concept of highlightRef has been implemented make this open highlightRef section on update too
    const { spacesData } = this.props;
    const { lastEdited } = this.props.spacesFilters;

    if (lastEdited && spacesData && !this.hasPreExpanded) {
      for (const scope of visibleScopes) {
        const spaceSet = spacesData.sets[scope];
        const scopeContainsLastEdited = spaceSet.filter((space) => space.ref && space.ref.id === lastEdited.id).length > 0;

        if (visibleScopes.includes(scope) && scopeContainsLastEdited) {
          this.hasPreExpanded = true;
          this.props.spacesFiltersUpdate({ expanded: { [scope]: true } });
        }
      }
    }
  }

  private focusSpaceInList = (item: Space.Entity) => {
    const { spacesData } = this.props;
    const spaceInOtherScope = spacesData && spacesData.sets.otherScopes.filter(
        (space) => space.ref && item.ref && space.ref.id === item.ref.id).length > 0;

    if (spaceInOtherScope) {
      this.props.spacesFiltersUpdate({ expanded: { currentScope: true } });
    }
    // FIXME: scroll to space
  }

  private onAccordionChange = (key: string) => {
    const expanded = { [key]: !this.props.spacesFilters.expanded[key] };
    this.props.spacesFiltersUpdate({ expanded });
  }

  private getTitles = (structures: Structures | undefined, globalMap: GlobalMapState) => {
    const { activeRef } = globalMap;
    const structure = structures && activeRef ? structures.index[refToUrn(activeRef)] : undefined;

    return {
      currentScope: structure ? `Spaces ${structurePreposition(activeRef!)} ${structure.name}` : 'Spaces with boundary',
      unpositioned: 'Spaces with no boundary',
      otherScopes: 'Spaces at other locations',
    };
  }

  public render() {
    const {
      globalMap,
      spacesFilters,
      spacesData,
      focusSpaceOnMap,
      highlightRef,
      setHighlightedRef,
    } = this.props;

    if (!spacesData || !spacesData.sets.allOrdered) {
      return null;
    }

    const titles = this.getTitles(this.props.structures, globalMap);

    // FIXME use this
    const scrollToIndex = (items: ReadonlyArray<Space.Entity>) => {
      if (!this.props.highlightRef) {
        return undefined;
      }
      const scrollToIndex = items.findIndex((item) => isRefEqual(item.ref, this.props.highlightRef));
      return scrollToIndex === -1 ? undefined : scrollToIndex;
    };

    const shared = {
      focusSpaceOnMap,
      focusSpaceInList: this.focusSpaceInList,
      highlightRef,
      setHighlightedRef,
    };

    return (
      <ColumnAccordion openItems={spacesFilters.expanded} onChange={this.onAccordionChange}>

        <CollapsibleSpaces
          id="currentScope"
          title={titles.currentScope}
          spacesData={spacesData}
          {...shared}
        />
        <CollapsibleSpaces
          id="unpositioned"
          title={titles.unpositioned}
          spacesData={spacesData}
          {...shared}
        />
        {!globalMap.activeRef ? null :
        <CollapsibleSpaces
          id="otherScopes"
          title={titles.otherScopes}
          spacesData={spacesData}
          {...shared}
          />
        }

      </ColumnAccordion>
    );
  }
}

type CollapsibleSpacesProps = Pick<Props, 'focusSpaceOnMap' | 'highlightRef' | 'setHighlightedRef'> & {
  readonly id: VisibleScope;
  readonly title: string;
  readonly spacesData: SpacesData;
  readonly focusSpaceInList: (i: Space.Entity) => void;
};

const CollapsibleSpaces = ({ id, title, spacesData, focusSpaceOnMap, focusSpaceInList, setHighlightedRef, highlightRef }: CollapsibleSpacesProps) => {
  const items = spacesData.sets[id];

  return (
    <ColumnAccordionItem id={id} label={items.length} title={title} innerHeight={items.length * 64}>
      <ItemList
        items={items}
        spacesData={spacesData}
        highlightRef={highlightRef}
        onClick={(ref: Ref) => {
          const item = items.find((i) => i && i.ref ?  i.ref.id === ref.id : false);
          if (item) {
            focusSpaceOnMap(item);
            focusSpaceInList(item);
          }
        }}
        onMouseEnter={setHighlightedRef}
        onMouseLeave={() => setHighlightedRef(undefined)}
      />
    </ColumnAccordionItem>
  );
};
