import React, { ReactNode } from 'react'

import { IconAngle } from 'components/ui/icons'
import { ICON_CONTAINER_CLASSNAME } from 'components/ui/icons/Icon'
import { Loader as LoaderComponent } from 'components/ui/loader'
import { space } from 'helpers/style'

import styled, { css } from 'styled'
import { NavLinkItem } from '../primary-navigation'

type ButtonBase = {
  readonly children: ReactNode;
  readonly isDisabled?: boolean;
  readonly darkTheme?: boolean;
  readonly isIcon?: boolean;
  readonly isSmall?: boolean; // FIXME: legacy button support.
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Directly maps to the native HTML attribute.
   */
  readonly type?: 'button' | 'submit' | 'reset' | undefined;
  readonly ref?: React.Ref<any>;
}

type ButtonDefault = ButtonBase & {
  readonly variant: 'default';
  readonly appearance?: 'primary' | 'secondary' | 'positive' | 'negative';
  readonly isLoading?: boolean;
}

type ButtonSubtle = ButtonBase & {
  readonly variant?: 'subtle';
  readonly appearance?: 'primary';
}

type ButtonAsLink = ButtonBase & {
  readonly variant?: 'link';
  readonly appearance?: 'primary' | 'secondary';
}

type Props = ButtonDefault | ButtonSubtle | ButtonAsLink

const Loader = styled(LoaderComponent)`
  top: 15px;
  left: calc(50% - 4px);
`

/**
 * When you need to group `Button` components together horizontally you should use this component. It ensures that they are event spaced throughout the application.
 *
 * In certain cases it can make sense to use this for grouping `Link` components as well.
 */
export const ButtonGroup = styled.div<{ align?: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ align: alignProp }) => {
    const align = alignProp || 'right'
    return `
      justify-content: ${align === 'right' ? 'flex-end' : 'flex-start'};
    `
  }}

  a, button {
    margin-left: ${space(3)};

    &:first-child {
      margin-left: 0;
    }
  }
`

export const ctaFocus = css`
  :focus {
    position: relative;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      display: block;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border: 1px dotted ${({ theme }) => theme.color.primary};
    }
  }
`

export const solidCtaFocus = css`
  &:focus {
    position: relative;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% + 30px);
      height: 32px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.color.grayD20};
    }
  }
`

export const buttonReset = css<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 42px;
  appearance: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.base};
  transition: ${({ theme }) => theme.transition.base};
  user-select: none;
  white-space: nowrap;

  ${ctaFocus}
`

const ButtonElement = styled.button<Props>`
  ${buttonReset}

  justify-content: ${({ variant, isIcon }) => isIcon || variant === 'default' ? 'center' : null};
  height: ${({ isIcon, isSmall }) => {
    if (isIcon && isSmall) {
      return '32px'
    }

    return '42px'
  }};

  min-width: ${({ isIcon, isSmall, variant }) => {
    if (!isIcon && !isSmall && variant === 'default') {
      return '132px'
    }

    if (isIcon && !isSmall) {
      return '42px'
    }

    return null
  }};
  padding: ${({ variant, isIcon }) => !isIcon && variant !== 'subtle' ? `0 ${space(4)}` : 0};
  position: relative;
  width: ${({ isIcon, isSmall }) => {
    if (isIcon && isSmall) {
      return '32px'
    }

    if (isIcon) {
      return '42px'
    }

    return null
  }};

  border-radius: 42px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  font-size: ${({ theme, variant }) => variant !== 'subtle' ? theme.font.size.small : theme.font.size.xSmall};

  &:focus:after {
    border-radius: ${({ variant }) => variant === 'default' ? '42px' : 0};
  }

  .${ICON_CONTAINER_CLASSNAME} {
    margin-right: ${({ isIcon }) => !isIcon ? space(1) : null};
  }

  svg {
    fill: currentColor;
  }

  ${(props) => {
    if (props.variant === 'subtle') {
      if (props.disabled) {
        return `
          color: ${props.theme.color.primaryL60}
          text-transform: uppercase;

          svg {
            fill: ${props.isIcon ? props.theme.color.grayL60 : 'currentColor'};
          }
        `
      }

      return `
        background-color: transparent;
        color: ${props.theme.color.primary}
        text-transform: uppercase;

        &:hover {
          color: ${props.theme.color.primaryD20}
        }

        &:active {
          color: ${props.theme.color.primaryD60}
        }

        svg {
          fill: ${props.isIcon ? props.theme.color.gray : 'currentColor'};
        }
      `
    }

    if (props.variant === 'link') {
      return `
        height: auto;
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        color: ${props.appearance === 'primary' ? props.theme.color.primary : props.theme.color.gray};
        text-decoration: underline;

        &:hover {
          color: ${props.appearance === 'primary' ? props.theme.color.primaryD20 : props.theme.color.grayD20};
        }

        &:active {
          color: ${props.appearance === 'primary' ? props.theme.color.primaryD60 : props.theme.color.grayD60};
        }
      `
    }

    if (props.disabled) {
      switch (props.appearance) {
        case 'primary': {
          return `
            background-color: ${props.theme.color.grayL70};
            color: ${props.theme.color.white};
          `
        }
        case 'secondary': {
          return `
            background-color: ${props.theme.color.white};
            border-color: ${props.theme.color.grayL70};
            color: ${props.theme.color.grayL40};
          `
        }
        case 'positive': {
          return `
            background-color: ${props.theme.color.successL60};
            color: ${props.theme.color.white};
          `
        }
        case 'negative': {
          return `
            background-color: ${props.theme.color.errorL60};
            color: ${props.theme.color.white};
          `
        }
      }
    }

    switch (props.appearance) {
      case 'primary': {
        return `
          background-color: ${props.theme.color.primary};
          color: ${props.theme.color.white};

          &:hover {
            background-color: ${props.theme.color.primaryD20};
          }

          &:active {
            background-color: ${props.theme.color.primaryD60};
          }
        `
      }
      case 'secondary': {
        return `
          background-color: ${props.theme.color.white};
          border-color: ${props.theme.color.grayL70};
          color: ${props.theme.color.gray};

          &:hover {
            border-color: ${props.theme.color.grayL60};
          }

          &:active {
            border-color: ${props.theme.color.grayL40};
            background-color: ${props.theme.color.grayL90};
          }
        `
      }
      case 'positive': {
        return `
          background-color: ${props.theme.color.success};
          color: ${props.theme.color.white};

          &:hover {
            background-color: ${props.theme.color.successD20};
          }

          &:active {
            background-color: ${props.theme.color.successD60};
          }
        `
      }
      case 'negative': {
        return `
          background-color: ${props.theme.color.error};
          color: ${props.theme.color.white};

          &:hover {
            background-color: ${props.theme.color.errorD20};
          }

          &:active {
            background-color: ${props.theme.color.errorD60};
          }
        `
      }
      default: {
        return ''
      }
    }
  }};

  ${(props) => {
    if (props.variant === 'default' && props.isLoading) {
      return `
        background-color: ${props.theme.color.white};
        border-color: ${props.theme.color.grayL80};
        cursor: not-allowed;
      `
    }
  }};
`

/**
 * Button components are used as triggers for actions in situations such as forms, toolbars and modals.
 *
 * A button should definitely be used for triggering asynchronous actions, as opposed to `Link`.
 *
 * Buttons support `:hover`, `:active`, `:focus` and `disabled` states through visual styles.
 *
 * The button comes with three variants, and they do not all allow the same props to be passed. These are restricted by its [TypeScript prop types](https://github.com/ArtProcessors/mos-apps/blob/master/admin-web/frontend/src/components/ui/button/index.tsx).
 *
 * These variant prop values are outlined below:
 *
 * ## `default` variant
 *
 * The sole purpose of the default button should never be to change routes. Some action should occur which the user anticipated.
 *
 * The default variant has two _special case_ `appearance` prop values: "**positive**" and "**negative**". These can be used to aid the user in understanding the type of major action they are able to perform by using the button. Because these only provide a different background colour the prop alone is not enough to indicate this to the user and appropriate copy should always be used within the button to specify context.
 *
 * ### `isSmall` prop
 *
 * An `isSmall` prop has only been made available to support legacy situations where views have not yet been uplifted to the MOS Design Language System.
 *
 * ## `subtle` variant
 *
 * This is an alternate style for buttons, generally used when initiating a new sequence of interactions the user will be guided through. This can be specified using the prop `variant="subtle"`.
 *
 * The subtle variant has a `Link` companion component with similar styles, although the button has a taller height so it can sit next to other form elements at 42px. These styles must be kept in sync with each other.
 *
 * ### `isSmall` prop
 *
 * A subtle variant of button with an icon within it can use the `isSmall` prop to reduce it's padding. This can be useful for situations where there is not as much available space, such as placing it within a form field compound component.
 *
 * ## `link` variant
 *
 * There may be cases where UI design will request a button to visually appear as a link, in a situation where it is not triggering a route change (for example the `MediaField` component). In these edge cases we can use the link variant: `variant="link"`.
 *
 * Opt to not use this variant in those cases because it is confusing for users expectation of the UI. Use of this variant is a worst case scenario and accumulates UI debt.
 *
 * This variant does not maintain the same height as all other `Button` component variants. It's visual compatibility when used within a view is specific to the `Link` component, by technical design.
 *
 * ## Icons
 *
 * When using icons within button components they should use `size="medium"`, unless they are the subtle variant which uses `size="base"`.
 */
// this is only exported to extract props table for storybook. you should use `Button`.
// FIXME: props table not rendering with components using forwardRef(): https://github.com/storybookjs/storybook/issues/8336</div>
export const ButtonComponent = (props: Props, ref: React.Ref<any>) => {
  const { appearance, children, isDisabled, isIcon, variant } = props
  const isLoading = props.variant == 'default' && props.isLoading
  return (
    <ButtonElement
      ref={ref}
      variant={variant || 'default'}
      appearance={appearance || 'primary'}
      isIcon={isIcon || false}
      {...props}
      // always set button to disabled when it `isLoading`.
      disabled={isLoading || isDisabled}
    >
      {isLoading ? (<Loader />) : children}
    </ButtonElement>
  )
}

ButtonComponent.defaultProps = {
  variant: 'default' as const,
  appearance: 'primary' as const,
  isIcon: false,
  isSmall: false,
}

export const Button = React.forwardRef<any, Props>(ButtonComponent)

type NavButtonProps = Omit<Props, 'children'>

const NavButton = styled(Button)<NavButtonProps>`
  text-decoration: none;
  border: 0;
  padding: 0px;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
  margin-left: 5px;

  &:hover {
    color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
  }
  
  ${solidCtaFocus}

  &:focus {
    &::after {
      left: -5px;
      width: calc(100% + 20px);
    }
  }

  .${ICON_CONTAINER_CLASSNAME} {
    margin-right: 0px;
  }
`

export const NavButtonBack = (props: NavButtonProps) => (
  <NavButton {...props} variant="link" darkTheme={props.darkTheme}>
    <IconAngle rotate={90} size="base" color={props.darkTheme ? "grayL60" : "gray"} />
      Back
  </NavButton>
)
