import React from 'react'

import { Container, Content, Wrap, ItemIndex, DragHandle } from 'components/ui/draggable'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu'
import { Description, Image, Title } from 'components/ui/list-item-label'
import { IconRoom } from 'components/ui/icons'

import { space } from 'helpers/style'

import styled from 'styled'

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-weight: ${({ theme }) => theme.font.weight.base};
  color: ${({ theme }) => theme.color.grayL40};
`

const IconText = styled.span<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? space(45) : space(10))};
  margin-left: ${space(2)};
  font-size: ${({ theme }) => theme.font.size.small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`

type Props = {
  readonly historyPush: (id: string) => void
  readonly projectId: string
  readonly tourId: string
  readonly soundEffectZoneId: string
  readonly draggableIndex?: number
  readonly isDragging?: boolean
  readonly title: string
  readonly deleteHandler: () => void
  readonly locationDisplayText: string
}

export const SoundItem: React.FC<Props> = ({
  draggableIndex,
  historyPush,
  isDragging,
  projectId,
  tourId,
  soundEffectZoneId,
  title,
  deleteHandler,
  locationDisplayText,
}) => {
  const editLocation = `/project/${projectId}/tour/${tourId}/sound-effect-zone/${soundEffectZoneId}`
  return (
    <Container
      isDragging={isDragging}
      onClick={() => {
        historyPush(editLocation)
      }}
    >
      <Wrap isDragging={isDragging}>
        <Content>
          {draggableIndex !== undefined && (
            <>
              <DragHandle />
              <ItemIndex>{draggableIndex + 1}</ItemIndex>
            </>
          )}
          <Image src="" />
          <Description>
            <Title>{title}</Title>
          </Description>
        </Content>
        <IconsContainer>
          <IconBlock>
            <IconRoom color="grayL40" size="small" />
            <IconText wide>{locationDisplayText}</IconText>
          </IconBlock>
        </IconsContainer>
        <PopOverMenu iconSize="base">
          <PopOverMenuButton
            onClick={(event) => {
              historyPush(editLocation)
              event.stopPropagation()
            }}
          >
            Edit
          </PopOverMenuButton>
          <PopOverMenuButton
            onClick={(event) => {
              event.stopPropagation()
              deleteHandler()
            }}
          >
            Delete
          </PopOverMenuButton>
        </PopOverMenu>
      </Wrap>
    </Container>
  )
}
