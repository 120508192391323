/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconEdit = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M4 16.667V20h3.333l9.83-9.83-3.333-3.333L4 16.667zm15.74-9.074a.885.885 0 000-1.253l-2.08-2.08a.885.885 0 00-1.253 0l-1.626 1.626 3.333 3.333 1.626-1.626z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconEditString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M4 16.667V20h3.333l9.83-9.83-3.333-3.333L4 16.667zm15.74-9.074a.885.885 0 000-1.253l-2.08-2.08a.885.885 0 00-1.253 0l-1.626 1.626 3.333 3.333 1.626-1.626z"  fillRule="nonzero" />
  </svg>`

IconEdit.displayName = 'IconEdit'
