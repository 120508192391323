import { ActionType, ActionsType } from 'containers/core';
import { BuildingLevel, Building, AdminMap } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import { Polygon2D, Point2D } from "helpers/geo";
import { StructuresAppState, StructuresMessage } from '../store';

export const actionCreators = {
  buildingLevelEditReset: () =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditReset' as const }),
  buildingLevelCreateReset: () =>
    ({ domain: 'structures' as const, type: 'buildingLevelCreateReset' as const }),

  buildingLevelCreateSaveRequest: (payload: {
    buildingRef: Building.Ref;
    name: string;
    shortName: string;
    zOrder: number;
  }) => ({ domain: 'structures' as const, type: 'buildingLevelCreateSaveRequest' as const, payload }),
  buildingLevelCreateSaveFailure: (message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingLevelCreateSaveFailure' as const, message }),
  buildingLevelCreateSaveSuccess: (ref: BuildingLevel.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingLevelCreateSaveSuccess' as const, ref }),

  buildingLevelEditLoadRequest: (ref: BuildingLevel.Ref) => ({
    domain: 'structures' as const,
    type: 'buildingLevelEditLoadRequest' as const,
    ref,
  }),
  buildingLevelEditLoadFailure: (ref: BuildingLevel.Ref, message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditLoadFailure' as const, ref, message }),
  buildingLevelEditLoadSuccess: (ref: BuildingLevel.Ref, entity: BuildingLevel.Entity, adminMapEntity: AdminMap.Entity | null) => ({
    domain: 'structures' as const,
    type: 'buildingLevelEditLoadSuccess' as const,
    ref,
    entity,
    adminMapEntity,
  }),

  buildingLevelEditSaveRequest: (ref: BuildingLevel.Ref, payload: {
    buildingRef: Building.Ref;
    name: string;
    shortName: string;
    zOrder: number;
    boundary?: Polygon2D;
    adminMapCreate?: {
      imageUrl: string;
      bottomLeftGeoReference: Point2D;
      topRightGeoReference: Point2D;
    };
    adminMapUpdate?: {
      ref: AdminMap.Ref; // if none is provided, an admin map is created
      bottomLeftGeoReference: Point2D;
      topRightGeoReference: Point2D;
    };
    adminMapDeleteRef?: AdminMap.Ref;
  }) => ({ domain: 'structures' as const, type: 'buildingLevelEditSaveRequest' as const, ref, payload }),
  buildingLevelEditSaveFailure: (ref: BuildingLevel.Ref, message: StructuresMessage) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditSaveFailure' as const, ref, message }),
  buildingLevelEditSaveSuccess: (ref: BuildingLevel.Ref) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditSaveSuccess' as const, ref }),

  buildingLevelEditDeleteRequest: (args: { onSuccess?: () => void }) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditDeleteRequest' as const, ...args }),
  buildingLevelEditFailure: (ref: BuildingLevel.Ref, message: string) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditFailure' as const, ref, message }),
  buildingLevelEditUpdate: (value: StructuresAppState['buildingLevelEdit']) =>
    ({ domain: 'structures' as const, type: 'buildingLevelEditUpdate' as const, value }),
};


export type ActionCreators = typeof actionCreators;
export type BuildingLevelAction = ActionType<ActionCreators>;
export type BuildingLevelActions = ActionsType<ActionCreators>;

