/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconBundlePublic = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M12 4C4 4 1 12 1 12s3 8 11 8 11-8 11-8-3-8-11-8zm0 2c5.28 0 7.94 4.27 8.81 6-.87 1.71-3.56 6-8.81 6s-7.94-4.27-8.81-6c.87-1.71 3.55-6 8.81-6zm0 2a4 4 0 104 4 4 4 0 00-4-4zm0 2a2 2 0 11-2 2 2 2 0 012-2z" />
  </Icon>
)

// for use with mapbox addables.
export const IconBundlePublicString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M12 4C4 4 1 12 1 12s3 8 11 8 11-8 11-8-3-8-11-8zm0 2c5.28 0 7.94 4.27 8.81 6-.87 1.71-3.56 6-8.81 6s-7.94-4.27-8.81-6c.87-1.71 3.55-6 8.81-6zm0 2a4 4 0 104 4 4 4 0 00-4-4zm0 2a2 2 0 11-2 2 2 2 0 012-2z" />
  </svg>`

IconBundlePublic.displayName = 'IconBundlePublic'
