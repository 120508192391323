import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'

import {
  GetSoundEffectZoneResponse,
  UpdateSoundEffectZoneRequest,
  UpdateSoundEffectZoneResponse,
  DeleteSoundEffectZoneResponse,
} from '../types'
import { soundEffectZonesActionCreators } from '../actions/sound-effect-zones'

const getSoundEffectZoneSaga = wamTakeLeading('wamGetSoundEffectZoneRequest', function* (action) {
  try {
    const response: GetSoundEffectZoneResponse =
      yield*
      httpRest<undefined, GetSoundEffectZoneResponse>(
        `guided-tours/${action.payload.ecmsTourId}/sound-effect-zones/${action.payload.ecmsEffectSlug}`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getSoundEffectZoneSaga ] Missing response payload')
    }
    yield put(soundEffectZonesActionCreators.wamGetSoundEffectZoneSuccess(response))
  } catch (error) {
    yield put(soundEffectZonesActionCreators.wamGetSoundEffectZoneFailure(error.message))
  }
})

const updateSoundEffectZoneSaga = wamTakeLeading('wamUpdateSoundEffectZoneRequest', function* (action) {
  try {
    const { ecmsTourId, ecmsEffectSlug, ...data } = action.payload
    const response: UpdateSoundEffectZoneResponse =
      yield*
      httpRest<Omit<UpdateSoundEffectZoneRequest, 'ecmsTourId' | 'ecmsEffectSlug'>, UpdateSoundEffectZoneResponse>(
        `guided-tours/${ecmsTourId}/sound-effect-zones/${ecmsEffectSlug}`,
        data,
        'PUT',
      )

    if (!response) {
      throw new Invariant('[ updateSoundEffectZoneSaga ] Missing response payload')
    }
    yield put(soundEffectZonesActionCreators.wamUpdateSoundEffectZoneSuccess(response))

  } catch (error) {
    yield put(soundEffectZonesActionCreators.wamUpdateSoundEffectZoneFailure(error.message))
  }
})

const deleteSoundEffectZoneSaga = wamTakeLeading('wamDeleteSoundEffectZoneRequest', function* (action) {
  try {
    const response: DeleteSoundEffectZoneResponse =
      yield*
      httpRest<undefined, DeleteSoundEffectZoneResponse>(
        `guided-tours/${action.payload.ecmsTourId}/sound-effect-zones/${action.payload.ecmsEffectSlug}`,
        undefined,
        'DELETE',
      )

    if (!response) {
      throw new Invariant('[ deleteSoundEffectZoneSaga ] Missing response payload')
    }
    yield put(soundEffectZonesActionCreators.wamDeleteSoundEffectZoneSuccess(response))
    yield put(soundEffectZonesActionCreators.wamDeleteSoundEffectZoneReset())


  } catch (error) {
    yield put(soundEffectZonesActionCreators.wamDeleteSoundEffectZoneFailure(error.message))
  }
})

export const soundEffectZonesSagas: ReadonlyArray<Effect> = [
  getSoundEffectZoneSaga,
  updateSoundEffectZoneSaga,
  deleteSoundEffectZoneSaga,
]
