import React from 'react'

import { Button, ButtonGroup } from 'components/ui/button'
import { Confirm } from 'components/ui/modal'
import styled from 'styled'
import { UserMenu } from './user-menu'
import { IconAngle } from '../icons'
import { NavLinkItem, HeaderWrapper, Row } from '.'
import { NavButtonBack } from '../button'
import { HelpScout } from './helpscout'

const Wrapper = styled(HeaderWrapper)<{darkTheme?: boolean}>`
  // FIXME: unset justification while locate edit buttons are required.
  justify-content: unset;
  background-color: ${({ darkTheme, theme }) => darkTheme ? theme.color.primaryD80 : theme.color.white};
`

const Title = styled.h2<{darkTheme?: boolean}>`
  // FIXME: margins to align title while locate edit buttons are required.
  margin: auto;
  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.white : theme.color.grayD80};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const NavReturnLinkItem = styled(({darkTheme, ...rest}) => <NavLinkItem {...rest} />)<{darkTheme?: boolean}>`
  color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
  margin-left: 5px;

  &:hover {
    color: ${({ darkTheme, theme }) => darkTheme ? theme.color.grayL60 : theme.color.gray};
  }

  &:focus {
    &::after {
      left: -5px;
      width: calc(100% + 20px);
    }
  }
`;

type Props = {
  readonly title: string;
  readonly darkTheme?: boolean;
  readonly onSave?: () => void;
  readonly onCancel?: () => void;
  readonly onConfirm?: () => void;
  readonly disabled?: boolean;
  readonly saveDisabled?: boolean;
  readonly modified?: boolean;
  readonly navBackRoute?: string;
};

type State = {
  readonly showConfirmReturnModal?: boolean;
};

export class PrimaryNavigationEditEntity extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = {}
  }

  private confirmReturnToList() {
    this.setState({ showConfirmReturnModal: true })
  }

  public render() {
    const { showConfirmReturnModal } = this.state;
    const { disabled, modified, onCancel, onSave, saveDisabled, onConfirm, title, darkTheme, navBackRoute } = this.props;

    return (
      <>
        {!showConfirmReturnModal || onConfirm === undefined ?
          null : (
            <Confirm
              onClose={() => this.setState({ showConfirmReturnModal: false }) }
              title="Unsaved Changes"
              message="Do you want to discard your changes?"
              confirmLabel="Yes, discard"
              closeLabel="Cancel"
              onConfirm={() => onConfirm()}
          />
        )}

        <Wrapper darkTheme={!!darkTheme}>
          <Row>
            { onConfirm === undefined && !!navBackRoute ?
              <NavReturnLinkItem to={navBackRoute} darkTheme={!!darkTheme}>
                <IconAngle rotate={90} size="base" color={darkTheme ? 'grayL60' : 'gray'} />
                  Back
              </NavReturnLinkItem>
                :
              <NavButtonBack onClick={() => this.confirmReturnToList()} isDisabled={disabled} darkTheme={!!darkTheme} />
            }
          </Row>
          <Title darkTheme={!!darkTheme}>{title}</Title>

          {/** // FIXME: edit buttons are currently required for locate views. */}
          <ButtonGroup>
            {onCancel !== undefined ? (
              <Button
                appearance="secondary"
                onClick={() => this.confirmReturnToList()}
                isDisabled={disabled}
              >
                Cancel
              </Button>
            ) : undefined}
            {onSave !== undefined ? (
              <Button
                onClick={() => onSave()}
                isDisabled={disabled || saveDisabled}
              >
                Save
              </Button>
            ) : undefined}
            <HelpScout darkTheme={!!darkTheme} />
          </ButtonGroup>
          <div style={{ marginLeft: 8 }}>
            <UserMenu darkTheme={!!darkTheme} />
          </div>
        </Wrapper>
      </>
    )
  }
}
