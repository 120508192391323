/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconSpace = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M0 9.602l10.733 5.584.517.332v6.607L0 16.5V9.602zm24 .233V16.5l-11.25 5.625v-6.607l.389-.25 10.86-5.433zM12.75 1.407L24 7.5v.563l-5.856 2.947-5.394-3.085V1.407zm-1.5 0v6.518l-5.272 3.016L0 7.809V7.5l11.25-6.093z"  fillRule="evenodd" />
  </Icon>
)

// for use with mapbox addables.
export const IconSpaceString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M0 9.602l10.733 5.584.517.332v6.607L0 16.5V9.602zm24 .233V16.5l-11.25 5.625v-6.607l.389-.25 10.86-5.433zM12.75 1.407L24 7.5v.563l-5.856 2.947-5.394-3.085V1.407zm-1.5 0v6.518l-5.272 3.016L0 7.809V7.5l11.25-6.093z"  fillRule="evenodd" />
  </svg>`

IconSpace.displayName = 'IconSpace'
