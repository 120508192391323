import { RefUrn, refToUrn } from 'entity';
import { PlacedPolygon } from 'generated/mos/geo';
import { Site } from 'generated/mos/structure';

export { structuresSelector } from './selectors';
import { StructureRef } from './entities';

export interface StructureFilterState {
  readonly expanded: Record<RefUrn, boolean>;
  readonly lastEdited?: StructureRef;
}

