/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMove = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M12 2l3.4 3.4h-2.15l-.001 5.35H18.6V8.6L22 12l-3.4 3.4v-2.15l-5.351-.001.001 5.351h2.15L12 22l-3.4-3.4h2.15l-.001-5.351-5.349.001v2.15L2 12l3.4-3.4v2.15h5.349l.001-5.35H8.6z" />
  </Icon>
)

// for use with mapbox addables.
export const IconMoveString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M12 2l3.4 3.4h-2.15l-.001 5.35H18.6V8.6L22 12l-3.4 3.4v-2.15l-5.351-.001.001 5.351h2.15L12 22l-3.4-3.4h2.15l-.001-5.351-5.349.001v2.15L2 12l3.4-3.4v2.15h5.349l.001-5.35H8.6z" />
  </svg>`

IconMove.displayName = 'IconMove'
