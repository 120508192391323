import { Site, AdminMap } from 'generated/mos/structure';
import { StructuresMessage } from '../store';
import { Polygon2D, Point2D } from "helpers/geo";

export const actionCreators = {
  siteCreateReset: () =>
    ({ domain: 'structures' as const, type: 'siteCreateReset' as const }),

  siteCreateSaveRequest: (args: { name: string }) =>
    ({ domain: 'structures' as const, type: 'siteCreateSaveRequest' as const, ...args }),

  siteCreateSaveFailure: (args: { message: StructuresMessage }) =>
    ({ domain: 'structures' as const, type: 'siteCreateSaveFailure' as const, ...args }),

  siteCreateSaveSuccess: (args: { ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteCreateSaveSuccess' as const, ...args }),

  siteEditReset: () =>
    ({ domain: 'structures' as const, type: 'siteEditReset' as const }),

  siteEditLoadRequest: (args: { ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditLoadRequest' as const, ...args }),

  siteEditLoadFailure: (args: { message: StructuresMessage; ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditLoadFailure' as const, ...args }),

  siteEditLoadSuccess: (args: { ref: Site.Ref; entity: Site.Entity; adminMapEntity: AdminMap.Entity | null }) =>
    ({ domain: 'structures' as const, type: 'siteEditLoadSuccess' as const, ...args }),

  siteEditSaveRequest: (args: {
    ref: Site.Ref;
    name: string;
    boundary: Polygon2D | undefined;
    adminMapCreate?: {
      imageUrl: string;
      bottomLeftGeoReference: Point2D;
      topRightGeoReference: Point2D;
    };
    adminMapUpdate?: {
      ref: AdminMap.Ref; // if none is provided, an admin map is created
      bottomLeftGeoReference: Point2D;
      topRightGeoReference: Point2D;
    };
    adminMapDelete?: AdminMap.Ref;
  }) =>
    ({ domain: 'structures' as const, type: 'siteEditSaveRequest' as const, ...args }),

  siteEditSaveFailure: (args: { message: StructuresMessage; ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditSaveFailure' as const, ...args }),

  siteEditSaveSuccess: (args: { ref: Site.Ref; entity: Site.Entity }) =>
    ({ domain: 'structures' as const, type: 'siteEditSaveSuccess' as const, ...args }),

  siteEditDeleteRequest: (args: { ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditDeleteRequest' as const, ...args }),

  siteEditDeleteFailure: (args: { message: StructuresMessage; ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditDeleteFailure' as const, ...args }),

  siteEditDeleteSuccess: (args: { ref: Site.Ref }) =>
    ({ domain: 'structures' as const, type: 'siteEditDeleteSuccess' as const, ...args }),
};
