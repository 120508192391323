import { Effect, takeLeading, takeLatest, takeEvery } from 'redux-saga/effects'

import { WamActions } from '../actions'
import { toursSagas } from './tours'
import { uploadSagas } from './upload'
import { atmosphericZonesSagas } from './atmospheric-zones'
import { soundEffectZonesSagas } from './sound-effect-zones'
import { stopsSagas } from './stops'
import { publishingSagas } from './publishing'
import { holdingAudioSagas } from './holding-audio'

export function wamTakeLeading<TActionKey extends keyof WamActions>(
  pattern: TActionKey,
  worker: (action: WamActions[TActionKey]) => any,
) {
  return takeLeading(pattern, worker)
}

export function wamTakeLatest<TActionKey extends keyof WamActions>(
  pattern: TActionKey,
  worker: (action: WamActions[TActionKey]) => any,
) {
  return takeLatest(pattern, worker)
}

export function wamTakeEvery<TActionKey extends keyof WamActions>(
  pattern: TActionKey,
  worker: (action: WamActions[TActionKey]) => any,
) {
  return takeEvery(pattern, worker)
}

export const wamRootSaga: ReadonlyArray<Effect> = [
  ...toursSagas,
  ...uploadSagas,
  ...atmosphericZonesSagas,
  ...soundEffectZonesSagas,
  ...stopsSagas,
  ...publishingSagas,
  ...holdingAudioSagas,
]
