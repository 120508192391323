// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, ensureScalar, ensureScalarOptional } from "generated/webrpc";

// We can refer to an _attribute_ on an Entity in MOS using an AttributeRef. It's
// a combination of a typename and id (like a Ref) _plus_ an "attribute_name", which
// should map one-to-one with the name of a field in a proto
export namespace AttributeRef {
  export const refName = "mos.entity.AttributeRef" as const;
  export type Entity = {
    readonly type: typeof refName,
    // typename and id should match the semantics of Ref
    readonly typename: string,
    readonly id: string,
    // attribute_name should line up 1-to-1 with field names on proto messages.
    readonly attributeName: string,
  }
  export const defaults: Entity = {
    type: refName,
    typename: "",
    id: "",
    attributeName: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.entity.AttributeRef");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["typename"] = ensureScalar(ctx, v["typename"], "string");
      out["id"] = ensureScalar(ctx, v["id"], "string");
      out["attributeName"] = ensureScalar(ctx, v["attributeName"], "string");
      return out as any as Entity;
    }
  }();
}

// Represents a file checksum - note the method is specified to allow us to change it later on or to suuport
// multiple different mechansisms dependent on use-case.
export namespace Checksum {
  export const refName = "mos.entity.Checksum" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly method: ChecksumMethod,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    method: "CHECKSUM_METHOD_UNSPECIFIED",
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.entity.Checksum");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        const ev = ensureScalar(ctx, v["method"], "string") || defaults["method"];
        if (!ChecksumMethodValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.entity.ChecksumMethod`);
        }
        out["method"] = ev as any;
      }
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

export const ChecksumMethodValues = new Set([
  'CHECKSUM_METHOD_UNSPECIFIED' as const,
  'CHECKSUM_METHOD_SHA1' as const,
  'CHECKSUM_METHOD_SHA256' as const,
]);
export type ChecksumMethod = typeof ChecksumMethodValues extends Set<infer U> ? U : never;

// Occassionally we will have the need to provide users of MOS with a dynamic clickable action
// The `HyperLink` entity covers this use case combining the flexibility of a URL with the display
// text that should be shown to the user.
// 
export namespace HyperLink {
  export const refName = "mos.entity.HyperLink" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly url: string,
    readonly displayText: string,
  }
  export const defaults: Entity = {
    type: refName,
    url: "",
    displayText: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.entity.HyperLink");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["url"] = ensureScalar(ctx, v["url"], "string");
      out["displayText"] = ensureScalar(ctx, v["displayText"], "string");
      return out as any as Entity;
    }
  }();
}

// Entities MAY have metadata attached. See Ref for more details about Entity
// requirements.
// 
export namespace Metadata {
  export const refName = "mos.entity.Metadata" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly createdAt: Timestamp | undefined,
    readonly createdBy: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
    readonly modifiedAt: Timestamp | undefined,
    readonly modifiedBy: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    createdAt: undefined,
    createdBy: undefined,
    modifiedAt: undefined,
    modifiedBy: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["createdAt"] = v.createdAt ? v.createdAt.value : "";
      obj["createdBy"] = v.createdBy;
      obj["modifiedAt"] = v.modifiedAt ? v.modifiedAt.value : "";
      obj["modifiedBy"] = v.modifiedBy;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.entity.Metadata");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        const tsv = ensureScalarOptional(ctx, v["createdAt"], "string");
        out["createdAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      out["createdBy"] = v["createdBy"];
      {
        const tsv = ensureScalarOptional(ctx, v["modifiedAt"], "string");
        out["modifiedAt"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
      }
      out["modifiedBy"] = v["modifiedBy"];
      return out as any as Entity;
    }
  }();
}

