import React, { ReactNode } from 'react'
import Dropzone from 'react-dropzone'

import { Button } from 'components/ui/button'
import { Modal } from 'components/ui/modal'
import { StatusGuard } from 'components/ui/status-guard'

import { DataStatus, Status } from 'helpers/status'

import {
  DropArea,
  DropAreaText,
  DropAreaTextSmall,
  ModalBody,
} from './styled'


type Props = {
  readonly status: DataStatus<any, any>;
  readonly onClose: () => void;
  readonly onDrop: (files: ReadonlyArray<File>) => void;
  readonly renderComplete: ReactNode
}

export const BulkUpload = ({ onClose, onDrop, status, renderComplete }: Props) => {
  return (
    <Modal
      onClose={onClose}
      header={<h3>{status.status !== Status.Ready ? 'Add assets' : 'Bulk Upload Summary'}</h3>}
    >
      {/* <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault()
          onSave()
        }}
      > */}
      <StatusGuard status={status} mode="form">
        <ModalBody>
          {status.status !== Status.Ready
            ? <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <DropArea {...getRootProps()}>
                  <DropAreaText>
                    Drag a file here
                        <DropAreaTextSmall>or</DropAreaTextSmall>
                  </DropAreaText>
                  <input {...getInputProps()} multiple />
                  <Button
                    onClick={(event) => {
                      event.preventDefault()
                    }}
                  >
                    Choose from device
                  </Button>
                </DropArea>
              )}
            </Dropzone>
            : renderComplete
          }
        </ModalBody>
      </StatusGuard>
      {/* </form> */}
    </Modal>
  )
}
