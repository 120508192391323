// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { APIKey, AccessToken, DeviceKind, DeviceKindValues, RefreshToken, RefreshTokenInfo } from "generated/mos/user";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureScalar, stripTypePropertyDeep } from "generated/webrpc";

export namespace AssignNewPasswordRequest {
  export const refName = "mos.user_authentication.AssignNewPasswordRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly userRef: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
    readonly password: string,
    // See ResetToken for documentation on how to obtain this token. ResetToken
    // is not compatible with AuthToken.
    readonly token: ResetToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    userRef: undefined,
    password: "",
    token: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AssignNewPasswordRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["userRef"] = v["userRef"];
      out["password"] = ensureScalar(ctx, v["password"], "string");
      out["token"] = ctx.decode(ResetToken.codec, v["token"], "token");
      return out as any as Entity;
    }
  }();
}

export namespace AssignNewPasswordResponse {
  export const refName = "mos.user_authentication.AssignNewPasswordResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AssignNewPasswordResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

export namespace AuthenticateAPIKeyUserRequest {
  export const refName = "mos.user_authentication.AuthenticateAPIKeyUserRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly apiKey: APIKey.Entity | undefined,
    // A profile for the device making the authentication request
    readonly deviceProfile: DeviceProfileInput.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    apiKey: undefined,
    deviceProfile: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AuthenticateAPIKeyUserRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["apiKey"] = ctx.decode(APIKey.codec, v["apiKey"], "apiKey");
      out["deviceProfile"] = ctx.decode(DeviceProfileInput.codec, v["deviceProfile"], "deviceProfile");
      return out as any as Entity;
    }
  }();
}

export namespace AuthenticateAPIKeyUserResponse {
  export const refName = "mos.user_authentication.AuthenticateAPIKeyUserResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly accessToken: AccessToken.Entity | undefined,
    readonly refreshToken: RefreshToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    accessToken: undefined,
    refreshToken: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AuthenticateAPIKeyUserResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["accessToken"] = ctx.decode(AccessToken.codec, v["accessToken"], "accessToken");
      out["refreshToken"] = ctx.decode(RefreshToken.codec, v["refreshToken"], "refreshToken");
      return out as any as Entity;
    }
  }();
}

export namespace AuthenticateEmailUserRequest {
  export const refName = "mos.user_authentication.AuthenticateEmailUserRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly email: string,
    readonly password: string,
    // A profile for the device making the authentication request
    readonly deviceProfile: DeviceProfileInput.Entity | undefined,
    readonly org: { readonly typename: 'mos.user.Org', readonly id: string } | undefined | string | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    email: "",
    password: "",
    deviceProfile: undefined,
    org: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["email"] = v.email;
      obj["password"] = v.password;
      obj["deviceProfile"] = DeviceProfileInput.codec.encode(v.deviceProfile);
      {
        const uv: any = v.org;
        if (uv !== undefined) {
          if (uv["typename"] !== undefined && uv["id"] !== undefined) {
            obj["orgRef"] = uv;
          }
          else if (typeof(uv) === "string") {
            obj["orgSlug"] = uv;
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.user_authentication.AuthenticateEmailUserRequest.org");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AuthenticateEmailUserRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["email"] = ensureScalar(ctx, v["email"], "string");
      out["password"] = ensureScalar(ctx, v["password"], "string");
      out["deviceProfile"] = ctx.decode(DeviceProfileInput.codec, v["deviceProfile"], "deviceProfile");
      {
        if (v["orgRef"] !== undefined) {
          out["org"] = v["orgRef"];
        }
        if (v["orgSlug"] !== undefined) {
          out["org"] = ensureScalar(ctx, v["orgSlug"], "string");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace AuthenticateEmailUserResponse {
  export const refName = "mos.user_authentication.AuthenticateEmailUserResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly accessToken: AccessToken.Entity | undefined,
    readonly refreshToken: RefreshToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    accessToken: undefined,
    refreshToken: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.AuthenticateEmailUserResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["accessToken"] = ctx.decode(AccessToken.codec, v["accessToken"], "accessToken");
      out["refreshToken"] = ctx.decode(RefreshToken.codec, v["refreshToken"], "refreshToken");
      return out as any as Entity;
    }
  }();
}

export namespace DeleteRefreshTokenRequest {
  export const refName = "mos.user_authentication.DeleteRefreshTokenRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly refreshTokenRef: { readonly typename: 'mos.user.RefreshToken', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    refreshTokenRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.DeleteRefreshTokenRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["refreshTokenRef"] = v["refreshTokenRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteRefreshTokenResponse {
  export const refName = "mos.user_authentication.DeleteRefreshTokenResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly refreshTokenRef: { readonly typename: 'mos.user.RefreshToken', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    refreshTokenRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.DeleteRefreshTokenResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["refreshTokenRef"] = v["refreshTokenRef"];
      return out as any as Entity;
    }
  }();
}

// See mos.user.DeviceProfile for field-level documentation.
export namespace DeviceProfileInput {
  export const refName = "mos.user_authentication.DeviceProfileInput" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly deviceId: string,
    readonly deviceName: string,
    readonly deviceModel: string,
    readonly appName: string,
    readonly userAgent: string,
    readonly deviceKind: DeviceKind,
  }
  export const defaults: Entity = {
    type: refName,
    deviceId: "",
    deviceName: "",
    deviceModel: "",
    appName: "",
    userAgent: "",
    deviceKind: "DEVICE_KIND_UNKNOWN",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.DeviceProfileInput");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["deviceId"] = ensureScalar(ctx, v["deviceId"], "string");
      out["deviceName"] = ensureScalar(ctx, v["deviceName"], "string");
      out["deviceModel"] = ensureScalar(ctx, v["deviceModel"], "string");
      out["appName"] = ensureScalar(ctx, v["appName"], "string");
      out["userAgent"] = ensureScalar(ctx, v["userAgent"], "string");
      {
        const ev = ensureScalar(ctx, v["deviceKind"], "string") || defaults["deviceKind"];
        if (!DeviceKindValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.user.DeviceKind`);
        }
        out["deviceKind"] = ev as any;
      }
      return out as any as Entity;
    }
  }();
}

export namespace GetAnonymousVisitorTokenRequest {
  export const refName = "mos.user_authentication.GetAnonymousVisitorTokenRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly orgRef: { readonly typename: 'mos.user.Org', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    orgRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.GetAnonymousVisitorTokenRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["orgRef"] = v["orgRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetAnonymousVisitorTokenResponse {
  export const refName = "mos.user_authentication.GetAnonymousVisitorTokenResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly accessToken: AccessToken.Entity | undefined,
    readonly refreshToken: RefreshToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    accessToken: undefined,
    refreshToken: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.GetAnonymousVisitorTokenResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["accessToken"] = ctx.decode(AccessToken.codec, v["accessToken"], "accessToken");
      out["refreshToken"] = ctx.decode(RefreshToken.codec, v["refreshToken"], "refreshToken");
      return out as any as Entity;
    }
  }();
}

export namespace ListRefreshTokensRequest {
  export const refName = "mos.user_authentication.ListRefreshTokensRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly userRef: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    userRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.ListRefreshTokensRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["userRef"] = v["userRef"];
      return out as any as Entity;
    }
  }();
}

export namespace ListRefreshTokensResponse {
  export const refName = "mos.user_authentication.ListRefreshTokensResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly refreshTokens: ReadonlyArray<RefreshTokenInfo.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    refreshTokens: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["refreshTokens"] = [];
      for (const item of v.refreshTokens) {
        obj["refreshTokens"].push(RefreshTokenInfo.codec.encode(item));
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.ListRefreshTokensResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["refreshTokens"] = decodeMessageRepeated(ctx, RefreshTokenInfo.codec, v["refreshTokens"], "refreshTokens");
      return out as any as Entity;
    }
  }();
}

export namespace RefreshAuthenticationTokenRequest {
  export const refName = "mos.user_authentication.RefreshAuthenticationTokenRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly refreshToken: RefreshToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    refreshToken: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.RefreshAuthenticationTokenRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["refreshToken"] = ctx.decode(RefreshToken.codec, v["refreshToken"], "refreshToken");
      return out as any as Entity;
    }
  }();
}

export namespace RefreshAuthenticationTokenResponse {
  export const refName = "mos.user_authentication.RefreshAuthenticationTokenResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly accessToken: AccessToken.Entity | undefined,
    readonly refreshToken: RefreshToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    accessToken: undefined,
    refreshToken: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.RefreshAuthenticationTokenResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["accessToken"] = ctx.decode(AccessToken.codec, v["accessToken"], "accessToken");
      out["refreshToken"] = ctx.decode(RefreshToken.codec, v["refreshToken"], "refreshToken");
      return out as any as Entity;
    }
  }();
}

export namespace RequestNewPasswordRequest {
  export const refName = "mos.user_authentication.RequestNewPasswordRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly orgRef: { readonly typename: 'mos.user.Org', readonly id: string } | undefined,
    readonly email: string,
  }
  export const defaults: Entity = {
    type: refName,
    orgRef: undefined,
    email: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.RequestNewPasswordRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["orgRef"] = v["orgRef"];
      out["email"] = ensureScalar(ctx, v["email"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace RequestNewPasswordResponse {
  export const refName = "mos.user_authentication.RequestNewPasswordResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.RequestNewPasswordResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

// ResetToken is created to allow a user to reset their password. It is
// a hash of the user's identity along with some user state that's sure to
// change after a password reset, so it will invalidate when it's used.
// 
// ResetToken is acquired from the emails sent by the CreateUser or
// SendUserCreateEmail RPCs in UserManagement.
// 
// ResetToken is not compatible with AuthToken.
// 
// If a ResetToken is not used, it will invalidate after a configurable
// time period has passed. This period is implementation defined.
// 
export namespace ResetToken {
  export const refName = "mos.user_authentication.ResetToken" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: string,
  }
  export const defaults: Entity = {
    type: refName,
    value: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.ResetToken");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["value"] = ensureScalar(ctx, v["value"], "string");
      return out as any as Entity;
    }
  }();
}

export namespace ValidateResetTokenRequest {
  export const refName = "mos.user_authentication.ValidateResetTokenRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly userRef: { readonly typename: 'mos.user.User', readonly id: string } | undefined,
    readonly token: ResetToken.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    userRef: undefined,
    token: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.ValidateResetTokenRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["userRef"] = v["userRef"];
      out["token"] = ctx.decode(ResetToken.codec, v["token"], "token");
      return out as any as Entity;
    }
  }();
}

export namespace ValidateResetTokenResponse {
  export const refName = "mos.user_authentication.ValidateResetTokenResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly valid: boolean,
  }
  export const defaults: Entity = {
    type: refName,
    valid: false,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.user_authentication.ValidateResetTokenResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["valid"] = ensureScalar(ctx, v["valid"], "boolean");
      return out as any as Entity;
    }
  }();
}

