/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconInfo = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M12 3a9 9 0 110 18 9 9 0 010-18zm.2 6.895a1.567 1.567 0 00-.325.164 43.38 43.38 0 00-1.4 1.048 2.72 2.72 0 00-.242.26l-.108.142v.243a.258.258 0 00.293.09l.051-.024.227-.096.226-.101.1-.041.106-.03a.122.122 0 01.167.112l-.004.034a3.95 3.95 0 01-.091.525c-.309 1.248-.622 2.495-.925 3.744-.113.453.09.841.514 1.034l.03.023h.668c.584-.303 1.103-.705 1.61-1.114.128-.106.247-.22.357-.344a.87.87 0 00.126-.194.205.205 0 00-.021-.236.193.193 0 00-.22-.064l-.2.077-.417.176-.11.04a.117.117 0 01-.128-.01.117.117 0 01-.045-.088l.004-.034c.022-.2.057-.398.103-.594l.885-3.608c.145-.586-.163-1.063-.758-1.183a.786.786 0 00-.473.05zm.639-3.047a1.265 1.265 0 10-.971 2.337 1.265 1.265 0 00.97-2.337z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconInfoString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M12 3a9 9 0 110 18 9 9 0 010-18zm.2 6.895a1.567 1.567 0 00-.325.164 43.38 43.38 0 00-1.4 1.048 2.72 2.72 0 00-.242.26l-.108.142v.243a.258.258 0 00.293.09l.051-.024.227-.096.226-.101.1-.041.106-.03a.122.122 0 01.167.112l-.004.034a3.95 3.95 0 01-.091.525c-.309 1.248-.622 2.495-.925 3.744-.113.453.09.841.514 1.034l.03.023h.668c.584-.303 1.103-.705 1.61-1.114.128-.106.247-.22.357-.344a.87.87 0 00.126-.194.205.205 0 00-.021-.236.193.193 0 00-.22-.064l-.2.077-.417.176-.11.04a.117.117 0 01-.128-.01.117.117 0 01-.045-.088l.004-.034c.022-.2.057-.398.103-.594l.885-3.608c.145-.586-.163-1.063-.758-1.183a.786.786 0 00-.473.05zm.639-3.047a1.265 1.265 0 10-.971 2.337 1.265 1.265 0 00.97-2.337z"  fillRule="nonzero" />
  </svg>`

IconInfo.displayName = 'IconInfo'
