import { channel, Channel } from 'redux-saga'
import { call, put, take, select } from 'redux-saga/effects'
import { DateTime } from 'luxon'

import { store } from 'containers/store'
import { actionCreators } from 'domains/account/actions'
import { Org, User } from 'generated/mos/user'
import { Invariant } from 'helpers/core'
import { Status } from 'helpers/status'

type RefreshClaims = {
  readonly exp: number; // Expiry time in seconds
}
type AccessClaims = {
  readonly exp: number; // Expiry time in seconds
  readonly id: string; // User UUID
  readonly name: string;
  readonly email: string;
  readonly org: string;
}

// AuthSession represents a logged-in user session. It is destroyed on logout.
export type AuthSession = {
  readonly access: string;
  readonly refresh: string;
  readonly accessClaims: AccessClaims;
  readonly refreshClaims: RefreshClaims;
  readonly user: AuthUser;
}
// AuthUser contains the user-related claims from the Access JWT.
export type AuthUser = {
  readonly name: string;
  readonly email: string;
  readonly org: Org.Ref;
  readonly ref: User.Ref;
}
// AuthDevice represents the browser being used. It should outlive AuthSession.
export type AuthDevice = {
  readonly id: string;
  readonly displayName: string;
}
export type AuthTokens = Pick<AuthSession, 'access' | 'refresh'>

export const ensureSessionChannel: Channel<any> = channel()

export function* ensureValidSession(): any {
  let session: AuthSession | undefined
  const { account } = yield select()
  const { tokenStatus } = account
  session = account.session

  if(!session) {
    throw new Error('No session data available.')
  }

  // is the current token expired?
  else if (authSessionSelector.accessExpired(session)) {
    // is there already a token refresh request in-flight?
    if (tokenStatus.status === Status.Updating) {
      // pause for the refresh, then allow channel to continue.
      yield take(ensureSessionChannel)
      yield put(ensureSessionChannel, {})

    // otherwise exchange the refresh token for a new token pair.
    } else {
      yield call(refreshToken)
    }

    const { account: updatedAccount } = yield select()
    return updatedAccount.session as AuthSession
  }

  // we already have a valid token.
  return session
}

export function* ensureActiveJWT() {
  const session = store.getState().account.session
  const hasExpired = session && authSessionSelector.accessExpired(session)

  if(!session || hasExpired) {
    yield call(refreshToken)
  }
}

function* refreshToken() {
  // request and wait for token refresh and storage update.
  yield put(actionCreators.accountRefreshAuthenticationTokenRequest())
  yield take('accountRefreshAuthenticationTokenSuccess')

  // allow channel to continue.
  yield take(ensureSessionChannel)
  yield put(ensureSessionChannel, {})
}

const authUserFromClaims = (claims: AccessClaims): AuthUser => {
  if (!claims.id || !claims.name || !claims.email || !claims.org) {
    throw new Error(`invalid claims`)
  }
  return {
    name: claims.name,
    email: claims.email,
    org: { typename: 'mos.user.Org' , id: claims.org },
    ref: { typename: 'mos.user.User' as const, id: claims.id },
  }
}

const parseClaims = (token: string): object => {
  // convert base 64 url to base 64
  const base64 = token
    .replace('-', '+')
    .replace('_', '/')
    .split('.')

  if (base64.length < 2) {
    throw new Error(`could not parse token: ${base64}`)
  }
  return JSON.parse(atob(base64[1]))
}

export const createAuthSession = ({ access, refresh }: AuthTokens): AuthSession => {
  const accessClaims = parseClaims(access) as AccessClaims
  const refreshClaims = parseClaims(refresh) as RefreshClaims
  return {
    access,
    refresh,
    accessClaims,
    refreshClaims,
    user: authUserFromClaims(accessClaims),
  }
}

export const authSessionSelector = new class {
  private offsetSeconds = 30
  // applies a negative buffer when comparing token expiry, used for proactively refreshing.
  private bufferExpirySeconds = (timeStamp: DateTime): DateTime => (
    timeStamp.minus({ seconds: this.offsetSeconds })
  )
  // returns the current utc datetime.
  private getCurrentTime = (): DateTime => (
    DateTime.utc()
  )
  // returns a datetime from a number of seconds since the epoch.
  private getTimeFromEpoch = (timeStamp: number): DateTime => (
    DateTime.fromSeconds(timeStamp).toUTC()
  )
  private accessExpiry = (session: AuthSession): DateTime => (
    this.bufferExpirySeconds(this.getTimeFromEpoch(session.accessClaims.exp))
  )
  private refreshExpiry = (session: AuthSession): DateTime => (
    this.bufferExpirySeconds(this.getTimeFromEpoch(session.refreshClaims.exp))
  )
  public accessExpired = (session: AuthSession): boolean => (
    this.getCurrentTime() >= this.accessExpiry(session)
  )
  public refreshExpired = (session: AuthSession): boolean => (
    this.getCurrentTime() >= this.refreshExpiry(session)
  )
}()
