import { Effect, put, call, delay, all } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading, wamTakeEvery } from '.'
import { publishingActionCreators } from '../actions/publishing'

import {
  ListReleasableDevicesResponse,
  PublishDevicesRequest,
  PublishDevicesResponse,
  ListReleasesResponse,
  SyncLabelResponse,
  ReleaseSummaryResponse,
} from '../types'

function* getReleasableDevices() {
  try {
    const response: ListReleasableDevicesResponse =
      yield*
      httpRest<undefined, ListReleasableDevicesResponse>(
        `/publish/devices`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getReleasableDevicesSaga ] Missing response payload')
    }
    yield put(publishingActionCreators.wamListReleasableDevicesSuccess(response))
  } catch (error) {
    yield put(publishingActionCreators.wamListReleasableDevicesFailure(error.message))
  }
}
const getReleasableDevicesSaga = wamTakeLeading('wamListReleasableDevicesRequest', getReleasableDevices)

const getReleasesSaga = wamTakeLeading('wamListReleasesRequest', function* (action: any) {
  const { payload: { page } } = action
  try {
    const response: ListReleasesResponse =
      yield*
      httpRest<undefined, ListReleasesResponse>(
        `/publish/releases/?page=${page}`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getReleasesSaga ] Missing response payload')
    }
    yield put(publishingActionCreators.wamListReleasesSuccess(response))
  } catch (error) {
    yield put(publishingActionCreators.wamListReleasesFailure(error.message))
  }
})

const releaseSummarySaga = wamTakeEvery('wamReleaseSummaryRequest', function* (action) {
  const { payload } = action
  try {
    const response: ReleaseSummaryResponse['summary'] =
      yield*
      httpRest<undefined, ReleaseSummaryResponse['summary']>(
        `/publish/releases/${payload.id}/summary`,
        undefined,
        'GET',
      )
    if (!response) {
      throw new Invariant('[ releaseSummarySaga ] Missing response payload')
    }
    yield put(publishingActionCreators.wamReleaseSummarySuccess({
      id: payload.id,
      summary: response
    }))
  } catch (error) {
    yield put(publishingActionCreators.wamReleaseSummaryFailure({ id: payload.id, error: error.message }))
  }
})


function* publishDevices(action: any) {
  const { payload } = action
  try {
    const response: PublishDevicesResponse =
      yield*
      httpRest<PublishDevicesRequest, PublishDevicesResponse>(
        `/publish`,
        payload,
        'POST',
      )

    if (!response) {
      throw new Invariant('[ publishDevicesSaga ] Missing response payload')
    }

    yield put(publishingActionCreators.wamPublishDevicesSuccess(response))
    yield put(publishingActionCreators.wamPublishDevicesReset())
  } catch (error) {
    yield put(publishingActionCreators.wamPublishDevicesFailure(error.message))
  }
}

const publishDevicesSaga = wamTakeLeading('wamPublishDevicesRequest', function* (action) {
  function* delayedReleasableDevices() {
    yield delay(100)
    yield call(getReleasableDevices)
  }
  yield all([
    call(publishDevices, action),
    call(delayedReleasableDevices),
  ])
})

const syncLabelSaga = wamTakeEvery('wamSyncLabelRequest', function* (action) {
  const { payload } = action
  try {
    const response: any =
      yield*
      httpRest<undefined, SyncLabelResponse>(
        `/sync/digital-labels/${payload.id}`,
        undefined,
        'POST',
      )
    if (!response) {
      throw new Invariant('[ syncLabelSaga ] Missing response payload')
    }
    yield put(publishingActionCreators.wamSyncLabelSuccess({
      id: payload.id
    }))
    yield delay(2000)
    yield put(publishingActionCreators.wamSyncLabelReset({
      id: payload.id
    }))
  } catch (error) {
    yield put(publishingActionCreators.wamSyncLabelFailure({ id: payload.id, error: error.message }))
  }
})

export const publishingSagas: ReadonlyArray<Effect> = [
  getReleasableDevicesSaga,
  publishDevicesSaga,
  releaseSummarySaga,
  getReleasesSaga,
  syncLabelSaga,
]
