/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconNavBack = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" />
  </Icon>
)

// for use with mapbox addables.
export const IconNavBackString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" />
  </svg>`

IconNavBack.displayName = 'IconNavBack'
