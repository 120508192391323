/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaTranscript = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-4 11H5v2h10v-2zm4-4H5v2h14v-2zm0-4H5v2h14V6z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaTranscriptString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-4 11H5v2h10v-2zm4-4H5v2h14v-2zm0-4H5v2h14V6z"  fillRule="nonzero" />
  </svg>`

IconMediaTranscript.displayName = 'IconMediaTranscript'
