import React from 'react'

import { connect } from 'containers/store';

import { AccountAppState } from 'domains/account/store'
import { AuthSession } from 'services';

import { LoggedInRoutes, NotLoggedInRoutes } from './route-config';

type ConnectedProps = Pick<AccountAppState, 'session'>

type Props = ConnectedProps;

class RoutesView extends React.PureComponent<Props, {}> {
  public render = () => {
    if (this.props.session) {
      return <LoggedInRoutes {...this.props} />;
    } else {
      return <NotLoggedInRoutes {...this.props} />;
    }
  }
}

export const Routes = connect<ConnectedProps, {}, {}>(
  (store) => ({
    session: store.account.session,
  }),
  undefined,
  undefined,
  { respondToRouteChanges: true },
)(RoutesView);
