import React from 'react'
import ReactDOM from 'react-dom';

import { ThemeColor } from 'styled/theme';
import { IconArrow, IconTick } from 'components/ui/icons';
import { space } from 'helpers/style'
import { Button, buttonReset } from 'components/ui/button'
import styled from 'styled';


const MenuWrapper = styled.div<{ alignRight?: boolean }>`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border: 1px solid ${({ theme }) => theme.color.grayL80};
  border-radius: 4px;
  box-shadow: 1px 2px 4px 0 rgba(194, 194, 194, 0.6);
  background-color: ${({ theme }) => theme.color.white};
  ${({ alignRight }) => alignRight ? 'right: 0;' : 'left: 0;' }
`;

const Background = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const DefaultButton = styled.button`
  ${buttonReset}
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  height: 42px;
  padding: ${`0 ${space(1)}`};
  background-color: transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-decoration: none;
  text-transform: uppercase;

  svg {
    fill: currentColor;
  }

  &:hover {
    color: ${({ theme }) => theme.color.gray};
    text-decoration: none;
  }

  &:active {
    color: ${({ theme }) => theme.color.gray};
  }
`

type Props = {
  buttonProps?: Parameters<typeof Button>[0];
  alignRight?: boolean;
  children: React.ReactNode;
  value: string;
};

export const SelectFilter = (props: Props) => {
  const portalContainer = document.getElementById('menu-root');
  if (!portalContainer) {
    throw Error('element with id "menu-root" not found');
  }
  const [visible, setVisible] = React.useState(false);

  const handleWrapperClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const el = event.target as HTMLElement;
    if (['A', 'BUTTON'].includes(el.tagName)) {
      // has clicked something inside wrapper so close menu
      setVisible(false);
    }
    // else clicked menu or whitespace, leave menu open
  };

  return (
    <div style={{ position: 'relative' }}>
      {props.buttonProps
        ? <Button {...props.buttonProps} onClick={() => setVisible(true)} />
        : <DefaultButton onClick={() => setVisible(true)}>{props.value}<IconArrow /></DefaultButton>
      }
      {visible && (
        <MenuWrapper alignRight={props.alignRight} onClick={handleWrapperClick}>
          {props.children}
        </MenuWrapper>
      )}
      {ReactDOM.createPortal(visible && (
        // needs to be in portal to escape any overflow: scroll elements
        <Background onClick={() => setVisible(false)} />
      ), portalContainer)}
    </div>
  );
}

export const SelectFilterItem = styled.button<{ disabled?: boolean; selected?: boolean }>`
  ${buttonReset}
  flex-direction: column;
  align-items: flex-start;
  padding: ${space(3)};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.base};
  text-align: left;
  min-width: 238px;
  height: auto;
  min-height: 44px;
  user-select: none;
  ${({ selected, theme }) => selected && `color: ${theme.color.black};`}
  ${({ disabled, theme }) => disabled && `
    color: ${theme.color.grayL60};
    cursor: not-allowed;
  `};
  &:hover {
    background-color: ${({ theme }) => theme.color.grayL90};
    transition: ${({ theme }) => theme.transition.base};
  }
  &:active {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const SelectFilterHeader = styled.h4<{ hasBackground?: boolean }>`
  display: block;
  ${({ hasBackground, theme }) => hasBackground && `background-color: ${theme.color.grayL90};`}
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: ${space(1)} ${space(3)};
  margin: ${space(2)} 0;
  text-transform: uppercase;
  pointer-events: none;
`;

export const SelectFilterSeparator = styled.hr`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL80};
  margin: 0;
`;

const DetailedText = styled.span`
  color: ${({ theme }) => theme.color.darkText};
  margin-left: ${space(2)};
`;
const DetailedSubtext = styled.span`
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  margin-top: 2px;
`;
const Square = styled.span<{ color: ThemeColor }>`
  width: 9px;
  height: 9px;
  background-color: ${({ theme, color }) => theme.color[color]};
  border-radius: 2px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const Selected = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 48px;
`

type SelectFilterItemDetailedProps = {
  icon: ThemeColor | React.ReactElement; // pass a color to use a square icon, or pass a custom icon component
  text: string;
  subtext?: string;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export const SelectFilterItemDetailed = (props: SelectFilterItemDetailedProps) => (
  <SelectFilterItem onClick={props.onClick}>
    <Container>
      <div>
        <IconContainer>
          {typeof props.icon === 'string'
            ? <Square color={props.icon} />
            : props.icon
          }
          <DetailedText>{props.text}</DetailedText>
        </IconContainer>
        {!!props.subtext && <DetailedSubtext>{props.subtext}</DetailedSubtext>}
      </div>
      <Selected>
        {props.selected && <IconTick />}
      </Selected>
    </Container>
  </SelectFilterItem>
)
