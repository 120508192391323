import { all, call, Effect, put, select, takeLeading } from 'redux-saga/effects'

import { refToUrn } from 'entity';
import { Site, Building, BuildingLevel, OutdoorLevel, AdminMap } from 'generated/mos/structure';
import { Invariant } from 'helpers/core';

import {
  CreateBuildingRequest,
  CreateBuildingResponse,
  CreateBuildingLevelRequest,
  CreateBuildingLevelResponse,
  UpdateBuildingLevelRequest,
  UpdateBuildingLevelResponse,
  DeleteBuildingLevelRequest,
  DeleteBuildingLevelResponse,
  DeleteBuildingRequest,
  DeleteBuildingResponse,
  DeleteSiteRequest,
  DeleteSiteResponse,
  CreateSiteRequest,
  CreateSiteResponse,
  UpdateSiteRequest,
  UpdateSiteResponse,
  UpdateBuildingRequest,
  UpdateBuildingResponse,
  CreateAdminMapRequest,
  CreateAdminMapResponse,
  UpdateAdminMapRequest,
  UpdateAdminMapResponse,
  DeleteAdminMapRequest,
  DeleteAdminMapResponse,
} from 'generated/mos/structuremanagement';
import {
  GetSiteRequest,
  GetSiteResponse,
  GetBuildingRequest,
  GetBuildingResponse,
  GetBuildingLevelRequest,
  GetBuildingLevelResponse,
  GetOutdoorLevelRequest,
  GetOutdoorLevelResponse,
  GetAdminMapRequest,
  GetAdminMapResponse,
  ListSitesRequest,
  ListSitesResponse,
  ListBuildingsRequest,
  ListBuildingsResponse,
  ListBuildingLevelsRequest,
  ListBuildingLevelsResponse,
  ListOutdoorLevelsRequest,
  ListOutdoorLevelsResponse,
  ListAdminMapsRequest,
  ListAdminMapsResponse,
} from 'generated/mos/structureaccess';

import { unaryGRPC } from 'services/unary-grpc';
import { Status } from 'helpers/status'

import { StructuresActions, structuresActionCreators } from './actions'
import { StructuresAppState } from './store';


// structuresTakeLeading wraps takeLeading, requiring that the action derive from a key of
// StructuresActions. It brings an additional level of type safety to the ActionPattern.
//
// It is not necessary to pass the type parameter for TActionKey - it will be inferred
// from the value for the 'pattern' arg.
const structuresTakeLeading = <TActionKey extends keyof StructuresActions>(
  pattern: TActionKey,
  worker: (action: StructuresActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
};


export const buildingLevelCreateSaveRequest = structuresTakeLeading(
  'buildingLevelCreateSaveRequest',
  function* (action: StructuresActions['buildingLevelCreateSaveRequest']) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/CreateBuildingLevel',
        {
          type: CreateBuildingLevelRequest.refName,
          buildingRef: action.payload.buildingRef,
          name: action.payload.name,
          shortName: action.payload.shortName,
          zOrder: action.payload.zOrder,
          boundaries: undefined,
        },
        CreateBuildingLevelRequest.codec,
        CreateBuildingLevelResponse.codec,
      );
      yield put(structuresActionCreators.buildingLevelCreateSaveSuccess(
        BuildingLevel.mustRef(resp.buildingLevel!.ref)));
    } catch (err) {
      yield put(structuresActionCreators.buildingLevelCreateSaveFailure(
        { code: err.message, text: err.message }));
    }
    yield put(structuresActionCreators.structuresInvalidate());
  }
);


export const buildingLevelEditLoadRequest = structuresTakeLeading(
  'buildingLevelEditLoadRequest',
  function* (action: StructuresActions['buildingLevelEditLoadRequest']) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetBuildingLevel',
        { type: GetBuildingLevelRequest.refName, buildingLevelRef: action.ref },
        GetBuildingLevelRequest.codec,
        GetBuildingLevelResponse.codec,
      );

      const adminMapRef = resp.buildingLevel!.adminMapRefs ? resp.buildingLevel!.adminMapRefs[0] : undefined;
      if (adminMapRef === undefined) {
        yield put(structuresActionCreators.buildingLevelEditLoadSuccess(
          BuildingLevel.mustRef(resp.buildingLevel!.ref), resp.buildingLevel!, null));
        return;
      }

      const adminMapResp: GetAdminMapResponse.Entity = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetAdminMap',
        { type: GetAdminMapRequest.refName, adminMapRef },
        GetAdminMapRequest.codec,
        GetAdminMapResponse.codec,
      );
      yield put(structuresActionCreators.buildingLevelEditLoadSuccess(
        BuildingLevel.mustRef(resp.buildingLevel!.ref), resp.buildingLevel!, adminMapResp.adminMap!));
    } catch (err) {
      yield put(structuresActionCreators.buildingLevelEditLoadFailure(action.ref, { code: err.message, text: err.message }));
    }
  }
);


export const buildingLevelEditSaveRequest = structuresTakeLeading(
  'buildingLevelEditSaveRequest',
  function* (action: StructuresActions['buildingLevelEditSaveRequest']) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/UpdateBuildingLevel',
        {
          type: "mos.structure_management.UpdateBuildingLevelRequest",
          buildingLevelRef: action.ref,
          updateMask: { type: 'google.protobuf.FieldMask', fields: ['name', 'shortName', 'zOrder', 'boundaries'] },
          name: action.payload.name,
          shortName: action.payload.shortName,
          zOrder: action.payload.zOrder,
          boundaries: action.payload.boundary
            ? { type: 'MultiPolygon', coordinates: [action.payload.boundary.coordinates] }
            : undefined,
        },
        UpdateBuildingLevelRequest.codec,
        UpdateBuildingLevelResponse.codec,
      );

      if (action.payload.adminMapDeleteRef) yield* deleteAdminMap(action.payload.adminMapDeleteRef);
      if (action.payload.adminMapCreate) yield* createAdminMap(resp.buildingLevel!.ref, action.payload.adminMapCreate);
      if (action.payload.adminMapUpdate) yield* updateAdminMap(action.payload.adminMapUpdate);
      yield put(structuresActionCreators.structuresInvalidate());

      yield put(structuresActionCreators.buildingLevelEditSaveSuccess(
        BuildingLevel.mustRef(resp.buildingLevel!.ref)));
    } catch (err) {
      yield put(structuresActionCreators.buildingLevelEditSaveFailure(action.ref, { code: err.message, text: err.message }));
    }
  }
);

export const buildingLevelEditDeleteRequest = structuresTakeLeading(
  "buildingLevelEditDeleteRequest",
  function* (action: StructuresActions["buildingLevelEditDeleteRequest"]) {
    const state: StructuresAppState = (yield select()).structures;
    const { buildingLevelEdit } = state;
    if (buildingLevelEdit.status !== Status.Updating) {
      throw new Invariant(`expected Updating, found ${buildingLevelEdit.status}`); // FIXME: 3.7, use core.ts 'assert'
    }
    if (!buildingLevelEdit.ref) {
      throw new Invariant("building level missing ref"); // FIXME: 3.7, use core.ts 'assert'
    }

    const ref = buildingLevelEdit.ref;
    try {
      yield* unaryGRPC(
        'mos.structure_management.StructureManagement/DeleteBuildingLevel',
        { type: "mos.structure_management.DeleteBuildingLevelRequest", buildingLevelRef: ref },
        DeleteBuildingLevelRequest.codec,
        DeleteBuildingLevelResponse.codec,
      );
      yield put(structuresActionCreators.buildingLevelEditUpdate({ status: Status.Idle }));
      yield put(structuresActionCreators.structuresInvalidate());
      if (action.onSuccess) { // FIXME: legacy callback
        action.onSuccess();
      }
    } catch (err) {
      yield put(structuresActionCreators.buildingLevelEditFailure(ref, err.message));
    }
  }
);

export const buildingCreateSaveRequest = structuresTakeLeading(
  "buildingCreateSaveRequest",
  function* (action: StructuresActions["buildingCreateSaveRequest"]) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/CreateBuilding',
        { type: CreateBuildingRequest.refName, siteRef: action.siteRef, name: action.name, boundaries: undefined },
        CreateBuildingRequest.codec,
        CreateBuildingResponse.codec,
      );
      yield put(structuresActionCreators.buildingCreateSaveSuccess(
        Building.mustRef(resp.building!.ref)));
      yield put(structuresActionCreators.structuresInvalidate());
    } catch (err) {
      yield put(structuresActionCreators.buildingCreateSaveFailure({ code: err.message, text: err.message }));
    }
  }
);


export const buildingEditLoadRequest = structuresTakeLeading(
  'buildingEditLoadRequest',
  function* (action: StructuresActions['buildingEditLoadRequest']) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetBuilding',
        { type: GetBuildingRequest.refName, buildingRef: action.ref },
        GetBuildingRequest.codec,
        GetBuildingResponse.codec,
      );
      yield put(structuresActionCreators.buildingEditLoadSuccess(
        Building.mustRef(resp.building!.ref), resp.building!));
    } catch (err) {
      yield put(structuresActionCreators.buildingEditLoadFailure(action.ref, { code: err.message, text: err.message }));
    }
  }
);


export const buildingEditSaveRequest = structuresTakeLeading(
  'buildingEditSaveRequest',
  function* (action: StructuresActions['buildingEditSaveRequest']) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/UpdateBuilding',
        {
          type: UpdateBuildingRequest.refName,
          updateMask: { type: 'google.protobuf.FieldMask', fields: ['name', 'boundaries'] },
          buildingRef: action.ref,
          name: action.payload.name,
          boundaries: action.payload.boundary
            ? { type: 'MultiPolygon', coordinates: [action.payload.boundary.coordinates] }
            : undefined,
        },
        UpdateBuildingRequest.codec,
        UpdateBuildingResponse.codec,
      );
      yield put(structuresActionCreators.buildingEditSaveSuccess(Building.mustRef(resp.building!.ref), resp.building!));

      yield put(structuresActionCreators.structuresInvalidate());
    } catch (err) {
      yield put(structuresActionCreators.buildingEditSaveFailure(
        action.ref, { code: err.message, text: err.message }));
    }
  }
);

export const buildingEditDeleteRequest = structuresTakeLeading(
  "buildingEditDeleteRequest",
  function* (action: StructuresActions["buildingEditDeleteRequest"]) {
    try {
      const resp = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/DeleteBuilding',
        { type: "mos.structure_management.DeleteBuildingRequest", buildingRef: action.ref },
        DeleteBuildingRequest.codec,
        DeleteBuildingResponse.codec,
      );
      yield put(structuresActionCreators.buildingEditDeleteSuccess(Building.mustRef(resp.ref)));
      yield put(structuresActionCreators.structuresInvalidate());
    } catch (err) {
      yield put(structuresActionCreators.buildingEditDeleteFailure(action.ref, err.message));
    }
  }
);


export const siteCreateSaveRequest = structuresTakeLeading(
  "siteCreateSaveRequest",
  function* (action: StructuresActions["siteCreateSaveRequest"]) {
    try {
      const entity = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/CreateSite',
        { type: CreateSiteRequest.refName, name: action.name, boundary: undefined },
        CreateSiteRequest.codec,
        CreateSiteResponse.codec,
      );
      const ref = Site.mustRef(entity.site!.ref); // FIXME: site should not be undefined in the generated code
      yield put(structuresActionCreators.siteCreateSaveSuccess({ ref }));
    } catch (err) {
      yield put(structuresActionCreators.siteCreateSaveFailure({ message: { code: err.message, text: err.message } }));
    }

    yield put(structuresActionCreators.structuresInvalidate());
  }
);


export const siteEditLoadRequest = structuresTakeLeading(
  'siteEditLoadRequest',
  function* (action: StructuresActions['siteEditLoadRequest']) {
    try {
      const siteResp: GetSiteResponse.Entity = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetSite',
        { type: GetSiteRequest.refName, siteRef: action.ref },
        GetSiteRequest.codec,
        GetSiteResponse.codec,
      );
      const siteEntity = siteResp.site!; // FIXME: this should not be undefined in the generated wrapper

      const levelResp: GetOutdoorLevelResponse.Entity = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetOutdoorLevel',
        { type: GetOutdoorLevelRequest.refName, outdoorLevelRef: OutdoorLevel.mustRef(siteEntity.outdoorLevelRef) },
        GetOutdoorLevelRequest.codec,
        GetOutdoorLevelResponse.codec,
      );

      const outdoorLevel = levelResp.outdoorLevel!; // FIXME: outdoorLevel should not be undefined in generated code
      if (outdoorLevel.adminMapRefs === undefined || !outdoorLevel.adminMapRefs[0]) {
        yield put(structuresActionCreators.siteEditLoadSuccess({
          ref: Site.mustRef(siteEntity.ref),
          entity: siteEntity,
          adminMapEntity: null,
        }));
        return;
      }

      const adminMapResp: GetAdminMapResponse.Entity = yield* unaryGRPC(
        'mos.structure_access.StructureAccess/GetAdminMap',
        { type: GetAdminMapRequest.refName, adminMapRef: outdoorLevel.adminMapRefs[0] },
        GetAdminMapRequest.codec,
        GetAdminMapResponse.codec,
      );

      yield put(structuresActionCreators.siteEditLoadSuccess({
        ref: Site.mustRef(siteEntity.ref),
        entity: siteEntity,
        adminMapEntity: adminMapResp.adminMap || null,
      }));
      return;
    } catch (err) {
      yield put(structuresActionCreators.siteEditLoadFailure({
        ref: action.ref,
        message: { code: err.message, text: err.message },
      }));
      return;
    }
  }
);

function* createAdminMap(
  levelRef: OutdoorLevel.Ref | BuildingLevel.Ref | undefined,
  adminMap: StructuresActions['siteEditSaveRequest']['adminMapCreate'],
) {
  if (!adminMap || levelRef === undefined) return;
  yield* unaryGRPC(
    'mos.structure_management.StructureManagement/CreateAdminMap',
    {
      type: CreateAdminMapRequest.refName,
      levelRef,
      imageUrl: adminMap.imageUrl,
      bottomLeftGeoReference: adminMap.bottomLeftGeoReference,
      topRightGeoReference: adminMap.topRightGeoReference,
    },
    CreateAdminMapRequest.codec,
    CreateAdminMapResponse.codec,
  );
}

function* deleteAdminMap(
  adminMapRef: StructuresActions['siteEditSaveRequest']['adminMapDelete'],
) {
  if (!adminMapRef) return;
  yield* unaryGRPC(
    'mos.structure_management.StructureManagement/DeleteAdminMap',
    {
      type: DeleteAdminMapRequest.refName,
      adminMapRef: adminMapRef,
    },
    DeleteAdminMapRequest.codec,
    DeleteAdminMapResponse.codec,
  );
}

function* updateAdminMap(
  adminMap: StructuresActions['siteEditSaveRequest']['adminMapUpdate'],
) {
  if (!adminMap) return;
  yield* unaryGRPC(
    'mos.structure_management.StructureManagement/UpdateAdminMap',
    {
      type: UpdateAdminMapRequest.refName,
      adminMapRef: adminMap.ref,
      updateMask: { type: 'google.protobuf.FieldMask', fields: ['bottomLeftGeoReference', 'topRightGeoReference'] },
      bottomLeftGeoReference: adminMap.bottomLeftGeoReference,
      topRightGeoReference: adminMap.topRightGeoReference,
    },
    UpdateAdminMapRequest.codec,
    UpdateSiteResponse.codec,
  );
}


export const siteEditSaveRequest = structuresTakeLeading(
  'siteEditSaveRequest',
  function* (action: StructuresActions['siteEditSaveRequest']) {
    try {
      const resp: UpdateSiteResponse.Entity = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/UpdateSite',
        {
          type: UpdateSiteRequest.refName,
          updateMask: { type: 'google.protobuf.FieldMask', fields: ['name', 'boundary'] },
          siteRef: action.ref,
          name: action.name,
          boundary: action.boundary,
        },
        UpdateSiteRequest.codec,
        UpdateSiteResponse.codec,
      );
      const siteEntity = resp.site!; // FIXME: this should not be undefined in the generated wrapper

      if (action.adminMapDelete) yield* deleteAdminMap(action.adminMapDelete);
      if (action.adminMapCreate) yield* createAdminMap(siteEntity.outdoorLevelRef, action.adminMapCreate);
      if (action.adminMapUpdate) yield* updateAdminMap(action.adminMapUpdate);

      yield put(structuresActionCreators.siteEditSaveSuccess(
        { ref: Site.mustRef(siteEntity.ref), entity: siteEntity }));

      yield put(structuresActionCreators.structuresInvalidate());
    } catch (err) {
      yield put(structuresActionCreators.siteEditSaveFailure({
        ref: action.ref,
        message: { code: err.message, text: err.message },
      }));
    }
  }
);

export const siteEditDeleteRequest = structuresTakeLeading(
  "siteEditDeleteRequest",
  function* (action: StructuresActions["siteEditDeleteRequest"]) {
    const ref = action.ref;
    try {
      const entity = yield* unaryGRPC(
        'mos.structure_management.StructureManagement/DeleteSite',
        { type: "mos.structure_management.DeleteSiteRequest", siteRef: ref },
        DeleteSiteRequest.codec,
        DeleteSiteResponse.codec,
      );
      yield put(structuresActionCreators.siteEditDeleteSuccess({ ref: action.ref }));
    } catch (err) {
      yield put(structuresActionCreators.siteEditDeleteFailure(
        { ref: action.ref, message: { code: err.message, text: err.message } }));
    }

    yield put(structuresActionCreators.structuresInvalidate());
  }
);

export const structuresLoadRequest = structuresTakeLeading(
  'structuresLoadRequest',
  function* (action: StructuresActions['structuresLoadRequest']) {
    try {
      const [
        sitesResp,
        buildingsResp,
        buildingLevelsResp,
        outdoorLevelsResp,
        adminMapsResp,
      ] = yield all([
        unaryGRPC(
          'mos.structure_access.StructureAccess/ListSites',
          { type: ListSitesRequest.refName },
          ListSitesRequest.codec,
          ListSitesResponse.codec,
        ),
        unaryGRPC(
          'mos.structure_access.StructureAccess/ListBuildings',
          { type: ListBuildingsRequest.refName },
          ListBuildingsRequest.codec,
          ListBuildingsResponse.codec,
        ),
        unaryGRPC(
          'mos.structure_access.StructureAccess/ListBuildingLevels',
          { type: ListBuildingLevelsRequest.refName },
          ListBuildingLevelsRequest.codec,
          ListBuildingLevelsResponse.codec,
        ),
        unaryGRPC(
          'mos.structure_access.StructureAccess/ListOutdoorLevels',
          { type: ListOutdoorLevelsRequest.refName },
          ListOutdoorLevelsRequest.codec,
          ListOutdoorLevelsResponse.codec,
        ),
        unaryGRPC(
          'mos.structure_access.StructureAccess/ListAdminMaps',
          { type: ListAdminMapsRequest.refName },
          ListAdminMapsRequest.codec,
          ListAdminMapsResponse.codec,
        ),
      ]);

      yield put(
        structuresActionCreators.structuresLoadSuccess({
          sites: sitesResp.sites,
          buildings: buildingsResp.buildings,
          buildingLevels: buildingLevelsResp.buildingLevels,
          outdoorLevels: outdoorLevelsResp.outdoorLevels,
          adminMaps: adminMapsResp.adminMaps,
        }),
      );
    } catch (err) {
      console.error(err);
      yield put(
        structuresActionCreators.structuresLoadFailure({
          code: err.message,
          text: err.message,
        }),
      );
    }
  },
);

export const structuresRootSaga: ReadonlyArray<Effect> = [
  buildingCreateSaveRequest,
  buildingEditLoadRequest,
  buildingEditSaveRequest,
  buildingEditDeleteRequest,
  buildingLevelCreateSaveRequest,
  buildingLevelEditLoadRequest,
  buildingLevelEditSaveRequest,
  buildingLevelEditDeleteRequest,
  siteCreateSaveRequest,
  siteEditLoadRequest,
  siteEditSaveRequest,
  siteEditDeleteRequest,
  structuresLoadRequest,
];
