import { Effect, put, takeLeading } from 'redux-saga/effects'

import {
  GetLinkedEntityMappingsRequest,
  GetLinkedEntityMappingsResponse,
  GetLinkedFieldMappingsRequest,
  GetLinkedFieldMappingsResponse,
} from 'generated/mos/linkedfieldmappings'
import { unaryGRPC } from 'services/unary-grpc'
import { failureStatusMessage } from 'helpers/status'

import { LinkableActions, actionCreators } from './actions'

const linkableTakeLeading = <TActionKey extends keyof LinkableActions>(
  pattern: TActionKey,
  worker: (action: LinkableActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
}

const getLinkedEntityMappings = linkableTakeLeading(
  'linkableGetLinkedEntityMappingsRequest',
  function* (action) {
    const { relationshipName, sourceTypename } = action
    try {
      const response: GetLinkedEntityMappingsResponse.Entity = yield* unaryGRPC(
        'mos.linked_field_mappings.LinkedFieldMappings/GetLinkedEntityMappings',
        {
          ...GetLinkedEntityMappingsRequest.defaults,
          relationshipName,
          sourceTypename,
        },
        GetLinkedEntityMappingsRequest.codec,
        GetLinkedEntityMappingsResponse.codec,
      )

      yield put(actionCreators.linkableGetLinkedEntityMappingsSuccess(sourceTypename, response))

    } catch (error) {
      yield put(actionCreators.linkableGetLinkedEntityMappingsFailure(
        sourceTypename,
        failureStatusMessage(error.message)
      ))
    }
  }
)

const getLinkedFieldMappings = linkableTakeLeading(
  'linkableGetLinkedFieldMappingsRequest',
  function* (action) {
    const { payload} = action
    try {
      const response: GetLinkedFieldMappingsResponse.Entity = yield* unaryGRPC(
        'mos.linked_field_mappings.LinkedFieldMappings/GetLinkedFieldMappings',
        payload,
        GetLinkedFieldMappingsRequest.codec,
        GetLinkedFieldMappingsResponse.codec,
      )

      yield put(actionCreators.linkableGetLinkedFieldMappingsSuccess(response))

    } catch (error) {
      yield put(actionCreators.linkableGetLinkedFieldMappingsFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

export const linkableRootSaga: ReadonlyArray<Effect> = [
  getLinkedEntityMappings,
  getLinkedFieldMappings,
]
