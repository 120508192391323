import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { StatusGuard } from 'components/ui/status-guard'
import { connect } from 'containers/store';
import { Org } from 'generated/mos/user';
import { AuthenticateEmailUserRequest } from 'generated/mos/userauthentication';
import { pick } from 'helpers/core';
import { isEmail } from 'helpers/form';
import { Status } from 'helpers/status';
import styled from 'styled'

import { accountBindableActionCreators } from 'domains/account/actions';
import { DataStatus } from 'domains/account/store';

const FormError = styled.div`
  display: block;
  color: ${({ theme }) => theme.color.error};
`

const WideButton = styled(Button)`
  width: 100%;
`

type DirectProps = {
  readonly orgSlug: string;
  readonly onSuccess: () => void;
};

type ActionProps = Pick<typeof accountBindableActionCreators, 'accountLoginRequest'>;

type ConnectedProps = {
  readonly loginStatus: DataStatus<undefined>;
};

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly email: string;
  readonly password: string;
};

export class SignInFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ email: '', password: '' }),
    };
  }

  private validate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public componentDidUpdate() {
    if (this.props.loginStatus.status === Status.Ready) {
      this.props.onSuccess()
    }
  }

  private onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const bag = validateFormBag(this.state.bag, this.validate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.accountLoginRequest({
      ...AuthenticateEmailUserRequest.defaults,
      org: this.props.orgSlug,
      email: bag.values.email,
      password: bag.values.password,
    });
  }

  public render() {
    const { bag } = this.state;
    const { touched, errors } = bag;

    const loading = this.props.loginStatus.status === Status.Updating;

    const submitErrors = this.props.loginStatus.status === Status.Failed ?
      [...new Set(this.props.loginStatus.messages.map((v) => v.text))] :
      [];

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <div style={{ minHeight: 52 }}>
          {!submitErrors ? null :
          <FormError>
            {submitErrors.map((v) => <p key={v}>{v}</p>)}
          </FormError>
          }
        </div>

        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.validate}>
          <StatusGuard status={this.props.loginStatus} mode="form">
            <Input
              label="Email"
              field="email"
              type="email"
              error={touched.email ? errors.email : undefined}
            />
            <Input
              label="Password"
              field="password"
              type="password"
              error={touched.password ? errors.password : undefined}
            />
          </StatusGuard>

          <WideButton type="submit" isDisabled={!bag.valid}>
            {loading ? 'Signing in...' : 'Sign in'}
          </WideButton>
        </FormData>
      </form>
    );
  }
}


export const SignInForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'loginStatus'),
  pick(accountBindableActionCreators, 'accountLoginRequest'),
)(SignInFormView);
