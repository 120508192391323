import { bffEndpoint } from 'helpers/bff';
import { assertNever } from 'helpers/core';
import { NotificationProps, Notification } from 'components/ui/toast-notifications';

// SharedAppState contains state parameters that can be accessed from all
// domains. This is a bit of a hack until we sort out domain dependencies
// properly.
export interface SharedAppState {
  // Should be set to window.location.hostname. Used to construct endpoints but
  // to prevent littering the code with references to the window global. This
  // may be futile.
  readonly hostname: string;
  readonly bffEndpoint: string;

  readonly toastNotifications: ReadonlyArray<ToastNotification>;
}

export const initialSharedAppState: SharedAppState = {
  // FIXME(bw): this is as far to the edge as I've been able to push these
  // globals so far, but it's still NQR.
  hostname: window.location.hostname,
  bffEndpoint: bffEndpoint(window.location.hostname),

  toastNotifications: [],
};

export type ToastNotification = {
  readonly expiryTime: number;
} & Notification

export const sharedActionCreators = {
  toastNotification: ({ durationMs,...message}: NotificationProps) =>
    ({ domain: 'shared' as const, type: 'toastNotification' as const, message: { ...message, expiryTime: Date.now() + (durationMs || 6000) } }),
  toastNotificationRemove: () =>
    ({ domain: 'shared' as const, type: 'toastNotificationRemove' as const }),
};

type SharedUpdateAction = ReturnType<typeof sharedActionCreators[keyof typeof sharedActionCreators]>;

export type SharedActionCreators = typeof sharedActionCreators;

export type SharedAction = SharedUpdateAction;

const assertShared = (domain: 'shared') => {
  return domain === 'shared'
}

export const sharedReducer = (state: SharedAppState = initialSharedAppState, action: SharedUpdateAction): SharedAppState => {
  if (!assertShared(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'toastNotificationRemove':
      return { ...state, toastNotifications: [...state.toastNotifications.filter((v) => v.expiryTime > Date.now())] };
    case 'toastNotification':
      return { ...state, toastNotifications: [...state.toastNotifications, action.message ] };

    default:
      assertNever(action);
      throw new Error();
  }
};
