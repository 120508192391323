import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { IconSpace } from 'components/ui/icons'
import { FlatListItem } from 'components/ui/list-item';
import { PopOverMenu, PopOverMenuLink } from 'components/ui/pop-over-menu';
import { refToUrn } from 'entity';
import { Space } from 'generated/mos/structure';
import { debounce } from 'helpers/core';

import { SpacesData } from './spaces-data';

const ITEM_HEIGHT_PX = 64;

type Props = {
  readonly active?: boolean;
  readonly items: ReadonlyArray<Space.Entity>;
  readonly onClick: (ref: Space.Ref) => void;
  readonly onMouseEnter: (ref: Space.Ref) => void;
  readonly onMouseLeave: (ref: Space.Ref) => void;
  readonly spacesData: SpacesData;

  // FIXME implement scroll to index based on selected ref
  readonly scrollToIndex?: number;
  readonly highlightRef: Space.Ref | undefined;
};

export class ItemList extends React.Component<Props, {}> {
  private rowRenderer = ({ index, style }: ListChildComponentProps) => {
    const { items, onClick, onMouseEnter, onMouseLeave } = this.props;

    const item = items[index];
    const ref = item.ref;
    if (!ref) {
      throw new Error();
    }

    const spaceType = item.spaceTypeRef ? this.props.spacesData.spaceTypes.index[refToUrn(item.spaceTypeRef)] : undefined;
    const spaceTypeName = spaceType ? spaceType.name : "";

    return (
      <div style={style}>
        <FlatListItem
          height={ITEM_HEIGHT_PX}
          icon={IconSpace}
          title={item.name}
          subtitle=""
          description={spaceTypeName}
          onClick={() => onClick(ref) }
          onMouseEnter={debounce(() => onMouseEnter(ref), 50)}
          onMouseLeave={debounce(() => onMouseLeave(ref), 50)}
          key={`list-item-${ref.id}`}
          active={!!this.props.active}
          metadata={item.metadata!}
          primaryAction={{ label: 'Edit', to: `/locate/spaces/edit/${ref.id}` }}
          renderMenu={(
            <PopOverMenu>
              <PopOverMenuLink to={`/locate/spaces/edit/${ref.id}`}>Edit space</PopOverMenuLink>
            </PopOverMenu>
          )}
        />
      </div>
    );
  }

  public render() {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={this.props.items.length}
            itemSize={ITEM_HEIGHT_PX}
          >
            {this.rowRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  }
}
