import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/forms'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu';
import { AttributeBlock } from 'components/ui/attribute-block';
import { PrimaryNavigationEditPosition } from 'components/ui/primary-navigation/edit-position'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { Mapbox, MapInitialBounds, MapView } from 'components/mapbox';
import { EditableBoundaryMapSet } from 'components/mapsets/editable-polygon';
import { NavigationControlSet } from 'components/mapsets/navigation-control';
import { RefCopy } from 'components/ui/ref-copy';
import { S3Uploader, SignResult } from 'components/s3-uploader';
import { AddAttributeButton } from 'components/ui/add-attribute-button';
import { Alert, ConfirmDelete } from 'components/ui/modal';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { Site, AdminMap } from 'generated/mos/structure';
import { assertNever, pick, shallowEqual } from 'helpers/core';
import * as geo from 'helpers/geo';
import { Status, statusSelector } from 'helpers/status';
import { space } from 'helpers/style'

import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresActionCreators } from 'domains/structures/actions';
import { LevelRef, buildAdminMapCorners } from 'domains/structures/entities';
import { AdminMapSet, BoundarySet } from 'domains/structures/mapsets';
import { AdminMapEditSet, Dimensions } from 'domains/structures/mapsets/admin-map-edit-set';
import { StructuresAppState } from 'domains/structures/store';

import styled from 'styled';

const Section = styled.div`
  padding: 0 ${space(6)};
`;

const SectionHeader = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  margin-top: ${space(9)};
  margin-bottom: ${space(9)};
  font-size: 20px;
`;

const Hr = styled.div`
  padding-top: ${space(2)};
  margin-bottom: ${space(8)};
  border-color: #e7e7e7;
`;

const LastButtonRow = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${space(6)};
`;

const NoBoundaryLabel = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayL80};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL80};
  padding: ${space(3)} ${space(4)};
`;


type DirectProps = {
  readonly siteId: string;
  readonly onComplete: () => void;
};

type ConnectedProps = Pick<StructuresAppState, 'siteEdit' | 'structures' | 'globalMap'>;

type ActionProps =
  Pick<typeof sharedActionCreators, 'toastNotification'> &
  Pick<typeof structuresActionCreators,
    'structuresLoadRequest' | 'structuresFiltersUpdate'> &
  Pick<typeof structuresActionCreators,
    'siteEditSaveRequest' | 'siteEditLoadRequest' | 'siteEditReset' | 'siteEditDeleteRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type EditMode = undefined | 'adminmap' | 'boundary' | 'adminmapImage';;

type State = {
  readonly siteEditReady?: boolean;
  readonly showConfirmDeleteModal?: boolean;
  readonly showBoundaryAlert?: boolean;
  readonly editMode: EditMode;
  readonly showDismissableErrorModal: boolean;
  readonly errorModalMessage: string;

  readonly boundary?: geo.Polygon2D;
  readonly boundaryLevel?: LevelRef;
  readonly boundaryPrevious?: geo.Polygon2D;

  readonly adminMapUrl?: string;
  readonly adminMapUrlPrevious?: string;
  readonly adminMapCornersPrevious?: geo.Corners;
  readonly adminMapCorners?: geo.Corners;
  readonly adminMapDimensions?: Dimensions;
  readonly adminMapUploading?: boolean;

  // The globalMap's view is only used for a default if the beacon is
  // unpositioned.
  readonly mapView?: MapView;

  // Replace this with a new '{}' to force mapbox to update to the initial bounds:
  readonly mapBoundsIdentity?: object;
  readonly mapBounds?: geo.BBox2D;

  readonly initialFormValues: FormValues;
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly name: string;
};

// XXX: this (or something much better than it) may belong as a core helper
const getImageDimensions = async (url: string): Promise<Dimensions> => {
  const img = new Image();
  return new Promise((resolve) => {
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = url;
  });
};

const deriveAdminMapState = (adminMap?: AdminMap.Entity) => {
  if (!adminMap) return {};
  const dimensions = {
    width: adminMap.imageWidth,
    height: adminMap.imageHeight,
  };
  return {
    adminMapUrl: adminMap.imageUrl,
    adminMapCorners: buildAdminMapCorners(adminMap),
    adminMapDimensions: dimensions.width && dimensions.height ? dimensions : undefined,
  };
}

class SiteEditPageView extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    const emptyFormValues: FormValues = { name: '' };
    this.state = {
      editMode: undefined,
      adminMapUrl: undefined,
      adminMapUrlPrevious: undefined,
      bag: createFormBag(emptyFormValues),
      initialFormValues: emptyFormValues,
      showDismissableErrorModal: false,
      errorModalMessage: '',
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    if (state.siteEditReady) return null;

    if (!statusSelector.hasData(props.siteEdit) ||
      !statusSelector.hasData(props.structures)) {
      return null;
    }

    const site = props.siteEdit.data.entity;
    if (!site) {
      throw new Error();
    }

    let mapView: MapView = props.globalMap.view;
    if (site.boundary) {
      mapView = {
        ...mapView,
        center: geo.centroid2D({
          type: 'Polygon',
          coordinates: site.boundary.coordinates,
        }),
      };
    }
    const initialFormValues: FormValues = { name: site.name };

    const adminMap = props.siteEdit.data.adminMapEntity;
    const newState: Partial<State> = {
      siteEditReady: true,
      ...deriveAdminMapState(adminMap || undefined),
      boundary: site.boundary,
      bag: createFormBag(initialFormValues),
      initialFormValues,
      mapView,
    };

    return newState;
  }

  private get ref(): Site.Ref {
    return { typename: 'mos.structure.Site', id: this.props.siteId };
  }

  private get name() { return this.state.bag.values.name; }

  private mapBounds(): MapInitialBounds | undefined {
    if (!this.state.mapBoundsIdentity || !this.state.mapBounds) {
      return;
    }
    return {
      padding: 120, // FIXME put in context
      identity: this.state.mapBoundsIdentity,
      bounds: this.state.mapBounds,
    };
  }

  public componentWillUnmount() {
    this.props.siteEditReset();
  }

  public componentDidMount() {
    this.props.siteEditLoadRequest({ ref: this.ref });
    this.refresh();
  }

  public componentDidUpdate() {
    this.refresh();
  }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private onConfirmDelete() {
    if (this.props.siteEdit.status !== Status.Ready) return;
    this.props.siteEditDeleteRequest({
      ref: this.props.siteEdit.ref,
    });
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  // {{{ Admin map handlers

  private onAdminMapPosEdit = () => {
    if (!this.state.adminMapUrl) {
      throw new Error();
    }
    this.setState({
      editMode: 'adminmap',
      adminMapCornersPrevious: this.state.adminMapCorners,
    });
  }

  private resolveAdminMap(complete: SignResult): void {
    this.setState({ adminMapUploading: true });

    // Strip all the AWS signing query parameters from the URL that we send to the backend:
    const url = new URL(complete.signedUrl);
    url.search = '';
    const imageUrl = url.toString();

    getImageDimensions(imageUrl)
      .then((dimensions) => {
        this.setState({
          editMode: 'adminmap',
          adminMapUploading: false,
          adminMapUrl: imageUrl,
          adminMapDimensions: dimensions,
        });
      });
  }

  private resolveReplacementAdminMap(complete: SignResult): void {
    this.setState({ adminMapUploading: true });

    // Strip all the AWS signing query parameters from the URL that we send to the backend:
    const url = new URL(complete.signedUrl);
    url.search = '';
    const imageUrl = url.toString();

    const adminMapUrlPrevious = this.state.adminMapUrl

    this.setState({
      ...this.state,
      adminMapUrlPrevious,
      editMode: 'adminmapImage',
      adminMapUploading: false,
      adminMapUrl: imageUrl,
    });
  }

  private onAdminMapPosCommit = () => {
    this.setState({ editMode: undefined });
  }

  private onAdminMapShow = () => {
    if (this.state.adminMapCorners) {
      this.setState({ mapBoundsIdentity: {}, mapBounds: geo.asBBox2D(this.state.adminMapCorners) });
    }
  }

  private onAdminMapCancel = () => {
    this.setState({
      editMode: undefined,
      adminMapCorners: geo.optionalCorners(this.state.adminMapCornersPrevious),
    });
  }

  private onAdminMapUpdateCancel = () => {
    this.setState({
      ...this.state,
      editMode: undefined,
      adminMapUrl: this.state.adminMapUrlPrevious,
      adminMapUrlPrevious: undefined,
    });
  }

  private onAdminMapPosUpdate = (e: { corners: geo.Corners }) => {
    if (!this.state.adminMapUrl) {
      throw new Error();
    }
    this.setState({ adminMapCorners: e.corners });
  }

  private onAdminMapRemove = () => {
    // TODO(dc): implement a delete saga. This is doesn't do anything anymore.
    this.setState({ adminMapUrl: undefined, adminMapCorners: undefined, editMode: undefined });
  }

  // }}}


  // {{{ Boundary handlers

  private onBoundaryShow = () => {
    if (this.state.boundary) {
      this.setState({ mapBoundsIdentity: {}, mapBounds: geo.bbox2D(this.state.boundary) });
    }
  }

  private onBoundaryAdd = () => {
    if (this.state.boundary) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: `'${this.name}' already has a boundary.` });
      return;
    }
    this.setState({ editMode: 'boundary' });
  }

  private onBoundaryEdit = () => this.setState({ editMode: 'boundary', boundaryPrevious: this.state.boundary });
  private onBoundaryCancel = () => this.setState({ editMode: undefined, boundary: this.state.boundaryPrevious });
  private onBoundaryPosCommit = () => this.setState({ editMode: undefined });;
  private onBoundaryRemove = () => this.setState({ boundary: undefined });

  private onBoundaryUpdate = (e: { polygon: geo.Polygon2D | undefined }) => {
    this.setState({ boundary: e.polygon });
  }

  // }}}


  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    return errors;
  }

  private save() {
    if (this.state.editMode) {
      throw new Error();
    }

    if (this.props.siteEdit.status !== Status.Ready) return;

    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    this.props.structuresFiltersUpdate({ lastEdited: this.ref });

    let adminMapCreate, adminMapUpdate;
    const data = statusSelector.data(this.props.siteEdit)

    if (data && data.adminMapEntity && this.state.adminMapUrl === data.adminMapEntity.imageUrl && data.adminMapEntity.ref) {
      adminMapUpdate = {
        ref: data.adminMapEntity.ref,
        bottomLeftGeoReference: {
          type: 'Point' as const,
          coordinates: this.state.adminMapCorners![3],
        },
        topRightGeoReference: {
          type: 'Point' as const,
          coordinates: this.state.adminMapCorners![1],
        },
      };
    } else if (this.state.adminMapUrl) {
      adminMapCreate = {
        imageUrl: this.state.adminMapUrl,
        bottomLeftGeoReference: {
          type: 'Point' as const,
          coordinates: this.state.adminMapCorners![3],
        },
        topRightGeoReference: {
          type: 'Point' as const,
          coordinates: this.state.adminMapCorners![1],
        },
      };
    }

    this.props.siteEditSaveRequest({
      name: this.name,
      boundary: this.state.boundary,
      adminMapCreate,
      adminMapUpdate,
      ref: this.props.siteEdit.ref,
      adminMapDelete: !this.state.adminMapUrl && data && data.adminMapEntity ? data.adminMapEntity.ref : undefined
    });
  }

  private onCancelEdit = () => {
    this.props.onComplete();
  }

  private modified = (): boolean => {
    if (!statusSelector.hasData(this.props.siteEdit) ||
      !statusSelector.hasData(this.props.structures)) {
      return false;
    }

    // check if form has changed
    if (!shallowEqual(this.state.bag.values, this.state.initialFormValues)) {
      return true;
    }

    // check if admin map or boundary has changed
    const site = this.props.siteEdit.data;
    const initialBoundary = site.entity.boundary;
    const initialAdminMapUrl = site.adminMapEntity ? site.adminMapEntity.imageUrl : undefined;
    const initialAdminMapCorners = site.adminMapEntity ? buildAdminMapCorners(site.adminMapEntity) : undefined;

    if (!geo.equal(initialBoundary, this.state.boundary) ||
      !geo.equal(initialAdminMapCorners, this.state.adminMapCorners) ||
      initialAdminMapUrl !== this.state.adminMapUrl) {
      return true;
    }

    return false;
  }

  public render() {
    if (this.props.siteEdit.status === Status.Failed) {
      // FIXME: el-cheapo error display, StatusGuard?
      return this.props.siteEdit.messages.map((v) => v.text).join(', ');
    }

    if (this.props.siteEdit.status === Status.Complete) {
      this.props.toastNotification({ type: 'success', text: this.props.siteEdit.message });
      this.props.onComplete();
    }

    const { errors, touched } = this.state.bag;

    const modified = this.modified()

    const commitDisabled = !!this.state.editMode ||
      this.props.siteEdit.status === Status.Updating;

    const sidebar = () => ((this.props.siteEdit.status !== Status.Updating && this.props.siteEdit.status !== Status.Ready) ? null :
      <React.Fragment>
        <div>
          <Section>
            <SectionHeader>Details</SectionHeader>

            <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
              <Input
                label="Site name"
                field="name"
                error={touched.name ? errors.name : undefined}
              />
            </FormData>
          </Section>

          <Section style={{ paddingBottom: 60 }}>
            <Label>Admin map overlay</Label>
            {!this.state.adminMapUrl
              ? <S3Uploader
                htmlId="admin-map-upload"
                label="Upload image"
                disabled={this.state.adminMapUploading || !!this.state.editMode}
                onComplete={(complete) => this.resolveAdminMap(complete)}
              />
              : <>
                <AttributeBlock title={this.state.adminMapUrl.substring(this.state.adminMapUrl.lastIndexOf('/') + 1)}>
                  <PopOverMenu>
                    <PopOverMenuButton onClick={this.onAdminMapPosEdit}>Edit alignment</PopOverMenuButton>
                    <PopOverMenuButton onClick={this.onAdminMapShow}>Centre on map</PopOverMenuButton>
                  </PopOverMenu>
                </AttributeBlock>
                <S3Uploader
                  htmlId="admin-map-upload"
                  label="Replace admin map overlay"
                  disabled={this.state.adminMapUploading || !!this.state.editMode}
                  onComplete={(complete) => this.resolveReplacementAdminMap(complete)}
                />
              </>
            }
          </Section>

          <Section>
            <Hr />

            <SectionHeader>Attributes</SectionHeader>
            <AddAttributeButton title="Boundary" onClick={this.onBoundaryAdd} />
            {!this.state.boundary
              ? <NoBoundaryLabel>No boundary set</NoBoundaryLabel>
              : <AttributeBlock title="Site Boundary">
                <PopOverMenu>
                  <PopOverMenuButton onClick={this.onBoundaryEdit}>Edit boundary</PopOverMenuButton>
                  <PopOverMenuButton onClick={this.onBoundaryShow}>Centre on map</PopOverMenuButton>
                </PopOverMenu>
              </AttributeBlock>
            }
          </Section>
        </div>

        <LastButtonRow>
          <Button
            isDisabled={commitDisabled}
            onClick={() => this.setState({ showConfirmDeleteModal: true })}
          >
            Delete site
        </Button>
          <RefCopy modelRef={this.ref} />
        </LastButtonRow>
      </React.Fragment>
    );

    let nav: () => React.ReactNode;
    if (this.state.editMode === 'adminmap') {
      nav = () => (
        <PrimaryNavigationEditPosition
          title="Editing map position"
          kind="overlay"
          onCommit={this.onAdminMapPosCommit}
          onCancel={this.onAdminMapCancel}
          onDelete={this.onAdminMapRemove}
        />
      );
    } else if (this.state.editMode === 'adminmapImage') {
      nav = () => (
        <PrimaryNavigationEditPosition
          title="Replace admin map and position"
          kind="overlay"
          onCommit={this.onAdminMapPosCommit}
          onCancel={this.onAdminMapUpdateCancel}
          onDelete={this.onAdminMapRemove}
        />
      );
    } else if (this.state.editMode === 'boundary') {
      nav = () => (
        <PrimaryNavigationEditPosition
          title="Edit site boundary"
          kind="boundary"
          onCommit={this.onBoundaryPosCommit}
          onCancel={this.onBoundaryCancel}
          onDelete={this.onBoundaryRemove}
        />
      );

    } else if (!this.state.editMode) {
      nav = () => (
        <PrimaryNavigationEditEntity
          title="Edit Site"
          saveDisabled={commitDisabled}
          onSave={() => this.save()}
          disabled={!modified}
          onCancel={this.onCancelEdit}
          onConfirm={modified ? this.onCancelEdit : undefined}
          navBackRoute={!modified ? '/locate/structures' : undefined}
        />
      );
    } else {
      assertNever(this.state.editMode); // Exhaustiveness check
      throw new Error(); // TypeScript requires this
    }

    let boundaryEditSet: EditableBoundaryMapSet | undefined;
    let boundarySet: BoundarySet | undefined;

    if (this.state.editMode === 'boundary') {
      boundaryEditSet = new EditableBoundaryMapSet({
        key: 'activeStructure',
        boundary: this.state.boundary,
        onUpdated: this.onBoundaryUpdate,
      });

    } else if (this.state.boundary) {
      boundarySet = new BoundarySet({ key: 'structhover', boundaries: [this.state.boundary] });
    }

    const adminMapViewSet = this.state.editMode !== 'adminmap' && this.state.adminMapUrl && this.state.adminMapCorners
      ? new AdminMapSet('adminmap', this.state.adminMapUrl, this.state.adminMapCorners)
      : undefined;

    const adminMapEditSet = (this.state.editMode === 'adminmap' || this.state.editMode === 'adminmapImage') && this.state.adminMapUrl && this.state.adminMapDimensions
      ? new AdminMapEditSet({
        key: 'adminmapedit',
        imageUrl: this.state.adminMapUrl,
        corners: this.state.adminMapCorners,
        dimensions: this.state.adminMapDimensions,
        onUpdated: this.onAdminMapPosUpdate,
      })
      : undefined;

    const mapSets = [adminMapViewSet, boundarySet, boundaryEditSet, adminMapEditSet, new NavigationControlSet({ showCompass: false })];

    return (
      <div style={{ height: '100vh' }}>
        {!this.state.showConfirmDeleteModal ? null :
          <ConfirmDelete
            title={`Delete '${this.name}'?`}
            message="Deleting a building will in turn delete all associated buildings, levels, spaces, beacons, and properties"
            checklist={['All buildings, levels, and level properties will be deleted', 'All beacons and spaces will be unpositioned', 'This cannot be undone.']}
            confirmLabel="Yes, delete this site"
            onConfirm={() => this.onConfirmDelete()}
            onClose={() => this.setState({ showConfirmDeleteModal: false })}
          />
        }

        {!this.state.showBoundaryAlert ? null :
          <Alert
            onClose={() => this.setState({ showBoundaryAlert: false })}
            message="You can only add one boundary. Please remove or edit the existing boundary."
          />
        }

        {!this.state.showDismissableErrorModal ? null :
          <Alert
            onClose={() => this.setState({ showDismissableErrorModal: false })}
            message={this.state.errorModalMessage} />
        }

        <LayoutLocateEditPage
          nav={nav}
          sidebar={sidebar}
          content={() => (
            <Mapbox
              initialBounds={this.mapBounds()}
              view={this.state.mapView!}
              onViewChanged={(e) => this.setState({ mapView: e })}
              mapSets={mapSets}
            />
          )}
        />
      </div>
    );
  }
}

export const SiteEditPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.structures, 'siteEdit', 'globalMap', 'structures'),
  {
    ...pick(
      structuresActionCreators,
      'structuresLoadRequest', 'structuresFiltersUpdate',
    ),
    ...pick(sharedActionCreators, 'toastNotification'),
    ...pick(structuresActionCreators,
      'siteEditLoadRequest', 'siteEditReset', 'siteEditDeleteRequest',
      'siteEditSaveRequest',
    ),
  },
)(SiteEditPageView);
