const bffEndpointOverride: string = `${process.env.REACT_APP_API_DOMAIN || window.location.origin}/mos`;

let bffEndpointCached: string = '';

// bffEndpoint implements a hacky way to derive the BFF hostname from the
// frontend's current host.
//
// This is used to support the automatic reconfiguration of frontends for
// different orgs that are deployed to different hostnames. See
// https://github.com/ArtProcessors/mos-rfcs/blob/master/rfcs/0002-platform-domains/README.md
//
// If REACT_APP_HTTP_GRPC_DOMAIN is set, it is used in preference to any hostname
// munging.
//
// @param hostname This should come from window.location.hostname
export function bffEndpoint(hostname: string): string {
  if (bffEndpointCached) {
    return bffEndpointCached;
  }

  let endpoint: string = '';
  if (bffEndpointOverride) {
    endpoint = bffEndpointOverride;
  } else {
    endpoint = `//web-admin-bff.${hostname}`;
  }

  bffEndpointCached = endpoint;
  return endpoint;
}

// https://github.com/grpc/grpc/blob/master/doc/statuscodes.md
export enum StatusCode {
  OK = 0,
  Cancelled = 1,
  Unknown = 2,
  InvalidArgument = 3,
  DeadlineExceeded = 4,
  NotFound = 5,
  AlreadyExists = 6,
  PermissionDenied = 7,
  Unauthenticated = 16,
  ResourceExhausted = 8,
  FailedPrecondition = 9,
  Aborted = 10,
  OutOfRange = 11,
  Unimplemented = 12,
  Internal = 13,
  Unavailable = 14,
  DataLoss = 15,
}

// DO NOT use directly, use codeLookup(). There are some weird issues with
// TypeScript, Babel and globals.
let _codeIndex: Map<number, StatusCode>;

function codeLookup(): ReadonlyMap<number, StatusCode> {
  if (!_codeIndex) {
    _codeIndex = new Map();
    for (const item in StatusCode) {
      if (!isNaN(parseInt(item, 10))) { // Idiotic double-check to satisfy linter
        const num = parseInt(item, 10);
        _codeIndex.set(num, num as StatusCode);
      }
    }
  }
  return _codeIndex;
}

export function asStatusCode(v: number, defaultCode?: StatusCode): StatusCode {
  if (defaultCode === undefined) {
    defaultCode = StatusCode.Internal;
  }

  const code = codeLookup().get(v);
  return code !== undefined ? code : defaultCode;
}

type Status = Readonly<{
  status: StatusCode;
  statusDesc: string;
  message: string;
  details: ReadonlyArray<Message>;
}>;

// This is based on the Error message in mosx.errors:
// https://github.com/ArtProcessors/mos-proto/blob/master/proto/mosx/errors.proto#L11
//
// The BFF will return exactly what the Backend emits, if it is appropriate to
// do so.
type Message = Readonly<{
  codeScope: string;
  code: number;
  debugName: string;
  paths: ReadonlyArray<string>;
  parameters: { [key: string]: string };
}>;
