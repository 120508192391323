import React, { useState } from 'react'
import { Field } from 'react-formage'

import { IconTick } from 'components/ui/icons'
import { space } from 'helpers/style'
import styled from 'styled'

type Props<TKey> = {
  readonly label: string;
  /**
   * This maps to the property name in the `formage` form bag.
   */
  readonly field: TKey;
  /**
   * Optional copy which sits to the right of the label in a subtle colour.
   */
  readonly labelSupportingText?: string;
  /**
   * Displays below the form element describing it's purpose.
   */
  readonly description?: string;
  readonly isDestructive?: boolean;
  /**
   * Used for styling purposes only.
   */
  readonly isAssociated?: boolean;
  readonly isDisabled?: boolean;
  /**
   * A validation error.
   */
  readonly error?: string;
  /**
   * A warning that there is a publishing issue.
   */
  readonly warning?: string;
}

const FieldWrapper = styled.div<{ isAssociated?: boolean }>`
  margin-bottom: ${({ isAssociated }) => isAssociated ? space(4) : space(6)};
  ${({ isAssociated, theme }) => !isAssociated ? null : `
    padding-left: ${space(4)};
    border-left: 3px solid ${theme.color.grayL70};
  `}
`

const Label = styled.label<{ isDisabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.color.grayL40 : theme.color.grayD60};
  cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const LabelSupport = styled.span`
  padding-left: ${space(1)};
  color: ${({ theme }) => theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const CheckboxHidden = styled.input`
  /**
   * hide checkbox visually but remain accessible to screen readers.
   * source: https://polished.js.org/docs/#hidevisually
  */
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

type CheckboxProps = {
  readonly hasError?: boolean;
  readonly isChecked: boolean;
  readonly isDestructive?: boolean;
  readonly isDisabled?: boolean;
  readonly isFocussed?: boolean;
}

const CheckboxStyled = styled.div<CheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: ${space(2)};
  margin-left: 0;
  cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ isDisabled }) => isDisabled ? 0.7 : 1};
  border-style: solid;
  border-width: 2px;
  border-radius: 2px;
  ${({ isChecked, isDestructive, theme }) => {
    if (isDestructive) {
      return `
        background-color: ${isChecked ? theme.color.error : 'transparent'};
        border-color: ${isChecked ? theme.color.error : theme.color.errorL60};
      `
    }
    return `
      background-color: ${isChecked ? theme.color.primary : 'transparent'};
      border-color: ${isChecked ? theme.color.primary : theme.color.grayL60};
    `
  }};

  ${({ isFocussed, theme }) => isFocussed ? `
    position: relative;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      display: block;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border: 1px dotted ${theme.color.primary};
      border-radius: 4px;
    }
  ` : null}
`

const Description = styled.small<{ isDisabled?: boolean }>`
  display: block;
  color: ${({ theme, isDisabled }) => isDisabled ? theme.color.grayL40 : theme.color.gray};
  line-height: ${space(6)};
`

const Error = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.error};
  line-height: ${space(6)};
`

const Warning = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.warning};
  line-height: ${space(6)};
`

const FormageField = styled(Field) <{ render: (fieldProps: any) => any }>``

/**
 * The only props which are mandatory are the `label` and the `field`. The field value is a string representing the name of the value the element should control in the formage formbag.
 *
 * An optional `labelSupportText` prop can be used to provide supporting text for the fields label, and should be visually more subtle than the label copy itself.
 *
 * The `isAssociated` prop is used to change the visual style of the element when it relates to another field above it, for example alternate text, credits etc.
 *
 * ## Accessibility
 *
 * The field is wrapped with a `label` element to avoid the need to pass ids into the component. Focus states should be tested when altering the styles of this component.
 *
 * ## Validation feedback
 *
 * The `error` prop is used to display field validation issues. The `warning` prop is used to display issues related to publishing snapshots. In cases where both an error and a warning are presented to the field, only the error will be displayed.
 *
 * ## Legacy support
 * `isDestructive` is only available for the `ConfirmDelete` modal component. This is not a pattern we've seen in current visual design.
 */
export const Checkbox = function <
  TFormValues,
  TKey extends keyof TFormValues,
  TValue extends TFormValues[TKey] = TFormValues[TKey],
  >(props: Props<TKey>) {
  const {
    description,
    error,
    field,
    isAssociated,
    isDestructive,
    isDisabled,
    label,
    labelSupportingText,
    warning,
  } = props
  const hasError = error && !!error.trim().length
  const hasWarning = warning && !!warning.trim().length
  const [isFocussed, toggleFocus] = useState(false)

  return (
    <FieldWrapper isAssociated={isAssociated}>
      <FormageField
        name={field}
        render={(fieldProps) => {
          const isChecked = fieldProps.value
          return (
            <Label isDisabled={isDisabled} onBlur={fieldProps.blur}>
              <CheckboxHidden
                checked={isChecked}
                disabled={isDisabled}
                onChange={(event) => fieldProps.setFieldValue(field, event.target.checked)}
                onBlur={() => toggleFocus(false)}
                onFocus={() => toggleFocus(true)}
                type="checkbox"
              />
              <CheckboxStyled
                isChecked={isChecked}
                isDestructive={isDestructive}
                isDisabled={isDisabled}
                isFocussed={isFocussed}
              >
                {isChecked && <IconTick size="base" color="white" />}
              </CheckboxStyled>
              {label}
              {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
            </Label>
          )
        }}
      />

      {hasError && (<Error role="alert">{error}</Error>)}
      {!hasError && hasWarning && (<Warning role="alert">{warning}</Warning>)}
      {description && (<Description isDisabled={isDisabled}>{description}</Description>)}
    </FieldWrapper>
  )
}

Checkbox.defaultProps = {
  isAssociated: false,
  isDestructive: false,
  isDisabled: false,
}


type CheckboxInputElementProps = React.InputHTMLAttributes<HTMLInputElement>;
/**
 * Special case checkbox, requires a `<label />` wrapping it to function.
 * Intended to only be useful as a flexible building block in a situation where you are managing your own state.
 */
export const CheckboxInputElement = (props: CheckboxInputElementProps) => (
  <>
    <CheckboxHidden type="checkbox" {...props} />
    <CheckboxStyled isChecked={!!props.checked}>
      {!!props.checked && <IconTick size="base" color="white" />}
    </CheckboxStyled>
  </>
)
