// GEO JANK!
//
// This is an interesting beast - this entire file is an EPIC blast of geojank, but it's
// an accumulation: we do all the geojank in here so you don't have to.
//
// All geo functions we intend to use should be wrapped and shimmed for proper typing in
// here. All geo code we write should be treated like we treat UTF-8: convert to the
// immutable geo types as early as possible, convert from these types as late as possible.
//
// The excessive typing in here is based on a few simple observations:
//
// - GeoJSON is not going to change in any significant or meaningful way any time soon.
//
// - Lots of things depend on it.
//
// - There are lots of subtly different ways of representing or handling it. (turf, I'm
//   looking squarely at you!!!)
//
// - Nobody can make up their mind whether they support 2D, 3D, sorta both, sorta neither,
//   or sometimes (n)D. We want 2D everywhere. We don't want to have to handle the
//   possibility that something might return either 2D or 3D even if only passed a 2D
//   argument.
//
// An additional design goal is to gracefully manage undefined and null. Our graphql
// typings currently use a lot of nulls so we have to tolerate them for now.
//
// If a conversion function with a dynamic signature receives a type that can not be
// null/undefined, the inferred return type MUST NOT allow null or undefined.
//
// Thanks to MQS0002, this is all stuffed into a single file. This is to preserve
// usability and discoverability by callers at the expense of maintainers. This should
// only be broken apart into separate files when MQS0002 no longer applies and it can
// all be made available via a single import.
//
//
// Using
// -----
//
// The idea is that as long as you know roughly what geo type you have, exactly what geo
// type you need, and which class of functions to use, that's all you should have to think
// about to work with geo data safely.
//
// Conversion and assertion functions are mostly organised into categories:
//
//   - "is", for example, isPolygon2D(). "is" functions are pure TypeScript type guards
//     and will return a boolean indicating whether the input exactly satisfies the type
//     constraint.
//
//   - "must": for example, mustPolygon2D(). "must" functions are a combination of type
//     assertion and runtime assertion and will call "is" under the hood.  "must" expects
//     that the thing you passed in is _exactly_ the thing you expect to be returned, just
//     that the typing is not aligned.
//
//     "must" will assert that the shape of the data is correct and return the correct
//     type, or raise an Exception if it is not correct.
//
//     "must" functions DO NOT accept undefined or null: this will raise an Exception.
//
//   - "optional": this is the complement to "must", but "null" and "undefined" are
//     allowed as input. Only "undefined" is returned as output.
//
//   - "as": for example, asPolygon2D, asExtent. "as" functions will convert from
//     any reasonably convertible representation to the type specified in the function.
//     An Exception is raised if the conversion would not succeed.
//
// There are other categories emerging, for example mutability/immutability conversions,
// but these are not as well articulated as the classes mentioned above.
//
//
// Warts
// -----
//
// ## Busted type guards
//
// The `isBlah` type guards need to accept the narrowest possible known input type that
// is still useful. Type guards MUST NOT accept any because it causes more problems than
// it solves - it doesn't flush out enough errors at the call site where something loosely
// resembling a geo type needs to be made concrete. We realised this after introducing
// about a dozen bugs.
//
// Unfortunately, this means that the code _inside_ here can't make reasonable use of
// the type guards to distinguish between the range of possible inputs, especially in
// 'asBlah' style functions, which need to be very tolerant. The "solution" at this
// point has been to do a lot of casting in here, but the real solution is for TypeScript
// to clean up its act around type guarding and 'unknowns' - type guards don't work if
// we cast to 'any' (for no reason I can discern), but 'unknown', which should absolutely
// still work, also does not.
//
// As long as _users_ of geo.ts almost never have to think about this junk and it's
// dealt with in here and properly tested, it can be as ugly as it needs to be, it'd
// just be nicer if it didn't have to be.
//
// It may be that 'any' is an acceptable input type once we are doing fewer geo
// conversions in the guts of the app; this will be helped by having better-typed
// code-generation step for our api integration, which will possibly be led by the
// GRPC-web migration.
//


// XXX: We need to be able to import the external geojson typings in here, but they should
// not be used otherwise, only the types in this file should be used in application code.
/* eslint-disable no-restricted-imports */
import * as looseGeoJSON from 'geojson';

import Equality from 'geojson-equality';

// {{{ Library wrapper imports
import mapboxExtent from '@mapbox/geojson-extent';
import turfArea from '@turf/area';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfBearing from '@turf/bearing';
import turfCenter from '@turf/center';
import turfCentroid from '@turf/centroid';
import turfDestination from '@turf/destination';
import turfDistance from '@turf/distance';
import turfLineArc from '@turf/line-arc';
import turfTransformScale from '@turf/transform-scale';
/* eslint-enable no-restricted-imports */
// }}}


// {{{ Immutable Geojson
//
// We have attempted a couple of times to tame this. The last attempt was
// to try to override the core typing, but that just makes libraries that
// want to mutate break.
//
// The last remaining solution is to fork the defs, then wrap and unwrap
// at every call site. This can be ameliorated by providing wrapped versions
// of the functions from turf we want to use.
//
// Definitions are copied from the geojson types, but modified to work in
// an immutable context.
//
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped

// https://tools.ietf.org/html/rfc7946#section-1.4
export type GeoJSONGeometryType = Geometry['type'];

const geoJSONGeometryTypes: ReadonlyArray<GeoJSONGeometryType> = [
  'Point', 'MultiPoint', 'LineString', 'MultiLineString', 'Polygon', 'MultiPolygon', 'GeometryCollection',
];

// https://tools.ietf.org/html/rfc7946#section-1.4
export type GeoJSONType = GeoJSON['type'];

const geoJSONTypes: ReadonlyArray<GeoJSONType> = (geoJSONGeometryTypes as ReadonlyArray<GeoJSONType>).concat(
  ['Feature', 'FeatureCollection'],
);

// The value of the bbox member MUST be an array of length 2*n where n is the number of
// dimensions represented in the contained geometries, with all axes of the most
// southwesterly point followed by all axes of the more northeasterly point.  The axes
// order of a bbox follows the axes order of geometries.
//
// https://tools.ietf.org/html/rfc7946#section-5
//
export type BBox = BBox2D | BBox3D;

// XXX(bw): Ideally a BBox2D would be in a consistent order; the RFC implies WSEN (through
// unnecessarily overwrought language, naturally, so I could be wrong), but some crazy
// people who implement not-exactly-unpopular APIs are prone to doing crazy things:
// https://wiki.openstreetmap.org/wiki/Bounding_Box
//
// See also geo.Extent2D, which I'm on the fence about, but it is at least unambiguous.
export type BBox2D = readonly [number, number, number, number];

export type BBox3D = readonly [number, number, number, number, number, number];

export type MutableBBox = MutableBBox2D | MutableBBox3D;
export type MutableBBox2D = [number, number, number, number];
export type MutableBBox3D = [number, number, number, number, number, number];


// A Position is an array of coordinates.
// https://tools.ietf.org/html/rfc7946#section-3.1.1
// Array should contain between two and three elements.
// The previous GeoJSON specification allowed more elements (e.g., which could be used to represent M values),
// but the current specification only allows X, Y, and (optionally) Z to be defined.
//
export type Position = Position2D | Position3D;

// FIXME: these are not readonly. This is fixed with readonly tuples in TS3.4 but seemingly missing in TS3.5, come back to this when patched or at TS3.6 // release, this can be changed to 'readonly [number, number]':
export type Position2D = readonly [number, number];
export type Position3D = readonly [number, number, number];

export type Positioned = Position | Point | Feature<Point>;
export type Positioned2D = Position2D | Point2D | Feature<Point2D>;
export type Positioned3D = Position3D | Point3D | Feature<Point3D>;

export type MutablePosition = MutablePosition2D | MutablePosition3D;
export type MutablePosition2D = [number, number];
export type MutablePosition3D = [number, number, number];

// https://tools.ietf.org/html/rfc7946#section-3
//
// The GeoJSON specification also allows foreign members
// (https://tools.ietf.org/html/rfc7946#section-6.1)
// Developers should use "&" type in TypeScript or extend the interface
// to add these foreign members.
//
export interface GeoJSONObject {
  readonly type: GeoJSONType;

  // Bounding box of the coordinate range of the object's Geometries, Features, or Feature Collections.
  // The value of the bbox member is an array of length 2*n where n is the number of dimensions
  // represented in the contained geometries, with all axes of the most southwesterly point
  // followed by all axes of the more northeasterly point.
  // The axes order of a bbox follows the axes order of geometries.
  // https://tools.ietf.org/html/rfc7946#section-5
  readonly bbox?: BBox;
}

export interface GeoJSONObject2D { readonly type: GeoJSONType; readonly bbox?: BBox2D }
export interface GeoJSONObject3D { readonly type: GeoJSONType; readonly bbox?: BBox3D }

export interface MutableGeoJSONObject   { type: GeoJSONType; bbox?: MutableBBox }
export interface MutableGeoJSONObject2D { type: GeoJSONType; bbox?: MutableBBox2D }
export interface MutableGeoJSONObject3D { type: GeoJSONType; bbox?: MutableBBox3D }

export type GeoJSON = Geometry | Feature | FeatureCollection;
export type GeoJSON2D = Geometry2D | Feature2D | FeatureCollection2D;
export type GeoJSON3D = Geometry3D | Feature3D | FeatureCollection3D;

export type MutableGeoJSON = MutableGeometry | MutableFeature | MutableFeatureCollection;
export type MutableGeoJSON2D = MutableGeometry2D | MutableFeature2D | MutableFeatureCollection2D;
export type MutableGeoJSON3D = MutableGeometry3D | MutableFeature3D | MutableFeatureCollection3D;


// https://tools.ietf.org/html/rfc7946#section-3
export type Geometry = Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon | GeometryCollection;
export type Geometry2D = Point2D | MultiPoint2D | LineString2D | MultiLineString2D | Polygon2D | MultiPolygon2D | GeometryCollection2D;
export type Geometry3D = Point3D | MultiPoint3D | LineString3D | MultiLineString3D | Polygon3D | MultiPolygon3D | GeometryCollection3D;

export type MutableGeometry = MutablePoint | MutableMultiPoint | MutableLineString | MutableMultiLineString | MutablePolygon | MutableMultiPolygon | MutableGeometryCollection;
export type MutableGeometry2D = MutablePoint2D | MutableMultiPoint2D | MutableLineString2D | MutableMultiLineString2D | MutablePolygon2D | MutableMultiPolygon2D | MutableGeometryCollection2D;
export type MutableGeometry3D = MutablePoint3D | MutableMultiPoint3D | MutableLineString3D | MutableMultiLineString3D | MutablePolygon3D | MutableMultiPolygon3D | MutableGeometryCollection3D;


// https://tools.ietf.org/html/rfc7946#section-3.1.2
export type Point = Point2D | Point3D;
export interface Point2D extends GeoJSONObject { readonly type: 'Point'; readonly coordinates: Position2D }
export interface Point3D extends GeoJSONObject { readonly type: 'Point'; readonly coordinates: Position3D }

export type MutablePoint = MutablePoint2D | MutablePoint3D;
export interface MutablePoint2D extends MutableGeoJSONObject2D { type: 'Point'; coordinates: MutablePosition2D }
export interface MutablePoint3D extends MutableGeoJSONObject3D { type: 'Point'; coordinates: MutablePosition3D }


// https://tools.ietf.org/html/rfc7946#section-3.1.3
export type MultiPoint = MultiPoint2D | MultiPoint3D;
export interface MultiPoint2D extends GeoJSONObject { readonly type: 'MultiPoint'; readonly coordinates: ReadonlyArray<Position2D> }
export interface MultiPoint3D extends GeoJSONObject { readonly type: 'MultiPoint'; readonly coordinates: ReadonlyArray<Position3D> }

export type MutableMultiPoint = MutableMultiPoint2D | MutableMultiPoint3D;
export interface MutableMultiPoint2D extends MutableGeoJSONObject { type: 'MultiPoint'; coordinates: MutablePosition2D[] }
export interface MutableMultiPoint3D extends MutableGeoJSONObject { type: 'MultiPoint'; coordinates: MutablePosition3D[] }


// https://tools.ietf.org/html/rfc7946#section-3.1.4
export type LineString = LineString2D | LineString3D;
export interface LineString2D extends GeoJSONObject { readonly type: 'LineString'; readonly coordinates: ReadonlyArray<Position2D> }
export interface LineString3D extends GeoJSONObject { readonly type: 'LineString'; readonly coordinates: ReadonlyArray<Position3D> }

export type MutableLineString = MutableLineString2D | MutableLineString3D;
export interface MutableLineString2D extends MutableGeoJSONObject { type: 'LineString'; coordinates: MutablePosition2D[] }
export interface MutableLineString3D extends MutableGeoJSONObject { type: 'LineString'; coordinates: MutablePosition3D[] }


// https://tools.ietf.org/html/rfc7946#section-3.1.5
export type MultiLineString = MultiLineString2D | MultiLineString3D;
export interface MultiLineString2D extends GeoJSONObject { readonly type: 'MultiLineString'; readonly coordinates: ReadonlyArray<ReadonlyArray<Position2D>> }
export interface MultiLineString3D extends GeoJSONObject { readonly type: 'MultiLineString'; readonly coordinates: ReadonlyArray<ReadonlyArray<Position3D>> }

export type MutableMultiLineString = MutableMultiLineString2D | MutableMultiLineString3D;
export interface MutableMultiLineString2D extends MutableGeoJSONObject { type: 'MultiLineString'; coordinates: MutablePosition2D[][] }
export interface MutableMultiLineString3D extends MutableGeoJSONObject { type: 'MultiLineString'; coordinates: MutablePosition3D[][] }


// https://tools.ietf.org/html/rfc7946#section-3.1.6
export type Polygon = Polygon2D | Polygon3D;
export interface Polygon2D extends GeoJSONObject { readonly type: 'Polygon'; readonly coordinates: ReadonlyArray<ReadonlyArray<Position2D>> }
export interface Polygon3D extends GeoJSONObject { readonly type: 'Polygon'; readonly coordinates: ReadonlyArray<ReadonlyArray<Position3D>> }

export type MutablePolygon = MutablePolygon2D | MutablePolygon3D;
export interface MutablePolygon2D extends MutableGeoJSONObject { type: 'Polygon'; coordinates: MutablePosition2D[][] }
export interface MutablePolygon3D extends MutableGeoJSONObject { type: 'Polygon'; coordinates: MutablePosition3D[][] }


// https://tools.ietf.org/html/rfc7946#section-3.1.7
export type MultiPolygon = MultiPolygon2D | MultiPolygon3D;
export interface MultiPolygon2D extends GeoJSONObject { readonly type: 'MultiPolygon'; readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Position2D>>> }
export interface MultiPolygon3D extends GeoJSONObject { readonly type: 'MultiPolygon'; readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Position3D>>> }

export type MutableMultiPolygon = MutableMultiPolygon2D | MutableMultiPolygon3D;
export interface MutableMultiPolygon2D extends MutableGeoJSONObject { type: 'MultiPolygon'; coordinates: MutablePosition2D[][][] }
export interface MutableMultiPolygon3D extends MutableGeoJSONObject { type: 'MultiPolygon'; coordinates: MutablePosition3D[][][] }


// https://tools.ietf.org/html/rfc7946#section-3.1.8
export type GeometryCollection = GeometryCollection2D | GeometryCollection3D;
export interface GeometryCollection2D extends GeoJSONObject { readonly type: 'GeometryCollection'; readonly geometries: ReadonlyArray<Geometry2D> }
export interface GeometryCollection3D extends GeoJSONObject { readonly type: 'GeometryCollection'; readonly geometries: ReadonlyArray<Geometry3D> }

export type MutableGeometryCollection = MutableGeometryCollection2D | MutableGeometryCollection3D;
export interface MutableGeometryCollection2D extends MutableGeoJSONObject { type: 'GeometryCollection'; geometries: MutableGeometry2D[] }
export interface MutableGeometryCollection3D extends MutableGeoJSONObject { type: 'GeometryCollection'; geometries: MutableGeometry3D[] }

{ const _: looseGeoJSON.GeometryCollection = null as any as MutableGeometryCollection; } // Assert compatibility with upstream GeoJSON typings


export type GeoJSONProperties = Readonly<{}> | null;
export type MutableGeoJSONProperties = {} | null;

// A feature object which contains a geometry and associated properties:
// https://tools.ietf.org/html/rfc7946#section-3.2
export interface Feature<G extends Geometry | null = Geometry, P = GeoJSONProperties> extends GeoJSONObject {
  readonly type: 'Feature';
  readonly geometry: Readonly<G>;
  readonly id?: string | number;
  readonly properties: Readonly<P>;
}

export interface Feature2D<G extends Geometry2D | null = Geometry2D, P = GeoJSONProperties> extends GeoJSONObject2D {
  readonly type: 'Feature'; readonly geometry: Readonly<G>; readonly id?: string | number; readonly properties: Readonly<P>; }

export interface Feature3D<G extends Geometry3D | null = Geometry3D, P = GeoJSONProperties> extends GeoJSONObject3D {
  readonly type: 'Feature'; readonly geometry: Readonly<G>; readonly id?: string | number; readonly properties: Readonly<P>; }


export interface MutableFeature<G extends MutableGeometry | null = MutableGeometry, P = MutableGeoJSONProperties> extends MutableGeoJSONObject {
  type: 'Feature';
  geometry: G;
  id?: string | number;
  properties: P;
}

{ const _: looseGeoJSON.Feature = null as any as MutableFeature; } // Assert compatibility with upstream GeoJSON typings

export interface MutableFeature2D<G extends MutableGeometry2D | null = MutableGeometry2D, P = MutableGeoJSONProperties> extends MutableGeoJSONObject {
  type: 'Feature'; geometry: G; id?: string | number; properties: P; }

export interface MutableFeature3D<G extends MutableGeometry3D | null = MutableGeometry3D, P = MutableGeoJSONProperties> extends MutableGeoJSONObject {
  type: 'Feature'; geometry: G; id?: string | number; properties: P; }


// A collection of feature objects: https://tools.ietf.org/html/rfc7946#section-3.3
export interface FeatureCollection<G extends Geometry | null = Geometry, P = GeoJSONProperties> extends GeoJSONObject {
  readonly type: 'FeatureCollection';
  readonly features: ReadonlyArray<Feature<G, P>>;
}

export interface FeatureCollection2D<G extends Geometry2D | null = Geometry2D, P = GeoJSONProperties> extends GeoJSONObject2D {
  readonly type: 'FeatureCollection'; readonly features: ReadonlyArray<Feature2D<G, P>>; }

export interface FeatureCollection3D<G extends Geometry3D | null = Geometry3D, P = GeoJSONProperties> extends GeoJSONObject3D {
  readonly type: 'FeatureCollection'; readonly features: ReadonlyArray<Feature3D<G, P>>; }


export interface MutableFeatureCollection<G extends MutableGeometry | null = MutableGeometry, P = MutableGeoJSONProperties> extends MutableGeoJSONObject {
  type: 'FeatureCollection';
  features: Array<MutableFeature<G, P>>;
}

export interface MutableFeatureCollection2D<G extends MutableGeometry2D | null = MutableGeometry2D, P = MutableGeoJSONProperties> extends MutableGeoJSONObject2D {
  type: 'FeatureCollection'; features: Array<MutableFeature2D<G, P>>; }

export interface MutableFeatureCollection3D<G extends MutableGeometry3D | null = MutableGeometry3D, P = MutableGeoJSONProperties> extends MutableGeoJSONObject3D {
  type: 'FeatureCollection'; features: Array<MutableFeature3D<G, P>>; }

{ const _: looseGeoJSON.FeatureCollection = null as any as MutableFeatureCollection; } // Assert compatibility with upstream GeoJSON typings

// }}}


// {{{ Extra helper types

// Canonical format should be clockwise from top left:
//  Top left, top right, bottom right, bottom left
//
// XXX: this is very hard to work with as a tuple. We should consider migrating
// to a similar structured format to Extent2D.
export type Corners = readonly [Position2D, Position2D, Position2D, Position2D];
export type MutableCorners = [MutablePosition2D, MutablePosition2D, MutablePosition2D, MutablePosition2D];


// @deprecated, use Position2D or Point2D with [lng,lat]:
export interface LngLat {
  readonly lng: number;
  readonly lat: number;
}


interface VerboseLngLat { latitude: number; longitude: number }

type LooseLngLat = LngLat | VerboseLngLat | UnhelpfulMapboxLonLat;

function isLngLat(v: LoosePosition2D): v is LngLat {
  return typeof v === 'object' && (v as LngLat).lat !== undefined && (v as LngLat).lng !== undefined;
}

function isVerboseLngLat(v: LoosePosition2D): v is VerboseLngLat {
  return typeof v === 'object' && (v as VerboseLngLat).latitude !== undefined && (v as VerboseLngLat).longitude !== undefined;
}

function isGeoJSONPointFeature(v: any): v is Feature<Point, any> {
  return typeof v === 'object' && v.type === 'Feature' && (v as Feature).geometry && (v as Feature).geometry.type === 'Point';
}

// Note: This is an egress function _only_. Use Position2D wherever possible.
export function asLngLat(v: Point2D | Position2D): LngLat {
  v = asPosition2D(v);
  return { lng: v[0], lat: v[1] };
}

// }}}


// {{{ Equality

export function geoJsonEqual(a: GeoJSON | undefined, b: GeoJSON | undefined): boolean { return equal(a, b); }

export function positionEqual(a: Position | undefined, b: Position | undefined): boolean {
  if (!!a !== !!b) {
    return false;
  }
  if (a === undefined || b === undefined) {
    return true;
  }
  if (a.length !== b.length) {
    return false;
  }
  return (
    a.length === 2 ? a[0] === b[0] && a[1] === b[1] :
    a.length === 3 ? a[0] === b[0] && a[1] === b[1] && a[2] === b[2] :
    false
  );
}

export function equal(a: GeoJSON | Position | Corners | undefined | null, b: GeoJSON | Position | Corners | undefined | null): boolean {
  if (a === b) {
    return true;
  } if (!!a !== !!b) {
    return false;
  } else if (!a || !b) {
    return true;
  }

  if ((isCorners(a) && !isCorners(b)) || (!isCorners(a) && isCorners(b))) {
    return false;
  }

  if (isCorners(a) && isCorners(b)) {
    return a.every((aPos, idx) => positionEqual(aPos, b[idx]));
  }

  const aType: GeoJSONType | undefined = ('type' in a) ? a.type : undefined;
  const bType: GeoJSONType | undefined = ('type' in b) ? b.type : undefined;

  // XXX(bw) 20190404: the geojson-equality module is really low quality. It
  // swallows GeoJSON types it doesn't understand rather than throwing
  // exceptions and it has unimplemented features that are surfaced in the
  // public API but do not throw "not implemented" exceptions (removePseudo).
  // This was just from a 10-second glance, I'm sure there's more.
  //
  // Turf's library for the same just uses geojson-equality under the hood.

  if (aType !== bType) {
    return false;
  }

  // FIXME(bw): This is a bit janky. We should also support bare arrays of GeoJSON, not
  // just Feature/GeometryCollections, so I suspect this will need an overhaul.
  if (!aType || !bType) {
    if (!isPosition(a) || !isPosition(b)) {
      throw new Error(`unsupported type`);
    }
    return positionEqual(a, b);
  }

  // FIXME: implement this!
  if (aType === 'FeatureCollection' || aType === 'GeometryCollection') {
    throw new Error(`GeoJSON type '${aType}' not implemented by geojson-equality`);
  }

  const eq = new Equality();

  // XXX: 'any' is used to skirt the mut/immut typing here. The assumption is that
  // compare() shouldn't be mucking around mutating the values. Fingers crossed.
  return eq.compare(a as any, b as any);
}

// }}}


// {{{ Mutable clone

export function cloneMut(v: Point): MutablePoint;
export function cloneMut(v: Point | undefined): MutablePoint | undefined;
export function cloneMut(v: Point | null): MutablePoint | null;

export function cloneMut(v: MultiPoint): MutableMultiPoint;
export function cloneMut(v: MultiPoint | undefined): MutableMultiPoint | undefined;
export function cloneMut(v: MultiPoint | null): MutableMultiPoint | null;

export function cloneMut(v: LineString): MutableLineString;
export function cloneMut(v: LineString | undefined): MutableLineString | undefined;
export function cloneMut(v: LineString | null): MutableLineString | null;

export function cloneMut(v: Polygon): MutablePolygon;
export function cloneMut(v: Polygon | undefined): MutablePolygon | undefined;
export function cloneMut(v: Polygon | null): MutablePolygon | null;

export function cloneMut(v: MultiPolygon): MutableMultiPolygon;
export function cloneMut(v: MultiPolygon | undefined): MutableMultiPolygon | undefined;
export function cloneMut(v: MultiPolygon | null): MutableMultiPolygon | null;

export function cloneMut(v: Geometry): MutableGeometry;
export function cloneMut(v: Geometry | undefined): MutableGeometry | undefined;
export function cloneMut(v: Geometry | null): MutableGeometry | null;

export function cloneMut(v: GeometryCollection): MutableGeometryCollection;
export function cloneMut(v: GeometryCollection | undefined): MutableGeometryCollection | undefined;
export function cloneMut(v: GeometryCollection | null): MutableGeometryCollection | null;

export function cloneMut(v: Feature): MutableFeature;
export function cloneMut(v: Feature | undefined): MutableFeature | undefined;
export function cloneMut(v: Feature | null): MutableFeature | null;

export function cloneMut(v: FeatureCollection): MutableFeatureCollection;
export function cloneMut(v: FeatureCollection | undefined): MutableFeatureCollection | undefined;
export function cloneMut(v: FeatureCollection | null): MutableFeatureCollection | null;

export function cloneMut(v: GeoJSON | undefined | null): MutableGeoJSON | undefined | null {
  if (v === null) {
    return null;
  } else if (v === undefined) {
    return;
  }

  // XXX(bw): Gutter deep-clone: GeoJSON properties could contain anything. GeoJSON objects
  // can also be extended with anything (the original typing file explicitly recommends
  // extending with '&' in certain circumstances). We don't use or recommend this
  // (composition, pls!) but we can't know for sure how much garbage we'll be given by the
  // libraries we work with.
  //
  // This will bork if "anything" means "something unserialisable", but I think borking in
  // that situation is better than silently half-cloning. We may need to revisit this
  // though.
  //
  // I'm betting that mapbox will be the first bork off the rank.
  return JSON.parse(JSON.stringify(v));
}

// }}}


// {{{ Mutable/immutable conversion
//
// XXX(bw): The first version looked like this, where every single possible type
// combination was spelled out explicitly using overloads:
//
//    export function mut(v: Point): MutablePoint;
//    export function mut(v: ReadonlyArray<Point>): MutablePoint[];
//    export function mut(v: Point | undefined): MutablePoint | undefined;
//    export function mut(v: Point | null): MutablePoint | null;
//
//    export function mut(v: Polygon): MutablePolygon;
//    <snip>
//
// Once the 2D/3D types were made distinct, the number of overloads exploded, so
// I replaced it with this mapped type disaster. The mapped types are much more
// condensed, but they're an absolute nightmare to debug. They're probably a bit
// too far over the "clever" line for my taste so I think we should consider going
// back to overloads at some point and coping with the volume.
//
// This stuff should be extremely stable once it sets, so the boilerplate overhead
// isn't especially scary.

type AsMutableGeometry2D<T extends GeoJSON | null | undefined> =
  T extends Point2D ? MutablePoint2D :
  T extends MultiPoint2D ? MutableMultiPoint2D :
  T extends LineString2D ? MutableLineString2D :
  T extends Polygon2D ? MutablePolygon2D :
  T extends MultiPolygon2D ? MutableMultiPolygon2D :
  T extends null ? null :
  T extends undefined ? undefined :
  never;

type AsMutableGeometry3D<T extends GeoJSON | null | undefined> =
  T extends Point3D ? MutablePoint3D :
  T extends MultiPoint3D ? MutableMultiPoint3D :
  T extends LineString3D ? MutableLineString3D :
  T extends Polygon3D ? MutablePolygon3D :
  T extends MultiPolygon3D ? MutableMultiPolygon3D :
  T extends null ? null :
  T extends undefined ? undefined :
  never;

type AsMutableGeometry<T extends Geometry | null | undefined> =
  T extends Geometry2D ? AsMutableGeometry2D<T> :
  T extends Geometry3D ? AsMutableGeometry3D<T> :
  never;

type AsMutable<T extends GeoJSON | null | undefined> =
  T extends MutableGeoJSON ? T :
  T extends Feature2D<infer U> ?
    (U extends Geometry2D ? MutableFeature2D<AsMutableGeometry2D<U>> : never) :
  T extends Feature3D<infer U> ?
    (U extends Geometry3D ? MutableFeature3D<AsMutableGeometry3D<U>> : never) :
  T extends Feature<infer U> ?
    (U extends Geometry ? MutableFeature<AsMutableGeometry<U>> : never) :

  T extends FeatureCollection2D<infer U> ?
    (U extends Geometry2D ? MutableFeatureCollection2D<AsMutableGeometry2D<U>> : never) :
  T extends FeatureCollection3D<infer U> ?
    (U extends Geometry3D ? MutableFeatureCollection3D<AsMutableGeometry3D<U>> : never) :
  T extends FeatureCollection<infer U> ?
    (U extends Geometry ? MutableFeatureCollection<AsMutableGeometry<U>> : never) :

  T extends GeometryCollection ? MutableGeometryCollection :
  T extends Geometry ? AsMutableGeometry<T> :

  T extends null ? null :
  T extends undefined ? undefined :
  never;


type GeoExtra = Corners | BBox | Position | Extent2D;
type MutableGeoExtra = MutableCorners | MutableBBox | MutablePosition | MutableExtent2D;

// mut is an egress-level function that allows our immutable geo types to work with
// external libraries.
//
// The main community-supplied geojson typing file (@types/geojson) is depended on quite
// broadly, but it defines mutable geojson objects. This means that we have to use a
// call to 'mut()' anywhere we cross the boundary into another lib.
//
// geo.ts solves much of this problem by creating wrappers for everything, but our
// mapbox integrations have to handle this stuff manually.
//
export function mut<T extends BBox2D>(v: T): MutableBBox2D;
export function mut<T extends BBox3D>(v: T): MutableBBox3D;
export function mut<T extends GeoJSON>(v: T): AsMutable<T>;
export function mut<T extends GeoJSON | undefined>(v: T): AsMutable<T>;
export function mut<T extends Position2D>(v: T): MutablePosition2D;

export function mut<T extends ReadonlyArray<GeoJSON | MutableGeoJSON>>(v: T): Array<AsMutable<T extends ReadonlyArray<infer U> ? U : never>>;

export function mut(v: GeoExtra | GeoJSON | ReadonlyArray<GeoJSON> | undefined | null): MutableGeoExtra | MutableGeoJSON | MutableGeoJSON[] | undefined | null {
  return v as any;
}


type AsImmutableGeometry2D<T extends MutableGeoJSON | Geometry2D | null | undefined> =
  T extends Geometry2D ? T :
  T extends MutablePoint2D ? Point2D :
  T extends MutableMultiPoint2D ? MultiPoint2D :
  T extends MutableLineString2D ? LineString2D :
  T extends MutablePolygon2D ? Polygon2D :
  T extends MutableMultiPolygon2D ? MultiPolygon2D :
  T extends null ? null :
  T extends undefined ? undefined :
  never;

type AsImmutableGeometry3D<T extends MutableGeoJSON | Geometry3D | null | undefined> =
  T extends Geometry3D ? T :
  T extends MutablePoint3D ? Point3D :
  T extends MutableMultiPoint3D ? MultiPoint3D :
  T extends MutableLineString3D ? LineString3D :
  T extends MutablePolygon3D ? Polygon3D :
  T extends MutableMultiPolygon3D ? MultiPolygon3D :
  T extends null ? null :
  T extends undefined ? undefined :
  never;

type AsImmutableGeometry<T extends MutableGeometry | Geometry | null | undefined> =
  T extends MutableGeometry2D ? AsImmutableGeometry2D<T> :
  T extends MutableGeometry3D ? AsImmutableGeometry3D<T> :
  T extends Geometry ? T :
  never;

type AsImmutable<T extends MutableGeoJSON | GeoJSON | null | undefined> =
  T extends GeoJSON ? T :
  T extends MutableFeature2D<infer U> ?
    (U extends MutableGeometry2D ? Feature2D<AsImmutableGeometry2D<U>> : never) :
  T extends MutableFeature3D<infer U> ?
    (U extends MutableGeometry3D ? Feature3D<AsImmutableGeometry3D<U>> : never) :
  T extends MutableFeature<infer U> ?
    (U extends MutableGeometry ? Feature<AsImmutableGeometry<U>> : never) :

  T extends MutableFeatureCollection2D<infer U> ?
    (U extends MutableGeometry2D ? FeatureCollection2D<AsImmutableGeometry2D<U>> : never) :
  T extends MutableFeatureCollection3D<infer U> ?
    (U extends MutableGeometry3D ? FeatureCollection3D<AsImmutableGeometry3D<U>> : never) :
  T extends MutableFeatureCollection<infer U> ?
    (U extends MutableGeometry ? FeatureCollection<AsImmutableGeometry<U>> : never) :

  T extends MutableGeometryCollection ? GeometryCollection :
  T extends MutableGeometry ? AsImmutableGeometry<T> :

  T extends null ? null :
  T extends undefined ? undefined :
  never;

export function immut<T extends MutableGeoJSON>(v: T): AsImmutable<T>;
export function immut<T extends MutableGeoJSON | null>(v: T): AsImmutable<T>;
export function immut<T extends GeoJSON>(v: T): T;
export function immut<T extends GeoJSON | null>(v: T): T;
export function immut<T extends ReadonlyArray<MutableGeoJSON>>(v: T): ReadonlyArray<AsImmutable<T extends ReadonlyArray<infer U> ? U : never>>;
export function immut<T extends ReadonlyArray<GeoJSON>>(v: T): T;
export function immut<T extends ReadonlyArray<MutableGeoJSON | GeoJSON>>(v: T): ReadonlyArray<AsImmutable<T extends ReadonlyArray<infer U> ? U : never>>;

export function immut(
  v: MutableGeoJSON |
    GeoJSON |
    ReadonlyArray<MutableGeoJSON | GeoJSON> |
    ReadonlyArray<MutableGeoJSON> |
    ReadonlyArray<GeoJSON> |
    undefined |
    null,
): GeoJSON | ReadonlyArray<GeoJSON> | undefined | null {
  // FIXME: assert
  return v as any;
}

// }}} Mutable/immutable conversion


// {{{ Filtering
//
// API endpoints may return arrays of geo values that can be individually null/undefined.
// This is a big source of geo-jank like this:
//
//    const pois = beacons
//      .map((v): geo.Point2D | undefined => geo.mustPoint2D(v.position))
//      .filter((v): v is geo.Point2D => !!v);
//
// filter() allows the above code to be replaced with this:
//
//    const pois = geo.filter(beacons.map((v) => geo.mustPoint2D(v.position)));
//

export function filter<TValue extends Geometry | Feature>(vs: TValue | ReadonlyArray<TValue | undefined> | undefined): ReadonlyArray<TValue> {
  let vArr: ReadonlyArray<TValue>;
  if (Array.isArray(vs)) {
    vArr = vs;
  } else if (vs) {
    vArr = [vs as TValue];
  } else {
    vArr = [];
  }

  const out = [];
  for (const v of vArr) {
    if (v) { out.push(v); }
  }
  return out;
}

// }}}


// {{{ Assertions

type LooseBBox2D =
  Corners |
  Extent2D |
  readonly [Position2D, Position2D] |
  BBox2D |
  MutableBBox2D
;

export function isBBox2D(v: any): v is BBox2D {
  return Array.isArray(v) && v.length === 4 && Number.isFinite(v[0]) && Number.isFinite(v[1]) && Number.isFinite(v[2]) && Number.isFinite(v[3]);
}

export function asBBox2D(v: LooseBBox2D): BBox2D {
  if (isExtent2D(v)) {
    return [v.w, v.s, v.e, v.n];

  } else if (isBBox2D(v)) {
    return v;

  } else if (isCorners(v)) {
    return [
      Math.min(v[0][0], v[1][0], v[2][0], v[3][0]), // W
      Math.min(v[0][1], v[1][1], v[2][1], v[3][1]), // S

      Math.max(v[0][0], v[0][0], v[2][0], v[3][0]), // E
      Math.max(v[1][1], v[1][1], v[2][1], v[3][1]), // N
    ];

  } else if (Array.isArray(v) && v.length === 2 && isPosition2D(v[0]) && isPosition2D(v[1])) {
    return [
      Math.min(v[0][0], v[1][0]), // W
      Math.min(v[0][1], v[1][1]), // S

      Math.max(v[0][0], v[1][0]), // E
      Math.max(v[0][1], v[1][1]), // N
    ];
  }

  throw new Error(`geo: can not represent '${repr(v)}' as BBox2D`);
}


export function isCorners(v: any): v is Corners {
  return Array.isArray(v) && v.length === 4 && isPosition2D(v[0]) && isPosition2D(v[1]) && isPosition2D(v[2]) && isPosition2D(v[3]);
}

type LooseCorners =
  MapboxLngLatBounds | // Ingress-only
  Corners |
  Extent2D |
  readonly [Position2D, Position2D] |
  BBox2D
;

//  Top left, top right, bottom right, bottom left
export function asCorners(v: LooseCorners): Corners {
  if (isCorners(v)) {
    return v;

  } else if (isExtent2D(v)) {
    return [[v.w, v.n], [v.e, v.n], [v.e, v.s], [v.w, v.s]];

  } else if (isMapboxLngLatBounds(v)) {
    const ne = asPosition2D(v.getNorthEast());
    const sw = asPosition2D(v.getSouthWest());
    return [[sw[0], ne[1]], [ne[0], ne[1]], [ne[0], sw[1]], [sw[0], sw[1]]];

  } else if (Array.isArray(v)) {
    if (v.length === 2 && isPosition2D(v[0]) && isPosition2D(v[1])) {
      const w = Math.min(v[0][0], v[1][0]);
      const e = Math.max(v[0][0], v[1][0]);
      const s = Math.min(v[0][1], v[0][1]);
      const n = Math.max(v[1][1], v[1][1]);
      return [[w, n], [e, n], [e, s], [w, s]];

    } else if (v.length === 4 && Number.isFinite(v[0]) && Number.isFinite(v[1]) && Number.isFinite(v[2]) && Number.isFinite(v[3])) {
      return [[v[0], v[3]], [v[2], v[3]], [v[2], v[1]], [v[0], v[1]]];
    }
  }

  throw new Error(`geo: can not represent '${repr(v)}' as Extent2D`);
}

export function optionalCorners(v: any): Corners | undefined {
  if (v === undefined || v === null || (v as any).length === 0) {
    return undefined;
  }
  return mustCorners(v);
}

export function mustCorners(v: any): Corners {
  if (!isCorners(v)) {
    throw new Error(`geo: could not assert that '${repr(v)}' is Corners`);
  }
  return v;
}

// XXX: this shouldn't be exported, it's used internally for fast checks but doesn't
// exhaustively validate:
function isGeoJSONFast(v: object): v is GeoJSON {
  return (v as any).type && geoJSONTypes.indexOf((v as any).type) >= 0;
}

// XXX: this shouldn't be exported, it's used internally for fast checks but doesn't
// exhaustively validate:
function isFeature2DFast(v: object): v is Feature2D {
  return (v as any).type === 'Feature';
}

export function isPosition(v: any): v is Position {
  return isPosition2D(v) || isPosition3D(v);
}

export function isPosition3D(v: any): v is Position3D {
  return Array.isArray(v) && v.length === 3 && Number.isFinite(v[0]) && Number.isFinite(v[1]) && Number.isFinite(v[2]);
}

export function isPosition2D(v: any): v is Position2D {
  return Array.isArray(v) && v.length === 2 && Number.isFinite(v[0]) && Number.isFinite(v[1]);
}

export type LoosePosition2D = number[]
  | UnhelpfulMapboxLonLat
  | Position2D
  | LngLat
  | VerboseLngLat
  | Point2D
  | LoosePoint2D
  | MutablePoint2D
  | Feature<Point2D, any>;

export function asPosition2D(v: LoosePosition2D | Point2D): Position2D {
  if (isFeature2DFast(v)) {
    v = v.geometry as any; // XXX: assumes that LoosePosition2D only allows Feature<Point2D>
  }

  if (isPosition2D(v)) {
    return v;

  } else if (isPoint2D(v as any)) {
    return (v as Point2D).coordinates;

  } else if (isVerboseLngLat(v)) {
    return [v.longitude, v.latitude];

  } else if (isLngLat(v)) {
    return [v.lng, v.lat];

  } else if (isUnhelpfulMapboxLonLat(v)) {
    return [v.lon, v.lat];
  }

  throw new Error(`geo: could not convert '${repr(v)}' to a 2D Position`);
}

export function mustPosition2D(v: ReadonlyArray<any> | undefined | null): Position2D {
  if (!isPosition2D(v)) {
    throw new Error(`geo: could not assert that '${repr(v)}' is a Position2D`);
  }
  return v;
}

export function optionalPosition2D(v: ReadonlyArray<any> | undefined | null): Position2D | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  return mustPosition2D(v);
}


export type LoosePoint2D = { type: string; coordinates: ReadonlyArray<number> };

export function asPoint2D(v: LoosePosition2D | LoosePoint2D | Point2D): Point2D {
  if (isPoint2D(v as any)) {
    return v as Point2D;
  } else if (isPosition2D(v)) {
    return { type: 'Point', coordinates: asPosition2D(v) };
  }
  throw new Error(`geo: could not convert '${repr(v)}' to a Point2D`);
}

export function isPoint2D(v: LoosePoint2D | undefined | null): v is Point2D {
  return !!v && (v as any).type === 'Point' && isPosition2D((v as any).coordinates);
}

export function mustPoint2D(v: LoosePoint2D | null | undefined): Point2D {
  if (!isPoint2D(v)) {
    throw new Error(`geo: could not assert that '${repr(v)}' is a Point2D`);
  }
  return v;
}

export function optionalPoint2D(v: LoosePoint2D | undefined | null): Point2D | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  return mustPoint2D(v);
}


type Polygonable2D = Polygon2D | Corners | Extent2D | BBox2D;

export function asPolygon2D(v: Polygonable2D): Polygon2D {
  if (isCorners(v)) {
    // FIXME: winding
    return { type: 'Polygon', coordinates: [[...v, v[0]]] as any };

  } else if (isBBox2D(v)) {
    return turfBboxPolygon(v as any).geometry as any;

  } else if (isExtent2D(v)) {
    return turfBboxPolygon([v.w, v.s, v.e, v.n]).geometry as any;

  } else if (isPolygon2D(v)) {
    return v;
  }

  throw new Error(`geo: could not convert '${repr(v)}' to a 2D Polygon`);
}

export function isPolygon2D(v: any): v is Polygon2D {
  // FIXME: consolidate, check all points, be less gross
  return !!v &&
    (v as any).type === 'Polygon' &&
    (v as any).coordinates &&
    Array.isArray((v as any).coordinates) &&
    (v as any).coordinates.length >= 1 &&
    Array.isArray((v as any).coordinates[0]) &&
    (v as any).coordinates[0].length >= 1 &&
    isPosition2D((v as any).coordinates[0][1])
  ;
}

export function mustPolygon2D(v: { type: string } | null | undefined): Polygon2D {
  if (!isPolygon2D(v)) {
    throw new Error(`geo: could not assert that '${repr(v)}' is a 2D Polygon`);
  }
  return v;
}

export function optionalPolygon2D(v: { type: string } | undefined | null): Polygon2D | undefined {
  if (!v) {
    return;
  }
  return mustPolygon2D(v);
}

// }}}


// {{{ Juggling

type Geometry2DAsFeature<T extends Geometry2D | null | undefined, TProperties = GeoJSONProperties> =
  T extends null           ? null :
  T extends undefined      ? undefined :
  T extends Point2D        ? Feature2D<Point2D, TProperties> :
  T extends MultiPoint2D   ? Feature2D<MultiPoint2D, TProperties> :
  T extends LineString2D   ? Feature2D<LineString2D, TProperties> :
  T extends Polygon2D      ? Feature2D<Polygon2D, TProperties> :
  T extends MultiPolygon2D ? Feature2D<MultiPolygon2D, TProperties> :
  never;

type Geometry3DAsFeature<T extends Geometry3D | null | undefined, TProperties = GeoJSONProperties> =
  T extends null           ? null :
  T extends undefined      ? undefined :
  T extends Point3D        ? Feature3D<Point3D, TProperties> :
  T extends MultiPoint3D   ? Feature3D<MultiPoint3D, TProperties> :
  T extends LineString3D   ? Feature3D<LineString3D, TProperties> :
  T extends Polygon3D      ? Feature3D<Polygon3D, TProperties> :
  T extends MultiPolygon3D ? Feature3D<MultiPolygon3D, TProperties> :
  never;

type GeometryAsFeature<T extends Geometry | Geometry | null | undefined, TProperties = GeoJSONProperties> =
  T extends Geometry2D ? Geometry2DAsFeature<T> :
  T extends Geometry3D ? Geometry3DAsFeature<T> :
  never;

type AsFeature<T extends Feature | Geometry | null | undefined, TProperties = GeoJSONProperties> =
  T extends null       ? null :
  T extends undefined  ? undefined :
  T extends Geometry2D ? Geometry2DAsFeature<T, TProperties> :
  T extends Geometry3D ? Geometry3DAsFeature<T, TProperties> :

  T extends Feature2D<infer U, TProperties> ?
    (U extends Geometry2D ? Geometry2DAsFeature<U, TProperties> : never) :

  T extends Feature3D<infer U, TProperties> ?
    (U extends Geometry3D ? Geometry3DAsFeature<U, TProperties> : never) :

  T extends Feature<infer U> ?
    (U extends Geometry ? GeometryAsFeature<U, TProperties> : never) :

  never;


export function asFeatures2D<TValue extends Geometry2D | Feature2D, TProperties = GeoJSONProperties>(
  vs: TValue | ReadonlyArray<TValue>,
  ps?: ReadonlyArray<TProperties>):
    ReadonlyArray<AsFeature<TValue>> {

  return asFeatures(vs, ps) as any;
}

export function asFeatures3D<TValue extends Geometry3D | Feature3D, TProperties = GeoJSONProperties>(
  vs: TValue | ReadonlyArray<TValue>,
  ps?: ReadonlyArray<TProperties>):
    ReadonlyArray<AsFeature<TValue>> {

  return asFeatures(vs, ps) as any;
}

export function asFeatures<TValue extends Geometry | Feature, TProperties = GeoJSONProperties>(
  vs: TValue | ReadonlyArray<TValue> | undefined,
  ps?: ReadonlyArray<TProperties>):
    ReadonlyArray<AsFeature<TValue>> {

  let vArr: ReadonlyArray<TValue>;
  if (Array.isArray(vs)) {
    vArr = vs;
  } else if (vs) {
    vArr = [vs as TValue];
  } else {
    vArr = [];
  }

  const out = [];
  let idx = 0;
  for (const v of vArr) {
    if (v) {
      out.push(asFeature(v!, ps ? ps[idx] : undefined));
      idx++;
    }
  }
  return out;
}


export function asFeature2D<TValue extends Geometry2D | Feature2D | undefined, TProperties = GeoJSONProperties>(v: TValue, p?: TProperties): AsFeature<TValue> {
  return asFeature(v as any, p) as any;
}

export function asFeature3D<TValue extends Geometry3D | Feature3D | undefined, TProperties = GeoJSONProperties>(v: TValue, p?: TProperties): AsFeature<TValue> {
  return asFeature(v as any, p) as any;
}

export function asFeature<TValue extends Geometry | Feature | undefined, TProperties = GeoJSONProperties>(v: TValue, p?: TProperties): AsFeature<TValue> {
  if (!v) {
    return undefined as any; // XXX: no idea why typescript doesn't let us return this; it's explicitly allowed by the type parameter and by AsFeature.
  }

  if (v.type === 'Feature') {
    // FIXME: consider console.warning here
    return v as any;
  }

  // Properties MUST always be set to an empty object if not provided; many libraries will
  // assert that it exists:
  return { type: 'Feature', geometry: v, properties: p || {}} as any;
}


type UnwrapFeature<T extends Feature | Geometry | null | undefined> =
  T extends undefined ? undefined :
  T extends null ? undefined :
  T extends Geometry ? T :
  T extends Feature<infer U> ?  U :
  never;

export function unwrapFeature<TValue extends Geometry | Feature>(v: TValue): UnwrapFeature<TValue> {
  if (v.type !== 'Feature') {
    // FIXME: consider console.warning here
    return v as any;
  }
  return (v as any).geometry;
}


export function resolvePosition2D(v: Positioned2D): Position2D {
  if (isPosition2D(v)) {
    return v;
  } else if (isPoint2D(v as any)) {
    return (v as Point2D).coordinates;
  } else if (v.type === 'Feature') {
    return v.geometry.coordinates;
  }
  throw new Error(`geo: could not assert that '${repr(v)}' is a Position2D, Point2D or Feature<Point2D>`);
}

// }}}


// {{{ Wrappers

// Extent represents the northern and southernmost extremities of latitude and the eastern
// and westernmost extremities of longitude. A common pattern is to represent this as a
// 4-tuple in [W, S, E, N] order (referred to as 'WSEN' data).
export type Extent2D = Readonly<{ w: number; s: number; e: number; n: number }>;
export type MutableExtent2D = { w: number; s: number; e: number; n: number };

// extent2D calculates the extent of any 2D GeoJSON object.
export function extent2D(coords: GeoJSON2D): Extent2D {
  const out = mapboxExtent(coords);
  return { w: out[0], s: out[1], e: out[2], n: out[3] };
}

type LooseExtent2D =
  MapboxLngLatBounds | // Ingress-only
  Extent2D |
  readonly [Position2D, Position2D] |
  BBox2D
;

export function isExtent2D(v: any): v is Extent2D {
  return typeof v === 'object' && 'w' in v && 's' in v && 'e' in v && 'n' in v &&
    Number.isFinite(v.w) && Number.isFinite(v.s) && Number.isFinite(v.e) && Number.isFinite(v.n);
}

// asExtent2D converts a loose representation of an extent received from an external
// source to our consistent representation.
export function asExtent2D(v: LooseExtent2D): Extent2D {
  if (Array.isArray(v)) {
    if (v.length === 2 && isPosition2D(v[0]) && isPosition2D(v[1])) {
      return {
        w: Math.min(v[0][0], v[1][0]),
        e: Math.max(v[0][0], v[1][0]),
        s: Math.min(v[0][1], v[0][1]),
        n: Math.max(v[1][1], v[1][1]),
      };
    } else if (v.length === 4 && Number.isFinite(v[0]) && Number.isFinite(v[1]) && Number.isFinite(v[2]) && Number.isFinite(v[3])) {
      return { w: v[0], s: v[1], e: v[2], n: v[3] };
    }

  } else if (isExtent2D(v)) {
    return v;

  } else if (isMapboxLngLatBounds(v)) { // Ingress-only
    const sw = asPosition2D(v.getSouthWest());
    const ne = asPosition2D(v.getNorthEast());
    return { w: sw[0], s: sw[1], e: ne[0], n: ne[1] };
  }

  throw new Error(`geo: can not represent '${repr(v)}' as Extent2D`);
}

// }}}


// {{{ Wrappers: mapbox

type UnhelpfulMapboxLonLat = { lon: number; lat: number };

// XXX: the typing for mapboxgl.LatLngBoundsLike proclaims to support the properties
// 'sw' and 'ne' directly, but attempts to use these result in an undefined so we
// have to look for getSouthWest and getNorthEast
interface MapboxLngLatBounds {
  getSouthWest(): LooseLngLat | Position2D;
  getNorthEast(): LooseLngLat | Position2D;
}

export function isMapboxLngLatBounds(v: any): v is MapboxLngLatBounds {
  return typeof v === 'object' &&
    typeof (v as any).getSouthWest() &&
    isLngLat((v as any).getSouthWest()) &&
    typeof (v as any).getNorthEast() &&
    isLngLat((v as any).getNorthEast());
}

function isUnhelpfulMapboxLonLat(v: LoosePosition2D): v is UnhelpfulMapboxLonLat {
  return (v as UnhelpfulMapboxLonLat).lon !== undefined && (v as UnhelpfulMapboxLonLat).lat !== undefined;
}

// }}}


// {{{ Turf wrappers
//
// Turf does some things that don't quite line up with what we want to support:
//
// - Calculations in problematic units like 'yards'
// - Underspecified intersections of implementation supported units and type-system
//   supported units (lineArc takes a subset of units, only declared in jsdoc)
// - Functions return unnecessary GeoJSON Features rather than Geometry
// - Loose, weird, mutable GeoJSON typings
// - Returned values often wrapped in unnecessary GeoJSON features
//
// It's a very useful library though so we have decided to wrap every function we want to
// use on the assumption that our externally facing interface will remain stable.
//

type Centroid2DType = GeoJSON2D;

export function centroid2DPoint(v: Centroid2DType): Point2D;
export function centroid2DPoint(v: Centroid2DType | undefined): Point2D | undefined {
  if (!v) { return; }
  if (!isGeoJSONFast(v)) {
    throw new Error(`geo: '${repr(v)}' is not GeoJSON`);
  }
  const result = turfCentroid(v as any); // turf's centroid only handles 2D calculations
  return result.geometry as any;
}

export function centroid2D(v: Centroid2DType): Position2D;
export function centroid2D(v: Centroid2DType): Position2D | undefined {
  const c = centroid2DPoint(v);
  return c ? c.coordinates : undefined;
}


export function center2D(v: Position2D | GeoJSON2D | Corners | Extent2D | BBox2D): Position2D;
export function center2D(v: Position2D | GeoJSON2D | Corners | Extent2D | BBox2D | undefined): Position2D | undefined {
  if (!v) { return; }
  if (isPosition2D(v)) {
    return v;
  }

  if (isCorners(v) || isExtent2D(v) || isBBox2D(v)) {
    v = asPolygon2D(v);
  }
  if (!isGeoJSONFast(v)) {
    throw new Error(`geo: '${repr(v)}' is not GeoJSON`);
  }

  const result = turfCenter(v as any) as any;
  return result.geometry.coordinates as any;
}


export function bbox(v: GeoJSON2D): BBox2D;
export function bbox(v: GeoJSON2D | undefined): BBox2D | undefined;
export function bbox(v: GeoJSON3D): BBox3D;
export function bbox(v: GeoJSON3D | undefined): BBox3D | undefined;
export function bbox(v: GeoJSON): BBox;
export function bbox(v: GeoJSON | undefined): BBox | undefined {
  if (!v) { return; }
  const result = turfBbox(v as any);
  return result as any; // Sidestep turf typings
}
export function bbox2D(v: GeoJSON2D): BBox2D;
export function bbox2D(v: GeoJSON2D | undefined): BBox2D | undefined { return bbox(v); }
export function bbox3D(v: GeoJSON3D): BBox3D;
export function bbox3D(v: GeoJSON3D | undefined): BBox3D | undefined { return bbox(v); }


export function squareMeters(v: GeoJSON2D): number { return turfArea(v as any); }


export function bearing(start: Positioned, end: Positioned, options?: { final?: boolean }): number {
  return turfBearing(start as any, end as any, options);
}

export function destMeters(start: Positioned2D, meters: number, bearing: number): Point2D;
export function destMeters(start: Positioned3D, meters: number, bearing: number): Point3D;
export function destMeters(start: Positioned, meters: number, bearing: number): Point {
  const dest = turfDestination(start as any, meters, bearing, { units: 'meters'});
  return dest.geometry as any;
}

export function destKilometers(start: Positioned2D, kilometers: number, bearing: number): Point2D;
export function destKilometers(start: Positioned3D, kilometers: number, bearing: number): Point3D;
export function destKilometers(start: Positioned, kilometers: number, bearing: number): Point {
  const dest = turfDestination(start as any, kilometers, bearing, { units: 'kilometers'});
  return dest.geometry as any;
}

export function kilometers(start: Positioned, end: Positioned): number { return turfDistance(start as any, end as any, { units: 'kilometers'}); }
export function meters(start: Positioned, end: Positioned): number { return turfDistance(start as any, end as any, { units: 'meters'}); }


export function lineArcKilometers2D(opts: { center: Positioned2D; radius: number; bearing1: number; bearing2: number; steps?: number }): LineString2D {
  const out = turfLineArc(opts.center as any, opts.radius, opts.bearing1, opts.bearing2, opts as any);
  return out.geometry as any;
}


type TransformOrigin2D = 'sw' | 'se' | 'nw' | 'ne' | 'center' | 'centroid' | Positioned2D;

export function transformScale2D<T extends GeoJSON2D | undefined>(v: T, factor: number, origin?: TransformOrigin2D): T extends undefined ? T | undefined : T {
  if (!v) {
    return undefined as any;
  }
  const out = turfTransformScale(v as any, factor, origin ? { origin } as any : undefined);
  return out as any;
}

// }}}


// {{{ Formatters

// XXX(bw): Humans put longitude first, which is really unhelpful! After discussing
// with Jack, we feel that users should almost never be exposed to the fact that
// longitude is first in a Position2D, hence functions like this.
export function formatPosition2D(v: Point2D | Position2D): string {
  const p: Position2D = asPosition2D(isPoint2D(v as any) ? (v as Point2D).coordinates : v);
  return `${p[1]}, ${p[0]}`;
}

// }}}


type TypeToGeometry2D<T extends GeoJSONType> =
  T extends 'Point'        ? Point2D :
  T extends 'MultiPoint'   ? MultiPoint2D :
  T extends 'LineString'   ? LineString2D :
  T extends 'Polygon'      ? Polygon2D :
  T extends 'MultiPolygon' ? MultiPolygon2D :
  never
;

type TypeToObject2D<T extends GeoJSONType> =
  T extends GeoJSONGeometryType  ? TypeToGeometry2D<T> :
  T extends 'Feature'            ? Feature2D :
  T extends 'FeatureCollection'  ? FeatureCollection2D :
  T extends 'GeometryCollection' ? GeometryCollection2D :
  never
;

type TypeToGeometry3D<T extends GeoJSONType> =
  T extends 'Point'        ? Point3D :
  T extends 'MultiPoint'   ? MultiPoint3D :
  T extends 'LineString'   ? LineString3D :
  T extends 'Polygon'      ? Polygon3D :
  T extends 'MultiPolygon' ? MultiPolygon3D :
  never
;

type TypeToObject3D<T extends GeoJSONType> =
  T extends GeoJSONGeometryType  ? TypeToGeometry3D<T> :
  T extends 'Feature'            ? Feature3D :
  T extends 'FeatureCollection'  ? FeatureCollection3D :
  T extends 'GeometryCollection' ? GeometryCollection3D :
  never
;


function repr(v: any): string {
  // FIXME: this is a bit dangerous to do with 'any'
  let out = JSON.stringify(v);
  if (out.length > 200) {
    out = out.slice(0, 200) + '...';
  }
  return out;
}
