import * as React from 'react'
import Select from 'react-select';

import { classNamePrefix, FieldWrapper } from 'components/ui/select'
import { connect } from 'containers/store';
import { refToUrn } from 'entity';
import { Space, SpaceType } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import { Status, statusSelector } from 'helpers/status';

import { spacesBindableActionCreators } from 'domains/spaces/actions';
import { DataStatus, SpaceTypes } from 'domains/spaces/store';

type DirectProps = {
  readonly selected?: SpaceType.Entity | SpaceType.Ref;
  readonly onBlur?: () => void;
  readonly onChange?: (e: SpaceTypeChanged) => void;
};

type ConnectedProps = {
  readonly spaceTypes: DataStatus<SpaceTypes>;
};

type ActionProps = Pick<typeof spacesBindableActionCreators, 'spaceTypesRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

export type SpaceTypeChanged = {
  readonly spaceType: SpaceType.Entity | undefined;
};

// This component connects to the store for now because space type options are
// essentially global; perhaps this data should come as part of the app config.
class SpaceTypeSelectView extends React.PureComponent<Props> {
  public componentDidMount() {
    if (statusSelector.shouldLoad(this.props.spaceTypes.status)) {
      this.props.spaceTypesRequest();
    }
  }

  public render() {
    const { onBlur, spaceTypes } = this.props;
    if (spaceTypes.status !== Status.Ready) {
      return 'Loading...';
    }

    const options = spaceTypes.data.spaceTypes.map((v) => ({ label: v.name, value: v.ref }));

    let selectedRef: SpaceType.Ref | undefined;
    if (this.props.selected) {
      selectedRef = "ref" in this.props.selected ?
        this.props.selected.ref :
        this.props.selected;
    }

    return (
      <FieldWrapper>
        <Select
          classNamePrefix={classNamePrefix}
          options={options}
          styles={{ control: (base) => ({ ...base, height: '51px' }), menuList: (base) => ({ ...base, maxHeight: '100%' }), }}
          value={options.filter((v) => {
            return selectedRef && v.value && v.value.id === selectedRef.id;
          })}
          onBlur={onBlur ? () => onBlur() : undefined}
          onChange={(option: any) => { // MQS0003
            if (!this.props.onChange) {
              return;
            }
            return this.props.onChange({
              spaceType: option ? spaceTypes.data.index[refToUrn(option.value)] : undefined,
            });
          }}
        />
      </FieldWrapper>
    );
  }
}

export const SpaceTypeSelect = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({ spaceTypes: store.spaces.spaceTypes }),
  pick(spacesBindableActionCreators, 'spaceTypesRequest'),
)(SpaceTypeSelectView);
