/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaSubtitles = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-5 13H5v2h9v-2zm5 0h-3v2h3v-2zM8 12H5v2h3v-2zm11 0h-9v2h9v-2z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaSubtitlesString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-5 13H5v2h9v-2zm5 0h-3v2h3v-2zM8 12H5v2h3v-2zm11 0h-9v2h9v-2z"  fillRule="nonzero" />
  </svg>`

IconMediaSubtitles.displayName = 'IconMediaSubtitles'
