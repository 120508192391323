import React, { useEffect, useState } from 'react'
import { createFormBag, FormData } from 'react-formage'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { Button } from 'components/ui/button'
import { IconTrash } from 'components/ui/icons'
import { Input } from 'components/ui/input'
import { Option, SelectRef } from 'components/ui/select'

import { MediaField } from 'domains/media/components/media-field'

import * as entity from 'entity'

import { AttributeRef } from 'generated/mos/entity'
import { Media, MediaKind, MediaKindValues } from 'generated/mos/media'

import { Invariant } from 'helpers/core'
import { LinkableValue } from 'helpers/linkables'
import { space } from 'helpers/style'
import styled from 'styled'

export type LinkableOverrideOptions = Pick<Props, 'alternateFieldValues' | 'customOverrideValues' | 'onUpdate'> & {
  readonly allowCustomOverride?: boolean;
}

type FormValues = {
  overrideField?: LinkableValue;
  overrideCustom?: LinkableValue;
};

type AlternateFieldValue = Option<any> & {
  readonly value: AttributeRef.Entity | entity.Ref;
};

type Props = {
  /**
   * The label for the original field.
   */
  readonly label: string;
  /**
   * Options from the alternate linked field mappings.
   */
  readonly alternateFieldValues?: ReadonlyArray<AlternateFieldValue> | undefined;
  /**
   * Determines which type of input to use, if any, for a custom override for the value.
   */
  readonly customOverrideType?: 'input' | 'textarea' | MediaKind;
  /**
   * Options for custom override, if applicable. Currently to support LinkablePlacement.
   */
  readonly customOverrideValues?: ReadonlyArray<AlternateFieldValue>;
  /**
   * Allows this component to disable the parent components state which is allowing an override.
   */
  readonly onCancel: () => void;
  /**
   * Update the root form component state with the override value.
   */
  readonly onUpdate: (value?: LinkableValue) => void;
}

const initialFormValues: FormValues = {
  overrideField: undefined,
  overrideCustom: undefined,
}

const OverrideOptions = styled.fieldset`
  margin: ${space(4)} 0 ${space(1)} 0;
  padding: ${space(6)} ${space(15)} 0 ${space(5)};
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.grayL70};
  border-radius: 2px;
`

const Label = styled.span`
  color: ${({ theme }) => theme.color.grayD60};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const TabClassPrefix = 'react-tabs'
export const TabWrapper = styled.div`
  text-align: left;
  -webkit-tap-highlight-color: transparent;

  .is-hidden {
    display: none;
  }

  .${TabClassPrefix}__tab-list {
    display: inline-flex;
    margin: ${space(2)} 0 ${space(6)};
    padding: 0;
    border: 1px solid ${({ theme }) => theme.color.grayL70};
    border-radius: 2px;
  }

  .${TabClassPrefix}__tab {
    display: inline-flex;
    align-items: center;
    height: 48px;
    padding: 0 ${space(4)};
    border-right: 1px solid ${({ theme }) => theme.color.grayL70};
    font-size: ${({ theme }) => theme.font.size.base};
    font-weight: ${({ theme }) => theme.font.weight.light};
    cursor: pointer;
    list-style: none;

    &:last-of-type {
      border-right: 0;
    }

    &:focus {
      outline: 1px solid ${({ theme }) => theme.color.primary};
    }
  }

  .${TabClassPrefix}__tab--selected {
    background-color: ${({ theme }) => theme.color.white};
  }

  .${TabClassPrefix}__tab-panel {
    display: none;
  }

  .${TabClassPrefix}__tab-panel--selected {
    display: block;
  }
`

const OverrideDelete = styled.div`
  position: absolute;
  right: ${space(1)};
  top: ${space(3)};
`
/**
 * Forms which use linked fields should maintain the original value in the formbag, and an override can be maintained in local state. The override value will change on update, and also if the override is cancelled.
 *
 * When assembling the resulting entity to send the update to the API, make sure to check for an override value, and if it is `undefined` then use the original from the formbag.
 */
export const LinkableOverride = (props: Props) => {
  const {
    alternateFieldValues,
    customOverrideType,
    customOverrideValues,
    label,
    onCancel,
    onUpdate,
  } = props
  const bag = createFormBag(initialFormValues)
  const [ formBag, setFormBag ] = useState(bag)
  const [ media, setMedia ] = useState<Media.Entity | undefined>(undefined)
  const hasAlternateFieldsVisible = alternateFieldValues && alternateFieldValues.length
  const hasCustomOverrideEnabled =
    (customOverrideType && customOverrideType !== undefined) ||
    (customOverrideValues && customOverrideValues.length)
  const hasTabsVisible = hasCustomOverrideEnabled && hasAlternateFieldsVisible

  // update the parent value, updates when switching between tabs if visible.
  const updateParentValue = (index: number) => {
    if (!hasTabsVisible) {
      if (hasAlternateFieldsVisible) {
        onUpdate(formBag.values.overrideField)
      }
      else if (hasCustomOverrideEnabled) {
        onUpdate(formBag.values.overrideCustom)
      }
    }
    else {
      onUpdate(index === 0 ? formBag.values.overrideField : formBag.values.overrideCustom)
    }
  }

  // initialise the parent value.
  useEffect(() => updateParentValue(0), []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!hasAlternateFieldsVisible && !hasCustomOverrideEnabled) {
    throw new Invariant('Linkable field has no alternate options.')
  }

  return (
    <FormData
      bag={formBag}
      onUpdate={(event) => setFormBag(event.bag)}
    >
      <OverrideOptions>
        <OverrideDelete>
          <Button
            variant="subtle"
            isIcon={true}
            onClick={() => {
              onUpdate(undefined)
              onCancel()
            }}
          >
            <IconTrash />
          </Button>
        </OverrideDelete>

        {hasTabsVisible ? (<Label>Override by</Label>) : null}
        <TabWrapper>
          <Tabs onSelect={updateParentValue}>
            <TabList
              aria-hidden={!hasTabsVisible}
              className={!hasTabsVisible ? 'is-hidden' : undefined}
            >
              {hasAlternateFieldsVisible && (<Tab>Alternate field</Tab>)}
              {hasCustomOverrideEnabled && (<Tab>Custom value</Tab>)}
            </TabList>
            {hasAlternateFieldsVisible && alternateFieldValues ? (
              <TabPanel>
                <SelectRef<FormValues, 'overrideField'>
                  label="Alternate TMS field"
                  field="overrideField"
                  isSearchable={true}
                  options={alternateFieldValues}
                  description="Select from available TMS fields"
                  onChange={val => onUpdate(val)}
                  equalityCheck={(value, selectedValue) => value && selectedValue && value.attributeName === selectedValue.attributeName}
                />
              </TabPanel>
            ) : null}
            {hasCustomOverrideEnabled && (
              <TabPanel>
                {/** text input override. */}
                {!customOverrideValues &&
                (customOverrideType === 'input' || customOverrideType === 'textarea') && (
                  <Input
                    component={customOverrideType}
                    field="overrideCustom"
                    label={`Overridden “${label}”`}
                    onKeyUp={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target
                      onUpdate(value)
                    }}
                  />
                )}

                {/** media ref override. */}
                {
                  !customOverrideValues &&
                  customOverrideType &&
                  customOverrideType !== 'input' &&
                  customOverrideType !== 'textarea' &&
                  MediaKindValues.has(customOverrideType as any) && (
                    <MediaField
                      kind={customOverrideType}
                      label="Custom image"
                      mediaRef={media && Media.isRef(media.ref) ? media.ref : undefined}
                      onUpdate={(entity: Media.Entity | undefined) => {
                        setMedia(entity)
                        onUpdate(entity && Media.mustRef(entity.ref))
                      }}
                    />
                  )
                }

                {/** entity ref override from prefetched options, for linked placement. */}
                {(!customOverrideType && customOverrideValues) && (
                  <SelectRef<FormValues, 'overrideCustom'>
                    label={`Overridden “${label}”`}
                    field="overrideCustom"
                    isSearchable={true}
                    options={customOverrideValues}
                    onChange={val => onUpdate(val)}
                  />
                )}
              </TabPanel>
            )}
          </Tabs>
        </TabWrapper>
      </OverrideOptions>
    </FormData>
  )
}
