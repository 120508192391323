import React from 'react'

import * as entity from 'entity';

import { ColumnAccordion, ColumnAccordionItem } from 'components/ui/accordion';
import { BeaconFilterState } from 'domains/beacons/store';
import { Beacon } from 'generated/mos/beacon'
import { objectKeys } from 'helpers/core';

import { structurePreposition, Structures } from 'domains/structures/entities';
import { GlobalMapState } from 'domains/structures/store';

import { actionCreators } from '../../actions';
import { BeaconsData, VisibleScope, visibleScopes } from './beacons-data';
import { ItemList } from './item-list';


type Props = {
  readonly beaconsData: BeaconsData | undefined;
  readonly structures: Structures;

  readonly highlightedRef?: entity.BeaconRef;
  readonly setHighlightedRef: (v: entity.BeaconRef | undefined) => void;

  readonly focusBeaconOnMap: (v: Beacon.Entity) => void;
  readonly globalMap: GlobalMapState;
  readonly beaconFiltersUpdate: typeof actionCreators.beaconFiltersUpdate;
  readonly beaconFilters: BeaconFilterState;
};

export class BeaconListGroups extends React.Component<Props> {
  // only allow pre-expand accordion once per lifecycle
  private hasPreExpanded = false;

  public componentDidMount = () => this.preExpand();
  public componentDidUpdate = () => this.preExpand();

  // remove lastEdited ref on unmount as this data is now 'stale'
  public componentWillUnmount = () => this.props.beaconFiltersUpdate({ lastEdited: undefined });

  private preExpand = () => {
    // TODO once concept of highlightRef has been implemented make this open highlightRef section on update too
    const { beaconsData } = this.props;
    const { lastEdited } = this.props.beaconFilters;

    if (lastEdited && beaconsData && !this.hasPreExpanded) {

      for (const scope of objectKeys(beaconsData)) {
        const scopeContainsLastEdited = beaconsData[scope].filter((beacon) => beacon && beacon.ref && (beacon.ref.id === lastEdited.id)).length > 0;

        if (visibleScopes.includes(scope) && scopeContainsLastEdited) {
          this.hasPreExpanded = true;
          this.props.beaconFiltersUpdate({ expanded: { [scope]: true } });
        }
      }
    }
  }

  private focusBeaconInList = (item: Beacon.Entity) => {
    const { beaconsData } = this.props;
    const beaconInOtherScope =
    beaconsData && beaconsData.otherScopes.filter((beacon) => item && item.ref && beacon && beacon.ref && (beacon.ref.id === item.ref.id)).length > 0;
    if (beaconInOtherScope) {
      this.props.beaconFiltersUpdate({ expanded: { currentScope: true } });
    }
    // TODO scroll to Beacon
  }

  private onAccordionChange = (key: string) => {
    const expanded = { [key]: !this.props.beaconFilters.expanded[key] };
    this.props.beaconFiltersUpdate({ expanded });
  }

  private getTitles = (structures: Structures, globalMap: GlobalMapState) => {
    const { activeRef } = globalMap;
    const structure = structures && activeRef ? structures.index[entity.refToUrn(activeRef)] : undefined;
    const preposition = activeRef ? structurePreposition(activeRef) : '';

    return {
      currentScope: structure ? `Beacons ${preposition} ${structure.name}` : 'Positioned beacons',
      unpositioned: 'Unpositioned Beacons',
      otherScopes: structure ? `Beacons not ${preposition} ${structure.name}` : 'Other beacons',
    };
  }

  public render() {
    const { beaconFilters, globalMap, beaconsData, focusBeaconOnMap, highlightedRef, setHighlightedRef, structures } = this.props;

    if (!beaconsData || !beaconsData.allOrdered) {
      return null;
    }
    const titles = this.getTitles(structures, globalMap);

    const shared = {
      focusBeaconOnMap,
      highlightedRef,
      setHighlightedRef,
      focusBeaconInList: this.focusBeaconInList,
    };

    return (
      <ColumnAccordion openItems={beaconFilters.expanded} onChange={this.onAccordionChange}>
        <CollapsibleBeacons
          id="currentScope"
          title={titles.currentScope}
          items={beaconsData.currentScope}
          {...shared}
          />
        <CollapsibleBeacons
          id="unpositioned"
          title={titles.unpositioned}
          items={beaconsData.unpositioned}
          {...shared}
          />
        <CollapsibleBeacons
          id="otherScopes"
          title={titles.otherScopes}
          items={beaconsData.otherScopes}
          {...shared}
          />
      </ColumnAccordion>
    );
  }
}


type CollapsibleBeaconsProps = Pick<Props, 'focusBeaconOnMap' | 'highlightedRef' | 'setHighlightedRef'> & {
  readonly title: string;
  readonly items: ReadonlyArray<Beacon.Entity>;
  readonly id: VisibleScope;
  readonly focusBeaconInList: (i: Beacon.Entity) => void;
};

const CollapsibleBeacons = ({ id, items, title, focusBeaconOnMap, focusBeaconInList, setHighlightedRef, highlightedRef }: CollapsibleBeaconsProps) => {
  return (
    <ColumnAccordionItem id={id} label={items.length} title={title} innerHeight={items.length * 64}>
      <ItemList
        items={items}
        highlightedRef={highlightedRef}
        onClick={(ref: entity.Ref) => {
          const item = items.find((i) => i && i.ref && i.ref.id === ref.id);
          if (item) {
            focusBeaconOnMap(item);
            focusBeaconInList(item);
          }
        }}
        onMouseEnter={setHighlightedRef}
        onMouseLeave={() => setHighlightedRef(undefined) }
      />
    </ColumnAccordionItem>
  );
};
