// FIXME: once there is a unified domain for all 'locate' functionality, this should be moved there.

import { PointEntity } from 'components/cluster-map'

import { Beacon } from 'generated/mos/beacon'
import { PlacedPoint } from 'generated/mos/geo'
import { LevelPlacement, Placement, PositionPlacement } from 'generated/mos/placement'
import { POI } from 'generated/mos/poi'

import { assertNever } from 'helpers/core'
import { Position2D } from 'helpers/geo'

// firstLocation extracts the first position from a list of locations, if
// one is set. It is a hack (FIXME:!) introduced while migrating to webrpc;
// the backends expose multiple positions but the old BFF GraphQL schema
// hid that and only supported one.
const firstLocation = (
  entity: POI.Entity | undefined
): Placement.Entity | undefined => {
  if (!entity || !entity.locations) {
    return undefined;
  }
  if (entity.locations.length > 1) {
    // If this happens, the backend has been updated to return multiple
    // locations but the frontend hasn't been updated to support it yet.
    throw new Error('Multiple locations detected');
  }
  return entity.locations.length == 1 ? entity.locations[0] : undefined;
}

export const firstPlacedPointFromEntity = (entity: PointEntity): PlacedPoint.Entity | undefined => {
  switch (entity.type) {
    // beacons, containing a single point.
    case Beacon.refName: {
      return entity.position!
    }

    // pois, which can contain multiple points within the locations array.
    case POI.refName: {
      const location = firstLocation(entity)
      if (location &&
        location.placement &&
        location.placement.type === PositionPlacement.refName &&
        location.placement.position) {
          return location.placement.position
      }

      // If this happens, the backend has been updated to return location types
      // beyond placed points but the frontend hasn't been updated to support it yet.
      return undefined
    }

    default: {
      assertNever(entity)
    }
  }
}

export const firstLevelFromEntity = (entity: PointEntity): LevelPlacement.Entity | PlacedPoint.Entity | undefined => {
  switch (entity.type) {
    // beacons, containing a single point.
    case Beacon.refName: {
      return entity.position!
    }

    // pois, which can contain multiple points within the locations array.
    case POI.refName: {
      const location = firstLocation(entity)
      if (location &&
        location.placement &&
        location.placement.type === PositionPlacement.refName &&
        location.placement.position &&
        location.placement.position.levelRef) {
          return location.placement.position
      }

      // If this happens, the backend has been updated to return location types
      // beyond placed points but the frontend hasn't been updated to support it yet.
      return undefined
    }

    default: {
      assertNever(entity)
    }
  }
}

export const getCenter = (): Position2D => [115.8573, -31.9528]
