import React from 'react'

import { buttonReset } from 'components/ui/button'
import { IconCaret } from 'components/ui/icons';
import { space } from 'helpers/style'

import styled from 'styled';

const HEADER_HEIGHT_PX = 48;

type ContextType = {
  siblingCount: number;
  multipleOpen: boolean;
  onClick: (key: string) => void;
  openItems: Record<string, boolean>;
};
const AccordionContext = React.createContext<ContextType>({ openItems: {}, onClick: () => {}, multipleOpen: false, siblingCount: 0 });

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
`;

const ContentWrapper = styled.div<{ open: boolean; siblingCount: number; minHeight: number; innerHeight?: number }>`
  height: ${({ innerHeight }) => innerHeight ? `${innerHeight}px` : 'auto'};
  overflow: ${({ open }) => open ? 'auto' : 'hidden'};
  max-height: ${({ siblingCount, open }) => open ? `calc(100% - ${siblingCount * HEADER_HEIGHT_PX}px)` : 0};
  min-height: ${({ open, minHeight }) => (open && minHeight) ? `${minHeight}px` : 'initial'};
`;

const HeaderWrapper = styled.button`
  ${buttonReset}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  flex-shrink: 0;
  padding: 0 ${space(4)};
  &:focus, &:active {
    outline: none;

    &::after {
      display: none;
    }
  }
`;

const HeaderMain = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLabel = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.bold };
`;

const HeaderTitle = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  padding-left: ${space(3)};
`;


type Props = {
  openItems: Record<string, boolean>;
  onChange: (key: string) => void;
  children: any;
};
export const ColumnAccordion = ({ openItems, children, onChange }: Props) => {

  // each collapse item needs to know number of siblings to calc max-height
  const siblingCount = Array.isArray(children) ? children.length : 1;
  // and need to set a min height if multiple are open
  const multipleOpen = Object.values(openItems).filter((i) => i).length > 1;

  return (
    <AccordionContext.Provider value={{ siblingCount, openItems, multipleOpen, onClick: onChange }}>
      <AccordionWrapper>{children}</AccordionWrapper>
    </AccordionContext.Provider>
  );
};

type ItemProps = {
  title: string;
  label?: number | string;
  id: string;
  children: any;
  innerHeight?: number;
};
export const ColumnAccordionItem = ({ children, innerHeight, title, label, id }: ItemProps) => {
  return (
    <AccordionContext.Consumer>
      {(value: ContextType) => (
        <>
          <HeaderWrapper onClick={() => value.onClick(id)}>
            <HeaderMain>
              <IconCaret size="base" rotate={value.openItems[id] ? 0 : 270} />
              <HeaderTitle>{title}</HeaderTitle>
            </HeaderMain>
            {label !== undefined && <HeaderLabel>{label}</HeaderLabel>}
          </HeaderWrapper>
          <ContentWrapper
            open={!!value.openItems[id]}
            siblingCount={value.siblingCount}
            minHeight={value.multipleOpen ? 72 : 0}
            innerHeight={innerHeight}
            >
            {children}
          </ContentWrapper>
        </>
        )}
    </AccordionContext.Consumer>
  );
};
