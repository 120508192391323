// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { AttributeRef } from "generated/mos/entity";
import { Placement } from "generated/mos/placement";
import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureBigInt, ensureScalar } from "generated/webrpc";
import jsbi from "jsbi";

export namespace LinkableBoolValue {
  export const refName = "mos.linkable.LinkableBoolValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | boolean | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if ((uv === true || uv === false)) {
            obj["boolValue"] = uv;
          }
          else if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkableBoolValue.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkableBoolValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["boolValue"] !== undefined) {
          out["value"] = ensureScalar(ctx, v["boolValue"], "boolean");
        }
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace LinkableDoubleValue {
  export const refName = "mos.linkable.LinkableDoubleValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | number | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (typeof(uv) === "number") {
            obj["doubleValue"] = uv;
          }
          else if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkableDoubleValue.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkableDoubleValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["doubleValue"] !== undefined) {
          out["value"] = ensureScalar(ctx, v["doubleValue"], "number");
        }
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace LinkableInt64Value {
  export const refName = "mos.linkable.LinkableInt64Value" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | jsbi | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (uv instanceof jsbi) {
            obj["int64Value"] = uv.toString(10);
          }
          else if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkableInt64Value.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkableInt64Value");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["int64Value"] !== undefined) {
          out["value"] = ensureBigInt(ctx, v["int64Value"]);
        }
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace LinkableMediaValue {
  export const refName = "mos.linkable.LinkableMediaValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | { readonly typename: 'mos.media.Media', readonly id: string } | undefined | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else if (uv["typename"] !== undefined && uv["id"] !== undefined) {
            obj["mediaRef"] = uv;
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkableMediaValue.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkableMediaValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
        if (v["mediaRef"] !== undefined) {
          out["value"] = v["mediaRef"];
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace LinkablePlacementValue {
  export const refName = "mos.linkable.LinkablePlacementValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | Placement.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else if (uv.type == "mos.placement.Placement") {
            obj["placement"] = Placement.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkablePlacementValue.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkablePlacementValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
        if (v["placement"] !== undefined) {
          out["value"] = ctx.decode(Placement.codec, v["placement"], "placement");
        }
      }
      return out as any as Entity;
    }
  }();
}

export namespace LinkableStringValue {
  export const refName = "mos.linkable.LinkableStringValue" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly value: AttributeRef.Entity | string | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    value: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      {
        const uv: any = v.value;
        if (uv !== undefined) {
          if (uv.type == "mos.entity.AttributeRef") {
            obj["linkTargetFieldRef"] = AttributeRef.codec.encode(uv);
          }
          else if (typeof(uv) === "string") {
            obj["stringValue"] = uv;
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.linkable.LinkableStringValue.value");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.linkable.LinkableStringValue");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      {
        if (v["linkTargetFieldRef"] !== undefined) {
          out["value"] = ctx.decode(AttributeRef.codec, v["linkTargetFieldRef"], "linkTargetFieldRef");
        }
        if (v["stringValue"] !== undefined) {
          out["value"] = ensureScalar(ctx, v["stringValue"], "string");
        }
      }
      return out as any as Entity;
    }
  }();
}

