import { Effect, put, select, takeLeading } from 'redux-saga/effects'

import { Beacon } from 'generated/mos/beacon'
import {
  GetBeaconRequest,
  GetBeaconResponse,
  ListBeaconsRequest,
  ListBeaconsResponse,
} from 'generated/mos/beaconaccess'
import {
  CreateBeaconRequest,
  CreateBeaconResponse,
  DeleteBeaconRequest,
  DeleteBeaconResponse,
  UpdateBeaconRequest,
  UpdateBeaconResponse,
} from 'generated/mos/beaconmanagement'
import { unaryGRPC } from 'services/unary-grpc'
import { failureStatusMessage } from 'helpers/status'

import { BeaconsActions, actionCreators } from './actions'

const beaconsTakeLeading = <TActionKey extends keyof BeaconsActions>(
  pattern: TActionKey,
  worker: (action: BeaconsActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
};

const listBeaconsSaga = beaconsTakeLeading(
  'beaconsListBeaconsRequest',
  function* (action: BeaconsActions['beaconsListBeaconsRequest']) {
    try {
      const resp = yield* unaryGRPC<
        ListBeaconsRequest.Entity,
        ListBeaconsResponse.Entity
      >(
        'mos.beacon_access.BeaconAccess/ListBeacons',
        ListBeaconsRequest.defaults,
        ListBeaconsRequest.codec,
        ListBeaconsResponse.codec,
      );
      yield put(actionCreators.beaconsListBeaconsSuccess(resp.beacons));
    } catch (err) {
      yield put(actionCreators.beaconsListBeaconsFailure(failureStatusMessage(err.message)));
    }
  }
)

const getBeaconSaga = beaconsTakeLeading(
  'beaconsGetBeaconRequest',
  function* (action: BeaconsActions['beaconsGetBeaconRequest']) {
    try {
      const request: GetBeaconRequest.Entity = {
        type: GetBeaconRequest.refName,
        beaconRef: action.beaconRef,
      }
      const resp = yield* unaryGRPC<
        GetBeaconRequest.Entity,
        GetBeaconResponse.Entity
      >(
        'mos.beacon_access.BeaconAccess/GetBeacon',
        request,
        GetBeaconRequest.codec,
        GetBeaconResponse.codec,
      );
      yield put(actionCreators.beaconsGetBeaconSuccess(resp.beacon!));
    } catch (err) {
      yield put(actionCreators.beaconsGetBeaconFailure(
        action.beaconRef,
        failureStatusMessage(err.message)
      ));
    }
  }
)

const createBeaconSaga = beaconsTakeLeading(
  'beaconsCreateBeaconRequest',
  function* (action: BeaconsActions['beaconsCreateBeaconRequest']) {
    try {
      const resp = yield* unaryGRPC<
        CreateBeaconRequest.Entity,
        CreateBeaconResponse.Entity
      >(
        'mos.beacon_management.BeaconManagement/CreateBeacon',
        action.payload,
        CreateBeaconRequest.codec,
        CreateBeaconResponse.codec,
      );
      yield put(actionCreators.beaconsCreateBeaconSuccess({
        ref: Beacon.mustRef(resp.beacon!.ref),
        profileType: action.payload!.identificationProfile!.profile!.type,
      }));
    } catch (err) {
      yield put(actionCreators.beaconsCreateBeaconFailure(failureStatusMessage(err.message)));
    }
  }
)

const updateBeaconSaga = beaconsTakeLeading(
  'beaconsUpdateBeaconRequest',
  function* (action: BeaconsActions['beaconsUpdateBeaconRequest']) {
    const beaconRef = Beacon.mustRef(action.payload.beaconRef)
    try {
      const request: UpdateBeaconRequest.Entity = {
        ...action.payload,
        updateMask: {
          type: 'google.protobuf.FieldMask',
          fields: ['name', 'identificationProfile', 'position', 'snapToPosition'],
        }
      }
      const resp = yield* unaryGRPC<
        UpdateBeaconRequest.Entity,
        UpdateBeaconResponse.Entity
      >(
        'mos.beacon_management.BeaconManagement/UpdateBeacon',
        request,
        UpdateBeaconRequest.codec,
        UpdateBeaconResponse.codec,
      );

      yield put(actionCreators.beaconsUpdateBeaconSuccess(resp.beacon!));
    } catch (err) {
      yield put(actionCreators.beaconsUpdateBeaconFailure(
        beaconRef,
        failureStatusMessage(err.message)
      ));
    }
  }
)

const deleteBeaconSaga = beaconsTakeLeading(
  'beaconsDeleteBeaconRequest',
  function* (action: BeaconsActions['beaconsDeleteBeaconRequest']) {
    try {
      const request = {
        type: DeleteBeaconRequest.refName,
        beaconRef: action.beaconRef,
      }
      const resp = yield* unaryGRPC<
        DeleteBeaconRequest.Entity,
        DeleteBeaconResponse.Entity
      >(
        'mos.beacon_management.BeaconManagement/DeleteBeacon',
        request,
        DeleteBeaconRequest.codec,
        DeleteBeaconResponse.codec,
      );

      yield put(actionCreators.beaconsDeleteBeaconSuccess(Beacon.mustRef(resp.ref)));
      // reload to be safe.
      yield put(actionCreators.beaconsListBeaconsRequest());
    } catch (err) {
      yield put(actionCreators.beaconsDeleteBeaconFailure(failureStatusMessage(err.message)));
    }
  }
)

export const beaconsRootSaga: ReadonlyArray<Effect> = [
  listBeaconsSaga,
  getBeaconSaga,
  createBeaconSaga,
  updateBeaconSaga,
  deleteBeaconSaga,
]
