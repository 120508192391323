import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { StatusGuard } from 'components/ui/status-guard';
import { connect } from 'containers/store';
import { Org } from 'generated/mos/user';
import { RequestNewPasswordRequest } from 'generated/mos/userauthentication';
import { pick } from 'helpers/core';
import { isEmail } from 'helpers/form';
import { Status } from 'helpers/status';
import styled from 'styled'

import { accountBindableActionCreators } from 'domains/account/actions';
import { DataStatus } from 'domains/account/store';

const WideButton = styled(Button)`
  width: 100%;
`

type ConnectedProps = {
  readonly requestNewPasswordStatus: DataStatus<undefined>;
};

type ActionProps = Pick<typeof accountBindableActionCreators, 'accountRequestNewPasswordRequest'>;

type DirectProps = {
  readonly orgRef: Org.Ref;
  readonly onSuccess: () => void;
};

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly email: string;
};


class ForgotPasswordFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ email: '' }),
    };
  }

  public componentDidUpdate() {
    if (this.props.requestNewPasswordStatus.status === Status.Ready) {
      this.props.onSuccess()
    }
  }

  public validate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const bag = validateFormBag(this.state.bag, this.validate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.accountRequestNewPasswordRequest({
      ...RequestNewPasswordRequest.defaults,
      orgRef: this.props.orgRef,
      email: bag.values.email,
    });
  }

  public render() {
    const { errors, valid, touched } = this.state.bag;

    const loading = this.props.requestNewPasswordStatus.status === Status.Updating;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.validate}>
          <StatusGuard status={this.props.requestNewPasswordStatus} mode="form">
            <Input
              label="Email"
              field="email"
              type="email"
              error={touched.email ? errors.email : undefined}
            />
          </StatusGuard>

          <WideButton type="submit" isDisabled={!valid}>
            {loading ? 'Requesting...' : 'Request password reset'}
          </WideButton>
        </FormData>
      </form>
    );
  }
}


export const ForgotPasswordForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'requestNewPasswordStatus'),
  pick(accountBindableActionCreators, 'accountRequestNewPasswordRequest'),
)(ForgotPasswordFormView);
