import React, { useEffect, useRef, useState } from 'react'
import { createFormBag, FormBag, FormData } from 'react-formage'

import { Button, ButtonGroup } from 'components/ui/button'
import { Fieldset } from 'components/ui/fieldset'
import { FooterEditEntity } from 'components/ui/footer'
import { Input } from 'components/ui/input'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { IconLanguages, IconTrash } from 'components/ui/icons'
import { MultiSelect } from 'components/ui/select'
import { StatusGuard } from 'components/ui/status-guard'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'
import { spacesBindableActionCreators } from 'domains/spaces/actions'


import { areObjectsEqual, pick } from 'helpers/core'
import { statusSelector, Status } from 'helpers/status'
import { space } from 'helpers/style'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import styled from 'styled'
import { Stop, UpdateStopDirectionsRequest } from 'domains/org/wam/types'
import { Space } from 'generated/mos/structure'
import { SpacesStore } from 'domains/spaces/store'
import { Confirm } from 'components/ui/modal'


const DeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin: ${space(1)};

  &:focus {
    position: absolute;
  }
`

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: -${space(6)};
`

const Form = styled.form`
  width: 60%;
`

const NoDirections = styled.span`
  padding-left: ${space(5)};
`

type FormValues = {
  id: string
  title: string
  directionsTitle: string | undefined
  directionsAudioFilename: string
  directionsImageFilename: string
  directionsImageAltText: string | undefined
  directionsTranscript: string | undefined
  directionsSpaces: ReadonlyArray<Space.Ref>
  directionsAudioDuration: number
}

type ActionProps = Pick<WamActionCreators, 'wamGetStopRequest' | 'wamDeleteStopDirectionsRequest' | 'wamUpdateStopDirectionsRequest'> &
  Pick<typeof spacesBindableActionCreators, 'spacesRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['stops'], 'stopUpdate' | 'stopDirectionsDelete' | 'stopDirectionsUpdate'> &
  Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  tourId: string
  stopId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const StopUpdatePageView = (props: Props) => {
  const {
    historyPush,
    projectId,
    tourId,
    spacesList,
    spacesRequest,
    stopId,
    stopUpdate,
    stopDirectionsDelete,
    stopDirectionsUpdate,
    toastNotification,
    wamGetStopRequest,
    wamDeleteStopDirectionsRequest,
    wamUpdateStopDirectionsRequest,
  } = props

  const data = statusSelector.data(stopUpdate)
  const spacesListData = statusSelector.data(spacesList)

  const [formBag, setFormBag] = useState<FormBag<FormValues> | undefined>(undefined)
  const [deleteDirectionsModal, setDeleteDirectionsModal] = useState(false)

  const hasSubmitted = useRef<boolean>(false)

  useEffect(() => {
    spacesRequest()
    wamGetStopRequest({
      ecmsTourId: tourId,
      ecmsStopId: stopId
    })
  }, [])

  useEffect(() => {
    if (stopUpdate.status === Status.Ready && initialFormValues) {
      setFormBag(createFormBag(initialFormValues))
    }
  }, [stopUpdate])

  // delete Directions toast notifications
  useEffect(() => {
    if (stopDirectionsDelete.status === Status.Complete) {
      wamGetStopRequest({
        ecmsTourId: tourId,
        ecmsStopId: stopId
      })
      toastNotification({
        type: 'success',
        text: 'Stop Directions deleted successfully.',
      })
    }
    if (stopDirectionsDelete.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Stop Directions could not be deleted',
      })
    }

  }, [stopDirectionsDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  // update Directions toast notifications
  useEffect(() => {
    if (stopDirectionsUpdate.status === Status.Ready && hasSubmitted.current) {
      toastNotification({
        type: 'success',
        text: 'Stop Directions updated successfully.',
      })
      hasSubmitted.current = false
    }

    if (stopDirectionsUpdate.status === Status.Failed && hasSubmitted.current) {
      toastNotification({
        type: 'error',
        text: 'Stop Directions could not be updated',
      })
      hasSubmitted.current = false
    }

  }, [stopDirectionsUpdate])

  const formValues = (data: Stop.Entity) => {
    const { directions } = data
    const directionValues = directions && {
      directionsTitle: directions.title && directions.title.en,
      directionsAudioFilename: directions.audioFilename,
      directionsImageFilename: directions.imageFilename,
      directionsImageAltText: directions.imageAltText && directions.imageAltText.en,
      directionsTranscript: directions.transcript && directions.transcript.en,
      directionsAudioDuration: directions.audioDurationSeconds,
      directionsSpaces: directions.destinationSpaces,
    }

    return {
      id: data.ecmsId,
      title: data.title,
      ...directionValues,
    }
  }
  const initialFormValues: FormValues | undefined =
    data && data.ecmsId === stopId ? formValues(data) : undefined

  const modified = initialFormValues && data && formBag && !areObjectsEqual(initialFormValues, formBag.values)

  const onDeleteDirections = () => {
    wamDeleteStopDirectionsRequest({
      ecmsTourId: tourId,
      ecmsStopId: stopId
    })
    setDeleteDirectionsModal(false)
  }

  const onSaveHandler = () => {
    if (formBag && data && data.directions) {
      const {
        directionsTitle,
        directionsImageAltText,
        directionsSpaces
      } = formBag.values

      const updateDirectionsPayload: UpdateStopDirectionsRequest = {
        ecmsTourId: tourId,
        ecmsStopId: stopId,
        title: {
          en: directionsTitle
        },
        imageAltText: {
          en: directionsImageAltText
        },
        destinationSpaces: directionsSpaces
      }

      hasSubmitted.current = true
      wamUpdateStopDirectionsRequest(updateDirectionsPayload)
    }

  }

  return (
    <>
      {deleteDirectionsModal &&
        <Confirm
          title="Delete Directions?"
          onClose={() => setDeleteDirectionsModal(false)}
          message="Are you sure you want to permanently delete Directions?"
          confirmLabel="Delete"
          onConfirm={onDeleteDirections}
          danger
        />
      }
      <LayoutListOrFormPage
        nav={() => (
          <PrimaryNavigationEditEntity
            title="Edit Stop"
            onConfirm={modified ? () => historyPush(`/project/${projectId}/tour/${tourId}`) : undefined}
            navBackRoute={`/project/${projectId}/tour/${tourId}`}
          />
        )}
        subnav={() => null}
        title={data ? data.title : ''}
        content={() => (
          <>
            {data && data.directions &&
              <StyledButtonGroup>
                <Button
                  variant="subtle"
                  onClick={() => historyPush(`/project/${projectId}/tour/${tourId}/stop/${stopId}/translations`)}
                >
                  <IconLanguages />
                  Manage Translations
                </Button>
              </StyledButtonGroup>
            }
            <StatusGuard status={stopUpdate}>
              {formBag && data && spacesListData &&
                <Form
                  noValidate
                  onSubmit={(event) => {
                    event.preventDefault()
                  }}
                >
                  <FormData
                    bag={formBag}
                    onUpdate={(event: { bag: any }) => {
                      setFormBag(event.bag)
                    }}
                    validate={() => ({})}
                  >
                    <Fieldset legend="Details">
                      <Input
                        isDisabled
                        label="ID"
                        field="id"
                      />
                      <Input
                        isDisabled
                        label="Title"
                        field="title"
                      />
                    </Fieldset>
                    <Fieldset legend="Directions">
                      {data.directions ?
                        <>
                          <DeleteButton
                            variant="subtle"
                            isIcon={true}
                            onClick={() => setDeleteDirectionsModal(true)}
                          >
                            <IconTrash />
                          </DeleteButton>
                          <Input label="Title" field="directionsTitle" />
                          <Input isDisabled label="Audio Asset URL" field="directionsAudioFilename" />
                          <Input isDisabled label="Duration" field="directionsAudioDuration" description="Time in seconds" />
                          <Input isDisabled label="Transcript" field="directionsTranscript" />
                          <MultiSelect<FormValues, 'directionsSpaces'>
                            label="Destination space(s)"
                            field="directionsSpaces"
                            options={spacesListData.map(space => ({
                              label: space.displayName || space.name,
                              value: Space.mustRef(space.ref)
                            }))}
                          />
                          <Input isDisabled label="Image" field="directionsImageFilename" />
                          <Input label="Image Alt-Text" field="directionsImageAltText" />
                        </> :
                        <NoDirections>No directions associated with this stop.</NoDirections>
                      }
                    </Fieldset>
                  </FormData>
                </Form>
              }
            </StatusGuard>
          </>
        )}
        footer={() => (
          <FooterEditEntity
            entity={stopUpdate}
            modified={modified}
            onCancel={() => historyPush(`/project/${projectId}/tour/${tourId}/sound-effect-zones`)}
            onSave={onSaveHandler}
          />
        )}
      />
    </>
  )
}

export const StopUpdatePage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.stops, 'stopUpdate', 'stopDirectionsDelete', 'stopDirectionsUpdate'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(actionCreators, 'wamGetStopRequest', 'wamDeleteStopDirectionsRequest', 'wamUpdateStopDirectionsRequest'),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(StopUpdatePageView)
