import React from 'react'
import { Redirect } from 'react-router';
import { Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';

import { PrimaryNavigation } from 'components/ui/primary-navigation';

import { AccountAppState } from 'domains/account/store'

// universal
import { AssignNewPasswordPage } from 'domains/account/components/page-assign-new-password';
import { ForgotPasswordPage } from 'domains/account/components/page-forgot-password';
import { Dashboard } from 'pages/dashboard';
import { Error404Page } from 'pages/error/404';
import { SignOutPage } from 'pages/sign-out';

// logged out
import { SignInPage } from 'pages/sign-in';
import { SignUpPage } from 'pages/sign-up';

// logged in
import { BeaconsRoutes } from 'domains/beacons/components/routes';
import { SpacesRoutes } from 'domains/spaces/components/routes';
import { StructuresRoutes } from 'domains/structures/components/routes';

// org specific.
import { wamRoutes } from 'domains/org/wam/routes'

const log = (...v: any[]) => console.log('router', ...v);


// Danger: there's a footgun with RouteProps.Component: the following will not
// work, it will remount the whole thing in circumstances you wouldn't expect:
//
//  const BadRoutesComponent = () => (
//    <Switch>
//     <Route
//       exact path="/locate/spaces/"
//       component={() => <AlwaysRemountedComponent />} />
//    </Switch>
//  );
//
// The value for the 'component' prop should be hoisted out of BadRoutesComponent.
//
// For this reason we have disabled the react/display-name eslint rule for the
// rest of this file. It is normally a valuable rule that adds debugging info.
/* eslint-disable react/display-name */

export interface RouteConfig extends RouteProps {
  // Don't use component at all, use render. The footgun example in the interface's
  // top-level comment is just one example.
  // https://github.com/ReactTraining/react-router/issues/4105
  readonly component?: never;
}

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
// https://reacttraining.com/react-router/web/example/route-config
export const RouteWithSubRoutes = (route: any) => (
  <Route
    path={route.path}
    render={(props) => {
      if (route.component) {
        // pass the sub-routes down to keep nesting
        return <route.component {...props} routes={route.routes} />;
      } else {
        return route.render(props);
      }
    }}
  />
);

const universalRoutes: ReadonlyArray<RouteConfig> = [
  // sign-in must be universal because routes re-render on user change
  {
    path: '/sign-in',
    exact: true,
    render: (routeProps: RouteComponentProps<{}>) => (
      <SignInPage onComplete={() => routeProps.history.push('/')} />
    ),
  },

  {
    path: '/sign-out',
    exact: true,
    render: () => <SignOutPage />,
  },

  {
    path: '/forgot-password',
    exact: true,
    render: (routeProps: RouteComponentProps<{}>) => (
      <ForgotPasswordPage onComplete={() => routeProps.history.push('/')} />
    ),
  },

  {
    // FIXME: The BFF and backend name all of these things AssignNewPassword, but the
    // frontend URL is reset-password, which is referenced in mos-identify-service's
    // settings.py. This naming split should be resolved somehow.
    path: '/reset-password/:userId?/:token?', // /reset-password/<user-id>/<token>)
    exact: true,
    render: (routeProps: RouteComponentProps<{ userId: string; token: string }>) => (
      <AssignNewPasswordPage
        token={routeProps.match.params.token} userId={routeProps.match.params.userId}
        onComplete={() => routeProps.history.push('/')} />
    ),
  },

  {
    path: '/404',
    exact: true,
    render: () => <Error404Page />,
  },
];

const notLoggedInRoutes: ReadonlyArray<RouteConfig> = [
  {
    path: '/sign-up/:userId/:token', // http://{org_slug}.artpro.io/sign-up/{uuid}/{token}
    exact: true,
    render: (routeProps: RouteComponentProps<{ userId: string; token: string }>) => (
      <SignUpPage
        token={routeProps.match.params.token} userId={routeProps.match.params.userId}
        onComplete={() => routeProps.history.push('/')}
      />
    ),
  },
];

const locateBaseUrl = '/locate';
export const LocateHeader = () => () => (
  <PrimaryNavigation
    page={{ title: 'Site Management', url: locateBaseUrl }}
  />
);

const loggedInRoutes: ReadonlyArray<RouteConfig> = [
  {
    path: '/',
    exact: true,
    render: () => <Redirect to={{ pathname: '/locate' }} />,
  },
  {
    path: '/locate',
    exact: true,
    render: () => <Dashboard />,
  },
  {
    path: '/locate/beacons/',
    render: () => <BeaconsRoutes />,
  },
  {
    path: '/locate/structures/',
    render: () => <StructuresRoutes />,
  },
  {
    path: '/locate/spaces/',
    render: () => <SpacesRoutes />,
  },
];

type Props = Pick<AccountAppState, 'session'>

export const LoggedInRoutes = (props: Props) => {
  const routes = [
    // wam(org) specific.
    ...wamRoutes,

    // general.
    ...universalRoutes,
    ...loggedInRoutes,

    // catch all - must be last.
    {
      render: () => {
        log(`no match at "${window.location.pathname}"`);
        return <Route render={() => <Error404Page /> } />;
      },
    },
  ]
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} {...props} />
      ))}
    </Switch>
  );
  
};

export const NotLoggedInRoutes = (props: Props) => {
  const routes = [
    // general.
    ...universalRoutes,
    ...notLoggedInRoutes,

    // catch all - must be last.
    {
      render: () => {
        log(`no match at "${window.location.pathname}", redirecting to "/sign-in"`);
        return <Redirect to="/sign-in" />;
      },
    },
  ]

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} {...props} />
      ))}
    </Switch>
  );
};
