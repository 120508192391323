/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaEmpty = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M10 11.41l4 4 4-3.99 3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l3 2.99 4-4zM19 3c1.1 0 2 .9 2 2v6.59l-3-3.01-4 4.01-4-4-4 4-3-3.01V5c0-1.1.9-2 2-2h14z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaEmptyString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M10 11.41l4 4 4-3.99 3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l3 2.99 4-4zM19 3c1.1 0 2 .9 2 2v6.59l-3-3.01-4 4.01-4-4-4 4-3-3.01V5c0-1.1.9-2 2-2h14z"  fillRule="nonzero" />
  </svg>`

IconMediaEmpty.displayName = 'IconMediaEmpty'
