/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaImage = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-4.5 9L11 16.51 8.5 13.5 5 18h14l-4.5-6z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaImageString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm-4.5 9L11 16.51 8.5 13.5 5 18h14l-4.5-6z"  fillRule="nonzero" />
  </svg>`

IconMediaImage.displayName = 'IconMediaImage'
