import { SharedAppState } from 'containers/shared';
import { Space, SpaceType } from 'generated/mos/structure';
import { DataStatus as GenericDataStatus, EntityStatus as GenericEntityStatus, Status } from 'helpers/status';


type SpacesErrorCode = string;

export type DataStatus<T> = GenericDataStatus<T, SpacesErrorCode>;
export type EntityStatus<T extends object, TRefType=Space.Ref["typename"]> = GenericEntityStatus<T, SpacesErrorCode, TRefType>;


export type SpaceTypes = {
  readonly spaceTypes: ReadonlyArray<SpaceType.Entity>;

  readonly index: Readonly<{ [refUrn: string]: SpaceType.Entity | undefined }>;
};


export interface SpacesFilterState {
  readonly search?: string;

  // FIXME: Docs dead-end. Where do I look to find out what keys I might find in here?
  readonly expanded: Record<string, boolean>;

  readonly lastEdited?: Space.Ref;
}

export interface SpacesAppState {
  readonly spacesFilters: SpacesFilterState;

  readonly spaceCreate: DataStatus<Space.Ref>;
  readonly spacesList: DataStatus<ReadonlyArray<Space.Entity>>;

  // spaceEdit contains the result of retrieving a single beacon paceor the
  // purpose of editing it. It does not represent the intermediate state.
  readonly spaceEdit: EntityStatus<Space.Entity>;

  readonly spaceTypes: DataStatus<SpaceTypes>;
}

export type SpacesStore = {
  readonly spaces: SpacesAppState;
  readonly shared: SharedAppState;
};


export const initialSpacesAppState: SpacesAppState = {
  spacesFilters: { search: '', expanded: {} },
  spaceCreate: { status: Status.Idle },
  spaceEdit: { status: Status.Idle },
  spacesList: { status: Status.Idle },
  spaceTypes: { status: Status.Idle },
};

