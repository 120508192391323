import React, { PureComponent } from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage'

import { Button, ButtonGroup } from 'components/ui/button'
import { Fieldset, FieldSection } from 'components/ui/fieldset'
import { Input } from 'components/ui/input'
import { Modal } from 'components/ui/modal'
import { StatusGuard } from 'components/ui/status-guard'

import { sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { Language } from 'generated/mos/i18n'
import { Attachment, Media } from 'generated/mos/media'

import { areObjectsEqual, assertNever, pick } from 'helpers/core'
import { defaultLanguage, parseLanguage } from 'helpers/i18n'
import { getAttachment } from 'helpers/media'
import { timestampToFormattedString } from 'helpers/timestamps'
import { Status } from 'helpers/status'
import { space } from 'helpers/style'

import styled from 'styled'
import { mediaQueries } from 'styled/core'

import { AttachmentField } from '../attachment-field'
import { actionCreators } from '../../actions'
import { DataStatus } from '../../store'

const Filename = styled.div`
  margin-top: ${space(-1)};
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const Main = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.hand} {
    flex-direction: row;
  }
`

const Section = styled.section<{ hasAside: boolean }>`
  ${mediaQueries.hand} {
    width: 61%;
  }
`

const Aside = styled.aside`
  order: -1;
  padding-bottom: ${space(6)};

  ${mediaQueries.hand} {
    order: 0;
    padding-bottom: 0;
    padding-left: ${space(15)};
    width: 39%;
  }

  ${mediaQueries.lap} {
    padding-left: ${space(19)};
  }
`

const DescriptionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: ${space(4)};
  border: 1px solid ${({ theme }) => theme.color.grayL70};
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const MetaTitle = styled.dt`
  padding-bottom: ${space(1)};
  width: 90px;
`

const MetaValue = styled.dd`
  margin-left: 0;
  padding-bottom: ${space(1)};
  width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
`

type ConnectedProps = {
  readonly attachments: { [key: string]: DataStatus<Attachment.Ref> };
  readonly mediaUpdate: DataStatus<Media.Entity>;
}

type DirectProps = {
  readonly media: Media.Entity;
  readonly onClose: () => void;
  readonly onSave: (entity: Media.Entity) => void;
}

type ActionProps =
  Pick<typeof actionCreators, 'mediaUpdateMediaRequest' | 'mediaUpdateMediaReset' | 'mediaUpdateAttachmentReset'> &
  Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = ConnectedProps & DirectProps & ActionProps;

type FormValues = {
  readonly internalNotes: string;
  readonly title: string;
};

type State = {
  readonly bag: FormBag<FormValues>;
  readonly mediaItem: Media.Entity;
}

class MediaEditComponent extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      bag: createFormBag({
        internalNotes: props.media.internalNotes,
        title: props.media.title,
      }),
      mediaItem: {
        ...Media.defaults,
        ...props.media,
      },
    }
  }

  public componentDidUpdate() {
    const {
      attachments,
      media,
      mediaUpdate,
      mediaUpdateAttachmentReset,
      mediaUpdateMediaReset,
      onClose,
      onSave,
      toastNotification,
    } = this.props

    // if updating a media entity in the dmm was successful...
    if (mediaUpdate.status === Status.Ready) {
      onSave(mediaUpdate.data)
      mediaUpdateMediaReset()
      onClose()
    }

    if (mediaUpdate.status === Status.Failed) {
      toastNotification({ type: 'error', text: 'There was a problem saving your changes.' })
      mediaUpdateMediaReset()
    }

    const attachmentStatus = Media.isRef(media.ref) ? attachments[media.ref.id] : undefined
    if (attachmentStatus && attachmentStatus.status === Status.Failed) {
      toastNotification({ type: 'error', text: 'There was a problem updating your attachment.' })
      mediaUpdateAttachmentReset(Media.mustRef(media.ref))
    }
  }

  private onClose = () => {
    this.props.onClose()
  }

  private onFormUpdate = (event: FormUpdateEvent<FormValues>) => {
    this.setState({
      bag: event.bag
    })
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {}
    return errors
  }

  private renderAttachments = (media: Media.Entity) => {
    const language: Language.Entity = media.language || defaultLanguage()

    // check if any attachment changes for the media entity are currently in-flight.
    const { attachments } = this.props
    const attachmentStatus = attachments[media.ref!.id]

    // this changing to false when the status updates to `Ready` will trigger the attachment
    //   field instance to stop displaying it's loader styles.
    const isUpdating = attachmentStatus && attachmentStatus.status === Status.Loading

    const thumbnailAttachment = getAttachment(media.attachments, 'MEDIA_INTENT_THUMBNAIL', language)
    const transcriptAttachment = getAttachment(media.attachments, 'MEDIA_INTENT_TRANSCRIPT', language)

    // only render attachments specific to the exact media kind.
    switch (media.kind) {
      case 'MEDIA_KIND_RASTER_IMAGE': {
        return null
      }
      case 'MEDIA_KIND_AUDIO': {
        return (
          <>
            <AttachmentField
              label="Thumbnail image"
              intent="MEDIA_INTENT_THUMBNAIL"
              attachment={thumbnailAttachment}
              mediaRef={Media.mustRef(media.ref)}
              language={language}
              isUpdating={isUpdating}
            />
            <FieldSection />
            <AttachmentField
              label="Transcript"
              intent="MEDIA_INTENT_TRANSCRIPT"
              attachment={transcriptAttachment}
              mediaRef={Media.mustRef(media.ref)}
              language={language}
              isUpdating={isUpdating}
            />
          </>
        )
      }
      case 'MEDIA_KIND_VIDEO': {
        return (
          <AttachmentField
            label="Thumbnail image"
            intent="MEDIA_INTENT_THUMBNAIL"
            attachment={thumbnailAttachment}
            mediaRef={Media.mustRef(media.ref)}
            language={language}
            isUpdating={isUpdating}
          />
        )
      }
      // these cases have not yet been specified.
      case 'MEDIA_KIND_UNSPECIFIED':
      case 'MEDIA_KIND_BINARY':
      case 'MEDIA_KIND_VECTOR_IMAGE':
      case 'MEDIA_KIND_PDF': {
        return null
      }
      default: {
        assertNever(media.kind)
      }
    }
  }

  private save = (event: React.FormEvent) => {
    // Prevents the user hitting "enter".
    if (event.type === "submit"){
      return
    }

    const bag = validateFormBag(this.state.bag, this.onFormValidate)

    if (!bag.valid) {
      return
    }

    // updates media item with new form values to make request
    this.setState({
      bag,
      mediaItem: {
        ...this.state.mediaItem,
        ...this.state.bag.values,
      }
    }, () => {
      this.props.mediaUpdateMediaRequest(this.state.mediaItem)
    })
  }

  private modified = () => {
    // creates initial form data so it can be compared against new form values
    const initialFormData = {
      title: this.state.mediaItem.title,
      internalNotes: this.state.mediaItem.internalNotes
    }

    return areObjectsEqual(initialFormData, this.state.bag.values)
  }

  public render() {
    const { media } = this.props
    const { bag: { errors, touched, values } } = this.state
    const loading = this.props.mediaUpdate.status === Status.Loading

    const renderFooter = () => (
      <ButtonGroup>
        <Button
          type="button"
          key="cancel"
          appearance="secondary"
          onClick={(event) => { event.preventDefault(); this.onClose(); }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={(event) => this.save(event)}
          isDisabled={this.modified()}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </ButtonGroup>
    )

    const attachments = this.renderAttachments(media)

    return (
      <Modal
        onClose={this.onClose}
        header={
          <>
            <h3>Edit asset</h3>
            <Filename>Assets &rsaquo; {media.uploadedFilename}</Filename>
          </>
        }
        footer={renderFooter()}
        size="large"
      >
        <form noValidate onSubmit={(event) => { event.preventDefault(); this.save(event); }}>
          <StatusGuard status={this.props.mediaUpdate} mode="form">
            <FormData
              bag={this.state.bag}
              onUpdate={this.onFormUpdate}
              validate={this.onFormValidate}
            >
              <Main>
                <Section hasAside={true}>
                  <Fieldset legend="Details">
                    <Input
                      label="Title"
                      labelSupportingText="(internal only)"
                      field="title"
                      error={touched.title ? errors.title : undefined}
                    />
                    <Input
                      label="Notes"
                      labelSupportingText="(internal only)"
                      field="internalNotes"
                      component="textarea"
                      error={touched.internalNotes ? errors.internalNotes : undefined}
                    />
                  </Fieldset>
                  {attachments && (
                    <Fieldset legend="Attachments">
                      {attachments}
                    </Fieldset>
                  )}
                </Section>

                <Aside>
                  <DescriptionList>
                    <MetaTitle>Filename:</MetaTitle>
                    <MetaValue>{media.uploadedFilename}</MetaValue>

                    {media.metadata && media.metadata.modifiedAt && (
                      <>
                        <MetaTitle>Modified:</MetaTitle>
                        <MetaValue>
                          {timestampToFormattedString(media.metadata.modifiedAt.value)}
                        </MetaValue>
                      </>
                    )}

                    {media.metadata && media.metadata.createdAt && (
                      <>
                        <MetaTitle>Created:</MetaTitle>
                        <MetaValue>
                          {timestampToFormattedString(media.metadata.createdAt.value)}
                        </MetaValue>
                      </>
                    )}

                    {media.language && (
                      <>
                        <MetaTitle>Language:</MetaTitle>
                        <MetaValue>{parseLanguage(media.language)}</MetaValue>
                      </>
                    )}
                  </DescriptionList>
                </Aside>
              </Main>
            </FormData>
          </StatusGuard>
        </form>
      </Modal>
    )
  }
}

export const MediaEdit = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    attachments: store.media.attachments,
    mediaUpdate: store.media.mediaUpdate,
  }),
  {
    ...pick(actionCreators, 'mediaUpdateMediaRequest', 'mediaUpdateMediaReset', 'mediaUpdateAttachmentReset'),
    ...pick(sharedActionCreators, 'toastNotification'),
  }
)(MediaEditComponent)
