/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMonitor = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M2 3.5a2 2 0 00-2 2v11a2 2 0 002 2h6v1a1 1 0 001 1h6a1 1 0 001-1v-1h6a2 2 0 002-2v-11a2 2 0 00-2-2zm0 2h20v11H2z" />
  </Icon>
)

// for use with mapbox addables.
export const IconMonitorString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M2 3.5a2 2 0 00-2 2v11a2 2 0 002 2h6v1a1 1 0 001 1h6a1 1 0 001-1v-1h6a2 2 0 002-2v-11a2 2 0 00-2-2zm0 2h20v11H2z" />
  </svg>`

IconMonitor.displayName = 'IconMonitor'
