// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

export const CodeValues = new Set([
  'OK' as const,
  'CANCELLED' as const,
  'UNKNOWN' as const,
  'INVALID_ARGUMENT' as const,
  'DEADLINE_EXCEEDED' as const,
  'NOT_FOUND' as const,
  'ALREADY_EXISTS' as const,
  'PERMISSION_DENIED' as const,
  'UNAUTHENTICATED' as const,
  'RESOURCE_EXHAUSTED' as const,
  'FAILED_PRECONDITION' as const,
  'ABORTED' as const,
  'OUT_OF_RANGE' as const,
  'UNIMPLEMENTED' as const,
  'INTERNAL' as const,
  'UNAVAILABLE' as const,
  'DATA_LOSS' as const,
]);
export type Code = typeof CodeValues extends Set<infer U> ? U : never;

