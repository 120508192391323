import React, { useEffect } from 'react'

import { Button, ButtonGroup } from 'components/ui/button'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SectionHeader } from 'components/ui/section-header'
import { UploadAssets } from '../upload-assets'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'

import { statusSelector } from 'helpers/status'
import { pick, pluralise } from 'helpers/core'
import { space } from 'helpers/style'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { ListItem } from './list-item'
import { getSubnav } from '../subnav'

import styled from 'styled'

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: -${space(6)};
  margin-bottom: ${space(3)};
`;

type ActionProps = Pick<WamActionCreators, 'wamListGuidedToursRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['tours'], 'list'>

type DirectProps = {
  projectId: string
  historyPush: (id: string) => void
  navBackRoute: string
  title: string
}
type Props = ActionProps & ConnectedProps & DirectProps

const ToursPageView = (props: Props) => {
  const { wamListGuidedToursRequest, list, projectId, historyPush, navBackRoute } = props
  const data = statusSelector.data(list)

  useEffect(() => {
    wamListGuidedToursRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LayoutListOrFormPage
      nav={() => <PrimaryNavigationEditEntity title="Edit Project" navBackRoute={navBackRoute} darkTheme />}
      title={'WAM'}
      toolbar={() => (
        <ButtonGroup>
          {/* <ValidationFailuresList projectId={projectId} /> */}
          <Button
            appearance="primary"
            onClick={() => props.historyPush(`/project/${projectId}/publishing`)}
          >
            Publish
          </Button>
        </ButtonGroup>
      )}
      subnav={() => <SecondaryNavigation navItems={getSubnav(projectId)} />}
      content={() => (
        <>
          <ButtonRow>
            <ButtonGroup>
              <UploadAssets onSuccessHandler={() => wamListGuidedToursRequest()} />
            </ButtonGroup>
          </ButtonRow>
          <StatusGuard status={list}>
            <SectionHeader heading={`${data ? data.length : 0} ${pluralise(data ? data.length : 0, 'tour', 'tours')} `} />
            {data &&
              data.map(({ ecmsId, title, location, thumbnailUrl }) => {
                return (
                  <ListItem
                    key={ecmsId}
                    title={`${title}`}
                    tourId={ecmsId}
                    historyPush={historyPush}
                    projectId={projectId}
                    location={location}
                    thumbnailUrl={thumbnailUrl}
                  />
                )
              })}
          </StatusGuard>
        </>
      )}
    />
  )
}

export const ToursPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.tours, 'list'),
  }),
  {
    ...pick(actionCreators, 'wamListGuidedToursRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(ToursPageView)
