import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'
import {
  ListGuidedToursResponse,
  GetGuidedTourRequest,
  GetGuidedTourResponse,
  UpdateGuidedTourRequest,
  UpdateGuidedTourResponse,
  DeleteTourDirectionsRequest,
  DeleteTourDirectionsResponse,
  DeleteTourOutroRequest,
  DeleteTourOutroResponse,
  UpdateTourDirectionsRequest,
  UpdateTourDirectionsResponse,
} from '../types'
import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const toursActionCreators = {
  wamListGuidedToursRequest: (): FluxStandardAction<
    undefined,
    'wamListGuidedToursRequest'
  > => ({
    domain: 'wam.tours' as const,
    type: 'wamListGuidedToursRequest' as const,
    payload: undefined,
  }),
  wamListGuidedToursSuccess: (
    payload: ListGuidedToursResponse,
  ): FluxStandardAction<ListGuidedToursResponse, 'wamListGuidedToursSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamListGuidedToursSuccess' as const,
    payload,
  }),
  wamListGuidedToursFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamListGuidedToursFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamListGuidedToursFailure' as const,
    payload,
    error: true,
  }),
  wamGetGuidedTourRequest: (
    payload: GetGuidedTourRequest,
  ): FluxStandardAction<GetGuidedTourRequest, 'wamGetGuidedTourRequest'> => ({
    domain: 'wam.tours' as const,
    type: 'wamGetGuidedTourRequest' as const,
    payload,
  }),
  wamGetGuidedTourSuccess: (
    payload: GetGuidedTourResponse,
  ): FluxStandardAction<GetGuidedTourResponse, 'wamGetGuidedTourSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamGetGuidedTourSuccess' as const,
    payload,
  }),
  wamGetGuidedTourFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamGetGuidedTourFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamGetGuidedTourFailure' as const,
    payload,
    error: true,
  }),

  wamUpdateGuidedTourRequest: (
    payload: UpdateGuidedTourRequest,
  ): FluxStandardAction<UpdateGuidedTourRequest, 'wamUpdateGuidedTourRequest'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateGuidedTourRequest' as const,
    payload,
  }),
  wamUpdateGuidedTourSuccess: (
    payload: UpdateGuidedTourResponse,
  ): FluxStandardAction<UpdateGuidedTourResponse, 'wamUpdateGuidedTourSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateGuidedTourSuccess' as const,
    payload,
  }),
  wamUpdateGuidedTourFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamUpdateGuidedTourFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateGuidedTourFailure' as const,
    payload,
    error: true,
  }),

  wamUpdateTourDirectionsRequest: (
    payload: UpdateTourDirectionsRequest,
  ): FluxStandardAction<UpdateTourDirectionsRequest, 'wamUpdateTourDirectionsRequest'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateTourDirectionsRequest' as const,
    payload,
  }),
  wamUpdateTourDirectionsSuccess: (
    payload: UpdateTourDirectionsResponse,
  ): FluxStandardAction<UpdateTourDirectionsResponse, 'wamUpdateTourDirectionsSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateTourDirectionsSuccess' as const,
    payload,
  }),
  wamUpdateTourDirectionsFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamUpdateTourDirectionsFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamUpdateTourDirectionsFailure' as const,
    payload,
    error: true,
  }),

  wamDeleteTourDirectionsRequest: (
    payload: DeleteTourDirectionsRequest,
  ): FluxStandardAction<DeleteTourDirectionsRequest, 'wamDeleteTourDirectionsRequest'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourDirectionsRequest' as const,
    payload,
  }),
  wamDeleteTourDirectionsSuccess: (
    payload: DeleteTourDirectionsResponse
  ): FluxStandardAction<DeleteTourDirectionsResponse, 'wamDeleteTourDirectionsSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourDirectionsSuccess' as const,
    payload
  }),
  wamDeleteTourDirectionsFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamDeleteTourDirectionsFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourDirectionsFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteTourDirectionsReset: ()  => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourDirectionsReset' as const
  }),

  wamDeleteTourOutroRequest: (
    payload: DeleteTourOutroRequest,
  ): FluxStandardAction<DeleteTourOutroRequest, 'wamDeleteTourOutroRequest'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourOutroRequest' as const,
    payload,
  }),
  wamDeleteTourOutroSuccess: (
    payload: DeleteTourOutroResponse
  ): FluxStandardAction<DeleteTourOutroResponse, 'wamDeleteTourOutroSuccess'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourOutroSuccess' as const,
    payload
  }),
  wamDeleteTourOutroFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamDeleteTourOutroFailure'> => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourOutroFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteTourOutroReset: ()  => ({
    domain: 'wam.tours' as const,
    type: 'wamDeleteTourOutroReset' as const
  }),
}

type WamAction = ReturnType<typeof toursActionCreators[keyof typeof toursActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.tours'
}

export const toursReducer = (
  state: WamAppState['tours'] = initialWamAppState.tours,
  action: WamAction,
): WamAppState['tours'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamListGuidedToursRequest': {
      return {
        ...state,
        list: {
          status: Status.Loading,
        },
      }
    }
    case 'wamListGuidedToursSuccess': {
      const { payload } = action
      return {
        ...state,
        list: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamListGuidedToursFailure': {
      const { payload } = action
      return {
        ...state,
        list: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamGetGuidedTourRequest': {
      return {
        ...state,
        tourUpdate: {
          status: Status.Loading,
        },
      }
    }
    case 'wamGetGuidedTourSuccess': {
      const { payload } = action
      return {
        ...state,
        tourUpdate: {
          data: payload,
          status: Status.Ready,
        },
        tourDirectionsUpdate: {
          data: payload.directions,
          status: Status.Ready,
        }
      }
    }
    case 'wamGetGuidedTourFailure': {
      const { payload } = action
      return {
        ...state,
        tourUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamUpdateGuidedTourRequest': {
      if (state.tourUpdate.status !== Status.Ready) {
        throw new Error(
          '[ wamUpdateGuidedTourRequest ] Cannot update entity when `tourUpdate` is not in Ready state',
        )
      }
      return {
        ...state,
        tourUpdate: {
          ...state.tourUpdate,
          status: Status.Updating,
        },
      }
    }
    case 'wamUpdateGuidedTourSuccess': {
      const { payload } = action
      return {
        ...state,
        tourUpdate: {
          data: payload,
          status: Status.Ready,
        },
      }
    }
    case 'wamUpdateGuidedTourFailure': {
      const { payload } = action
      return {
        ...state,
        tourUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteTourDirectionsRequest': {
      return {
        ...state,
        tourDirectionsDelete: {
          status: Status.Loading
        }
      }
    }
    case 'wamDeleteTourDirectionsSuccess': {
      return {
        ...state,
        tourDirectionsDelete: {
          status: Status.Complete,
          message: 'Tour directions deleted',
        }
      }
    }
    case 'wamDeleteTourDirectionsFailure': {
      const { payload } = action
      return {
        ...state,
        tourDirectionsDelete: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteTourDirectionsReset': {
      return {
        ...state,
        tourDirectionsDelete: {
          status: Status.Idle
        }
      }
    }
    case 'wamDeleteTourOutroRequest': {
      return {
        ...state,
        tourOutroDelete: {
          status: Status.Loading
        }
      }
    }
    case 'wamDeleteTourOutroSuccess': {
      return {
        ...state,
        tourOutroDelete: {
          status: Status.Complete,
          message: 'Tour Outro deleted',
        }
      }
    }
    case 'wamDeleteTourOutroFailure': {
      const { payload } = action
      return {
        ...state,
        tourOutroDelete: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteTourOutroReset': {
      return {
        ...state,
        tourOutroDelete: {
          status: Status.Idle
        }
      }
    }
    case 'wamUpdateTourDirectionsRequest': {
      if (state.tourDirectionsUpdate.status !== Status.Ready) {
        throw new Error(
          '[ wamUpdateTourDirectionsRequest ] Cannot update entity when `tourDirectionsUpdate` is not in Ready state',
        )
      }
      return {
        ...state,
        tourDirectionsUpdate: {
          ...state.tourDirectionsUpdate,
          status: Status.Updating,
        },
      }
    }
    case 'wamUpdateTourDirectionsSuccess': {
      const { payload } = action
      return {
        ...state,
        tourDirectionsUpdate: {
          data: payload,
          status: Status.Ready,
        }
      }
    }
    case 'wamUpdateTourDirectionsFailure': {
      const { payload } = action
      return {
        ...state,
        tourDirectionsUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
