/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconDivider = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M3 13h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconDividerString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M3 13h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2z"  fillRule="nonzero" />
  </svg>`

IconDivider.displayName = 'IconDivider'
