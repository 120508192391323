/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconList = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconListString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"  fillRule="nonzero" />
  </svg>`

IconList.displayName = 'IconList'
