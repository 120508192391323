import React from 'react'

import { Button, ButtonGroup, NavButtonBack } from 'components/ui/button'
import { space } from 'helpers/style'
import styled from 'styled'
import { HelpScout } from './helpscout'

const Wrapper = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${space(2)} ${space(5)};
  color: ${({ theme }) => theme.color.gray};
  background: ${({ theme }) => theme.color.white};
`

const Title = styled.p`
  margin: 0;
  margin-left: ${space(4)};
`

type Props = {
  readonly title: string;
  readonly kind: string;
  readonly onCommit: () => void;
  readonly onCancel: () => void;
  readonly onDelete: () => void;
};

export const PrimaryNavigationEditPosition = (props: Props) => {
  return (
    <Wrapper>
      <ButtonGroup>
        <NavButtonBack onClick={() => props.onCancel()} />
      </ButtonGroup>

      <Title>{props.title}</Title>

      <ButtonGroup>
        <Button appearance="secondary" onClick={() => props.onDelete()}>Delete {props.kind}</Button>
        <Button onClick={() => props.onCommit()}>Set {props.kind}</Button>
        <HelpScout />
      </ButtonGroup>
    </Wrapper>
  )
}
