import React from 'react'
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import { StructureRef, SpatialOption, Structures } from 'domains/structures/entities';
import { BuildingLevel, Building, OutdoorLevel, Site } from 'generated/mos/structure';
import { refToUrn } from 'entity';

import { classNamePrefix, MultiValueContainer, MultiValueRemove, OptionType, SelectWrapper } from './select-components';

type State = {
  readonly searchString: string;
};

type Props = {
  readonly structures: Structures | undefined;
  readonly structureRef: StructureRef | undefined;
  readonly onChange: (ref?: StructureRef) => void;
  readonly isDisabled?: boolean;
};

export class SpatialScopeSelector extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      searchString: '',
    };
  }

  private onChange(selectedValue: ValueType<OptionType>, { action, removedValue }: any): void {
    if (['remove-value', 'pop-value'].indexOf(action) >= 0 && (!removedValue || removedValue.isFixed)) {
      return;
    }

    if (action === 'clear') {
      this.props.onChange(undefined);
      return;
    }

    let option: any; // MQS0003
    if (Array.isArray(selectedValue)) {
      option = selectedValue[selectedValue.length - 1];
    } else if (selectedValue) {
      option = selectedValue;
    }

    this.props.onChange(option && option.data ? option.data.ref : undefined);
  }

  private onInputChange(value: string): void {
    this.setState({ searchString: value });
  }

  public render(): React.ReactNode {
    const { structures, structureRef } = this.props;
    const { searchString } = this.state;
    if (!structures) {
      return null; // FIXME: loading
    }

    const { index, children, leaves } = structures.spatialOptions;

    // Spatial option indexes require a 'stringy' RefUrn for ref-based lookups,
    // the object doesn't work directly:
    const activeRefUrn = structureRef ? refToUrn(structureRef) : '';
    const nextChildren = children[activeRefUrn] || [];

    const convertOption = (v: SpatialOption): OptionType => ({
      value: v.refUrn, data: v, key: v.ref.id, label: v.name,
    });

    const groups = [
      { label: 'Sites', filter: Site.isRef},
      { label: 'Outdoor Levels', filter: OutdoorLevel.isRef},
      { label: 'Buildings', filter: Building.isRef},
      { label: 'Building Levels', filter: BuildingLevel.isRef},
    ];
    const groupedChildren = groups.map((group) => ({
      label: group.label,
      options: nextChildren.filter((opt) => group.filter(opt.ref)).map(convertOption),
    }));

    if (searchString.length) {
      // only show other locations when I have typed at least one character
      groupedChildren.push({
        label: 'Other Locations',
        options: leaves.map((o) => ({ ...convertOption(o), label: o.fullName })),
      });
    }

    let value: any[] = [];
    const activeOption = activeRefUrn ? index[activeRefUrn] : undefined;
    if (activeOption) {
      // Assembles the selected option from the list of parents for the selected option,
      // which is made available from the store. react-select doesn't seem to compare
      // object to object, so it doesn't matter that this is a separate object instance
      // to the options constructed earlier.
      value = [...activeOption.parents.map(convertOption), convertOption(activeOption)];
    }

    return (
      <div style={{ position: 'relative' }}>
        <SelectWrapper isDisabled={this.props.isDisabled}>
          <Select<OptionType>
            classNamePrefix={classNamePrefix}
            value={value}
            isDisabled={this.props.isDisabled}
            isMulti
            components={{ MultiValueContainer, MultiValueRemove }}
            name="spatial-select"
            placeholder="Everything"
            onChange={(value: any, action: any) => this.onChange(value, action)} // MQS0003
            onInputChange={(newValue: string) => this.onInputChange(newValue)}
            options={groupedChildren}
            closeMenuOnSelect={true}
          />
          {/*isClearable={primaryLocations.some((v) => !v.isFixed)}*/}
        </SelectWrapper>
      </div>
    );
  }
}
