import { Dispatch } from 'redux';
import { runSaga } from 'redux-saga'

import { store } from 'containers/store'
import { GetConfigResponse } from 'generated/mos/admin/config';
import { UnaryRequest, UnaryResponse } from 'generated/mos/admin/webrpc'
import {
  AssignNewPasswordRequest,
  AuthenticateEmailUserRequest,
  AuthenticateEmailUserResponse,
  RefreshAuthenticationTokenResponse,
  RequestNewPasswordRequest,
  ValidateResetTokenRequest,
} from 'generated/mos/userauthentication'
import { assertNever, Invariant, pick } from 'helpers/core';
import { ensureValidSession, ensureSessionChannel } from 'services'

import { Status, ErrorMessage } from 'helpers/status';

import { AuthDevice, AuthSession, authSessionSelector, createAuthSession } from 'services';
import { authSessionSave } from './auth-session'
import { AccountAppState, AccountStore, initialAccountAppState } from './store';

interface ErrorResponse {
  readonly error: string;
}

// Run-time type guard function:
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isErrorResponse(response: any): response is ErrorResponse {
  return (response as ErrorResponse).error !== undefined;
}

// const loadConfig = () => {
//   return async (dispatch: Dispatch<AccountAction>, getState: () => AccountStore) => {
//     const { bffEndpoint } = getState().shared;

//     const httpResponse = await fetch(`${bffEndpoint}/call/mos.admin.config.Config/GetConfig`, { method: 'POST', body: '{}' });
//     dispatch(actionCreators.accountConfigStatusUpdate({ status: Status.Loading }));

//     const data = await httpResponse.json();
//     const rpcResponse = UnaryResponse.codec.decode(data)
//     if (rpcResponse.code !== 0 || !rpcResponse.body) {
//       throw new Error(`unexpected response ${JSON.stringify(data)}`);
//     }

//     const config = GetConfigResponse.codec.decode(rpcResponse.body)
//     if (!config.publicOrgInfo || !config.publicOrgInfo.orgRef) {
//       throw new Error(`missing org info ${JSON.stringify(data)}`);
//     }

//     await dispatch(actionCreators.accountConfigUpdate(config));
//   };
// };

// XXX: Cheap global to avoid multiple calls into the global helpscout API;
// this can really spam the logs when the helpscout API fails for reasons
// outside our control.
let helpscoutIdentified: string = '';

// FIXME: This side-effect rampage should probably not be hidden so deep in the app:
export function helpscoutIdentify(user: string, email: string): void {
  const key = JSON.stringify({user, email});
  if (helpscoutIdentified === key) {
    return;
  }
  helpscoutIdentified = key;

  try {
    const win = window as any;
    if (win.Beacon) {
      win.Beacon('identify', {name, email});
    }
  } catch (e) {
    // This can fail for all sorts of icky reasons, like if their email address
    // validation doesn't quite line up with ours.
    console.error(e);
  }
}

const sagaOptions = {
  channel: ensureSessionChannel,
  dispatch(output: any): any {
    store.dispatch(output)
  },
  getState() {
    return store.getState()
  },
}

export const ensureSession = () => async (dispatch: Dispatch<AccountAction>, getState: () => AccountStore): Promise<AuthSession | undefined> => {
    const state = getState();
    let { session } = state.account;

    if (!session) {
      // user is unauthenticated. We have to do nothing in this situation
      // otherwise mutations can't be used at unauthenticated routes. We rely
      // solely on the BFF for security in this case.

    } else if (authSessionSelector.refreshExpired(session)) {
      // log the user out.
      session = undefined
      dispatch(actionCreators.accountLogout())

    } else if (authSessionSelector.accessExpired(session)) {
      // manually run the token refresh saga.
      const tokens = await runSaga(
        sagaOptions,
        ensureValidSession,
      ).toPromise().then(tokens => tokens)

      session = {
        ...session,
        ...tokens,
      }

    }

    return session
  }


// {{{ actions
// exported for sagas, not to be bound to connected components.
export const actionCreators = {
  accountLoginRequest: (payload: AuthenticateEmailUserRequest.Entity) => ({
    domain: 'account' as const,
    type: 'accountLoginRequest' as const,
    payload,
  }),
  accountLoginSuccess: (user: AuthenticateEmailUserResponse.Entity) => ({
    domain: 'account' as const,
    type: 'accountLoginSuccess' as const,
    user,
  }),
  accountLoginFailure: (message: ErrorMessage) => ({
    domain: 'account' as const,
    type: 'accountLoginFailure' as const,
    message,
  }),

  accountRefreshAuthenticationTokenRequest: () => ({
    domain: 'account' as const,
    type: 'accountRefreshAuthenticationTokenRequest' as const,
  }),
  accountRefreshAuthenticationTokenSuccess: (authTokens: AuthenticateEmailUserResponse.Entity | RefreshAuthenticationTokenResponse.Entity) => {
    // pick out the oauth tokens and save to local storage.
    const tokens = {
      access: authTokens && authTokens.accessToken ? authTokens.accessToken.value : '',
      refresh: authTokens && authTokens.refreshToken ? authTokens.refreshToken.value : ''
    }
    const session = createAuthSession(tokens)
    authSessionSave(session)

    return {
      domain: 'account' as const,
      type: 'accountRefreshAuthenticationTokenSuccess' as const,
      session,
    }
  },
  accountRefreshAuthenticationTokenFailure: (message: ErrorMessage) => ({
    domain: 'account' as const,
    type: 'accountRefreshAuthenticationTokenFailure' as const,
    message,
  }),

  accountLogout: () => {
    return {
      domain: 'account' as const,
      type: 'accountLogout' as const,
    }
  },

  accountRequestNewPasswordRequest: (payload: RequestNewPasswordRequest.Entity) => ({
    domain: 'account' as const,
    type: 'accountRequestNewPasswordRequest' as const,
    payload,
  }),
  accountRequestNewPasswordSuccess: () => ({
    domain: 'account' as const,
    type: 'accountRequestNewPasswordSuccess' as const,
  }),
  accountRequestNewPasswordFailure: (message: ErrorMessage) => ({
    domain: 'account' as const,
    type: 'accountRequestNewPasswordFailure' as const,
    message,
  }),
  accountRequestNewPasswordReset: () => ({
    domain: 'account' as const,
    type: 'accountRequestNewPasswordReset' as const,
  }),
  accountRequestNewPasswordStatusUpdate: (status: AccountAppState['requestNewPasswordStatus']) => ({
    domain: 'account' as const,
    type: 'accountRequestNewPasswordStatusUpdate' as const,
    status
  }),

  accountValidateResetTokenRequest: (payload: ValidateResetTokenRequest.Entity) => ({
    domain: 'account' as const,
    type: 'accountValidateResetTokenRequest' as const,
    payload,
  }),
  accountValidateResetTokenSuccess: (valid: boolean) => ({
    domain: 'account' as const,
    type: 'accountValidateResetTokenSuccess' as const,
    valid,
  }),
  accountValidateResetTokenFailure: (message: ErrorMessage) => ({
    domain: 'account' as const,
    type: 'accountValidateResetTokenFailure' as const,
    message
  }),

  accountAssignNewPasswordRequest: (payload: AssignNewPasswordRequest.Entity) => ({
    domain: 'account' as const,
    type: 'accountAssignNewPasswordRequest' as const,
    payload,
  }),
  accountAssignNewPasswordSuccess: () => ({
    domain: 'account' as const,
    type: 'accountAssignNewPasswordSuccess' as const,
  }),
  accountAssignNewPasswordFailure: (message: ErrorMessage) => ({
    domain: 'account' as const,
    type: 'accountAssignNewPasswordFailure' as const,
    message
  }),

  // accountConfigUpdate: (config: GetConfigResponse.Entity) =>
  //   ({ domain: 'account' as const, type: 'accountConfigUpdate' as const, config }),

  // accountConfigStatusUpdate: (value: AccountAppState['configStatus']) =>
  //   ({ domain: 'account' as const, type: 'accountConfigStatusUpdate' as const, value }),

  accountDeviceUpdate: (device: AuthDevice) =>
    ({ domain: 'account' as const, type: 'accountDeviceUpdate' as const, device }),

  accountSessionUpdate: (update: Partial<Pick<AccountAppState, 'session'>>) =>
    ({ domain: 'account' as const, type: 'accountSessionUpdate' as const, update }),
};


type ActionCreators = typeof actionCreators;
export type AccountAction = ReturnType<ActionCreators[keyof ActionCreators]>;
export type AccountActions = {
  [T in keyof ActionCreators]: ReturnType<ActionCreators[T]>;
};

// FIXME: this can be removed so we only export actionCreators once graphql is removed.
export const accountBindableActionCreators = {
  ...pick(
    actionCreators,
    'accountLoginRequest',
    'accountLogout',
    'accountRequestNewPasswordRequest',
    'accountValidateResetTokenRequest',
    'accountAssignNewPasswordRequest',
  ),
  // FIXME: the two action creators below should be removed once s3-uploader, graphql are
  //   removed, and loadconfig is no longer a thunk. then this second export of action
  //   creators is no longer required.
  ensureSession,
  // loadConfig,
};
export type AccountBindableActionCreators = typeof accountBindableActionCreators;
// }}}


// {{{ reducer
const assertAccounts = (domain: 'account') => {
  return domain === 'account'
};

export const accountReducer = (state: AccountAppState = initialAccountAppState, action: AccountAction): AccountAppState => {
  if (!assertAccounts(action.domain)) {
    return state
  }

  switch (action.type) {
    // {{{ authentication sagas.
    case 'accountLoginRequest': {
      if (state.loginStatus.status === Status.Updating) {
        throw new Invariant('An authentication attempt is in-flight.');
      }
      return {
        ...state,
        loginStatus: {
          data: undefined,
          status: Status.Updating,
        },
      }
    }
    case 'accountLoginSuccess': {
      return {
        ...state,
        loginStatus: {
          data: undefined,
          status: Status.Ready,
        },
      }
    }
    case 'accountLoginFailure': {
      return {
        ...state,
        loginStatus: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      }
    }
    case 'accountLogout': {
      return {
        ...state,
      };
    }

    case 'accountRefreshAuthenticationTokenRequest': {
      return {
        ...state,
        tokenStatus: {
          status: Status.Updating,
        },
      }
    }
    case 'accountRefreshAuthenticationTokenSuccess': {
      return {
        ...state,
        session: {
          ...state.session,
          ...action.session,
        },
        tokenStatus: {
          status: Status.Ready,
        },
      }
    }
    case 'accountRefreshAuthenticationTokenFailure': {
      return {
        ...state,
        tokenStatus: {
          status: Status.Failed,
        },
      }
    }
    // }}}
    // {{{ password reset sagas.
    case 'accountRequestNewPasswordRequest': {
      return {
        ...state,
        requestNewPasswordStatus: {
          data: undefined,
          status: Status.Updating,
        },
      }
    }
    case 'accountRequestNewPasswordSuccess': {
      return {
        ...state,
        requestNewPasswordStatus: {
          data: undefined,
          status: Status.Ready,
        },
      }
    }
    case 'accountRequestNewPasswordFailure': {
      return {
        ...state,
        requestNewPasswordStatus: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      }
    }
    case 'accountRequestNewPasswordReset': {
      return {
        ...state,
        requestNewPasswordStatus: {
          status: Status.Idle,
        },
      }
    }
    case 'accountRequestNewPasswordStatusUpdate': {
      return {
        ...state,
        requestNewPasswordStatus: action.status,
      }
    }

    case 'accountValidateResetTokenRequest': {
      if (state.tokenValidation.status === Status.Updating) {
        throw new Invariant('A token validation is already in progress.');
      }
      return {
        ...state,
        tokenValidation: {
          data: { valid: false },
          status: Status.Updating,
        },
      }
    }
    case 'accountValidateResetTokenSuccess': {
      return {
        ...state,
        tokenValidation: {
          data: { valid: action.valid },
          status: Status.Ready,
        },
      }
    }
    case 'accountValidateResetTokenFailure': {
      return {
        ...state,
        tokenValidation: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      }
    }

    case 'accountAssignNewPasswordRequest': {
      if (state.assignNewPasswordStatus.status === Status.Updating) {
        throw new Invariant('An assign password request is already in progress.');
      }
      return {
        ...state,
        assignNewPasswordStatus: {
          data: undefined,
          status: Status.Updating,
        },
      }
    }
    case 'accountAssignNewPasswordSuccess': {
      return {
        ...state,
        assignNewPasswordStatus: {
          data: undefined,
          status: Status.Ready,
        },
      }
    }
    case 'accountAssignNewPasswordFailure': {
      return {
        ...state,
        assignNewPasswordStatus: {
          messages: [ action.message ],
          status: Status.Failed,
        },
      }
    }
    // }}}

    // case 'accountConfigUpdate': {
    //   return {
    //     ...state,
    //     config: action.config,
    //     configStatus: {
    //       data: undefined,
    //       status: Status.Ready,
    //     },
    //   };
    // }
    // case 'accountConfigStatusUpdate': {
    //   return {
    //     ...state,
    //     configStatus: action.value,
    //   }
    // }

    case 'accountDeviceUpdate': {
      return {
        ...state,
        device: action.device,
      }
    }

    case 'accountSessionUpdate': {
      return {
        ...state,
        ...action.update,
      }
    }

    default: {
      assertNever(action);
      throw new Error();
    }
  }
};
// }}}
