/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconWebpage = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-6H7V7h4v6zm6 0h-4v-2h4v2zm0-4h-4V7h4v2z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconWebpageString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-6H7V7h4v6zm6 0h-4v-2h4v2zm0-4h-4V7h4v2z"  fillRule="nonzero" />
  </svg>`

IconWebpage.displayName = 'IconWebpage'
