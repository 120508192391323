// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { PlacedPolygon } from "generated/mos/geo";
import { AdminMap, Building, BuildingLevel, Site, Space } from "generated/mos/structure";
import { Codec, DecodeContext, FieldMask, RemoteObject, WebRPCError, decodeMOSMultiPolygon, decodeMOSPoint, decodeMOSPolygon, decodeMessageRepeated, encodeMOSMultiPolygon, encodeMOSPoint, encodeMOSPolygon, ensureScalar } from "generated/webrpc";
import { MultiPolygon2D, Point2D, Polygon2D } from "helpers/geo";

export namespace CreateAdminMapRequest {
  export const refName = "mos.structure_management.CreateAdminMapRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly levelRef: { readonly typename: 'mos.structure.BuildingLevel' | 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    // The image should already be uploaded at the time that CreateAdminMap() is called - the url
    // should be considered immutable. Working out the exact flow for doing the upload then calling
    // this is a TODO
    readonly imageUrl: string,
    readonly bottomLeftGeoReference: Point2D | undefined,
    readonly topRightGeoReference: Point2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    levelRef: undefined,
    imageUrl: "",
    bottomLeftGeoReference: undefined,
    topRightGeoReference: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["levelRef"] = v.levelRef;
      obj["imageUrl"] = v.imageUrl;
      obj["bottomLeftGeoReference"] = encodeMOSPoint(v.bottomLeftGeoReference);
      obj["topRightGeoReference"] = encodeMOSPoint(v.topRightGeoReference);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateAdminMapRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["levelRef"] = v["levelRef"];
      out["imageUrl"] = ensureScalar(ctx, v["imageUrl"], "string");
      out["bottomLeftGeoReference"] = decodeMOSPoint(ctx, v["bottomLeftGeoReference"]);
      out["topRightGeoReference"] = decodeMOSPoint(ctx, v["topRightGeoReference"]);
      return out as any as Entity;
    }
  }();
}

export namespace CreateAdminMapResponse {
  export const refName = "mos.structure_management.CreateAdminMapResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMap: AdminMap.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMap: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["adminMap"] = AdminMap.codec.encode(v.adminMap);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateAdminMapResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMap"] = ctx.decode(AdminMap.codec, v["adminMap"], "adminMap");
      return out as any as Entity;
    }
  }();
}

export namespace CreateBuildingLevelRequest {
  export const refName = "mos.structure_management.CreateBuildingLevelRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
    readonly name: string,
    readonly shortName: string,
    readonly zOrder: number,
    // Warning: Multiple boundaries are supported in the protocol, but it is not yet guaranteed
    // that any implementation will support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingRef: undefined,
    name: "",
    shortName: "",
    zOrder: 0,
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingRef"] = v.buildingRef;
      obj["name"] = v.name;
      obj["shortName"] = v.shortName;
      obj["zOrder"] = v.zOrder;
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateBuildingLevelRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingRef"] = v["buildingRef"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["shortName"] = ensureScalar(ctx, v["shortName"], "string");
      out["zOrder"] = ensureScalar(ctx, v["zOrder"], "number");
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

export namespace CreateBuildingLevelResponse {
  export const refName = "mos.structure_management.CreateBuildingLevelResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevel: BuildingLevel.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevel: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingLevel"] = BuildingLevel.codec.encode(v.buildingLevel);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateBuildingLevelResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevel"] = ctx.decode(BuildingLevel.codec, v["buildingLevel"], "buildingLevel");
      return out as any as Entity;
    }
  }();
}

export namespace CreateBuildingRequest {
  export const refName = "mos.structure_management.CreateBuildingRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    readonly name: string,
    // Warning: Multiple boundaries are supported in the protocol, but it is not yet guaranteed
    // that any implementation will support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
    name: "",
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["siteRef"] = v.siteRef;
      obj["name"] = v.name;
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateBuildingRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

export namespace CreateBuildingResponse {
  export const refName = "mos.structure_management.CreateBuildingResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly building: Building.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    building: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["building"] = Building.codec.encode(v.building);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateBuildingResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["building"] = ctx.decode(Building.codec, v["building"], "building");
      return out as any as Entity;
    }
  }();
}

export namespace CreateSiteRequest {
  export const refName = "mos.structure_management.CreateSiteRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly name: string,
    readonly boundary: Polygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    name: "",
    boundary: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["name"] = v.name;
      obj["boundary"] = encodeMOSPolygon(v.boundary);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateSiteRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundary"] = decodeMOSPolygon(ctx, v["boundary"]);
      return out as any as Entity;
    }
  }();
}

export namespace CreateSiteResponse {
  export const refName = "mos.structure_management.CreateSiteResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly site: Site.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    site: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["site"] = Site.codec.encode(v.site);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateSiteResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["site"] = ctx.decode(Site.codec, v["site"], "site");
      return out as any as Entity;
    }
  }();
}

export namespace CreateSpaceRequest {
  export const refName = "mos.structure_management.CreateSpaceRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    // Attributes
    readonly name: string,
    readonly displayName: string,
    readonly boundaries: ReadonlyArray<PlacedPolygon.Entity>,
    readonly spaceTypeRef: { readonly typename: 'mos.structure.SpaceType', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
    name: "",
    displayName: "",
    boundaries: [],
    spaceTypeRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["siteRef"] = v.siteRef;
      obj["name"] = v.name;
      obj["displayName"] = v.displayName;
      {
        const vs = [];
        for (const item of v.boundaries) {
          vs.push(PlacedPolygon.codec.encode(item));
        }
        obj["boundaries"] = { placedPolygons: vs };
      }
      obj["spaceTypeRef"] = v.spaceTypeRef;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateSpaceRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["displayName"] = ensureScalar(ctx, v["displayName"], "string");
      {
        const from = v["boundaries"];
        out["boundaries"] = from && from["placedPolygons"] ? decodeMessageRepeated(ctx, PlacedPolygon.codec, from["placedPolygons"], "boundaries") : [];
      }
      out["spaceTypeRef"] = v["spaceTypeRef"];
      return out as any as Entity;
    }
  }();
}

export namespace CreateSpaceResponse {
  export const refName = "mos.structure_management.CreateSpaceResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly space: Space.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    space: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["space"] = Space.codec.encode(v.space);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.CreateSpaceResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["space"] = ctx.decode(Space.codec, v["space"], "space");
      return out as any as Entity;
    }
  }();
}

export namespace DeleteAdminMapRequest {
  export const refName = "mos.structure_management.DeleteAdminMapRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMapRef: { readonly typename: 'mos.structure.AdminMap', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMapRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteAdminMapRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMapRef"] = v["adminMapRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteAdminMapResponse {
  export const refName = "mos.structure_management.DeleteAdminMapResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.AdminMap', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteAdminMapResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBuildingLevelRequest {
  export const refName = "mos.structure_management.DeleteBuildingLevelRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevelRef: { readonly typename: 'mos.structure.BuildingLevel', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevelRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteBuildingLevelRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevelRef"] = v["buildingLevelRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBuildingLevelResponse {
  export const refName = "mos.structure_management.DeleteBuildingLevelResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.BuildingLevel', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteBuildingLevelResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBuildingRequest {
  export const refName = "mos.structure_management.DeleteBuildingRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteBuildingRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingRef"] = v["buildingRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteBuildingResponse {
  export const refName = "mos.structure_management.DeleteBuildingResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteBuildingResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteSiteRequest {
  export const refName = "mos.structure_management.DeleteSiteRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteSiteRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteSiteResponse {
  export const refName = "mos.structure_management.DeleteSiteResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteSiteResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteSpaceRequest {
  export const refName = "mos.structure_management.DeleteSpaceRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceRef: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteSpaceRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceRef"] = v["spaceRef"];
      return out as any as Entity;
    }
  }();
}

export namespace DeleteSpaceResponse {
  export const refName = "mos.structure_management.DeleteSpaceResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.DeleteSpaceResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace UpdateAdminMapRequest {
  export const refName = "mos.structure_management.UpdateAdminMapRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMapRef: { readonly typename: 'mos.structure.AdminMap', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"adminMapRef" | "bottomLeftGeoReference" | "topRightGeoReference">,
    readonly bottomLeftGeoReference: Point2D | undefined,
    readonly topRightGeoReference: Point2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMapRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    bottomLeftGeoReference: undefined,
    topRightGeoReference: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["adminMapRef"] = v.adminMapRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["bottomLeftGeoReference"] = encodeMOSPoint(v.bottomLeftGeoReference);
      obj["topRightGeoReference"] = encodeMOSPoint(v.topRightGeoReference);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateAdminMapRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMapRef"] = v["adminMapRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "adminMapRef" && fmv !== "bottomLeftGeoReference" && fmv !== "topRightGeoReference") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["bottomLeftGeoReference"] = decodeMOSPoint(ctx, v["bottomLeftGeoReference"]);
      out["topRightGeoReference"] = decodeMOSPoint(ctx, v["topRightGeoReference"]);
      return out as any as Entity;
    }
  }();
}

export namespace UpdateAdminMapResponse {
  export const refName = "mos.structure_management.UpdateAdminMapResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly adminMap: AdminMap.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    adminMap: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["adminMap"] = AdminMap.codec.encode(v.adminMap);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateAdminMapResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["adminMap"] = ctx.decode(AdminMap.codec, v["adminMap"], "adminMap");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBuildingLevelRequest {
  export const refName = "mos.structure_management.UpdateBuildingLevelRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevelRef: { readonly typename: 'mos.structure.BuildingLevel', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"buildingLevelRef" | "name" | "shortName" | "zOrder" | "boundaries">,
    readonly name: string,
    readonly shortName: string,
    readonly zOrder: number,
    // Warning: Multiple boundaries are supported in the protocol, but it is not yet guaranteed
    // that any implementation will support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevelRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    name: "",
    shortName: "",
    zOrder: 0,
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingLevelRef"] = v.buildingLevelRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["name"] = v.name;
      obj["shortName"] = v.shortName;
      obj["zOrder"] = v.zOrder;
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateBuildingLevelRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevelRef"] = v["buildingLevelRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "buildingLevelRef" && fmv !== "name" && fmv !== "shortName" && fmv !== "zOrder" && fmv !== "boundaries") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["shortName"] = ensureScalar(ctx, v["shortName"], "string");
      out["zOrder"] = ensureScalar(ctx, v["zOrder"], "number");
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBuildingLevelResponse {
  export const refName = "mos.structure_management.UpdateBuildingLevelResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingLevel: BuildingLevel.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingLevel: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingLevel"] = BuildingLevel.codec.encode(v.buildingLevel);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateBuildingLevelResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingLevel"] = ctx.decode(BuildingLevel.codec, v["buildingLevel"], "buildingLevel");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBuildingRequest {
  export const refName = "mos.structure_management.UpdateBuildingRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"buildingRef" | "name" | "boundaries">,
    readonly name: string,
    // Warning: Multiple boundaries are supported in the protocol, but it is not yet guaranteed
    // that any implementation will support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    buildingRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    name: "",
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["buildingRef"] = v.buildingRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["name"] = v.name;
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateBuildingRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["buildingRef"] = v["buildingRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "buildingRef" && fmv !== "name" && fmv !== "boundaries") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

export namespace UpdateBuildingResponse {
  export const refName = "mos.structure_management.UpdateBuildingResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly building: Building.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    building: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["building"] = Building.codec.encode(v.building);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateBuildingResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["building"] = ctx.decode(Building.codec, v["building"], "building");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateSiteRequest {
  export const refName = "mos.structure_management.UpdateSiteRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"siteRef" | "name" | "boundary">,
    readonly name: string,
    readonly boundary: Polygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    siteRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    name: "",
    boundary: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["siteRef"] = v.siteRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["name"] = v.name;
      obj["boundary"] = encodeMOSPolygon(v.boundary);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateSiteRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["siteRef"] = v["siteRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "siteRef" && fmv !== "name" && fmv !== "boundary") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundary"] = decodeMOSPolygon(ctx, v["boundary"]);
      return out as any as Entity;
    }
  }();
}

export namespace UpdateSiteResponse {
  export const refName = "mos.structure_management.UpdateSiteResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly site: Site.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    site: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["site"] = Site.codec.encode(v.site);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateSiteResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["site"] = ctx.decode(Site.codec, v["site"], "site");
      return out as any as Entity;
    }
  }();
}

export namespace UpdateSpaceRequest {
  export const refName = "mos.structure_management.UpdateSpaceRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly spaceRef: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
    readonly updateMask: FieldMask<"spaceRef" | "name" | "displayName" | "boundaries" | "spaceTypeRef">,
    readonly name: string,
    readonly displayName: string,
    readonly boundaries: ReadonlyArray<PlacedPolygon.Entity>,
    readonly spaceTypeRef: { readonly typename: 'mos.structure.SpaceType', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    spaceRef: undefined,
    updateMask: { type: "google.protobuf.FieldMask", fields: [] },
    name: "",
    displayName: "",
    boundaries: [],
    spaceTypeRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["spaceRef"] = v.spaceRef;
      obj["updateMask"] = v.updateMask.fields.join(",");
      obj["name"] = v.name;
      obj["displayName"] = v.displayName;
      {
        const vs = [];
        for (const item of v.boundaries) {
          vs.push(PlacedPolygon.codec.encode(item));
        }
        obj["boundaries"] = { placedPolygons: vs };
      }
      obj["spaceTypeRef"] = v.spaceTypeRef;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateSpaceRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["spaceRef"] = v["spaceRef"];
      {
        const fmvs = ensureScalar(ctx, v["updateMask"], "string").split(/\s,\s/);
        for (const fmv of fmvs) {
          if (fmv !== "spaceRef" && fmv !== "name" && fmv !== "displayName" && fmv !== "boundaries" && fmv !== "spaceTypeRef") {
            throw ctx.error(`received invalid value ${fmv} in field mask`);
          }
        }
        out["updateMask"] = fmvs;
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["displayName"] = ensureScalar(ctx, v["displayName"], "string");
      {
        const from = v["boundaries"];
        out["boundaries"] = from && from["placedPolygons"] ? decodeMessageRepeated(ctx, PlacedPolygon.codec, from["placedPolygons"], "boundaries") : [];
      }
      out["spaceTypeRef"] = v["spaceTypeRef"];
      return out as any as Entity;
    }
  }();
}

export namespace UpdateSpaceResponse {
  export const refName = "mos.structure_management.UpdateSpaceResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly space: Space.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    space: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["space"] = Space.codec.encode(v.space);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure_management.UpdateSpaceResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["space"] = ctx.decode(Space.codec, v["space"], "space");
      return out as any as Entity;
    }
  }();
}

