import React from 'react'

import { connect } from 'containers/store';
import { refToUrn } from 'entity';
import { pick } from 'helpers/core';
import * as geo from 'helpers/geo';
import { statusSelector } from 'helpers/status';
import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { StructureFilterState, structuresSelector } from 'domains/structures';
import { structuresActionCreators } from 'domains/structures/actions';
import { StructureRef, Structures } from 'domains/structures/entities';
import { SpatialScopeSelector } from 'domains/structures/components/spatial-scope-select';
import { StructuresOutlineMap } from 'domains/structures/components/structures-outline-map';
import { DataStatus as StructuresDataStatus, GlobalMapState } from 'domains/structures/store';

import { StructureHoverEvent, StructureList, StructureSelectEvent } from '../structure-list';

import { MapInitialBounds } from 'components/mapbox/core';
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity';

type DirectProps = {
  readonly onCreate: (ref: StructureRef) => void;
  readonly navBackRoute?: string;
};

type ConnectedProps = {
  readonly globalMap: GlobalMapState;
  readonly structures: StructuresDataStatus<Structures>;
  readonly structureFilters: StructureFilterState;
};

type ActionProps = Pick<typeof structuresActionCreators, 'globalMapUpdate' | 'structuresFiltersUpdate' | 'structuresLoadRequest'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly highlightRef?: StructureRef;

  // Replace this with a new '{}' to force mapbox to update to the initial bounds:
  readonly mapBoundsIdentity?: object;
  readonly mapBounds?: geo.BBox2D;
};

class StructuresListPageView extends React.Component<Props, State> {
  // only allow pre-expanding the tree once per lifecycle
  private hasPreExpanded = false;

  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount()  { this.refresh(); this.preExpand(); }
  public componentDidUpdate() { this.refresh(); this.preExpand(); }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private preExpand = () => {
    const structures = statusSelector.data(this.props.structures);
    const { expanded, lastEdited } = this.props.structureFilters;
    const newExpanded: typeof expanded = {};

    if (lastEdited && structures && !this.hasPreExpanded) {
      for (const ref of structuresSelector.stack(structures, lastEdited)) {
        newExpanded[refToUrn(ref)] = true;
      }

      this.hasPreExpanded = true;
      this.props.structuresFiltersUpdate({ expanded: { ...expanded, ...newExpanded } });
    }
  }

  private mapBounds(): MapInitialBounds | undefined {
    if (!this.state.mapBoundsIdentity || !this.state.mapBounds) {
      return;
    }
    return {
      padding: 60, // FIXME put in context
      identity: this.state.mapBoundsIdentity,
      bounds: this.state.mapBounds,
    };
  }

  private onStructureHovered = (e: StructureHoverEvent) => {
    this.setState({ highlightRef: e.ref });
  }

  private centerStructureOnMap = (ref: StructureRef | undefined) => {
    const structures = statusSelector.data(this.props.structures);
    if (!structures || !ref) {
      return;
    }
    const boundary = structuresSelector.selectClosestBoundaryByRef(structures, ref);
    if (boundary) {
      this.setState({ mapBoundsIdentity: {}, mapBounds: geo.bbox2D(boundary) });
    }
  }

  private onStructureSelected = (e: StructureSelectEvent) => {
    this.props.globalMapUpdate({ activeRef: e.ref });
    this.centerStructureOnMap(e.ref);
  }

  private onSpatialScopeChange = (ref: StructureRef | undefined) => {
    this.props.globalMapUpdate({ activeRef: ref} );
    this.centerStructureOnMap(ref);
  }

  private onStructureCreated = (ref: StructureRef) => {
    this.props.globalMapUpdate({ activeRef: ref});
    this.props.onCreate(ref);
  }

  public render() {
    return (
      <LayoutLocateEditPage
        nav={() => (
          <PrimaryNavigationEditEntity 
            title="Edit Structures"             
            navBackRoute={this.props.navBackRoute}
            darkTheme        
          />
        )}
        sidebar={() => (
          <StructureList
            structures={this.props.structures}
            filters={this.props.structureFilters}
            filtersUpdate={this.props.structuresFiltersUpdate}
            onHover={(e) => this.onStructureHovered(e)}
            onSelect={(e) => this.onStructureSelected(e)}
            onCreate={this.onStructureCreated}
            centerStructureOnMap={this.centerStructureOnMap}
          />
        )}
        content={() => (
          <React.Fragment>
            <SpatialScopeSelector
              structures={statusSelector.data(this.props.structures)}
              structureRef={this.props.globalMap.activeRef}
              onChange={this.onSpatialScopeChange}
            />
            <StructuresOutlineMap
              mapView={this.props.globalMap.view}
              onMapViewChanged={this.props.globalMapUpdate}
              structures={statusSelector.data(this.props.structures)}
              selectedRef={this.props.globalMap.activeRef}
              highlightRef={this.state.highlightRef}
              initialBounds={this.mapBounds()}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export const StructuresListPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.structures, 'globalMap', 'structureFilters', 'structures'),
  pick(structuresActionCreators,
    'globalMapUpdate', 'structuresFiltersUpdate', 'structuresLoadRequest'),
)(StructuresListPageView);
