import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Loader } from 'components/ui/loader';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { GetConfigResponse } from 'generated/mos/admin/config';
import { AssignNewPasswordRequest, ResetToken, ValidateResetTokenRequest } from 'generated/mos/userauthentication'
import { pick } from 'helpers/core';
import { passwordValidationError } from 'helpers/form';
import { Status, statusSelector } from 'helpers/status';
import { space } from 'helpers/style';
import { LayoutUnauthenticatedPage } from 'layouts/unauthenticated-page';
import styled from 'styled';

import { accountBindableActionCreators } from 'domains/account/actions';
import { SecurityNote } from 'domains/account/components/security-note';
import { DataStatus, TokenValidationResult } from 'domains/account/store';
import { AUTH_SUPPORT_LINK } from '../../constants'

const Content = styled.div`
  text-align: center;
`

const FormError = styled.div`
  display: block;
  color: ${({ theme }) => theme.color.error};
`

const WideButton = styled(Button)`
  margin-top: ${space(2)};
  width: 100%;
`


type DirectProps = {
  readonly userId: string;
  readonly token: string;
  readonly onComplete: () => void;
};

type ConnectedProps = {
  readonly tokenValidation: DataStatus<TokenValidationResult>;
  readonly assignNewPasswordStatus: DataStatus<undefined>;
  // readonly config: GetConfigResponse.Entity  | undefined;
};

type ActionProps =
  Pick<typeof accountBindableActionCreators, 'accountAssignNewPasswordRequest' | 'accountValidateResetTokenRequest'> &
  Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly bag: FormBag<FormValues>;
};

type FormValues = {
  readonly password: string;
  readonly confirmPassword: string;
};


// https://projects.invisionapp.com/share/BENUBND372N#/screens/319661067
class SignUpPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ password: '', confirmPassword: '' }),
    };
  }

  public componentDidMount() {
    this.props.accountValidateResetTokenRequest({
      ...ValidateResetTokenRequest.defaults,
      token: {
        ...ResetToken.defaults,
        value: this.props.token,
      },
      userRef: { typename: 'mos.user.User', id: this.props.userId },
    });
  }

  public componentDidUpdate() {
    if (this.props.assignNewPasswordStatus.status === Status.Ready) {
      this.props.toastNotification({ type: 'success', text: 'Your account has been successfully updated' });
      this.props.onComplete();
    }
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private validate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};

    const error = passwordValidationError(values.password);
    if (error) {
      errors.password = error;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  }

  private onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const bag = validateFormBag(this.state.bag, this.validate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.accountAssignNewPasswordRequest({
      ...AssignNewPasswordRequest.defaults,
      password: bag.values.password,
      token: {
        ...ResetToken.defaults,
        value: this.props.token
      },
      userRef: { typename: 'mos.user.User', id: this.props.userId },
    });
    return;
  }

  public render() {
    if (this.props.tokenValidation.status !== Status.Ready) {
      return <Loader />;
    }

    const tokenValidation = statusSelector.data(this.props.tokenValidation);

    if (!tokenValidation || !tokenValidation.valid) {
      return (
        <LayoutUnauthenticatedPage
          title={() => 'Oops! This link is invalid'}
          content={() => (
            <Content>
              <p>For security reasons, these links only last a limited amount of time.</p>
              <p>You may request a new invite from your organisation's administrator</p>

              {!AUTH_SUPPORT_LINK ? null :
                <a style={{ textDecoration: 'underline' }} href={AUTH_SUPPORT_LINK.url}>
                  {AUTH_SUPPORT_LINK.displayText}
                </a>
              }
            </Content>
          )}
        />
      );
    }

    const signUpForm = () => {
      const { errors, valid, touched } = this.state.bag;

      const assignErrors = this.props.assignNewPasswordStatus.status === Status.Failed ?
        this.props.assignNewPasswordStatus.messages.map((v) => v.text) : [];

      const submitErrors = [...new Set([...assignErrors])];

      const loading = this.props.assignNewPasswordStatus.status === Status.Updating;
      const cantSubmit = this.props.assignNewPasswordStatus.status === Status.Ready;
      return (
        <form noValidate onSubmit={this.onSubmit}>
          {!submitErrors ? null :
            <FormError>
              {submitErrors.map((v) => <p key={v}>{v}</p>)}
            </FormError>
          }

          <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.validate}>
            <Input
              label="Password"
              field="password"
              type="password"
              error={touched.password ? errors.password : undefined}
            />
            <Input
              label="Confirm password"
              field="confirmPassword"
              type="password"
              error={touched.confirmPassword ? errors.confirmPassword : undefined}
            />

            <SecurityNote />

            <WideButton type="submit" isDisabled={!valid || cantSubmit}>
              {loading ? 'Creating account...' : 'Create account'}
            </WideButton>
          </FormData>
        </form>
      );
    };

    // FIXME: designs indicate user name is required, but we have no way to retrieve it.
    // https://projects.invisionapp.com/share/BENUBND372N#/screens/319661067
    return <LayoutUnauthenticatedPage title={() => 'Welcome'} content={signUpForm} />;
  }
}

export const SignUpPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'assignNewPasswordStatus', 'tokenValidation'),
  {
    ...pick(accountBindableActionCreators, 'accountAssignNewPasswordRequest', 'accountValidateResetTokenRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(SignUpPageView);
