/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMinus = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M19 13H5v-2h14z" />
  </Icon>
)

// for use with mapbox addables.
export const IconMinusString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M19 13H5v-2h14z" />
  </svg>`

IconMinus.displayName = 'IconMinus'
