import { Position2D } from 'helpers/geo'; // XXX: geo is the only mos-admin-web dependency allowed in here

// Copy-pasted from helpers/core to start minimising dependencies on outside code:
export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return !!input;
}

// dataIdentity is used as a bit of a cheat throughout this component: it allows a MapSet
// author to control when data is invalidated for the purpose of re-rendering. If a
// dataIdentity compares as equal using this function, this Mapbox integration will not
// make any changes.
//
// The rationale for the 'dataIdentity' concept is that arbitrary data may be handed to a MapSet,
// that data will frequently be difficult to compare for equality (for example, deeply
// nested GeoJSON polygons), and the data may or may not come from a stable source.
//
// If the data is sourced from Redux, object instances will be retained and pass '==='
// checks, whereas if the data is sourced from render(), object instances will be created
// afresh on each render and not pass '===' checks. As a MapSet author, when a
// dataIdentity is required, you should boil the data down into a list of things that will
// always pass '===' checks if they are conceptually the same, regardless of the lifetime
// of the object instances that hold the data. We often use ref IDs for this as they are
// string GUIDs, so they are guaranteed to be stable and to bypass issues with object
// '===' equivalence.
//
// Users of a created MapSet should not need to know about dataIdentity, and should not
// need to care that they are using objects sourced from Redux or created in a render()
// when instantiating a MapSet.
//
// dataIdentity is an array so you can use multiple data values to invalidate the data.
// The array itself is not compared, only the contents: each item in the identity is
// compared with each item in the previous identity for strict equality (===). This allows
// you to, for example, map a list of string IDs from a list of data that you are
// filtering in your component's render() function and avoid spurious redraws.
export function identityEqual(a: ReadonlyArray<any> | undefined, b: ReadonlyArray<any> | undefined): boolean {
  if (!!a !== !!b) {
    return false;
  }
  if (!a) {
    return true;
  }
  if (a.length !== b!.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b![i]) {
      return false;
    }
  }
  return true;
}

// This shouldn't need to exist, but TypeScript can't even maintain the consistency
// of its own compromises so it's a bit of a stretch to think they'd bother to find
// a better way to support something like this:
// https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-263132208
export function objectKeys<T extends object>(v: T): Array<keyof T> {
  return Object.keys(v) as Array<keyof T>;
}


export function coordinatesEqual(a: ReadonlyArray<ReadonlyArray<number>> | undefined, b: ReadonlyArray<ReadonlyArray<number>> | undefined): boolean {
  // XXX: everything in here is explicit for performance

  if (a === b) {
    return true;
  } else if (!!a !== !!b) {
    return false;
  } else if (!a || !b) { // redundant check of !b is to satisfy typescript
    return true;
  }

  if (a.length !== b.length) { return false; }
  for (let i = 0; i < a.length; i++) {
    const ai = a[i];
    const bi = b[i];
    if (ai.length !== bi.length) { return false; }
    for (let j = 0; j < ai.length; j++) {
      if (ai[j] !== bi[j]) {
        return false;
      }
    }
  }
  return true;
}


export function normalizePosition(a: mapboxgl.LngLatLike | Position2D): Position2D {
  if (Array.isArray(a) && a.length === 2) {
    return a;
  } else if ('lng' in a && 'lat' in a) {
    return [a.lng, a.lat];
  } else if ('lon' in a && 'lon' in a) {
    return [a.lon, a.lat];
  } else {
    throw new Error();
  }
}


export function positionedEqual(a: mapboxgl.LngLatLike | Position2D | undefined | null, b: mapboxgl.LngLatLike | Position2D | undefined | null): boolean {
  if (!!a !== !!b) {
    return false;
  } else if (!a || !b) {
    return true;
  }

  const av = normalizePosition(a);
  const bv = normalizePosition(b);

  return av[0] === bv[0] && av[1] === bv[1]; // FIXME: float comparison
}


// https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}


// {{{ DeepReadonly

// DeepReadonly from https://github.com/Microsoft/TypeScript/issues/13923.
//
// Here's an alternative implementation in some rando's commit; could be a useful
// source of ideas if (when) this thing falls over again:
// https://github.com/karlhorky/typescript-tricks/commit/597a9ec7576d0065d260dd5461caed30155c1275
//
// TypeScript should provide a construct like this OOTB.
export type DeepReadonly<T> =
  T extends () => any ? T :

  // XXX(bw): 3.6 broke DeepReadonly, forcing us to exempt DOM Nodes. I have a bad
  // feeling this conditional will accumulate more nasty arms like this over time:
  T extends Node ? T :

  T extends ReadonlyArray<infer R> ? DeepReadonlyArray<R> :
  T extends object ? DeepReadonlyObject<T> :
  T;

interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

// }}} DeepReadonly
