import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

import { ctaFocus } from 'components/ui/button'
import { ICON_CONTAINER_CLASSNAME } from 'components/ui/icons/Icon'
import { space } from 'helpers/style'
import styled, { css } from 'styled'

// FIXME: loose typing due to battles with react-router and styled-components types.
type Props = LinkProps & {
  readonly variant?: 'default' | 'button';
  /**
   * Has no impact on the "button" variant.
   */
  readonly appearance?: 'primary' | 'secondary';
}

const LinkComponent = styled(RouterLink)<Props>`
  display: inline-flex;
  align-items: center;
  font-size: ${({ variant, theme }) =>
    variant === 'default' ? theme.font.size.small : theme.font.size.xSmall};

  .${ICON_CONTAINER_CLASSNAME} {
    margin-right: ${space(1)};
  }

  svg {
    fill: currentColor;
  }

  ${ctaFocus}

  ${(props) => {
    if (props.variant === 'default' && props.appearance === 'secondary') {
      return `
        color: ${props.theme.color.gray}

        &:hover {
          color: ${props.theme.color.grayD20}
        }

        &:active {
          color: ${props.theme.color.grayD60}
        }
      `
    }

    return `
      color: ${props.theme.color.primary}

      &:hover {
        color: ${props.theme.color.primaryD20}
      }

      &:active {
        color: ${props.theme.color.primaryD60}
      }
    `
  }};

  ${({ variant }) => {
    if (variant === 'default') {
      return `
        text-decoration: underline;
      `
    }

    return `
      text-transform: uppercase;
    `
  }};
`
/**
 * This component customises the visual style of the `Link` component exposed by `react-router-dom` and is used to navigate to a new route. A link should **never** be used for triggering an asynchronous action, as opposed to a `Button`.
 *
 * ## `button` variant
 *
 * There may be cases where UI design will request a link to visually appear as a button to change routes. In these cases we can use the button variant, `variant="button"`, in the place of a subtle variant of the `Button` component. These styles must be kept in sync with each other.
 *
 * The `appearance` prop has no impact on this variant.
 *
 * ## Icons
 *
 * When using icons within link components they should use `size="base"`.
 */
export const Link = (props: Props) => (
  <LinkComponent {...props} />
)

Link.defaultProps = {
  variant: 'default' as const,
  appearance: 'primary' as const,
}

type NavLinkProps = {
  readonly appearance?: 'primary' | 'secondary';
  readonly isActive?: boolean;
}

const activeNavLink = css`
  background-color: ${({ theme }) => theme.color.primary};
`

export const NavLink = styled(RouterLink).attrs({
  activeClassName: 'is-active',
})<NavLinkProps>`
  display: inline-flex;
  height: 24px;
  border: none;
  border-radius: 24px;

  ${({ isActive }) => isActive ? activeNavLink : null}
  .${({ activeClassName }) => activeClassName} {
    ${activeNavLink}
  }
`

NavLink.defaultProps = {
  activeClassName: 'is-active',
}
