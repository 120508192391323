/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconHContext = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M18.25 12A2.758 2.758 0 0021 14.75 2.758 2.758 0 0023.75 12 2.758 2.758 0 0021 9.25 2.758 2.758 0 0018.25 12zm-2.75 0a2.758 2.758 0 00-2.75-2.75A2.758 2.758 0 0010 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 0015.5 12zm-8.25 0A2.758 2.758 0 004.5 9.25 2.758 2.758 0 001.75 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 007.25 12z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconHContextString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M18.25 12A2.758 2.758 0 0021 14.75 2.758 2.758 0 0023.75 12 2.758 2.758 0 0021 9.25 2.758 2.758 0 0018.25 12zm-2.75 0a2.758 2.758 0 00-2.75-2.75A2.758 2.758 0 0010 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 0015.5 12zm-8.25 0A2.758 2.758 0 004.5 9.25 2.758 2.758 0 001.75 12a2.758 2.758 0 002.75 2.75A2.758 2.758 0 007.25 12z"  fillRule="nonzero" />
  </svg>`

IconHContext.displayName = 'IconHContext'
