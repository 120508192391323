/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconAudioTour = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M18 4c1.383 0 2.5 1.117 2.5 2.5A2.497 2.497 0 0119 8.792V16c0 2.21-1.79 4-4 4s-4-1.79-4-4V9c0-1.1-.9-2-2-2s-2 .9-2 2v7l-.047.187A2.497 2.497 0 018.5 18.5C8.5 19.883 7.383 21 6 21a2.497 2.497 0 01-2.5-2.5c0-1.046.638-1.94 1.548-2.313L5 16V9c0-2.21 1.79-4 4-4s4 1.79 4 4v7c0 1.1.9 2 2 2s2-.9 2-2V8.793A2.497 2.497 0 0115.5 6.5C15.5 5.117 16.617 4 18 4z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconAudioTourString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M18 4c1.383 0 2.5 1.117 2.5 2.5A2.497 2.497 0 0119 8.792V16c0 2.21-1.79 4-4 4s-4-1.79-4-4V9c0-1.1-.9-2-2-2s-2 .9-2 2v7l-.047.187A2.497 2.497 0 018.5 18.5C8.5 19.883 7.383 21 6 21a2.497 2.497 0 01-2.5-2.5c0-1.046.638-1.94 1.548-2.313L5 16V9c0-2.21 1.79-4 4-4s4 1.79 4 4v7c0 1.1.9 2 2 2s2-.9 2-2V8.793A2.497 2.497 0 0115.5 6.5C15.5 5.117 16.617 4 18 4z"  fillRule="nonzero" />
  </svg>`

IconAudioTour.displayName = 'IconAudioTour'
