import { GetLinkedEntityMappingsResponse, GetLinkedFieldMappingsResponse } from 'generated/mos/linkedfieldmappings'
import { DataStatus as GenericDataStatus, Status } from 'helpers/status'

type LinkableErrorCode = string;

export type DataStatus<T> = GenericDataStatus<T, LinkableErrorCode>;

export type LinkableAppState = {
  readonly entityMappings: DataStatus<GetLinkedEntityMappingsResponse.Entity>;
  readonly fieldMappings: DataStatus<GetLinkedFieldMappingsResponse.Entity>;
};

export const initialLinkableAppState: LinkableAppState = {
  entityMappings: { status: Status.Idle },
  fieldMappings: { status: Status.Idle },
}

export type LinkableStore = {
  readonly linkable: LinkableAppState;
}
