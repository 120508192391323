export const ORG_SLUG = 'wam'
export const ORG_NAME = 'Art Processors MOS'
export const ORG_TITLE = ' WA Museum'
export const AUTH_SUPPORT_LINK = {
  url: '',
  displayText: '',
}

export const REACT_APP_MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYXJ0cHJvY2Vzc29ycyIsImEiOiJjbDVoY2VzcnEwNDBkM2NtdGx2Z2QzaHhsIn0.gIYuJxj6fOb5jQTFglq4Xg'
export const REACT_APP_MAPBOX_STYLES = 'mapbox://styles/mapbox/light-v9?optimize=true'
export const REACT_APP_MAPBOX_DEVTOOLS = false
