import { SharedAppState } from 'containers/shared';
import { Beacon } from 'generated/mos/beacon'
import {
  DataStatus as GenericDataStatus,
  EntityStatus as GenericEntityStatus,
  Status,
} from 'helpers/status';

import { ProfileType } from './profiles'

type BeaconErrorCode = string;

export type DataStatus<T> = GenericDataStatus<T, BeaconErrorCode>;
export type EntityStatus<T extends object> = GenericEntityStatus<T, BeaconErrorCode, string>;

export type BeaconEdit = Beacon.Entity;

export type BeaconsList = ReadonlyArray<Beacon.Entity>;

export interface BeaconFilterState {
  readonly search?: string;

  // FIXME: Docs dead-end. Where do I look to find out what keys I might find in here?
  readonly expanded: Record<string, boolean>;

  readonly lastEdited?: Beacon.Ref;
}

export type BeaconsAppState = {
  readonly beaconFilters: BeaconFilterState;

  readonly beaconLastProfileType?: ProfileType;
  readonly beaconCreate: DataStatus<Beacon.Ref>;
  readonly beaconDelete: DataStatus<undefined>;

  // beaconEdit contains the result of retrieving a single beacon for the
  // purpose of editing it. It does not represent the intermediate state.
  readonly beaconEdit: EntityStatus<BeaconEdit>;

  readonly beacons: DataStatus<BeaconsList>;
};

export const initialBeaconsAppState: BeaconsAppState = {
  beaconFilters: { search: '', expanded: {} },
  beaconCreate: { status: Status.Idle },
  beaconDelete: { status: Status.Idle },
  beaconEdit: { status: Status.Idle },
  beacons: { status: Status.Idle },
};

export type BeaconsStore = {
  readonly beacons: BeaconsAppState;
  readonly shared: SharedAppState;
};
