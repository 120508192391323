import React, { useState } from 'react'
import { Field, FormErrors } from 'react-formage'
import ReactSelect from 'react-select'

import { Button } from 'components/ui/button'
import { IconClose, IconEdit, IconLink } from 'components/ui/icons'

import {
  LinkableOverride,
  LinkableOverrideOptions,
} from 'domains/linkable/components/linkable-override'

import * as entity from 'entity'
import { space } from 'helpers/style'
import styled from 'styled'

export type Option<TValue> = {
  readonly label: string | JSX.Element;
  readonly value: TValue;
}

type CommonProps = {
  /**
   * This maps to the property name in the `formage` form bag.
   */
  readonly field: string;
  readonly label: string;
  /**
   * Optional copy which sits to the right of the label in a subtle colour.
   */
  readonly labelSupportingText?: string;
  /**
   * An optional placeholder within the element. Check with the content or design team if not available in visual designs.
   */
  readonly placeholder?: string;
  /**
   * Displays below the form element describing it's purpose.
   */
  readonly description?: string;
  /**
   * Used for styling purposes only.
   */
  readonly isAssociated?: boolean;
  readonly isClearable?: boolean;
  readonly isDisabled?: boolean;
  readonly isSearchable?: boolean;
  /**
   * Configuration object for linked field behaviour. Only available to RefSelect because linked fields manage Placement or Attribute refs.
   */
  readonly linkableOptions?: LinkableOverrideOptions;
}

type Props<TValue, TKey> = Omit<CommonProps, 'linkableOptions'> & {
  /**
   * This maps to the property name in the `formage` form bag.
   */
  readonly field: TKey;
  readonly options: ReadonlyArray<Option<TValue>>;
  /**
   * A validation error.
   */
  readonly error?: string | FormErrors<TValue>;
  /**
   * A warning that there is a publishing issue.
   */
  readonly warning?: string;
}

export const classNamePrefix = 'react-select__form'

type FieldWrapperProps = {
  readonly hasError?: boolean;
  readonly isAssociated?: boolean;
  readonly isDisabled?: boolean;
  readonly isOverriding?: boolean;
  readonly linkableOptions?: LinkableOverrideOptions;
}

export const FieldWrapper = styled.div<FieldWrapperProps>`
  margin-bottom: ${({ isAssociated }) => isAssociated ? space(4) : space(6)};

  ${({ isAssociated, theme }) => !isAssociated ? null :`
    padding-left: ${space(4)};
    border-left: 3px solid ${theme.color.grayL70};
  `}

  .${classNamePrefix}__control {
    min-height: 44px;
    margin-top: ${space(2)};
    margin-bottom: ${space(1)};
    border: 1px solid ${({ theme }) => theme.color.grayL80};
    border-radius: 2px;
    font-size: ${({ theme }) => theme.font.size.base};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.grayD80};
  }

  .has-error > .${classNamePrefix}__control {
    border-bottom: 2px solid ${({ theme }) => theme.color.error};

    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.color.error};
    }
  }

  .is-overriding > .${classNamePrefix}__control {
    ${({ theme }) => {
      const gray = theme.color.grayL80
      return `
        background-image: linear-gradient(135deg, ${gray} 25%, transparent 25%, transparent 50%, ${gray} 50%, ${gray} 75%, transparent 75%, transparent);
        background-size: 8px 8px;
      `
    }}
  }

  .is-linked .${classNamePrefix}__dropdown-indicator {
    visibility: hidden;
  }


  .${classNamePrefix}__control--is-focused {
    ${({ isDisabled, theme }) => !isDisabled && `
      background-color: ${theme.color.white};
      border-color: transparent !important;
      border-radius: 0;
      outline: solid 2px ${theme.color.primaryD40} !important;
    `}

    .${classNamePrefix}__dropdown-indicator svg {
      transform: rotate(180deg);
    }
  }
  .${classNamePrefix}__indicator-separator {
    background: none;
  }
  .${classNamePrefix}__value-container {
    padding-left: ${space(4)};
  }
  .${classNamePrefix}__single-value {
    color: ${({ theme }) => theme.color.grayD80};
  }
  .${classNamePrefix}__dropdown-indicator {
    padding-right: ${space(4)};
    svg {
      fill: ${({ theme }) => theme.color.gray} !important;
      transition: ${({ theme }) => theme.transition.base};
    }
  }
  .${classNamePrefix}__option {
    color: ${({ theme }) => theme.color.grayD80};
    font-size: ${({ theme }) => theme.font.size.base};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
  .${classNamePrefix}__option--is-focused {
    background-color: ${({ theme }) => theme.color.grayL90} !important;
    color: ${({ theme }) => theme.color.grayD80};
  }
  .${classNamePrefix}__option--is-selected {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.grayD80};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  .${classNamePrefix}__multi-value {
    background-color: ${({ theme }) => theme.color.grayL90};
    border: 1px solid ${({ theme }) => theme.color.grayL70};
    border-radius: 4px;
    &:hover {
      background-color: ${({ theme }) => theme.color.grayL80};
      border-color: ${({ theme }) => theme.color.grayL40};
      .${classNamePrefix}__multi-value__label {
        color: ${({ theme }) => theme.color.darkText};
      }
    }
  }
  .${classNamePrefix}__multi-value__label {
    color: ${({ theme }) => theme.color.gray};
    padding: 4px 4px 4px 8px;
  }
  .${classNamePrefix}__multi-value__remove {
    svg {
      fill: ${({ theme }) => theme.color.gray};
    }
    &:hover {
      background: none;
      svg {
        fill: ${({ theme }) => theme.color.darkText};
      }
    }
  }
`

const LinkedFieldIcon = styled(IconLink)`
  display: inline-block;
  margin-right: ${space(1)};
  vertical-align: middle;
`

const Label = styled.label`
  color: ${({ theme }) => theme.color.grayD60};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const LabelSupport = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const Description = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.gray};
  line-height: ${space(6)};
`

const Error = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.error};
  line-height: ${space(6)};
`

const Warning = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.warning};
  line-height: ${space(6)};
`

const LinkedFieldEditButton = styled.div`
  position: absolute;
  right: ${space(1)};
  top: 50%;
  transform: translate(0, -50%);
`

/**
 * Under the hood this component is using `react-select`. Styles have been customised to provide the closest appearance to the other common form elements in this design system.
 *
 * The only props which are mandatory are the `label` and the `field`. The field value is a string representing the name of the value the element should control in the formage formbag.
 *
 * An optional `labelSupportText` prop can be used to provide supporting text for the fields label, and should be visually more subtle than the label copy itself.
 *
 * The `isAssociated` prop is used to change the visual style of the element when it relates to another field above it, for example alternate text, credits etc.
 *
 * ## Accessibility
 *
 * The field is wrapped with a `label` element to avoid the need to pass ids into the component. Focus states should be tested when altering the styles of this component.
 *
 * ## Validation feedback
 *
 * The `error` prop is used to display field validation issues. The `warning` prop is used to display issues related to publishing snapshots. In cases where both an error and a warning are presented to the field, only the error will be displayed.
 *
 * ## `SelectRef`
 *
 * This is an alternate component exposed which appears and functions the same as the regular `Select` but can be used in situations where you need to select an entities ref.
 *
 * ### Linked fields
 *
 * This functionality is only available to the `SelectRef` component as linked field values handle Placement and Attribute refs.
 *
 * If a field is linked we need to pass a configuration object through the `linkableOptions` prop to allow the linking functionality to operate. This object consists of the following options:
 *
 * - `alternateFieldValues`: Attribute Ref based values to select from based on linked field values mappings.
 * - `customOverrideValues`: Unlike other linkable field components which use `allowCustomOverride` this component allows selection from a defined set of alternative refs as a custom override. If this array isn't passed then custom override will not be available for the field.
 * - `onUpdate`: **Required** This passes the value from the override form back to the parent components state.
 *
 * At least one of `alternateFieldValues` or `allowCustomOverride` must be truthy.
 *
 * When using linked field values the intention is that the original value from the entity in Redux can be used to display the current value in this field. Potential overrides should be handled separately within local state and can be kept updated with the `onUpdate` function.
 *
 * When the user wants to save the updates we should check to see if an override has been set (a non-`undefined` value is present) and use that value in place of the original. This override value may either be an Attribute Ref or a standard MOS value (string, Media Ref etc.). This also allows us to have manual control of setting `updateMask`s in the parent form component.
 *
 * ## `MultiSelect`
 *
 * This is an alternate component exposed which appears and functions in a similar way to `SelectRef` but can be used when multiple refs can be selected at once.
 */
export function Select<
  TFormValues,
  TKey extends keyof TFormValues,
  TValue extends TFormValues[TKey] = TFormValues[TKey],
>(props: Props<TValue, TKey>) {
  const {
    description,
    error,
    field,
    isAssociated,
    isClearable,
    isDisabled,
    isSearchable,
    label,
    labelSupportingText,
    options,
    placeholder,
    warning,
  } = props
  const hasError = !!error
  const hasWarning = warning && !!warning.trim().length

  return (
    <FieldWrapper
      hasError={hasError || false}
      isAssociated={isAssociated}
      isDisabled={isDisabled}
    >
      <Label>
        {label}
        {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
        <Field<TFormValues, TKey, TValue> name={field} render={(props) => (
          <ReactSelect<Option<TValue>>
            classNamePrefix={classNamePrefix}
            className={hasError ? 'has-error' : ''}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            onBlur={() => props.blur()}
            onChange={(option: any) => { // MQS0003
              props.change(option ? option.value : undefined);
            }}
            options={options}
            placeholder={placeholder}
            value={options.filter((option) => option.value === props.value)}
          />)}
        />
      </Label>

      {hasError && (<Error role="alert" id={`${field}Alert`}>{error}</Error>)}
      {!hasError && hasWarning && (<Warning role="alert" id={`${field}Alert`}>{warning}</Warning>)}
      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}

type RefProps<TFormValues, TKey> = CommonProps & {
  readonly field: TKey;
  readonly options: ReadonlyArray<Option<entity.Ref>>;
  /**
   * A validation error.
   */
  readonly error?: string | FormErrors<TFormValues>;
  /**
   * A warning that there is a publishing issue.
   */
  readonly warning?: string;

  readonly onChange?: (value: any) => void;
  /**
   * Check the value you want of the item that is selected.
   */
  readonly equalityCheck?: (value: any, selectedValue: any) => void;
};

export function SelectRef<
  TFormValues,
  TKey extends keyof TFormValues,
  TValue extends entity.Ref | undefined = entity.Ref | undefined,
>(props: RefProps<TValue, TKey>) {
  const {
    description,
    error,
    field,
    isAssociated,
    isClearable,
    isDisabled,
    isSearchable,
    label,
    labelSupportingText,
    linkableOptions,
    options,
    placeholder,
    warning,
    onChange,
    equalityCheck,
  } = props
  const hasError = !!error
  const hasWarning = warning && !!warning.trim().length
  const allowCustomOverride = linkableOptions && linkableOptions.allowCustomOverride !== undefined ? linkableOptions.allowCustomOverride : true

  const [ isOverriding, setOverrideMode ] = useState(false)

  const renderLabel = () => (
    <>
      {linkableOptions ? (<LinkedFieldIcon size="base" />) : null}
      {label}
      {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
    </>
  )

  return (
    <FieldWrapper
      hasError={hasError || false}
      isAssociated={isAssociated}
      isDisabled={isDisabled}
      isOverriding={isOverriding}
      linkableOptions={linkableOptions}
    >
      <Label>
        {renderLabel()}
        <div style={{ position: 'relative' }}>
          <Field<TFormValues, TKey, entity.Ref> name={field} render={(props) => (
            <ReactSelect<Option<entity.Ref>>
              classNamePrefix={classNamePrefix}
              className={`
                ${hasError ? 'has-error' : ''}
                ${linkableOptions ? 'is-linked' : ''}
                ${isOverriding ? 'is-overriding' : ''}
              `}
              isDisabled={isDisabled || !!linkableOptions}
              isSearchable={isSearchable}
              isClearable={isClearable}
              onBlur={() => props.blur()}
              onChange={(option: any) => { // MQS0003
                props.change(option ? option.value : undefined);
                onChange && onChange(option ? option.value : undefined)
              }}
              options={options}
              placeholder={placeholder}
              value={
                options.filter((option) => equalityCheck ? equalityCheck(option.value, props.value as any) : entity.isRefEqual(option.value, props.value as any))
              }
            />)}
          />
          {linkableOptions && !isOverriding && (
            <LinkedFieldEditButton>
              <Button
                variant="subtle"
                isIcon={true}
                onClick={() => setOverrideMode(true)}
              >
                <IconEdit />
              </Button>
            </LinkedFieldEditButton>
          )}
        </div>
      </Label>

      {isOverriding && linkableOptions && (
        <LinkableOverride
          label={label}
          alternateFieldValues={linkableOptions.alternateFieldValues}
          customOverrideType={allowCustomOverride ? 'input' : undefined}
          customOverrideValues={linkableOptions.customOverrideValues}
          onCancel={() => setOverrideMode(false)}
          onUpdate={(value) => linkableOptions.onUpdate(value)}
        />
      )}

      {hasError && (<Error role="alert" id={`${field}Alert`}>{error}</Error>)}
      {!hasError && hasWarning && (<Warning role="alert" id={`${field}Alert`}>{warning}</Warning>)}
      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}

type MultiValueRemoveProps = {
  innerProps: any;
}
const MultiValueRemove = ({ innerProps }: MultiValueRemoveProps) => (
  <div {...innerProps} >
    <IconClose size="small" />
  </div>
)
const ClearIndicator = () => null;

export function MultiSelect<
  TFormValues,
  TKey extends keyof TFormValues,
  TValue extends entity.Ref[] | undefined = entity.Ref[] | undefined,
>(props: Omit<RefProps<TValue, TKey>, 'linkableOptions'>) {
  const {
    description,
    error,
    field,
    isAssociated,
    isDisabled,
    isSearchable,
    label,
    labelSupportingText,
    options,
    placeholder,
    warning,
  } = props
  const hasError = !!error
  const hasWarning = warning && !!warning.trim().length

  return (
    <FieldWrapper
      hasError={hasError || false}
      isAssociated={isAssociated}
      isDisabled={isDisabled}
    >
      <Label>
        {label}
        {labelSupportingText && (<LabelSupport> {labelSupportingText}</LabelSupport>)}
        <Field<TFormValues, TKey, entity.Ref[] | undefined> name={field} render={(props) => (
          <ReactSelect<Option<entity.Ref>>
            classNamePrefix={classNamePrefix}
            className={hasError ? 'has-error' : ''}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            onBlur={() => props.blur()}
            onChange={(option: any) => { // MQS0003
              const newOptions = option.map((o: any) => o.value)
              props.change(newOptions);
            }}
            options={options}
            placeholder={placeholder}
            value={options.filter((option) => {
              if (!props.value) return;
              return props.value.some(value => entity.isRefEqual(option.value, value))
            })}

            isMulti
            components={{ ClearIndicator, MultiValueRemove }}

            // change what react-select uses for a key for MultiValue component (can't be an object)
            // much cleaner than alt fix: github.com/JedWatson/react-select/issues/2656
            getOptionValue={o => o.value.id}
          />)}
        />
      </Label>

      {hasError && (<Error role="alert" id={`${field}Alert`}>{error}</Error>)}
      {!hasError && hasWarning && (<Warning role="alert" id={`${field}Alert`}>{warning}</Warning>)}
      {description && (<Description>{description}</Description>)}
    </FieldWrapper>
  )
}
