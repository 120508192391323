/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMediaPdf = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3c1.05 0 1.918.82 1.994 1.851L21 5v14c0 1.05-.82 1.918-1.851 1.994L19 21H5c-1.05 0-1.918-.82-1.994-1.851L3 19V5c0-1.05.82-1.918 1.851-1.994L5 3h14zM8 9H5.5v6H7v-2h1c.781 0 1.42-.593 1.493-1.355L9.5 11.5v-1c0-.781-.593-1.42-1.355-1.493L8 9zm11.5 0h-4v6H17v-2h1.5v-1.5H17v-1h2.5V9zM13 9h-2.5v6H13c.781 0 1.42-.593 1.493-1.355l.007-.145v-3c0-.781-.593-1.42-1.355-1.493L13 9zm0 1.5v3h-1v-3h1zm-5 0v1H7v-1h1z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMediaPdfString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3c1.05 0 1.918.82 1.994 1.851L21 5v14c0 1.05-.82 1.918-1.851 1.994L19 21H5c-1.05 0-1.918-.82-1.994-1.851L3 19V5c0-1.05.82-1.918 1.851-1.994L5 3h14zM8 9H5.5v6H7v-2h1c.781 0 1.42-.593 1.493-1.355L9.5 11.5v-1c0-.781-.593-1.42-1.355-1.493L8 9zm11.5 0h-4v6H17v-2h1.5v-1.5H17v-1h2.5V9zM13 9h-2.5v6H13c.781 0 1.42-.593 1.493-1.355l.007-.145v-3c0-.781-.593-1.42-1.355-1.493L13 9zm0 1.5v3h-1v-3h1zm-5 0v1H7v-1h1z"  fillRule="nonzero" />
  </svg>`

IconMediaPdf.displayName = 'IconMediaPdf'
