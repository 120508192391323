import React from 'react'

import { Link } from 'components/ui/link'
import { connect } from 'containers/store';
import { GetConfigResponse } from 'generated/mos/admin/config';
import { pick } from 'helpers/core';
import { space } from 'helpers/style'
import { LayoutUnauthenticatedPage } from 'layouts/unauthenticated-page';
import styled from 'styled';

import { accountBindableActionCreators } from 'domains/account/actions';
import { AuthSession } from 'services';
import { SignInForm } from 'domains/account/components/sign-in-form';
import { Org } from 'generated/mos/user';
import { ORG_NAME, ORG_SLUG } from '../../constants'

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(10)};
`

type DirectProps = {
  onComplete: () => void;
};

type ConnectedProps = {
  readonly session: AuthSession | undefined;
};

type ActionProps = Pick<typeof accountBindableActionCreators, 'accountLoginRequest'>;

type Props = DirectProps & ConnectedProps & ActionProps;

class SignInView extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.session) {
      this.props.onComplete();
    }
  }

  public render() {
    // const { config } = this.props;
    // if (!config || !config.publicOrgInfo) {
    //   return null;
    // }

    // const publicOrgInfo = config.publicOrgInfo;
    // if (!publicOrgInfo.orgRef) {
    //   return null;
    // }

    // const orgRef = publicOrgInfo.orgRef;.Ref

    return (
      <LayoutUnauthenticatedPage
        title={() => ORG_NAME}
        content={() => (
          <>
            <SignInForm
              orgSlug={ORG_SLUG}
              onSuccess={() => this.props.onComplete()}
            />
            <CtaContainer>
              <Link to="/forgot-password">
                Forgot your password?
              </Link>
            </CtaContainer>
          </>
        )}
      />
    );
  }
}

export const SignInPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.account, 'session'),
  pick(accountBindableActionCreators, 'accountLoginRequest'),
)(SignInView);
