// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMOSPoint, decodeMOSPolygon, decodeMessageRepeated, encodeMOSPoint, encodeMOSPolygon, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";
import { Point2D, Polygon2D } from "helpers/geo";

// Represents an array of long/lat coordinates
// 
export namespace CoordinateList {
  export const refName = "mos.geo.CoordinateList" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly values: ReadonlyArray<number>,
  }
  export const defaults: Entity = {
    type: refName,
    values: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.CoordinateList");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["values"] = ensureScalarRepeated(ctx, v["values"], "number");
      return out as any as Entity;
    }
  }();
}

// Represents an array of arrays of long/lat coordinates
// 
export namespace CoordinateList2d {
  export const refName = "mos.geo.CoordinateList2d" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly values: ReadonlyArray<CoordinateList.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    values: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.CoordinateList2d");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["values"] = decodeMessageRepeated(ctx, CoordinateList.codec, v["values"], "values");
      return out as any as Entity;
    }
  }();
}

// Represents an array of arrays of arrays of long/lat coordinates
// 
export namespace CoordinateList3d {
  export const refName = "mos.geo.CoordinateList3d" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly values: ReadonlyArray<CoordinateList2d.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    values: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.CoordinateList3d");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["values"] = decodeMessageRepeated(ctx, CoordinateList2d.codec, v["values"], "values");
      return out as any as Entity;
    }
  }();
}

export namespace CoordinateQuad {
  export const refName = "mos.geo.CoordinateQuad" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The coordinates are described in counter clockwise order from top left,
    // following the MGLCoordinateQuad structure in the iOS lib:
    // https://docs.mapbox.com/ios/api/maps/4.2.0/Structs/MGLCoordinateQuad.html#/MGLCoordinateQuad
    // 
    // The JS lib declares these coordinates as a clockwise 4-tuple for some reason,
    // but we can deal with that in the JS frontends:
    // https://docs.mapbox.com/mapbox-gl-js/style-spec/#sources-image
    readonly topLeft: Point2D | undefined,
    readonly bottomLeft: Point2D | undefined,
    readonly bottomRight: Point2D | undefined,
    readonly topRight: Point2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    topLeft: undefined,
    bottomLeft: undefined,
    bottomRight: undefined,
    topRight: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["topLeft"] = encodeMOSPoint(v.topLeft);
      obj["bottomLeft"] = encodeMOSPoint(v.bottomLeft);
      obj["bottomRight"] = encodeMOSPoint(v.bottomRight);
      obj["topRight"] = encodeMOSPoint(v.topRight);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.CoordinateQuad");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["topLeft"] = decodeMOSPoint(ctx, v["topLeft"]);
      out["bottomLeft"] = decodeMOSPoint(ctx, v["bottomLeft"]);
      out["bottomRight"] = decodeMOSPoint(ctx, v["bottomRight"]);
      out["topRight"] = decodeMOSPoint(ctx, v["topRight"]);
      return out as any as Entity;
    }
  }();
}

export namespace PlacedPoint {
  export const refName = "mos.geo.PlacedPoint" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly levelRef: { readonly typename: 'mos.structure.BuildingLevel' | 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    readonly point: Point2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    levelRef: undefined,
    point: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["levelRef"] = v.levelRef;
      obj["point"] = encodeMOSPoint(v.point);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.PlacedPoint");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["levelRef"] = v["levelRef"];
      out["point"] = decodeMOSPoint(ctx, v["point"]);
      return out as any as Entity;
    }
  }();
}

export namespace PlacedPolygon {
  export const refName = "mos.geo.PlacedPolygon" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly levelRef: { readonly typename: 'mos.structure.BuildingLevel' | 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    readonly polygon: Polygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    levelRef: undefined,
    polygon: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["levelRef"] = v.levelRef;
      obj["polygon"] = encodeMOSPolygon(v.polygon);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.geo.PlacedPolygon");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["levelRef"] = v["levelRef"];
      out["polygon"] = decodeMOSPolygon(ctx, v["polygon"]);
      return out as any as Entity;
    }
  }();
}

