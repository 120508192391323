import React, { ReactElement, PureComponent } from 'react'
import { createFormBag, FormBag, FormData, FormUpdateEvent } from 'react-formage'

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { IconType } from 'components/ui/icons'
import { InfiniteScroll } from 'components/ui/infinite-scroll'

import { ActionType } from 'containers/core'

import { Page } from 'generated/mos/pagination'

import { DataStatus, PaginatedList, Status, statusSelector } from 'helpers/status'

import {
  DisplayOptions,
  SearchContainer,
  SearchTools,
} from './styled'

type Entity = {
  readonly ref: { readonly typename: string; readonly id: string } | undefined;
  readonly name: string | undefined;
  readonly title: string | undefined;
};

type FormValues = {
  search: string;
};

type RenderStyle = {
  readonly icon: IconType;
  readonly name: string;
  readonly render: (entity: any, index: number) => ReactElement;
}

type Props = {
  readonly entityList: DataStatus<PaginatedList<Partial<Entity>>, string>;
  readonly filter?: Filter;
  readonly onSearch: () => void;
  readonly renderEntityStyles: ReadonlyArray<RenderStyle>;
  readonly requestRefType: string;
  readonly sortOrder?: string;
  readonly triggerRequest: ActionType<any>;
}

type Filter = {
  readonly type: string;
  readonly kinds?: ReadonlyArray<any>;
  readonly term?: string;
}

type State = {
  readonly filter: Filter | undefined;
  readonly renderStyleIndex: number;
  readonly searchTerm: string;
  readonly sortOrder: string;
  readonly formBag: FormBag<FormValues>;
  readonly typingTimeout: NodeJS.Timeout | undefined;
}

const pageSize = 32
const initialPage = {
  ...Page.defaults,
  pageSize,
  pageToken: '',
}

export class EntitySearch extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props)
    const formBag: FormBag<FormValues> = createFormBag({ search: '' })
    this.state = {
      filter: props.filter ? {
        ...props.filter,
        term: '',
      } : undefined,
      renderStyleIndex: 0,
      searchTerm: '',
      sortOrder: props.sortOrder || 'SORT_CREATION_DATE_DESC',
      formBag,
      typingTimeout: undefined
    }
  }

  public componentDidMount() {
    this.requestDataUpdate({ firstPage: true })
  }

  private onFormUpdate = (event: FormUpdateEvent<FormValues>) => {
    this.setState({ formBag: event.bag })
    const searchTerm = event.bag.values.search.trim()

    if (searchTerm !== this.state.searchTerm) {
      const filter = this.state.filter ? { ...this.state.filter, term: searchTerm } : undefined
      this.setState({
        filter,
        searchTerm,
      }, () => {
          this.props.onSearch()
          this.requestDataUpdate({ firstPage: true })
        }
      )
    }
  }

  private requestDataUpdate = (args?: { firstPage?: boolean }) => {
    // if only paginating, check there are further pages to fetch.
    const hasUnservedData = () => {
      const { entityList } = this.props
      if (statusSelector.hasData(entityList)) {
        return entityList.data.nextPage && (entityList.data.entityList.length < entityList.data.totalItems)
      }

      return true
    }

    const getPaginationData = () => {
      const { entityList } = this.props
      if (statusSelector.hasData(entityList)) {
        if (!args || args && !args.firstPage) {
          return entityList.data.nextPage
        }
      }

      return initialPage
    }

    const { filter, sortOrder, searchTerm, typingTimeout } = this.state
    const { entityList: { status }, triggerRequest } = this.props

    // clear the typing timeout if already set
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    // if in the correct status and we know there is data yet to be fetched, execute the call.
    if (status === Status.Idle || status === Status.Ready || status === Status.Updating) {
      if (args && args.firstPage || hasUnservedData()) {
        this.setState({ typingTimeout: setTimeout(() => {
          triggerRequest({
            type: this.props.requestRefType,
            filter: filter ? filter : searchTerm,
            page: getPaginationData(),
            sortOrder,
            searchTerm,
          })
        }, 300)})
      }
    }
  }

  private requestNextPage = () => {
    // next page has already been set from the last update. try to load another.
    this.requestDataUpdate()
  }

  private setDisplayStyle = (renderStyleIndex: number) => {
    this.setState({
      renderStyleIndex,
    })
  }

  public render() {
    const { entityList, renderEntityStyles } = this.props
    const { renderStyleIndex } = this.state
    return (
      <>
        <SearchContainer>
          <FormData
            bag={this.state.formBag}
            onUpdate={this.onFormUpdate}
          >
            <Input
              label="Search"
              labelHidden={true}
              field="search"
              placeholder="Search"
            />
          </FormData>
        </SearchContainer>

        <SearchTools>
          <span>{
            statusSelector.hasData(entityList)
              ? `${entityList.data.totalItems} found`
              : null
          }</span>

          {renderEntityStyles.length > 1 && (
            <DisplayOptions>
              {renderEntityStyles.map((style, index) => {
                const Icon = style.icon
                return (
                  <Button
                    key={style.name}
                    variant="subtle"
                    isIcon={true}
                    isSmall={true}
                    onClick={() => this.setDisplayStyle(index)}
                  >
                    <Icon size="base" color={index === renderStyleIndex ? 'primary' : undefined} />
                  </Button>
                )
              })}
            </DisplayOptions>
          )}
        </SearchTools>

        <InfiniteScroll
          height={300}
          renderEntity={renderEntityStyles[renderStyleIndex].render}
          requestNextPage={this.requestNextPage}
          statusList={entityList}
        />
      </>
    )
  }
}
