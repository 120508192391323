/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconMap = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM12 7a3.497 3.497 0 00-3.5 3.5C8.5 13.125 12 17 12 17s3.5-3.875 3.5-6.5C15.5 8.565 13.935 7 12 7zm0 4.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconMapString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM12 7a3.497 3.497 0 00-3.5 3.5C8.5 13.125 12 17 12 17s3.5-3.875 3.5-6.5C15.5 8.565 13.935 7 12 7zm0 4.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"  fillRule="nonzero" />
  </svg>`

IconMap.displayName = 'IconMap'
