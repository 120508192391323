// XXX(bw): All mapbox related code MUST use bog-standard loops and ifs rather
// than functional stuff like map/reduce/filter. This is a performance-critical
// section of code (in spite of some carefully and deliberately deployed O(n^2)
// stuff) and bog-standard constructs are faster (at least, they were in 2019).

import * as mapboxViewport from '@mapbox/geo-viewport';
import mapboxgl, { CameraOptions, MapboxEvent, MapboxOptions, Marker, Popup } from 'mapbox-gl';
import React from 'react'

// XXX: this is the only module from the mos-admin-web project we should ever import
// inside here - we should look to break both 'geo' and 'mapbox' into separate
// projects at some point, to be installed using npm.
import * as geo from 'helpers/geo';
import { getCenter } from 'helpers/locate'

import { Addable, AddableManager, AddableManagerInternal, AddControlPosition, computeAddableChanges, RetainedAddable } from './addable';
import { DefaultState, ImageDef, LayoutProperties, MapData, MapDataIndex, MapEvents, MapInitialBounds, MapSet, MapView, PaintProperties } from './core';
import { computeLayerChanges, LayerDef, LayerResolver } from './layers';
import { computeSourceChanges, prepareSource, SourceActions, SourceDef, SourceDefs } from './sources';
import { assertNever, normalizePosition, objectKeys, positionedEqual } from './util';

export { Marker, MapboxEvent, Popup };

// XXX(bw): we can't re-export due to limitations in typescript/babel, but aliasing
// seems to work well enough for now:
export type Addable<TMapSetState={}> = Addable<TMapSetState>;
export type AddableManager<TMapSetState=unknown> = AddableManager<TMapSetState>;
export type ImageDef = ImageDef;
export type LayerDef = LayerDef;
export type LayoutProperties = LayoutProperties;
export type MapEvents = MapEvents;
export type MapInitialBounds = MapInitialBounds;
export type MapSet<TMapSetState=unknown> = MapSet<TMapSetState>;
export type MapView = MapView;
export type PaintProperties = PaintProperties;
export type SourceDef = SourceDef;
export type SourceDefs = SourceDefs;

// XXX(bw): This is missing from the mapboxgl typings. Jumping to def via tsserver
// leads you to MapboxEvent, which is wrong.
export type MarkerEvent = {
  readonly target: Marker;
};

type Source =
  mapboxgl.VectorSource |
  mapboxgl.RasterSource |
  mapboxgl.RasterDemSource |
  mapboxgl.GeoJSONSource |
  mapboxgl.ImageSource |
  mapboxgl.VideoSource |
  mapboxgl.GeoJSONSourceRaw;

type Sources = { [key: string]: Source };

// FIXME: perhaps bridgePool should be in react context and exposed by a
// provider so we can actually test this thing.
const bridgePool: { [key: string]: MapboxBridge } = {};


// For now only style URL is supported
type MapStyle = string;


type Props = MapData & {
  // mapId selects a specific mapboxgl.Map instance from the internal pool.
  // This can be used to reuse specific map instances for specific areas of
  // your app. If not passed, the default mapboxgl.Map is used.
  readonly mapId?: string;
  readonly maxZoom?: number;
  readonly mapStyle?: MapStyle;
};


type MapboxContextDef = {
  readonly center?: geo.Position2D;
  readonly debug?: boolean;
  readonly maxZoom?: number;
  readonly zoom?: number;
  readonly devTools?: boolean;
  readonly mapStyle?: MapStyle;

  readonly animationOptions?: mapboxgl.AnimationOptions;
};

const defaultCenter: geo.Position2D = getCenter()
const defaultZoom = 16;

const defaultContext: MapboxContextDef = {
  center: defaultCenter,
  debug: false,
  maxZoom: 24,
  zoom: defaultZoom,
  devTools: false,
  animationOptions: {
    offset: [0, 0],
    duration: 500,
  },
};

export const MapboxContext = React.createContext<MapboxContextDef>(defaultContext);

export const MapboxProvider = MapboxContext.Provider;
export const MapboxConsumer = MapboxContext.Consumer;

export class Mapbox extends React.Component<Props> {
  public static contextType: React.Context<MapboxContextDef> = MapboxContext;
  public context!: React.ContextType<typeof MapboxContext>;

  // This is assigned in render() using a ref
  private mapContainer?: HTMLDivElement;

  // This is assigned on first render() from a pool.
  private bridge?: MapboxBridge;

  public componentDidMount() {
    if (this.bridge && this.mapContainer) {
      this.bridge.attach(this.mapContainer);
    }
  }

  public componentWillUnmount() {
    if (this.bridge) {
      this.bridge.detach();
    }
  }

  public render() {
    const { mapId = 'default' } = this.props;

    // FIXME: this needs to be runtime validated; if you pass 'undefined' as
    // the zoom level, you get 'Cannot invert matrix'.
    const context = { ...defaultContext, ...this.context };

    let mapData = this.props;

    if (bridgePool[mapId] === undefined) {
      const view = this.props.view;
      const zoom = view && view.zoom ? view.zoom : (context.zoom || defaultZoom);
      const center = view && view.center ? view.center : (context.center || defaultCenter);

      if (!view || view.zoom !== zoom || view.center !== center) {
        // If we have taken default view settings from the Context, they must
        // be reflected in the mapData as they are missing from the props. If
        // we don't do this, we can't track changes properly.
        mapData = { ...mapData, view: { zoom, center } };
      }

      const mapboxOptions: MapboxOptions = {
        // XXX: TS requires container to be set for some reason, but it's not
        // required. This connection is managed elsewhere.
        container: '',

        zoom: zoom,
        center: { lng: center[0], lat: center[1] },
        maxZoom: this.props.maxZoom || context.maxZoom,
        style: this.props.mapStyle || context.mapStyle,

        // FIXME: crappy defaults, should be migrated to context
        attributionControl: false,

        // XXX: This is included to call attention to a nasty undocumented mapbox gotcha.
        // Mapbox has a global fade for symbol layers that ignores any attempts to
        // override with more specific fades (i.e. image-opacity).
        //
        // From https://github.com/mapbox/mapbox-gl-js/issues/6519#issuecomment-390001993:
        //
        //   Label fade-in is a separate thing entirely. Symbols fade in and out because
        //   symbols are fundamentally different from any other layer type: symbols go
        //   through complex placement logic to display as much information as can fit well
        //   and makes sense, so for example, when you zoom out by a small fraction of a zoom
        //   level, the map may now be able to fit one more label in the space provided, so
        //   another label appears. We fade them in and out by default because labels
        //   appearing and disappearing suddenly is a pretty jarring popping effect. At
        //   present the duration of symbol fadein/fadeout is a global map property
        //   defaulting to 300ms — you can actually change this (by passing e.g.
        //   fadeDuration: 1000 as a map option). This option is currently not documented and
        //   is, notably, a global option — so it will apply to all symbols on the map, both
        //   in fadein and fadeout.
        //
        fadeDuration: 300,
      };

      const bridgeOptions = {
        debug: context.debug,
        devTools: !!context.devTools,
        animationOptions: context.animationOptions,
      };

      bridgePool[mapId] = new MapboxBridge(mapId, mapboxOptions, bridgeOptions);
    }

    this.bridge = bridgePool[mapId];
    this.bridge.updateMapData(this, this.props);

    return (
      <div className="mapbox-default"
        ref={(el) => (this.mapContainer = el || undefined)}
      />
    );
  }
}


type MapboxBridgeOptions = {
  readonly debug?: boolean;
  readonly devTools?: boolean;
  readonly animationOptions?: mapboxgl.AnimationOptions;
};


// MapboxBridge manages a representation of the state of a mapboxgl.Map instance
// for the purpose of computing and applying the minimal set of changes from
// an incoming set of React props.
//
// FIXME: this needs to be refactored to manage all possible combinations of three
// separate state lifecycles:
//
//  - attachment
//  - mapbox loaded
//  - map updated
//
// Essentially the problem stems from the fact that the order of all three of those
// lifecycles is not guaranteed. We have hit the limit for the number of hacks to
// make it sorta look like it works that we can reasonably tolerate, so the
// next time a bug related to this crops up, we are at the spill point.
//
class MapboxBridge {
  public readonly key: string;
  public readonly container: HTMLDivElement;
  public readonly mapOptions: MapboxOptions;
  public readonly bridgeOptions: MapboxBridgeOptions;
  public readonly map: mapboxgl.Map;

  private attachment?: HTMLElement;
  private newData?: MapData;
  private loaded = false;

  private currentIndex: MapDataIndex = new MapDataIndex({ mapSets: [] }, {});

  // XXX: There's a bit of overlap between currentData and currentEvents. There's
  // some finessing work left to do to separate them in here while retaining the
  // ability to recombine them for the Props but this hack will hold for now;
  // just make sure you only reference the events through currentEvents rather
  // than currentData.
  private currentData?: MapData;
  private currentEvents?: MapEvents;

  // Because we are simulating an imperative instruction with the initialBounds
  // (it's a bit of a hack, but the best option we found), it's not enough to
  // use currentData for this. We have to clear it on detach so the next user
  // of a recycled Bridge instance can set the initial bounds again, even if
  // it's technically the same bounds as the last time.
  //
  // Default to a blank object so strict equality check will always fail if
  // incoming value is 'undefined'.
  private initialBoundsIdentity: any = {};

  // React may (or may not) create new instances of the addables on each
  // render() so we can't rely on the instances in currentState or newState
  // to work out what to remove. We need to retain the exact instance which
  // was added to the map (using Addable.add()) because it will almost
  // certainly retain state that is required to tear itself down properly
  // when Addable.remove() is called:
  private retainedAddables: { [key: string]: RetainedAddable } = {};

  private devTools?: DevTools;

  private mapSetState: { [key: string]: object } = {};

  // We have to track the current and previous owner of the bridge because the
  // lifecycle events fire in an irritating and inconvenient order. When the
  // owner changes, the bridge's methods are called in the following order:
  //  - updateMapData (new owner)
  //  - detach (previous owner)
  //  - attach (new owner)
  //
  // This means we have to accept the owner when we accept updated data, but
  // we must not apply the map data until we have detached from the previous
  // owner and attached to the new one.
  //
  // We really shouldn't assume any order for detach and attach; React could
  // change the order these things happen in at any time without warning.
  private owner?: object;
  private prevOwner?: object;

  // {{{
  // View locks: we used to use a counter for this, but of _course_ you get
  // multiple zoomstart and movestart events for each zoomend or moveend. Of
  // course you do. Why on earth would you assume they'd be paired?
  private viewZooming: boolean = false;
  private viewMoving: boolean = false;
  // }}}

  public constructor(key: string, mapOptions: MapboxOptions, bridgeOptions: MapboxBridgeOptions={}) {
    this.key = key;
    this.container = document.createElement('div');
    this.mapOptions = mapOptions;
    this.bridgeOptions = bridgeOptions;

    const innerMap = new mapboxgl.Map({ ...mapOptions, container: this.container });

    this.map = bridgeOptions.debug ? debugMapHandler(innerMap) : innerMap;

    this.map.on('load', () => {
      this.loaded = true;
      this.applyMapData();
    });

    this.map.on('zoomstart', this.onZoomStart);
    this.map.on('movestart', this.onMoveStart);
    this.map.on('zoomend', this.onZoomEnd);
    this.map.on('moveend', this.onMoveEnd);

    // this.map.on('zoom', (e) => { console.log('zoom', this.map.getZoom()); });
    // this.map.on('move', (e) => { console.log('move', this.map.getCenter().toArray()); });

    if (bridgeOptions.devTools) {
      this.devTools = connectDevTools(this.key);
    }
  }

  private onZoomStart = () => { this.viewZooming = true; };
  private onMoveStart = () => { this.viewMoving = true; };
  private onZoomEnd = () => { this.viewZooming = false; this.viewChanged(); };
  private onMoveEnd = () => { this.viewMoving = false; this.viewChanged(); };

  private get viewLocked() {
    return this.viewZooming || this.viewMoving;
  }

  private viewChanged() {
    if (this.viewLocked || !this.currentEvents || !this.currentEvents.onViewChanged) {
      return;
    }

    const mapZoom = this.map.getZoom();
    const mapCenter = normalizePosition(this.map.getCenter());

    if (!this.currentData || !this.currentData.view ||
      this.currentData.view.zoom !== mapZoom ||
      !geo.positionEqual(mapCenter, this.currentData.view.center)
    ) {
      const view = {
        zoom: mapZoom,
        center: mapCenter,
      };
      this.currentEvents.onViewChanged(view);
    }
  }

  public getMapSetState(key: string): object | undefined {
    return this.mapSetState[key];
  }

  public setMapSetState(key: string, stateUpdate: object) {
    if (key in this.mapSetState) {
      this.mapSetState[key] = { ...this.mapSetState[key], ...stateUpdate };
    } else {
      this.mapSetState[key] = stateUpdate;
    }
    this.applyMapData(true);
  }

  public updateMapData(owner: object, newData: MapData) {
    this.owner = owner;
    this.newData = newData;
    this.applyMapData();
  }

  // applyMapData can be called to re-render the map
  public applyMapData(force?: boolean): boolean {
    if (!this.loaded || this.owner !== this.prevOwner) {
      return false;
    }

    if (!this.newData) {
      return false;
    }

    // XXX: We must make sure we have initialised the state for all incoming mapSets
    // before we try to index them as the MapSet API requires the state to get the child
    // objects.
    //
    // FIXME: we may need to compare the map set lists like we do with the layers, etc
    // so we can delete the state for mapSets that have been removed.
    if (this.newData.mapSets) {
      // FIXME: this is not ideal; we are indexing this twice:
      const newMapSetIndex: { [key: string]: MapSet } = {};

      for (const mapSet of this.newData.mapSets) {
        if (mapSet) {
          newMapSetIndex[mapSet.key] = mapSet;
          if (! (mapSet.key in this.mapSetState)) {
            this.mapSetState[mapSet.key] = {};
          }
        }
      }

      // If a map set has been removed, we have to purge its state. This must also be done
      // before we build the index because state is used when the index is built. If we
      // don't do it beforehand, the index will be built using old state:
      for (const oldKey of objectKeys(this.mapSetState)) {
        if (! (oldKey in newMapSetIndex)) {
          delete this.mapSetState[oldKey];
        }
      }
    }

    let changed = false;

    const nextData = this.newData;
    const prevIndex = this.currentIndex;
    const nextIndex = new MapDataIndex(nextData, this.mapSetState);

    this.currentIndex = nextIndex;
    this.currentData = nextData;
    this.currentEvents = nextData;

    if (this.devTools) {
      this.devTools.send(devToolsRepr(nextIndex));
    }

    // If these are the same object, this must have come from state so we don't
    // need to recalc anything:
    if (force || prevIndex.mapData.mapSets !== nextIndex.mapData.mapSets) {
      changed = this.applyMapSets(prevIndex, nextIndex) || changed;
    }

    if (nextData.initialBounds && nextData.initialBounds.identity !== this.initialBoundsIdentity) {
      if (this.forceBounds(nextData.initialBounds)) {
        changed = true;
      }

    } else {
      const nextView = nextIndex.mapData.view;
      const prevView = prevIndex.mapData.view;
      if (this.updateView(prevView, nextView) || changed) {
        changed = true;
      }
    }

    if (this.bridgeOptions.debug) {
      console.log('map changed', changed);
    }
    return changed;
  }

  private forceBounds(mapBounds: MapInitialBounds): boolean {
    this.initialBoundsIdentity = mapBounds.identity;

    this.map.fitBounds(geo.mut(geo.asBBox2D(mapBounds.bounds)), {
      padding: mapBounds.padding,
      // easing: FIXME
    });

    return true;
  }

  private updateView(prevView: MapView | undefined, nextView: MapView | undefined): boolean {
    if (!nextView) {
      return false;
    }

    let changed = false;
    let viewChanged = false;

    const camera: CameraOptions = {};
    if (nextView.zoom !== undefined && this.map.getZoom() !== nextView.zoom) {
      camera.zoom = nextView.zoom;
      viewChanged = true;
    }
    if (nextView.center !== undefined && !positionedEqual(this.map.getCenter(), nextView.center)) {
      camera.center = nextView.center as mapboxgl.LngLatLike;
      viewChanged = true;
    }

    if (viewChanged) {
      const isViewUpdateIntended = !prevView ||
        prevView.zoom !== nextView.zoom ||
        !positionedEqual(prevView.center, nextView.center);

      if (isViewUpdateIntended) {
        changed = true;
        if (this.bridgeOptions.animationOptions) {
          this.map.easeTo(camera, this.bridgeOptions.animationOptions);
        } else {
          this.map.jumpTo(camera, this.bridgeOptions.animationOptions);
        }
      }
    }

    return changed;
  }

  private applyMapSets(prevIndex: MapDataIndex, nextIndex: MapDataIndex): boolean {
    const sourceActions = computeSourceChanges(prevIndex.sources, nextIndex.sources);
    const imageActions = computeKeyedChanges(prevIndex.imagesByKey, nextIndex.imagesByKey);
    const layerActions = computeLayerChanges(sourceActions, prevIndex, nextIndex);
    const addableActions = computeAddableChanges(this.retainedAddables, nextIndex.addablesByKey);

    for (const remove of addableActions.removes) {
      if (remove !== this.retainedAddables[remove.addable.key]) {
        throw new Error('attempted to remove non-actual addable'); // XXX: sanity check
      }
      remove.manager.knockDown();
      if (remove.addable.destroy) {
        remove.addable.destroy();
      }
      delete this.retainedAddables[remove.addable.key];
    }

    const layerResolver = new LayerResolver(this.map, nextIndex.layersById);

    layerResolver.knockDown(layerActions);

    for (const image of imageActions.removes) {
      this.map.removeImage(image.key);
    }

    resolveSources(this.map, sourceActions, nextIndex.sourcesById);

    for (const image of imageActions.adds) {
      this.map.addImage(image.key, image.data, image.options);
    }

    layerResolver.buildUp(layerActions);

    for (const key of Object.keys(addableActions.changes)) {
      const resolver = addableActions.changes[key];
      switch (resolver.resolution) {
        case 'add': {
          const addable = nextIndex.addablesByKey[key];
          const manager = new AddableManagerImpl<any>(this, nextIndex.mapSetsByAddable.get(addable)!);
          addable.add(manager);
          this.retainedAddables[addable.key] = {addable, manager};
          break;
        }

      case 'update':
        resolver.update();
      break;

      default:
        assertNever(resolver); // TypeScript exhaustiveness check
      }
    }

    return addableActions.changed || imageActions.changed || layerActions.changed || sourceActions.changed;
  }

  public attach(to: HTMLElement) {
    if (this.attachment) {
      throw new Error(`pooled map ${this.key} already attached`);
    }

    // If the container doesn't get set to 100%/100%, the map.resize() call
    // will not fill the available space.
    this.container.style.height = '100%';
    this.container.style.width = '100%';

    to.appendChild(this.container);
    this.attachment = to;
    this.map.resize();

    // XXX(bw): this could re-attach the previously detached events. When
    // re-using a Mapbox, detach() happens after updateMapData() is called for
    // the new component, which is naaaaasty, so we need to retain the instance
    // of the caller of attach(), then check it in updateMapData. If it's different,
    // the update should be retained and run when the new attach happens.
    this.currentEvents = this.currentData;

    // XXX: ideally this would happen on detach so we don't leak this memory if the
    // subsequent attach never happens, but the detach/attach ordering is not guaranteed
    // and so this is the only safe place to do it for now.
    this.mapSetState = {};

    this.prevOwner = this.owner;
    this.applyMapData();
  }

  public detach() {
    if (!this.attachment) {
      throw new Error(`pooled map ${this.key} is not attached`);
    }
    this.currentEvents = undefined;
    this.initialBoundsIdentity = {};
    this.attachment.removeChild(this.container);
    this.attachment = undefined;

    this.map.stop(); // Stop animations currently in progress
  }
}


class AddableManagerImpl<TState=DefaultState> implements AddableManagerInternal<TState> {
  private undos: Array<() => void> = [];
  private bridge: MapboxBridge;
  private map: mapboxgl.Map;
  private mapSet: MapSet;

  public constructor(bridge: MapboxBridge, mapSet: MapSet) {
    this.bridge = bridge;
    this.map = this.bridge.map;
    this.mapSet = mapSet;
  }

  public get dangerousMap(): mapboxgl.Map {
    return this.map;
  }

  public undo(fn: () => void): void {
    this.undos.push(fn);
  }

  public addControl(control: mapboxgl.Control | mapboxgl.IControl, position?: AddControlPosition): void {
    this.map.addControl(control, position);
    this.undos.push(() => this.map.removeControl(control));
  }

  public get state(): Readonly<TState> | undefined {
    return this.bridge.getMapSetState(this.mapSet.key) as any;
  }

  public setState(stateUpdate: Partial<TState>) {
    this.bridge.setMapSetState(this.mapSet.key, stateUpdate);
  }

  // XXX: This signature sucks but we have to cope with the overloads, which are copied
  // directly from mapboxgl's typings:
  public on(a: any, b: any, c?: any): void {
    this.map.on(a, b, c);
    this.undos.push(() => this.map.off(a, b, c));
  }

  public knockDown(): void {
    for (const undo of this.undos) {
      undo();
    }
  }
}


function resolveSources(map: mapboxgl.Map, sourceActions: SourceActions, newSourcesById: { [key: string]: SourceDef }) {
  for (const sourceId of Object.keys(sourceActions.actions)) {
    const action = sourceActions.actions[sourceId];
    switch (action.resolution) {
    case 'add':
      map.addSource(sourceId, prepareSource(newSourcesById[sourceId]!));
    break;

    case 'remove':
      map.removeSource(sourceId);
    break;

    case 'replace':
      map.removeSource(sourceId);
      map.addSource(sourceId, prepareSource(newSourcesById[sourceId]!));
    break;

      case 'update': {
        const source = map.getSource(sourceId);
        if (!source) {
          throw new Error(`existing source ${sourceId} not found while updating sources`); // FIXME: better error text
        }
        action.update(source);
        break;
      }

    case 'keep':
      // All good!
    break;

    default:
      assertNever(action); // Exhaustiveness check
      return false; // This doesn't do anything other than keep TypeScript happy.
    }
  }
}


function computeKeyedChanges<T>(
  olds: { [key: string]: T },
  news: { [key: string]: T },
): {
  readonly changed: boolean;
  readonly adds: ReadonlyArray<T>;
  readonly removes: ReadonlyArray<T>;
} {
  const adds: T[] = [];
  const removes: T[] = [];

  for (const key of objectKeys(olds)) {
    if (! (key in news)) {
      removes.push(olds[key]);
    }
  }

  for (const key of Object.keys(news)) {
    if (key in olds) {
      continue;
    }
    adds.push(news[key]);
  }

  return { changed: adds.length + removes.length > 0, adds, removes };
}

function loggingProxy(name: string, target: any, options?: {
  exclude: string[];
  wrap: string[];
}) {
  options = { exclude: [], wrap: [], ...(options || {}) };

  const handler = {
    get: (target: any, propKey: any, receiver: any): any => {
      const targetValue = Reflect.get(target, propKey, receiver);

      if (typeof targetValue === 'function' && options!.exclude.indexOf(propKey) < 0) {
        return function(this: any, ...args: any) {
          console.log(`CALL ${name}`, propKey, args); // XXX: Expected use of console.log, do not remove:

          const v = targetValue.apply(this, args);
          if (v === target || v === this) {
            return this;
          }
          return options!.wrap.indexOf(propKey) >= 0 && typeof v === 'object' ? loggingProxy(`${name}.${propKey}`, v) : v;
        };

      } else {
        return propKey in options!.wrap ? loggingProxy(`${name}.${propKey}`, targetValue) : targetValue;
      }
    },
  };

  return new Proxy(target, handler);
}

function debugMapHandler(innerMap: mapboxgl.Map): mapboxgl.Map {
  return loggingProxy('mapbox', innerMap, {
    exclude: ['listens', 'fire'],
    wrap: ['getSource', 'getLayer'],
  });
}

interface DevTools {
  send(mapData: any): void;
}

function connectDevTools(mapId: string): DevTools | undefined {
  const extension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ || (window.top as any).__REDUX_DEVTOOLS_EXTENSION__;
  if (!extension) {
    console.warn('Please install/enable Redux devtools extension');
    return;
  }
  const conn = extension.connect({ name: 'Mapbox state' });

  let initialized = false;
  return {
    send(mapData: any) {
      if (!initialized) {
        conn.init(mapData);
        initialized = true;
      } else {
        conn.send(mapId, mapData);
      }
    },
  };
}

function devToolsStripUnserialisable(v: any): any {
  // XXX: This is a really nasty compromise. It's too difficult to go through and fix
  // everything up to be fully declarative - some properties will have things that
  // definitely can't be serialised, but it's way too much work at this point to go
  // through and whitelist absolutely everyhing. This crap blacklist will do for now:
  // devtools should never be used in production and should be controlled from a
  // developer's env var anyway:
  for (const key in v) {
    if (
      key === 'identity' ||
      key === 'dataIdentity' ||
      v instanceof HTMLElement
    ) {
      delete v[key];

    } else if (typeof v[key] === 'object') {
      v[key] = devToolsStripUnserialisable(v[key]);
    }
  }
  return v;
}

function devToolsRepr(index: MapDataIndex): object {
  const addables: object[] = [];
  for (const addable of index.addables) {
    if (addable.repr) {
      addables.push({ kind: addable.constructor.name, key: addable.key, repr: addable.repr() });
    } else {
      addables.push({ kind: addable.constructor.name, key: addable.key });
    }
  }

  const out = {
    initialBounds: index.mapData.initialBounds,
    view: index.mapData.view,
    layers: index.layers,
    images: index.images,
    sources: index.sources,
    addables: addables,
  };

  return devToolsStripUnserialisable(out);
}


// {{{ Public helper functions. See MQS0002.

type Dimensions = {
  readonly width: number;
  readonly height: number;
};

export function viewBounds(center: geo.Position2D, zoom: number, dimensions: Dimensions, tileSize?: number): geo.Extent2D {
  return geo.asExtent2D(mapboxViewport.bounds(center, zoom, [dimensions.width, dimensions.height], tileSize));
}

export function viewCenterZoom(
  bounds: geo.Extent2D,
  dimensions: Dimensions,
  options: {
    tileSize?: number;
    minZoom?: number;
    maxZoom?: number;
  } = {},
): mapboxViewport.Viewport {

  return mapboxViewport.viewport(
    [bounds.w, bounds.s, bounds.e, bounds.n],
    [dimensions.width, dimensions.height],
    options.minZoom,
    options.maxZoom,
    options.tileSize,
    false,
  );
}

// }}}
