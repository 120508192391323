export const mediaQueries = {
  palm: '@media only screen and (min-width: 375px)',  // not in use.
  hand: '@media only screen and (min-width: 768px)',
  lap:  '@media only screen and (min-width: 1440px)',
  desk: '@media only screen and (min-width: 2048px)', // 2k monitor.
  wall: '@media only screen and (min-width: 3840px)', // 4k monitor.
}

export const iconSizes = {
  large: 40,
  medium: 24,
  base: 20,
  small: 16,
}

export type IconSizes = keyof typeof iconSizes;
