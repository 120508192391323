import React from 'react'

import { StatusGuard } from 'components/ui/status-guard';
import { Modal } from 'components/ui/modal';
import { FullHeightWrapper, PanelBody, PanelHeader } from 'components/ui/side-panel';
import { connect } from 'containers/store';
import { Space } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import * as geo from 'helpers/geo';
import { statusSelector } from 'helpers/status';
import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresSelector } from 'domains/structures';
import { structuresActionCreators } from 'domains/structures/actions';
import { Structures, StructureRef } from 'domains/structures/entities';
import { SpatialScopeSelector } from 'domains/structures/components/spatial-scope-select';
import { DataStatus as StructuresDataStatus, GlobalMapState } from 'domains/structures/store';

import { spacesBindableActionCreators } from 'domains/spaces/actions';
import { SpacesMap } from 'domains/spaces/components/spaces-map';
import { DataStatus, SpacesFilterState, SpaceTypes } from 'domains/spaces/store';

import { SpaceCreatedEvent, SpaceCreateForm } from '../space-create-form';
import { filterSpaces } from './spaces-data';
import { SpacesListGroups } from './spaces-list-groups';

import { MapInitialBounds } from 'components/mapbox/core';
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity';

type DirectProps = {
  readonly onCreate: (e: {ref: Space.Ref}) => void;
  readonly navBackRoute?: string;
};

type ConnectedProps = {
  readonly spacesFilters: SpacesFilterState;
  readonly spacesList: DataStatus<ReadonlyArray<Space.Entity>>;
  readonly spaceTypes: DataStatus<SpaceTypes>;
  readonly globalMap: GlobalMapState;
  readonly structures: StructuresDataStatus<Structures>;
};

type ActionProps =
  Pick<typeof structuresActionCreators, 'structuresLoadRequest' | 'globalMapUpdate'> &
  Pick<typeof spacesBindableActionCreators, 'spacesRequest' | 'spaceTypesRequest' | 'spacesFiltersUpdate'>
;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly showNewSpaceModal: boolean;

  // Changing initialBounds.identity is how we tell <Mapbox /> it needs to focus
  // on the currently selected initialBounds object, see comments in <Mapbox />
  // for explanation why. We keep track of initialBoundsIdentity in state and pass
  // it down. To focus on whatever initialBounds is just increment this value.
  readonly initialBoundsIdentity: number;
  readonly activeSpace: Space.Entity | undefined;
};

class SpacesListPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showNewSpaceModal: false,
      initialBoundsIdentity: 0,
      activeSpace: undefined,
    };
  }

  public componentDidMount()  { this.refresh(); }
  public componentDidUpdate() { this.refresh(); }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.spacesList.status)) {
      this.props.spacesRequest();
    }
    if (statusSelector.shouldLoad(this.props.spaceTypes.status)) {
      this.props.spaceTypesRequest();
    }
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private onSpatialChange = (ref?: StructureRef) => {
    this.props.globalMapUpdate({ activeRef: ref });

    // This forces the visible spaces to change from whatever you last clicked on in the
    // list view, to the spaces for the scope you just filtered by:
    this.setState({ activeSpace: undefined });
  }

  private onSpaceCreated = (e: SpaceCreatedEvent) => {
    this.setState({ showNewSpaceModal: false });
    this.props.globalMapUpdate({ activeRef: e.siteRef });
    this.props.onCreate({ ref: e.ref });
  }

  private focusSpaceOnMap = (item: Space.Entity): void => {
    const boundary = structuresSelector.firstBoundary(item.boundaries);
    if (!boundary) {
      return;
    }

    // set current item's level as activeRef (to show in SSS)
    this.props.globalMapUpdate({ activeRef: boundary.levelRef });

    // increment identity so map will recalculate center from bounds
    this.setState((state) => ({ initialBoundsIdentity: state.initialBoundsIdentity + 1 }));

    // add clicked space to state so it can be highlighted on the map
    this.setState({ activeSpace: item });
  }

  public render() {
    const spacesList = statusSelector.data(this.props.spacesList) || [];

    const structures = statusSelector.data(this.props.structures);
    const spaceTypes = statusSelector.data(this.props.spaceTypes);
    const spacesData = structures && spaceTypes?
      filterSpaces(spacesList, structures, this.props.globalMap.activeRef, this.props.spacesFilters, spaceTypes) :
      undefined;

    let initialBounds: MapInitialBounds | undefined;
    if (this.state.activeSpace) {
      const boundary = structuresSelector.firstBoundary(this.state.activeSpace.boundaries);
      const poly = boundary ? boundary.polygon : undefined;
      if (poly) {
        initialBounds = {
          padding: 60, // FIXME put in context
          identity: this.state.initialBoundsIdentity,
          bounds: geo.bbox2D(poly),
        };
      }
    }

    const sidebar = (
      <FullHeightWrapper>
        <PanelHeader
          title="Spaces"
          onAdd={() => this.setState({ showNewSpaceModal: true })}
          searchText={this.props.spacesFilters.search}
          onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.spacesFiltersUpdate({ search: e.target.value })}
          onSearchClear={() => this.props.spacesFiltersUpdate({ search: '' })}
        />

        <PanelBody>
          <StatusGuard status={this.props.structures} mode="hideErrors" fullHeight>
            <StatusGuard status={this.props.spacesList} mode="hideErrors" fullHeight>
              <StatusGuard status={this.props.spaceTypes} mode="hideErrors" fullHeight>
                <FullHeightWrapper>
                  <SpacesListGroups
                    structures={structures}
                    spacesData={spacesData}
                    globalMap={this.props.globalMap}
                    spacesFilters={this.props.spacesFilters}
                    spacesFiltersUpdate={this.props.spacesFiltersUpdate}
                    focusSpaceOnMap={this.focusSpaceOnMap}
                    highlightRef={undefined} // TODO
                    setHighlightedRef={(e: Space.Ref |  undefined) => undefined} // TODO
                  />
                </FullHeightWrapper>
              </StatusGuard>
            </StatusGuard>
          </StatusGuard>
        </PanelBody>
      </FullHeightWrapper>
    );

    return (
      <>
        {this.state.showNewSpaceModal && (
          <Modal
            onClose={() => this.setState({ showNewSpaceModal: false }) }
            header={ <h2 className="text-h4">Create a New Space</h2> }
          >
            <SpaceCreateForm
              onSuccess={this.onSpaceCreated}
              structures={structures}
            />
          </Modal>
        )}

        <LayoutLocateEditPage
          nav={() => (
            <PrimaryNavigationEditEntity 
              title="Edit Spaces"             
              navBackRoute={this.props.navBackRoute}
              darkTheme        
            />
          )}
          sidebar={() => sidebar}
          content={() => (
            <>
              <SpatialScopeSelector
                structures={structures}
                structureRef={this.props.globalMap.activeRef}
                onChange={this.onSpatialChange}
              />
              <SpacesMap
                mapView={this.props.globalMap.view}
                onMapViewChanged={this.props.globalMapUpdate}

                spacesList={spacesList}
                structures={structures}
                structureRef={this.props.globalMap.activeRef}
                initialBounds={initialBounds}
                activeSpace={this.state.activeSpace}
              />
            </>
          )}
        />
      </>
    );
  }
}

export const SpacesListPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    ...pick(store.spaces, 'spacesFilters', 'spacesList', 'spaceTypes'),
    ...pick(store.structures, 'globalMap', 'structures'),
  }),
  {
    ...pick(spacesBindableActionCreators, 'spacesRequest', 'spaceTypesRequest', 'spacesFiltersUpdate'),
    ...pick(structuresActionCreators, 'structuresLoadRequest', 'globalMapUpdate'),
  },
)(SpacesListPageView);
