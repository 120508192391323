/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconAngle = (props: Props) => (
  <Icon {...props}>
    <path className="path1"  fillRule="nonzero" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  </Icon>
)

// for use with mapbox addables.
export const IconAngleString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1"  fillRule="nonzero" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  </svg>`

IconAngle.displayName = 'IconAngle'
