import React, { useEffect, useState } from 'react'

import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SectionHeader } from 'components/ui/section-header'
import { Confirm } from 'components/ui/modal'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'
import { spacesBindableActionCreators } from 'domains/spaces/actions'
import { SpacesStore } from 'domains/spaces/store'

import { Space } from 'generated/mos/structure'

import { statusSelector, Status } from 'helpers/status'
import { pick } from 'helpers/core'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { getTourSubnav } from '../tours/subnav'
import { AtmosphericItem } from './atmospheric-item'

import { AtmosphericAudio } from '../../types'

type ActionProps = Pick<WamActionCreators, 'wamGetGuidedTourRequest' | 'wamDeleteAtmosphericZoneRequest'> &
  Pick<typeof spacesBindableActionCreators, 'spacesRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps =
  Pick<WamAppState['tours'], 'tourUpdate'> &
  Pick<WamAppState['atmosphericZones'], 'atmosphericZoneDelete'> &
  Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const AtmosphericZonePageView = (props: Props) => {
  const {
    projectId,
    tourId,
    wamGetGuidedTourRequest,
    tourUpdate,
    historyPush,
    wamDeleteAtmosphericZoneRequest,
    atmosphericZoneDelete,
    toastNotification,
    spacesList,
    spacesRequest,
  } = props

  const data = statusSelector.data(tourUpdate)
  const spacesListData = statusSelector.data(spacesList)

  const [deleteAtmosphericModal, setDeleteAtmosphericModal] = useState<AtmosphericAudio.Entity | null>(null)

  useEffect(() => {
    spacesRequest()
    wamGetGuidedTourRequest({ ecmsId: tourId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (atmosphericZoneDelete.status === Status.Complete) {
      wamGetGuidedTourRequest({ ecmsId: tourId })
      toastNotification({
        type: 'success',
        text: 'Atmospheric sound deleted successfully.',
      })
    }
    if (atmosphericZoneDelete.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Atmospheric sound could not be deleted',
      })
    }
  }, [atmosphericZoneDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDeleteAtmospheric = (zone: AtmosphericAudio.Entity) => {
    wamDeleteAtmosphericZoneRequest({ ecmsId: tourId, ecmsAtmosSlug: zone.slug })
    setDeleteAtmosphericModal(null)
  }

  // get Location display text for list item
  const getLocationDisplayText = (activeSpaces: ReadonlyArray<Space.Ref>): string => {
    switch(activeSpaces.length) {
      case 0: return 'No Spaces'
      case 1:
        const space = spacesListData && spacesListData.find((space) => Space.mustRef(space.ref).id === activeSpaces[0].id)
        return space ? space.displayName || space.name : 'No Spaces'
      default:
        return 'Multiple Spaces'
    }
  }

  return (
    <>
      {deleteAtmosphericModal &&
        <Confirm
          title="Delete Atmospheric sound?"
          onClose={() => setDeleteAtmosphericModal(null)}
          message={`Are you sure you want to permanently delete "${deleteAtmosphericModal.title || `Untitled - ${deleteAtmosphericModal.slug}`}"?`}
          confirmLabel="Delete"
          onConfirm={() => onDeleteAtmospheric(deleteAtmosphericModal)}
          danger
        />
      }
      <LayoutListOrFormPage
        nav={() => (
          <PrimaryNavigationEditEntity
            darkTheme
            title="Edit tour"
            navBackRoute={`/project/${projectId}/tours`}
          />
        )}
        title={data ? data.title : ''}
        subnav={() => <SecondaryNavigation navItems={getTourSubnav(projectId, tourId)} />}
        content={() => (
          <StatusGuard status={tourUpdate}>
            <StatusGuard status={spacesList}>
              <SectionHeader heading={`${data ? data.atmosphericZones.length : 0} atmospheric zones`} />
              {data &&
                data.atmosphericZones.map((zone) => {
                  const { title, slug, activeSpaces } = zone
                  return (
                    <AtmosphericItem
                      key={title}
                      title={title || `Untitled - ${slug}`}
                      tourId={tourId}
                      atmosphericZoneId={slug}
                      historyPush={historyPush}
                      projectId={projectId}
                      locationDisplayText={getLocationDisplayText(activeSpaces)}
                      deleteHandler={() => setDeleteAtmosphericModal(zone)}
                    />
                  )
                })
              }
            </StatusGuard>
          </StatusGuard>
        )}
      />
    </>
  )
}

export const AtmosphericZonePage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.tours, 'tourUpdate'),
    ...pick(store.wam.atmosphericZones, 'atmosphericZoneDelete'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(actionCreators, 'wamGetGuidedTourRequest', 'wamDeleteAtmosphericZoneRequest'),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(AtmosphericZonePageView)
