import React from 'react'

import styled from 'styled';
import { space } from 'helpers/style'

const Wrapper = styled.div`
	position: absolute;
	top: 2px;
	width: 100%;
	pointer-events: none;
	z-index: 1000000;
	text-align: center;
`;

const Pill = styled.span<{ staging: boolean }>`
  display: inline-block;
	font-size: 10px;
	border-radius: 1em;
	color: black;
	padding: ${space(1)} ${space(2)};
  text-align: center;
  background-color: ${({ staging }) => staging ? 'orange' : 'beige'};
`;

export const DisplayEnv = () => {
  const locationOrigin = window.location.origin;

  let env = null;

  // FIXME: this should not be hardcoded here.
  if (locationOrigin.includes('localhost')) {
    env = 'DEVELOPMENT';
  } else if (locationOrigin.includes('herokuapp')) {
    env = 'STAGING';
  }

  return (
    <Wrapper>
      <Pill staging={env === 'STAGING'}>
        ENV: <strong>{env}</strong>
      </Pill>
    </Wrapper>
  );
};
