import { areObjectsEqual, assertNever } from "helpers/core";
import { FormBag } from "react-formage";
import { LanguageCode, LANGUAGES } from "./constants";

import { TranslatableString } from './types'

export const parseLanguage = (languageCode: LanguageCode) => {
  switch (languageCode) {
    case 'en':
      return 'English'
    case 'de':
      return 'German'
    case 'it':
      return 'Italian'
    case 'zhHans':
      return 'Chinese Simplified'
    default:
      assertNever(languageCode)
  }
}

// if a prefix is given, use it an convert formkey to camelcase
const assembleFormKey = <TFormValues>(attribute: string, prefix?: string): keyof TFormValues => (
  prefix ?`${prefix}${attribute.charAt(0).toUpperCase()}${attribute.slice(1)}` as keyof TFormValues: attribute as keyof TFormValues
)

// get all values of a given language in an object
export const getFormValuesForLanguage = <TEntity, TFormValues>(
  entity: TEntity,
  language: LanguageCode,
  prefix?: string
): TFormValues => (
  Object.keys(entity)
    .reduce((acc, attribute) => {
      // if a prefix is given, use it an convert formkey to camelcase
      const formKey = assembleFormKey<TFormValues>(attribute, prefix)

      const value = entity[attribute as keyof TEntity] as TranslatableString

      return {
        ...acc,
        [`${formKey}`]: value && value[language]
      }
    }, {} as TFormValues)
)

// get all language values each attribute key
export const getLanguageValuesInForm = <TFormBags, TFormValues>(
  attributeKeys: ReadonlyArray<string>,
  formBags: TFormBags,
  prefix?: string
): { [key: string]: TranslatableString } => (
  // loop through each attribute key provided and create a TranslataleString object for its value
  attributeKeys.reduce((acc, attributeKey) => {
    const formValues = LANGUAGES.reduce((acc2, language) => {
      const formBag = formBags[language as keyof TFormBags] as any as FormBag<TFormValues>
      const formKey = assembleFormKey<TFormValues>(attributeKey, prefix)

      const currentAttrValue = formBag.values[formKey] as TranslatableString

      return {
        ...acc2,
        [language]: currentAttrValue
      }
    }, {})

    return {
      ...acc,
      [attributeKey]: formValues
    }
  }, {} as {[key: string]: TranslatableString})
)

// compare the values in two TranslationFormBags
export const areTranslationFormBagsEqual = <TFormBags, TFormValues>(
  firstTranslationFormBag: TFormBags,
  secondTranslationFormBag: TFormBags
): boolean => {
  for (const language of LANGUAGES) {
    const formBag1 = firstTranslationFormBag[language as keyof TFormBags] as any as FormBag<TFormValues>
    const formBag2 = secondTranslationFormBag[language as keyof TFormBags] as any as FormBag<TFormValues>

    if (!areObjectsEqual(formBag1.values, formBag2.values)) {
      return false
    }
  }

  return true
}

