import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled'
import { mediaQueries } from 'styled/core'

import { createFormBag, FormBag, FormData } from 'react-formage'

import { FieldSection, Fieldset } from 'components/ui/fieldset'
import { FooterEditEntity, FOOTER_HEIGHT } from 'components/ui/footer'
import { Input } from 'components/ui/input'
import { Description, Title } from 'components/ui/list-item-label'
import { NAVBAR_HEIGHT } from 'components/ui/primary-navigation'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { LanguageCode, LANGUAGES } from 'domains/org/wam/constants'
import { WamAppState, WamStore } from 'domains/org/wam/store'
import { Directions, UpdateTourDirectionsRequest, Outro, GuidedTour } from 'domains/org/wam/types'
import { getLanguageValuesInForm, getFormValuesForLanguage, parseLanguage, areTranslationFormBagsEqual } from 'domains/org/wam/utils'


import { pick } from 'helpers/core'
import { TranslationFormBags } from 'helpers/schema'
import { Status, statusSelector } from 'helpers/status'
import { space } from 'helpers/style'

import { LayoutFullWidthPage } from 'layouts/full-width-page'

const HEADER_HEIGHT = 100

const Scroll = styled.div`
  display: flex;
  padding-top: ${space(6)};
  padding-bottom: ${space(15)};
  overflow-x: scroll;
  height: calc(100vh - ${(NAVBAR_HEIGHT + FOOTER_HEIGHT + HEADER_HEIGHT)}px);
`

const Panels = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-left: ${space(9)};
  padding-right: ${space(9)};

  ${mediaQueries.lap} {
    padding-left: ${space(18)};
    padding-right: ${space(18)};
  }

  @media only screen and (min-width: 1600px) {
    padding-left: calc(${space(18)} + (100% - 1600px) / 2);
    padding-right: calc(${space(18)} + (100% - 1600px) / 2);
  }
`

const Panel = styled.div`
  width: 60%;
  flex: 0 0 auto;
  &:not(:last-child) {
    padding-right: ${space(6)};
    margin-right: ${space(6)};
    border-right: 1px solid ${({ theme }) => theme.color.grayL60};
  }

  ${mediaQueries.hand} {
    width: 500px;
  }
`

const CategoryHeader = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: ${space(8)};
  padding-bottom: ${space(6)};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL60};
`

const FormTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.light};

  margin-bottom: ${space(4)};
  margin-top: 0;
`

type ActionProps = Pick<
  WamActionCreators,
  'wamGetGuidedTourRequest' |
  'wamUpdateTourDirectionsRequest'
> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['tours'], 'tourUpdate' | 'tourDirectionsUpdate'>

type DirectProps = {
  projectId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

type FormValues = {
  [key: string]: string | undefined
}

type FormProps = {
  data: GuidedTour.Entity
  formBag: FormBag<FormValues> | undefined
  language: LanguageCode
  setFormBag: (bag: FormBag<FormValues>) => void
}

const TranslationForm = (props: FormProps) => {
  const { formBag, language, setFormBag, data } = props

  const isDisabled = language === 'en'

  const parsedLanguage = parseLanguage(language)

  return formBag ? (
    <Panel>
      <FormData
        bag={formBag}
        onUpdate={(event) => {
          setFormBag(event.bag)
        }}
        validate={() => ({})}
        >
        <Fieldset legend={parsedLanguage ? parsedLanguage : ''} >
          {data.directions &&
          <>
            <FormTitle>Directions</FormTitle>
            <Input
              field="directionsTitle"
              label="Title"
              isDisabled={isDisabled}
            />
            <Input
              field="directionsImageAltText"
              label="Image Alt-Text"
              isDisabled={isDisabled}
            />
            <Input
              field="directionsTranscript"
              label="Transcript"
              isDisabled={true}
            />
          </>
          }
          {data.outro && data.directions &&
            <FieldSection />
          }
          {data.outro &&
            <>
              <FormTitle>Outro</FormTitle>
              <Input
                field="outroTranscript"
                label="Transcript"
                isDisabled={true}
              />
            </>
          }
        </Fieldset>
      </FormData>
    </Panel>
  ) : null
}

const PropertiesWayfindingTranslationsPageView = (props: Props) => {
  const {
    historyPush,
    projectId,
    toastNotification,
    tourDirectionsUpdate,
    tourId,
    tourUpdate,
    wamGetGuidedTourRequest,
    wamUpdateTourDirectionsRequest,
  } = props

  // set up form values
  const [ initialValues, setInitialValues ] = useState<TranslationFormBags<FormValues, LanguageCode> | undefined>(undefined);
  const [ formBags, setFormBags ] = useState<TranslationFormBags<FormValues, LanguageCode> | undefined>(undefined);

  const data = statusSelector.data(tourUpdate)

  const tourDirections = data && data.directions
  const tourOutro = data && data.outro

  const backLocation = `/project/${projectId}/tour/${tourId}/properties`;
  const modified = formBags && initialValues && !areTranslationFormBagsEqual<TranslationFormBags<FormValues, LanguageCode>, FormValues>(formBags, initialValues)

  const hasSubmitted = useRef(false)

  useEffect(() => {
    wamGetGuidedTourRequest({ ecmsId: tourId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tourUpdate && tourUpdate.status === Status.Ready) {
      const initValues = getInitialValues()
      setInitialValues(initValues)
      setFormBags(initValues)
    }

    if (tourDirectionsUpdate.status === Status.Ready && hasSubmitted.current) {
      toastNotification({ type: 'success', text: 'Translations updated' })
      hasSubmitted.current = false
    }

    if (tourDirectionsUpdate.status === Status.Failed && hasSubmitted.current) {
      toastNotification({ type: 'error', text: 'Translations could not be updated' })
      hasSubmitted.current = false
    }
  }, [tourUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  // get initial form values
  const getInitialValues = () => {
    const initialValues = LANGUAGES.reduce(
      (acc, language) => {

        // tourDirections && tourOutro

        // get only the attributes that have TranslatableString values
        const tourDirectionsTranslatables: Pick<Directions.Entity, 'title' | 'imageAltText' | 'transcript'> | undefined =
          tourDirections && pick(tourDirections, 'title', 'imageAltText', 'transcript')

        const initialDirectionsFormValues = tourDirectionsTranslatables && getFormValuesForLanguage<typeof tourDirectionsTranslatables, FormValues>(
          tourDirectionsTranslatables,
          language,
          'directions'
        )

        // get only the attributes that have TranslatableString values
        const tourOutroTranslatables: Pick<Outro.Entity, 'transcript'> | undefined =
        tourOutro && pick(tourOutro, 'transcript')

        const initialOutroFormValues = tourOutroTranslatables && getFormValuesForLanguage<typeof tourOutroTranslatables, FormValues>(
          tourOutroTranslatables,
          language,
          'outro'
        )

        const initialFormValues = {
          ...initialDirectionsFormValues,
          ...initialOutroFormValues
        }

        return {
          ...acc,
          [language]: createFormBag(initialFormValues)
        }
      }, {} as TranslationFormBags<FormValues, LanguageCode>)

    return initialValues
  }

  const updateFormBags = (languageCode: LanguageCode) =>  (bag: FormBag<FormValues>) => {
    if(formBags) {
      setFormBags({
        ...formBags,
        [languageCode]: bag
      })
    }
  }

  const onSaveHandler = () => {
    // construct update directions payload
    if (tourDirections && formBags) {
      const attributeKeys = ['title', 'imageAltText']
      const allValues = getLanguageValuesInForm<typeof formBags, FormValues>(attributeKeys, formBags, 'directions')

      const payload = {
        ecmsId: tourId,
        destinationSpaces: tourDirections.destinationSpaces,
        ...allValues
      } as UpdateTourDirectionsRequest

      wamUpdateTourDirectionsRequest(payload)

      hasSubmitted.current = true
    }
  }

  return (
    <LayoutFullWidthPage
      nav={() => (
        <PrimaryNavigationEditEntity
          title="Edit Tour"
          onConfirm={modified ? () => historyPush(backLocation) : undefined}
          navBackRoute={backLocation}
        />
      )}
      header={() => (
        <>
          <CategoryHeader>
            <Description>
              <Title>Properties & Wayfinding</Title>
            </Description>
          </CategoryHeader>
        </>
      )}
      content={() => (
        <StatusGuard status={tourUpdate} mode="form">
          <Scroll>
            <Panels>
              {formBags && data && LANGUAGES.map((language) => (
                <TranslationForm
                  key={language}
                  language={language}
                  formBag={formBags[language]}
                  setFormBag={updateFormBags(language)}
                  data={data}
                />
              ))}
            </Panels>
          </Scroll>
        </StatusGuard>
      )}
      footer={() => (
        <FooterEditEntity
          entity={tourUpdate}
          modified={modified}
          onCancel={() => historyPush(backLocation)}
          onSave={() => onSaveHandler()}
        />
      )}
    />
  )
}

export const PropertiesWayfindingTranslationsPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
  ...pick(store.wam.tours, 'tourUpdate', 'tourDirectionsUpdate'),
  }),
  {
    ...pick(
      actionCreators,
      'wamGetGuidedTourRequest',
      'wamUpdateTourDirectionsRequest'
    ),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(PropertiesWayfindingTranslationsPageView)
