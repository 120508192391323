import React from 'react'
import { NavLink } from 'react-router-dom'

import { IconAngle } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled from 'styled';
import { UserMenu } from './user-menu';
import { solidCtaFocus } from '../button';
import { HelpScout } from './helpscout';
import { ORG_SLUG } from '../../../constants'

const LINK_HEIGHT = 52;
export const NAVBAR_HEIGHT = 56;

const NavLinkBase = styled(NavLink)`
  display: flex;
  height: ${LINK_HEIGHT}px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.grayL60};
  cursor: pointer;
  text-decoration: none;
  transition: ${({ theme }) => theme.transition.base};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  &:hover {
    color: ${({ theme }) => theme.color.grayL80};
  }
`;

export const NavLinkItem = styled(NavLinkBase)`
  margin: ${space(1)} ${space(4)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  &.is-active {
    color: ${({ theme }) => theme.color.white};
    border-bottom: 4px solid ${({theme }) => theme.color.primary};
    margin-bottom: 0px;
  }

  ${solidCtaFocus}
`;

const NavIconLinkWrapper = styled(NavLinkBase)`
  width: 24px;
`;

export const NavReturnLink = (props: { to: string }) => (
  <>
    <NavIconLinkWrapper to={props.to}>
      <IconAngle color="grayL60" rotate={90} size="base" />
    </NavIconLinkWrapper>
    <NavLinkItem to={props.to} style={{ marginLeft: 5 }}>Back</NavLinkItem>
  </>
);

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primaryD80};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT}px;
  padding: ${space(3)};
`;

export const Row = styled.div`
  display: flex;    
  justify-content: center;
  align-items: center;
`;

type Props = {
  readonly page?: {
    title: string;
    url: string;
  };
};


// only exported for storybook props table.
export const PrimaryNavigation = (props: Props) => {
  return (
    <HeaderWrapper>
      <Row>
        {ORG_SLUG === "wam" ? 
          <>
            <NavLinkItem to="/" exact activeClassName="is-active">Projects</NavLinkItem>
            <NavLinkItem to="/analytics" exact activeClassName="is-active">Analytics</NavLinkItem>
            <NavLinkItem to="/sitemanagement" activeClassName="is-active">Site Management</NavLinkItem>
          </> :
          <NavLinkItem to="/locate" activeClassName="is-active"> Site Management</NavLinkItem>
        }
      </Row>
      <Row>
        <HelpScout darkTheme />
        <div style={{ marginLeft: 8 }}>
          <UserMenu darkTheme />
        </div>
      </Row>
    </HeaderWrapper>
  );
};
