import { Effect, put, takeLeading } from 'redux-saga/effects'

import { Page } from 'generated/mos/pagination'
import { ListReleaseControlsRequest, ListReleaseControlsResponse } from 'generated/mos/releasecontrolsaccess'
import { SetReleaseStatusRequest, SetReleaseStatusResponse } from 'generated/mos/releasecontrolsmanagement'

import { unaryGRPC } from 'services/unary-grpc'
import { failureStatusMessage } from 'helpers/status'

import { ReleaseControlsActions, actionCreators } from './actions'

const releaseControlsTakeLeading = <TActionKey extends keyof ReleaseControlsActions>(
  pattern: TActionKey,
  worker: (action: ReleaseControlsActions[TActionKey]) => any,
) => {
  return takeLeading(pattern, worker);
}

const releaseControlsListReleaseControlsSaga = releaseControlsTakeLeading(
  'releaseControlsListReleaseControlsRequest',
  function* listReleaseControls(action) {
    try {
      const response: ListReleaseControlsResponse.Entity = yield* unaryGRPC(
        'mos.release_controls_access.ReleaseControlsAccess/ListReleaseControls',
        {
          ...ListReleaseControlsRequest.defaults,
          filters: {
            ...ListReleaseControlsRequest.ListReleaseControlsFilter.defaults,
            byRefs: action.payload,
          },
          sortOrder: "SORT_MODIFIED_AT_DESC",
          page: {
            ...Page.defaults,
            pageSize: 10000
          }
        },
        ListReleaseControlsRequest.codec,
        ListReleaseControlsResponse.codec,
      )

      yield put(actionCreators.releaseControlsListReleaseControlsSuccess(response))

    } catch (error) {
      yield put(actionCreators.releaseControlsListReleaseControlsFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

const releaseControlsSetReleaseStatusSaga = releaseControlsTakeLeading(
  'releaseControlsSetReleaseStatusRequest',
  function* listReleaseControls(action) {
    try {
      const response: SetReleaseStatusResponse.Entity = yield* unaryGRPC(
        'mos.release_controls_management.ReleaseControlsManagement/SetReleaseStatus',
        action.payload,
        SetReleaseStatusRequest.codec,
        SetReleaseStatusResponse.codec,
      )

      yield put(actionCreators.releaseControlsSetReleaseStatusSuccess(response))

    } catch (error) {
      yield put(actionCreators.releaseControlsSetReleaseStatusFailure(
        failureStatusMessage(error.message)
      ))
    }
  }
)

export const releaseControlsRootSaga: ReadonlyArray<Effect> = [
  releaseControlsListReleaseControlsSaga,
  releaseControlsSetReleaseStatusSaga,
]
