import React from 'react'

import { Container, Content, Wrap, ItemIndex, DragHandle } from 'components/ui/draggable'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu'
import { Description, Image, Title } from 'components/ui/list-item-label'

type Props = {
  readonly historyPush: (id: string) => void;
  readonly projectId: string;
  readonly tourId: string;
  readonly stopId: string;
  readonly draggableIndex?: number;
  readonly isDragging?: boolean;
  readonly title: string;
  readonly thumbnailUrl: string;
};

export const StopItem: React.FC<Props> = ({
  draggableIndex,
  historyPush,
  isDragging,
  projectId,
  tourId,
  stopId,
  title,
  thumbnailUrl,
}) => {
  const editLocation = `/project/${projectId}/tour/${tourId}/stop/${stopId}`

  return (
    <Container isDragging={isDragging} onClick={() => { historyPush(editLocation) }}>
      <Wrap isDragging={isDragging}>
        <Content>
          {draggableIndex !== undefined && (
            <>
              <DragHandle />
              <ItemIndex>
                {draggableIndex + 1}
              </ItemIndex>
            </>
          )}
          <Image src={thumbnailUrl} />
          <Description>
            <Title>{title}</Title>
          </Description>
        </Content>
        <PopOverMenu iconSize="base">
          <PopOverMenuButton
            onClick={(event) => {
              historyPush(editLocation)
              event.stopPropagation()
            }}
          >
            Edit
          </PopOverMenuButton>
        </PopOverMenu>
      </Wrap>
    </Container>
  )
}
