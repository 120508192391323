import { Effect, put } from 'redux-saga/effects'

import { Invariant } from 'helpers/core'
import { httpRest } from 'services/http-rest'

import { wamTakeLeading } from '.'
import { atmosphericZonesActionCreators } from '../actions/atmospheric-zones'

import {
  GetAtmosphericZoneResponse,
  UpdateAtmosphericZoneRequest,
  UpdateAtmosphericZoneResponse,
  DeleteAtmosphericZoneResponse,
} from '../types'

const getAtmosphericZoneSaga = wamTakeLeading('wamGetAtmosphericZoneRequest', function* (action) {
  try {
    const response: GetAtmosphericZoneResponse =
      yield*
      httpRest<undefined, GetAtmosphericZoneResponse>(
        `guided-tours/${action.payload.ecmsId}/atmospheric-zones/${action.payload.ecmsAtmosSlug}`,
        undefined,
        'GET',
      )

    if (!response) {
      throw new Invariant('[ getAtmosphericZoneSaga ] Missing response payload')
    }
    yield put(atmosphericZonesActionCreators.wamGetAtmosphericZoneSuccess(response))
  } catch (error) {
    yield put(atmosphericZonesActionCreators.wamGetAtmosphericZoneFailure(error.message))
  }
})

const updateAtmosphericZoneSaga = wamTakeLeading('wamUpdateAtmosphericZoneRequest', function* (action) {
  try {
    const { ecmsId, ecmsAtmosSlug, ...data } = action.payload
    const response: UpdateAtmosphericZoneResponse =
      yield*
      httpRest<Omit<UpdateAtmosphericZoneRequest, 'ecmsId' | 'ecmsAtmosSlug'>, UpdateAtmosphericZoneResponse>(
        `guided-tours/${ecmsId}/atmospheric-zones/${ecmsAtmosSlug}`,
        data,
        'PUT',
      )

    if (!response) {
      throw new Invariant('[ updateAtmosphericZoneSaga ] Missing response payload')
    }
    yield put(atmosphericZonesActionCreators.wamUpdateAtmosphericZoneSuccess(response))

  } catch (error) {
    yield put(atmosphericZonesActionCreators.wamUpdateAtmosphericZoneFailure(error.message))
  }
})

const deleteAtmosphericZoneSaga = wamTakeLeading('wamDeleteAtmosphericZoneRequest', function* (action) {
  try {
    const response: DeleteAtmosphericZoneResponse =
      yield*
      httpRest<undefined, DeleteAtmosphericZoneResponse>(
        `guided-tours/${action.payload.ecmsId}/atmospheric-zones/${action.payload.ecmsAtmosSlug}`,
        undefined,
        'DELETE',
      )

    if (!response) {
      throw new Invariant('[ deleteAtmosphericZoneSaga ] Missing response payload')
    }
    yield put(atmosphericZonesActionCreators.wamDeleteAtmosphericZoneSuccess(response))
    yield put(atmosphericZonesActionCreators.wamDeleteAtmosphericZoneReset())


  } catch (error) {
    yield put(atmosphericZonesActionCreators.wamDeleteAtmosphericZoneFailure(error.message))
  }
})

export const atmosphericZonesSagas: ReadonlyArray<Effect> = [
  getAtmosphericZoneSaga,
  updateAtmosphericZoneSaga,
  deleteAtmosphericZoneSaga,
]
