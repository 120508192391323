import { AttributeRef } from 'generated/mos/entity'
import { Language } from 'generated/mos/i18n';
import { Attachment, Media } from 'generated/mos/media'
import {
  TranslatableLinkableBoolValue,
  TranslatableLinkableDoubleValue,
  TranslatableLinkableInt64Value,
  TranslatableLinkableMediaValue,
  TranslatableLinkableStringValue,
  TranslatableBoolValue,
  TranslatableDoubleValue,
  TranslatableInt64Value,
  TranslatableMediaValue,
  TranslatableStringValue,
} from 'generated/mos/translatable';

import { assertNever } from 'helpers/core';
import { defaultLanguage } from 'helpers/i18n'
import {
  createLinkableStringValue,
  getLinkableValue,
  LinkableValueEntity,
} from 'helpers/linkables';

type TranslatableType =
  TranslatableBoolValue.Entity['type'] |
  TranslatableStringValue.Entity['type'] |
  TranslatableInt64Value.Entity['type'] |
  TranslatableDoubleValue.Entity['type'] |
  TranslatableMediaValue.Entity['type'];

type TranslatableLinkableType =
  TranslatableLinkableBoolValue.Entity['type'] |
  TranslatableLinkableStringValue.Entity['type'] |
  TranslatableLinkableInt64Value.Entity['type'] |
  TranslatableLinkableDoubleValue.Entity['type'] |
  TranslatableLinkableMediaValue.Entity['type'];

type TranslatableValue =
  TranslatableBoolValue.TranslatedValue.Entity |
  TranslatableStringValue.TranslatedValue.Entity |
  TranslatableInt64Value.TranslatedValue.Entity |
  TranslatableDoubleValue.TranslatedValue.Entity |
  TranslatableMediaValue.TranslatedValue.Entity;

type TranslatableLinkableValue =
  TranslatableLinkableBoolValue.TranslatedValue.Entity |
  TranslatableLinkableStringValue.TranslatedValue.Entity |
  TranslatableLinkableInt64Value.TranslatedValue.Entity |
  TranslatableLinkableDoubleValue.TranslatedValue.Entity |
  TranslatableLinkableMediaValue.TranslatedValue.Entity;

type Value =
  TranslatableBoolValue.TranslatedValue.Entity['boolValue'] |
  TranslatableStringValue.TranslatedValue.Entity['stringValue'] |
  TranslatableInt64Value.TranslatedValue.Entity['int64Value'] |
  TranslatableDoubleValue.TranslatedValue.Entity['doubleValue'] |
  TranslatableMediaValue.TranslatedValue.Entity['mediaRefValue'];

type Translatable = {
  readonly type: TranslatableType;
  readonly translatedValues: ReadonlyArray<TranslatableValue>;
}

type TranslatableLinkable = {
  readonly type: TranslatableLinkableType;
  readonly translatedValues: ReadonlyArray<TranslatableLinkableValue>;
}

const extractTranslatableValue = (translatedValue: TranslatableValue | undefined)  => {
  if (translatedValue === undefined) {
    return undefined;
  }
  switch (translatedValue.type) {
    case 'mos.translatable.TranslatableBoolValue.TranslatedValue':
      return translatedValue.boolValue;
    case 'mos.translatable.TranslatableStringValue.TranslatedValue':
      return translatedValue.stringValue;
    case 'mos.translatable.TranslatableInt64Value.TranslatedValue':
      return translatedValue.int64Value;
    case 'mos.translatable.TranslatableDoubleValue.TranslatedValue':
      return translatedValue.doubleValue;
    case 'mos.translatable.TranslatableMediaValue.TranslatedValue':
      return translatedValue.mediaRefValue;
    default:
      assertNever(translatedValue);
  }
}

const extractTranslatableLinkableValue = (translatedValue: TranslatableLinkableValue | undefined)  => {
  if (translatedValue === undefined) {
    return undefined;
  }
  switch (translatedValue.type) {
    case 'mos.translatable.TranslatableLinkableBoolValue.TranslatedValue':
      return translatedValue.linkableBoolValue;
    case 'mos.translatable.TranslatableLinkableStringValue.TranslatedValue':
      return translatedValue.linkableStringValue;
    case 'mos.translatable.TranslatableLinkableInt64Value.TranslatedValue':
      return translatedValue.linkableInt64Value;
    case 'mos.translatable.TranslatableLinkableDoubleValue.TranslatedValue':
      return translatedValue.linkableDoubleValue;
    case 'mos.translatable.TranslatableLinkableMediaValue.TranslatedValue':
      return translatedValue.linkableMediaValue;
    default:
      assertNever(translatedValue);
  }
}

// converts a translatable value to a renderable value.
export const getTranslatableValue = (
  input?: Translatable,
  requestedLanguage?: Language.Entity,
): Value | undefined => {
  const language = requestedLanguage || defaultLanguage();
  if (input === undefined) {
    return undefined;
  }

  const matchingValues = getValuesForLanguage(input.translatedValues, language)

  // FIXME: currently selects first from array, future cases could have multiple results.
  if (matchingValues && matchingValues.length) {
    return extractTranslatableValue(matchingValues[0] as TranslatableValue)
  }

  return undefined
}

// converts a translatable and linkable value to a renderable value.
export const getTranslatableLinkableValue = (
  input?: TranslatableLinkable,
  requestedLanguage?: Language.Entity,
): LinkableValueEntity['value'] => {
  const language = requestedLanguage || defaultLanguage();
  if (input === undefined) {
    return undefined;
  }

  const matchingValues = getValuesForLanguage(input.translatedValues, language)

  // FIXME: currently selects first from array, future cases could have multiple results.
  if (matchingValues && matchingValues.length) {
    return getLinkableValue(extractTranslatableLinkableValue(matchingValues[0] as TranslatableLinkableValue))
  }

  return undefined
}

// manages updates to a list of translatable values, ensuring only one value exists per language.
// when passed undefined as the value it will remove this item from the list of values.
export const setTranslatableValue = (
  newValue: TranslatableValue,
  existingValues: Translatable,
): Translatable => {
  const translatedValues = existingValues.translatedValues || []
  const { language } = newValue

  if (!language) {
    throw new Error('Language attribute must be provided for translatable values.')
  }

  // remove existing value(s) for language.
  const filteredTranslatedValues = translatedValues.filter(value => value.language &&
    value.language.languageCode !== language.languageCode &&
    value.language.variant !== language.variant);

  // check for falsey value, but not false boolean.
  const value = extractTranslatableValue(newValue)
  const falseyValue = (value === undefined || (typeof value === 'string' && !value.trim().length))

  // return with the new value appended, if the value was not falsey.
  return {
    ...existingValues,
    translatedValues: [
      ...filteredTranslatedValues,
      !falseyValue ? {
        ...newValue,
        mode: 'TRANSLATED_VALUE_MODE_VALUE_SET',
      } : null as any,
    ],
  }
}

// manages updates to a list of translatable linkable values, ensuring only one value exists per
// language.
// when passed undefined as the value it will remove this item from the list of values.
export const setTranslatableLinkableValue = (
  newValue: TranslatableLinkableValue,
  existingValues: TranslatableLinkable,
): TranslatableLinkable => {
  const translatedValues = existingValues.translatedValues || []
  const { language } = newValue

  if (!language) {
    throw new Error('Language attribute must be provided for translatable values.')
  }

  // remove existing value(s) for language.
  const filteredTranslatedValues = translatedValues.filter(value => value.language &&
    value.language.languageCode !== language.languageCode &&
    value.language.variant !== language.variant)

  // check if value is set.
  const value = extractTranslatableLinkableValue(newValue)
  const ignoreValue = !value

  // return with the new value appended, if the value shouldn't be ignored.
  return {
    ...existingValues,
    translatedValues: [
      ...filteredTranslatedValues,
      !ignoreValue ? {
        ...newValue,
        mode: 'TRANSLATED_VALUE_MODE_VALUE_SET',
      } : null as any,
    ],
  }
}

// creates a new translated linkable string value entity.
export const createTranslatableLinkableStringValue = (
  value: AttributeRef.Entity | string | undefined,
  language?: Language.Entity,
): TranslatableLinkableStringValue.TranslatedValue.Entity => ({
  ...TranslatableLinkableStringValue.TranslatedValue.defaults,
  language: language || defaultLanguage(),
  linkableStringValue: createLinkableStringValue(value),
})

// creates a new translated media value entity.
export const createTranslatableMediaValue = (
  mediaRefValue: Media.Ref | undefined,
  language?: Language.Entity,
): TranslatableMediaValue.TranslatedValue.Entity => ({
  ...TranslatableMediaValue.TranslatedValue.defaults,
  language: language || defaultLanguage(),
  mode: 'TRANSLATED_VALUE_MODE_VALUE_SET',
  mediaRefValue,
})

// creates a new translated string value entity.
export const createTranslatableStringValue = (
  stringValue: string,
  language?: Language.Entity,
): TranslatableStringValue.TranslatedValue.Entity => ({
  ...TranslatableStringValue.TranslatedValue.defaults,
  language: language || defaultLanguage(),
  mode: 'TRANSLATED_VALUE_MODE_VALUE_SET',
  stringValue,
})

export const getValuesForLanguage = (
  values: ReadonlyArray<TranslatableValue | TranslatableLinkableValue | Attachment.Entity>,
  language: Language.Entity,
): ReadonlyArray<TranslatableValue | TranslatableLinkableValue | Attachment.Entity> | undefined =>
  values.filter(value =>
    value.language &&
    value.language.languageCode === language.languageCode &&
    // variant is optional.
    (!language.variant || language.variant && value.language.variant === language.variant))
