/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconSite = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M6.773 19.193L6.489 24H1l.465-4.806 5.308-.001zm10.616 0h5.126L23 24h-5.331l-.28-4.807zm-1.503 0l.28 4.807H7.992l.284-4.807h7.61zM7.24 11.261l-.38 6.432H1.61l.623-6.432h5.008zm14.474 0l.649 6.433-5.062-.001-.375-6.432h4.788zm-6.195 1.66l.279 4.772H8.364l.266-4.535.333.457.598.802c.263.348.473.618.63.813l.076.09c.209.251.388.456.543.62l.111.115 1.094 1.093 1.623-1.724c.437-.521.891-1.116 1.426-1.858.152-.21.304-.426.456-.644zM11.982 0c1.455 0 2.699.51 3.731 1.531 1.032 1.02 1.549 2.259 1.549 3.713 0 .728-.182 1.567-.546 2.517-.364.95-.804 1.83-1.32 2.64a48.343 48.343 0 01-1.548 2.288l-.372.509c-.357.482-.667.88-.93 1.198l-.564.598a8.395 8.395 0 01-.428-.473l-.135-.16a42.724 42.724 0 01-1.25-1.637 33.676 33.676 0 01-1.601-2.358 16.09 16.09 0 01-1.267-2.587c-.376-.962-.563-1.807-.563-2.535 0-1.454.504-2.686 1.513-3.695C9.284.516 10.527 0 11.982 0zM5.1 4.176l.15 1.246c.123 1.035.306 1.911.557 2.637l.098.265c.196.503.405.983.627 1.44L2.378 9.76l.54-5.585H5.1zm15.901 0l.563 5.585-4.087.001a16.8 16.8 0 00.641-1.465c.26-.68.453-1.511.587-2.5l.048-.38.143-1.241H21zm-9.018-.797c-.516 0-.956.182-1.32.545a1.798 1.798 0 00-.545 1.32c0 .517.176.962.528 1.338a1.89 1.89 0 001.337.528c.516 0 .962-.182 1.338-.546a1.77 1.77 0 00.563-1.32 1.77 1.77 0 00-.563-1.32 1.857 1.857 0 00-1.338-.545z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconSiteString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M6.773 19.193L6.489 24H1l.465-4.806 5.308-.001zm10.616 0h5.126L23 24h-5.331l-.28-4.807zm-1.503 0l.28 4.807H7.992l.284-4.807h7.61zM7.24 11.261l-.38 6.432H1.61l.623-6.432h5.008zm14.474 0l.649 6.433-5.062-.001-.375-6.432h4.788zm-6.195 1.66l.279 4.772H8.364l.266-4.535.333.457.598.802c.263.348.473.618.63.813l.076.09c.209.251.388.456.543.62l.111.115 1.094 1.093 1.623-1.724c.437-.521.891-1.116 1.426-1.858.152-.21.304-.426.456-.644zM11.982 0c1.455 0 2.699.51 3.731 1.531 1.032 1.02 1.549 2.259 1.549 3.713 0 .728-.182 1.567-.546 2.517-.364.95-.804 1.83-1.32 2.64a48.343 48.343 0 01-1.548 2.288l-.372.509c-.357.482-.667.88-.93 1.198l-.564.598a8.395 8.395 0 01-.428-.473l-.135-.16a42.724 42.724 0 01-1.25-1.637 33.676 33.676 0 01-1.601-2.358 16.09 16.09 0 01-1.267-2.587c-.376-.962-.563-1.807-.563-2.535 0-1.454.504-2.686 1.513-3.695C9.284.516 10.527 0 11.982 0zM5.1 4.176l.15 1.246c.123 1.035.306 1.911.557 2.637l.098.265c.196.503.405.983.627 1.44L2.378 9.76l.54-5.585H5.1zm15.901 0l.563 5.585-4.087.001a16.8 16.8 0 00.641-1.465c.26-.68.453-1.511.587-2.5l.048-.38.143-1.241H21zm-9.018-.797c-.516 0-.956.182-1.32.545a1.798 1.798 0 00-.545 1.32c0 .517.176.962.528 1.338a1.89 1.89 0 001.337.528c.516 0 .962-.182 1.338-.546a1.77 1.77 0 00.563-1.32 1.77 1.77 0 00-.563-1.32 1.857 1.857 0 00-1.338-.545z"  fillRule="nonzero" />
  </svg>`

IconSite.displayName = 'IconSite'
