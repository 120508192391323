// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Page } from "generated/mos/pagination";
import { EntityChange, ReleaseControl } from "generated/mos/releasecontrols";
import { Codec, DecodeContext, RemoteObject, Timestamp, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarOptional, stripTypePropertyDeep } from "generated/webrpc";

export namespace GetReleaseControlRequest {
  export const refName = "mos.release_controls_access.GetReleaseControlRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: string, readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.GetReleaseControlRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      return out as any as Entity;
    }
  }();
}

export namespace GetReleaseControlResponse {
  export const refName = "mos.release_controls_access.GetReleaseControlResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly releaseControl: ReleaseControl.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    releaseControl: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["releaseControl"] = ReleaseControl.codec.encode(v.releaseControl);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.GetReleaseControlResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["releaseControl"] = ctx.decode(ReleaseControl.codec, v["releaseControl"], "releaseControl");
      return out as any as Entity;
    }
  }();
}

export namespace ListEntityChangesRequest {
  export const refName = "mos.release_controls_access.ListEntityChangesRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly filters: ListEntityChangesRequest.ListEntityChangesFilter.Entity | undefined,
    readonly sortOrder: ListEntityChangesRequest.SortOrder,
    readonly page: Page.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    filters: undefined,
    sortOrder: "SORT_DEFAULT",
    page: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["filters"] = ListEntityChangesRequest.ListEntityChangesFilter.codec.encode(v.filters);
      obj["sortOrder"] = v.sortOrder;
      obj["page"] = Page.codec.encode(v.page);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListEntityChangesRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["filters"] = ctx.decode(ListEntityChangesRequest.ListEntityChangesFilter.codec, v["filters"], "filters");
      {
        const ev = ensureScalar(ctx, v["sortOrder"], "string") || defaults["sortOrder"];
        if (!ListEntityChangesRequest.SortOrderValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.release_controls_access.ListEntityChangesRequest.SortOrder`);
        }
        out["sortOrder"] = ev as any;
      }
      out["page"] = ctx.decode(Page.codec, v["page"], "page");
      return out as any as Entity;
    }
  }();
  export namespace ListEntityChangesFilter {
    export const refName = "mos.release_controls_access.ListEntityChangesRequest.ListEntityChangesFilter" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly byEntityRefs: ReadonlyArray<{ readonly typename: string, readonly id: string }>,
      readonly since: Timestamp | undefined,
    }
    export const defaults: Entity = {
      type: refName,
      byEntityRefs: [],
      since: undefined,
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const obj: RemoteObject = {};
        obj["byEntityRefs"] = v.byEntityRefs;
        obj["since"] = v.since ? v.since.value : "";
        return obj;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListEntityChangesRequest.ListEntityChangesFilter");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["byEntityRefs"] = v["byEntityRefs"];
        {
          const tsv = ensureScalarOptional(ctx, v["since"], "string");
          out["since"] = tsv ? {type: "google.protobuf.Timestamp", value: tsv} : undefined;
        }
        return out as any as Entity;
      }
    }();
  }

  export const SortOrderValues = new Set([
    'SORT_DEFAULT' as const,
    'SORT_CREATED_AT_DESC' as const,
  ]);
  export type SortOrder = typeof SortOrderValues extends Set<infer U> ? U : never;

}

export namespace ListEntityChangesResponse {
  export const refName = "mos.release_controls_access.ListEntityChangesResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly entityChanges: ReadonlyArray<EntityChange.Entity>,
    readonly nextPage: Page.Entity | undefined,
    readonly totalItems: number,
  }
  export const defaults: Entity = {
    type: refName,
    entityChanges: [],
    nextPage: undefined,
    totalItems: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["entityChanges"] = [];
      for (const item of v.entityChanges) {
        obj["entityChanges"].push(EntityChange.codec.encode(item));
      }
      obj["nextPage"] = Page.codec.encode(v.nextPage);
      obj["totalItems"] = v.totalItems;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListEntityChangesResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["entityChanges"] = decodeMessageRepeated(ctx, EntityChange.codec, v["entityChanges"], "entityChanges");
      out["nextPage"] = ctx.decode(Page.codec, v["nextPage"], "nextPage");
      out["totalItems"] = ensureScalar(ctx, v["totalItems"], "number");
      return out as any as Entity;
    }
  }();
}

export namespace ListReleaseControlsRequest {
  export const refName = "mos.release_controls_access.ListReleaseControlsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly filters: ListReleaseControlsRequest.ListReleaseControlsFilter.Entity | undefined,
    readonly sortOrder: ListReleaseControlsRequest.SortOrder,
    readonly page: Page.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    filters: undefined,
    sortOrder: "SORT_DEFAULT",
    page: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListReleaseControlsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["filters"] = ctx.decode(ListReleaseControlsRequest.ListReleaseControlsFilter.codec, v["filters"], "filters");
      {
        const ev = ensureScalar(ctx, v["sortOrder"], "string") || defaults["sortOrder"];
        if (!ListReleaseControlsRequest.SortOrderValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.release_controls_access.ListReleaseControlsRequest.SortOrder`);
        }
        out["sortOrder"] = ev as any;
      }
      out["page"] = ctx.decode(Page.codec, v["page"], "page");
      return out as any as Entity;
    }
  }();
  export namespace ListReleaseControlsFilter {
    export const refName = "mos.release_controls_access.ListReleaseControlsRequest.ListReleaseControlsFilter" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly byRefs: ReadonlyArray<{ readonly typename: string, readonly id: string }>,
    }
    export const defaults: Entity = {
      type: refName,
      byRefs: [],
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListReleaseControlsRequest.ListReleaseControlsFilter");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["byRefs"] = v["byRefs"];
        return out as any as Entity;
      }
    }();
  }

  export const SortOrderValues = new Set([
    'SORT_DEFAULT' as const,
    'SORT_MODIFIED_AT_DESC' as const,
  ]);
  export type SortOrder = typeof SortOrderValues extends Set<infer U> ? U : never;

}

export namespace ListReleaseControlsResponse {
  export const refName = "mos.release_controls_access.ListReleaseControlsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly releaseControls: ReadonlyArray<ReleaseControl.Entity>,
    readonly nextPage: Page.Entity | undefined,
    readonly totalItems: number,
  }
  export const defaults: Entity = {
    type: refName,
    releaseControls: [],
    nextPage: undefined,
    totalItems: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["releaseControls"] = [];
      for (const item of v.releaseControls) {
        obj["releaseControls"].push(ReleaseControl.codec.encode(item));
      }
      obj["nextPage"] = Page.codec.encode(v.nextPage);
      obj["totalItems"] = v.totalItems;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.release_controls_access.ListReleaseControlsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["releaseControls"] = decodeMessageRepeated(ctx, ReleaseControl.codec, v["releaseControls"], "releaseControls");
      out["nextPage"] = ctx.decode(Page.codec, v["nextPage"], "nextPage");
      out["totalItems"] = ensureScalar(ctx, v["totalItems"], "number");
      return out as any as Entity;
    }
  }();
}

