import * as React from 'react'

import { Mapbox, MapInitialBounds, MapSet, MapView } from 'components/mapbox';
import { EditableBoundaryMapSet, OnPolygonUpdated } from 'components/mapsets/editable-polygon';
import { NavigationControlSet } from 'components/mapsets/navigation-control';
import { refToUrn } from 'entity';
import { Building, BuildingLevel, OutdoorLevel, Site, Space, SpaceType } from 'generated/mos/structure';
import { isNotNullOrUndefined } from 'helpers/core';
import * as geo from 'helpers/geo';
import * as mapStyles from 'helpers/map-styles';

import { structuresSelector } from 'domains/structures';
import { Structures } from 'domains/structures/entities';
import { buildAdminMapCorners, mustLevelRef, StructureRef } from 'domains/structures/entities';
import { AdminMapSet, BoundarySet } from 'domains/structures/mapsets';


type Props = {
  readonly mapView: MapView;
  readonly onMapViewChanged?: (e: MapView) => void;

  readonly activeSpace?: Space.Entity;
  readonly isEditingBoundary?: boolean;
  readonly showOtherSpaces?: boolean;
  readonly spaceRef?: Space.Ref;
  readonly spacesList: ReadonlyArray<Space.Entity>;
  readonly structures?: Structures;
  readonly structureRef?: StructureRef;
  readonly onUpdated?: OnPolygonUpdated;
  readonly initialBounds?: MapInitialBounds | undefined;
};

export class SpacesMap extends React.Component<Props> {
  public render() {
    const { structureRef, structures, showOtherSpaces } = this.props;

    const structureBoundaries = structures ? selectStructureBoundaries(structures, this.props.structureRef) : [];

    let boundaryEditSet: EditableBoundaryMapSet | undefined;
    let spaceBoundarySet: MapSet | undefined;
    let activeSpaceSet: MapSet | undefined;

    const boundary = this.props.activeSpace ?
      structuresSelector.firstBoundary(this.props.activeSpace.boundaries) : undefined;

    if (this.props.activeSpace) {
      const boundaries = this.props.spacesList
        .filter((v) => this.props.activeSpace && v.ref !== this.props.activeSpace.ref)
        .filter((v) => this.props.activeSpace && SpaceType.mustRef(v.spaceTypeRef).id === SpaceType.mustRef(this.props.activeSpace.spaceTypeRef).id)
        .flatMap((v) => v.boundaries)
        .filter((v) => !structures || !this.props.structureRef ? true :
          structuresSelector.containsOrEquals(structures, this.props.structureRef, mustLevelRef(v.levelRef)))
        .map((v) => v.polygon)
        .filter(isNotNullOrUndefined);

      if (this.props.isEditingBoundary) {
        boundaryEditSet = new EditableBoundaryMapSet({
          key: 'activeSpaceEdit',
          boundary: boundary ? boundary.polygon : undefined,
          onUpdated: this.props.onUpdated,
        });
        spaceBoundarySet = new BoundarySet({
          key: 'activeSpaceView',
          boundaries: !!showOtherSpaces ? boundaries : [],
          color: mapStyles.primaryBoundaryColor,
        });

      } else if (boundary && boundary.polygon) {
        spaceBoundarySet = new BoundarySet({
          key: 'activeSpaceView',
          boundaries: !!showOtherSpaces ? boundaries : [boundary.polygon],
          color: mapStyles.primaryBoundaryColor,
        });
        activeSpaceSet = new BoundarySet({
          key: 'activeIndividualSpaceView',
          boundaries: !!showOtherSpaces ? [boundary.polygon] : [],
          color: '#3bb2d0',
        });
      }
    } else {
      const boundaries = this.props.spacesList
        .flatMap((v) => v.boundaries)

        // FIXME: this is a bit tangled. we could make it more concise:
        .filter((v) => !structures || !this.props.structureRef ? true :
          structuresSelector.containsOrEquals(structures, this.props.structureRef, mustLevelRef(v.levelRef)))

        .map((v) => v.polygon)
        .filter(isNotNullOrUndefined);

      spaceBoundarySet = new BoundarySet({
        key: 'activeSpaceView',
        color: mapStyles.primaryBoundaryColor,
        boundaries: boundaries,
      });
    }

    const adminMapSet = (!structures || !structureRef) ? null : (() => {
      const selectedEntity = structures.index[refToUrn(structureRef)];
      if (selectedEntity) {
        const adminMap = structuresSelector.selectAdminMapByRef(structures, selectedEntity.ref!);
        const corners = adminMap ? buildAdminMapCorners(adminMap) : undefined;
        return adminMap && corners
          ? new AdminMapSet('adminmap', adminMap.imageUrl, corners)
          : undefined;
      }
    })();

    const mapSets = [
      adminMapSet,
      new BoundarySet({ key: 'spaces/structures', boundaries: structureBoundaries, color: mapStyles.backgroundBoundaryColor }),
      spaceBoundarySet,
      boundaryEditSet,
      activeSpaceSet,
      new NavigationControlSet({ showCompass: false }),
    ];

    return <Mapbox
      view={this.props.mapView}
      onViewChanged={this.props.onMapViewChanged}
      initialBounds={this.props.initialBounds}
      mapSets={mapSets} />;
  }
}

function selectStructureBoundaries(structures: Structures, ref?: StructureRef): ReadonlyArray<geo.Polygon2D> {
  if (!ref) {
    return geo.filter(structures.sites.map((v) => geo.optionalPolygon2D(v.boundary)));

  } else if (Site.isRef(ref)) {
    const site = structuresSelector.findSiteByRef(structures, ref);
    if (site === undefined) {
      return [];
    }
    return geo.filter(
      [
        site,
        ...structures.buildings.filter(b => b.siteRef && site.ref ? b.siteRef.id === site.ref.id : false),
      ].map((v) => geo.optionalPolygon2D(v.boundary)),
    );

  } else if (Building.isRef(ref) || BuildingLevel.isRef(ref) || OutdoorLevel.isRef(ref)) {
    const boundary = structuresSelector.selectBoundaryByRef(structures, ref);
    return boundary ? [boundary] : [];
  } else {
    throw new Error();
  }
}
