import React, { Fragment } from 'react'

import { space } from 'helpers/style'

export const ErrorPage = () => (
  <Fragment>
    <div style={{ padding: space(10, false) }}>
      <p>Something unexpected happened.</p>
    </div>
  </Fragment>
);
