import * as React from 'react'
import { createFormBag, Field, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu';
import { AttributeBlock } from 'components/ui/attribute-block';
import { PrimaryNavigationEditPosition } from 'components/ui/primary-navigation/edit-position'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { MapInitialBounds, MapView } from 'components/mapbox';
import { RefCopy } from 'components/ui/ref-copy';
import { AddAttributeButton } from 'components/ui/add-attribute-button';
import { ErrorLabel, Label } from 'components/ui/forms';
import { Alert, Confirm } from 'components/ui/modal';
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { isRefEqual } from 'entity';
import { PlacedPolygon } from 'generated/mos/geo';
import { Space, SpaceType } from 'generated/mos/structure';
import { UpdateSpaceRequest } from 'generated/mos/structuremanagement';
import { assertNever, pick, shallowEqual } from 'helpers/core';
import * as geo from 'helpers/geo';
import { Status, statusSelector } from 'helpers/status';

import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresSelector } from 'domains/structures';
import { structuresActionCreators } from 'domains/structures/actions';
import { Structures, isLevelRef, LevelRef, StructureRef } from 'domains/structures/entities';
import { SpatialScopeSelector } from 'domains/structures/components/spatial-scope-select';
import { DataStatus as StructuresDataStatus, GlobalMapState } from 'domains/structures/store';

import { spacesBindableActionCreators } from 'domains/spaces/actions';
import { SpaceTypeSelect } from 'domains/spaces/components/space-type-select';
import { SpacesMap } from 'domains/spaces/components/spaces-map';
import { DataStatus, EntityStatus } from 'domains/spaces/store';

import { LastButtonRow, NoBoundaryLabel, Section, SectionHeader, ToggleSpaces } from './styled';


type DirectProps = {
  readonly spaceId: string;
  readonly onComplete: () => void;
};

type ConnectedProps = {
  readonly spaceEdit: EntityStatus<Space.Entity>;
  readonly spacesList: DataStatus<ReadonlyArray<Space.Entity>>;
  readonly structures: StructuresDataStatus<Structures>;

  // The globalMap's activeRef is used as a fallback for the spatial
  // scope selector if the space being edited does not have a position:
  readonly globalMap: GlobalMapState;
};

type ActionProps =
  Pick<typeof sharedActionCreators, 'toastNotification'> &
  Pick<typeof spacesBindableActionCreators,
    'spacesRequest' | 'spacesFiltersUpdate' | 'spaceEditClear' |
    'spaceEditBeginRequest' | 'spaceEditDeleteRequest' | 'spaceEditSaveRequest'> &
  Pick<typeof structuresActionCreators, 'structuresLoadRequest'>;

type Props = DirectProps & ConnectedProps & ActionProps;


type EditMode = undefined | 'boundary';

type State = {
  readonly showConfirmDeleteModal?: boolean;
  readonly showDismissableErrorModal?: boolean;
  readonly errorModalMessage: string;

  // The globalMap's activeRef is only used for a default if the space is
  // unpositioned. The spatial scope selector on the space edit page is managed
  // by this state value:
  readonly spatialRef: StructureRef | undefined;

  // The globalMap's view is only used for a default if the beacon is
  // unpositioned.
  readonly mapView?: MapView;

  // Replace this with a new '{}' to force mapbox to update to the initial bounds:
  readonly mapBoundsIdentity?: object;
  readonly mapBounds?: geo.BBox2D;

  readonly bag: FormBag<FormValues>;
  readonly spaceEditReady?: boolean;
  readonly editMode: EditMode;
  readonly boundary?: geo.Polygon2D;
  readonly boundaryLevel?: LevelRef;
  readonly boundaryPrevious?: geo.Polygon2D;
  readonly initialFormValues: FormValues;
  readonly localBag: FormBag<LocalFormValues>;
};


type FormValues = {
  readonly name: string;
  readonly displayName: string;
  readonly spaceTypeRef?: SpaceType.Ref;
};

type LocalFormValues = {
  readonly otherSpaces: boolean
};

class SpaceEditPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const initialFormValues = {
      name: '',
      displayName: '',
    }
    this.state = {
      errorModalMessage: '',
      editMode: undefined,

      // If the space is not positioned, we default to the global active
      // spatial, but we never modify it in this context:
      spatialRef: props.globalMap.activeRef,

      bag: createFormBag(initialFormValues),
      localBag: createFormBag({
        otherSpaces: false,
      }),
      initialFormValues,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    // When the space to be edited has loaded, we set the spatial scope selector's
    // ref to the ref contained in the position and construct the dir
    if (state.spaceEditReady ||
      !statusSelector.hasData(props.spaceEdit) ||
      !statusSelector.hasData(props.spacesList)) {

      return null;
    }

    const space = props.spaceEdit.data;
    if (!space) {
      throw new Error();
    }

    let mapView: MapView = props.globalMap.view;

    const values: FormValues = {
      name: space.name,
      displayName: space.displayName,
      spaceTypeRef: space.spaceTypeRef,
    };

    const boundary = structuresSelector.firstBoundary(space.boundaries);

    if (boundary && boundary.polygon) {
      mapView = {
        ...mapView,
        center: geo.centroid2D(boundary.polygon),
      };
    }

    const boundaryRef = boundary ? boundary.levelRef : undefined;

    const newState: Partial<State> = {
      localBag: state.localBag,
      bag: createFormBag(values),
      boundary: boundary ? boundary.polygon : undefined,
      boundaryLevel: boundaryRef,
      mapView,
      spaceEditReady: true,
      spatialRef: boundaryRef || state.spatialRef,
      initialFormValues: values
    };

    return newState;
  }

  private get ref(): Space.Ref {
    return { typename: 'mos.structure.Space', id: this.props.spaceId };
  }

  private get name() { return this.state.bag.values.name; }

  private mapBounds(): MapInitialBounds | undefined {
    if (!this.state.mapBoundsIdentity || !this.state.mapBounds) {
      return;
    }
    return {
      padding: 120, // FIXME put in context
      identity: this.state.mapBoundsIdentity,
      bounds: this.state.mapBounds,
    };
  }

  private dirtySpace(): Space.Entity | undefined {
    const editSpace = statusSelector.data(this.props.spaceEdit);
    if (!editSpace) {
      return;
    }

    const boundaries: PlacedPolygon.Entity[] = !this.state.boundary ? [] : [
      { ...PlacedPolygon.defaults, polygon: this.state.boundary, levelRef: this.state.boundaryLevel },
    ];

    return {
      ...Space.defaults,
      name: this.name,
      ref: this.ref,
      siteRef: editSpace.siteRef,
      metadata: editSpace.metadata,
      spaceTypeRef: this.state.bag.values.spaceTypeRef || editSpace.spaceTypeRef,
      boundaries: boundaries,
    };
  }

  public componentWillUnmount() {
    this.props.spaceEditClear();
  }

  public componentDidMount() {
    this.setState({
      ...this.state,
      spaceEditReady: false
    })
    this.props.spaceEditClear()
    this.props.spaceEditBeginRequest(this.ref);
    this.refresh();
  }

  public componentDidUpdate() {
    this.refresh();
  }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.spacesList.status)) {
      this.props.spacesRequest();
    }
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  private onLocalFormUpdate = (e: FormUpdateEvent<LocalFormValues>) => {
    this.setState({ localBag: e.bag });
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    if (!values.spaceTypeRef) {
      errors.spaceTypeRef = 'Required';
    }
    return errors;
  }


  // {{{ Boundary handlers

  private onBoundaryShow = () => {
    if (this.state.boundary) {
      this.setState({ mapBoundsIdentity: {}, mapBounds: geo.bbox2D(this.state.boundary) });
    }
  }

  private onBoundaryAdd = () => {
    if (this.state.boundary) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: `'${this.name}' already has a boundary.` });

    } else if (!isLevelRef(this.state.spatialRef)) {
      this.setState({ showDismissableErrorModal: true, errorModalMessage: 'You must be on a level to draw a boundary.' });

    } else {
      this.setState({ editMode: 'boundary', boundaryLevel: this.state.spatialRef });
    }
  }

  private onBoundaryEdit = () => this.setState({ editMode: 'boundary', boundaryPrevious: this.state.boundary });
  private onBoundaryCancel = () => this.setState({ editMode: undefined, boundary: this.state.boundaryPrevious });
  private onBoundaryPosCommit = () => this.setState({ editMode: undefined });
  private onBoundaryRemove = () => this.setState({ boundary: undefined, boundaryLevel: undefined });

  private onBoundaryUpdate = (e: { polygon: geo.Polygon2D | undefined }) => {
    this.setState({ boundary: e.polygon });
  }

  // }}}


  private onConfirmDelete = () => {
    if (this.props.spaceEdit.status !== Status.Ready) {
      return;
    }

    this.props.spaceEditDeleteRequest({
      onSuccess: () => {
        this.props.toastNotification({ type: 'success', text: `Space deleted` });
        this.props.onComplete();
      },
    });
  }

  private onSave = () => {
    if (this.state.editMode) {
      throw new Error();
    }

    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });

    if (!bag.valid) {
      return;
    }

    this.props.spacesFiltersUpdate({ lastEdited: this.ref });

    let boundaries: PlacedPolygon.Entity[] = [];
    if (this.state.boundary && this.state.boundary && this.state.boundaryLevel) {
      boundaries = [{
        type: "mos.geo.PlacedPolygon",
        polygon: this.state.boundary,
        levelRef: this.state.boundaryLevel,
      }];
    }

    this.props.spaceEditSaveRequest({
      onSuccess: () => {
        this.props.toastNotification({ type: 'success', text: `Space saved` });
        this.props.onComplete();
      },

      // NOTE: not using defaults here - if we forget to add a property
      // it doesn't blow up until runtime.
      spaceInput: {
        ...UpdateSpaceRequest.defaults,
        updateMask: {
          type: "google.protobuf.FieldMask",
          fields: ['name', 'displayName', 'boundaries', 'spaceTypeRef'],
        },
        spaceRef: this.ref,
        name: bag.values.name,
        displayName: bag.values.displayName,
        boundaries: boundaries,
        spaceTypeRef: bag.values.spaceTypeRef, // should be asserted on validate
      },
    });
  }

  private onCancel = () => {
    this.props.onComplete();
  }

  private isModified = (): boolean => {
    if (!statusSelector.hasData(this.props.spaceEdit) ||
      !statusSelector.hasData(this.props.spacesList)) {
      return false;
    }

    // check if form has changed
    if (!shallowEqual(this.state.bag.values, this.state.initialFormValues)) {
      return true;
    }

    // check if boundary has changed
    const spaceEdit = this.props.spaceEdit.data;
    const firstBoundary = structuresSelector.firstBoundary(spaceEdit.boundaries)
    const initialBoundary = firstBoundary && firstBoundary.polygon

    if (!geo.equal(initialBoundary, this.state.boundary)) {
      return true;
    }

    return false;
  }

  public render() {
    if (this.props.spaceEdit.status === Status.Failed) {
      // FIXME: el-cheapo error display
      return this.props.spaceEdit.messages.map((v) => v.text).join(', ');
    }

    const structures = statusSelector.data(this.props.structures);

    const dirtySpace = this.dirtySpace();

    const spacesList = [...statusSelector.data(this.props.spacesList) || []];

    if (dirtySpace) { // replace space in list with the space currently being edited
      const idx = spacesList.findIndex((v) => isRefEqual(v.ref, this.ref));
      spacesList.splice(idx, 1);
      spacesList.push(dirtySpace);
    }

    const isDisabled = this.props.spaceEdit.status === Status.Updating;
    const locationString = structures && this.state.boundaryLevel ?
      structuresSelector.namePath(structures, this.state.boundaryLevel, ['No boundary']).join(' > ') : '';

    const { errors, touched } = this.state.bag;

    const modified = this.isModified()

    const sidebar = () => ((this.props.spaceEdit.status !== Status.Updating && this.props.spaceEdit.status !== Status.Ready) ? null :
      <>
        <Section>
          <SectionHeader>Details</SectionHeader>
          <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
            <Input
              label="Name"
              field="name"
              error={touched.name ? errors.name : undefined}
            />
            <Input
              label="Display Name"
              field="displayName"
              error={touched.displayName ? errors.displayName : undefined}
            />
            <Label>Space Type</Label>
            <Field<FormValues, 'spaceTypeRef'> name="spaceTypeRef" render={(props) => (
              <SpaceTypeSelect selected={props.value}
                onBlur={props.blur}
                onChange={(e) => props.change(e.spaceType ? e.spaceType.ref : undefined)}
              />
            )} />
            <ErrorLabel>{touched.spaceTypeRef && errors.spaceTypeRef}</ErrorLabel>
          </FormData>
        </Section>

        <Section>
          <SectionHeader>Attributes</SectionHeader>
          <AddAttributeButton title="Boundary" onClick={this.onBoundaryAdd} />
          {!this.state.boundary
            ? <NoBoundaryLabel>No boundary set</NoBoundaryLabel>
            : <AttributeBlock title="Space Boundary" description={locationString}>
              <PopOverMenu>
                <PopOverMenuButton onClick={this.onBoundaryEdit}>Edit boundary</PopOverMenuButton>
                <PopOverMenuButton onClick={this.onBoundaryShow}>Centre on map</PopOverMenuButton>
              </PopOverMenu>
            </AttributeBlock>
          }
        </Section>

        <ToggleSpaces>
          <FormData bag={this.state.localBag} onUpdate={this.onLocalFormUpdate}>
            <Checkbox<LocalFormValues, "otherSpaces">
              label="Display adjacent spaces"
              field="otherSpaces"
              isDisabled={!isLevelRef(this.state.spatialRef)}
              description={"Toggle spaces of the same type on this level"}
            />
          </FormData>
        </ToggleSpaces>
        <LastButtonRow>
          <Button
            onClick={() => this.setState({ showConfirmDeleteModal: true })}
            isDisabled={isDisabled}
          >
            Delete space
          </Button>

          <RefCopy modelRef={this.ref} />
        </LastButtonRow>
      </>
    );
    const content = () => (
      <>
        <SpatialScopeSelector
          structures={structures}
          structureRef={this.state.spatialRef}
          isDisabled={!!this.state.editMode}
          onChange={(e) => this.setState({ spatialRef: e })}
        />
        <SpacesMap
          mapView={this.state.mapView!}
          onMapViewChanged={(e) => this.setState({ mapView: e })}
          initialBounds={this.mapBounds()}

          activeSpace={dirtySpace}
          showOtherSpaces={this.state.localBag.values.otherSpaces}
          isEditingBoundary={this.state.editMode === 'boundary'}
          onUpdated={this.onBoundaryUpdate}
          spacesList={spacesList}
          structures={structures}
          structureRef={this.state.spatialRef}
        />
      </>
    );

    let nav: () => React.ReactNode;
    if (this.state.editMode === 'boundary') {
      nav = () => (
        <PrimaryNavigationEditPosition
          title="Edit space boundary"
          kind="boundary"
          onCommit={this.onBoundaryPosCommit}
          onCancel={this.onBoundaryCancel}
          onDelete={this.onBoundaryRemove}
        />
      );

    } else if (!this.state.editMode) {
      nav = () => (
        <PrimaryNavigationEditEntity
          title="Edit Space"
          saveDisabled={!modified || !!this.state.editMode || this.props.spaceEdit.status === Status.Updating}
          onSave={this.onSave}
          disabled={!modified}
          onCancel={this.onCancel}
          onConfirm={modified ? this.onCancel : undefined}
          navBackRoute={!modified ? '/locate/spaces' : undefined}
        />
      );

    } else {
      assertNever(this.state.editMode); // Exhaustiveness check
      throw new Error(); // TypeScript requires this
    }


    return (
      <div style={{ height: '100vh' }}>
        {!this.state.showConfirmDeleteModal ? null :
          <Confirm
            danger
            title={`Delete ${this.name}?`}
            message="Deleting a space will delete both the space itself and it's relationships with content within projects permanently."
            confirmLabel="Yes, delete space forever"
            onConfirm={this.onConfirmDelete}
            onClose={() => this.setState({ showConfirmDeleteModal: false })}
          />
        }

        {!this.state.showDismissableErrorModal ? null :
          <Alert onClose={() => this.setState({ showDismissableErrorModal: false })}
            message={this.state.errorModalMessage} />
        }

        <LayoutLocateEditPage
          nav={nav}
          sidebar={sidebar}
          content={content}
        />
      </div>
    );
  }
}

export const SpaceEditPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    ...pick(store.spaces, 'spaceEdit', 'spacesList'),
    ...pick(store.structures, 'globalMap', 'structures'),
  }),
  {
    ...pick(spacesBindableActionCreators, 'spaceEditBeginRequest', 'spaceEditClear', 'spaceEditDeleteRequest', 'spaceEditSaveRequest', 'spacesRequest', 'spacesFiltersUpdate'),
    ...pick(structuresActionCreators, 'structuresLoadRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(SpaceEditPageView);
