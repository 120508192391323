import { space } from 'helpers/style'
import styled from 'styled'

export const bodyHeight = 380

export const ModalBody = styled.div`
  height: ${bodyHeight}px;
  margin: 0 ${space(3)};
`

// {{{ media upload.
export const DropArea = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: ${bodyHeight}px;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.color.grayL80};
  border-radius: 12px;

  &:focus {
    outline: none;
  }
`

export const DropAreaText = styled.p`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-align: center;
`

export const DropAreaTextSmall = styled.small`
  display: block;
  margin-top: ${space(3)};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  text-transform: uppercase;
`

export const FileListItem = styled.div<{ selected?: boolean}>`
  display: flex;
  align-items: center;
  height: 68px;
  margin-bottom: ${space(4)};
  padding: ${space(4)};
  border: 1px solid ${({ selected, theme }) => selected ? theme.color.grayD80 : theme.color.grayL80};
  border-radius: 4px;
  cursor: pointer;
`

export const FileDetails = styled.div`
  justify-self: flex-start;
  margin-right: ${space(3)};
  overflow: hidden;
  width: 100%;
`

export const FileDescription = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FileTitle = styled(FileDescription)`
  color: ${({ theme }) => theme.color.darkText};
  font-size: ${({ theme }) => theme.font.size.base};
`

export const ProgressArea = styled.div`
  height: 8px;
  margin-right: ${space(2)};
  width: 100px;
  background: ${({ theme }) => theme.color.grayL80};
`

export const ProgressBar = styled.div<{ percentage?: number }>`
  height: 100%;
  background: ${({ theme }) => theme.color.success};
  ${({ percentage }) => percentage ? `
    width: ${percentage}px;
  ` : `
    width: 0px;
  `}
`

export const MediaDetails = styled.div`
  margin-top: ${space(8)};
`
// }}} media upload.
