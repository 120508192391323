import styled from 'styled';

export const Label = styled.label`
  font-size: 13px;
  display: block;
  margin: 0 0 10px 0;
`;

export const Input = styled.input`
  display: block;
  width: auto;
  border-radius: 5px;
  border: solid 1px #e7e7e7;
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100%;
  box-sizing: border-box;
`;

export const ButtonRow = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
`;
