import { ButtonGroup } from 'components/ui/button'
import { IconLink } from 'components/ui/icons'
import { space } from 'helpers/style'
import styled from 'styled'

export const FieldWrapper = styled.div<{ isAssociated?: boolean }>`
  margin-bottom: ${({ isAssociated }) => isAssociated ? space(4) : space(6)};
  ${({ isAssociated, theme }) => !isAssociated ? null :`
    padding-left: ${space(4)};
    border-left: 3px solid ${theme.color.grayL70};
  `}
`

export const Label = styled.label`
  color: ${({ theme }) => theme.color.grayD60};
  font-weight: ${({ theme }) => theme.font.weight.base};
`

export const LinkedFieldIcon = styled(IconLink)`
  display: inline-block;
  margin-right: ${space(1)};
  vertical-align: middle;
`

export const LabelSupport = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

export const Preview = styled.div<{ isDisabled?: boolean; isOverriding?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${space(2)};
  min-height: 88px;
  padding: ${space(4)};
  position: relative;
  background-color: ${({ isDisabled, theme }) => isDisabled ? 'transparent' : theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grayL80};
  border-radius: 2px;

  ${({ isOverriding, theme }) => {
    if (isOverriding) {
      const gray = theme.color.grayL80
      return `
        background-color: transparent;
        background-image: linear-gradient(135deg, ${gray} 25%, transparent 25%, transparent 50%, ${gray} 50%, ${gray} 75%, transparent 75%, transparent);
        background-size: 8px 8px;
      `
    }
  }}
`

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  margin-right: ${space(3)};
  min-width: 56px;
  max-width: 56px;
  background: ${({ theme }) => theme.color.grayL70};
  border-radius: 2px;
  overflow:auto;
`

export const Title = styled.h6`
  margin: 0 0 ${space(1)};
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.light};
`

export const EmptyDetails = styled.span`
  color: ${({ theme }) => theme.color.gray};
`

export const Table = styled.table`
  width: 100%;
`

export const TableHeader = styled.th`
  padding-left: 0;
  width: 70px;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-align: left;
`

export const TableCell = styled.td`
  max-width: 0;
  padding-left: 0;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const LinkedFieldEditButton = styled.div`
  position: absolute;
  right: ${space(1)};
  top: 50%;
  transform: translate(0, -50%);
`

export const PlayerContainer = styled.div`
  margin-top: -1px;
`

export const MainOptions = styled(ButtonGroup)`
  margin-top: ${space(2)};
  margin-bottom: ${space(1)};
`

export const Description = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.gray};
  line-height: ${space(6)};
`

export const Warning = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.warning};
  line-height: ${space(6)};
`
