import React from 'react'

import { Container, Content, Wrap } from 'components/ui/draggable'
import { IconHash, IconRoom } from 'components/ui/icons'
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu'
import { Description, Image, Title } from 'components/ui/list-item-label'

import { Location } from '../../types'

import { space } from 'helpers/style'

import styled from 'styled'

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-weight: ${({ theme }) => theme.font.weight.base};
  color: ${({ theme }) => theme.color.grayL40};
`;

const IconText = styled.span<{ wide?: boolean }>`
  width: ${({ wide }) => wide ? space(45) : space(10)};
  margin-left: ${space(2)};
  font-size: ${({ theme }) => theme.font.size.small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`

const DisplayBadge = styled.div`
  white-space: nowrap;
`;

type Props = {
  readonly historyPush: (id: string) => void;
  readonly projectId: string;
  readonly tourId: string;
  readonly title: string;
  readonly location: Location;
  readonly thumbnailUrl: string;
};

export const ListItem: React.FC<Props> = ({
  historyPush,
  projectId,
  tourId,
  title,
  location,
  thumbnailUrl
}) => {

  const editLocation = `/project/${projectId}/tour/${tourId}/stops`

  return (
    <Container onClick={() => { historyPush(editLocation) }}>
      <Wrap listItemLink={true} >
        <Content>
          <Image src={thumbnailUrl} />
          <Description>
            <Title>{title}</Title>
          </Description>
        </Content>

        <IconsContainer>
          <IconBlock>
            <IconHash color="grayL40" size="small" />
            <IconText wide>{tourId}</IconText>
          </IconBlock>

          <IconBlock>
            <IconRoom color="grayL40" size="small" />
            <IconText wide>{location ? location.name : 'No Location'}</IconText>
          </IconBlock>
        </IconsContainer>
        <PopOverMenu iconSize="base">
          <PopOverMenuButton
            onClick={(event) => {
              historyPush(editLocation)
              event.stopPropagation()
            }}
          >
            Edit
          </PopOverMenuButton>
        </PopOverMenu>
      </Wrap>
    </Container>
  )
}
