import React from 'react'
import { Link } from 'react-router-dom'

import { ImageWithFallback } from 'components/ui/image'
import { space } from 'helpers/style'
import styled from 'styled'
import { mediaQueries } from 'styled/core'

const Wrapper = styled.div`
  padding: ${space(3)};

  width: 100%;
  ${mediaQueries.palm} {
    width: calc(100%);
  }
  ${mediaQueries.hand} {
    width: calc(100% / 3);
  }
  ${mediaQueries.lap} {
    width: calc(100% / 4);
  }
`

const Card = styled(Link)`
  display: block;
  border: 1px solid ${({ theme }) => theme.color.grayL90};
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow 200ms ease;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`

const Title = styled.h4`
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.base};
  color: ${({ theme }) => theme.color.grayD80};
  height: ${space(10)};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const Body = styled.div`
  flex-shrink: 0;
  height: ${space(24)};
  padding: ${space(3)};
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Image = styled(ImageWithFallback)`
  height: ${space(44)};
`

type Props = {
  projectTitle: string
}

export const ProjectCard = ({ projectTitle }: Props) => {
  return (
    <Wrapper>
      <Card to={`/project/0/tours`}>
        <Image src='/assets/WAM-EMS-project.jpg' />
        <Body>
          <Title>{projectTitle}</Title>
        </Body>
      </Card>
    </Wrapper>
  )
}
