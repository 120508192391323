// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Media, MediaKind, MediaKindValues, Projection, Tag, Transform } from "generated/mos/media";
import { Page } from "generated/mos/pagination";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureScalar, ensureScalarRepeated, stripTypePropertyDeep } from "generated/webrpc";

export namespace GetMediaRequest {
  export const refName = "mos.media_access.GetMediaRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly mediaRef: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    mediaRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.GetMediaRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["mediaRef"] = v["mediaRef"];
      return out as any as Entity;
    }
  }();
}

export namespace GetMediaResponse {
  export const refName = "mos.media_access.GetMediaResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly media: Media.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    media: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["media"] = Media.codec.encode(v.media);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.GetMediaResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["media"] = ctx.decode(Media.codec, v["media"], "media");
      return out as any as Entity;
    }
  }();
}

// Projections don't have a unique identified, instead is uses a combination of the media form which
// it is derived and the list of transforms desired - note this make the order of the tranformations
// meaningful.
export namespace GetProjectionRequest {
  export const refName = "mos.media_access.GetProjectionRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly derivedFrom: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
    readonly transform: Transform.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    derivedFrom: undefined,
    transform: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["derivedFrom"] = v.derivedFrom;
      obj["transform"] = Transform.codec.encode(v.transform);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.GetProjectionRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["derivedFrom"] = v["derivedFrom"];
      out["transform"] = ctx.decode(Transform.codec, v["transform"], "transform");
      return out as any as Entity;
    }
  }();
}

export namespace GetProjectionResponse {
  export const refName = "mos.media_access.GetProjectionResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly projection: Projection.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    projection: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["projection"] = Projection.codec.encode(v.projection);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.GetProjectionResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["projection"] = ctx.decode(Projection.codec, v["projection"], "projection");
      return out as any as Entity;
    }
  }();
}

export namespace ListMediaRequest {
  export const refName = "mos.media_access.ListMediaRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly page: Page.Entity | undefined,
    // Passing an invalid SortOrder will cause an INVALID_ARGUMENT error to be raised.
    readonly sortOrder: ListMediaRequest.SortOrder,
    readonly filter: ListMediaRequest.ByRef.Entity | ListMediaRequest.ByKindAndTerm.Entity | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    page: undefined,
    sortOrder: "SORT_DEFAULT",
    filter: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["page"] = Page.codec.encode(v.page);
      obj["sortOrder"] = v.sortOrder;
      {
        const uv: any = v.filter;
        if (uv !== undefined) {
          if (uv.type == "mos.media_access.ListMediaRequest.ByKindAndTerm") {
            obj["byKindAndTerm"] = ListMediaRequest.ByKindAndTerm.codec.encode(uv);
          }
          else if (uv.type == "mos.media_access.ListMediaRequest.ByRef") {
            obj["byRef"] = ListMediaRequest.ByRef.codec.encode(uv);
          }
          else {
            throw new WebRPCError("union discrimination failed for .mos.media_access.ListMediaRequest.filter");
          }
        }
      }
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.ListMediaRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["page"] = ctx.decode(Page.codec, v["page"], "page");
      {
        const ev = ensureScalar(ctx, v["sortOrder"], "string") || defaults["sortOrder"];
        if (!ListMediaRequest.SortOrderValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.media_access.ListMediaRequest.SortOrder`);
        }
        out["sortOrder"] = ev as any;
      }
      {
        if (v["byKindAndTerm"] !== undefined) {
          out["filter"] = ctx.decode(ListMediaRequest.ByKindAndTerm.codec, v["byKindAndTerm"], "byKindAndTerm");
        }
        if (v["byRef"] !== undefined) {
          out["filter"] = ctx.decode(ListMediaRequest.ByRef.codec, v["byRef"], "byRef");
        }
      }
      return out as any as Entity;
    }
  }();
  export namespace ByRef {
    export const refName = "mos.media_access.ListMediaRequest.ByRef" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly mediaRefs: ReadonlyArray<{ readonly typename: 'mos.media.Media', readonly id: string }>,
    }
    export const defaults: Entity = {
      type: refName,
      mediaRefs: [],
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.media_access.ListMediaRequest.ByRef");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        out["mediaRefs"] = v["mediaRefs"];
        return out as any as Entity;
      }
    }();
  }

  export namespace ByKindAndTerm {
    export const refName = "mos.media_access.ListMediaRequest.ByKindAndTerm" as const;
    export type Entity = {
      readonly type: typeof refName,
      readonly kinds: ReadonlyArray<MediaKind>,
      readonly term: string,
    }
    export const defaults: Entity = {
      type: refName,
      kinds: [],
      term: "",
    }
    export const codec: Codec<Entity> = new class {
      public encode(v: Entity): RemoteObject;
      public encode(v: Entity | undefined): RemoteObject | null {
        if (!v) return null;
        const { type, ...out } = v;
        return out;
      }
      public decode(v: RemoteObject): Entity;
      public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
        if (v === undefined || v === null) return undefined;
        if (!ctx) ctx = new DecodeContext(".mos.media_access.ListMediaRequest.ByKindAndTerm");
        if (typeof(v) !== "object") throw ctx.expected("object", v);
        const out: RemoteObject = { ...defaults };
        {
          const evs = ensureScalarRepeated(ctx, v["kinds"], "string").map(i => i || defaults["kinds"]);
          for (const ev of evs) {
            if (!MediaKindValues.has(ev as any)) {
              throw ctx.error(`unknown value "${ev}" for enum .mos.media.MediaKind`);
            }
          }
          out["kinds"] = evs as any;
        }
        out["term"] = ensureScalar(ctx, v["term"], "string");
        return out as any as Entity;
      }
    }();
  }

  export const SortOrderValues = new Set([
    'SORT_DEFAULT' as const,
    'SORT_DESCRIPTION_ASC' as const,
    'SORT_CREATION_DATE_DESC' as const,
  ]);
  export type SortOrder = typeof SortOrderValues extends Set<infer U> ? U : never;

}

export namespace ListMediaResponse {
  export const refName = "mos.media_access.ListMediaResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly media: ReadonlyArray<Media.Entity>,
    readonly nextPage: Page.Entity | undefined,
    // Estimated number of available results, after search queries and filtering but before pagination.
    // This may change during pagination, and should not be relied upon for list termination.
    readonly totalItems: number,
  }
  export const defaults: Entity = {
    type: refName,
    media: [],
    nextPage: undefined,
    totalItems: 0,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["media"] = [];
      for (const item of v.media) {
        obj["media"].push(Media.codec.encode(item));
      }
      obj["nextPage"] = Page.codec.encode(v.nextPage);
      obj["totalItems"] = v.totalItems;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.ListMediaResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["media"] = decodeMessageRepeated(ctx, Media.codec, v["media"], "media");
      out["nextPage"] = ctx.decode(Page.codec, v["nextPage"], "nextPage");
      out["totalItems"] = ensureScalar(ctx, v["totalItems"], "number");
      return out as any as Entity;
    }
  }();
}

// I am not expecting the list to be so long for a given organisation as to require filtering
export namespace ListTagsRequest {
  export const refName = "mos.media_access.ListTagsRequest" as const;
  export type Entity = {
    readonly type: typeof refName,
  }
  export const defaults: Entity = {
    type: refName,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.ListTagsRequest");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      return out as any as Entity;
    }
  }();
}

// I am not expecting the list to be so long for a given organisation as to require pagination.
export namespace ListTagsResponse {
  export const refName = "mos.media_access.ListTagsResponse" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly tags: ReadonlyArray<Tag.Entity>,
  }
  export const defaults: Entity = {
    type: refName,
    tags: [],
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      return stripTypePropertyDeep(v);
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.media_access.ListTagsResponse");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["tags"] = decodeMessageRepeated(ctx, Tag.codec, v["tags"], "tags");
      return out as any as Entity;
    }
  }();
}

