import React, { ReactElement, useRef, useEffect } from 'react'

import { ActionType } from 'containers/core'
import { Loader } from 'components/ui/loader'
import { debounce } from 'helpers/core'
import { DataStatus, PaginatedList, Status, statusSelector } from 'helpers/status'
import { space } from 'helpers/style'
import styled from 'styled'

const Container = styled.div<{ height?: number}>`
  height: ${({ height }) => height ? `${height}px` : null};
  min-height: 300px;
  position: relative;
  overflow-y: auto;
`

const ListCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PaginationActive = styled.div`
  height: 30px;
  padding-bottom: ${space(13)};
  position: relative;
`

// FIXME: strengthen entity typing in renderEntity
type Props = {
  readonly height?: number;
  readonly renderEntity: (entity: any, index: number) => ReactElement;
  readonly requestNextPage: ActionType<any>;
  readonly statusList: DataStatus<PaginatedList<any>, string>;
}

export const InfiniteScroll = ({ height, renderEntity, requestNextPage, statusList }: Props) => {
  const ref = useRef<HTMLElement>(null)

    // scroll event when we have set a height, so expect the container to scroll internally.
  const internalScrollCallback = debounce((event: Event) => {
    const element = event.target as HTMLElement
    if (element === null) return
    const scrollOffset = 20
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - scrollOffset &&
      statusList.status === Status.Ready
    ) {
      requestNextPage()
    }
  }, 50)

  // no height, so we are binding to the window scroll event.
  const windowScrollCallback = debounce(() => {
    const container = ref.current

    if (!container) return
    if (
      window.scrollY + window.innerHeight === container.offsetTop + container.clientHeight &&
      statusList.status === Status.Ready
    ) {
      requestNextPage()
    }
  }, 50)

  useEffect(() => {
    const container = ref.current
    height
      ? container!.addEventListener('scroll', internalScrollCallback)
      : window.addEventListener('scroll', windowScrollCallback)

    return () => {
      height
        ? container!.removeEventListener('scroll', internalScrollCallback)
        : window.removeEventListener('scroll', windowScrollCallback)
    }
  }, [height, statusList.status, internalScrollCallback, windowScrollCallback])

  const entityList = statusSelector.hasData(statusList) ? statusList.data.entityList : null

  const renderList = (status: Status) => {
    if (statusSelector.hasData(statusList)) {
      return entityList && entityList.length
        ? entityList.map((entity, index) => renderEntity(entity, index))
        : (<p>No results.</p>)
    }

    return null
  }

  return (
    <Container
      height={height}
      ref={ref as any}  // FIXME: any: https://github.com/styled-components/styled-components/issues/2202#issuecomment-437714783
      // FIXME: we're going to wait to customise the container styles if we are changing the
      // function used to display the entities here, once there is more than list view.
    >
      {statusList.status === Status.Loading && (<ListCover><Loader /></ListCover>)}

      {renderList(statusList.status)}

      {statusList.status === Status.Updating && (
      <PaginationActive><Loader /></PaginationActive>
    )}
    </Container>
  )
}
