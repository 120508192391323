import React, { useEffect, useRef, useState } from 'react'
import { createFormBag, FormBag, FormData } from 'react-formage'

import { Fieldset } from 'components/ui/fieldset'
import { FooterEditEntity } from 'components/ui/footer'
import { Input } from 'components/ui/input'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { MultiSelect } from 'components/ui/select'
import { StatusGuard } from 'components/ui/status-guard'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'
import { spacesBindableActionCreators } from 'domains/spaces/actions'
import { SpacesStore } from 'domains/spaces/store'

import { Space } from 'generated/mos/structure'

import { areObjectsEqual, pick } from 'helpers/core'
import { statusSelector, Status } from 'helpers/status'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'
import { UpdateSoundEffectZoneRequest } from 'domains/org/wam/types'

type FormValues = {
  slug: string | undefined
  audioFilename: string
  triggerSpaces: ReadonlyArray<Space.Ref>
  audioDurationSeconds: number
  retriggerIntervalSeconds: number
}

type ActionProps = Pick<WamActionCreators, 'wamGetSoundEffectZoneRequest' | 'wamUpdateSoundEffectZoneRequest'> &
  Pick<typeof spacesBindableActionCreators, 'spacesRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps = Pick<WamAppState['soundEffectZones'], 'soundEffectZoneUpdate'> & Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  soundEffectZoneId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const SoundEffectPageView = (props: Props) => {
  const {
    historyPush,
    projectId,
    tourId,
    soundEffectZoneId,
    soundEffectZoneUpdate,
    spacesList,
    spacesRequest,
    toastNotification,
    wamGetSoundEffectZoneRequest,
    wamUpdateSoundEffectZoneRequest,
  } = props
  const spacesListData = statusSelector.data(spacesList)

  const data = statusSelector.data(soundEffectZoneUpdate)
  const slug = data ? data.slug : ''

  const [formBag, setFormBag] = useState<FormBag<FormValues> | undefined>(undefined)
  const hasSubmitted = useRef<boolean>(false)

  useEffect(() => {
    spacesRequest()
    wamGetSoundEffectZoneRequest({
      ecmsTourId: tourId,
      ecmsEffectSlug: soundEffectZoneId
   })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (soundEffectZoneUpdate.status === Status.Ready && initialFormValues) {
      setFormBag(createFormBag(initialFormValues))
    }

    if (soundEffectZoneUpdate.status === Status.Ready && hasSubmitted.current) {
      toastNotification({
        type: 'success',
        text: 'Sound effect updated',
      })
      hasSubmitted.current = false
    }

    if (soundEffectZoneUpdate.status === Status.Failed && hasSubmitted.current) {
      toastNotification({
        type: 'error',
        text: 'Sound effect could not be updated',
      })
      hasSubmitted.current = false
    }
  }, [soundEffectZoneUpdate.status])

  const formValues = (data: any) => {
    const { slug, triggerSpaces, audioFilename, audioDurationSeconds, retriggerIntervalSeconds } = data
    return {
      slug,
      triggerSpaces,
      audioFilename,
      audioDurationSeconds,
      retriggerIntervalSeconds
    }
  }

  const initialFormValues: FormValues | undefined =
    data && data.slug === soundEffectZoneId ? formValues(data) : undefined

  const modified =
    initialFormValues && data && formBag && !areObjectsEqual(initialFormValues, formBag.values)

  const saveHandler = () => {
    if (formBag && data) {
      const payload: UpdateSoundEffectZoneRequest = {
        ecmsTourId: tourId,
        ecmsEffectSlug: soundEffectZoneId,
        retriggerIntervalSeconds: formBag.values.retriggerIntervalSeconds,
        triggerSpaces: formBag.values.triggerSpaces
      }

      wamUpdateSoundEffectZoneRequest(payload)
      hasSubmitted.current = true
    }
  }

  return (
    <LayoutListOrFormPage
      nav={() => (
        <PrimaryNavigationEditEntity
          title="Edit Sound Effect"
          onConfirm={modified ? () => historyPush(`/project/${projectId}/tour/${tourId}/sound-effect-zones`) : undefined}
          navBackRoute={`/project/${projectId}/tour/${tourId}/sound-effect-zones`}
        />
      )}
      sidebar={() => null}
      subnav={() => null}
      title={`Sound Effect ${slug}`}
      content={() => (
        <StatusGuard status={soundEffectZoneUpdate}>
          {formBag && data && spacesListData &&
            <form
              noValidate
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              <FormData
                bag={formBag}
                onUpdate={(event: { bag: any }) => {
                  setFormBag(event.bag)
                }}
                validate={() => ({})}
              >
                <Fieldset legend="Details">
                  <Input
                    isDisabled
                    label="Sound Effect Slug"
                    field="slug"
                  />
                  <MultiSelect<FormValues, 'triggerSpaces'>
                    label="Trigger space(s)"
                    field="triggerSpaces"
                    options={spacesListData.map(space => ({
                      label: space.displayName || space.name,
                      value: Space.mustRef(space.ref)
                    }))}
                  />
                  <Input
                    isDisabled
                    label="Audio Asset URL"
                    field="audioFilename"
                  />
                  <Input
                    isDisabled
                    label="Duration"
                    field="audioDurationSeconds"
                    description="Time in seconds"
                  />
                  <Input
                    label="Re-trigger Interval"
                    labelSupportingText="(seconds)"
                    field="retriggerIntervalSeconds"
                  />
                </Fieldset>
              </FormData>
            </form>
          }
        </StatusGuard>
      )}
      footer={() => (
        <FooterEditEntity
          entity={soundEffectZoneUpdate}
          modified={modified}
          onCancel={() => historyPush(`/project/${projectId}/tour/${tourId}/sound-effect-zones`)}
          onSave={saveHandler}
        />
      )}
    />
  )
}

export const SoundEffectPage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.soundEffectZones, 'soundEffectZoneUpdate'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(actionCreators, 'wamGetSoundEffectZoneRequest', 'wamUpdateSoundEffectZoneRequest'),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(SoundEffectPageView)
