import React from 'react'

import styled from 'styled';

const Stage = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  flex-grow: 0;
  flex-shrink: 0;
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  overflow: hidden;
`;

const BodyGrid = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const BodyLeft = styled.aside`
  position: relative;
  flex-basis: 440px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const BodyRight = styled.main`
  position: relative;
  flex-grow: 1;
`;

type Props = {
  readonly nav: () => React.ReactNode;
  readonly sidebar: () => React.ReactNode;
  readonly content: () => React.ReactNode;
};

export const LayoutLocateEditPage = (props: Props) => (
  <Stage>
    <Nav>{props.nav()}</Nav>
    <Body>
      <BodyGrid>
        <BodyLeft>{props.sidebar()}</BodyLeft>
        <BodyRight>{props.content()}</BodyRight>
      </BodyGrid>
    </Body>
  </Stage>
);
