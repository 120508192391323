import React from 'react'
import { Redirect } from 'react-router-dom';

import { connect, globalActionCreators } from 'containers/store';
import { accountBindableActionCreators } from 'domains/account/actions'
import { pick } from 'helpers/core';
import { resetErrorLoggingIdentifiers } from 'helpers/logging'

import { AuthSession } from 'services';


type DirectProps = {};

type ConnectedProps = {
  readonly session: AuthSession | undefined;
};

type ActionProps = Pick<typeof globalActionCreators, 'reset'> & Pick<typeof accountBindableActionCreators, 'accountLogout'>;

type Props = ActionProps & ConnectedProps & DirectProps;

class SignOutPageView extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.session) {
      this.props.accountLogout();
      this.props.reset();

      // once complete, clear the error logging scope.
      resetErrorLoggingIdentifiers()
    }
  }

  public render() {
    if (!this.props.session) {
      return <Redirect to="/" />;
    }
    return null;
  }
}

export const SignOutPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({ session: store.account.session }),
  {
    ...pick(accountBindableActionCreators, 'accountLogout'),
    ...pick(globalActionCreators, 'reset'),
  },
)(SignOutPageView);
