import React, { ReactNode } from 'react'

import styled from 'styled'
import { iconSizes, IconSizes } from 'styled/core'
import { ThemeColor } from 'styled/theme'

const StyledIcon = styled.span<{ color: ThemeColor; rotate?: number; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ rotate }) => rotate || 0}deg);

  & svg {
    fill: ${({ theme, color }) => theme.color[color]};
  }
`;

const Svg = styled.svg`
  path: {
    fill: currentColor;
  }
`;

export type Props = {
  readonly children?: ReactNode;
  /**
   * A key of `ThemeColor`.
   */
  readonly color?: ThemeColor;
  /**
   * Degrees of rotation, used for an optional `transform: rotate()`.
   */
  readonly rotate?: number;
  /**
   * A key of `iconSizes`.
   */
  readonly size?: IconSizes;
};

export const ICON_CONTAINER_CLASSNAME = 'icon'

export const Icon = ({size: propsSize, color, rotate, children, ...rest}: Props) => {
  const size = propsSize ? iconSizes[propsSize] : iconSizes.medium

  return (
    <StyledIcon
      className={ICON_CONTAINER_CLASSNAME}
      color={color || 'gray'}
      rotate={rotate}
      size={size}
      {...rest}
    >
      <Svg height={size} width={size} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
        {children}
      </Svg>
    </StyledIcon>
  );
}

Icon.defaultProps = {
  color: 'gray',
  size: 'medium',
}
