import styled from 'styled';
import { space } from 'helpers/style'

export const AttributeBlockAppend = styled.div`
  padding: ${space(4)};
`;

export const Section = styled.div`
  padding: 0 ${space(6)};
`;

export const SectionHeader = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  margin-top: ${space(9)};
  margin-bottom: ${space(9)};
  font-size: 20px;
`;

export const ToggleSpaces = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${space(6)};
`;


export const LastButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${space(6)} ${space(6)};
`;

export const NoBoundaryLabel = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  border-top: 1px solid ${({ theme }) => theme.color.grayL80};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL80};
  padding: ${space(3)} ${space(4)};
`;
