import { ImageWithFallback } from 'components/ui/image'
import { space } from 'helpers/style'
import styled, { css } from 'styled'

export const listItemBaseStyles = css<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${space(3)};
  padding: ${space(3)};
  border: 1px solid ${({ theme }) => theme.color.grayL70};
  border-radius: 4px;
  height: 70px;

  ${({ theme, disabled }) => disabled
    ?`cursor: not-allowed;
      opacity: 0.5;`
    :`cursor: default;
      &:hover {
        border-color: ${theme.color.grayL40};
      }`
  }
`

/**
 * This component is a simple styled component used to create a `<label>` which displays list items of options presented to the user, usually with a checkbox or radio input functionality.
 *
 * These situations don't use formage to manage form state, so you are responsible for managing your own state.
 *
 * Specialised variations of checkbox and radio are provided as `CheckboxInputElement` and `RadioInputElement` respectively. Examples of their usage follow below.
 *
 * ## Utility styled components
 *
 * When assembling these selection views (often within a modal) you will be presenting additional information about the entities you are selecting from. Because each entity is different you can construct your own list item, but the following components are exported to speed up the process and keep styles aligned:
 *
 * - `Image`: Uses the `ImageWithFallback` component to display an optional thumbnail of the entity where appropriate.
 * - `Description`: You will almost always want to use this to wrap the main text content of the list item. It can contain:
 *   - `Title`: The name or title of the entity. This should always be present.
 *   - `Subtitle`: Optionally there may be some additional copy to provide context about the entity.
 * - `IdentifyingText`: In certain situations it may be appropriate to display some type of unique identifier for the entity. For example when displaying an entity that originated from an external source, and it's external identifier is a searchable string.
 *
 * ## Base styles
 *
 * In other situations where you need to display a list of entities but want to use the same styles for the outer container, these are exported as `listItemBaseStyles`.
 */
export const ListItemLabel = styled.label<{ disabled?: boolean }>`
  ${listItemBaseStyles}
`;

export const Image = styled(ImageWithFallback)`
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin-right: ${space(1)};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space(1)};
  margin-right: ${space(2)};
  text-align: left;
  overflow: hidden;
`

export const Title = styled.span`
  color: ${({ theme }) => theme.color.grayD80};
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.color.grayL40};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.base};
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IdentifyingText = styled.span`
  margin-left: auto;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};
`
