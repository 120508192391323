/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconDragHandle = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M10.125 21.677c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S9 23.48 9 22.839c0-.642.504-1.162 1.125-1.162zm3.75 0c.621 0 1.125.52 1.125 1.162C15 23.48 14.496 24 13.875 24s-1.125-.52-1.125-1.161c0-.642.504-1.162 1.125-1.162zm-3.75-5.419c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162S9 18.06 9 17.419c0-.641.504-1.16 1.125-1.16zm3.75 0c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162s-1.125-.52-1.125-1.162c0-.641.504-1.16 1.125-1.16zm-3.75-5.42c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S9 12.641 9 12s.504-1.161 1.125-1.161zm3.75 0c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S12.75 12.641 12.75 12s.504-1.161 1.125-1.161zm-3.75-5.419c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.16-1.125 1.16S9 7.223 9 6.582c0-.642.504-1.162 1.125-1.162zm3.75 0c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.16-1.125 1.16s-1.125-.519-1.125-1.16c0-.642.504-1.162 1.125-1.162zM10.125 0c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162S9 1.803 9 1.16C9 .52 9.504 0 10.125 0zm3.75 0C14.496 0 15 .52 15 1.161c0 .642-.504 1.162-1.125 1.162s-1.125-.52-1.125-1.162C12.75.52 13.254 0 13.875 0z"  fillRule="evenodd" />
  </Icon>
)

// for use with mapbox addables.
export const IconDragHandleString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M10.125 21.677c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S9 23.48 9 22.839c0-.642.504-1.162 1.125-1.162zm3.75 0c.621 0 1.125.52 1.125 1.162C15 23.48 14.496 24 13.875 24s-1.125-.52-1.125-1.161c0-.642.504-1.162 1.125-1.162zm-3.75-5.419c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162S9 18.06 9 17.419c0-.641.504-1.16 1.125-1.16zm3.75 0c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162s-1.125-.52-1.125-1.162c0-.641.504-1.16 1.125-1.16zm-3.75-5.42c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S9 12.641 9 12s.504-1.161 1.125-1.161zm3.75 0c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.161-1.125 1.161S12.75 12.641 12.75 12s.504-1.161 1.125-1.161zm-3.75-5.419c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.16-1.125 1.16S9 7.223 9 6.582c0-.642.504-1.162 1.125-1.162zm3.75 0c.621 0 1.125.52 1.125 1.162 0 .641-.504 1.16-1.125 1.16s-1.125-.519-1.125-1.16c0-.642.504-1.162 1.125-1.162zM10.125 0c.621 0 1.125.52 1.125 1.161 0 .642-.504 1.162-1.125 1.162S9 1.803 9 1.16C9 .52 9.504 0 10.125 0zm3.75 0C14.496 0 15 .52 15 1.161c0 .642-.504 1.162-1.125 1.162s-1.125-.52-1.125-1.162C12.75.52 13.254 0 13.875 0z"  fillRule="evenodd" />
  </svg>`

IconDragHandle.displayName = 'IconDragHandle'
