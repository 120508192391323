/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconBuilding = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19.556 2v20h-2.223v-8.889H12.89V22H4V2h15.556zm-8.89 11.111H6.223v6.667h4.445V13.11zm0-6.667H6.223v4.445h4.445V6.444zm6.667 0H12.89v4.445h4.444V6.444z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconBuildingString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19.556 2v20h-2.223v-8.889H12.89V22H4V2h15.556zm-8.89 11.111H6.223v6.667h4.445V13.11zm0-6.667H6.223v4.445h4.445V6.444zm6.667 0H12.89v4.445h4.444V6.444z"  fillRule="nonzero" />
  </svg>`

IconBuilding.displayName = 'IconBuilding'
