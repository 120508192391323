// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Metadata } from "generated/mos/entity";
import { PlacedPolygon } from "generated/mos/geo";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMOSMultiPolygon, decodeMOSPoint, decodeMOSPolygon, decodeMessageRepeated, encodeMOSMultiPolygon, encodeMOSPoint, encodeMOSPolygon, ensureScalar } from "generated/webrpc";
import { MultiPolygon2D, Point2D, Polygon2D } from "helpers/geo";

export namespace AdminMap {
  export type Ref = { readonly typename: 'mos.structure.AdminMap', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.AdminMap');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a AdminMap`);
    return v;
  }
  export const refName = "mos.structure.AdminMap" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.AdminMap', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The Level that this AdminMap is attached to
    readonly levelRef: { readonly typename: 'mos.structure.BuildingLevel' | 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    // The image should be considered immutable for an AdminMap - if the image needs to be updated,
    // a new AdminMap should be created. Given that, the <image_url> should be considered immutable,
    // <image_width> and <image_height> should also be considered immutable.
    readonly imageUrl: string,
    readonly imageWidth: number,
    readonly imageHeight: number,
    // Instead of defining two arbitrary "anchor points", we are baking in the assumption that the
    // anchor points are the bottom left and top right corners of the image. This should maximise
    // accuracy of the geo-reference and reduce the need for arbitrary anchor point selection when
    // geo-referencing an image.
    readonly bottomLeftGeoReference: Point2D | undefined,
    readonly topRightGeoReference: Point2D | undefined,
    // These two geo references are derived from the bottom_left/top_right references,
    // and cannot be mutated directly.
    readonly topLeftGeoReference: Point2D | undefined,
    readonly bottomRightGeoReference: Point2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    levelRef: undefined,
    imageUrl: "",
    imageWidth: 0,
    imageHeight: 0,
    bottomLeftGeoReference: undefined,
    topRightGeoReference: undefined,
    topLeftGeoReference: undefined,
    bottomRightGeoReference: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["levelRef"] = v.levelRef;
      obj["imageUrl"] = v.imageUrl;
      obj["imageWidth"] = v.imageWidth;
      obj["imageHeight"] = v.imageHeight;
      obj["bottomLeftGeoReference"] = encodeMOSPoint(v.bottomLeftGeoReference);
      obj["topRightGeoReference"] = encodeMOSPoint(v.topRightGeoReference);
      obj["topLeftGeoReference"] = encodeMOSPoint(v.topLeftGeoReference);
      obj["bottomRightGeoReference"] = encodeMOSPoint(v.bottomRightGeoReference);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.AdminMap");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["levelRef"] = v["levelRef"];
      out["imageUrl"] = ensureScalar(ctx, v["imageUrl"], "string");
      out["imageWidth"] = ensureScalar(ctx, v["imageWidth"], "number");
      out["imageHeight"] = ensureScalar(ctx, v["imageHeight"], "number");
      out["bottomLeftGeoReference"] = decodeMOSPoint(ctx, v["bottomLeftGeoReference"]);
      out["topRightGeoReference"] = decodeMOSPoint(ctx, v["topRightGeoReference"]);
      out["topLeftGeoReference"] = decodeMOSPoint(ctx, v["topLeftGeoReference"]);
      out["bottomRightGeoReference"] = decodeMOSPoint(ctx, v["bottomRightGeoReference"]);
      return out as any as Entity;
    }
  }();
}

export namespace Building {
  export type Ref = { readonly typename: 'mos.structure.Building', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.Building');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Building`);
    return v;
  }
  export const refName = "mos.structure.Building" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The Site that this Building belongs to
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    // The BuildingLevels that belong to this Building
    readonly buildingLevelRefs: ReadonlyArray<{ readonly typename: 'mos.structure.BuildingLevel', readonly id: string }>,
    // Attributes
    readonly name: string,
    // DEPRECATED. Use boundaries. Will be removed in a future release.
    readonly boundary: Polygon2D | undefined,
    // The boundary of a building defines the area you cannot physically occupy
    // on the Outdoor Level. The building’s boundary does not represent the
    // extent of the building’s dimensions, it represents the obstacles to
    // pedestrian movement on the Outdoor Level.
    // 
    // Warning: Multiple boundaries are supported in the protocol and the Locate
    // service, but it is not yet guaranteed that any other implementation will
    // support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    siteRef: undefined,
    buildingLevelRefs: [],
    name: "",
    boundary: undefined,
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["siteRef"] = v.siteRef;
      obj["buildingLevelRefs"] = v.buildingLevelRefs;
      obj["name"] = v.name;
      obj["boundary"] = encodeMOSPolygon(v.boundary);
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.Building");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["siteRef"] = v["siteRef"];
      out["buildingLevelRefs"] = v["buildingLevelRefs"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundary"] = decodeMOSPolygon(ctx, v["boundary"]);
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

// Building Levels are levels that exist as part of a Building.
// 
// Building Levels represent any structural vertical area that a person can stand
// on that is directly associated with a Building, for example a floor in a
// gallery, a rooftop garden, or a courtyard.
// 
export namespace BuildingLevel {
  export type Ref = { readonly typename: 'mos.structure.BuildingLevel', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.BuildingLevel');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a BuildingLevel`);
    return v;
  }
  export const refName = "mos.structure.BuildingLevel" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.BuildingLevel', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The Site that this BuildingLevel belongs to
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    // The Building that this BuildingLevel belongs to
    readonly buildingRef: { readonly typename: 'mos.structure.Building', readonly id: string } | undefined,
    // The AdminMap for this BuildingLevel
    readonly adminMapRefs: ReadonlyArray<{ readonly typename: 'mos.structure.AdminMap', readonly id: string }>,
    // The list of spaces that refer to this level. Spaces can attach to multiple
    // levels so this is not an ownership relationship, it's an association. See
    // MultiPlacedPolygon and Space.boundaries for more information.
    readonly spaceRefs: ReadonlyArray<{ readonly typename: 'mos.structure.Space', readonly id: string }>,
    readonly name: string,
    readonly shortName: string,
    // <z_order> is used for sort/display ordering, but should not be shown to users. That's
    // what <name> and <short_name> are for.
    readonly zOrder: number,
    // DEPRECATED. Use boundaries. Will be removed in a future release.
    readonly boundary: Polygon2D | undefined,
    // Warning: Multiple boundaries are supported in the protocol and the Locate
    // service, but it is not yet guaranteed that any other implementation will
    // support more than one.
    readonly boundaries: MultiPolygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    siteRef: undefined,
    buildingRef: undefined,
    adminMapRefs: [],
    spaceRefs: [],
    name: "",
    shortName: "",
    zOrder: 0,
    boundary: undefined,
    boundaries: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["siteRef"] = v.siteRef;
      obj["buildingRef"] = v.buildingRef;
      obj["adminMapRefs"] = v.adminMapRefs;
      obj["spaceRefs"] = v.spaceRefs;
      obj["name"] = v.name;
      obj["shortName"] = v.shortName;
      obj["zOrder"] = v.zOrder;
      obj["boundary"] = encodeMOSPolygon(v.boundary);
      obj["boundaries"] = encodeMOSMultiPolygon(v.boundaries);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.BuildingLevel");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["siteRef"] = v["siteRef"];
      out["buildingRef"] = v["buildingRef"];
      out["adminMapRefs"] = v["adminMapRefs"];
      out["spaceRefs"] = v["spaceRefs"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["shortName"] = ensureScalar(ctx, v["shortName"], "string");
      out["zOrder"] = ensureScalar(ctx, v["zOrder"], "number");
      out["boundary"] = decodeMOSPolygon(ctx, v["boundary"]);
      out["boundaries"] = decodeMOSMultiPolygon(ctx, v["boundaries"]);
      return out as any as Entity;
    }
  }();
}

// Each Site has a single Outdoor Level, which is used to represent everything
// placed outside, but within the boundary of the Site.
// 
// Outdoor Levels inherit the boundary of the Site that they belong to. They
// are used to attach anything that isn’t contained within a Building.
// 
export namespace OutdoorLevel {
  export type Ref = { readonly typename: 'mos.structure.OutdoorLevel', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.OutdoorLevel');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a OutdoorLevel`);
    return v;
  }
  export const refName = "mos.structure.OutdoorLevel" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The Site that this OutdoorLevel belongs to
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    // The AdminMap for this OutdoorLevel
    readonly adminMapRefs: ReadonlyArray<{ readonly typename: 'mos.structure.AdminMap', readonly id: string }>,
    // The list of spaces that refer to this level. Spaces can attach to multiple
    // levels so this is not an ownership relationship, it's an association. See
    // MultiPlacedPolygon and Space.boundaries for more information.
    readonly spaceRefs: ReadonlyArray<{ readonly typename: 'mos.structure.Space', readonly id: string }>,
    // Attributes
    readonly name: string,
    readonly shortName: string,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    siteRef: undefined,
    adminMapRefs: [],
    spaceRefs: [],
    name: "",
    shortName: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["siteRef"] = v.siteRef;
      obj["adminMapRefs"] = v.adminMapRefs;
      obj["spaceRefs"] = v.spaceRefs;
      obj["name"] = v.name;
      obj["shortName"] = v.shortName;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.OutdoorLevel");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["siteRef"] = v["siteRef"];
      out["adminMapRefs"] = v["adminMapRefs"];
      out["spaceRefs"] = v["spaceRefs"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["shortName"] = ensureScalar(ctx, v["shortName"], "string");
      return out as any as Entity;
    }
  }();
}

// Sites represent a grouping of structures and content. They are spatially
// defined by a boundary in the form of a polygon of geo-coordinates.
// 
// Sites should generally map to something that makes sense for each
// organisation.
// 
export namespace Site {
  export type Ref = { readonly typename: 'mos.structure.Site', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.Site');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Site`);
    return v;
  }
  export const refName = "mos.structure.Site" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The OutdoorLevel that belongs to this Site.
    // The OutdoorLevel of a site inherits the Site's boundary.
    readonly outdoorLevelRef: { readonly typename: 'mos.structure.OutdoorLevel', readonly id: string } | undefined,
    // The Buildings that belong to this Site
    readonly buildingRefs: ReadonlyArray<{ readonly typename: 'mos.structure.Building', readonly id: string }>,
    // The Spaces that belong to this Site
    readonly spaceRefs: ReadonlyArray<{ readonly typename: 'mos.structure.Space', readonly id: string }>,
    readonly name: string,
    // Boundary represents the geographical extent of the site.
    // An Organisation can have multiple Sites, but the boundaries MUST NOT
    // overlap. Boundaries SHOULD NOT span more than 5km in any direction.
    // 
    // The OutdoorLevel of a site inherits this boundary.
    readonly boundary: Polygon2D | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    outdoorLevelRef: undefined,
    buildingRefs: [],
    spaceRefs: [],
    name: "",
    boundary: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["outdoorLevelRef"] = v.outdoorLevelRef;
      obj["buildingRefs"] = v.buildingRefs;
      obj["spaceRefs"] = v.spaceRefs;
      obj["name"] = v.name;
      obj["boundary"] = encodeMOSPolygon(v.boundary);
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.Site");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["outdoorLevelRef"] = v["outdoorLevelRef"];
      out["buildingRefs"] = v["buildingRefs"];
      out["spaceRefs"] = v["spaceRefs"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["boundary"] = decodeMOSPolygon(ctx, v["boundary"]);
      return out as any as Entity;
    }
  }();
}

// Spaces describe a physical space within a Site. They are defined using a
// boundary polygon of geo-coordinates on a level (any type of level). Spaces
// describe spaces that have semantic meaning that is intrinsic to the building,
// and include types like:
// 
// - Rooms
// - Hallways
// - Restrooms
// - Galleries
// - Foyers
// - Elevators
// - Stairs
// 
// Space describe things that are unambiguous, and are not intended to be used
// for subjective, experiential spatial modelling.
// 
export namespace Space {
  export type Ref = { readonly typename: 'mos.structure.Space', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.Space');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a Space`);
    return v;
  }
  export const refName = "mos.structure.Space" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.Space', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // The Site that this Space belongs to
    readonly siteRef: { readonly typename: 'mos.structure.Site', readonly id: string } | undefined,
    // Multple boundaries can be used to describe things like Spaces or Amenities
    // that cross level boundaries - e.g. a "Parking" amenity will often cover
    // multiple levels, but from the perspective of a visitor, it's a single
    // "thing". If they want directions to that "Parking" amenity, they'll want
    // directions to whatever of the multiple polygons is closest (which could be
    // on any level that the amenity covers), not to some arbitrary "primary"
    // polygon.
    // 
    // The boundaries can cover multiple levels in a single Site, but a single
    // Space MUST NOT cover levels that are in different Sites.
    // 
    // There is no explicit limitation placed on boundaries appearing in multiple
    // buildings.
    readonly boundaries: ReadonlyArray<PlacedPolygon.Entity>,
    // Internal name of this Space. Space names may not be unique.
    readonly name: string,
    // Human-readable name of this Space, suitable for presenting to the end user. Language of this name is project-dependent.
    readonly displayName: string,
    // SpaceType describes what type of space this is, for example a room, a
    // hallway, etc. A ref to a valid SpaceType is required.
    readonly spaceTypeRef: { readonly typename: 'mos.structure.SpaceType', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    siteRef: undefined,
    boundaries: [],
    name: "",
    displayName: "",
    spaceTypeRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["siteRef"] = v.siteRef;
      {
        const vs = [];
        for (const item of v.boundaries) {
          vs.push(PlacedPolygon.codec.encode(item));
        }
        obj["boundaries"] = { placedPolygons: vs };
      }
      obj["name"] = v.name;
      obj["displayName"] = v.displayName;
      obj["spaceTypeRef"] = v.spaceTypeRef;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.Space");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["siteRef"] = v["siteRef"];
      {
        const from = v["boundaries"];
        out["boundaries"] = from && from["placedPolygons"] ? decodeMessageRepeated(ctx, PlacedPolygon.codec, from["placedPolygons"], "boundaries") : [];
      }
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["displayName"] = ensureScalar(ctx, v["displayName"], "string");
      out["spaceTypeRef"] = v["spaceTypeRef"];
      return out as any as Entity;
    }
  }();
}

// SpaceType describes the type of a space, for example a room, a hallway, etc.
// 
// It is analogous to the "Unit Category" in Apple's IMDF
// (https://register.apple.com/resources/imdf/Categories/#unit).
// 
export namespace SpaceType {
  export type Ref = { readonly typename: 'mos.structure.SpaceType', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.structure.SpaceType');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a SpaceType`);
    return v;
  }
  export const refName = "mos.structure.SpaceType" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.structure.SpaceType', readonly id: string } | undefined,
    // User-facing, formatted representation of the SpaceType.
    readonly name: string,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    name: "",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.structure.SpaceType");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      return out as any as Entity;
    }
  }();
}

