import React from 'react'

import { IconBuilding, IconLevels, IconSite, IconType } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled from 'styled';

import { Steps } from '.';
import { Title } from './common';

export const SelectStructureTypeHeader = () => (
  <>
    <Title>Add new structures</Title>
    <p>Create a new site building or level. After creating it
      <br /> you can set boundaries and more.
    </p>
  </>
);

const Row = styled.div`
  display: flex;
  height: 180px;
  width: 100%;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: ${space(2)};
  border-radius: 6px;
  border: solid 1px #e7e7e7;
  cursor: pointer;
  &:hover {
    border-color: #7a7a7a;
  }
`;

const IconWrapper = styled.div`
  border: solid 1px #e7e7e7;
  border-radius: 50%;
  padding: ${space(3)};
`;

const SelectableTitle = styled.p`
  margin: ${space(2)} 0 0 0;
`;

type SelectableProps = {
  readonly icon: IconType;
  readonly title: string;
  readonly onClick: () => void;
  readonly className?: string;
};

const Selectable = ({ icon, title, onClick }: SelectableProps) => {
  const IconComponent = icon;
  return (
    <Wrapper onClick={onClick}>
      <IconWrapper><IconComponent /></IconWrapper>
      <SelectableTitle>{title}</SelectableTitle>
    </Wrapper>
  )
};

export const SelectStructureType = ({ setStep }: { setStep: (step: keyof Steps) => void }) => (
  <>
    <Row>
      <Selectable
        title="Site"
        icon={IconSite}
        onClick={() => setStep('selectSite') }
      />
      <Selectable
        title="Building"
        icon={IconBuilding}
        onClick={() => setStep('selectBuilding') }
      />
    </Row>
    <Row>
      <Selectable
        title="Building Level"
        icon={IconLevels}
        onClick={() => setStep('selectBuildingLevel') }
      />
    </Row>
  </>
);
