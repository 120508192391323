/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconTarget = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M12.917 1.979v1.876c1.905.227 3.532 1.016 4.883 2.367 1.35 1.35 2.125 2.978 2.324 4.882H22v1.792h-1.876c-.2 1.904-.974 3.532-2.324 4.882-1.35 1.35-2.978 2.14-4.883 2.367v1.876h-1.834v-1.876c-1.876-.227-3.497-1.016-4.861-2.367-1.365-1.35-2.147-2.978-2.346-4.882H2v-1.792h1.876c.2-1.904.981-3.532 2.346-4.882 1.364-1.35 2.985-2.14 4.861-2.367V1.979h1.834zm-.896 3.667c-1.762 0-3.269.618-4.52 1.855C6.251 8.738 5.625 10.237 5.625 12c0 1.763.625 3.27 1.876 4.52 1.251 1.223 2.758 1.834 4.52 1.834 1.763 0 3.263-.618 4.5-1.855 1.236-1.237 1.854-2.736 1.854-4.499 0-1.763-.618-3.262-1.855-4.499-1.236-1.237-2.736-1.855-4.499-1.855zM12 8.376c1.01 0 1.87.355 2.58 1.065.71.711 1.066 1.564 1.066 2.559 0 .995-.355 1.848-1.066 2.559A3.514 3.514 0 0112 15.625c-1.01 0-1.87-.356-2.58-1.066-.71-.711-1.066-1.564-1.066-2.559 0-.995.355-1.848 1.066-2.559A3.514 3.514 0 0112 8.375z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconTargetString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M12.917 1.979v1.876c1.905.227 3.532 1.016 4.883 2.367 1.35 1.35 2.125 2.978 2.324 4.882H22v1.792h-1.876c-.2 1.904-.974 3.532-2.324 4.882-1.35 1.35-2.978 2.14-4.883 2.367v1.876h-1.834v-1.876c-1.876-.227-3.497-1.016-4.861-2.367-1.365-1.35-2.147-2.978-2.346-4.882H2v-1.792h1.876c.2-1.904.981-3.532 2.346-4.882 1.364-1.35 2.985-2.14 4.861-2.367V1.979h1.834zm-.896 3.667c-1.762 0-3.269.618-4.52 1.855C6.251 8.738 5.625 10.237 5.625 12c0 1.763.625 3.27 1.876 4.52 1.251 1.223 2.758 1.834 4.52 1.834 1.763 0 3.263-.618 4.5-1.855 1.236-1.237 1.854-2.736 1.854-4.499 0-1.763-.618-3.262-1.855-4.499-1.236-1.237-2.736-1.855-4.499-1.855zM12 8.376c1.01 0 1.87.355 2.58 1.065.71.711 1.066 1.564 1.066 2.559 0 .995-.355 1.848-1.066 2.559A3.514 3.514 0 0112 15.625c-1.01 0-1.87-.356-2.58-1.066-.71-.711-1.066-1.564-1.066-2.559 0-.995.355-1.848 1.066-2.559A3.514 3.514 0 0112 8.375z"  fillRule="nonzero" />
  </svg>`

IconTarget.displayName = 'IconTarget'
