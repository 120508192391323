import { BuildingLevel, Building, OutdoorLevel, Site, AdminMap } from 'generated/mos/structure';
import { Structures, StructureRef } from './entities';
import { RefUrn, refToUrn, refFromUrn } from 'entity';
import { PlacedPolygon } from 'generated/mos/geo';
import { Polygon2D } from 'helpers/geo';


export const structuresSelector = new class {

  /** Retrieves all parents of the passed ref, as well as the ref itself */
  public stack(structures: Structures, ref: StructureRef): ReadonlyArray<StructureRef> {
    const refs: StructureRef[] = [ref];

    let currentUrn: RefUrn | undefined = refToUrn(ref);
    while (true) {
      const nextRef = structures.parentRef[currentUrn];
      if (!nextRef) {
        return refs;
      }
      refs.push(nextRef);
      currentUrn = refToUrn(nextRef);
    }
  }

  // XXX(bw) 20190530: From a domain point of view, if you have a StructureRef, you MUST
  // be able to retrieve a siteRef because a siteRef MUST exist. Unfortunately, siteRef
  // must be able to return undefined for now due to our data handling/caching: creating
  // new entities does not update the structures list in the store at the moment, so if
  // you try to get the siteRef for something you have just created but before the
  // structures list has been refreshed, it will fail.
  //
  // The solution here may become more apparent when we finish the Redux migration and
  // the GraphQL purge.
  public siteRef(structures: Structures, ref: StructureRef): Site.Ref | undefined {
    const structureStack = this.stack(structures, ref);
    const siteRef = structureStack[structureStack.length - 1];
    return Site.isRef(siteRef) ? siteRef : undefined;
  }

  public containsOrEquals(structures: Structures, parent: StructureRef, child: StructureRef): boolean {
    const parentUrn = refToUrn(parent);
    const childUrn = refToUrn(child);
    if (parentUrn === childUrn) {
      return true;
    }

    let currentUrn: RefUrn | undefined = childUrn;
    while (true) {
      const nextRef = structures.parentRef[currentUrn];
      if (!nextRef) {
        return false;
      }
      currentUrn = refToUrn(nextRef);
      if (currentUrn === parentUrn) {
        return true;
      }
    }
  }

  public namePath(structures: Structures, ref: StructureRef, dflt: ReadonlyArray<string>=[]): ReadonlyArray<string> {
    if (!structures) {
      return dflt;
    }

    const stack = [...this.stack(structures, ref)].reverse();
    const names = [];
    for (const ref of stack) {
      const refUrn = refToUrn(ref);
      const structure = structures.index[refUrn];
      if (!structure) {
        throw new Error(`structure not found for ref ${refUrn}`);
      }
      names.push(structure.name);
    }
    return names;
  }

  // firstBoundary extracts the first boundary from a list of boundaries, if
  // one is set. It is a hack (FIXME!) introduced while migrating to webrpc;
  // the backends expose multiple boundaries but the old BFF GraphQL schema
  // hid that and only supported one.
  public firstBoundary(
    boundaries: ReadonlyArray<PlacedPolygon.Entity> | undefined
  ): PlacedPolygon.Entity | undefined {
    if (!boundaries) {
      return undefined;
    }
    if (boundaries.length > 1) {
      // If this happens, the backend has been updated to return multiple
      // boundaries but the frontend hasn't been updated to support it yet.
      throw new Error("multiple boundaries detected");
    }
    return boundaries.length == 1 ? boundaries[0] : undefined;
  }

  public findSiteByRef(structures: Structures, ref: Site.Ref): Site.Entity | undefined {
    return structures.sites.find(s => s.ref ? s.ref.id === ref.id : false);
  }

  public findBuildingByRef(
    structures: Structures,
    ref: Building.Ref,
  ): Building.Entity | undefined {
    return structures.buildings.find(b => b.ref ? b.ref.id === ref.id : false);
  }

  public findBuildingLevelByRef(
    structures: Structures,
    ref: BuildingLevel.Ref,
  ): BuildingLevel.Entity | undefined {
    return structures.buildingLevels.find(l => l.ref ? l.ref.id === ref.id : false);
  }

  public findOutdoorLevelByRef(
    structures: Structures,
    ref: OutdoorLevel.Ref,
  ): OutdoorLevel.Entity | undefined {
    return structures.outdoorLevels.find(l => l.ref ? l.ref.id === ref.id : false);
  }

  public selectBoundaryByRef(
    structures: Structures,
    ref: StructureRef,
  ): Polygon2D | undefined {
    if (Site.isRef(ref)) {
      const site = this.findSiteByRef(structures, ref);
      return site ? site.boundary : undefined;
    }
    if (Building.isRef(ref)) {
      const building = this.findBuildingByRef(structures, ref);
      return building ? building.boundary : undefined;
    }
    if (BuildingLevel.isRef(ref)) {
      const buildingLevel = this.findBuildingLevelByRef(structures, ref);
      return buildingLevel ? buildingLevel.boundary : undefined;
    }
    if (OutdoorLevel.isRef(ref)) {
      const outdoorLevel = this.findOutdoorLevelByRef(structures, ref);
      if (outdoorLevel === undefined) return undefined;
      // outdoor level inherits the site boundary
      const site = outdoorLevel.siteRef ? this.findSiteByRef(structures, outdoorLevel.siteRef) : undefined;
      return site ? site.boundary : undefined;
    }
  }

  public selectClosestBoundaryByRef(
    structures: Structures,
    ref: StructureRef,
  ): Polygon2D | undefined {
    const structureRefs: readonly StructureRef[] = this.stack(structures, ref);
    for (const structureRef of structureRefs) {
      const boundary = this.selectBoundaryByRef(structures, structureRef);
      if (boundary) return boundary;
    }
  }

  private selectAdminMapByLevelRef(
    structures: Structures,
    ref: OutdoorLevel.Ref | BuildingLevel.Ref,
  ): AdminMap.Entity | undefined {
    return structures.adminMaps.find(
      m =>
        (OutdoorLevel.isRef(ref) && OutdoorLevel.isRef(m.levelRef) && m.levelRef.id === ref.id) ||
        (BuildingLevel.isRef(ref) && BuildingLevel.isRef(m.levelRef) && m.levelRef.id === ref.id),
    );
  }

  public selectAdminMapByRef(
    structures: Structures,
    ref: StructureRef,
  ): AdminMap.Entity | undefined {
    if (Site.isRef(ref)) {
      const outdoorLevel = structures.outdoorLevels.find(
        l => l.siteRef && l.siteRef.id === ref.id);
      if (outdoorLevel === undefined) return undefined;
      if (outdoorLevel.ref === undefined) return undefined;
      return this.selectAdminMapByLevelRef(structures, outdoorLevel.ref);
    }

    if (OutdoorLevel.isRef(ref) || BuildingLevel.isRef(ref)) {
      return this.selectAdminMapByLevelRef(structures, ref);
    }
  }
}();
