import { SharedAppState } from 'containers/shared';
import { DataStatus as GenericDataStatus, EntityStatus as GenericEntityStatus, Status, statusList } from 'helpers/status';
import { AuthDevice, AuthSession } from 'services'

type AccountErrorCode = string;

export type DataStatus<T> = GenericDataStatus<T, AccountErrorCode>;
export type EntityStatus<T extends object> = GenericEntityStatus<T, AccountErrorCode, string>;

export type TokenValidationResult = {
  readonly valid: boolean;
};

export type TokenStatus = {
  readonly status: typeof statusList;
}

export type AccountAppState = {
  readonly assignNewPasswordStatus: DataStatus<undefined>;
  readonly requestNewPasswordStatus: DataStatus<undefined>;
  readonly tokenValidation: DataStatus<TokenValidationResult>;

  // configStatus is a special case for the DataStatus pattern: everything that
  // depends on config presumes that it is guarded by App - nothing should render
  // below App if config is not loaded. Forcing all consumers of the config in
  // the store to handle configStatus is pretty horrible, so in this case, we
  // separate the status and the data.
  readonly configStatus: DataStatus<undefined>;
  readonly device?: AuthDevice | undefined;
  readonly session: AuthSession | undefined;

  readonly loginStatus: DataStatus<undefined>;
  readonly tokenStatus: TokenStatus;
};

export type AccountStore = {
  readonly account: AccountAppState;
  readonly shared: SharedAppState;
};

export const initialAccountAppState: AccountAppState = {
  assignNewPasswordStatus: { status: Status.Idle },
  // config: undefined,
  configStatus: { status: Status.Idle },
  device: undefined,
  loginStatus: { status: Status.Idle },
  requestNewPasswordStatus: { status: Status.Idle },
  session: undefined,
  tokenStatus: { status: Status.Idle },
  tokenValidation: { status: Status.Idle },
};