import { space } from 'helpers/style'
import styled from 'styled'

export const FieldWrapper = styled.div`
  margin-bottom: ${space(6)};
`

export const Preview = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${space(2)};
  min-height: 88px;
  padding: ${space(4)};
  position: relative;
  background-color: ${({ isDisabled, theme }) => isDisabled ? 'transparent' : theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grayL80};
  border-radius: 2px;
`
