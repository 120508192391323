// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Codec, DecodeContext, RemoteObject, WebRPCError, ensureScalar, ensureScalarOptional } from "generated/webrpc";

export namespace Color {
  export const refName = "mos.color.Color" as const;
  export type Entity = {
    readonly type: typeof refName,
    // The amount of red in the colour, for extended sRGB this can extend outside the interval [0, 1]
    readonly red: number,
    // The amount of green in the colour, for extended sRGB this can extend outside the interval [0, 1]
    readonly green: number,
    // The amount of blue in the colour, for extended sRGB this can extend outside the interval [0, 1]
    readonly blue: number,
    // The FloatVal wrapper is used here to allow us to differentiate between not set and 0.0
    // which is transparent
    readonly alpha: number | undefined,
    readonly colorSpace: ColorSpace,
  }
  export const defaults: Entity = {
    type: refName,
    red: 0,
    green: 0,
    blue: 0,
    alpha: undefined,
    colorSpace: "COLOUR_SPACE_UNSPECIFIED",
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.color.Color");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["red"] = ensureScalar(ctx, v["red"], "number");
      out["green"] = ensureScalar(ctx, v["green"], "number");
      out["blue"] = ensureScalar(ctx, v["blue"], "number");
      out["alpha"] = ensureScalarOptional(ctx, v["alpha"], "number");
      {
        const ev = ensureScalar(ctx, v["colorSpace"], "string") || defaults["colorSpace"];
        if (!ColorSpaceValues.has(ev as any)) {
          throw ctx.error(`unknown value "${ev}" for enum .mos.color.ColorSpace`);
        }
        out["colorSpace"] = ev as any;
      }
      return out as any as Entity;
    }
  }();
}

export const ColorSpaceValues = new Set([
  'COLOUR_SPACE_UNSPECIFIED' as const,
  'COLOUR_SPACE_EXTENDED_SRGB' as const,
]);
export type ColorSpace = typeof ColorSpaceValues extends Set<infer U> ? U : never;

