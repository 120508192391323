import React from 'react'

import { ClusterMap } from 'components/cluster-map';
import { StatusGuard } from 'components/ui/status-guard';
import { FullHeightWrapper, PanelBody, PanelHeader } from 'components/ui/side-panel';
import { connect } from 'containers/store';
import * as entity from 'entity';
import { Beacon } from 'generated/mos/beacon'
import { pick } from 'helpers/core';
import * as geo from 'helpers/geo';
import { statusSelector, Status } from 'helpers/status';
import { LayoutLocateEditPage } from 'layouts/locate-edit-page';

import { structuresActionCreators } from 'domains/structures/actions';
import { mustStructureRef, StructureRef, Structures } from 'domains/structures/entities';
import { SpatialScopeSelector } from 'domains/structures/components/spatial-scope-select';
import { DataStatus as StructuresDataStatus, GlobalMapState } from 'domains/structures/store';

import { BeaconCreateForm } from 'domains/beacons/components/beacon-create-form';

import { actionCreators } from '../../actions';
import { BeaconFilterState, BeaconsList, DataStatus } from '../../store';

import { BeaconListGroups } from './beacon-list-collapsible-groups';
import { filterBeacons } from './beacons-data';
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity';

type DirectProps = {
  readonly onCreate: (e: {ref: entity.BeaconRef}) => void;
  readonly navBackRoute?: string;
};

type ConnectedProps = {
  readonly beaconFilters: BeaconFilterState;
  readonly beacons: DataStatus<BeaconsList>;
  readonly globalMap: GlobalMapState;
  readonly structures: StructuresDataStatus<Structures>;
};

type ActionProps =
  Pick<typeof actionCreators, 'beaconsListBeaconsRequest' | 'beaconFiltersUpdate'> &
  Pick<typeof structuresActionCreators, 'structuresLoadRequest' | 'globalMapUpdate'>
;

type Props = ActionProps & ConnectedProps & DirectProps;

type State = {
  readonly showNewBeaconModal: boolean;
  readonly highlightedRef: entity.BeaconRef | undefined;
};

class BeaconListPageView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showNewBeaconModal: false,
      highlightedRef: undefined,
    };
  }

  public componentDidMount() { this.refresh(); }
  public componentDidUpdate() { this.refresh(); }

  private refresh() {
    if (statusSelector.shouldLoad(this.props.beacons.status)) {
      this.props.beaconsListBeaconsRequest();
    }
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  private onSpatialChange = (ref?: StructureRef) => {
    this.props.globalMapUpdate({ activeRef: ref });
  }

  private focusBeaconOnMap = (item: Beacon.Entity) => {
    if (!item.position || !item.position.point) {
      return;
    }
    const { globalMap } = this.props;

    const nextActiveRef = mustStructureRef(item.position.levelRef);
    const newCenter = geo.asPosition2D(item.position.point);

    // Avoid bashing the store with multiple unnecessary updates:
    if (!geo.equal(globalMap.view.center, newCenter) || !entity.isRefEqual(globalMap.activeRef, nextActiveRef)) {
      const view = { zoom: 19, center: newCenter }; // FIXME: hard-coded default zoom
      this.props.globalMapUpdate({ activeRef: nextActiveRef, view });
    }
  }

  private setHighlightedRef = (ref: entity.BeaconRef | undefined) => {
    this.setState({ highlightedRef: ref });
  }

  public render() {
    if (this.props.beacons.status !== Status.Ready || this.props.structures.status !== Status.Ready) {
      // TODO(dc): loading indicator
      return null;
    }
    const pointList = this.props.beacons.data;
    const structures = this.props.structures.data;
    const beaconsData = filterBeacons(
      pointList, structures, this.props.beaconFilters, this.props.globalMap.activeRef);

    const sidebar = (
      <FullHeightWrapper>
        <PanelHeader
          title="Beacons"
          onAdd={() => this.setState({ showNewBeaconModal: true })}
          searchText={this.props.beaconFilters.search}
          onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.beaconFiltersUpdate({ search: e.target.value })}
          onSearchClear={() => this.props.beaconFiltersUpdate({ search: '' })}
        />

        <PanelBody>
          <StatusGuard status={this.props.structures} mode="hideErrors" fullHeight>
            <StatusGuard status={this.props.beacons} mode="hideErrors" fullHeight>
              <FullHeightWrapper>
                <BeaconListGroups
                  structures={structures}
                  beaconsData={beaconsData}
                  focusBeaconOnMap={this.focusBeaconOnMap}
                  highlightedRef={this.state.highlightedRef}
                  setHighlightedRef={this.setHighlightedRef}
                  globalMap={this.props.globalMap}
                  beaconFiltersUpdate={this.props.beaconFiltersUpdate}
                  beaconFilters={this.props.beaconFilters}
                />
              </FullHeightWrapper>
            </StatusGuard>
          </StatusGuard>
        </PanelBody>
      </FullHeightWrapper>
    );

    return (
      <>
        {this.state.showNewBeaconModal && (
          <BeaconCreateForm
            onClose={() => this.setState({ showNewBeaconModal: false }) }
            onSuccess={(e) => {
              this.props.onCreate(e);
            }} />
        )}

        <LayoutLocateEditPage
          nav={() => (
            <PrimaryNavigationEditEntity 
              title="Edit Beacons"             
              navBackRoute={this.props.navBackRoute}
              darkTheme     
            />
          )}          sidebar={() => sidebar}
          content={() => (
            <>
              <SpatialScopeSelector
                structures={structures}
                structureRef={this.props.globalMap.activeRef}
                onChange={(e) => this.onSpatialChange(e)}
              />
              <ClusterMap
                mapView={this.props.globalMap.view}
                onMapViewChanged={this.props.globalMapUpdate}

                pointList={beaconsData ? beaconsData.currentScope : []}
                activePointRef={this.state.highlightedRef}
                structures={structures}
                structureRef={this.props.globalMap.activeRef}
              />
            </>
          )}
        />
      </>
    );
  }
}


export const BeaconListPage = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => ({
    beaconFilters: store.beacons.beaconFilters,
    beacons: store.beacons.beacons,
    globalMap: store.structures.globalMap,
    structures: store.structures.structures,
  }),
  {
    ...pick(actionCreators, 'beaconsListBeaconsRequest', 'beaconFiltersUpdate'),
    ...pick(structuresActionCreators, 'globalMapUpdate', 'structuresLoadRequest'),
  },
)(BeaconListPageView);
