// Code generated by protoc-gen-ts. DO NOT EDIT.
// protoc-gen-ts is an Art Processors production, found in mos-sdk-py.

import { Metadata } from "generated/mos/entity";
import { Placement } from "generated/mos/placement";
import { Codec, DecodeContext, RemoteObject, WebRPCError, decodeMessageRepeated, ensureScalar } from "generated/webrpc";

// POI is a pedestrian-accessible convenience, available at one or more
// points in a physical space, for example a bathroom or a car park.
export namespace POI {
  export type Ref = { readonly typename: 'mos.poi.POI', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.poi.POI');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a POI`);
    return v;
  }
  export const refName = "mos.poi.POI" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.poi.POI', readonly id: string } | undefined,
    readonly metadata: Metadata.Entity | undefined,
    // Name is not required. If a name is not present, the "name" should be
    // inferred at the presentation site by the poi_type_ref.
    readonly name: string,
    // Type of the POI, i.e. "Car Park", "Bathroom", etc.
    readonly poiTypeRef: { readonly typename: 'mos.poi.POIType', readonly id: string } | undefined,
    // Until "hours" are specified and implemented, this freeform text field can
    // be used for arbitrary visitor information.
    readonly visitorInfo: string,
    // A POI can have one or more locations. A car park POI, for example, may be
    // available on multiple building levels.
    // 
    // A POI may exist in the system with no locations, which allows a POI to be
    // partially constructed by the administrator, but a POI requires a location
    // in order to be valid and complete.
    readonly locations: ReadonlyArray<Placement.Entity>,
    readonly imageMediaRef: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    metadata: undefined,
    name: "",
    poiTypeRef: undefined,
    visitorInfo: "",
    locations: [],
    imageMediaRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const obj: RemoteObject = {};
      obj["ref"] = v.ref;
      obj["metadata"] = Metadata.codec.encode(v.metadata);
      obj["name"] = v.name;
      obj["poiTypeRef"] = v.poiTypeRef;
      obj["visitorInfo"] = v.visitorInfo;
      obj["locations"] = [];
      for (const item of v.locations) {
        obj["locations"].push(Placement.codec.encode(item));
      }
      obj["imageMediaRef"] = v.imageMediaRef;
      return obj;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.poi.POI");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["metadata"] = ctx.decode(Metadata.codec, v["metadata"], "metadata");
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["poiTypeRef"] = v["poiTypeRef"];
      out["visitorInfo"] = ensureScalar(ctx, v["visitorInfo"], "string");
      out["locations"] = decodeMessageRepeated(ctx, Placement.codec, v["locations"], "locations");
      out["imageMediaRef"] = v["imageMediaRef"];
      return out as any as Entity;
    }
  }();
}

// POIType is configured per-org
export namespace POIType {
  export type Ref = { readonly typename: 'mos.poi.POIType', readonly id: string };
  export function isRef(v: { typename: string, id: string } | undefined): v is Ref {
    return !!v && typeof v.id === "string" && (v.typename === 'mos.poi.POIType');
  }
  export function mustRef(v: { typename: string, id: string } | undefined): Ref {
    if (!isRef(v)) throw new WebRPCError(`Ref {v.typename}:{v.id} is not a valid ref for a POIType`);
    return v;
  }
  export const refName = "mos.poi.POIType" as const;
  export type Entity = {
    readonly type: typeof refName,
    readonly ref: { readonly typename: 'mos.poi.POIType', readonly id: string } | undefined,
    // User-facing, formatted representation of the POIType.
    readonly name: string,
    readonly iconMediaRef: { readonly typename: 'mos.media.Media', readonly id: string } | undefined,
  }
  export const defaults: Entity = {
    type: refName,
    ref: undefined,
    name: "",
    iconMediaRef: undefined,
  }
  export const codec: Codec<Entity> = new class {
    public encode(v: Entity): RemoteObject;
    public encode(v: Entity | undefined): RemoteObject | null {
      if (!v) return null;
      const { type, ...out } = v;
      return out;
    }
    public decode(v: RemoteObject): Entity;
    public decode(v: RemoteObject | null, ctx?: DecodeContext): Entity | undefined {
      if (v === undefined || v === null) return undefined;
      if (!ctx) ctx = new DecodeContext(".mos.poi.POIType");
      if (typeof(v) !== "object") throw ctx.expected("object", v);
      const out: RemoteObject = { ...defaults };
      out["ref"] = v["ref"];
      out["name"] = ensureScalar(ctx, v["name"], "string");
      out["iconMediaRef"] = v["iconMediaRef"];
      return out as any as Entity;
    }
  }();
}

