import React, { useEffect, useState } from 'react'

import { Confirm } from 'components/ui/modal'
import { PrimaryNavigationEditEntity } from 'components/ui/primary-navigation/edit-entity'
import { StatusGuard } from 'components/ui/status-guard'
import { SecondaryNavigation } from 'components/ui/secondary-navigation'
import { SectionHeader } from 'components/ui/section-header'

import { SharedActionCreators, sharedActionCreators } from 'containers/shared'
import { connect } from 'containers/store'

import { actionCreators, WamActionCreators } from 'domains/org/wam/actions'
import { WamStore, WamAppState } from 'domains/org/wam/store'
import { spacesBindableActionCreators } from 'domains/spaces/actions'
import { SpacesStore } from 'domains/spaces/store'

import { Space } from 'generated/mos/structure'

import { Status, statusSelector } from 'helpers/status'
import { pick } from 'helpers/core'

import { LayoutListOrFormPage } from 'layouts/list-or-form-page'

import { getTourSubnav } from '../tours/subnav'
import { SoundItem } from './sound-item'

type ActionProps =
  Pick<WamActionCreators, 'wamGetGuidedTourRequest' | 'wamDeleteSoundEffectZoneRequest'> &
  Pick<typeof spacesBindableActionCreators, 'spacesRequest'> &
  Pick<SharedActionCreators, 'toastNotification'>

type ConnectedProps =
  Pick<WamAppState['tours'], 'tourUpdate'> &
  Pick<WamAppState['soundEffectZones'], 'soundEffectZoneDelete'> &
  Pick<SpacesStore['spaces'], 'spacesList'>

type DirectProps = {
  projectId: string
  tourId: string
  historyPush: (id: string) => void
}
type Props = ActionProps & ConnectedProps & DirectProps

const SoundEffectZonePageView = (props: Props) => {
  const {
    projectId,
    tourId,
    wamGetGuidedTourRequest,
    wamDeleteSoundEffectZoneRequest,
    soundEffectZoneDelete,
    tourUpdate,
    historyPush,
    toastNotification,
    spacesRequest,
    spacesList,
  } = props

  const data = statusSelector.data(tourUpdate)
  const spacesListData = statusSelector.data(spacesList)

  const [ deleteSoundEffect, setDeleteSoundEffect ] = useState<string | undefined>(undefined)

  useEffect(() => {
    spacesRequest()
    wamGetGuidedTourRequest({ ecmsId: tourId })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (soundEffectZoneDelete.status === Status.Complete) {
      wamGetGuidedTourRequest({ ecmsId: tourId })
      toastNotification({
        type: 'success',
        text: 'Sound Effect deleted successfully.',
      })
    }

    if (soundEffectZoneDelete.status === Status.Failed) {
      toastNotification({
        type: 'error',
        text: 'Sound Effect could not be deleted.',
      })
    }
  }, [soundEffectZoneDelete])

  const deleteHandler = (soundEffectSlug: string) => {
    wamDeleteSoundEffectZoneRequest({
      ecmsTourId: tourId,
      ecmsEffectSlug: soundEffectSlug
    })
    setDeleteSoundEffect(undefined)
  }

  // get Location display text for list item
  const getLocationDisplayText = (triggerSpaces: ReadonlyArray<Space.Ref>): string => {
    switch(triggerSpaces.length) {
      case 0: return 'No Spaces'
      case 1:
        const space = spacesListData && spacesListData.find((space) => Space.mustRef(space.ref).id === triggerSpaces[0].id)
        return space ? space.displayName || space.name : 'No Spaces'
      default:
        return 'Multiple Spaces'
    }
  }

  return (
    <>
      {deleteSoundEffect &&
        <Confirm
          title="Delete Sound Effect?"
          onClose={() => setDeleteSoundEffect(undefined)}
          message={`Are you sure you want to permanently delete "${deleteSoundEffect}"?`}
          confirmLabel="Delete"
          onConfirm={() => deleteHandler(deleteSoundEffect)}
          danger
        />
      }
      <LayoutListOrFormPage
        nav={() => (
          <PrimaryNavigationEditEntity
            darkTheme
            title="Edit tour"
            navBackRoute={`/project/${projectId}/tours`}
          />
        )}
        title={data ? data.title : ''}
        subnav={() => <SecondaryNavigation navItems={getTourSubnav(projectId, tourId)} />}
        content={() => (
          <StatusGuard status={tourUpdate}>
            <StatusGuard status={spacesList}>
              <SectionHeader
                heading={`${data ? data.soundEffectZones.length : 0} sound effect zones`}
              />
                {data && spacesListData &&
                  data.soundEffectZones.map(({slug, triggerSpaces}) => {
                    return (
                      <SoundItem
                        key={slug}
                        title={slug}
                        tourId={tourId}
                        soundEffectZoneId={slug}
                        historyPush={historyPush}
                        projectId={projectId}
                        locationDisplayText={getLocationDisplayText(triggerSpaces)}
                        deleteHandler={() => setDeleteSoundEffect(slug)}
                      />
                    )
                  })
                }
            </StatusGuard>
          </StatusGuard>
        )}
      />
    </>
  )
}

export const SoundEffectZonePage = connect<
  ConnectedProps,
  ActionProps,
  DirectProps,
  WamStore,
  WamActionCreators
>(
  (store) => ({
    ...pick(store.wam.tours, 'tourUpdate'),
    ...pick(store.wam.soundEffectZones, 'soundEffectZoneDelete'),
    ...pick(store.spaces, 'spacesList'),
  }),
  {
    ...pick(actionCreators, 'wamGetGuidedTourRequest', 'wamDeleteSoundEffectZoneRequest'),
    ...pick(spacesBindableActionCreators, 'spacesRequest'),
    ...pick(sharedActionCreators, 'toastNotification'),
  },
)(SoundEffectZonePageView)
