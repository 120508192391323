import React from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'

import { Button, buttonReset } from 'components/ui/button'
import { IconVContext, IconType } from 'components/ui/icons';
import { space } from 'helpers/style'
import styled, { css } from 'styled';
import { iconSizes, IconSizes } from 'styled/core'

const MenuButton = styled(Button)<{ hasBorder?: boolean}>`
  ${({ hasBorder }) => {
    if (!hasBorder) {
      return `
        background-color: transparent;
        border: 0;
        width: auto;

        &:focus:after {
        border-radius: 0;
      }
      `
    }

    return `
      &:focus:after {
        border-radius: 50%;
      }
    `
  }};
`

const MenuWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.grayL80};
  box-shadow: 1px 2px 5px rgba(0,0,0,0.1); /* TODO shadows should be in theme, talk to Mel about semantics/future plans */
  ${({ visible }) => !visible && 'display: none;'}
`;

type Props = {
  readonly hasBorder?: boolean;
  readonly icon: IconType;
  readonly iconSize: IconSizes;
};
type State = {
  readonly visible: boolean;
};
/**
 * The pop over menu can be placed in any position and will not overflow outside the screen, as long as the viewport is at least twice the height and width of the pop over.
 */
export class PopOverMenu extends React.Component<Props, State> {
  public static defaultProps: Props = {
    icon: IconVContext,
    iconSize: 'medium',
  };

  private buttonRef: React.RefObject<HTMLButtonElement>;
  private wrapperRef: React.RefObject<HTMLDivElement>;
  private root: Element
  private instance: Element | null

  public constructor(props: Props) {
    super(props);
    this.state = { visible: false };

    this.buttonRef = React.createRef();
    this.wrapperRef = React.createRef();

    const POPOVER_ROOT_ID = 'popover-root'

    const createPopoverRoot = (id: string) => {
      const root: Element = document.createElement('div')
      root.setAttribute('id', id)
      document.body.appendChild(root)
      return root
    }

    this.root = document.getElementById(POPOVER_ROOT_ID) || createPopoverRoot(POPOVER_ROOT_ID)
    this.instance = document.createElement('div')

    this.show = this.show.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleClick);
    document.addEventListener('scroll', this.handleScroll);
    this.instance && this.root.appendChild(this.instance)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('scroll', this.handleScroll);
    this.instance && this.root.removeChild(this.instance)
  }

  private handleClick(event: any) { // FIXME any
    const wasButtonClick = this.buttonRef.current && this.buttonRef.current.contains(event.target);
    
    if (wasButtonClick && !this.state.visible) {
      this.show();
    } else if (this.state.visible) {
      // any clicks while popover is open should close it (menuButton onClicks will run first)
      this.hide();
    }
  }

  private handleScroll() {
    if (this.state.visible) { this.hide(); }
  }

  private hide() { 
    this.setState({ visible: false });
  }

  private show() {
    if (!this.wrapperRef.current || !this.buttonRef.current) { return; }
    this.setState({ visible: true });

    // get dimensions and positions
    const rect = this.buttonRef.current.getBoundingClientRect();
    const buttonX = rect.left;
    const buttonY = rect.top;
    const buttonW = rect.width;
    const buttonH = rect.height;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const wrapperW = this.wrapperRef.current.offsetWidth;
    const wrapperH = this.wrapperRef.current.offsetHeight;

    // calculate if needs to be moved from default position (bottom to the right)
    const showToRight = (screenW - buttonX - buttonW) > wrapperW;
    const showToBottom = (screenH - buttonY) > wrapperH;

    // set popover position
    const xPosition = showToRight ? `${buttonX + buttonW}px` : `${buttonX - wrapperW}px`;
    const yPosition = showToBottom ? `${buttonY}px` : `${buttonY + buttonH - wrapperH}px`;
    this.wrapperRef.current.style.left = xPosition;
    this.wrapperRef.current.style.top = yPosition;
  }

  public render() {
    const Icon = this.props.icon

    const popoverContent = (
      <MenuWrapper ref={this.wrapperRef} visible={this.state.visible}>
        {this.props.children}
      </MenuWrapper>
    )

    return (
      <>
        <MenuButton
          ref={this.buttonRef}
          hasBorder={this.props.hasBorder}
          appearance="secondary"
          isIcon={true}
          onClick={(event) => event.stopPropagation()}
        >
          <Icon size={this.props.iconSize} />
        </MenuButton>
        {this.instance ? ReactDOM.createPortal(popoverContent, this.instance) : null}
      </>
    )
  }
}

const menuItemCss = css<{ disabled?: boolean }>`
  ${buttonReset}
  padding: ${space(3)};
  background-color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.base};
  text-align: left;
  min-width: 160px;
  user-select: none;
  ${({ disabled, theme }) => disabled && `
    color: ${theme.color.grayL60};
    cursor: not-allowed;
  `};
  &:hover {
    background-color: ${({ theme }) => theme.color.grayL90};
    transition: ${({ theme }) => theme.transition.base};
  }
  &:active {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const PopOverMenuButton = styled.button`${menuItemCss}`;
export const PopOverMenuLink = styled(Link)`${menuItemCss}`;

export const PopOverMenuSeparator = styled.hr`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayL80};
  margin: 0;
`;
