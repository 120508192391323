import React from 'react'

import { PanelHeader } from 'components/ui/side-panel';
import { refToUrn } from 'entity';
import * as geo from 'helpers/geo';
import { Status } from 'helpers/status';

import { TreeListItem } from 'components/ui/list-item';
import { PopOverMenu, PopOverMenuButton } from 'components/ui/pop-over-menu';

import { StructureFilterState } from 'domains/structures';
import { structuresActionCreators } from 'domains/structures/actions';
import { CreateNewStructureModal } from 'domains/structures/components/structure-create';
import { DataStatus } from 'domains/structures/store';
import { StructureRef, StructureEntity, Structures } from 'domains/structures/entities';
import { structuresSelector } from 'domains/structures/selectors';
import { Building, Site } from 'generated/mos/structure';

import { IconBuilding, IconLevels, IconSite, IconType } from 'components/ui/icons';
import styled from 'styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TreeWrapper = styled.div`
  border-top: solid 1px #ddd;
  overflow: auto;
`;

type State = {
  readonly showNewStructureModal: boolean;
};

export type StructureSelectEvent = {
  readonly ref: StructureRef;
  readonly boundary: geo.Polygon2D | undefined;
};

export type StructureHoverEvent = {
  readonly ref?: StructureRef;
};

type Props = {
  readonly structures: DataStatus<Structures>;
  readonly filters: StructureFilterState;
  readonly filtersUpdate: (typeof structuresActionCreators)['structuresFiltersUpdate'];

  readonly onCreate: (ref: StructureRef) => void;
  readonly onSelect: (e: StructureSelectEvent) => void;
  readonly onHover?: (e: StructureHoverEvent) => void;
  readonly centerStructureOnMap: (b: StructureRef | undefined) => void;
};

export class StructureList extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showNewStructureModal: false,
    };
  }

  private hoverItem(ref?: StructureRef) {
    if (this.props.onHover) {
      this.props.onHover({ ref: ref });
    }
  }

  private toggleExpanded = (item: StructureEntity) => {
    if (item.ref === undefined) return;
    const expanded = this.props.filters.expanded;
    const urn = refToUrn(item.ref);
    this.props.filtersUpdate({ expanded: { ...expanded, [urn]: !expanded[urn] } });
  }

  private getEditEntityUrl = (item: StructureEntity) => {
    const ref = item.ref;
    if (ref === undefined) throw new Error();
    switch (ref.typename) {
      case 'mos.structure.Site': return `/locate/structures/site/edit/${ref.id}`;
      case 'mos.structure.Building': return `/locate/structures/building/edit/${ref.id}`;
      case 'mos.structure.BuildingLevel': return `/locate/structures/building-level/edit/${ref.id}`;
      default: throw new Error();
    }
  }

  private renderItems<T extends StructureEntity>(args: {
    items: ReadonlyArray<T>;
    icon: IconType;
    level: number;
    countChildren: (v: T) => number;
    renderChildren?: (structures: Structures, item: T) => any;
  }) {
    return (
      <>
        {args.items.map((item) => {
          if (this.props.structures.status !== Status.Ready) return null;
          if (!item.ref) return null;
          const boundary = structuresSelector.selectBoundaryByRef(
            this.props.structures.data, item.ref)
          const urn = refToUrn(item.ref);
          const isExpanded = !!this.props.filters.expanded[urn];

          return (
            <React.Fragment key={urn}>
              <TreeListItem
                icon={args.icon}
                title={item.name}
                level={args.level}
                childCount={args.countChildren(item)}
                isLastLevel={!args.renderChildren}
                isExpanded={isExpanded}
                onMouseEnter={() => this.hoverItem(item.ref)}
                onMouseLeave={() => this.hoverItem(undefined)}
                onClick={() => {
                  if (item.ref === undefined) return;
                  if (args.renderChildren) { this.toggleExpanded(item); }
                  this.props.onSelect({ ref: item.ref, boundary });
                }}
                primaryAction={{ label: 'Edit', to: this.getEditEntityUrl(item) }}
                renderMenu={<PopOverMenu>
                  <PopOverMenuButton
                    disabled={!boundary}
                    onClick={() => this.props.centerStructureOnMap(item.ref)}
                  >Center on map</PopOverMenuButton>
                </PopOverMenu>}
              />
              {isExpanded && args.renderChildren && args.renderChildren(this.props.structures.data, item)}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  private renderSites = (structures: Structures) => this.renderItems<Site.Entity>({
    items: structures.sites,
    icon: IconSite,
    level: 1,
    countChildren: (v: Site.Entity) => v.buildingRefs ? v.buildingRefs.length : 0,
    renderChildren: this.renderBuildings,
  })

  private renderBuildings = (structures: Structures, site: Site.Entity) => this.renderItems({
    items: structures.buildings.filter(b => b.siteRef && site.ref ? b.siteRef.id === site.ref.id : false),
    icon: IconBuilding,
    level: 2,
    countChildren: (v: Building.Entity) => v.buildingLevelRefs ? v.buildingLevelRefs.length : 0,
    renderChildren: this.renderLevels,
  })

  private renderLevels = (structures: Structures, building: Building.Entity) => this.renderItems({
    items: structures.buildingLevels.filter(
      l => building.ref && l.buildingRef ? l.buildingRef.id === building.ref.id : false).sort((a, b) => a.zOrder - b.zOrder),
    icon: IconLevels,
    level: 3,
    countChildren: (v: any) => 0,
  })

  public render() {
    if (this.props.structures.status !== Status.Ready && this.props.structures.status !== Status.Stale) {
      return null; // FIXME: loading-indicator
    }

    return (
      <Wrapper>
        <div>
          <PanelHeader title="Structures" onAdd={() => this.setState({ showNewStructureModal: true })} />
          {this.state.showNewStructureModal && (
            <CreateNewStructureModal
              onClose={() => this.setState({ showNewStructureModal: false })}
              onCreate={this.props.onCreate}
            />
          )}
        </div>
        <TreeWrapper>
          {this.renderSites(this.props.structures.data)}
        </TreeWrapper>
      </Wrapper>
    );
  }
}
