import React from 'react'

import { Button } from 'components/ui/button'
import { sharedActionCreators } from 'containers/shared';
import { connect } from 'containers/store';
import { Ref, refToUrn } from 'entity';
import { pick } from 'helpers/core';

type DirectProps = {
  readonly modelRef?: Ref;
};

type ActionProps = Pick<typeof sharedActionCreators, 'toastNotification'>;

type Props = DirectProps & ActionProps;

class RefCopyView extends React.Component<Props> {
  private onRefCopy() {
    const ref = this.props.modelRef;

    if (ref && navigator.clipboard) {
      navigator.clipboard.writeText(refToUrn(ref));
      this.props.toastNotification({ type: 'success', text: 'Ref copied to clipboard' });
    }
  }

  public render() {
    return (
      <Button variant="link" onClick={() => this.onRefCopy()}>
        Copy Ref
      </Button>
    );
  }
}

export const RefCopy = connect<{}, ActionProps, DirectProps>(
  () => ({}),
  pick(sharedActionCreators, 'toastNotification'),
)(RefCopyView);
