import { Ref } from 'entity';
import { Page } from 'generated/mos/pagination'
import { captureException } from 'helpers/logging'

export enum Status {
  // Idle means that there is no data and that it is available to load.
  // Idle transitions to 'Loading' only.
  Idle,

  // Loading indicates that the DataStatus is populating its data for the first time.
  // Loading transitions to 'Ready' or 'Failed'.
  Loading,

  // Stale means that there is data, but it should be reloaded.
  // Stale transitions to 'Loading' or 'Updating'.
  Stale,

  // Ready indicates the DataStatus is populated with non-null, defined data.
  // Ready transitions to 'Stale' or 'Updating'.
  Ready,

  // Updating indicates the DataStatus is populated with non-null, defined data which
  // may be in the process of being submitted to the backend, or may be in the process
  // of being refreshed.
  Updating,

  // Complete indicates the DataStatus has successfully finished whatever it is there to do.
  // e.g. The entity has been deleted, or, the entity has been created. Usually a
  // UI will watch for this, and navigate to a new page when it is encountered.
  Complete,

  // The application has encountered an error which can be handled in a way that is
  // meaningful to the user. This information will be carried in the 'messages' property.
  Failed,
}

// Status enum converted to plain object
export const statusList = Object.keys(Status).reduce((acc, statusKey: any) => {
  if (!isNaN(parseInt(statusKey, 10))) { return acc; }
  return {
    ...acc,
    [statusKey]: Status[statusKey],
  };
}, {});

export interface Message<TErrorCode> {
  readonly code: TErrorCode;
  readonly text: string;
}

type DataStatusWithData<TData, TErrorCode> =
  { readonly status: Status.Stale; readonly data: TData } |
  { readonly status: Status.Ready; readonly data: TData; readonly errors?: ReadonlyArray<Message<TErrorCode>> } |
  { readonly status: Status.Updating; readonly data: TData };

export type DataStatus<TData, TErrorCode> =
  { readonly status: Status.Idle } |
  { readonly status: Status.Loading } |
  DataStatusWithData<TData, TErrorCode> |
  { readonly status: Status.Failed; readonly messages: ReadonlyArray<Message<TErrorCode>> } |
  { readonly status: Status.Complete; readonly message: string };

export type EntityStatus<TData extends object, TErrorCode, TRefType = string> =
  { readonly status: Status.Idle } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Loading } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Stale; readonly data: TData } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Ready; readonly data: TData; readonly errors?: ReadonlyArray<Message<TErrorCode>> } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Updating; readonly data: TData } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Failed; readonly messages: ReadonlyArray<Message<TErrorCode>> } |
  { readonly ref: Ref<TRefType>; readonly status: Status.Complete; readonly message: string };


export const statusSelector = new (class {
  public hasData<TData, TErrorCode>(data: DataStatus<TData, TErrorCode>): data is DataStatusWithData<TData, TErrorCode> {
    return data.status === Status.Stale || data.status === Status.Ready || data.status === Status.Updating;
  }

  public data<TData, TErrorCode>(data: DataStatus<TData, TErrorCode>): TData | undefined {
    if (this.hasData(data)) {
      return data.data;
    }
    return undefined;
  }

  public shouldLoad(status: Status): boolean {
    return status === Status.Idle || status === Status.Stale;
  }

  public invalidate<TData, TErrorCode>(data: DataStatus<TData, TErrorCode>): DataStatus<TData, TErrorCode> {
    return data.status === Status.Ready ?
      { ...data, status: Status.Stale } :
      { ...data, status: Status.Idle };
  }
})();

export type PaginatedList<TEntity> = {
  readonly entityList: ReadonlyArray<TEntity>;
  readonly nextPage: Page.Entity | undefined;
  readonly totalItems: number;
}

type ErrorCode = string;
export type ErrorMessage = Message<ErrorCode>
export const failureStatusMessage = (message: string): ErrorMessage => {
  captureException({ error: message })
  return {
    code: message,
    text: message,
  }
};
