/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconBeacon = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M19.62 3.386a10.234 10.234 0 014.375 8.383 10.21 10.21 0 01-4.378 8.404l-.857-1.23a8.71 8.71 0 003.735-7.174 8.735 8.735 0 00-3.737-7.155l.862-1.228zm-15.24 0l.862 1.228a8.735 8.735 0 00-3.737 7.155 8.71 8.71 0 003.498 7.002l.237.171-.857 1.231A10.21 10.21 0 01.005 11.77c0-3.372 1.658-6.475 4.375-8.383zm13 2.4a7.307 7.307 0 013.123 5.986 7.29 7.29 0 01-3.126 6l-.857-1.23a5.79 5.79 0 002.483-4.77c0-1.913-.94-3.673-2.485-4.758l.862-1.227zm-10.76 0l.862 1.228a5.808 5.808 0 00-2.485 4.758 5.79 5.79 0 002.285 4.625l.198.145-.857 1.23a7.29 7.29 0 01-3.126-6A7.307 7.307 0 016.62 5.787zm8.554 2.509a4.25 4.25 0 01-.003 6.97l-.857-1.231a2.75 2.75 0 00-.002-4.512l.862-1.227zm-6.348 0l.862 1.227a2.75 2.75 0 00-.155 4.398l.153.114-.857 1.23a4.25 4.25 0 01-.002-6.97zM12 10.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconBeaconString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M19.62 3.386a10.234 10.234 0 014.375 8.383 10.21 10.21 0 01-4.378 8.404l-.857-1.23a8.71 8.71 0 003.735-7.174 8.735 8.735 0 00-3.737-7.155l.862-1.228zm-15.24 0l.862 1.228a8.735 8.735 0 00-3.737 7.155 8.71 8.71 0 003.498 7.002l.237.171-.857 1.231A10.21 10.21 0 01.005 11.77c0-3.372 1.658-6.475 4.375-8.383zm13 2.4a7.307 7.307 0 013.123 5.986 7.29 7.29 0 01-3.126 6l-.857-1.23a5.79 5.79 0 002.483-4.77c0-1.913-.94-3.673-2.485-4.758l.862-1.227zm-10.76 0l.862 1.228a5.808 5.808 0 00-2.485 4.758 5.79 5.79 0 002.285 4.625l.198.145-.857 1.23a7.29 7.29 0 01-3.126-6A7.307 7.307 0 016.62 5.787zm8.554 2.509a4.25 4.25 0 01-.003 6.97l-.857-1.231a2.75 2.75 0 00-.002-4.512l.862-1.227zm-6.348 0l.862 1.227a2.75 2.75 0 00-.155 4.398l.153.114-.857 1.23a4.25 4.25 0 01-.002-6.97zM12 10.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"  fillRule="nonzero" />
  </svg>`

IconBeacon.displayName = 'IconBeacon'
