import { assertNever, Invariant } from 'helpers/core'
import { failureStatusMessage, Status } from 'helpers/status'

import { WamAppState, initialWamAppState } from '../store'

import {
  DeleteStopDirectionsRequest,
  DeleteStopDirectionsResponse,
  GetStopRequest,
  GetStopResponse,
  UpdateStopDirectionsRequest,
  UpdateStopDirectionsResponse,
} from '../types'

import { FluxStandardAction, FluxStandardErrorAction } from '.'

export const stopsActionCreators = {
  wamGetStopRequest: (
    payload: GetStopRequest,
  ): FluxStandardAction<GetStopRequest, 'wamGetStopRequest'> => ({
    domain: 'wam.stops' as const,
    type: 'wamGetStopRequest' as const,
    payload,
  }),
  wamGetStopSuccess: (
    payload: GetStopResponse,
  ): FluxStandardAction<GetStopResponse, 'wamGetStopSuccess'> => ({
    domain: 'wam.stops' as const,
    type: 'wamGetStopSuccess' as const,
    payload,
  }),
  wamGetStopFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamGetStopFailure'> => ({
    domain: 'wam.stops' as const,
    type: 'wamGetStopFailure' as const,
    payload,
    error: true,
  }),

  wamUpdateStopDirectionsRequest: (
    payload: UpdateStopDirectionsRequest,
  ): FluxStandardAction<UpdateStopDirectionsRequest, 'wamUpdateStopDirectionsRequest'> => ({
    domain: 'wam.stops' as const,
    type: 'wamUpdateStopDirectionsRequest' as const,
    payload,
  }),
  wamUpdateStopDirectionsSuccess: (
    payload: UpdateStopDirectionsResponse,
  ): FluxStandardAction<UpdateStopDirectionsResponse, 'wamUpdateStopDirectionsSuccess'> => ({
    domain: 'wam.stops' as const,
    type: 'wamUpdateStopDirectionsSuccess' as const,
    payload,
  }),
  wamUpdateStopDirectionsFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamUpdateStopDirectionsFailure'> => ({
    domain: 'wam.stops' as const,
    type: 'wamUpdateStopDirectionsFailure' as const,
    payload,
    error: true,
  }),

  wamDeleteStopDirectionsRequest: (
    payload: DeleteStopDirectionsRequest,
  ): FluxStandardAction<DeleteStopDirectionsRequest, 'wamDeleteStopDirectionsRequest'> => ({
    domain: 'wam.stops' as const,
    type: 'wamDeleteStopDirectionsRequest' as const,
    payload,
  }),
  wamDeleteStopDirectionsSuccess: (
    payload: DeleteStopDirectionsResponse
  ): FluxStandardAction<DeleteStopDirectionsResponse, 'wamDeleteStopDirectionsSuccess'> => ({
    domain: 'wam.stops' as const,
    type: 'wamDeleteStopDirectionsSuccess' as const,
    payload
  }),
  wamDeleteStopDirectionsFailure: (
    payload: Error,
  ): FluxStandardErrorAction<'wamDeleteStopDirectionsFailure'> => ({
    domain: 'wam.stops' as const,
    type: 'wamDeleteStopDirectionsFailure' as const,
    payload,
    error: true,
  }),
  wamDeleteStopDirectionsReset: ()  => ({
    domain: 'wam.stops' as const,
    type: 'wamDeleteStopDirectionsReset' as const
  }),
}

type WamAction = ReturnType<typeof stopsActionCreators[keyof typeof stopsActionCreators]>

const assertDomain = (domain: string) => {
  return domain === 'wam.stops'
}

export const stopsReducer = (
  state: WamAppState['stops'] = initialWamAppState.stops,
  action: WamAction,
): WamAppState['stops'] => {
  if (!assertDomain(action.domain)) {
    return state
  }

  switch (action.type) {
    case 'wamGetStopRequest': {
      return {
        ...state,
        stopUpdate: {
          status: Status.Loading
        }
      }
    }
    case 'wamGetStopSuccess': {
      const { payload } = action
      return {
        ...state,
        stopUpdate: {
          status: Status.Ready,
          data: payload
        },
        stopDirectionsUpdate: {
          status: Status.Ready,
          data: payload.directions
        }
      }
    }
    case 'wamGetStopFailure': {
      const { payload } = action
      return {
        ...state,
        stopUpdate: {
          status: Status.Failed,
          messages: [failureStatusMessage(payload.toString())],
        }
      }
    }
    case 'wamUpdateStopDirectionsRequest': {
      if (state.stopDirectionsUpdate.status !== Status.Ready) {
        throw new Error(
          '[ wamUpdateStopDirectionsRequest ] Cannot update entity when `stopDirectionsUpdate` is not in Ready state',
        )
      }
      return {
        ...state,
        stopDirectionsUpdate: {
          ...state.stopDirectionsUpdate,
          status: Status.Updating,
        },
      }
    }
    case 'wamUpdateStopDirectionsSuccess': {
      const { payload } = action
      return {
        ...state,
        stopDirectionsUpdate: {
          data: payload,
          status: Status.Ready,
        }
      }
    }
    case 'wamUpdateStopDirectionsFailure': {
      const { payload } = action
      return {
        ...state,
        stopDirectionsUpdate: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteStopDirectionsRequest': {
      return {
        ...state,
        stopDirectionsDelete: {
          status: Status.Loading
        }
      }
    }
    case 'wamDeleteStopDirectionsSuccess': {
      return {
        ...state,
        stopDirectionsDelete: {
          status: Status.Complete,
          message: 'Stop directions deleted',
        }
      }
    }
    case 'wamDeleteStopDirectionsFailure': {
      const { payload } = action
      return {
        ...state,
        stopDirectionsDelete: {
          messages: [failureStatusMessage(payload.toString())],
          status: Status.Failed,
        },
      }
    }
    case 'wamDeleteStopDirectionsReset': {
      return {
        ...state,
        stopDirectionsDelete: {
          status: Status.Idle
        }
      }
    }
    default: {
      assertNever(action)
      throw new Invariant()
    }
  }
}
