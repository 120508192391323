import * as geo from 'helpers/geo';


export type Dimensions = {
  readonly width: number;
  readonly height: number;
};


const getImageBounds = (mapWidth: number, mapHeight: number, dimensions: Dimensions): Dimensions => {
  const SCALE = 0.6;
  if (dimensions.width < dimensions.height) {
    const height = mapHeight * SCALE;
    const width = mapHeight * (dimensions.width / dimensions.height) * SCALE;
    return { width, height };

  } else {
    const width = mapWidth * SCALE;
    const height = mapWidth * (dimensions.height / dimensions.width) * SCALE;
    return { width, height };
  }
};


export const getAdminMapCorners = (extent: geo.Extent2D, dimensions: Dimensions): geo.Corners => {
  const origin: geo.Position2D = [extent.w, extent.n];
  const mapWidth = geo.kilometers([extent.w, extent.n], [extent.e, extent.n]);
  const mapHeight = geo.kilometers([extent.w, extent.n], [extent.w, extent.s]);

  const { width, height } = getImageBounds(mapWidth, mapHeight, dimensions);
  const offsetTop = (mapHeight - height) / 2;
  const offsetLeft = (mapWidth - width) / 2;

  const nw: geo.Position2D = [
    geo.destKilometers(origin, offsetLeft, 90).coordinates[0],
    geo.destKilometers(origin, offsetTop, 180).coordinates[1],
  ];
  const ne = geo.destKilometers(nw, width, 90).coordinates;
  const se = geo.destKilometers(ne, height, 180).coordinates;
  const sw = geo.destKilometers(se, width, -90).coordinates;

  return [nw, ne, se, sw];
};


export function adminMapMove(feature: any, delta: geo.LngLat) {
  const constrainedDelta = constrainFeatureMovement(feature.toGeoJSON(), delta);

  const currentCoordinates = feature.getCoordinates();

  const moveCoordinate = (coord: geo.Position2D): geo.Position2D => {
    const point = {
      lng: coord[0] + constrainedDelta.lng,
      lat: coord[1] + constrainedDelta.lat,
    };
    return [point.lng, point.lat];
  };

  const nextCoords = currentCoordinates.map((poly: geo.Position2D[]) => poly.map((coord) => moveCoordinate(coord)));

  feature.incomingCoords(nextCoords);

  return nextCoords;
}


export function createSupplementaryPoints(geojson: geo.Feature2D<geo.Polygon2D, any>, basePath?: string | null) {
  const { coordinates } = geojson.geometry;
  const featureId = geojson.properties && geojson.properties.id;

  const supplementaryPoints: geo.Feature2D[] = []; // FIXME: any

  for (const [lineIndex, line] of coordinates.entries()) {
    const lineBasePath = basePath ? `${basePath}.${lineIndex}` : (lineIndex + '');

    let firstPoint: geo.Position2D | undefined;
    for (const [pointIndex, point] of line.entries()) {
      const point2D = point as geo.Position2D;
      const pointPath = lineBasePath ? `${lineBasePath}.${pointIndex}` : String(pointIndex);
      const vertex = createVertex(featureId, point2D, pointPath);

      if (!firstPoint || firstPoint[0] !== point2D[0] || firstPoint[1] !== point2D[1]) {
        supplementaryPoints.push(vertex);
      }
      if (!firstPoint) {
        firstPoint = point2D;
      }
    }
  }

  return supplementaryPoints;
}


// FIXME: gql-typing; path is 'any' for now
export function createVertex(parentId: any, coordinates: geo.Position2D, path: any): geo.Feature2D<geo.Point2D, any> {
  return {
    type: 'Feature',
    geometry: { type: 'Point', coordinates },
    properties: { // FIXME: properties typing
      meta: 'vertex',
      parent: parentId,
      // snake case required to interact with external lib
      // eslint-disable-next-line @typescript-eslint/camelcase
      coord_path: path, 
    },
  };
}


const LAT_MIN = -90;
const LAT_RENDERED_MIN = -85;
const LAT_MAX = 90;
const LAT_RENDERED_MAX = 85;
const LNG_MIN = -270;
const LNG_MAX = 270;

// Ensure that we do not drag north-south far enough for
// - any part of any feature to exceed the poles
// - any feature to be completely lost in the space between the projection's
//   edge and the poles, such that it couldn't be re-selected and moved back
function constrainFeatureMovement(feature: geo.Feature2D, delta: geo.LngLat): geo.LngLat {
  // "inner edge" = a feature's latitude closest to the equator
  let northInnerEdge = LAT_MIN;
  let southInnerEdge = LAT_MAX;
  // "outer edge" = a feature's latitude furthest from the equator
  let northOuterEdge = LAT_MIN;
  let southOuterEdge = LAT_MAX;

  let westEdge = LNG_MAX;
  let eastEdge = LNG_MIN;

  const bounds = geo.extent2D(feature);
  const featureSouthEdge = bounds.s;
  const featureNorthEdge = bounds.n;
  const featureWestEdge = bounds.w;
  const featureEastEdge = bounds.e;
  if (featureSouthEdge > northInnerEdge) { northInnerEdge = featureSouthEdge; }
  if (featureNorthEdge < southInnerEdge) { southInnerEdge = featureNorthEdge; }
  if (featureNorthEdge > northOuterEdge) { northOuterEdge = featureNorthEdge; }
  if (featureSouthEdge < southOuterEdge) { southOuterEdge = featureSouthEdge; }
  if (featureWestEdge < westEdge) { westEdge = featureWestEdge; }
  if (featureEastEdge > eastEdge) { eastEdge = featureEastEdge; }

  // These changes are not mutually exclusive: we might hit the inner
  // edge but also have hit the outer edge and therefore need
  // another readjustment
  const constrainedDelta = { ...delta };
  if (northInnerEdge + constrainedDelta.lat > LAT_RENDERED_MAX) {
    constrainedDelta.lat = LAT_RENDERED_MAX - northInnerEdge;
  }
  if (northOuterEdge + constrainedDelta.lat > LAT_MAX) {
    constrainedDelta.lat = LAT_MAX - northOuterEdge;
  }
  if (southInnerEdge + constrainedDelta.lat < LAT_RENDERED_MIN) {
    constrainedDelta.lat = LAT_RENDERED_MIN - southInnerEdge;
  }
  if (southOuterEdge + constrainedDelta.lat < LAT_MIN) {
    constrainedDelta.lat = LAT_MIN - southOuterEdge;
  }
  if (westEdge + constrainedDelta.lng <= LNG_MIN) {
    constrainedDelta.lng += Math.ceil(Math.abs(constrainedDelta.lng) / 360) * 360;
  }
  if (eastEdge + constrainedDelta.lng >= LNG_MAX) {
    constrainedDelta.lng -= Math.ceil(Math.abs(constrainedDelta.lng) / 360) * 360;
  }

  return constrainedDelta;
}
