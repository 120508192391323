import React from 'react'
import { createFormBag, FormBag, FormData, FormErrors, FormUpdateEvent, validateFormBag } from 'react-formage';

import { Button, ButtonGroup } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { SelectRef } from 'components/ui/select'
import { connect } from 'containers/store';
import { BuildingLevel, Building, Site } from 'generated/mos/structure';
import { pick } from 'helpers/core';
import { Status, statusSelector } from 'helpers/status';
import styled from 'styled'

import { structuresActionCreators } from 'domains/structures/actions';
import { StructuresAppState } from 'domains/structures/store';

type DirectProps = {
  readonly onSuccess: (ref: BuildingLevel.Ref) => void;
};

type ActionProps = Pick<typeof structuresActionCreators,
  'buildingLevelCreateSaveRequest' | 'buildingLevelCreateReset' | 'structuresLoadRequest'>;

type ConnectedProps = Pick<StructuresAppState, 'buildingLevelCreate' | 'structures'>;

type Props = ActionProps & ConnectedProps & DirectProps;

type FormValues = {
  readonly name: string;
  readonly siteRef: Site.Ref | undefined;
  readonly buildingRef: Building.Ref | undefined;
  readonly zOrder: number;
};

type State = {
  readonly bag: FormBag<FormValues>;
};

type BuildingOption = { readonly label: string; readonly value: Building.Ref };

class BuildingLevelCreateFormView extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bag: createFormBag({ name: '', siteRef: undefined, buildingRef: undefined, zOrder: 0 }),
    };
  }

  public componentDidMount() {
    if (statusSelector.shouldLoad(this.props.structures.status)) {
      this.props.structuresLoadRequest();
    }
  }

  public componentDidUpdate() {
    if (this.props.buildingLevelCreate.status === Status.Ready) {
      this.props.onSuccess(this.props.buildingLevelCreate.data);
      this.props.buildingLevelCreateReset();
    }
  }

  private onSubmit = () => {
    const bag = validateFormBag(this.state.bag, this.onFormValidate);
    this.setState({ bag });
    if (!bag.valid) {
      return;
    }

    const { buildingLevelCreateSaveRequest } = this.props;
    const { values } = bag;

    buildingLevelCreateSaveRequest({
      buildingRef: values.buildingRef!,
      name: values.name,
      shortName: values.name, // FIXME
      zOrder: values.zOrder,
    });
  }

  private onFormValidate = (values: FormValues) => {
    const errors: FormErrors<FormValues> = {};
    if (!values.name.trim()) {
      errors.name = 'Required';
    }
    if (!values.siteRef) {
      errors.siteRef = 'Required';
    }
    if (!values.buildingRef) {
      errors.buildingRef = 'Required';
    }
    if (!values.zOrder) {
      errors.zOrder = 'Required';
    }
    return errors;
  }

  private onFormUpdate = (e: FormUpdateEvent<FormValues>) => {
    this.setState({ bag: e.bag });
  }

  public render() {
    const { errors, valid, values, touched } = this.state.bag;
    const { siteRef } = values;

    const loading = this.props.buildingLevelCreate.status === Status.Updating;
    const structures = statusSelector.data(this.props.structures);
    const sites = structures ? structures.sites : [];

    const siteOpts = sites.map((site) => ({
      label: site.name,
      value: Site.mustRef(site.ref),
    }));

    let buildingOpts: BuildingOption[] = [];
    if (structures && siteRef) {
      const buildings = structures.buildings.filter(b => b.siteRef ? b.siteRef.id === siteRef.id : false);
      buildingOpts = buildings.map((building) => ({
        label: building.name,
        value: Building.mustRef(building.ref),
      }));
    }

    const updateRefandOrder = (buildingRef: Building.Ref) => {
      if (structures && siteRef) {
        const buildings = structures.buildings.filter(b => b.siteRef ? b.siteRef.id === siteRef.id : false);
        const building = buildings.find((item) => item.ref === buildingRef)
        const levelCount = building && building.buildingLevelRefs.length
        levelCount && this.setState({
          bag: {
            ...this.state.bag,
            values: {
              ...this.state.bag.values,
              buildingRef,
              zOrder: levelCount
            }
          }
        })
      }
    }

    return (
      <form noValidate onSubmit={(e) => { e.preventDefault(); this.onSubmit(); }}>
        <FormData bag={this.state.bag} onUpdate={this.onFormUpdate} validate={this.onFormValidate}>
          <SelectRef<FormValues, 'siteRef'>
            label="Site"
            field="siteRef"
            options={siteOpts}
            error={touched.siteRef ? errors.siteRef : undefined}
          />
          <SelectRef<FormValues, 'buildingRef'>
            label="Building"
            field="buildingRef"
            options={buildingOpts}
            onChange={updateRefandOrder}
            error={touched.buildingRef ? errors.buildingRef : undefined}
          />
          <Input
            label="Name"
            field="name"
            error={touched.name ? errors.name : undefined}
          />
          <Input
            label="List order"
            field="zOrder"
          />
          <ButtonGroup>
            <Button type="submit" isDisabled={!valid}>
              {loading ? 'Creating...' : 'Create building level'}
            </Button>
          </ButtonGroup>
        </FormData>
      </form>
    );
  }
}

export const BuildingLevelCreateForm = connect<ConnectedProps, ActionProps, DirectProps>(
  (store) => pick(store.structures, 'buildingLevelCreate', 'structures'),
  pick(structuresActionCreators,
    'buildingLevelCreateSaveRequest', 'buildingLevelCreateReset', 'structuresLoadRequest'),
)(BuildingLevelCreateFormView);
