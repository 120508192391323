/* eslint-disable max-len */
// GENERATED BY process-svg DO NOT EDIT
import React from 'react'
import { Icon, Props } from './Icon'

export const IconPause = (props: Props) => (
  <Icon {...props}>
    <path className="path1" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"  fillRule="nonzero" />
  </Icon>
)

// for use with mapbox addables.
export const IconPauseString =
  `<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path className="path1" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"  fillRule="nonzero" />
  </svg>`

IconPause.displayName = 'IconPause'
